import Raven from 'raven-js';
import { userInfoSync } from 'hub-http/userInfo';

function createScopeOrGateChecker(getGatesOrScopesFromUserInfo) {
  const resultsMap = {};
  return function (key, userInfo) {
    if (!(key in resultsMap)) {
      try {
        const gatesOrScopes = getGatesOrScopesFromUserInfo(userInfo || userInfoSync());
        resultsMap[key] = gatesOrScopes.includes(key);
      } catch (e) {
        // Should only fire during unit tests (as userInfo not initialized)
        Raven.captureException(e, {
          fingerprint: ['failed to read auth from userInfoSync']
        });
      }
    }

    return !!resultsMap[key];
  };
}

export const isUngated = createScopeOrGateChecker(userInfo => userInfo.gates);
export const hasScope = createScopeOrGateChecker(userInfo => userInfo.user.scopes);
export const EXPORTED_FOR_TESTING = {
  createScopeOrGateChecker
};