import { omit } from 'underscore';
import formatName from 'I18n/utils/formatName';
import unescapedText from 'I18n/utils/unescapedText';
import { CrmObjectTypes } from 'ContentUtils/constants/CrmObjectTypes';
import { CrmObjectMetaTypes } from 'ContentUtils/enums/CrmObjectMetaTypes';

function isBuiltinType(crmObjectSchema, builtinType) {
  return crmObjectSchema && crmObjectSchema.metaType === CrmObjectMetaTypes.HUBSPOT && crmObjectSchema.name.toLowerCase() === builtinType;
}

export const PrivateCrmObjectTypes = Object.freeze(Object.assign({}, omit(CrmObjectTypes, [CrmObjectTypes.PRODUCT.toUpperCase(), CrmObjectTypes.MARKETING_EVENT.toUpperCase()])));
/*
Returns a list of the labelling properties for CRM object type
 * @param {Object} crmObjectSchema - CRM object schema
 * @returns {Array} - Array of property strings
*/

export function getLabellingProperties(crmObjectSchema, displayProperties = []) {
  if (displayProperties.length) return displayProperties;
  if (isBuiltinType(crmObjectSchema, CrmObjectTypes.PRODUCT)) return ['name'];
  const {
    primaryDisplayProperty,
    secondaryDisplayProperties = []
  } = crmObjectSchema;
  return (primaryDisplayProperty ? [primaryDisplayProperty] : []).concat(secondaryDisplayProperties);
}
/*
Gets the display format of the label for a CRM object instance
 * @param {String} displayFormat - template string for data formatting
 * @param {Number} numberOfDisplayProperties - number of display properties
 * @returns {String} - Display label for the instance
*/

const getCrmObjectLabelDisplayFormat = (displayFormat, numberOfDisplayProperties) => {
  if (displayFormat) return displayFormat;
  let defaultDisplayFormat = '%0';

  for (let i = 1; i <= (numberOfDisplayProperties - 1 || 0); i++) {
    defaultDisplayFormat = `${defaultDisplayFormat} %${i}`;
  }

  return defaultDisplayFormat;
};
/*
Gets the label for a CRM object instance based on the primaryDisplayProperty or
secondaryDisplayProperties from the object's schema. If making edits consider if
applicable to HubDB Row field's `formatRowLabel`.
 * @param {Object} crmObject - CRM object instance
 * @param {Object} crmObjectSchema - CRM object schema
 * @returns {String} - Display label for the instance
*/


export function getCrmObjectLabel(crmObject, crmObjectSchema, displayOptions) {
  if (crmObjectSchema) {
    const {
      properties = {}
    } = crmObject || {};
    const {
      displayFormat,
      displayProperties
    } = displayOptions || {};
    let hasNoContent = true;

    if (displayProperties && displayProperties.length) {
      const numberOfDisplayProperties = displayProperties.length;
      let option = getCrmObjectLabelDisplayFormat(displayFormat, numberOfDisplayProperties);

      if (/%\d+/.test(option)) {
        displayProperties.forEach((col, i) => {
          const labelValue = properties[col];
          if (labelValue) hasNoContent = false;
          option = option.replaceAll(`%${i}`, properties[col] || '') || option;
        });
      }

      return hasNoContent ? '' : option.replaceAll(/%\d+/g, '');
    }

    if (isBuiltinType(crmObjectSchema, CrmObjectTypes.CONTACT)) {
      const {
        firstname: firstName,
        lastname: lastName
      } = properties;
      if (firstName || lastName) return formatName({
        firstName,
        lastName
      });
    }

    const labellingProperties = getLabellingProperties(crmObjectSchema);

    for (const propertyName of labellingProperties) {
      if (properties[propertyName]) return properties[propertyName];
    }
  }

  return `${crmObject.objectId}`;
}
export function getSearchQueryParams(query, displayProperties) {
  if (!(displayProperties && displayProperties.length)) return {
    query
  };
  return {
    filterGroups: displayProperties.map(property => ({
      filters: [{
        property,
        operator: 'EQ',
        value: `${query}*`
      }]
    }))
  };
}
export function getObjectTypeLabel(crmObjectSchema, objectType) {
  const {
    labels,
    metaType
  } = crmObjectSchema || {};
  const {
    singular
  } = labels || {};
  let objectTypeLabel = singular || objectType;
  const builtinTypeKey = objectType.toUpperCase();
  const builtinType = CrmObjectTypes[builtinTypeKey];

  if (metaType === CrmObjectMetaTypes.HUBSPOT && builtinType) {
    objectTypeLabel = unescapedText(`ContentComponents.crmObjectTypes.${builtinType}.singular`);
  }

  return objectTypeLabel.toLowerCase();
}