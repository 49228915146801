import { AccountVerificationProgressStatus } from '../enums';
const {
  ACCEPTED_ACCOUNT_VERIFICATION,
  LOST_ACCESS_CANNOT_APPEAL,
  NEEDS_ACCOUNT_VERIFICATION_APPEAL,
  NOT_APPLICABLE,
  PENDING_ACCOUNT_VERIFICATION,
  REJECTED_ACCOUNT_VERIFICATION
} = AccountVerificationProgressStatus;

const mapAccountVerificationProgressStatusToUIAlertType = resultStatus => {
  switch (resultStatus) {
    case NEEDS_ACCOUNT_VERIFICATION_APPEAL:
    case LOST_ACCESS_CANNOT_APPEAL:
    case REJECTED_ACCOUNT_VERIFICATION:
      return 'danger';

    case ACCEPTED_ACCOUNT_VERIFICATION:
      return 'success';

    case NOT_APPLICABLE:
    case PENDING_ACCOUNT_VERIFICATION:
    default:
      return 'warning';
  }
};

export default mapAccountVerificationProgressStatusToUIAlertType;