import quickFetch from 'quick-fetch'; // @ts-expect-error no declarations found for module

import { buildErrorResponse } from 'hub-http/adapters/adapterUtils'; // @ts-expect-error no declarations found for module

import { responseError } from 'hub-http/helpers/response';

function getEarlyRequesterResultPromise(name, {
  removeRequestOnSuccess = true
} = {}) {
  const earlyMetaRequest = quickFetch.getRequestStateByName(name);
  if (!earlyMetaRequest) return null;
  return new Promise((resolve, reject) => {
    earlyMetaRequest.whenFinished(result => {
      resolve(result);

      if (removeRequestOnSuccess) {
        quickFetch.removeEarlyRequest(name);
      }
    });
    earlyMetaRequest.onError((xhr, errorCode) => {
      const errorReponse = buildErrorResponse(xhr, `early request '${name}' failed`, errorCode);
      reject(responseError(errorReponse, `early request '${name}' failed`, errorCode));
      quickFetch.removeEarlyRequest(name);
    });
  });
}

export default getEarlyRequesterResultPromise;