import { useMemo } from 'react';
import { ApprovalUserType } from '../types/ApprovalUserType';

const useCurrentUserType = (approvalData, currentUser) => {
  return useMemo(() => {
    let result = ApprovalUserType.NON_PARTICIPANT;

    if (!approvalData) {
      return result;
    }

    if (approvalData.requester.activeUserId === currentUser.user_id) {
      result = ApprovalUserType.REQUESTER;
    } else if (approvalData.approvalSteps.length && approvalData.approvalSteps.some(approvalStep => approvalStep.approver.activeUserId === currentUser.user_id)) {
      result = ApprovalUserType.APPROVER;
    }

    return result;
  }, [approvalData, currentUser]);
};

export default useCurrentUserType;