import { useMemo } from 'react';
import { useOwnersContext } from '../context/OwnersContext';
export default function useUnifiedEventSubmittedUser(event) {
  const owners = useOwnersContext();
  return useMemo(() => {
    if (!event.properties.hs_submitted_by_user_id) {
      return null;
    }

    const userId = event.properties.hs_submitted_by_user_id.value;
    return owners.find(owner => {
      return String(owner.activeUserId) === userId;
    });
  }, [owners, event]);
}