'use es6';

import * as actionTypes from 'ContentEditorUI/redux/actions/actionTypes';
import { fetchPortalScopes as requestPortalScopes, fetchPortalInfo as requestPortalInfo, fetchMultiDomainScopeChange, fetchCustomDomainsPurgeDate, fetchSitePagesPasswordProtectionPurgeDate as fetchSitePagesPasswordProtectionPurgeDateApi, fetchLandingPagesPasswordProtectionPurgeDate as fetchLandingPagesPasswordProtectionPurgeDateApi, fetchAudienceAccessPurgeDate as fetchAudienceAccessPurgeDateApi, fetchDynamicPageAccessPurgeDate as fetchDynamicPageAccessPurgeDateApi } from 'ContentEditorUI/api/Hubs';

const fetchPortalScopesSucceeded = response => ({
  type: actionTypes.FETCH_PORTAL_SCOPES_SUCCESS,
  payload: response
});

const fetchPortalScopesFailed = error => ({
  type: actionTypes.FETCH_PORTAL_SCOPES_FAILED,
  error
});

export const fetchPortalScopes = () => dispatch => {
  dispatch({
    type: actionTypes.FETCH_PORTAL_SCOPES_REQUEST
  });

  const success = resp => dispatch(fetchPortalScopesSucceeded(resp));

  const error = resp => dispatch(fetchPortalScopesFailed(resp));

  requestPortalScopes().then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};

const fetchPortalInfoSucceeded = response => ({
  type: actionTypes.FETCH_PORTAL_INFO_SUCCESS,
  payload: response
});

const fetchPortalInfoFailed = error => ({
  type: actionTypes.FETCH_PORTAL_INFO_FAILED,
  error
});

export const fetchPortalInfo = () => dispatch => {
  dispatch({
    type: actionTypes.FETCH_PORTAL_INFO_REQUEST
  });

  const success = resp => dispatch(fetchPortalInfoSucceeded(resp));

  const error = resp => dispatch(fetchPortalInfoFailed(resp));

  requestPortalInfo().then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};

const fetchMultiDomainPublishingScopeChangeSucceeded = status => {
  return {
    type: actionTypes.FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_SUCCESS,
    payload: status
  };
};

const fetchMultiDomainPublishingScopeChangeFailed = error => {
  return {
    type: actionTypes.FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_FAILED,
    payload: error
  };
};

export const fetchMultiDomainPublishingScopeChange = () => dispatch => {
  dispatch({
    type: actionTypes.FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_REQUEST
  });

  const success = resp => dispatch(fetchMultiDomainPublishingScopeChangeSucceeded(resp));

  const error = resp => dispatch(fetchMultiDomainPublishingScopeChangeFailed(resp));

  fetchMultiDomainScopeChange().then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchCustomDomainsPurgeDateForFreeLP = () => dispatch => {
  dispatch({
    type: actionTypes.FETCH_CUSTOM_DOMAINS_PURGE_REQUEST
  });

  const success = date => dispatch({
    type: actionTypes.FETCH_CUSTOM_DOMAINS_PURGE_SUCCESS,
    payload: {
      date
    }
  });

  const error = err => dispatch({
    type: actionTypes.FETCH_CUSTOM_DOMAINS_PURGE_FAILED,
    payload: {
      err
    }
  });

  fetchCustomDomainsPurgeDate().then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchSitePagesPasswordProtectionPurgeDate = () => dispatch => {
  dispatch({
    type: actionTypes.FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST
  });

  const success = date => dispatch({
    type: actionTypes.FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_SUCCESS,
    payload: {
      date
    }
  });

  const error = err => dispatch({
    type: actionTypes.FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_FAILED,
    payload: {
      err
    }
  });

  fetchSitePagesPasswordProtectionPurgeDateApi().then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchLandingPagesPasswordProtectionPurgeDate = () => dispatch => {
  dispatch({
    type: actionTypes.FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST
  });

  const success = date => dispatch({
    type: actionTypes.FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_SUCCESS,
    payload: {
      date
    }
  });

  const error = err => dispatch({
    type: actionTypes.FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_FAILED,
    payload: {
      err
    }
  });

  fetchLandingPagesPasswordProtectionPurgeDateApi().then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchAudienceAccessPurgeDate = () => dispatch => {
  dispatch({
    type: actionTypes.FETCH_AUDIENCE_ACCESS_PURGE_DATE_REQUEST
  });

  const success = date => dispatch({
    type: actionTypes.FETCH_AUDIENCE_ACCESS_PURGE_DATE_SUCCESS,
    payload: {
      date
    }
  });

  const error = err => dispatch({
    type: actionTypes.FETCH_AUDIENCE_ACCESS_PURGE_DATE_FAILED,
    payload: {
      err
    }
  });

  fetchAudienceAccessPurgeDateApi().then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchDynamicPageAccessPurgeDate = () => dispatch => {
  dispatch({
    type: actionTypes.FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_REQUEST
  });

  const success = date => dispatch({
    type: actionTypes.FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_SUCCESS,
    payload: {
      date
    }
  });

  const error = err => dispatch({
    type: actionTypes.FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_FAILED,
    payload: {
      err
    }
  });

  fetchDynamicPageAccessPurgeDateApi().then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};