import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
const CheckoutFloatingAlertStore = FloatingAlertStore.newFloatingAlertStore();

CheckoutFloatingAlertStore.resetStore = function () {
  const alerts = this.getAlerts();
  Object.keys(alerts).forEach(alert => {
    const {
      id
    } = alerts[alert];
    this.removeAlert(id);
  });
};

export default CheckoutFloatingAlertStore;