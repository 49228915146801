import * as browserHelper from './client/browserHelper';
import * as metaPropertiesInterface from './metaProperties';
import * as storageKeys from './storageKeys';
import { clientDependenciesSchema } from './schemas';
import { createEventPool } from './eventPool';
import { createScheduler } from './eventScheduler';
import { createLockedTracker } from './tracker';
import { dispatchFunctionAsync, isArray, once } from './common/helpers';
export const createClient = (dependencies = {}) => {
  const parsedDependencies = clientDependenciesSchema.normalize(dependencies);
  clientDependenciesSchema.validate(parsedDependencies, '"createClient"');
  const {
    clientName,
    getDebug,
    getEmail,
    getHubId,
    getHstc,
    getLang,
    getCurrentHref,
    getReferrer,
    getUserAgent,
    getNetworkType,
    getNetworkSpeed,
    getScreenWidth,
    getScreenHeight,
    getWindowWidth,
    getWindowHeight,
    getDeployableName,
    getDeployableVersion,
    getTempStorage,
    logMessage,
    logWarning,
    logError,
    reportError,
    reportWarning,
    send,
    setTempStorage
  } = parsedDependencies;
  const eventPool = createEventPool({
    getTempStorage,
    setTempStorage
  });
  const {
    scheduleFlush
  } = createScheduler(browserHelper.isDocumentReady);

  const sendToNetwork = (events, options) => {
    const {
      isBeforeUnload,
      isExternalHost
    } = options;
    let query = `clientSendTimestamp=${Date.now()}`;

    if (isExternalHost) {
      query += '&dil=true';
    }

    send({
      events,
      query,
      isBeforeUnload: !!isBeforeUnload
    });
  };

  const flushPoolAndSendToNetwork = options => {
    const eventsToFlush = eventPool.flush();

    if (eventsToFlush && eventsToFlush.length) {
      // If the pool is empty we shouldn't send an empty request to the server
      // to reduce the amount of spam of events
      sendToNetwork(eventsToFlush, options);
    }
  };

  const sendToPool = (events, options) => {
    events.forEach(event => eventPool.push(event));
    dispatchFunctionAsync(() => // Asynchronously dispatch a timer to flush remaining events in the pool
    // to be flushed and then sent to the network
    scheduleFlush(() => flushPoolAndSendToNetwork(options)));
  };

  const metaPropertiesDependencies = {
    clientName,
    getCurrentHref,
    getReferrer,
    getUserAgent,
    getNetworkType,
    getNetworkSpeed,
    getScreenWidth,
    getScreenHeight,
    getWindowWidth,
    getWindowHeight,
    getDeployableName,
    getDeployableVersion,
    getTempStorage,
    setTempStorage
  };
  const getStaticMetaPropertiesOnce = once(metaPropertiesInterface.getStaticMetaProperties);

  const getMetaProperties = overrides => {
    // staticProperties = properties that get set once on tracker initialisation
    // this is called as `once` within `getMetaProperties` so that the first call
    // is when the first `.track` method is called on the tracker
    // as maybe, if this method is called exactly when the tracking client is created
    // the App context might not be ready yet.
    const staticMetaProperties = getStaticMetaPropertiesOnce(metaPropertiesDependencies); // dynamicProperties = properties that can change for each event
    // These properties are invoked within every single call of the `getMetaProperties`
    // as their nature is transient and they will change for each event

    const dynamicMetaProperties = metaPropertiesInterface.getDynamicMetaProperties(metaPropertiesDependencies, overrides.deviceIdOverride);
    return metaPropertiesInterface.getMetaProperties(staticMetaProperties, dynamicMetaProperties);
  };
  /** @deprecated this is here due to compatibility reasons and soon to be removed */


  const recordDispatchedEvents = (eventKey, event) => {
    try {
      // Attempts to get the localStorage state of the stored Usage Event Keys
      const keysRaw = getTempStorage(storageKeys.recordedEventKeys) || '[]'; // Always ensures the currentEventsKeys has 50 items or less
      // and that if it has more, the older entries get removed

      const keys = JSON.parse(keysRaw).reverse().slice(0, 49).reverse();
      setTempStorage(storageKeys.recordedEventKeys, JSON.stringify(keys.concat(eventKey)));
    } catch (err) {
      // In the case we failed to parse the recorded Event Keys we purge the Storage
      setTempStorage(storageKeys.recordedEventKeys, '[]');
    }

    try {
      // Attempts to get the localStorage state of the stored Usage Events
      const eventsRaw = getTempStorage(storageKeys.recordedEvents) || '[]'; // Always ensures the currentEvents has 50 items or less
      // and that if it has more, the older entries get removed

      const events = JSON.parse(eventsRaw).reverse().slice(0, 49).reverse();
      setTempStorage(storageKeys.recordedEvents, JSON.stringify(events.concat(event)));
    } catch (err) {
      // In the case we failed to parse the recorded Events we purge the Storage
      setTempStorage(storageKeys.recordedEvents, '[]');
    }
  }; // This method exists to proactively send (straight away) remaining Events
  // from different Apps or maybe event the current running App
  // that were not able to be dispatched nor handled by other guard-rails


  const proactivelySendTheEventsFromOtherApps = options => {
    try {
      const eventsRaw = getTempStorage(storageKeys.safeGuardKey) || '[]';
      const events = JSON.parse(eventsRaw); // We only want to dispatch this function if there are Events to be dispatched

      if (isArray(events) && events.length > 0) {
        // Directly send the remainder Events through the Network
        // as they're leftovers from a previous instance of the Tracker
        sendToNetwork(events, {
          isExternalHost: options.isExternalHost,
          isBeforeUnload: true
        });
      }
    } catch (err) {
      /* NOOP */
    } finally {
      // We can now clear the Events as they got sent
      setTempStorage(storageKeys.safeGuardKey, '[]');
    }
  };

  const scheduleEvent = (eventKey = '', event, options) => {
    const eventDispatchMethod = options.bypassPool || options.isBeforeUnload ? sendToNetwork : sendToPool; // Enqueue the Event or directly dispatch over the Network

    eventDispatchMethod([event], options); // Dispatch the Event to whoever that is listening to an Usage Tracker Event

    dispatchFunctionAsync(() => browserHelper.dispatchWindowEvent('usageTrackerEvent', {
      eventKey,
      eventPayload: event
    })); // Dispatches the Event to the deprecated/legacy Recorded Events Storage

    dispatchFunctionAsync(() => {
      // We only want to use the legacy way if explicitly requested as this is an
      // internal legacy HubSpot Engineering feature
      if (getTempStorage(storageKeys.recorderEnabled) === 'true') {
        recordDispatchedEvents(eventKey, event);
      }
    });
  };

  return {
    createTracker: config => {
      // This gracefully handles dispatching Events via Browser Beacon when the page is unloading
      // This is a best effort approach, as it is not guaranteed that remaining Events will get dispatched
      // This method is not reliable on Mobile Browsers and some other Edge-cases (like there was no user interaction)
      // @see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#usage_notes
      browserHelper.addEventListener('beforeunload', () => flushPoolAndSendToNetwork({
        isExternalHost: config.isExternalHost,
        isBeforeUnload: true
      })); // This handles scenarios when the user changes from one App to another (Mobile) or when
      // they change to another tab on the Browser or minimize the Browser
      // This is a recommend approach by MDN and can handle situations where `beforeunload` is not available
      // @see https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event#sending_end-of-session_analytics_on_transitioning_to_hidden

      browserHelper.addEventListener('visibilitychange', () => {
        if (!browserHelper.isDocumentVisible()) {
          flushPoolAndSendToNetwork({
            isExternalHost: config.isExternalHost,
            isBeforeUnload: true
          });
        }
      }); // This is another safety guard-rail as in case that both `visibilitychange` and `beforeunload` did not work
      // and in case there are leftover Events by any means, then we verify if these Events still exist

      dispatchFunctionAsync(() => proactivelySendTheEventsFromOtherApps(config));
      return createLockedTracker({
        clientName,
        getMetaProperties,
        getDebug,
        getEmail,
        getHubId,
        getHstc,
        getLang,
        logError,
        logMessage,
        logWarning,
        reportError,
        reportWarning,
        scheduleEvent
      }, config);
    }
  };
};