'use es6';

import { isSuperAdmin } from './UserState';
export const TARGET_TYPE_HUB = 'HUB';
export const TARGET_TYPE_USER = 'USER';
export const isHubOptin = targetType => targetType === TARGET_TYPE_HUB;
export const isUserOptin = targetType => targetType === TARGET_TYPE_USER;
export const OPT_STATE_AVAILABLE = 'AVAILABLE';
export const OPT_STATE_OPTED_IN = 'OPTED_IN';
export const OPT_STATE_OPTED_OUT = 'OPTED_OUT';
export const OPT_STATE_UNAVAILABLE = 'UNAVAILABLE';
export const OPT_CHOICE_USER_OPT_IN = 'USER_OPT_IN';
export const OPT_CHOICE_HUB_OPT_IN = 'HUB_OPT_IN';
export const OPT_CHOICE_USER_OPT_OUT = 'USER_OPT_OUT';
export const OPT_CHOICE_HUB_OPT_OUT = 'HUB_OPT_OUT';
const ALL_STATES = [OPT_STATE_AVAILABLE, OPT_STATE_OPTED_IN, OPT_STATE_OPTED_OUT, OPT_STATE_UNAVAILABLE, OPT_CHOICE_USER_OPT_IN, OPT_CHOICE_HUB_OPT_IN, OPT_CHOICE_USER_OPT_OUT, OPT_CHOICE_HUB_OPT_OUT];

const isUnavailable = value => value === OPT_STATE_UNAVAILABLE;

export const isAvailable = value => value === OPT_STATE_AVAILABLE;
export const isOptedIn = value => value === OPT_STATE_OPTED_IN || value === OPT_CHOICE_USER_OPT_IN || value === OPT_CHOICE_HUB_OPT_IN;
export const isOptedOut = value => value === OPT_STATE_OPTED_OUT || value === OPT_CHOICE_USER_OPT_OUT || value === OPT_CHOICE_HUB_OPT_OUT;
export const isHubWideChoice = choice => choice === OPT_CHOICE_HUB_OPT_IN || choice === OPT_CHOICE_HUB_OPT_OUT;
export const isKnownState = value => ALL_STATES.includes(value);
export const OPT_CHOICE_TO_STATE_VALUE = {
  [OPT_CHOICE_USER_OPT_IN]: OPT_STATE_OPTED_IN,
  [OPT_CHOICE_HUB_OPT_IN]: OPT_STATE_OPTED_IN,
  [OPT_CHOICE_USER_OPT_OUT]: OPT_STATE_OPTED_OUT,
  [OPT_CHOICE_HUB_OPT_OUT]: OPT_STATE_OPTED_OUT
};
export const OPT_CHOICE_TO_TARGET_TYPE = {
  [OPT_CHOICE_USER_OPT_IN]: TARGET_TYPE_USER,
  [OPT_CHOICE_HUB_OPT_IN]: TARGET_TYPE_HUB,
  [OPT_CHOICE_USER_OPT_OUT]: TARGET_TYPE_USER,
  [OPT_CHOICE_HUB_OPT_OUT]: TARGET_TYPE_HUB
};

const areActionsAllowed = ({
  actionsAllowed
}) => actionsAllowed;

export const canHubOptin = ({
  user
}) => isSuperAdmin(user);
export const canUserOptin = ({
  availability: {
    hubLevelOverride,
    targetType
  }
}) => isUserOptin(targetType) && !hubLevelOverride;
export const canHubOptout = ({
  user,
  availability: {
    hubLevelOverride,
    targetType
  }
}) => isSuperAdmin(user) && (isHubOptin(targetType) || isUserOptin(targetType) && hubLevelOverride);
export const canHubOptinFromOptout = ({
  user,
  availability: {
    hubLevelOverride,
    targetType
  }
}) => isSuperAdmin(user) && isUserOptin(targetType) && !hubLevelOverride;
export const canUserOptout = ({
  availability: {
    hubLevelOverride,
    targetType
  }
}) => isUserOptin(targetType) && !hubLevelOverride;
export const isOptin = ({
  state
}) => isAvailable(state) || isOptedOut(state);
export const isOptout = ({
  state
}) => isOptedIn(state);
export const show = ({
  availability: {
    state
  }
}) => !isUnavailable(state);
export const showOptin = optSettings => isOptin(optSettings.availability) && areActionsAllowed(optSettings.availability) && !!(canHubOptin(optSettings) || canUserOptin(optSettings));
export const showOptout = (optSettings, {
  showFeedbackSurvey,
  learnMore
}) => isOptout(optSettings.availability) && areActionsAllowed(optSettings.availability) && !!(canHubOptout(optSettings) || canHubOptinFromOptout(optSettings) || canUserOptout(optSettings) || showFeedbackSurvey || learnMore);
export const getLearnMoreConfig = ({
  availability: {
    articleUrl
  }
}, learnMoreText) => articleUrl ? {
  text: learnMoreText,
  url: articleUrl
} : undefined;
export const getConfirmationOptions = ({
  opt: {
    choice
  },
  OptInConfirmationModal,
  OptOutConfirmationModal
}) => {
  if (isOptedIn(choice)) {
    return OptInConfirmationModal;
  }

  if (isOptedOut(choice)) {
    return OptOutConfirmationModal;
  }

  return null;
};