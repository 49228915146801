import { createSelector } from 'reselect';
import { getContentModelId, stateSelector // @ts-expect-error Not Migrated yet
} from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { getHasPermissionsToCreateOrEditABTest } from 'ContentEditorUI/redux/selectors/authSelectors'; // @ts-expect-error Not Migrated yet

import { getRoutingPathname } from 'ContentEditorUI/redux/selectors/routingSelectors';
const AB_VARIANT_STATUS = ['variant', 'automated_variant', 'automated_loser_variant'];
export const getABTest = basicSelector(state => state.abTest);
export const getABTestID = createSelector([getABTest], abTest => abTest && abTest.abTestId);
export const getDisplayEndTestModal = createSelector([getABTest], abTest => abTest && abTest.forceEndAb);
export const getIsABState = stateSelector('_AB');
export const getIsAbTest = createSelector([getIsABState, getABTestID], (isAbState, ABTestId) => {
  return isAbState || Boolean(ABTestId);
});
export const getABMasterUrl = createSelector([getContentModelId, getABTest, getRoutingPathname], (contentId, abtest, __pathname) => {
  if (!abtest || !abtest.master) {
    return '';
  }

  return window.location.pathname.replace(contentId, `${abtest.master.id}`);
});
export const getABVariantUrl = createSelector([getContentModelId, getABTest, getRoutingPathname], (contentId, abtest, __pathname) => {
  if (!abtest || !abtest.activeVariant) {
    return '';
  }

  return window.location.pathname.replace(contentId, `${abtest.activeVariant.id}`);
});
export const getIsABVariant = createSelector([getABTest], abTest => !!(abTest && abTest.abStatus && AB_VARIANT_STATUS.includes(abTest.abStatus)));
export const getIsABVariantWithoutAccess = createSelector([getIsABVariant, getHasPermissionsToCreateOrEditABTest], (isABVariant, hasPermissionsToCreateOrEditABTest) => isABVariant && !hasPermissionsToCreateOrEditABTest);
export const getAbVariation = createSelector([getABTest], abTest => abTest.abVariation);
export const getAbActiveVariant = createSelector([getABTest], abTest => abTest && abTest.activeVariant);
export const getAbActiveVariantContent = createSelector([getAbActiveVariant], activeVariant => activeVariant && activeVariant.content);
export const getAbActiveVariantId = createSelector([getAbActiveVariant], activeVariant => activeVariant && activeVariant.id);
export const getAbActiveVariantName = createSelector([getAbActiveVariant], activeVariant => activeVariant && activeVariant.name);
export const getAbMaster = createSelector([getABTest], abTest => abTest && abTest.master);
export const getAbMasterContent = createSelector([getAbMaster], master => master && master.content);
export const getAbMasterId = createSelector([getAbMaster], master => master && master.id);
export const getAbMasterName = createSelector([getAbMaster], master => master && master.name);
export const getTotalNumberOfActiveAbVariations = createSelector([getABTest], abtest => abtest.totalActive ? abtest.totalActive : 1 // TODO, is the right answer 1 or 0 here !!!
);
export const getAbTestPages = createSelector([getABTestID, getAbMasterContent, getAbActiveVariantContent], (abTestId, abMaster, abVariation) => {
  return abTestId && abVariation ? [{
    id: abMaster.id,
    language: abMaster.language,
    name: abMaster.name,
    masterId: abMaster.translatedFromId,
    translatedFromId: abMaster.translatedFromId,
    ab: true,
    mab: false
  }, {
    id: abVariation.id,
    language: abMaster.language,
    name: abVariation.name,
    masterId: abVariation.translatedFromId,
    translatedFromId: abVariation.translatedFromId,
    ab: true,
    mab: false
  }] : null;
});
export const getHasAbTestStarted = createSelector([getABTest], abTest => abTest && abTest.allPublished);
export const getIsAbTestWithoutAccess = createSelector([getHasPermissionsToCreateOrEditABTest, getIsAbTest], (hasPermissionsToCreateAbTest, isAbTest) => isAbTest && !hasPermissionsToCreateAbTest);
export const getIsABTestActivelyRunning = createSelector([getIsAbTest, getTotalNumberOfActiveAbVariations], (isAbTest, activeVariations) => {
  return isAbTest && activeVariations > 1;
});