'use es6';

import { ALIGNMENTS, VERTICAL_ALIGNMENTS } from 'ContentUtils/constants/Alignments';
export const prepareLeafNodeStyles = (styles, {
  supportsFlexboxHorizontalCentering = false
} = {}) => {
  const shouldSetFlexboxPositioning = !styles || !styles.flexboxPositioning;

  if (supportsFlexboxHorizontalCentering && shouldSetFlexboxPositioning) {
    styles = Object.assign({}, styles); // Default new image, logo, and CTA modules are centered by default

    styles.flexboxPositioning = ALIGNMENTS.TOP_CENTER;
  }

  return styles;
};
export const calculateExistingVerticalAlignmentForRow = row => {
  const childColumns = row.getColumns();
  const columnAlignmentCounts = {};
  const NONE_KEY = 'NONE';
  columnAlignmentCounts[NONE_KEY] = 0;
  Object.keys(VERTICAL_ALIGNMENTS).forEach(key => {
    columnAlignmentCounts[key] = 0;
  });
  childColumns.forEach(column => {
    const styles = column.getLayoutStyleData();

    if (!styles || !styles.verticalAlignment) {
      columnAlignmentCounts[NONE_KEY] += 1;
      return;
    }

    columnAlignmentCounts[styles.verticalAlignment] += 1;
  });
  let mostCommonAlignment = VERTICAL_ALIGNMENTS.TOP;
  Object.keys(columnAlignmentCounts).forEach(alignmentKey => {
    if (columnAlignmentCounts[alignmentKey] > columnAlignmentCounts[mostCommonAlignment]) {
      mostCommonAlignment = alignmentKey;
    }
  });

  if (mostCommonAlignment === NONE_KEY) {
    return null;
  }

  return mostCommonAlignment;
};
export const setAlignmentOnAllColumnsInRow = (tree, rowName, verticalAlignmentToSet) => {
  let lastTreeResult = tree;
  const row = tree.findRow(rowName);
  const columnNames = row.getColumnNames();

  for (let i = 0; i < columnNames.length; i++) {
    const column = lastTreeResult.findCell(columnNames[i]);
    const columnHasNoStylesAndAnAlignmentNeedsToBeSet = !column.hasLayoutStyles() && verticalAlignmentToSet;
    const columnHasExistingStylesAndVerticalAlignmentIsntEqualToNewOne = column.hasLayoutStyles() && column.getLayoutStyleData().verticalAlignment !== verticalAlignmentToSet;

    if (columnHasNoStylesAndAnAlignmentNeedsToBeSet || columnHasExistingStylesAndVerticalAlignmentIsntEqualToNewOne) {
      lastTreeResult = lastTreeResult.mergeIntoCellStyles(column.getName(), {
        verticalAlignment: verticalAlignmentToSet
      }).tree;
    }
  }

  return {
    tree: lastTreeResult
  };
};
export const moveVerticalAlignmentFromModuleColumnToNewParentColumn = (mutableTree, oldModuleColumnName, newWrapperColumn) => {
  const oldModuleColumn = mutableTree.findCell(oldModuleColumnName);
  const existingVerticalAlignment = oldModuleColumn.hasLayoutStyles() ? oldModuleColumn.getLayoutStyleData().verticalAlignment : false;

  if (!existingVerticalAlignment || !newWrapperColumn) {
    return;
  }

  mutableTree.mergeIntoCellStyles(oldModuleColumn.getName(), {
    verticalAlignment: null
  });
  mutableTree.mergeIntoCellStyles(newWrapperColumn.getName(), {
    verticalAlignment: existingVerticalAlignment
  });
};