'use es6';

import { IMAGE } from 'ContentUtils/constants/CustomWidgetFieldTypes';
export const findModuleById = (modules, id) => {
  // Prevent matching any module that has id === undefined
  if (id == null) {
    return undefined;
  }

  return modules.get(id) || modules.get(`widget_${id}`) || modules.get(`module_${id}`) || modules.find(m => m.get('id') === id);
};
export const isCmv2Module = (module, schema) => {
  const type = module.get('type');

  if (type === 'module') {
    return true;
  }

  if (type === 'custom_widget') {
    const schemaVersion = schema.schemaVersion;
    return schemaVersion && schemaVersion === 2;
  }

  return false;
};
export const getIconForModule = (module, schema, schemaFromBuiltInMapping) => {
  if (module.get('type') === 'module' || module.get('type') === 'custom_widget') {
    return schema.icon;
  } else if (schemaFromBuiltInMapping) {
    return schemaFromBuiltInMapping.icon;
  }

  return null;
};
export const moduleHasAnyJs = schema => {
  return !!schema.js;
};
export const moduleHasAnyJsOrCss = schema => {
  return moduleHasAnyJs(schema) || schema.css;
};
export const getAnyBuiltinImageData = (module, schema) => {
  if (isCmv2Module(module, schema)) {
    const imgBodyValue = module.getIn(['body', 'img']);
    const fields = schema.fields || [];
    const imageField = fields.find(f => f.type === IMAGE);

    if (imgBodyValue) {
      return imgBodyValue.toJS();
    } else if (imageField && imageField.has('default')) {
      return imageField.get('default').toJS();
    }
  } else {
    // Manually pulling all of the image-related fields off of the cmv1 body
    const bodyValue = module.get('body', {});
    return {
      width: bodyValue.get('width'),
      height: bodyValue.get('height'),
      alt: bodyValue.get('alt'),
      img_alt: bodyValue.get('img_alt'),
      original_height: bodyValue.get('original_height'),
      original_width: bodyValue.get('original_width'),
      override_inherited_src: bodyValue.get('override_inherited_src'),
      src: bodyValue.get('src')
    };
  }

  return {};
};
export const getAnyBuiltinImageSize = (module, schema) => {
  const {
    width,
    height,
    size_type
  } = getAnyBuiltinImageData(module, schema);
  return {
    width,
    height,
    size_type
  };
};
const LINKED_IMAGE_MODULE_ID_QA = 802481;
const LINKED_IMAGE_MODULE_ID_PROD = 1155231;
export const isLinkedImageModuleId = moduleId => moduleId === LINKED_IMAGE_MODULE_ID_QA || moduleId === LINKED_IMAGE_MODULE_ID_PROD;
export const isEmailBodyName = name => {
  return name === 'hs_email_body' || name === 'email_body';
};
export const isV1GlobalModule = module => {
  return module && module.get('type') === 'global_widget';
};
export const isCmv2GlobalModule = (module, moduleSchema) => {
  return isCmv2Module(module, moduleSchema) && !!(moduleSchema && moduleSchema.global);
};
const MODULES_TYPES_WITH_FLEXBOX_HORIZONTAL_STYLES_ENABLED = ['image', 'linked_image', 'logo', 'cta', // LP starter only modules
'button'];
export const isModuleTypeWithFlexboxHorizontalStylesEnabled = type => MODULES_TYPES_WITH_FLEXBOX_HORIZONTAL_STYLES_ENABLED.includes(type);
export const builtinFormModuleHasFormIdSet = module => {
  return !!module.getIn(['body', 'form_to_use']) || !!module.getIn(['body', 'form', 'form_id']);
};
export const getAllFieldNamesOnModuleSpec = moduleSpec => (moduleSpec.fields || []).map(field => field.name);