'use es6';

import { universalAppIsReady } from 'ContentEditorUI/redux/actions/universalAppStatusActions';

const isAppReady = actionsObject => {
  return Object.keys(actionsObject).every(action => actionsObject[action]);
};

export default function universalAppReadyMiddleware(readyActions = []) {
  const readyActionsObject = readyActions.reduce((acc, action) => Object.assign({}, acc, {
    [action]: false
  }), {});
  return store => next => action => {
    // Make sure we compute the result of the current action first before firing app ready
    const result = next(action);

    if (readyActionsObject.hasOwnProperty(action.type)) {
      readyActionsObject[action.type] = true;

      if (isAppReady(readyActionsObject)) {
        store.dispatch(universalAppIsReady());
      }
    }

    return result;
  };
}