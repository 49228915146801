import * as CurrencyCodes from '../constants/CurrencyCodes';
import * as UpgradeProducts from '../constants/UpgradeProducts';
import * as ApiNames from '../constants/ApiNames';
import { RECURRING } from '../constants/ChargeTypes';
const currencyCodeValues = Object.values(CurrencyCodes || {});
const upgradeProductValues = Object.values(UpgradeProducts || {});
const apiNameValues = Object.values(ApiNames || {});
export const validateSkuId = skuId => {
  if (typeof skuId !== 'number') {
    throw new Error(`Invalid skuId (${skuId}), must be one of type number`);
  }
};
export const validateCurrencyCode = currencyCode => {
  if (currencyCodeValues.indexOf(currencyCode) === -1) {
    throw new Error(`Invalid currencyCode, must be one of: ${currencyCodeValues}`);
  }
};
export const validateProductConfigurations = productConfigurations => {
  if (productConfigurations === null || productConfigurations === undefined || Array.isArray(productConfigurations) && !productConfigurations.length) {
    return;
  }

  if (!Array.isArray(productConfigurations)) {
    throw new Error(`Invalid productConfigurations: ${JSON.stringify(productConfigurations)}, must be null, undefined, or an array`);
  }

  productConfigurations.forEach(productConfiguration => {
    // The offer builder API uses the property name "id" but we enforce
    // "merchandiseId" for clarity and later transform it for the API
    validateSkuId(productConfiguration.skuId); // The offer builder API supports null values for quantity and defaults
    // to 1 on the backend but we enforce the explicit passing of a value
    // for consistency on the front end.

    if (productConfiguration.quantity === undefined || productConfiguration.quantity === null) {
      throw new Error(`Invalid productConfiguration: ${JSON.stringify(productConfiguration)}, must contain a quantity.`);
    }

    if (!Number.isInteger(productConfiguration.quantity)) {
      throw new Error(`Invalid productConfiguration: ${JSON.stringify(productConfiguration)}, quantity must be an integer.`);
    }
  });
};
export const validatePurchaseableItemInConfig = (productConfigurations, productBasedOffers) => {
  if ((!productConfigurations || !productConfigurations.length) && (!productBasedOffers || !productBasedOffers.length)) {
    throw new Error(`Invalid purchaseConfig, must have at least one itemConfiguration or productBasedOffer`);
  }
};
export const hasRecurringProducts = (productConfigurations, productMap, skuMap) => productConfigurations.some(({
  skuId
}) => productMap[skuMap[skuId].productId].chargeType === RECURRING);
export const validatePurchaseConfig = ({
  offerId,
  currencyCode,
  productConfigurations,
  productBasedOffers
}, productMap, skuMap) => {
  validateCurrencyCode(currencyCode);
  validateProductConfigurations(productConfigurations);
  validatePurchaseableItemInConfig(productConfigurations, productBasedOffers);

  if (hasRecurringProducts(productConfigurations, productMap, skuMap) && !offerId) {
    throw new Error('Must include an offerId if your productConfigurations contain a RECURRING item');
  }
};
export const validateUpgradeProduct = upgradeProduct => {
  if (upgradeProductValues.indexOf(upgradeProduct) === -1) {
    throw new Error(`Invalid upgradeProduct, must be one of: ${upgradeProductValues}`);
  }
};
export const validateApiName = apiName => {
  if (apiNameValues.indexOf(apiName) === -1) {
    console.error(`Invalid apiName, must be one of: ${apiNameValues}`);
  }
};
export const validateMultipleOfferPurchseConfig = ({
  offerProductConfigurations,
  nonRecurringProductConfigurations,
  productBasedOffers,
  currencyCode
}) => {
  validateCurrencyCode(currencyCode);
  offerProductConfigurations.forEach(({
    productConfigurations
  }) => validateProductConfigurations(productConfigurations));
  validateProductConfigurations(nonRecurringProductConfigurations);
  const allProductConfigurations = [...offerProductConfigurations.map(({
    productConfigurations
  }) => productConfigurations).flat(), ...nonRecurringProductConfigurations];
  validatePurchaseableItemInConfig(allProductConfigurations, productBasedOffers);
};