import { uniq, flatten } from 'underscore';
import * as FieldAttributes from 'ContentUtils/constants/CustomWidgetFieldAttributes';
import * as FieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
/**
 * PLEASE READ BEFORE MAKING CHANGES
 *
 * Any changes made in this file, must also be made here to maintain consistency
 * https://git.hubteam.com/HubSpot/CmsAssets/blob/master/bin/constants/Translations.js
 */

export const BASE_ATTRIBUTES = Object.freeze([FieldAttributes.LABEL, FieldAttributes.HELP_TEXT, FieldAttributes.INLINE_HELP_TEXT, FieldAttributes.DEFAULT, FieldAttributes.PLACEHOLDER]);
export const ADDITIONAL_ATTRIBUTES = Object.freeze({
  [FieldTypes.TEXT]: [FieldAttributes.VALIDATION_REGEX],
  [FieldTypes.CHOICE]: [FieldAttributes.CHOICES]
});
export const TRANSLATABLE_FIELD_ATTRIBUTES = Object.freeze(uniq(BASE_ATTRIBUTES.concat(flatten(Object.values(ADDITIONAL_ATTRIBUTES || {})))).reduce((hash, attr) => {
  hash[attr] = attr;
  return hash;
}, {}));
export const PROTECTED_DEFAULT_PROPERTY_FIELDS = Object.freeze({
  [FieldTypes.CHOICE]: FieldTypes.CHOICE,
  [FieldTypes.BOOLEAN]: FieldTypes.BOOLEAN,
  [FieldTypes.DATE_TIME]: FieldTypes.DATE_TIME,
  [FieldTypes.DATE]: FieldTypes.DATE,
  [FieldTypes.NUMBER]: FieldTypes.NUMBER,
  [FieldTypes.SIMPLE_MENU]: FieldTypes.SIMPLE_MENU,
  [FieldTypes.BLOG]: FieldTypes.BLOG,
  [FieldTypes.COLOR]: FieldTypes.COLOR,
  [FieldTypes.CTA]: FieldTypes.CTA,
  [FieldTypes.EMAIL]: FieldTypes.EMAIL,
  [FieldTypes.FILE]: FieldTypes.FILE,
  [FieldTypes.FOLLOW_UP_EMAIL]: FieldTypes.FOLLOW_UP_EMAIL,
  [FieldTypes.HUBDB_TABLE]: FieldTypes.HUBDB_TABLE,
  [FieldTypes.ICON]: FieldTypes.ICON,
  [FieldTypes.MENU]: FieldTypes.MENU,
  [FieldTypes.PAGE]: FieldTypes.PAGE,
  [FieldTypes.TAG]: FieldTypes.TAG,
  [FieldTypes.WORKFLOW]: FieldTypes.WORKFLOW,
  [FieldTypes.FONT]: FieldTypes.FONT,
  [FieldTypes.HUBL]: FieldTypes.HUBL,
  [FieldTypes.HTML]: FieldTypes.HTML,
  [FieldTypes.GROUP]: FieldTypes.GROUP,
  [FieldTypes.URL]: FieldTypes.URL,
  [FieldTypes.LINK]: FieldTypes.LINK,
  [FieldTypes.VIDEO]: FieldTypes.VIDEO
});
export const SPECIAL_DEFAULT_CHILD_PROPERTIES = Object.freeze({
  [FieldTypes.FORM]: {
    message: 'message'
  },
  [FieldTypes.IMAGE]: {
    alt: 'alt'
  },
  [FieldTypes.LOGO]: {
    alt: 'alt'
  }
});
export const BLOG_CONTENT_PROD_MODULE_ID = 1367092;
export const BLOG_CONTENT_QA_MODULE_ID = 992886;
export const LOCALE_REGEX = /^[a-z]{2}-[a-z]{2}$/i;