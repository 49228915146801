import { checkIfLinkElement, INLINE_EDITOR_CSS_ELEMENT_ID, CONTENT_ASSET_TYPE_INLINE_EDITOR_CSS, CONTENT_ASSET_TYPE_CSS, CONTENT_ASSET_TYPE_INLINE_CSS, CONTENT_ASSET_TYPE_INLINE_OTHER, CONTENT_ASSET_TYPE_LINKED_ASSET, NO_CONTENT_ASSET_TYPE } from 'ContentEditorUI/ts/ContentAssetsTypes';
export const convertDomElementToAssetJSON = element => {
  const isLinkElement = checkIfLinkElement(element);
  const isPreloadElement = isLinkElement && element.rel && element.rel.toLowerCase() === 'preload';
  const isExternalStyleSheet = isLinkElement && !isPreloadElement;
  const isStyleBlock = element.tagName === 'STYLE';

  if (isExternalStyleSheet) {
    return {
      type: CONTENT_ASSET_TYPE_CSS,
      url: element.getAttribute('href') || ''
    };
  } else if (isPreloadElement) {
    return {
      type: CONTENT_ASSET_TYPE_LINKED_ASSET,
      loadMethod: element.rel,
      loadAsProperty: element.as,
      url: element.getAttribute('href') || ''
    };
  } else if (isStyleBlock) {
    const styleId = element.getAttribute('id');
    return {
      type: styleId === INLINE_EDITOR_CSS_ELEMENT_ID ? CONTENT_ASSET_TYPE_INLINE_EDITOR_CSS : CONTENT_ASSET_TYPE_INLINE_CSS,
      snippet: element.innerHTML
    };
  } else {
    return {
      type: NO_CONTENT_ASSET_TYPE,
      snippet: null
    };
  }
};
export const convertAssetHtmlAsStringToAssetJSON = assetHtmlAsString => {
  const tempDiv = document.createElement('div');
  tempDiv.insertAdjacentHTML('afterbegin', assetHtmlAsString);
  const assetAsElement = tempDiv.firstElementChild;

  if (assetAsElement) {
    return convertDomElementToAssetJSON(assetAsElement);
  }

  return undefined;
};
export const assetsAreEqual = (asset1, asset2) => {
  if (asset1.type !== asset2.type) {
    return false;
  }

  if (asset1.type === CONTENT_ASSET_TYPE_CSS && asset2.type === CONTENT_ASSET_TYPE_CSS) {
    // Since we know they are the same asset type,
    // if one asset has a url param, assume the second one does too
    // Are the external style sheet urls the same?
    if (asset1.url && asset1.url === asset2.url) {
      return true;
    }
  }

  const asset1HasSnippet = asset1.type === CONTENT_ASSET_TYPE_INLINE_EDITOR_CSS || asset1.type === CONTENT_ASSET_TYPE_INLINE_OTHER || asset1.type === CONTENT_ASSET_TYPE_INLINE_CSS;
  const asset2HasSnippet = asset2.type === CONTENT_ASSET_TYPE_INLINE_EDITOR_CSS || asset2.type === CONTENT_ASSET_TYPE_INLINE_OTHER || asset2.type === CONTENT_ASSET_TYPE_INLINE_CSS; // Compare snippets strings

  if (asset1HasSnippet && asset2HasSnippet && asset1.snippet.trim() === asset2.snippet.trim()) {
    return true;
  }

  return false;
};