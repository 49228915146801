'use es6';

import I18n from 'I18n';

function isPluralI18nKey(lookupValue) {
  if (typeof lookupValue !== 'object') {
    return false;
  }

  const lookupKeys = Object.keys(lookupValue);
  let pluralKeysCount = 0;

  if (lookupKeys.length <= 6) {
    lookupKeys.forEach(key => {
      if (key === 'zero' || key === 'one' || key === 'other' || key === 'many' || key === 'two' || key === 'few') {
        pluralKeysCount += 1;
      }
    });
  }

  return !!pluralKeysCount && pluralKeysCount === lookupKeys.length;
}

function isJSXKey(message, lookupValue) {
  return typeof lookupValue === 'function' && message.slice(-4).indexOf('_jsx') >= 0;
}

export default (message => {
  const lookupValue = I18n.lookup(message);
  return !!(lookupValue && (typeof lookupValue === 'string' || isJSXKey(message, lookupValue))) || isPluralI18nKey(lookupValue);
});