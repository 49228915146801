import { getSurveyFetchStatus, getSurveyCache } from 'ContentData/selectors/surveys';
import { createGenericFetchAction, createGenericCreateAction } from 'ContentData/helpers/reduxHelpers';
import { SURVEYS_FETCH_SURVEY, SURVEYS_FETCH, SURVEYS_CLEAR_CACHE, SURVEYS_SAVE_SURVEY_CACHE, SURVEYS_UPDATE_SURVEY_STATE } from 'ContentData/actions/ActionTypes';
import http from 'hub-http/clients/apiClient';
import * as surveysClient from 'ContentData/api/Surveys';
import { SUCCEEDED } from 'ContentUtils/constants/RequestStatus';

const surveyAlreadyFetched = (state, options) => {
  return getSurveyFetchStatus(state, options) === SUCCEEDED;
};

const fetchSurveyAction = createGenericFetchAction(SURVEYS_FETCH_SURVEY, surveysClient.fetchSurvey, surveyAlreadyFetched);
const enableSurveyAction = createGenericCreateAction(SURVEYS_UPDATE_SURVEY_STATE, surveysClient.enableSurvey);
const disableSurveyAction = createGenericCreateAction(SURVEYS_UPDATE_SURVEY_STATE, surveysClient.disableSurvey);
export function fetchSurvey(surveyType, id) {
  return fetchSurveyAction({
    surveyType,
    id
  });
}
export function fetchSurveys(query) {
  const {
    request,
    receive,
    error
  } = fetchSurvey;
  return (dispatch, getState, {
    httpClient = http
  } = {}) => {
    dispatch(request());
    return surveysClient.fetchSurveys(query, httpClient).then(surveysPage => {
      /* Transform results into standard paginated format */
      const response = {
        limit: query.pageSize,
        objects: surveysPage.results,
        total: surveysPage.totalCount,
        offset: (query.pageNumber - 1) * query.pageSize
      };
      dispatch(receive(response));
      return response;
    }).catch(__error => {
      dispatch(error(__error));
      return __error;
    });
  };
}

fetchSurvey.request = () => ({
  type: SURVEYS_FETCH
});

fetchSurvey.receive = response => ({
  type: SURVEYS_FETCH,
  response
});

fetchSurvey.error = error => ({
  type: SURVEYS_FETCH,
  error
});

export function saveCache(surveyType, id) {
  return (dispatch, getState) => {
    const survey = getSurveyCache(getState(), {
      surveyType,
      id
    });
    dispatch({
      type: SURVEYS_SAVE_SURVEY_CACHE,
      survey
    });
  };
}
export function clearCache() {
  return {
    type: SURVEYS_CLEAR_CACHE
  };
}
export function updateSurveyState(enabled, surveyType, surveyId) {
  const props = {
    surveyType,
    surveyId
  };
  return enabled ? enableSurveyAction(props) : disableSurveyAction(props);
}

updateSurveyState.request = () => ({
  type: SURVEYS_UPDATE_SURVEY_STATE
});

updateSurveyState.receive = response => ({
  type: SURVEYS_UPDATE_SURVEY_STATE,
  response
});

updateSurveyState.error = error => ({
  type: SURVEYS_UPDATE_SURVEY_STATE,
  error
});