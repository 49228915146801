'use es6';

import { createBrowserHistory } from 'history';
import { setHistory } from './getHistory';

const createHistory = rootUrl => {
  const routerHistory = createBrowserHistory({
    basename: rootUrl
  });
  setHistory(routerHistory);
  return routerHistory;
};

export default createHistory;