'use es6';

import http from 'hub-http/clients/apiClient';
import getEarlyRequesterResultPromise from 'ContentEditorUI/earlyRequester/getEarlyRequesterResultPromise';

const getURI = scope => `vetting/public/v1/account-verification/state/scope/${scope}`;

export default class AccountVerificationApi {
  static fetchAccountVerificationStatus() {
    return getEarlyRequesterResultPromise('initial-account-verification-status-fetch', false) || http.get(getURI('landingpages-trusted'));
  }

}