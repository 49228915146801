import { useMemo } from 'react';
import { countChangeBetweenLists } from '../utils/countChangesBetweenLists';
import { useApprovalContext } from './useApprovalContext';
import useApprovers from './useApprovers';
import useApproversInfo from './useApproversInfo';
import useMutableValue from './useMutableValue';

const useEditApproval = (approvalType, approvalAction) => {
  const {
    approvers,
    isLoading
  } = useApprovers(approvalType, approvalAction);
  const {
    approverIds,
    approvedApproverIds
  } = useApproversInfo();
  const approval = useApprovalContext();
  const [selectedApproverIdsMutable, setSelectedApproverIdsMutable] = useMutableValue(approverIds);
  const approversAll = useMemo(() => {
    // Combine the approvers from the `/approvers` enpoint and the approvers from the
    // current approval to ensure all approvers on that approval are shown, even if an
    // approver has lost permission since the approval request.
    const uniqueApprovers = {};
    const currentApprovalApprovers = !approval.data ? [] : approval.data.approvalSteps.map(step => step.approver);
    [...approvers, ...currentApprovalApprovers].forEach(item => {
      if (item.ownerId in uniqueApprovers) {
        return;
      } else {
        uniqueApprovers[item.ownerId] = item;
      }
    });
    return Object.values(uniqueApprovers).sort((a, b) => a.firstName.toLocaleUpperCase() < b.firstName.toLocaleUpperCase() ? -1 : 1);
  }, [approval, approvers]);
  const numberOfChanges = useMemo(() => countChangeBetweenLists(selectedApproverIdsMutable, approverIds), [selectedApproverIdsMutable, approverIds]);
  return {
    approvedApproverIds,
    approversAll,
    setSelectedApproverIdsMutable,
    isLoading,
    numberOfChanges,
    selectedApproverIdsMutable
  };
};

export default useEditApproval;