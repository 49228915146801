import devLogger from 'react-utils/devLogger';
import { Metrics } from '../Metrics';

function deprecated(propType, message, defaultValue) {
  const deprecatedPropType = (props, propName, componentName, location, ...rest) => {
    const propValue = props[propName];
    const output = typeof message === 'function' ? message(propName, propValue, props) : message;

    if (output && propValue !== defaultValue) {
      Metrics.counter('deprecated-prop-used', {
        component_name: componentName
      }).increment();

      if (typeof output === 'string') {
        devLogger.warn({
          message: output,
          key: null
        });
      } else {
        devLogger.warn(output);
      }
    }

    return propType(props, propName, componentName, location, ...rest);
  };

  if (propType.__INTROSPECTION__) {
    deprecatedPropType.__INTROSPECTION__ = Object.assign({}, propType.__INTROSPECTION__, {
      deprecated: true
    });
  }

  return deprecatedPropType;
}

export default deprecated;