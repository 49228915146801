import { AccountVerificationState, AccountVerificationProgressStatus } from '../enums';
const {
  ACCEPTED_ACCOUNT_VERIFICATION,
  LOST_ACCESS_CANNOT_APPEAL,
  NEEDS_ACCOUNT_VERIFICATION_APPEAL,
  NOT_APPLICABLE,
  PENDING_ACCOUNT_VERIFICATION,
  REJECTED_ACCOUNT_VERIFICATION
} = AccountVerificationProgressStatus;
const {
  PENDING,
  PENDING_AUTO_APPROVAL,
  ACCEPTED,
  ACCEPTED_WITHOUT_EMAIL,
  AUTO_ACCEPTED,
  REJECTED,
  PENDING_AUTO_DENIAL,
  AUTO_REJECTED
} = AccountVerificationState;

const mapScopeStatusToAccountVerificationProgressStatus = status => {
  if (!status) return NOT_APPLICABLE;
  const {
    suspended,
    canAppeal,
    appealState
  } = status;

  if (suspended && canAppeal) {
    return NEEDS_ACCOUNT_VERIFICATION_APPEAL;
  } else if (suspended && !canAppeal && (appealState === PENDING || appealState === PENDING_AUTO_APPROVAL)) {
    return PENDING_ACCOUNT_VERIFICATION;
  } else if (suspended && !canAppeal && (appealState === REJECTED || appealState === AUTO_REJECTED || appealState === PENDING_AUTO_DENIAL)) {
    return REJECTED_ACCOUNT_VERIFICATION;
  } else if (!suspended && !canAppeal && (appealState === ACCEPTED || appealState === ACCEPTED_WITHOUT_EMAIL || appealState === AUTO_ACCEPTED)) {
    return ACCEPTED_ACCOUNT_VERIFICATION;
  } else if (suspended && !canAppeal && !appealState) {
    return LOST_ACCESS_CANNOT_APPEAL;
  }

  return NOT_APPLICABLE;
};

export default mapScopeStatusToAccountVerificationProgressStatus;