export const CIRCLE_BASE_SIZE = 24;
export const BOX_BASE_WIDTH = 15;
export const BOX_BASE_HEIGHT = 40;
export const getSize = value => typeof value === 'number' ? `${value}px` : value;
export let UseOption;

(function (UseOption) {
  UseOption["DEFAULT"] = "default";
  UseOption["BLANK"] = "blank";
  UseOption["SHADE"] = "on-shade";
  UseOption["DARK"] = "on-dark";
})(UseOption || (UseOption = {}));