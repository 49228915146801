'use es6';

import AccountVerificationApi from 'ContentEditorUI/api/AccountVerificationApi';
import { FETCH_ACCOUNT_VERIFICATION_STATUS_FAILED, FETCH_ACCOUNT_VERIFICATION_STATUS_STARTED, FETCH_ACCOUNT_VERIFICATION_STATUS_SUCCEEDED } from './actionTypes';

const fetchAccountVerificationStatusSucceeded = status => {
  return {
    type: FETCH_ACCOUNT_VERIFICATION_STATUS_SUCCEEDED,
    payload: status
  };
};

const fetchAccountVerificationStatusFailed = error => {
  return {
    type: FETCH_ACCOUNT_VERIFICATION_STATUS_FAILED,
    payload: error
  };
};

export const fetchAccountVerificationStatus = () => dispatch => {
  dispatch({
    type: FETCH_ACCOUNT_VERIFICATION_STATUS_STARTED
  });

  const success = resp => {
    if (resp) {
      dispatch(fetchAccountVerificationStatusSucceeded(resp));
    } else {
      dispatch(fetchAccountVerificationStatusFailed(resp));
    }
  };

  const error = resp => dispatch(fetchAccountVerificationStatusFailed(resp));

  AccountVerificationApi.fetchAccountVerificationStatus().then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};