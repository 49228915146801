import { copyRect } from '../../utils/Rects';
const TOOLTIP_BUFFER_SIZE = 50;
/**
 * Detect if a mouseevent occurred within a given rectangle of UI coordinates
 **/

export const isCursorOver = (boundingClientRect, mouseEvent) => {
  const {
    top,
    bottom,
    left,
    right
  } = boundingClientRect;
  const {
    clientX: X,
    clientY: Y
  } = mouseEvent;
  return X >= left && X <= right && Y <= bottom && Y >= top;
};
/**
 * Grows each edge of `originalRect` outward toward `magnetRect` by `bufferSize`. Used to determine
 * the "cursor space" between a tooltip target and the tooltip itself.
 *
 * @param {Object} originalRect
 * @param {Object} magnetRect
 * @param {number} bufferSize
 * @return {Object}
 */

const addBufferToRect = (originalRect, magnetRect, bufferSize) => {
  const bufferedRect = copyRect(originalRect);

  if (originalRect.top > magnetRect.top) {
    bufferedRect.top = Math.max(magnetRect.top, originalRect.top - bufferSize);
  }

  if (originalRect.bottom < magnetRect.bottom) {
    bufferedRect.bottom = Math.min(magnetRect.bottom, originalRect.bottom + bufferSize);
  }

  if (originalRect.left > magnetRect.left) {
    bufferedRect.left = Math.max(magnetRect.left, originalRect.left - bufferSize);
  }

  if (originalRect.right < magnetRect.right) {
    bufferedRect.right = Math.min(magnetRect.right, originalRect.right + bufferSize);
  }

  return bufferedRect;
};
/**
 * Determine if a tooltip should close, given the `targetRect`, `tooltipRect`, and the originating
 * `mouseEvent`.
 *
 * @param {Object} targetRect
 * @param {Object} tooltipRect
 * @param {MouseEvent} mouseEvent
 * @return {boolean}
 **/


export const shouldTooltipClose = (targetRect, tooltipRect, mouseEvent) => {
  if (isCursorOver(tooltipRect, mouseEvent)) return false;
  const bufferedTarget = addBufferToRect(targetRect, tooltipRect, TOOLTIP_BUFFER_SIZE);
  if (isCursorOver(bufferedTarget, mouseEvent)) return false;
  return true;
};