import enviro from 'enviro';
import { TRANSLATABLE_FIELD_ATTRIBUTES, PROTECTED_DEFAULT_PROPERTY_FIELDS, SPECIAL_DEFAULT_CHILD_PROPERTIES, BLOG_CONTENT_QA_MODULE_ID, BLOG_CONTENT_PROD_MODULE_ID, LOCALE_REGEX } from 'ContentUtils/constants/Translations';
import * as FieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
import * as FieldAttributes from 'ContentUtils/constants/CustomWidgetFieldAttributes';

function getTranslatedString(key, translations, fallback) {
  if (translations) {
    const translation = translations[key];

    if (translation && translation.message) {
      return translation.message;
    }
  }

  return fallback;
}

function mergeTranslations(field, fieldTranslations, contentTranslations, initialKey) {
  const {
    name,
    type
  } = field;
  const flatKey = initialKey ? `${initialKey}.${name}` : name;
  return Object.keys(field).reduce((_module, modulePropKey) => {
    const modulePropertyKey = modulePropKey;
    const modulePropertyValue = field[modulePropertyKey];
    /* Field group */

    if (type === FieldTypes.GROUP && modulePropertyKey === FieldAttributes.CHILDREN) {
      const fieldGroupChildren = modulePropertyValue;
      _module[modulePropertyKey] = fieldGroupChildren.map(_field => {
        return mergeTranslations(_field, fieldTranslations, contentTranslations, flatKey);
      });
      return _module;
    }

    const isProtectedDefaultField = modulePropertyKey === FieldAttributes.DEFAULT && PROTECTED_DEFAULT_PROPERTY_FIELDS[type];

    if (!TRANSLATABLE_FIELD_ATTRIBUTES[modulePropertyKey] || isProtectedDefaultField) {
      return _module;
    }
    /* Choice (Never translate choice content, only labels)*/


    if (modulePropertyKey === FieldAttributes.CHOICES) {
      const choices = modulePropertyValue;
      _module[modulePropertyKey] = choices.map(choice => {
        const [choiceValue, choiceLabel, choiceLabelProperties] = choice;
        const translationKey = `${flatKey}.choices.${choiceValue}`;
        return [choiceValue, getTranslatedString(translationKey, fieldTranslations, choiceLabel), choiceLabelProperties];
      });
      return _module;
    }
    /* Don't translate content unless content translations exists */


    if (contentTranslations && modulePropertyKey === FieldAttributes.DEFAULT) {
      const translatableValues = SPECIAL_DEFAULT_CHILD_PROPERTIES[type];
      /* Default is an object */

      if (modulePropertyValue && typeof modulePropertyValue === 'object' && translatableValues) {
        /* Make sure you return the value as an object and merge into the original */
        _module[modulePropertyKey] = Object.keys(translatableValues).reduce((hash, property) => {
          const translationKey = `${flatKey}.${modulePropertyKey}.${property}`;
          hash[property] = getTranslatedString(translationKey, contentTranslations, modulePropertyValue[modulePropertyKey]);
          return hash;
        }, Object.assign({}, modulePropertyValue));
        return _module;
      }

      const translationKey = `${flatKey}.${modulePropertyKey}`;
      _module[modulePropertyKey] = getTranslatedString(translationKey, contentTranslations, modulePropertyValue);
      return _module;
    }
    /* Don't translate anything else unless field translations exists */


    if (fieldTranslations && modulePropertyKey !== FieldAttributes.DEFAULT) {
      const translationKey = `${flatKey}.${modulePropertyKey}`;
      _module[modulePropertyKey] = getTranslatedString(translationKey, fieldTranslations, modulePropertyValue);
      return _module;
    }

    return _module;
  }, Object.assign({}, field));
}

export function getTranslatedFields(fields, fieldTranslations, contentTranslations) {
  if (!fieldTranslations && !contentTranslations) return fields;
  return fields.map(field => {
    return mergeTranslations(field, fieldTranslations, contentTranslations);
  });
}
export function getTranslatedBlogContentFields(fields, fieldTranslations, contentTranslations) {
  if (!fieldTranslations && !contentTranslations) return fields;
  const initiallyTranslatedFields = getTranslatedFields(fields, fieldTranslations, contentTranslations);
  const [choiceField, blogPostField, blogListingField] = initiallyTranslatedFields;
  return [choiceField, Object.assign({}, blogPostField, {
    [FieldAttributes.DEFAULT]: getTranslatedString(`hubl_field_translation.${blogPostField.name}`, contentTranslations, blogPostField[FieldAttributes.DEFAULT])
  }), Object.assign({}, blogListingField, {
    [FieldAttributes.DEFAULT]: getTranslatedString(`hubl_field_translation.${blogListingField.name}`, contentTranslations, blogListingField[FieldAttributes.DEFAULT])
  })];
}
export function getIsBlogContentModule(moduleId) {
  if (enviro.isQa()) {
    return BLOG_CONTENT_QA_MODULE_ID === moduleId;
  }

  return BLOG_CONTENT_PROD_MODULE_ID === moduleId;
}

const isCodeWithLocale = langCode => {
  return !!LOCALE_REGEX.exec(langCode);
};

export const getTranslation = (messages, langCode) => {
  if (!messages || !Object.keys(messages).length || !langCode) {
    return null;
  }

  let translations = messages[langCode];

  if (!translations && isCodeWithLocale(langCode)) {
    translations = messages[langCode.slice(0, 2)];
  }

  if (!translations) {
    const partiallyMatchingLangCode = Object.keys(messages).find(_langCode => {
      return langCode.slice(0, 2) === _langCode.slice(0, 2);
    });
    if (partiallyMatchingLangCode) return messages[partiallyMatchingLangCode];
  }

  return translations || null;
};
export const getTranslatedModuleInlineHelpText = (inlineHelpText, primaryLang, currentLang, messages) => {
  if (primaryLang === currentLang) return inlineHelpText;
  const translations = getTranslation(messages, currentLang);
  return getTranslatedString(FieldAttributes.INLINE_HELP_TEXT, translations, inlineHelpText) || inlineHelpText;
};