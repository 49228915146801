import { useCallback, useEffect, useRef, useState } from 'react';
import useEmbeddedComments from './useEmbeddedComments';
import { MSG_TYPE_CONFIRM_DELETE_COMMENT, MSG_TYPE_OPENED_MINI_COMMENTER, MSG_TYPE_REMOVE_EMBEDDED_COMMENT } from '../../../constants/IFrameMessageTypes';
export default function useEmbeddedCommentsMessaging({
  moduleId,
  collaborationSidebarState,
  onUpdateCollaborationSidebarState,
  onRemoveComment,
  onClose,
  userInfo,
  isOpen
}) {
  const [isMessageHandlerReady, setIsMessageHandlerReady] = useState(false);
  const sendMessageRef = useRef();
  const {
    broadcastCommentsMessage
  } = useEmbeddedComments({
    moduleId,
    collaborationSidebarState,
    onUpdateCollaborationSidebarState,
    onRemoveComment,
    userInfo
  }); // Broadcast a message to preload the collaboration sidebar
  // as we use it for controlling the delete comment modal.

  useEffect(() => {
    if (isOpen) {
      broadcastCommentsMessage({
        type: MSG_TYPE_OPENED_MINI_COMMENTER
      });
    }
  }, [broadcastCommentsMessage, isOpen]);
  const handleBroadcastMessagesFromSidebar = useCallback(message => {
    const broadcastPayload = message.payload;

    if (message.type === MSG_TYPE_REMOVE_EMBEDDED_COMMENT && broadcastPayload.moduleId === moduleId && onClose) {
      onClose();
    }
  }, [onClose, moduleId]); // Relay a broadcast message

  useEffect(() => {
    if (collaborationSidebarState.broadcastCommentMessage && sendMessageRef.current) {
      const message = collaborationSidebarState.broadcastCommentMessage;
      handleBroadcastMessagesFromSidebar(message);
      sendMessageRef.current(message.type, message.payload);
    }
  }, [collaborationSidebarState.broadcastCommentMessage, handleBroadcastMessagesFromSidebar, isMessageHandlerReady]);
  const broadcastDelete = useCallback(({
    payload
  }) => {
    if (payload.type === MSG_TYPE_CONFIRM_DELETE_COMMENT && collaborationSidebarState.broadcastCommentMessage) {
      broadcastCommentsMessage(payload);
    }
  }, [broadcastCommentsMessage, collaborationSidebarState.broadcastCommentMessage]);
  const handleReady = useCallback(({
    sendMessage
  }) => {
    sendMessageRef.current = sendMessage;
    setIsMessageHandlerReady(true);
  }, [sendMessageRef]);
  return {
    handleReady,
    broadcastDelete
  };
}