'use es6';

import http from 'hub-http/clients/apiClient';
import { refreshCookie } from 'hub-http/auth';
import PortalIdParser from 'PortalIdParser';
export const startTrial = ({
  trialId,
  endDate,
  trialSource,
  existingUser = true,
  isWebsiteTrial = false
}) => {
  // Remove Zorse ISC chat eligibility cache, so users get the right version of the Zorse widget immediately upon trial activation
  try {
    localStorage.removeItem('hubspot:isc:userIsEligibleForIscChat');
  } catch (e) {// Noop
  }

  return http.post(`/monetization/v3/trials/start?trialId=${trialId}&endDate=${endDate}&trialSource=${trialSource}&existingUser=${existingUser}&isWebsiteTrial=${isWebsiteTrial}`).then(() => new Promise(resolve => setTimeout(resolve, 3000))) // Timeout for 3 secs to allow parcels to provision before invalidating cache/refreshing cookie. This is a stopgap until Mon BE can modify the trial activation endpoint to wait to return success until the scopes are properly provisioned. Once that change is made, we can remove the timeout logic from here
  .then(() => http.delete('/navconfig/v2/navconfig/cache')) // Reset the nav cache so users can see updated nav options immediately
  .then(() => refreshCookie(PortalIdParser.get(), true)); // Refresh cookie so /login-verify/hub-user-info starts returning new scopes immediately
};