import produce from 'immer';
import { FETCH_ACCOUNT_VERIFICATION_STATUS_SUCCEEDED } from '../actions/actionTypes';

const accountVerificationStatusReducer = (state = {}, action) => produce(state, draft => {
  const type = action.type;

  switch (type) {
    case FETCH_ACCOUNT_VERIFICATION_STATUS_SUCCEEDED:
      {
        return action.payload;
      }

    default:
      return draft;
  }
});

export default accountVerificationStatusReducer;