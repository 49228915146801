import { removeDomainFromTitle, removeTitleFromMetaDescription, addMetaDescription, addPageTitle, shortenMetaDescription, shortenPageTitle, openImageModule // @ts-expect-error file is untyped JS
} from 'ContentEditorUI/redux/actions/recommenderActions';
export const defaultXrayEditorApi = {
  addMetaDescription,
  addPageTitle,
  removeDomainFromTitle,
  removeTitleFromMetaDescription,
  openImageModule,
  shortenMetaDescription,
  shortenPageTitle
};