import { getElementModuleId } from './utils';

const getGlobalResourcePath = img => {
  const globalWrapper = img.closest('[data-global-resource-path]');
  return globalWrapper && globalWrapper.dataset.globalResourcePath;
};

export const getImages = ({
  userDOM
}) => {
  const images = userDOM.querySelectorAll('img');
  const normalizedImages = images && [...images].map(img => {
    const {
      src,
      alt,
      role,
      className
    } = img;
    const moduleId = getElementModuleId(img);
    const globalResourcePath = getGlobalResourcePath(img);
    return {
      className,
      src,
      alt,
      role,
      moduleId,
      globalResourcePath
    };
  });
  return normalizedImages;
};