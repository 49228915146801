'use es6';

import EditorConfigSingleton from 'ContentEditorUI/EditorConfigSingleton';
import { FEATURE_FLAGS } from '../constants';
const EMPTY_OBJECT = Object.freeze({}); // Implentation for "redux-y" selectors for editorConfigSingleton (NOTE: These are not actually based on redux data though)
// This is more for creating a similar pattern as Redux
// To try to establish some convetion, try to use these "Redux-y" selectors as much as possible
// and only default back to using the EditorConfigSingleton directly if absolutely necessary (i.e inside a reducer or plain JS class)

export const getFeatureFlag = flag => {
  return EditorConfigSingleton.getFeatureFlagOrDefault(flag);
};
export const getIsOnScalableEditor = () => EditorConfigSingleton.getIsOnScalableEditor();
export const getHideLockedEditFormFields = state => {
  const hideLockedEditFormFieldsFlag = EditorConfigSingleton.getFeatureFlagOrDefault(FEATURE_FLAGS.hideLockedEditFormFields, false);

  if (typeof hideLockedEditFormFieldsFlag === 'boolean') {
    return hideLockedEditFormFieldsFlag;
  }

  if (typeof hideLockedEditFormFieldsFlag !== 'object') {
    throw new Error(`Expected feature flag hideLockedEditFormFields to either be a boolean or an object, instead received a type of ${typeof hideLockedEditFormFieldsFlag}`);
  }

  const getHideLockedEditFormFieldsCallback = hideLockedEditFormFieldsFlag.getHideLockedEditFormFields;

  if (!getHideLockedEditFormFieldsCallback) {
    throw new Error('Expected key getHideLockedEditFormFields on the hideLockedEditFormFields feature flag configuration');
  }

  return getHideLockedEditFormFieldsCallback(state);
};
export const getCanUseSmartContentFeatureFlag = (state, moduleAsJS) => {
  const smartContentFlag = EditorConfigSingleton.getFeatureFlagOrDefault(FEATURE_FLAGS.smartContent, false); // If it's just a boolean, return the boolean

  if (typeof smartContentFlag === 'boolean') {
    return smartContentFlag;
  } // Otherwise, make sure it's a configured object


  if (typeof smartContentFlag !== 'object') {
    throw new Error(`Expected feature flag smartContent to either be a boolean or an object, instead received a type of ${typeof smartContentFlag}`);
  }

  const getModuleCanUseSmartContent = smartContentFlag.getModuleCanUseSmartContent;

  if (!getModuleCanUseSmartContent) {
    throw new Error('Expected key getModuleCanUseSmartContent on the smartContent feature flag configuration');
  }

  return getModuleCanUseSmartContent(state, moduleAsJS);
};
export const getExtraSmartFieldsPropsFromFeatureFlag = (state, moduleAsJS) => {
  const smartContentFlag = EditorConfigSingleton.getFeatureFlagOrDefault(FEATURE_FLAGS.smartContent, false);

  if (typeof smartContentFlag === 'boolean') {
    return EMPTY_OBJECT;
  } // Otherwise, make sure it's a configured object


  if (typeof smartContentFlag !== 'object') {
    throw new Error(`Expected feature flag smartContent to either be a boolean or an object, instead received a type of ${typeof smartContentFlag}`);
  }

  const getExtraSmartFieldPropsCallback = smartContentFlag.getExtraSmartFieldsProps;

  if (!getExtraSmartFieldPropsCallback) {
    throw new Error('Expected key getExtraSmartFieldsProps on the smartContent feature flag configuration');
  }

  return getExtraSmartFieldPropsCallback(state, moduleAsJS);
};