import { UserAttributesClient } from 'frontend-preferences-client';
const userAttributesClient = UserAttributesClient.forCaller('collaboration-sidebar');
export function fetchUserAttributes(attributes) {
  const keysObj = attributes.reduce((acc, attribute) => {
    acc[attribute.key] = attribute.defaultValue || ''; // setting empty string as the fallback default value

    return acc;
  }, {});
  return userAttributesClient.batchFetch(keysObj);
}
export async function setUserAttribute(attribute, value) {
  await userAttributesClient.write(attribute, value);
  return {
    key: attribute,
    value
  };
}