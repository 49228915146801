'use es6';

export const shouldShowSchema = (schema, searchQuery) => {
  const schemaLabel = schema.displayName || schema.label;
  const schemaLabelToTest = schemaLabel.toLowerCase();

  if (searchQuery !== '') {
    return schemaLabelToTest.indexOf(searchQuery) !== -1;
  }

  return true;
};