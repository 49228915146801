import { createGenericFetchAction } from 'ContentData/helpers/reduxHelpers';
import * as meetingsClient from 'ContentData/api/Meetings';
import { getMeetingCache } from 'ContentData/selectors/meetings';
import * as hublets from 'hubspot-url-utils/hublets';
import { na1 } from 'hubspot-url-utils/hublets';
import { MEETINGS_CLEAR_MEETINGS, MEETINGS_FETCH_MEETING, MEETINGS_FETCH_MEETINGS, MEETINGS_SAVE_MEETING_CACHE } from 'ContentData/actions/ActionTypes';

const sanitizePathname = pathname => {
  const lastIndex = pathname.length - 1;

  if (pathname.charAt(lastIndex) === '/') {
    return pathname.substring(0, lastIndex);
  }

  return pathname;
};

const getSlug = url => {
  /* from https://git.hubteam.com/HubSpot/MeetingsUI/blob/master/MeetingsPublic/static/js/app.js#L15 */
  if (url.indexOf(`/meetings/`) > -1) {
    return sanitizePathname(url).split('/meetings/')[1];
  }

  const availableHublets = Object.values(hublets);

  for (let i = 0; i < availableHublets.length; i++) {
    const hublet = availableHublets[i];
    const hubletString = hublet === na1 ? '' : `-${hublet}`;
    const meetingUrlQA = `meetings${hubletString}.hubspotqa.com`;

    if (url.indexOf(meetingUrlQA) > -1) {
      return sanitizePathname(url).split(`${meetingUrlQA}/`)[1];
    }

    const meetingUrlProd = `meetings${hubletString}.hubspot.com`;

    if (url.indexOf(meetingUrlProd) > -1) {
      return sanitizePathname(url).split(`${meetingUrlProd}/`)[1];
    }
  }

  return sanitizePathname(url).slice(1);
};

const fetchMeetingAction = createGenericFetchAction(MEETINGS_FETCH_MEETING, meetingsClient.fetchMeeting);
const fetchMeetingsAction = createGenericFetchAction(MEETINGS_FETCH_MEETINGS, meetingsClient.fetchMeetings);
export const fetchMeeting = link => {
  return fetchMeetingAction(encodeURIComponent(getSlug(link)));
};
export const fetchMeetings = (query = {}) => {
  return fetchMeetingsAction(query);
};
export function clearMeetings() {
  return {
    type: MEETINGS_CLEAR_MEETINGS
  };
}
export const saveCache = link => {
  return (dispatch, getState) => {
    const meeting = getMeetingCache(getState(), {
      link
    });
    dispatch({
      type: MEETINGS_SAVE_MEETING_CACHE,
      meeting
    });
  };
};