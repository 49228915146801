'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import Cell from '../CellAndRowsTree/Cell';
import { ensureIntField, hasAnyLayoutStyleData } from './helpers';
import { CELL_TYPE } from './constants';
import { COLUMN_CLASSNAME, CUSTOM_CSS_PARAM_NAMES } from '../constants';
export default class LayoutDataCell extends Cell {
  constructor(treeRef, {
    name,
    rowNames = [],
    parentRowName,
    value
  }) {
    value = value || {};

    if (value.width == null) {
      value.width = treeRef.getNumberColumnsInGrid();
    } else {
      ensureIntField(value, 'width');
    } // TODO omit legacy fields? (like https://git.hubteam.com/HubSpot/DesignManagerUI/blob/543ba54528c37ed86f86cdaa7644703b7698bc73/DesignData/static/js/models/layouts/LayoutWidget.js#L72-L74)
    // TODO need to so something like makeGetCssClassData? (https://git.hubteam.com/HubSpot/DesignManagerUI/blob/543ba54528c37ed86f86cdaa7644703b7698bc73/DesignData/static/js/models/layouts/LayoutWidget.js#L78)


    super(treeRef, {
      name,
      rowNames,
      parentRowName,
      value
    });
  }

  createFullClone(_ref, {
    stripSmartContentData = true
  } = {}) {
    let {
      value
    } = _ref,
        rest = _objectWithoutPropertiesLoose(_ref, ["value"]);

    // we're stripping smart-content when cloning to unblock smart-content from layout sections
    // the alternative is to:
    // 1) allow the clone here and add a flag to keep track of it
    // 2) reactor the editors to understand that they cannot save until the smart-content is truly cloned
    // 3) modify smart-content-lib to allow in-place cloning
    // 4) probably more stuff
    value = value != null ? value : this._value;

    if (stripSmartContentData && value && value.params && value.params.definition_id) {
      const _value$params = value.params,
            paramsWithoutSmartContent = _objectWithoutPropertiesLoose(_value$params, ["definition_id", "smart_objects", "smart_type"]);

      const dumbValue = Object.assign({}, value, {
        params: paramsWithoutSmartContent
      });
      return super.cloneAndChange(Object.assign({
        value: dumbValue
      }, rest));
    }

    return super.cloneAndChange(Object.assign({
      value
    }, rest));
  } // New methods


  getWidth() {
    return this.getValue().width;
  }

  hasParams() {
    return this.hasValue() && this.getParams() != null;
  }

  getParams() {
    return this.getValue().params;
  }

  isModule() {
    // Any cell on the leaf that is not a module group
    return this.isLeaf() && this.hasValue() && this.getValue().type !== CELL_TYPE;
  }

  hasSmartContent() {
    return this.hasParams() && this.getParams().smart_objects && this.getParams().smart_objects.length > 0 || this.getRows().some(c => c.hasSmartContent());
  }

  getAllCustomCssParams() {
    const result = {};

    if (this.hasParams()) {
      const params = this.getParams();
      CUSTOM_CSS_PARAM_NAMES.forEach(cssParamKey => {
        const cssParam = params[cssParamKey];

        if (cssParam != null && cssParam !== '') {
          result[cssParamKey] = cssParam;
        }
      }); // Remove the standard assumed "dnd-column" classname from the list

      if (result.css_class === COLUMN_CLASSNAME) {
        delete result.css_class;
      }
    }

    return result;
  }

  hasAnyCustomCssParams() {
    const cssParams = this.getAllCustomCssParams();
    return cssParams && Object.keys(cssParams).length > 0;
  }

  getLayoutStyleData() {
    if (this.hasLayoutStyles()) {
      return this.getValue().styles;
    }

    return null;
  }

  hasLayoutStyles() {
    return this.hasValue() && !!this.getValue().styles && Object.keys(this.getValue().styles).length > 0;
  }

  shouldAutoDeleteWhenEmpty() {
    // Don't auto delete any parents that have custom styling
    return !this.hasAnyCustomCssParams() && !hasAnyLayoutStyleData(this);
  }

  shouldSplitInsteadOfInsertingRow() {
    // Don't allow modules to contain rows
    return this.isModule();
  }

  isOnlyModuleInWrapperColumn() {
    if (this.isModule()) {
      const hasNoModuleOrColumnSiblings = this.getParent().getNumberColumns() === 1; // Ensure this is a module in a _wrapper_ column in a section, not a module with no wrapper column directly in a section

      const grandParentIsNotTheRoot = !this.getParent().getParent().isRoot();
      const grandParentHasASingleRow = this.getParent().getParent().getNumberRows() === 1;
      return hasNoModuleOrColumnSiblings && grandParentIsNotTheRoot && grandParentHasASingleRow;
    }

    return false;
  }

}