'use es6';

import keyMirror from 'react-utils/keyMirror';
import ContentRoutes from 'ContentUtils/Routes';
export const WorkflowType = keyMirror({
  FORMS: null,
  ADS: null,
  WORKFLOWS: null
});
export const editContentRoute = {
  [WorkflowType.WORKFLOWS]: workflow => {
    const {
      flowId,
      isEnabled,
      flowSource
    } = workflow;

    if (isEnabled) {
      return ContentRoutes.workflowPerformance(flowId);
    }

    return ContentRoutes.workflows(flowId, flowSource);
  },
  [WorkflowType.FORMS]: form => {
    const {
      guid
    } = form;
    return ContentRoutes.forms(guid);
  }
};
export const idKeyByWorkflowType = {
  [WorkflowType.WORKFLOWS]: 'flowId',
  [WorkflowType.FORMS]: 'guid'
};
export const EMAIL_SOURCE_APP_ID = 'MARKETING_EMAIL_AUTOMATED';
export const SOURCE_APP_IDS_FOR_TRACKING = {
  email: EMAIL_SOURCE_APP_ID
};
export const SIMPLE_AUTOMATION_EMAIL_WORKFLOW_TRIGGERS = ['BOUNCE', 'CLICK', 'SPAMREPORT', 'OPEN', 'DELIVERED', 'REPLIED', 'SENT', 'UNSUBSCRIBED' // 'OPEN_NOCLICK',
// 'OPEN_NOREPLY',
// 'DELIVERED_NOOPEN',
// 'SENT_NODELIVERED',
];