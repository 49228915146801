import enviro from 'enviro';
import { parse } from 'hub-http/helpers/params';
import { getUserInfo } from 'ui-addon-upgrades/_core/common/api/getUserInfo';
import { BILLING, MARKETABLE_CONTACTS_WRITE, TEAM } from 'ui-addon-upgrades/_core/common/constants/AdminTypes';
import logError from 'ui-addon-upgrades/_core/common/reliability/logError';
const isAdminQueryParam = parse(window.location.search.substring(1))['isAdmin'];
const HAS_ADMIN_OVERRIDE = enviro.isQa() && isAdminQueryParam === 'true';
const HAS_NOT_ADMIN_OVERRIDE = enviro.isQa() && isAdminQueryParam === 'false';
export const isAdmin = (user, adminType = TEAM) => {
  if (HAS_ADMIN_OVERRIDE) {
    return true;
  } else if (HAS_NOT_ADMIN_OVERRIDE) {
    return false;
  }

  if (adminType === BILLING) {
    const isBillingAdmin = user.scopes.indexOf('billing-write') !== -1;
    return isBillingAdmin;
  }

  if (adminType === MARKETABLE_CONTACTS_WRITE) {
    const hasMCWrite = user.scopes.indexOf('marketable-contacts-write') !== -1;
    return hasMCWrite;
  }

  const isHubOwner = user.scopes.indexOf('hub-owner') !== -1;
  const isJitaAdmin = user.scopes.indexOf('hub-parcel-write') !== -1;
  return isHubOwner || isJitaAdmin;
};
export const getIsAdmin = adminType => {
  return getUserInfo().then(({
    user
  }) => isAdmin(user, adminType)).catch(err => {
    logError('getIsAdmin', err);
    return false;
  });
};