const OVERLAY_PATH = 'M0,0H99999V99999H0Z';
export class PulserOverlay {
  constructor() {
    this.overlayPath = {
      start: OVERLAY_PATH,
      end: OVERLAY_PATH
    };
  }

  generateOverlayPath(pulserPath, oldPulserPath) {
    const {
      start,
      end
    } = this.overlayPath;
    const newOverlayPath = {
      start,
      end
    }; // Remove old spotlight path from overlay

    if (oldPulserPath && start.includes(oldPulserPath.start)) {
      newOverlayPath.start = start.replace(oldPulserPath.start, '');

      if (oldPulserPath.end) {
        newOverlayPath.end = end.replace(oldPulserPath.end, '');
      }
    } // Add new spotlight path to overlay


    if (!start.includes(pulserPath.start)) {
      newOverlayPath.start += pulserPath.start;

      if (pulserPath.end) {
        newOverlayPath.end += pulserPath.end;
      }
    }

    this.overlayPath = newOverlayPath;
  }

}