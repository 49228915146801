import { useCallback, useReducer } from 'react';
import taskDeleteReducer, { initialState } from 'collaboration-sidebar/reducers/taskDelete';
import { MSG_TYPE_CONFIRM_DELETE_TASK, MSG_TYPE_DELETE_TASK, MSG_TYPE_REQUEST_CLOSE_DELETE_TASK_MODAL, MSG_TYPE_REQUEST_OPEN_DELETE_TASK_MODAL } from 'collaboration-sidebar/constants/IFrameMessageTypes';
export default function useTaskDeleteModal({
  sendMessageRef,
  isSendMessageReady
}) {
  const [state, dispatch] = useReducer(taskDeleteReducer, initialState);
  const {
    show,
    taskId
  } = state;
  const handleMessage = useCallback( // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
  ({
    payload
  }) => dispatch(payload), []);
  const onClose = useCallback(() => {
    if (show) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      dispatch({
        type: MSG_TYPE_REQUEST_CLOSE_DELETE_TASK_MODAL
      });
    }
  }, [show]);
  const onDelete = useCallback(() => {
    if (show && isSendMessageReady && sendMessageRef.current) {
      sendMessageRef.current(MSG_TYPE_DELETE_TASK, {
        taskId
      }); // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.

      dispatch({
        type: MSG_TYPE_CONFIRM_DELETE_TASK
      });
    } else {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      dispatch({
        type: MSG_TYPE_REQUEST_OPEN_DELETE_TASK_MODAL
      });
    }
  }, [sendMessageRef, show, taskId, isSendMessageReady]);
  return Object.assign({}, state, {
    handleMessage,
    onClose,
    onDelete
  });
}