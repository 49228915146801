'use es6';

import { ALL_TOKEN_PATTERN, PROPERTY_ONLY_TOKEN_PATTERN, TOKEN_PATTERN_WITH_DEFAULT, OWNER_CATEGORY, SITE_SETTINGS_CATEGORY, FILTER_PATTERN_FRAGMENT, hsPersonalizeConfig } from '../constants/hspersonalize';

function escapeRegex(string) {
  return string.replace(new RegExp(/[[]/g), '\\$&');
}

const escapeParentheses = token => {
  return token.replace(new RegExp(/\(([^()]*)\)/g), (__wrapper, el) => `\\(${el}\\)`);
};

const getTokenCategoriesFromConfig = settings => {
  const tokens = [OWNER_CATEGORY];
  const supportedCrmObjectFQNs = settings[hsPersonalizeConfig.SUPPORTED_CRM_OBJECT_FQNS];

  if (Array.isArray(supportedCrmObjectFQNs)) {
    tokens.push(...supportedCrmObjectFQNs.map(fqn => {
      const escapedFQN = escapeParentheses(fqn);

      if (escapedFQN === escapedFQN.toLowerCase()) {
        return escapedFQN;
      } // Return token pattern for both case-sensitive FQN and lowercased version


      return `${escapedFQN}|${escapedFQN.toLowerCase()}`;
    }));
  }

  const shouldSupportSiteSettings = !settings[hsPersonalizeConfig.HIDE_SITE_SETTINGS_CATEGORY];

  if (shouldSupportSiteSettings) {
    tokens.push(SITE_SETTINGS_CATEGORY);
  }

  return tokens.filter((value, index, self) => {
    return self.indexOf(value) === index;
  }).sort();
};

const getPrefixesFromConfig = settings => {
  const customObjectPrefixes = settings[hsPersonalizeConfig.CUSTOM_OBJECT_PREFIXES];
  if (typeof customObjectPrefixes !== 'object') return [];
  return Object.values(customObjectPrefixes).map(prefix => escapeRegex(prefix));
};

const getHasFilterSupport = settings => {
  const propertyFiltersCallback = settings[hsPersonalizeConfig.PROPERTY_FILTERS_CALLBACK];
  return !!propertyFiltersCallback;
};

export const getTokenPattern = settings => {
  if (!settings) return ALL_TOKEN_PATTERN;
  const tokenPatterns = getTokenCategoriesFromConfig(settings).join('|');
  const prefixes = getPrefixesFromConfig(settings).join('|');
  const hasFilterSupport = getHasFilterSupport(settings);
  return new RegExp(`{{\\s*(${tokenPatterns}${prefixes.length !== 0 ? `|${prefixes}` : ''})\\.(\\w+)(?:\\.(\\w+))?\\s*${hasFilterSupport ? FILTER_PATTERN_FRAGMENT : ''}}}`, 'g');
};
export const getPropertyOnlyTokenPattern = settings => {
  if (!settings) return PROPERTY_ONLY_TOKEN_PATTERN;
  const prefixes = getPrefixesFromConfig(settings).join('|');
  return new RegExp(`{{\\s*(subscription_name${prefixes.length !== 0 ? `|${prefixes}` : ''})?\\s*}}`, 'g');
};
export const getTokenPatternWithDefault = settings => {
  if (!settings) return TOKEN_PATTERN_WITH_DEFAULT;
  const tokenPatterns = getTokenCategoriesFromConfig(settings).join('|');
  const prefixes = getPrefixesFromConfig(settings).join('|');
  const hasFilterSupport = getHasFilterSupport(settings);
  return new RegExp(`{{\\s*personalization_token\\(\\s*["']{1}(${tokenPatterns}${prefixes.length !== 0 ? `|${prefixes}` : ''})\\.(\\w+)(?:\\.(\\w+))?["']{1}\\s*,\\s*["']{1}(.*?)["']{1}\\s*\\)\\s*${hasFilterSupport ? FILTER_PATTERN_FRAGMENT : ''}}}`, 'g');
};
export const getDefaultValueType = defaultValue => {
  if (defaultValue === '') {
    return 'empty';
  } else if (defaultValue === null) {
    return 'global';
  } else {
    return 'local';
  }
};