'use es6';

const TEST_FLAG_PREFIX = 'testFlags=';
const TEST_FLAGS_PATTERN = /\btestFlags=([\w-:=,]+)/;

const parseFlagNameAndValue = flagString => {
  const flagTokens = flagString.split(/[:=]/);
  const [name] = flagTokens;

  if (flagTokens.length > 1) {
    return flagTokens;
  } else {
    return [name, true];
  }
};

const extractTestFlagObjectFromHashString = hashString => {
  const flagObj = {};

  if (hashString) {
    const matches = hashString.match(TEST_FLAGS_PATTERN);

    if (matches && matches[1]) {
      const rawFlagArray = matches[1].split(',').filter(t => t !== '');
      rawFlagArray.forEach(rawFlag => {
        const [name, value] = parseFlagNameAndValue(rawFlag);
        flagObj[name] = value;
      });
    }
  }

  return flagObj;
};

export const createTestFlagInstance = ({
  customHashFunc
} = {}) => {
  const getHash = () => // For tests, easier than mocking location.hash
  customHashFunc ? customHashFunc() : window.location.hash;

  const getTestFlags = () => {
    return Object.keys(extractTestFlagObjectFromHashString(getHash()));
  };

  const getTestFlagValuesObj = () => {
    return extractTestFlagObjectFromHashString(getHash());
  };

  const getTestFlagValue = name => {
    return getTestFlagValuesObj()[name];
  };

  const hasAnyTestFlags = () => {
    return getTestFlags().length > 0;
  };

  const hasTestFlag = flagToCheck => {
    return getTestFlags().includes(flagToCheck);
  };

  const hasTestFlagValue = name => {
    return getTestFlagValue(name) != null;
  };

  return {
    hasAnyTestFlags,
    getTestFlags,
    getTestFlagValuesObj,
    hasTestFlag,
    hasTestFlagValue
  };
};
const PERMANENT_TEST_FLAG_LOCALSTORAGE_KEY = 'PERMANENT_CEUI_TEST_FLAGS';

const getPermanentTestFlags = () => {
  try {
    const flagObjString = window.localStorage.getItem(PERMANENT_TEST_FLAG_LOCALSTORAGE_KEY);

    if (flagObjString) {
      const permFlags = JSON.parse(flagObjString);
      return permFlags;
    }
  } catch (error) {
    console.warn('Error getting permanent test flags', error);
  }

  return {};
};

const hasAnyPermanentTestFlags = () => {
  return Object.keys(getPermanentTestFlags()).length > 0;
};

const addTestFlagStringToHashString = (hash, testFlagString) => {
  if (hash.includes(TEST_FLAG_PREFIX)) {
    if (hash.indexOf(testFlagString) >= hash.indexOf(TEST_FLAG_PREFIX) + TEST_FLAG_PREFIX.length) {
      // Flag already exists in hash
      return hash;
    } else if (hash.endsWith(TEST_FLAG_PREFIX)) {
      // Prefix exists, but no flags
      return `${TEST_FLAG_PREFIX}${testFlagString}`;
    } // Append flag


    return `${hash},${testFlagString}`;
  } else {
    // Add flag and test flag hash prefix
    return `${TEST_FLAG_PREFIX}${testFlagString}`;
  }
};

const applyPermanentTestFlagsToHash = () => {
  const permFlags = getPermanentTestFlags();
  let modifiedHash = window.location.hash;

  for (const flagName of Object.keys(permFlags)) {
    const flagValue = permFlags[flagName];
    modifiedHash = addTestFlagStringToHashString(modifiedHash, flagValue !== true ? `${flagName}:${flagValue}` : flagName);
  }

  window.location.hash = modifiedHash;
};

let SINGLETON_FOR_GLOBAL_USE;

const singletonInstance = () => {
  if (!SINGLETON_FOR_GLOBAL_USE) {
    // Apply any "permanent" test flags from localStorage
    if (hasAnyPermanentTestFlags()) {
      applyPermanentTestFlagsToHash();
    }

    SINGLETON_FOR_GLOBAL_USE = createTestFlagInstance();
  }

  return SINGLETON_FOR_GLOBAL_USE;
};

export const hasAnyTestFlags = () => {
  return singletonInstance().hasAnyTestFlags();
};
export const getTestFlags = () => {
  return singletonInstance().getTestFlags();
};
export const hasTestFlag = flagToCheck => {
  return singletonInstance().hasTestFlag(flagToCheck);
};
export const getTestFlagValuesObj = () => {
  return singletonInstance().getTestFlagValuesObj();
};
export const hasTestFlagValue = flagToCheck => {
  return singletonInstance().hasTestFlagValue(flagToCheck);
};
export const setTemporaryTestFlag = testFlagString => {
  const hash = window.location.hash;

  if (!hasTestFlag(testFlagString)) {
    window.location.hash = addTestFlagStringToHashString(hash, testFlagString);
  }
};
export const setPermanentTestFlag = testFlagString => {
  const permFlags = getPermanentTestFlags();
  const hash = window.location.hash;

  if (!hasTestFlag(testFlagString)) {
    window.location.hash = addTestFlagStringToHashString(hash, testFlagString);
  }

  const [flagName, flagValue] = parseFlagNameAndValue(testFlagString);

  if (!permFlags.hasOwnProperty(flagName)) {
    permFlags[flagName] = flagValue;

    try {
      window.localStorage.setItem(PERMANENT_TEST_FLAG_LOCALSTORAGE_KEY, JSON.stringify(permFlags));
    } catch (error) {
      console.warn('Error setting permanent test flag', error);
    }
  }
};
export const removePermanentTestFlag = testFlagString => {
  const [flagName] = parseFlagNameAndValue(testFlagString);
  const permFlags = getPermanentTestFlags();
  delete permFlags[flagName];

  if (hasTestFlag(flagName)) {
    const regex = new RegExp(`\\b${flagName}(:\\w+)?\\b,?`);
    window.location.hash = window.location.hash.replace(regex, '').replace(/,$/, '');
  }

  try {
    window.localStorage.setItem(PERMANENT_TEST_FLAG_LOCALSTORAGE_KEY, JSON.stringify(permFlags));
  } catch (error) {
    console.warn('Error setting permanent test flag', error);
  }
};