import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';

/**
 * GET designmanager/v1/menus
 * @param {number} id Menu id
 * @param [httpClient] httpClient - optional custom http client
 * @return {Promise} fetch menu by id
 */
export function fetchMenu(id, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`designmanager/v1/menus/${id}`);
}
/**
 * GET designmanager/v1/menus/by-name
 * @param {string} name Menu name
 * @param [httpClient] httpClient - optional custom http client
 * @return {Promise} fetch menu by name
 */

export function fetchMenuByName(name, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`designmanager/v1/menus/by-name/${name}`);
}
/**
 * GET designmanager/v1/menus
 * @param {object} query Query params
 * @param [httpClient] httpClient - optional custom http client
 * @returns {Promise} fetch all menus (paginated)
 */

export function fetchMenus(query, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('designmanager/v1/menus', {
    query
  });
}
/**
 * POST  /designmanager/v1/menus
 * @param {string} name Name of new menu
 * @returns {Promise} result of menu creation
 */

export function createMenu(name, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.post('designmanager/v1/menus?casing=snake_r', {
    data: {
      name
    }
  });
}