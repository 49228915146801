import { Component } from 'react';
import logError from './_core/common/reliability/logError';
import Raven from 'raven-js';

class ErrorBoundary extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      error: false
    };
  }

  static getDerivedStateFromError() {
    return {
      error: true
    };
  }

  componentDidCatch(error, info) {
    Raven.captureException(error);
    const errorForLogError = error;
    errorForLogError.info = JSON.stringify(info);
    logError(this.props.boundaryName, errorForLogError);
  }

  render() {
    if (this.state.error) {
      return null;
    }

    return this.props.children;
  }

}

export default ErrorBoundary;