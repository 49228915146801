export const DEFAULT_CACHE_TIMEOUT = 15000; // 15 second timeout

export const MAX_CACHE_TIMEOUT = 3600000; // 1 hour max timeout

/**
 * This factory creates a scoped `withApiCache` function with the api client
 * method, url generator and cache object. The consumer has the option to
 * specify a different `cacheTimeout` if desired. Any timeout at or below 0 will
 * ignore the cache and return a newly initiated promise.
 *
 * The scoped `withApiCache` function is essentially a wrapper around the http
 * client that accepts fetch-time arguments for the `urlParam`, `httpParams`
 * and `cacheOptions`, if any of them are explicitly defined. The `cacheKey`
 * defaults to the url generated for the endpoint, but the consumer can
 * override this value at fetch-time within `cacheOptions`. They can request
 * a fresh promise using the `clearCache` flag.
 */
export function createApiCache(httpRequest, getUrl, cache = {}, _cacheTimeout = DEFAULT_CACHE_TIMEOUT) {
  return function withApiCache(urlParam = '', httpParams = {}, cacheOptions = {}) {
    const url = getUrl(urlParam);

    const initiatePromise = () => httpRequest(url, httpParams);

    const {
      cacheKey: _cacheKey = '',
      clearCache = false
    } = cacheOptions;
    const cacheTimeout = Math.max(0, _cacheTimeout);

    if (_cacheKey === null || cacheTimeout === 0) {
      // Ignore the cache and return a fresh promise
      return initiatePromise();
    } else if (typeof _cacheKey !== 'string') {
      console.warn(`API call received 'cacheKey' of type '${typeof _cacheKey}' but expected type 'string'. Using default cacheKey...`);
    } // Default `cacheKey` to the endpoint url qith query params unless one was specified


    const {
      query
    } = httpParams;
    const qs = query ? `?${Object.keys(query).map(key => `${key}=${query[key]}`).join('&')}` : '';
    const urlWithParams = `${url}${qs}`;
    const cacheKey = _cacheKey || urlWithParams;
    let cachedObject = cache[cacheKey];

    if (!cachedObject) {
      // Create the cached object for this `cacheKey`
      cachedObject = {
        promise: initiatePromise(),
        stale: false,
        timeout: setTimeout(() => {
          cache[cacheKey].stale = true;
        }, cacheTimeout)
      };
      cache[cacheKey] = cachedObject;
    } else {
      if (cachedObject.stale || clearCache) {
        // Reinitiate the promise
        cachedObject.promise = initiatePromise();
      } // Reset the timeout because it means that this promise, for the
      // specified `cacheKey`, has been requested a subsequent time


      cachedObject.stale = false;
      clearTimeout(cachedObject.timeout);
      cachedObject.timeout = setTimeout(() => {
        cache[cacheKey].stale = true;
      }, cacheTimeout);
    }

    return cachedObject.promise;
  };
}