import { useSelector } from 'react-redux'; // @ts-expect-error Untyped module

import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getContentPrimaryActions, getDisabledActions } from 'ContentEditorUI/utils/contentManagementPanel/contentTableUIActions';
import { getHasSocialAccess } from 'ContentEditorUI/redux/selectors/authSelectors';
import { useContentUsageLimits } from 'ContentEditorUI/components/contentManagement/ContentUsageLimitsProvider';

const useContentManagementTableRowActions = ({
  content,
  openModal
}) => {
  const {
    getUsageLimitInfoForContentTypeId
  } = useContentUsageLimits();
  const currentlyOpenContentId = useSelector(getContentModelId);
  const hasSocialAccess = useSelector(getHasSocialAccess);
  const actions = getContentPrimaryActions({
    content,
    hasSocialAccess,
    handleOpenModal: openModal
  });
  const disabledActions = getDisabledActions({
    currentlyOpenContentId,
    content,
    getUsageLimitInfoForContentTypeId
  });
  return {
    actions,
    disabledActions
  };
};

export default useContentManagementTableRowActions;