const _createFormAttributes = (formId, portalId) => {
  return {
    formId,
    portalId
  };
};

export const PRODUCTION_PQL_FORM_ATTRIBUTES = _createFormAttributes('5ae29a72-cc99-4702-9bcc-936891469830', '53');
export const QA_PQL_FORM_ATTRIBUTES = _createFormAttributes('479b8d6d-b9b2-4941-b16a-74d2cef76778', '99609805');
export const QA_DECESION_MAKER_FORM_ATTRIBUTES = _createFormAttributes('4505c281-fbc0-4e82-afe1-363bfbe364ea', '18770');
export const PROD_DECESION_MAKER_FORM_ATTRIBUTES = _createFormAttributes('352aafba-a522-41de-ad81-8053b66e5156', '53');