function createCommentInteractionsState() {
  return {
    scrollTo: {
      moduleId: null,
      location: null
    },
    highlightModuleIdFromHover: null,
    highlightModuleIdFromClick: null
  };
}

export default createCommentInteractionsState;