export const FONT_FORMAT_LIST = Object.freeze({
  'Andale Mono': "'andale mono', times",
  Arial: 'arial, helvetica, sans-serif',
  'Book Antiqua': "'book antiqua', palatino",
  'Courier New': "'courier new', courier",
  Georgia: 'georgia, palatino',
  Helvetica: 'helvetica',
  Impact: 'impact, chicago',
  Monaco: 'monaco, monospace',
  Tahoma: 'tahoma, arial, helvetica, sans-serif',
  'Times New Roman': "'times new roman', times",
  'Trebuchet MS': "'trebuchet ms', geneva",
  Verdana: 'verdana, geneva'
});
export const UNSUPPORTED_FONTS = Object.freeze({
  Symbol: 'symbol',
  Terminal: 'terminal, monaco'
});
export const FONT_STYLES = Object.freeze({
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline'
});
export const DEFAULT_STYLES = Object.freeze({
  'text-decoration': 'none',
  'font-weight': 'normal',
  'font-style': 'normal'
});
export const FONT_SETS = Object.freeze({
  BRANDING: 'BRANDING',
  CUSTOM: 'CUSTOM',
  DEFAULT: 'DEFAULT',
  GOOGLE: 'GOOGLE'
});
export const DEFAULT_GOOGLE_FONT_VARIANT_FALLBACK_ORDER = Object.freeze(['regular', '500', '300', '600', '700', '200', '800', '900', '100', 'italic']);
export const REGULAR_GOOGLE_FONT_VARIANT = '400';
export const FONT_SIZE_UNITS = Object.freeze({
  px: 'px',
  pt: 'pt',
  em: 'em',
  rem: 'rem',
  ['%']: '%',
  ex: 'ex',
  ch: 'ch'
});