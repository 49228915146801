import { combineReducers } from 'redux';
import { createGenericRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
import { CTAS_FETCH_CTA } from 'ContentData/actions/ActionTypes';

const getCtaGuid = resource => resource && resource.guid;

const ctas = (state = {}, action) => {
  switch (action.type) {
    case CTAS_FETCH_CTA:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [getCtaGuid(response)]: response
        });
      }

    default:
      return state;
  }
};

export default combineReducers({
  ctas,
  requestStatus: createGenericRequestStatusReducer(CTAS_FETCH_CTA)
});