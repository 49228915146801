/* hs-eslint ignored failing-rules */

/* eslint-disable promise/catch-or-return */
function timeout(promise, ms, error) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      if (!error || typeof error === 'string') {
        error = new Error(error || `Timed out after ${ms} ms`);
      }

      reject(error);
    }, ms);
    promise.then(value => {
      clearTimeout(timeoutId);
      resolve(value);
    }, reason => {
      clearTimeout(timeoutId);
      reject(reason);
    });
  });
}

export default timeout;