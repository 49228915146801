import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';

/**
 * GET sales-checkout/v2/session-templates/{id}
 * @param {number} id Payment id
 * @param [httpClient] httpClient - optional custom http client
 * @returns {Promise} fetch payment by id
 */
export function fetchPayment(id, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`sales-checkout/v2/session-templates/${id}`);
}
/**
 * GET sales-checkout/v2/session-templates
 * @param {object} query Query params
 * @param [httpClient] httpClient - optional custom http client
 * @returns {Promise} fetch all payments
 */

export function fetchPayments(query, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('sales-checkout/v2/session-templates', {
    query
  });
}
/**
 * GET payments/v1/payment-accounts/eligibility
 * @param [httpClient] httpClient - optional custom http client
 * @returns {Promise} fetch payments eligibility status
 */

export function fetchPaymentEligibility(...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('payments/v1/payment-accounts/eligibility');
}