import { getAudienceAccessType } from 'ContentUtils/helpers/AudienceAccessHelpers';
import { useSelector } from 'react-redux';
import { getContentModelPassword, getContentModelPublicAccessRulesAsJS, getContentModelPublicAccessRulesEnabled // @ts-expect-error Untyped module
} from 'page-editor-ui/redux/selectors/pageContentModelSelectors';

const useAudienceAccessType = () => {
  const publicAccessRules = useSelector(getContentModelPublicAccessRulesAsJS);
  const publicAccessRulesEnabled = useSelector(getContentModelPublicAccessRulesEnabled);
  const password = useSelector(getContentModelPassword);
  const audienceAccessType = getAudienceAccessType({
    password,
    publicAccessRules,
    publicAccessRulesEnabled
  });
  return audienceAccessType;
};

export default useAudienceAccessType;