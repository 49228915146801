import { isEqual } from 'underscore';
import PortalIdParser from 'PortalIdParser';
import { ACCEPTED_SOURCE_APPS, SIMPLE_WORKFLOW_SOURCE_APP } from 'ContentData/constants/workflows';

function getFormSubmissionOnPageFilterBranch(formId, contentId) {
  return {
    filterBranchOperator: 'AND',
    filterBranchType: 'AND',
    filters: [{
      filterType: 'FORM_SUBMISSION_ON_PAGE',
      formId,
      pageId: contentId.toString()
    }],
    filterBranches: []
  };
}

function isFilterBranchEmpty(filterBranch) {
  return filterBranch.filterBranches.length === 0 || filterBranch.filterBranches.every(fb => fb.filters.length === 0);
}

function getWorkflowHybridPermissionsForFilterBranch(filterBranch, formGuid, contentId, isCmsFormModuleFieldSourceApp = false) {
  const {
    filterBranches = [],
    filters = []
  } = filterBranch || {};
  let canRemoveEnrollmentCriteria = false;
  let shouldShow = false;

  for (const filter of filters) {
    if (['FORM_SUBMISSION', 'FORM_SUBMISSION_ON_PAGE'].includes(filter.filterType) && filter.formId === formGuid) {
      if (!contentId) {
        // If viewing outside the context of a page, show all workflow dependencies
        shouldShow = true;
        break;
      } else {
        // If viewing on a certain page, only show workflows that reference the form on that page or all pages
        const {
          pageId
        } = filter;
        const pageMatches = pageId === `${contentId}`;

        if (!pageId || pageMatches) {
          shouldShow = true; // If we're on a page and the workflow has an enrollment criteria for a form submission on that
          // particular page, allow removing that enrollment criteria
          // For sourceApp=CMS_FORM_MODULE_FIELD we disable editing enrollment criteria

          if (pageMatches) {
            if (!isCmsFormModuleFieldSourceApp) {
              canRemoveEnrollmentCriteria = true;
            }

            break;
          }
        }
      }
    }
  }

  if (canRemoveEnrollmentCriteria || shouldShow) {
    return {
      canRemoveEnrollmentCriteria,
      shouldShow
    };
  }

  for (const branch of filterBranches) {
    const branchPermissions = getWorkflowHybridPermissionsForFilterBranch(branch, formGuid, contentId, isCmsFormModuleFieldSourceApp);

    if (branchPermissions.canRemoveEnrollmentCriteria || branchPermissions.shouldShow) {
      return branchPermissions;
    }
  }

  return {
    canRemoveEnrollmentCriteria,
    shouldShow
  };
}

function getWorkflowHybridPermissionsForFlowEventFilter(filterBranch, formGuid, contentId) {
  const {
    filterBranches = [],
    filters = []
  } = filterBranch || {};
  const canRemoveEnrollmentCriteria = false; // Allow to be set to true once flow event filters support "AND"

  let shouldShow = false;

  for (const filter of filters) {
    const {
      operator,
      values = []
    } = filter.operation || {};

    if (filter.filterType === 'PROPERTY' && filter.property === 'hs_form_id' && operator === 'IS_ANY_OF' && values.includes(formGuid)) {
      shouldShow = true;
      break;
    }

    if (filter.filterType === 'PROPERTY' && filter.property === 'hs_content_id' && operator === 'IS_ANY_OF' && values.includes(`${contentId}`)) {
      shouldShow = true;
      break;
    }
  }

  if (shouldShow) {
    return {
      canRemoveEnrollmentCriteria,
      shouldShow
    };
  }

  for (const branch of filterBranches) {
    const branchPermissions = getWorkflowHybridPermissionsForFlowEventFilter(branch, formGuid, contentId);

    if (branchPermissions.shouldShow) {
      return branchPermissions;
    }
  }

  return {
    canRemoveEnrollmentCriteria,
    shouldShow
  };
}

export function getWorkflowHybridPermissions(workflowHybrid, formGuid, contentId, showFollowupEmail = false) {
  const {
    associatedLists = [],
    createMetadata,
    flowEventFilters
  } = workflowHybrid || {};
  const {
    sourceApp
  } = createMetadata || {};
  let canDelete = false;
  const canRemoveEnrollmentCriteria = false;
  let shouldShow = false;

  if (showFollowupEmail && isSimpleWorkflowForForm(workflowHybrid, formGuid)) {
    shouldShow = true;
  }

  if (!ACCEPTED_SOURCE_APPS[sourceApp]) {
    return {
      canDelete,
      canRemoveEnrollmentCriteria,
      shouldShow
    };
  }

  const isCmsFormModuleFieldSourceApp = sourceApp === ACCEPTED_SOURCE_APPS.CMS_FORM_MODULE_FIELD;

  if (isCmsFormModuleFieldSourceApp) {
    canDelete = true;
  }

  if (associatedLists && associatedLists.length) {
    for (const list of associatedLists) {
      const listPermissions = getWorkflowHybridPermissionsForFilterBranch(list.filterBranch, formGuid, contentId, isCmsFormModuleFieldSourceApp);

      if (listPermissions.canRemoveEnrollmentCriteria || listPermissions.shouldShow) {
        return Object.assign({
          canDelete
        }, listPermissions);
      }
    }
  }

  if (flowEventFilters && flowEventFilters.length) {
    for (const flowEventFilter of flowEventFilters) {
      const {
        filter
      } = flowEventFilter.eventFilter || {};
      const flowEventFilterPermissions = getWorkflowHybridPermissionsForFlowEventFilter(filter, formGuid, contentId);

      if (flowEventFilterPermissions.canRemoveEnrollmentCriteria || flowEventFilterPermissions.shouldShow) {
        return Object.assign({
          canDelete
        }, flowEventFilterPermissions);
      }
    }
  }

  return {
    canDelete,
    canRemoveEnrollmentCriteria,
    shouldShow
  };
}
export function createNewWorkflowWithEnrollmentCriteria(workflowName, formId, contentId) {
  return {
    name: workflowName,
    objectTypeId: '0-1',
    uuid: `form-field-followup-${contentId}-${new Date().getTime()}`,
    associatedLists: [{
      updateType: 'CREATE',
      listTypes: ['ENROLLMENT_LIST'],
      filterBranch: {
        filterBranchOperator: 'OR',
        filterBranchType: 'OR',
        filters: [],
        filterBranches: [getFormSubmissionOnPageFilterBranch(formId, contentId)]
      }
    }],
    enrollmentTrigger: 'LIST',
    classicEnrollmentSettings: {}
  };
} // Adds a new form submission enrollment criteria to a workflow for a specific page

export function addFormEnrollmentCriteriaToWorkflow(workflowHybrid, formId, contentId) {
  if (workflowHybrid.enrollmentTrigger === 'EVENT') {
    return workflowHybrid;
  }

  const {
    associatedLists
  } = workflowHybrid;
  const enrollmentList = associatedLists.find(list => list.listTypes.includes('ENROLLMENT_LIST'));
  const enrollmentListUpdate = enrollmentList ? Object.assign({}, enrollmentList, {
    updateType: 'UPDATE',
    filterBranch: Object.assign({}, enrollmentList.filterBranch, {
      filterBranches: [...enrollmentList.filterBranch.filterBranches, getFormSubmissionOnPageFilterBranch(formId, contentId)]
    })
  }) : {
    updateType: 'CREATE',
    listTypes: ['ENROLLMENT_LIST'],
    filterBranch: {
      filterBranchOperator: 'OR',
      filterBranchType: 'OR',
      filters: [],
      filterBranches: [getFormSubmissionOnPageFilterBranch(formId, contentId)]
    }
  };
  return Object.assign({}, workflowHybrid, {
    enrollmentTrigger: 'LIST',
    associatedLists: [enrollmentListUpdate]
  });
} // Removes any form submission enrollment criteria with the given form ID and page ID

export function removeFormEnrollmentCriteriaFromWorkflow(workflowHybrid, formId, contentId) {
  if (workflowHybrid.enrollmentTrigger !== 'LIST') {
    return workflowHybrid;
  }

  const enrollmentList = workflowHybrid.associatedLists.find(list => list.listTypes.includes('ENROLLMENT_LIST'));

  if (!enrollmentList) {
    return workflowHybrid;
  }

  const updatedEnrollmentFilterBranch = Object.assign({}, enrollmentList.filterBranch, {
    filterBranches: enrollmentList.filterBranch.filterBranches.map(filterBranch => {
      return Object.assign({}, filterBranch, {
        filters: filterBranch.filters.filter(filter => !(filter.filterType === 'FORM_SUBMISSION_ON_PAGE' && filter.formId === formId && filter.pageId === contentId.toString()) && !(filter.filterType === 'FORM_SUBMISSION' && filter.formId === formId))
      });
    }).filter(filterBranch => filterBranch.filters.length)
  });

  if (isEqual(updatedEnrollmentFilterBranch, enrollmentList.filterBranch)) {
    return workflowHybrid;
  }

  return Object.assign({}, workflowHybrid, {
    associatedLists: [isFilterBranchEmpty(updatedEnrollmentFilterBranch) ? Object.assign({}, enrollmentList, {
      updateType: 'DELETE'
    }) : Object.assign({}, enrollmentList, {
      updateType: 'UPDATE',
      filterBranch: updatedEnrollmentFilterBranch
    })]
  });
} // Determines if workflow is a simple workflow attached to form specified by formId

export function isSimpleWorkflowForForm(workflowHybrid, formId) {
  const {
    createMetadata,
    isEnabled,
    uuid
  } = workflowHybrid || {};
  const {
    sourceApp
  } = createMetadata || {};
  return !!(sourceApp === SIMPLE_WORKFLOW_SOURCE_APP && isEnabled && uuid.startsWith(`${PortalIdParser.get()}:${formId}`));
}
const EMAIL_ACTION_TYPE = 'EMAIL';
export function getFollowupEmailAction(workflowHybrid) {
  const {
    actions = {}
  } = workflowHybrid || {};
  const actionId = Object.keys(actions).find(_actionId => {
    const action = actions[_actionId];
    return action && action.actionType === EMAIL_ACTION_TYPE;
  });
  return actionId ? actions[actionId] : undefined;
}