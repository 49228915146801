import { combineReducers } from 'redux';
import { createGenericRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
import { omit } from 'underscore';
import { PENDING, SUCCEEDED, FAILED } from 'ContentUtils/constants/RequestStatus';
import { WORKFLOWS_FETCH_WORKFLOWS, WORKFLOWS_CREATE_WORKFLOW, WORKFLOWS_FETCH_WORKFLOW, WORKFLOWS_FETCH_SUMMARIES, WORKFLOWS_CLEAR_SUMMARIES, FORMS_FETCH_WORKFLOW_DEPENDENCIES, FORMS_ADD_WORKFLOW_DEPENDENCY, FORMS_DELETE_WORKFLOW_DEPENDENCY } from 'ContentData/actions/ActionTypes';

const workflows = (state = {}, action) => {
  switch (action.type) {
    case WORKFLOWS_FETCH_WORKFLOWS:
      {
        const {
          response
        } = action;
        if (!response || !response.workflows) return state;
        return response.workflows.reduce((hash, flow) => {
          const workflow = hash[flow.id] || {};
          hash[flow.id] = Object.assign({}, workflow, {}, flow);
          return hash;
        }, Object.assign({}, state));
      }

    case WORKFLOWS_FETCH_WORKFLOW:
    case WORKFLOWS_CREATE_WORKFLOW:
      {
        const {
          response
        } = action;
        if (!response) return state;
        const workflow = state[response.id] || {};
        return Object.assign({}, state, {
          [response.id]: Object.assign({}, workflow, {}, response)
        });
      }

    default:
      return state;
  }
};

const hybrids = (state = {}, action) => {
  switch (action.type) {
    case FORMS_FETCH_WORKFLOW_DEPENDENCIES:
      {
        const {
          hybridsResponse
        } = action;
        if (!hybridsResponse) return state;
        return Object.assign({}, state, {}, hybridsResponse);
      }

    case FORMS_ADD_WORKFLOW_DEPENDENCY:
      {
        const {
          flowId,
          newHybrid
        } = action;
        if (!flowId || !newHybrid) return state;
        return Object.assign({}, state, {
          [flowId]: newHybrid
        });
      }

    case FORMS_DELETE_WORKFLOW_DEPENDENCY:
      {
        const {
          flowId,
          updatedHybrid,
          deleted
        } = action;
        if (!flowId) return state;

        if (updatedHybrid) {
          return Object.assign({}, state, {
            [flowId]: updatedHybrid
          });
        } else if (deleted) {
          return omit(state, [`${flowId}`]);
        }

        return state;
      }

    default:
      return state;
  }
};

const summaries = (state = [], action) => {
  switch (action.type) {
    case WORKFLOWS_CLEAR_SUMMARIES:
      {
        return [];
      }

    case WORKFLOWS_FETCH_SUMMARIES:
      {
        const {
          response
        } = action;
        if (!response || !response.results) return state;
        return state.concat(response.results);
      }

    default:
      return state;
  }
};

const hybridsRequestStatus = (state = {}, action) => {
  switch (action.type) {
    case FORMS_FETCH_WORKFLOW_DEPENDENCIES:
      {
        const {
          flowIds,
          hybridsResponse,
          error
        } = action;
        if (!flowIds) return state;
        return flowIds.reduce((acc, flowId) => {
          let requestStatus = PENDING;

          if (hybridsResponse) {
            requestStatus = hybridsResponse[flowId] ? SUCCEEDED : FAILED;
          } else if (error) {
            requestStatus = FAILED;
          }

          acc[flowId] = requestStatus;
          return acc;
        }, Object.assign({}, state));
      }

    case FORMS_ADD_WORKFLOW_DEPENDENCY:
      {
        const {
          flowId,
          newHybrid,
          error
        } = action;
        let requestStatus = PENDING;
        if (newHybrid) requestStatus = SUCCEEDED;
        if (error) requestStatus = FAILED;
        return Object.assign({}, state, {
          [flowId]: requestStatus
        });
      }

    default:
      return state;
  }
};

export default combineReducers({
  workflows,
  hybrids,
  summaries,
  requestStatus: createGenericRequestStatusReducer(WORKFLOWS_FETCH_WORKFLOW),
  hybridsRequestStatus
});