import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import http from 'hub-http/clients/apiClient';
import TourContext from 'ui-shepherd-react/contexts/TourContext';
import { getHasDismissedOneClickPublishTour } from '../redux/selectors/cmsSettingsSelectors';
import { UPDATE_HAS_DISMISSED_ONE_CLICK_PUBLISH_TOUR_CMS_SETTING_LOCALLY } from '../redux/actions/actionTypes';
import { getIsUngatedForPublishImmediatelyFlow, getPortalCreatedAt } from '../redux/selectors/authSelectors';
const ONE_CLICK_PUBLISH_FULCRUM_DATE = 1696305600000;

const useOneClickPublishTour = () => {
  const {
    tour
  } = useContext(TourContext);
  const dispatch = useDispatch();
  const hasDismissedOneClickPublishTour = useSelector(getHasDismissedOneClickPublishTour);
  const portalCreatedAt = useSelector(getPortalCreatedAt);
  const isUngatedForPublishImmediatelyFlow = useSelector(getIsUngatedForPublishImmediatelyFlow);
  const startOneClickPublishTour = useCallback(() => {
    const currentStep = tour.getStep();

    if (isUngatedForPublishImmediatelyFlow && portalCreatedAt < ONE_CLICK_PUBLISH_FULCRUM_DATE && !hasDismissedOneClickPublishTour && (!currentStep || currentStep === 'not-started')) {
      tour.start('cms-editor-one-click-publish');
    }
  }, [hasDismissedOneClickPublishTour, isUngatedForPublishImmediatelyFlow, portalCreatedAt, tour]);
  const finishOneClickPublishTour = useCallback(() => {
    tour.finish();
    dispatch({
      type: UPDATE_HAS_DISMISSED_ONE_CLICK_PUBLISH_TOUR_CMS_SETTING_LOCALLY,
      payload: true
    });
    return http.patch('cms-user-settings', {
      data: {
        hasDismissedOneClickPublishTour: true
      }
    });
  }, [dispatch, tour]);
  return {
    startOneClickPublishTour,
    finishOneClickPublishTour
  };
};

export default useOneClickPublishTour;