import { useCallback, useEffect, useRef, useState } from 'react';
import productApprovalsApi from '../data/productApprovalsApi';

const useApprovers = (approvalType, approvalAction) => {
  const isUnmounted = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [approvers, setApprovers] = useState([]);
  const fetchApprovers = useCallback(() => {
    setIsLoading(true);
    productApprovalsApi.fetchApprovers(approvalType, approvalAction).then(response => {
      if (isUnmounted.current === true) {
        return;
      }

      setApprovers(response);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  }, [approvalType, approvalAction]);
  useEffect(() => {
    fetchApprovers();
    return () => {
      isUnmounted.current = true;
    };
  }, [fetchApprovers]);
  return {
    approvers,
    isLoading
  };
};

export default useApprovers;