import { CompletionStatuses } from '../../../constants';
import { GathererKeys } from '../../../gatherers/allGatherers';
import { getMetaDescriptionFromArtifacts } from '../../utils/metaTags';
export const removeTitleFromMetaDescriptionAudit = artifacts => {
  const pageTitle = artifacts[GathererKeys.PAGE_TITLE];
  const metaDescription = getMetaDescriptionFromArtifacts(artifacts);
  let status;

  if (!metaDescription) {
    status = CompletionStatuses.BLOCKED;
  } else if (metaDescription.includes(pageTitle)) {
    status = CompletionStatuses.INCOMPLETE;
  } else {
    status = CompletionStatuses.COMPLETE;
  }

  return {
    status
  };
};