import { ADD_FIELD_VALIDATION_ERROR, REMOVE_FIELD_VALIDATION_ERROR, SHIFT_FIELD_VALIDATION_ERROR } from 'ContentData/actions/ActionTypes';
import { setDeepValue, getDeepValue, removeDeepValue } from 'ContentUtils/helpers/ObjectHelpers';
export default function validationErrors(state = {}, action) {
  switch (action.type) {
    case ADD_FIELD_VALIDATION_ERROR:
      {
        const {
          fieldKey
        } = action;
        if (!fieldKey) return state;
        return setDeepValue(state, fieldKey, true);
      }

    case REMOVE_FIELD_VALIDATION_ERROR:
      {
        const {
          fieldKey
        } = action;
        if (!fieldKey) return state;
        let fieldKeyIndex = fieldKey.length - 1;
        state = removeDeepValue(state, fieldKey);

        while (fieldKeyIndex >= 0) {
          const fieldKeys = fieldKey.slice(0, fieldKeyIndex);
          const value = getDeepValue(state, fieldKeys);

          if (!(value && Object.keys(value).length)) {
            state = removeDeepValue(state, fieldKeys);
          }

          fieldKeyIndex--;
        }

        return state;
      }

    case SHIFT_FIELD_VALIDATION_ERROR:
      {
        const {
          fieldKey,
          fromIndex,
          toIndex
        } = action;
        if (!fieldKey) return state;
        const fromValue = getDeepValue(state, [...fieldKey, fromIndex]);
        state = removeDeepValue(state, [...fieldKey, fromIndex]);
        return setDeepValue(state, [...fieldKey, toIndex], fromValue);
      }

    default:
      return state;
  }
}