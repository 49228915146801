import { CompletionStatuses } from '../../../constants';
import { GathererKeys } from '../../../gatherers/allGatherers';
import { ReadabilityIssueCategories } from '../../constants';
export const grammarAudit = artifacts => {
  const readabilityIssues = artifacts[GathererKeys.READABILITY];
  const grammarIssues = readabilityIssues ? readabilityIssues.filter(issue => issue.category === ReadabilityIssueCategories.GRAMMAR) : [];
  let status;

  if (grammarIssues.length === 0) {
    status = CompletionStatuses.COMPLETE;
  } else {
    status = CompletionStatuses.INCOMPLETE;
  }

  return {
    status,
    grammarIssues
  };
};