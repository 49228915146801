import { createSelector } from 'reselect';
import { CREATE_SUBTOPIC_SUCCEEDED, FETCH_TOPICS_SUCCEEDED } from '../actionTypes';
export const topicsReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_TOPICS_SUCCEEDED:
      {
        return action.payload;
      }

    case CREATE_SUBTOPIC_SUCCEEDED:
      {
        return [...state, action.payload];
      }

    default:
      return state;
  }
};

const selectTopicsState = state => state.topics;

export const selectSubtopics = createSelector(selectTopicsState, topics => topics.filter(topic => topic.coreTopic === false));
export const selectSubtopicDropdownOptions = createSelector(selectSubtopics, subtopics => subtopics.map(subtopic => ({
  text: subtopic.phrase,
  disabled: subtopic.hasAttachedContent,
  value: subtopic.id
})));