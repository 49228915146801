'use es6';

import { IS_DISTRACTION_FREE_OPEN } from 'ContentEditorUI/lib/userAndLocalCacheSettings/constants';
import { toggleApplyChangesModalOpen } from 'ContentEditorUI/redux/actions/appActions';
import { setSuperstorePerContentSetting } from 'ContentEditorUI/redux/actions/settingsActions';
import { getPreviewIframeNeedsRefresh } from 'ContentEditorUI/redux/selectors/inpageSelectors';
export const toggleDistractionFree = isOpen => (dispatch, getState) => {
  const reduxState = getState();
  const previewIframeNeedsRefresh = getPreviewIframeNeedsRefresh(reduxState);

  if (previewIframeNeedsRefresh) {
    const applyChangesModalOpts = {
      distractionFreeInProgress: true,
      routingInProgress: null
    };
    dispatch(toggleApplyChangesModalOpen(true, applyChangesModalOpts));
    return;
  }

  dispatch(setSuperstorePerContentSetting(IS_DISTRACTION_FREE_OPEN, isOpen));
};