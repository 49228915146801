import Raven from 'raven-js';
import { useEffect, useState } from 'react';
import { UpgradeMessageEventKeys } from 'ui-addon-upgrades/_core/common/constants/upgradeMessageEventKeys';
import { track } from '../_core/common/eventTracking/tracker';
import { useUpgradeModal } from './useUpgradeModal';
import { getUpgradeDataTrackingProperties, getUpgradePointPromise, updateViewedUpgradePointsCache } from './utils';

const handleFetchUpgradePoint = (modalKey, isFeatureLocked) => {
  const upgradePointPromise = getUpgradePointPromise({
    upgradeKey: modalKey,
    delayCall: true
  });
  upgradePointPromise.then(upgradePoint => {
    if (isFeatureLocked && upgradePoint) {
      updateViewedUpgradePointsCache(modalKey, () => {
        track('interaction', Object.assign({
          action: 'viewed upgrade point'
        }, getUpgradeDataTrackingProperties(upgradePoint, true, true)));
      });
    }
  }).catch(error => {
    Raven.captureException(error);
  });
};
/**
 * `useUpgradeEventListener` should be called in the top window of your
 * application when using `useUpgradeModal` inside of an iframe. This will
 * mount an event listener to listen for upgrade events, which will in turn
 * open the upgrade modal in the parent window instead of the iframe.
 *
 * @example
 * ```tsx
 * const WrapperComponent = () => {
 *   useUpgradeEventListener();
 *
 *   return <iframe src="your-source.hubspot.com" />;
 * }
 * ```
 */


export const useUpgradeEventListener = () => {
  // We store this item in state as an object in order
  // to re-evaluate this component on every message.
  // This is because when the modal key changes,
  // we need `useUpgradeModal` to re-run so that
  // `openUpgradeModal` opens for the right upgrade point.
  const [key, setModalKey] = useState({
    inner: ''
  });
  const {
    openUpgradeModal
  } = useUpgradeModal(key.inner);
  useEffect(() => {
    const handleMessage = messageEvent => {
      if (!messageEvent || !messageEvent.data) {
        return;
      }

      switch (messageEvent.data.event) {
        case UpgradeMessageEventKeys.OPEN_MODAL:
          setModalKey({
            inner: messageEvent.data.modalKey
          });
          break;

        case UpgradeMessageEventKeys.FETCH_UPGRADE_POINT:
          handleFetchUpgradePoint(messageEvent.data.modalKey, messageEvent.data.isFeatureLocked);
          break;

        default:
          break;
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);
  useEffect(() => {
    if (key.inner) {
      openUpgradeModal();
    }
  }, [key, openUpgradeModal]);
};