export const getElementModuleId = element => {
  let wrapperElement = element.closest('.hs_cos_wrapper');

  if (!wrapperElement || wrapperElement.id.includes('custom_module')) {
    return null;
  }

  while (wrapperElement.parentElement) {
    const parentElement = wrapperElement.parentElement;

    if (!parentElement.classList.contains('hs_cos_wrapper')) {
      break;
    } else {
      wrapperElement = parentElement;
    }
  }

  const elementId = wrapperElement.id;
  return elementId && elementId.split('hs_cos_wrapper_').pop();
};