import http from 'hub-http/clients/apiClient';
import Raven from 'raven-js';

let _cachedProducts;

let _cachedProductsOwned;

export const resetProductCache = () => {
  _cachedProducts = null;
};
export const resetProductOwnedCache = () => {
  _cachedProductsOwned = null;
};
export const getProducts = () => {
  if (_cachedProducts) {
    return _cachedProducts;
  }

  _cachedProducts = http.get('monetization/v3/product').catch(error => {
    resetProductCache();

    if (error && error.status === 0) {
      return;
    }

    Raven.captureException(new Error('Failed to fetch products'), {
      extra: {
        error
      }
    });
  });
  return _cachedProducts;
};
export const getOwnedProducts = () => {
  if (_cachedProductsOwned) {
    return _cachedProductsOwned;
  }

  _cachedProductsOwned = http.get('monetization/v3/product/owned').catch(() => {
    resetProductOwnedCache();
  });
  return _cachedProductsOwned;
};