import PropTypes from 'prop-types';
import { upgradeDataPropsInterface } from 'ui-addon-upgrades/_core/common/data/upgradeData/interfaces/upgradeDataPropsInterface';
import { purchaseMotionKeys, purchaseMotionButtonPropsInterface } from 'ui-addon-upgrades/_core/common/constants/purchaseMotions';
import * as PurchaseMotionTypes from 'ui-addon-upgrades/_core/purchaseMotions/PurchaseMotionTypes';
export const MergedPurchaseMotionProps = Object.assign({}, purchaseMotionButtonPropsInterface[PurchaseMotionTypes.BUY_TOUCHLESS], {}, purchaseMotionButtonPropsInterface[PurchaseMotionTypes.TALK_TO_SALES], {}, purchaseMotionButtonPropsInterface[PurchaseMotionTypes.TRIAL], {}, purchaseMotionButtonPropsInterface[PurchaseMotionTypes.BUY_ADDITIONAL_CONTACTS], {}, purchaseMotionButtonPropsInterface[PurchaseMotionTypes.BUY_ADDITIONAL_SEATS], {}, purchaseMotionButtonPropsInterface[PurchaseMotionTypes.PRICING_PAGE_REDIRECT]);
export const PurchaseMotionButtonPropsInterface = Object.assign({}, upgradeDataPropsInterface, {
  level: PropTypes.oneOf([1, 2, 3, 4]).isRequired,
  overrides: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.element, PropTypes.func])),
  passthroughProps: PropTypes.objectOf(PropTypes.object),
  purchaseMotions: PropTypes.arrayOf(PropTypes.oneOf(purchaseMotionKeys)).isRequired,
  isUpgradePoint: PropTypes.bool
});