import { createSelector } from 'reselect';
import { invariant } from '../lib/helperFunctions';
import { getLatestArtifact as _getLatestArtifact } from '../reducers/createReducer';
// Artifact selector generators
const requestKeyRequired = 'A requestKey is required to select the latest artifact';
export const makeLatestArtifactSelector = (getRequestStates, getRequestKey) => {
  return createSelector([getRequestStates, getRequestKey], (requestStates, requestKey) => {
    invariant(!!requestKey, requestKeyRequired); // @ts-expect-error Selectors accept a second "props" argument, but for whatever reason the types don't support it

    return _getLatestArtifact(requestStates, {
      requestKey
    });
  });
}; // Default artifact selectors

export const getRequestStates = state => state.requestStates;
export const getRequestKey = (state, props) => props.requestKey;
export const getLatestArtifact = makeLatestArtifactSelector(getRequestStates, getRequestKey);