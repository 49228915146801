import { produce } from 'immer';
import { Map as ImmutableMap, fromJS } from 'immutable';
import { FETCH_USER_ATTRIBUTES_SUCCEEDED, UPDATE_USER_ATTRIBUTE, UPDATE_USER_ATTRIBUTE_ALSO_CACHED_IN_SUPERSTORE } from 'ContentEditorUI/redux/actions/actionTypes';
export const userAttributesReducerImmer = (state = {}, action) => produce(state, draft => {
  const {
    type
  } = action;

  switch (type) {
    case FETCH_USER_ATTRIBUTES_SUCCEEDED:
      {
        const {
          attributes
        } = action;
        return attributes;
      }

    case UPDATE_USER_ATTRIBUTE:
      {
        const {
          key,
          value
        } = action;
        draft[key] = value;
        return draft;
      }

    case UPDATE_USER_ATTRIBUTE_ALSO_CACHED_IN_SUPERSTORE:
      {
        const {
          key,
          stringifiedValue
        } = action;
        draft[key] = stringifiedValue;
        return draft;
      }

    default:
      return draft;
  }
});

const userAttributesReducer = (state = ImmutableMap(), {
  type,
  attributes,
  key,
  value,
  stringifiedValue
}) => {
  switch (type) {
    case FETCH_USER_ATTRIBUTES_SUCCEEDED:
      return fromJS(attributes);

    case UPDATE_USER_ATTRIBUTE:
      return state.set(key, value);

    case UPDATE_USER_ATTRIBUTE_ALSO_CACHED_IN_SUPERSTORE:
      return state.set(key, stringifiedValue);

    default:
      return state;
  }
};

export default userAttributesReducer;