import { useState, useCallback, useEffect, useRef } from 'react';
import omit from 'transmute/omit';
import { IS_OPEN, HIDE_EMBEDDED_COMMENTS, MODULES_WITH_REPLIES, IS_COMMENT_MODE_ACTIVE, BROADCAST_COMMENT_MESSAGE } from 'collaboration-sidebar/constants/CollaborationSidebarStateProperties';
import emptyFunction from 'react-utils/emptyFunction';
import useEmbeddedCommentsNavigation from 'collaboration-sidebar/features/deepCommenting/hooks/useEmbeddedCommentsNavigation';
import usePrevious from 'collaboration-sidebar/hooks/usePrevious';
import useIsCommentIconVisibleFromFilter from './useIsCommentIconVisibleFromFilter';
const ESCAPE_KEY_CODE = 27;
export default function useEmbeddedComments({
  moduleId,
  collaborationSidebarState,
  onUpdateCollaborationSidebarState,
  onRemoveComment = emptyFunction,
  commentableAreaRef,
  navigateTo = emptyFunction
}) {
  const {
    isEmbeddedCommentingEnabled,
    appName,
    objectId,
    objectType,
    modulesWithReplies,
    isCommentModeActive,
    hideEmbeddedComments,
    embeddedCommentSidebarInteractions = {},
    commentsFilter
  } = collaborationSidebarState;
  const {
    isHighlightedBySidebar
  } = useEmbeddedCommentsNavigation({
    commentableAreaRef,
    moduleId,
    onUpdateCollaborationSidebarState,
    navigateTo,
    // @ts-expect-error TODO not compatible type, needs fixing
    embeddedCommentSidebarInteractions
  });
  const modulesWithRepliesRef = useRef(modulesWithReplies);
  useEffect(() => {
    modulesWithRepliesRef.current = modulesWithReplies;
  }, [modulesWithReplies]);
  const [isCommenting, setIsCommenting] = useState(false);
  const cancelCommentMode = useCallback(() => {
    onUpdateCollaborationSidebarState({
      [IS_COMMENT_MODE_ACTIVE]: false
    });
  }, [onUpdateCollaborationSidebarState]);
  const onEscapeKeyDown = useCallback(({
    keyCode
  }) => {
    if (keyCode === ESCAPE_KEY_CODE) {
      cancelCommentMode();
    }
  }, [cancelCommentMode]);
  const startCommentMode = useCallback(() => {
    if (isEmbeddedCommentingEnabled && !isCommentModeActive) {
      // Add window listener for escape key to cancel comment mode
      window.addEventListener('keydown', onEscapeKeyDown);
      window.focus();
      onUpdateCollaborationSidebarState({
        [IS_OPEN]: false,
        [IS_COMMENT_MODE_ACTIVE]: true
      });
    }
  }, [isCommentModeActive, isEmbeddedCommentingEnabled, onEscapeKeyDown, onUpdateCollaborationSidebarState]);
  const setModuleReply = useCallback((repliedModuleId, comment) => {
    onUpdateCollaborationSidebarState({
      [MODULES_WITH_REPLIES]: Object.assign({}, modulesWithRepliesRef.current, {
        [repliedModuleId]: comment
      })
    });
  }, [onUpdateCollaborationSidebarState]);
  const broadcastCommentsMessage = useCallback(broadcastMessagePayload => {
    onUpdateCollaborationSidebarState({
      [BROADCAST_COMMENT_MESSAGE]: broadcastMessagePayload
    });
  }, [onUpdateCollaborationSidebarState]);
  const removeModuleReply = useCallback(repliedModuleId => {
    onUpdateCollaborationSidebarState({
      [MODULES_WITH_REPLIES]: omit([repliedModuleId], modulesWithRepliesRef.current)
    });
    onRemoveComment();

    if (!isCommentModeActive) {
      setIsCommenting(false);
    }
  }, [isCommentModeActive, onRemoveComment, onUpdateCollaborationSidebarState]);
  const setHideEmbeddedComments = useCallback(updatedHideEmbeddedComments => onUpdateCollaborationSidebarState({
    [HIDE_EMBEDDED_COMMENTS]: updatedHideEmbeddedComments
  }), [onUpdateCollaborationSidebarState]); // Turn off commenting hover indicators when comment mode is turned off.

  useEffect(() => {
    if (!isCommentModeActive) {
      setIsCommenting(false); // Remove window listener for escape key

      window.removeEventListener('keydown', onEscapeKeyDown);
    }
  }, [setIsCommenting, isCommentModeActive, onEscapeKeyDown]); // @ts-expect-error moduleId can be undefined here

  const moduleWithReply = (modulesWithReplies || {})[moduleId];
  const prevModuleWithReply = usePrevious(moduleWithReply);
  useEffect(() => {
    // Set is isCommenting to false if a module has been removed
    if (prevModuleWithReply && !moduleWithReply) {
      setIsCommenting(false);
    }
  }, [prevModuleWithReply, moduleWithReply]);
  const isCommentIconVisibleFromFilter = useIsCommentIconVisibleFromFilter({
    isCommenting,
    commentsFilter,
    moduleWithReply
  });
  const showComments = isCommentIconVisibleFromFilter && (!!moduleWithReply || isCommenting) && isEmbeddedCommentingEnabled && (!hideEmbeddedComments || isCommentModeActive);
  return {
    isEmbeddedCommentingEnabled,
    isCommentModeActive,
    startCommentMode,
    cancelCommentMode,
    appName,
    objectId,
    objectType,
    showComments,
    isCommenting,
    setModuleReply,
    removeModuleReply,
    setIsCommenting,
    hideEmbeddedComments,
    setHideEmbeddedComments,
    broadcastCommentsMessage,
    isHighlightedBySidebar,
    moduleParentComment: moduleWithReply
  };
}