'use es6';

import { VERTICAL_ALIGNMENTS } from 'ContentUtils/constants/Alignments';
import { calculateExistingVerticalAlignmentForRow, moveVerticalAlignmentFromModuleColumnToNewParentColumn, setAlignmentOnAllColumnsInRow } from '../alignment/helpers';
export const batchTreeMutations = (tree, callback) => {
  const mutableTree = tree.makeTemporaryMutableClone();
  callback(mutableTree);
  mutableTree.validateWholeTree();
  const finalTree = mutableTree.clone();
  return {
    tree: finalTree
  };
};

const insertWrapperColumn = (tree, {
  afterCellName,
  beforeCellName
}) => {
  return tree.insertNewColumnAt({
    afterCellName,
    beforeCellName,
    newCellValue: {
      type: 'cell'
    }
  });
};

const insertRowWithWrapperColumn = (tree, {
  beforeRowName,
  afterRowName
}) => {
  return tree.insertNewRowAt({
    beforeRowName,
    afterRowName,
    newCellValue: {
      type: 'cell'
    }
  });
};

const insertWrappedColumn = (tree, {
  afterCellName,
  beforeCellName,
  existingColumnId
}) => {
  const {
    tree: treeWithNewColumn,
    newCell
  } = insertWrapperColumn(tree, {
    afterCellName,
    beforeCellName
  });
  const newColumnId = newCell.getName();
  const prependRowResult = treeWithNewColumn.appendRow(newColumnId, {
    existingCellName: existingColumnId
  });
  return prependRowResult;
};

const wrapAllColumnsInRowIfNeeded = (tree, rowName) => {
  let row = tree.findRow(rowName); // The column count shouldn't change, but the names and references might change as we wrap columns

  let mostRecentColumnNames = row.getColumnNames();
  const numberOfChildColumns = mostRecentColumnNames.length;
  let lastOperationResult = {
    tree
  };

  for (let i = 0; i < numberOfChildColumns; i++) {
    const column = lastOperationResult.tree.findCell(mostRecentColumnNames[i]);

    if (column.isModule()) {
      const beforeCellName = mostRecentColumnNames[i + 1];
      const afterCellName = mostRecentColumnNames[i - 1];
      let positionKey;
      let positionValue;

      if (beforeCellName) {
        positionKey = 'beforeCellName';
        positionValue = beforeCellName;
      } else {
        positionKey = 'afterCellName';
        positionValue = afterCellName;
      }

      lastOperationResult = insertWrappedColumn(lastOperationResult.tree, {
        [positionKey]: positionValue,
        existingColumnId: column.getName()
      });
      row = lastOperationResult.tree.findRow(rowName);
      mostRecentColumnNames = row.getColumnNames();
    }
  }

  return lastOperationResult;
};

const insertColumnBeforeOrAfter = (tree, columnId, {
  shouldInsertBefore,
  shouldInsertAfter,
  existingColumnId,
  existingRowId,
  newModuleSchemaJson,
  originTree,
  defaultVerticalCentering
}) => {
  const targetColumn = tree.findCell(columnId);
  const parentRow = targetColumn.getParent();
  const parentRowOnlyHadOneChildBefore = parentRow.getNumberColumns() === 1;
  const existingVerticalAlignmentOfTargetColumnsSiblings = calculateExistingVerticalAlignmentForRow(parentRow);

  if (!shouldInsertBefore && !shouldInsertAfter) {
    throw new Error(`Need to pass shouldInsertBefore or shouldInsertAfter to insertColumnBeforeOrAfter`);
  }

  const beforeOrAfterKey = shouldInsertBefore ? 'beforeCellName' : 'afterCellName';
  let resultAfterColumnInsert; // New module being dropped

  if (newModuleSchemaJson) {
    resultAfterColumnInsert = tree.insertNewColumnAt({
      [beforeOrAfterKey]: columnId,
      newCellName: newModuleSchemaJson.name,
      newCellValue: newModuleSchemaJson
    });
  } else {
    // Existing cell being moved
    if (existingColumnId) {
      resultAfterColumnInsert = tree.moveCellTo(existingColumnId, {
        [beforeOrAfterKey]: columnId,
        originTree
      }); // Existing row being moved into column position
    } else {
      resultAfterColumnInsert = tree.moveRowTo(existingRowId, {
        [beforeOrAfterKey]: columnId,
        newWrapperCellValue: {
          type: 'cell'
        },
        originTree
      });
    }
  } // Make sure all the columns are wrapped


  resultAfterColumnInsert.tree = wrapAllColumnsInRowIfNeeded(resultAfterColumnInsert.tree, parentRow.getName()).tree; // Make sure all the columns have the default or same alignment set
  //  - If default vertical centering is turned on, the row is going from one to two columns, there
  //    are no existing vertical alignment styles left around... then set the vertical alignemnt to center
  //  - If default vertical centering is turned on... then set the vertical alignemnt to center for
  //    just the new column
  //  - Otherwise, make the vertical alignment of the new column use the most common vertical alignment
  //    on all the siblings

  const noExistingVerticalAlignment = !existingVerticalAlignmentOfTargetColumnsSiblings;
  const shouldVerticallyCenterAllColumns = defaultVerticalCentering && parentRowOnlyHadOneChildBefore && noExistingVerticalAlignment;
  const verticalAlignmentToSetOnAll = shouldVerticallyCenterAllColumns ? VERTICAL_ALIGNMENTS.MIDDLE : existingVerticalAlignmentOfTargetColumnsSiblings;
  const resultAfterVerticalAlignAdjustment = setAlignmentOnAllColumnsInRow(resultAfterColumnInsert.tree, parentRow.getName(), verticalAlignmentToSetOnAll);
  return {
    tree: resultAfterVerticalAlignAdjustment.tree,
    originTree: resultAfterColumnInsert.originTree,
    modifiedColumns: resultAfterColumnInsert.modifiedColumns
  };
};

export const appendColumnToEmptyRow = (tree, rowId, {
  existingColumnId,
  newModuleSchemaJson,
  originTree
}) => {
  let resultAfterColumnInsert; // New module being dropped

  if (newModuleSchemaJson) {
    resultAfterColumnInsert = tree.appendColumn(rowId, {
      newCellName: newModuleSchemaJson.name,
      newCellValue: newModuleSchemaJson
    });
  } else {
    // Existing cell being moved
    resultAfterColumnInsert = tree.appendColumn(rowId, {
      existingCellName: existingColumnId,
      originTree
    });
  }

  return {
    tree: resultAfterColumnInsert.tree,
    originTree: resultAfterColumnInsert.originTree,
    modifiedColumns: resultAfterColumnInsert.modifiedColumns
  };
};
export const insertColumnBefore = (tree, columnId, {
  existingColumnId,
  existingRowId,
  newModuleSchemaJson,
  originTree,
  defaultVerticalCentering = true
}) => {
  return insertColumnBeforeOrAfter(tree, columnId, {
    shouldInsertBefore: true,
    existingColumnId,
    existingRowId,
    newModuleSchemaJson,
    originTree,
    defaultVerticalCentering
  });
};
export const insertColumnAfter = (tree, columnId, {
  existingColumnId,
  existingRowId,
  newModuleSchemaJson,
  originTree,
  defaultVerticalCentering = true
}) => {
  return insertColumnBeforeOrAfter(tree, columnId, {
    shouldInsertAfter: true,
    existingColumnId,
    existingRowId,
    newModuleSchemaJson,
    originTree,
    defaultVerticalCentering
  });
};

const insertRowBeforeOrAfter = (tree, rowId, {
  insertBefore,
  insertAfter,
  existingColumnId,
  // when a cell is being moved to a new row
  existingRowId,
  // when a row is being moved
  newModuleSchemaJson,
  // when a new module is added as a row
  originTree,
  // when a node is being moved to a different tree
  shouldWrapSingleModuleInSection
}) => {
  const targetRow = tree.findRow(rowId);
  const isAddingNewModule = newModuleSchemaJson && !existingRowId && !existingColumnId;
  const isMovingACell = !!existingColumnId;
  const isMovingAModule = existingColumnId && (originTree || tree).findCell(existingColumnId).isModule(); // Wrap any new/moved modules with a wrapper column

  const shouldWrapCell = shouldWrapSingleModuleInSection && targetRow.getParent().isRoot() && (isAddingNewModule || isMovingAModule);

  if (!insertBefore && !insertAfter) {
    throw new Error('Either insertBefore or insertAfter needs to be set in insertRowBeforeOrAfter');
  }

  const beforeOrAfterKey = insertBefore ? 'beforeRowName' : 'afterRowName';

  if (newModuleSchemaJson) {
    if (shouldWrapCell) {
      const {
        tree: treeWithEmptyWrapper,
        newCell: newWrapperCell
      } = insertRowWithWrapperColumn(tree, {
        [beforeOrAfterKey]: rowId
      });
      const {
        tree: newTree
      } = treeWithEmptyWrapper.appendRow(newWrapperCell.getName(), {
        newCellName: newModuleSchemaJson ? newModuleSchemaJson.name : null,
        newCellValue: newModuleSchemaJson
      });
      return {
        tree: newTree
      };
    } else {
      const {
        tree: newTree
      } = tree.insertNewRowAt({
        [beforeOrAfterKey]: rowId,
        newCellName: newModuleSchemaJson.name,
        newCellValue: newModuleSchemaJson
      });
      return {
        tree: newTree
      };
    }
  } else if (isMovingACell) {
    if (shouldWrapCell) {
      const {
        tree: treeWithEmptyWrapper,
        newCell: newWrapperCell
      } = insertRowWithWrapperColumn(tree, {
        [beforeOrAfterKey]: rowId
      });
      return treeWithEmptyWrapper.appendRow(newWrapperCell.getName(), {
        existingCellName: existingColumnId,
        originTree
      });
    } else {
      const {
        tree: newTree,
        originTree: newOriginTree,
        modifiedColumns
      } = tree.moveCellTo(existingColumnId, {
        [beforeOrAfterKey]: rowId,
        originTree
      });
      return {
        tree: newTree,
        originTree: newOriginTree,
        modifiedColumns
      };
    }
  } else {
    // Never need to wrap a row being moved
    const {
      tree: newTree,
      originTree: newOriginTree,
      modifiedColumns
    } = tree.moveRowTo(existingRowId, {
      [beforeOrAfterKey]: rowId,
      originTree
    });
    return {
      tree: newTree,
      originTree: newOriginTree,
      modifiedColumns
    };
  }
};

export const insertRowBefore = (tree, rowId, {
  existingColumnId,
  // when a cell is being moved to a new row
  existingRowId,
  // when a row is being moved
  newModuleSchemaJson,
  // when a new module is added as a row
  originTree,
  // when a node is being moved to a different tree
  shouldWrapSingleModuleInSection
}) => {
  return insertRowBeforeOrAfter(tree, rowId, {
    insertBefore: true,
    existingColumnId,
    existingRowId,
    newModuleSchemaJson,
    originTree,
    shouldWrapSingleModuleInSection
  });
};
export const insertRowAfter = (tree, rowId, {
  existingColumnId,
  // when a cell is being moved to a new row
  existingRowId,
  // when a row is being moved
  newModuleSchemaJson,
  // when a new module is added as a row
  originTree,
  // when a node is being moved to a different tree
  shouldWrapSingleModuleInSection
}) => {
  return insertRowBeforeOrAfter(tree, rowId, {
    insertAfter: true,
    existingColumnId,
    existingRowId,
    newModuleSchemaJson,
    originTree,
    shouldWrapSingleModuleInSection
  });
};

const appendOrPrependRowToCell = (tree, columnId, {
  shouldAppendRow,
  shouldPrependRow,
  existingColumnId,
  // when a cell is being moved to a new row
  existingRowId,
  // when a row is being moved
  newModuleSchemaJson,
  // when a new module is added as a row
  originTree,
  // when a node is being moved to a different tree
  shouldWrapSingleModuleInSection
}) => {
  let newOriginTree;
  let modifiedColumns;
  let newGrandParentCell;

  if (!shouldAppendRow && !shouldPrependRow) {
    throw new Error(`Didn't pass shouldAppendRow or shouldPrependRow to appendOrPrependRowToCell`);
  }

  const isAddingNewModule = newModuleSchemaJson && !existingRowId && !existingColumnId;
  const isMovingAModule = existingColumnId && (originTree || tree).findCell(existingColumnId).isModule();
  const isMovingACell = !!existingColumnId;
  const {
    tree: newTree
  } = batchTreeMutations(tree, mutableTree => {
    let results;
    const targetCell = mutableTree.findCell(columnId); // Wrap any new/moved modules with a wrapper column if appending/prepending a row with a
    // new/moved module to the D&D area root cell

    const shouldWrapCell = shouldWrapSingleModuleInSection && targetCell.isRoot() && (isAddingNewModule || isMovingAModule);
    const appendOrPrependFunc = shouldPrependRow ? mutableTree.prependRow.bind(mutableTree) : mutableTree.appendRow.bind(mutableTree);

    if (newModuleSchemaJson) {
      if (shouldWrapCell) {
        ({
          newCell: newGrandParentCell
        } = appendOrPrependFunc(targetCell.getName(), {
          newCellValue: {
            type: 'cell'
          }
        }));
        results = mutableTree.appendRow(newGrandParentCell.getName(), {
          newCellName: newModuleSchemaJson ? newModuleSchemaJson.name : null,
          newCellValue: newModuleSchemaJson
        });
        results.newGrandParentCell = newGrandParentCell;
      } else {
        results = appendOrPrependFunc(targetCell.getName(), {
          newCellName: newModuleSchemaJson.name,
          newCellValue: newModuleSchemaJson,
          originTree
        });
      }
    } else if (isMovingACell) {
      if (shouldWrapCell) {
        ({
          newCell: newGrandParentCell
        } = appendOrPrependFunc(targetCell.getName(), {
          newCellValue: {
            type: 'cell'
          }
        }));
        results = mutableTree.appendRow(newGrandParentCell.getName(), {
          existingCellName: existingColumnId,
          originTree
        });
        results.newGrandParentCell = newGrandParentCell;
      } else {
        results = appendOrPrependFunc(targetCell.getName(), {
          existingCellName: existingColumnId,
          originTree
        });
      }
    } else {
      results = appendOrPrependFunc(targetCell.getName(), {
        existingRowName: existingRowId,
        originTree
      });
    }

    ({
      originTree: newOriginTree,
      modifiedColumns,
      newGrandParentCell
    } = results);

    if (newGrandParentCell) {
      moveVerticalAlignmentFromModuleColumnToNewParentColumn(mutableTree, targetCell.getName(), newGrandParentCell);
    }
  });
  return {
    tree: newTree,
    originTree: newOriginTree,
    modifiedColumns
  };
};

export const prependRowToCell = (tree, columnId, {
  existingColumnId,
  // when a cell is being moved to a new row
  existingRowId,
  // when a row is being moved
  newModuleSchemaJson,
  // when a new module is added as a row
  originTree,
  // when a node is being moved to a different tree
  shouldWrapSingleModuleInSection
}) => {
  return appendOrPrependRowToCell(tree, columnId, {
    shouldPrependRow: true,
    existingColumnId,
    existingRowId,
    newModuleSchemaJson,
    originTree,
    shouldWrapSingleModuleInSection
  });
};
export const appendRowToCell = (tree, columnId, {
  existingColumnId,
  // when a cell is being moved to a new row
  existingRowId,
  // when a row is being moved
  newModuleSchemaJson,
  // when a new module is added as a row
  originTree,
  // when a node is being moved to a different tree
  shouldWrapSingleModuleInSection
}) => {
  return appendOrPrependRowToCell(tree, columnId, {
    shouldAppendRow: true,
    existingColumnId,
    existingRowId,
    newModuleSchemaJson,
    originTree,
    shouldWrapSingleModuleInSection
  });
};