import http from 'hub-http/clients/apiClient';
import retryClient from './api/retryClient';
import { setGroupKey } from './api/userContextApi';
import { setOnboardingSettings } from './onboardingSettings';
export const USER_CONTEXT_ACTIONS_BASE_URL = 'usercontext/v1/actions';
export const USER_CONTEXT_APP_BASE_URL = 'usercontext-app/v1';
export const enableTaskCompletionModal = actionName => {
  const bannerFrame = document.getElementById('onboarding-tours-banner-frame');

  if (!window.sessionStorage || !bannerFrame || !bannerFrame.contentWindow) {
    return;
  }

  try {
    const configKey = 'ONBOARDING_TOURS_GUIDE';
    const configJson = window.sessionStorage.getItem(configKey);

    if (!configJson) {
      return;
    }

    const config = JSON.parse(configJson);

    if (config.type === 'task' && config.key === actionName) {
      config.completed = true;
      bannerFrame.contentWindow.postMessage({
        key: configKey,
        value: config
      }, '*');
    }
  } catch (e) {
    return;
  }
};
export const markActionComplete = (actionName, options = {}) => {
  return retryClient.post(USER_CONTEXT_ACTIONS_BASE_URL, {
    data: {
      actionName
    }
  }).then(() => {
    const {
      showTaskCompletionModal = true,
      source
    } = options;

    if (!showTaskCompletionModal) {
      return;
    }
    /**
     * Ignore task completion modal for avoiding breaking onboarding tour when:
     *   - Any onboarding tour is active in current page
     *   - markActionComplete is not triggered from onboarding-tours lib
     * */


    if (source !== 'onboarding-tours' && document.querySelector('.onboarding-tour--active')) {
      return;
    }

    enableTaskCompletionModal(actionName);
  });
};
export const getCompletedActions = () => http.get(USER_CONTEXT_ACTIONS_BASE_URL);
export const getIsActionCompleted = actionName => http.get(`${USER_CONTEXT_ACTIONS_BASE_URL}/${actionName}`);
export const getTasksGroup = () => {
  return http.get(`${USER_CONTEXT_APP_BASE_URL}/onboarding/tasks/group`, {
    headers: {
      accept: 'text/plain'
    }
  });
};
export const setDefaultGroupKey = (groupKey, options) => setGroupKey(groupKey, options);
export const setGettingStartedGroupKey = (groupKey, view, userId, portalId) => {
  const groupKeyRequests = [setDefaultGroupKey(groupKey)];

  if (view && userId) {
    groupKeyRequests.push(setOnboardingSettings({
      views: {
        [view]: {
          groupKey
        }
      },
      selectedView: view
    }, userId, portalId));
  }

  return Promise.all(groupKeyRequests);
};