import PortalIdParser from 'PortalIdParser';
import { parse, stringify } from 'hub-http/helpers/params';
import { getHublet, isHublet } from './hublet'; // Taken from https://stackoverflow.com/a/31432012

const RELATIVE_URL_REGEX = /^(?!www\.|(?:http|ftp)s?:\/\/|[A-Za-z]:\\|\/\/).*/;
export const localOr = (overrideKey, subDomain = 'api') => {
  let result;

  try {
    if (window.localStorage[overrideKey] === 'local') {
      result = 'local';
    }
  } catch (error) {
    /* Noop */
  }

  if (!result) {
    result = subDomain;
  }

  if (isHublet()) {
    result = `${result}-${getHublet()}`;
  }

  return result;
};
export const isQA = () => {
  return window.location.host.indexOf('hubspotqa') > -1;
};
export const getBaseUrl = opts => {
  const {
    localOverride,
    subDomain,
    domain
  } = opts;
  const subDomainOverride = localOr(localOverride, subDomain);
  const domainOverride = domain || 'hubspot';
  return isQA() ? `https://${subDomainOverride}.${domainOverride}qa.com` : `https://${subDomainOverride}.${domainOverride}.com`;
};

const isRelativeUrl = url => {
  return RELATIVE_URL_REGEX.test(url);
};

const getUrlWithPortalId = url => {
  if (!url.includes('%portalId%')) {
    return url;
  }

  return url.replace(/%portalId%/g, String(PortalIdParser.get()));
};

export const parseReturnUrl = url => {
  if (!url) {
    return undefined;
  }

  if (!isRelativeUrl(url)) {
    return undefined;
  }

  return getUrlWithPortalId(url);
};
export const removeQueryParams = queryParamKeys => {
  const {
    history,
    location
  } = window; // Get query params object and remove params by argument

  const queryParams = parse(location.search.slice(1));
  queryParamKeys.forEach(queryParamKey => {
    delete queryParams[queryParamKey];
  });
  let search = ''; // format query params object to query string

  const queryString = stringify(queryParams);

  if (queryString) {
    // Remove `=undefined` for query parameter without value
    // For example: foo=undefined&bar=1 will be foo&bar=1
    search = `?${queryString}`.replace(/=undefined(&|#|$)/, '$1');
  } // Use replaceState to change url which won't cause page re-rendering


  history.replaceState(history.state, '', `${location.pathname}${search}`);
};