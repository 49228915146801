import { useMemo } from 'react';
import { ApprovalStepStatus } from '../types';
export const getApprovalStepsInfo = (approvalSteps, currentUserId) => {
  const defaultValue = {
    stepCount: 0,
    pendingCount: 0,
    approvedCount: 0,
    stepStatusForCurrentUser: undefined
  };

  if (!approvalSteps.length) {
    return defaultValue;
  }

  return approvalSteps.reduce((accumulator, approvalStep) => {
    const {
      pendingCount,
      approvedCount,
      stepStatusForCurrentUser
    } = accumulator;
    const {
      approvalStepStatus,
      approver
    } = approvalStep;
    const isPending = approvalStepStatus === ApprovalStepStatus.APPROVAL_PENDING;
    const isApproved = approvalStepStatus === ApprovalStepStatus.APPROVED;
    const isCurrentUsersStep = approver.activeUserId === currentUserId;
    return {
      stepCount: approvalSteps.length,
      pendingCount: isPending ? pendingCount + 1 : pendingCount,
      approvedCount: isApproved ? approvedCount + 1 : approvedCount,
      stepStatusForCurrentUser: isCurrentUsersStep ? approvalStepStatus : stepStatusForCurrentUser
    };
  }, defaultValue);
};

const useApprovalStepsInfo = (approvalSteps, currentUserId) => {
  return useMemo(() => getApprovalStepsInfo(approvalSteps, currentUserId), [approvalSteps, currentUserId]);
};

export default useApprovalStepsInfo;