import http from 'hub-http/clients/apiClient';
import { getFullUrl } from 'hubspot-url-utils';
import Raven from 'raven-js';
import { HAS_RETAIL_OVERRIDE, HAS_NOT_RETAIL_OVERRIDE } from 'ui-addon-upgrades/_core/utils/commMethodOverrides';
export const getIsRetailPortal = () => {
  if (HAS_RETAIL_OVERRIDE) {
    return Promise.resolve(true);
  }

  if (HAS_NOT_RETAIL_OVERRIDE) {
    return Promise.resolve(false);
  }

  return http.get(`${getFullUrl('app-api')}/eligibility-engine/v2/retail`).catch(error => {
    // The endpoint throws a 404 when a hub has no company,
    // meaning that it can't be enrolled in retail
    if (error.status !== '404') {
      Raven.captureException(error);
    }

    return false;
  });
};