import { createSelector } from 'reselect';
import { getThemeObject, getThemeGlobalSettings } from 'ContentEditorUI/redux/selectors/themeSelectors';
import { mergeDeep } from 'ContentComponents/cmv2/utils'; // @ts-expect-error Not typed

import { getModuleLists } from 'ContentEditorUI/redux/selectors/moduleSelectorHelpers';
import { getGoogleFontsMap } from 'theme-previewer-lib/utils/fonts'; // Splitting these theme selectors off to prevent some circular selector dependencies

export const getThemePageOverrides = createSelector([getModuleLists], moduleLists => {
  return moduleLists.get('themePageOverrides');
});
export const getComputedThemeSettings = createSelector([getThemeGlobalSettings, getThemePageOverrides], (themeGlobalSettings, themePageOverrides) => {
  if (!themePageOverrides) {
    return themeGlobalSettings;
  }

  return mergeDeep(themeGlobalSettings, themePageOverrides);
});
export const getThemeGoogleFontsMap = createSelector([getThemeObject, getComputedThemeSettings], (theme, computedThemeSettings) => {
  const fields = theme && theme.fields ? theme.fields : [];
  return getGoogleFontsMap(fields, computedThemeSettings);
});