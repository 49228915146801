'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import LayoutDataTree from './index';
import LayoutDataCell from './LayoutDataCell';
import RowWithSortedColumns from './RowWithSortedColumns';
import { isGeneratedName, extractIntFromGeneratedName, cloneAndModifyState } from '../CellAndRowsTree/privateHelpers';
import { CELL_TYPE } from './constants';
import { SECTION_CLASSNAME, COLUMN_CLASSNAME, ROW_CLASSNAME, MODULE_CLASSNAME } from '../constants';
import StaticSection from './StaticSection';
import { isEmptyObject } from 'ContentUtils/helpers/ObjectHelpers';
import { getIsStaticSectionFromValue, getIsStaticSectionFromJSON } from './helpers'; // TODO this import/export implementation _only_ supports editable layout sections right now, and
// doesn't yet support arbitrary layout data structures (that may have flex columns, etc)
// TODO duplicate/share convertFlexNodesToRegularLayoutNodes code from https://git.hubteam.com/HubSpot/DesignManagerUI/blob/543ba54528c37ed86f86cdaa7644703b7698bc73/DesignData/static/js/models/layouts/Layout.js#L138-L154 ?
// Including convertFlexColumnNodeToRegularLayoutNode and convertFlexAreaNodeToRegularLayoutNode?

const PARAMS_TO_EXCLUDE_SET = new Set(['_layout_section_depth', '_layout_section_last_row_depth', '_layout_section_row_number_at_current_depth', 'label', 'parent_widget_container' // TODO, should this be omitted???
]);

const getFilteredParams = (params = {}) => {
  const result = {};
  Object.keys(params).filter(key => !PARAMS_TO_EXCLUDE_SET.has(key)).forEach(key => result[key] = params[key]);
  return result;
};

const exportRow = (row, {
  includeRowNames
}) => {
  const result = {};
  let totalWidthSoFar = 0;
  row.getColumns().forEach(colCell => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const exportedCell = exportCell(colCell, {
      includeRowNames
    });
    exportedCell.x = totalWidthSoFar;
    result[totalWidthSoFar] = exportedCell;
    totalWidthSoFar += colCell.getWidth();
  });
  return result;
};

const exportStaticSectionsModuleData = module => {
  const {
    module_id,
    order,
    params,
    name,
    type = 'module',
    styles,
    label
  } = module;
  return {
    name,
    module_id,
    order,
    type,
    styles,
    label,
    // Note: BE saves with Body, but FE uses params (see importAndMutateStaticSectionModules)
    body: isEmptyObject(params) ? undefined : params
  };
};

const exportStaticSectionModules = modules => {
  const staticSectionModules = {};
  Object.entries(modules).forEach(([moduleName, moduleData]) => {
    staticSectionModules[moduleName] = exportStaticSectionsModuleData(moduleData);
  });
  return staticSectionModules;
};

export const exportStaticSection = row => {
  const {
    type,
    params
  } = row.getValue();
  const name = row.getName();
  return {
    name,
    id: name,
    // BE needs the id to process the styles
    type,
    params: {
      fixed_layout_section_id: params.fixed_layout_section_id,
      id: name,
      // FE sections are processed by id off of the params
      modules: exportStaticSectionModules(params.modules)
    }
  };
};
export const exportCellAsModule = (cellName, cellValue, {
  isRoot = false
} = {}) => {
  const result = {
    name: cellName,
    id: cellValue.id || cellName,
    type: cellValue.type || CELL_TYPE,
    // TODO, is this fallback right? Or need more precise check?
    params: getFilteredParams(cellValue.params)
  };
  let className;

  if (!isRoot) {
    className = result.type === 'cell' ? COLUMN_CLASSNAME : MODULE_CLASSNAME;
  }

  if (result.params.css_class) {
    const classes = result.params.css_class.split(' ').filter(cname => cname !== COLUMN_CLASSNAME && cname !== MODULE_CLASSNAME);

    if (className) {
      classes.push(className);
    }

    result.params.css_class = classes.join(' ');
  } else {
    if (className) {
      result.params.css_class = className;
    }
  } // Other things to always/sometimes pull off of value?


  if (cellValue.label != null) {
    result.label = cellValue.label;
  }

  if (cellValue.merge_in_default_values != null) {
    result.merge_in_default_values = cellValue.merge_in_default_values;
  } // NOTE This is ported from CEUI ModuleTransforms. It formats the modules in the way that the backend currently expects.
  // https://git.hubteam.com/HubSpot/ContentEditorUI/blob/cdbe8f51016ddd4f658f746d0c188fc00abaf884/ContentEditorUI/static/js/data/ModuleTransforms.js#L204-L217
  // First, schema_version needs to be on the params


  const schemaVersion = cellValue.schemaVersion || cellValue.schema_version;

  if (schemaVersion) {
    result.params.schema_version = schemaVersion;
  } // Second it isn't aware of CMv2 and type === module


  if (result.type === 'module' && schemaVersion === 2) {
    result.type = 'custom_widget';
  }

  return result;
};
export const exportCell = (cell, {
  extraProperties,
  includeRowNames
} = {}) => {
  const result = exportCellAsModule(cell.getName(), cell.getValue(), {
    isRoot: cell.isRoot()
  });
  result.styles = cell.getLayoutStyleData();
  result.rows = cell.getRows().map(row => {
    const isStaticSectionRow = row.isStaticSection();

    if (isStaticSectionRow) {
      return {
        // 0: Used in the save service, but not the render service.
        0: exportStaticSection(row)
      };
    }

    return exportRow(row, {
      includeRowNames
    });
  });
  result.rowMetaData = cell.getRows().map(row => {
    const rowValue = row.getValue() || {};
    const existingCssClasses = rowValue.cssClass ? rowValue.cssClass.split(' ') : [];
    const helperClassToAdd = cell.isRoot() ? SECTION_CLASSNAME : ROW_CLASSNAME;

    if (existingCssClasses.indexOf(helperClassToAdd) === -1) {
      existingCssClasses.push(helperClassToAdd);
    }

    const rowMetaDataResult = {
      cssClass: existingCssClasses.join(' '),
      styles: row.getLayoutStyleData()
    };

    if (rowValue.label) {
      rowMetaDataResult.label = rowValue.label;
    }

    return rowMetaDataResult;
  });
  result.w = cell.getWidth(); // Note, result.x will be overridden in exportRow (except for the root)

  result.x = 0;

  if (extraProperties) {
    Object.assign(result, extraProperties);
  } // TEMP HACK for inpage syncing ???


  if (includeRowNames) {
    result.__rowNamesToImport = cell.getRowNames();
  }

  return result;
}; // Used as serialization - of `makeSaveAction`

export const exportForLayoutDataApi = (tree, {
  includeRowNames = false
} = {}) => {
  return exportCell(tree.getRootCell(), {
    includeRowNames
  });
};

const importCellHelper = (mutableTree, jsonCell, parentRow) => {
  const cellName = jsonCell.name;
  const newCellValue = {
    id: jsonCell.id,
    width: jsonCell.w,
    label: jsonCell.label,
    type: jsonCell.type,
    order: jsonCell.order,
    styles: jsonCell.styles,
    line_number: jsonCell.line_number,
    params: getFilteredParams(jsonCell.params)
  };

  if (jsonCell.merge_in_default_values != null) {
    newCellValue.merge_in_default_values = jsonCell.merge_in_default_values;
  }

  const {
    newCell
  } = mutableTree.appendColumn(parentRow.getName(), {
    stealWidthFromSiblings: false,
    newCellName: cellName,
    // now using name as primary tree identifier
    newCellValue
  });
  (jsonCell.rows || []).forEach((jsonRow, rowIndex) => {
    const rowMetaData = jsonCell.rowMetaData && jsonCell.rowMetaData.length ? jsonCell.rowMetaData[rowIndex] : null;
    const rowNameToImport = jsonCell.__rowNamesToImport ? jsonCell.__rowNamesToImport[rowIndex] : undefined; // eslint-disable-next-line @typescript-eslint/no-use-before-define

    importRowHelper(mutableTree, jsonRow, newCell, {
      rowNameToImport,
      rowMetaData
    });
  });
};

const importRowHelper = (mutableTree, jsonRow, parentCell, {
  rowNameToImport = undefined,
  rowMetaData = {}
} = {}) => {
  // const newRowName = `${parentCell.getName()}-row-${parentCell.getNumberRows()}`;
  const {
    newRow
  } = mutableTree.appendRow(parentCell.getName(), {
    newRowName: rowNameToImport,
    newRowValue: Object.assign({}, rowMetaData)
  });

  for (const columnXValue of Object.keys(jsonRow)) {
    const jsonCell = jsonRow[columnXValue];

    if (jsonCell.x !== parseInt(columnXValue, 10)) {
      throw new Error(`Column key for row does not match X value`);
    } // newRow is the parent


    importCellHelper(mutableTree, jsonRow[columnXValue], newRow);
  }
}; // Hack for BE to be able to import static sections modules
// The static section modules use body instead of params, but layout-dnd uses params with Cells


const importAndMutateStaticSectionModules = modules => {
  const result = {};
  Object.entries(modules).forEach(([moduleName, moduleData]) => {
    const {
      body
    } = moduleData,
          restOfModuleData = _objectWithoutPropertiesLoose(moduleData, ["body"]);

    if (body) {
      restOfModuleData.params = body;
    }

    result[moduleName] = restOfModuleData;
  });
  return result;
};

const importStaticSectionHelper = (mutableTree, jsonRow, parentCell, rowMetaData = {} // includes css_class, label and styles (always null for staticSection atm)
) => {
  // I don't think they'll be more than one, but need to confirm.
  for (const columnXValue of Object.keys(jsonRow)) {
    const jsonCell = jsonRow[columnXValue];

    if (jsonCell.x !== parseInt(columnXValue, 10)) {
      throw new Error(`Column key for row does not match X value`);
    }

    const params = getFilteredParams(jsonCell.params);

    if (params.modules) {
      params.modules = importAndMutateStaticSectionModules(params.modules);
    }

    const newRowValue = Object.assign({
      id: jsonCell.id,
      type: jsonCell.type,
      order: jsonCell.order,
      line_number: jsonCell.line_number,
      params
    }, rowMetaData);
    mutableTree.appendRow(parentCell.getName(), {
      newRowName: jsonCell.name,
      newRowValue
    });
  }
};

export const importTreeFromLayoutDataApi = (json, {
  shouldPreventEmptyRows,
  CellClass,
  RowClass,
  StaticSectionClass
} = {}) => {
  const rootX = json.x;
  const rootWidth = json.w;
  const blankTree = new LayoutDataTree({
    rootName: json.name,
    // now using name as primary tree identifier
    shouldPreventEmptyRows,
    CellClass,
    RowClass,
    StaticSectionClass
  });
  const mutableTree = blankTree.makeTemporaryMutableClone();
  const newRootValue = {
    x: rootX,
    id: json.id,
    width: rootWidth,
    label: json.label,
    type: json.type,
    params: getFilteredParams(json.params),
    styles: json.styles
  };

  if (json.order != null) {
    newRootValue.order = json.order;
  }

  if (json.line_number != null) {
    newRootValue.line_number = json.line_number;
  }

  mutableTree.modifyCellValue(mutableTree.getRootName(), newRootValue);
  (json.rows || []).forEach((jsonRow, rowIndex) => {
    const isStaticSectionRow = getIsStaticSectionFromJSON(jsonRow);
    const rowMetaData = json.rowMetaData && json.rowMetaData.length ? json.rowMetaData[rowIndex] : null;
    const rowNameToImport = json.__rowNamesToImport ? json.__rowNamesToImport[rowIndex] : undefined;

    if (isStaticSectionRow) {
      importStaticSectionHelper(mutableTree, jsonRow, mutableTree.getRootCell(), rowMetaData);
      return;
    }

    importRowHelper(mutableTree, jsonRow, mutableTree.getRootCell(), {
      rowNameToImport,
      rowMetaData
    });
  }); // Hacky way to make sure that the name counter is restored after import/export

  const autogeneratedNameInts = mutableTree.allCellsAndRows().filter(cellOrRow => isGeneratedName(cellOrRow.getName())).map(cellOrRow => extractIntFromGeneratedName(cellOrRow.getName())).sort((a, b) => b - a); // Descending numeric sort

  if (autogeneratedNameInts.length > 0) {
    // Grab highest int from exsiting names and add one
    cloneAndModifyState(mutableTree, {
      nameCtr: autogeneratedNameInts[0] + 1
    });
  }

  mutableTree.validateWholeTree();
  return mutableTree.clone();
};
export const cloneTreeFromLayoutDataApi = (json, {
  stripSmartContentData = true
} = {}) => {
  const importedTree = importTreeFromLayoutDataApi(json);
  return importedTree.cloneWholeTree({
    stripSmartContentData
  });
}; // Remove the tree reference for export

const prepareStaticSectionModulesForExport = staticSectionModule => {
  const restOfModuleAttributes = _objectWithoutPropertiesLoose(staticSectionModule, ["_treeRef"]);

  return restOfModuleAttributes;
};

export const quickTreeExport = (tree, {
  previousTreeToDiffValues
} = {}) => {
  const snapshot = tree.stateSnapshot(); // Diff previous tree and current tree values, so that we can only need to send along values that
  // have changed. This should signifcantly cut down on the size of the export _and_ make it so that
  // post import, existing unchanged value references are maintined (so selectors can still be memoized, etc)

  const unchangedRowValues = [];
  const unchangedColumnValues = []; // Get rid of the inner tree references

  Object.keys(snapshot.columnsByName).forEach(colName => {
    const _snapshot$columnsByNa = snapshot.columnsByName[colName],
          {
      _value
    } = _snapshot$columnsByNa,
          restColAttributes = _objectWithoutPropertiesLoose(_snapshot$columnsByNa, ["_treeRef", "_value"]);

    snapshot.columnsByName[colName] = restColAttributes;
    const prevValue = previousTreeToDiffValues && previousTreeToDiffValues.hasCell(colName) ? previousTreeToDiffValues.findCell(colName).getValue() : undefined;

    if (_value && _value === prevValue) {
      unchangedColumnValues.push(colName);
    } else {
      snapshot.columnsByName[colName]._value = _value;
    }
  });
  Object.keys(snapshot.rowsByName).forEach(rowName => {
    const _snapshot$rowsByName$ = snapshot.rowsByName[rowName],
          {
      _value,
      _staticSectionModules
    } = _snapshot$rowsByName$,
          restRowAttributes = _objectWithoutPropertiesLoose(_snapshot$rowsByName$, ["_treeRef", "_value", "_staticSectionModules"]);

    snapshot.rowsByName[rowName] = restRowAttributes; // Clean up static section modules

    if (_staticSectionModules) {
      snapshot.rowsByName[rowName]._staticSectionModules = _staticSectionModules.map(prepareStaticSectionModulesForExport);
    }

    const prevValue = previousTreeToDiffValues && previousTreeToDiffValues.hasRow(rowName) ? previousTreeToDiffValues.findRow(rowName).getValue() : undefined;

    if (_value && _value === prevValue) {
      unchangedRowValues.push(rowName);
    } else {
      snapshot.rowsByName[rowName]._value = _value;
    }
  });

  if (unchangedRowValues.length > 0) {
    snapshot.unchangedRowValues = unchangedRowValues;
  }

  if (unchangedColumnValues.length > 0) {
    snapshot.unchangedColumnValues = unchangedColumnValues;
  }

  return {
    snapshot,
    numColumns: tree.numColumns
  };
};
export const quickTreeImport = ({
  snapshot,
  numColumns
}, {
  previousTreeToDiffValues
} = {}) => {
  const tree = new LayoutDataTree({
    numColumns
  });

  if ((snapshot.unchangedRowValues || snapshot.unchangedColumnValues) && !previousTreeToDiffValues) {
    throw new Error('Cannot import snapshot with unchangedNodeValues without previousTreeToDiffValues argument');
  } // Refill snapshot with previous values that were unchanged


  if (snapshot.unchangedColumnValues) {
    snapshot.unchangedColumnValues.forEach(colName => {
      snapshot.columnsByName[colName]._value = previousTreeToDiffValues.findCell(colName).getValue();
    });
    delete snapshot.unchangedColumnValues;
  }

  if (snapshot.unchangedRowValues) {
    snapshot.unchangedRowValues.forEach(rowName => {
      snapshot.rowsByName[rowName]._value = previousTreeToDiffValues.findRow(rowName).getValue();
    });
    delete snapshot.unchangedRowValues;
  } // Convert column objects to instances


  Object.keys(snapshot.columnsByName).forEach(colName => {
    const col = snapshot.columnsByName[colName];
    snapshot.columnsByName[colName] = new LayoutDataCell(tree, {
      name: col._name,
      rowNames: col._rowNames,
      parentRowName: col._parentRowName,
      value: col._value
    });
  }); // Convert row objects to instances

  Object.keys(snapshot.rowsByName).forEach(rowName => {
    const row = snapshot.rowsByName[rowName];
    const isStaticSection = getIsStaticSectionFromValue(row._value);

    if (isStaticSection) {
      snapshot.rowsByName[rowName] = new StaticSection(tree, {
        name: row._name,
        parentCellName: row._parentCellName,
        // Maybe put the modules here?
        // Currently keeping hte same shape as the response
        value: row._value
      });
    } else {
      snapshot.rowsByName[rowName] = new RowWithSortedColumns(tree, {
        name: row._name,
        columnNames: row._columnNames,
        parentCellName: row._parentCellName,
        value: row._value
      });
    }
  });
  tree._state = snapshot;
  return tree;
};