'use es6';

import debounce from 'hs-lodash/debounce';
import emptyFunction from 'react-utils/emptyFunction';
import { getBaseApiUrl } from 'ContentEditorUI/redux/selectors/extraInitialStateSelectors';
import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getCategoryId } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getEditingInfo } from 'ContentEditorUI/redux/selectors/editingInfoSelectors';
import { getIsSaveInProgress, getHasUnsavedChanges } from 'ContentEditorUI/redux/selectors/saveDataSelectors';
import { getIsDraft } from 'ContentEditorUI/redux/selectors/publishSelectors';
import { getIsSavePermanentlyDisabled } from 'ContentEditorUI/redux/selectors/saveDataSelectors';
import contentEditLocalStorage from 'ContentEditorUI/utils/contentEditLocalStorage';
import { getSaveQuery, save as requestSave, structuredContentSaveRequest } from 'ContentEditorUI/api/ContentApi';
import logSeleniumEvent from 'ContentEditorUI/utils/logSeleniumEvent';
import { showAndLogSaveError } from 'ContentEditorUI/utils/errorUtils';
import { requestedContentSave, contentSaveSucceeded, contentSaveFailed } from 'ContentEditorUI/redux/actions/appActions';
import { getSubcategory } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getBaseContentUrl } from 'ContentEditorUI/api/ContentApi';
import { getIsHardSavePending } from 'ContentEditorUI/redux/selectors/saveDataSelectors';
const AUTOSAVE_DEBOUNCE_MS = 3000;

const autoSaveEnabled = () => !(contentEditLocalStorage('autoSaveEnabled') === false || document.location.search.match(/autosave=false/));

const defaultIsSaveEnabled = state => !getIsSavePermanentlyDisabled(state);

const defaultModifyOptions = (state, opts) => opts;

const makeSuccess = (dispatch, config, opts) => response => dispatch(contentSaveSucceeded({
  config,
  opts,
  response
}));

const makeError = (dispatch, getState, config, opts) => response => {
  const result = dispatch(contentSaveFailed({
    config,
    opts,
    response
  }));
  showAndLogSaveError(response, getCategoryId(getState()), getSubcategory(getState()));
  return result;
};

const makeOnComplete = ({
  onComplete = emptyFunction
}) => () => {
  logSeleniumEvent('formAfterSave');
  onComplete();
};

const makeStructuredContentSaveAction = config => {
  const {
    apiUrl,
    getIsSaveEnabled = defaultIsSaveEnabled,
    modifyOptions = defaultModifyOptions,
    serialize,
    shouldAvoidSnakeCasing = false
  } = config;
  const onComplete = makeOnComplete(config);
  return opts => (dispatch, getState) => {
    const state = getState();

    if (!getIsSaveEnabled(state)) {
      return Promise.resolve({});
    }

    const isHardSavePending = getIsHardSavePending(state);

    if (isHardSavePending) {
      opts = Object.assign({}, opts, {
        buffer: false
      });
    }

    opts = modifyOptions(state, opts);
    const {
      isAutoSave
    } = opts;
    let {
      buffer,
      data
    } = opts;

    if (buffer === undefined) {
      buffer = isAutoSave;
      opts = Object.assign({}, opts, {
        buffer
      });
    }

    if (data === undefined) {
      data = serialize(state, opts);
      opts = Object.assign({}, opts, {
        data
      });
    }

    const baseContentUrl = apiUrl && apiUrl(state);
    const editingInfo = getEditingInfo(state);
    const defaultQuery = getSaveQuery(editingInfo, shouldAvoidSnakeCasing);
    const request = structuredContentSaveRequest({
      baseContentUrl,
      buffer,
      data,
      query: defaultQuery
    });
    dispatch(requestedContentSave());
    const success = makeSuccess(dispatch, config, opts);
    const error = makeError(dispatch, getState, config, opts);
    return request.then(success).catch(error).finally(onComplete);
  };
};

const makeSaveAction = config => {
  const {
    getIsSaveEnabled = defaultIsSaveEnabled,
    modifyOptions = defaultModifyOptions,
    serialize,
    apiUrl,
    shouldAvoidSnakeCasing = false,
    modifySaveQuery
  } = config;
  const onComplete = makeOnComplete(config);
  return opts => (dispatch, getState) => {
    const state = getState();

    if (!getIsSaveEnabled(state)) {
      return Promise.resolve({});
    }

    const isPublished = !getIsDraft(state);
    const isHardSavePending = getIsHardSavePending(state);

    if (!isPublished && isHardSavePending) {
      opts = Object.assign({}, opts, {
        buffer: false
      });
    }

    opts = modifyOptions(state, opts);
    const {
      isAutoSave
    } = opts;
    let {
      buffer,
      data
    } = opts;
    /**
     * When do we save to the `buffer` vs. do a "hard save" to the `contents` table?
     * (The `contents` table represents the "live" data that is rendered publicly when an object is in the published state.)
     * Note that a "hard save" creates a revision.
     * 1. If NOT published:
     *  a. Save to `buffer` on "autosave" -- DO NOT create a revision
     *  b. Save to `contents` when you hit the save button (isAutoSave is falsy) --
     *     DO create a revision
     * 2. If IS published:
     *  a. ALWAYS save to the buffer. Otherwise we'd be changing the published content,
     *     which must always be done explicitly via a publish action instead.
     * 3. Exceptions:
     *    If `buffer` has manually been set in `opts` by the caller, override the above.
     *    We will still always prevent a hard save if published.
     *    Email DnD uses this to save revisions on auto-save every 5 minutes.
     */

    if (buffer === undefined || isPublished) {
      buffer = isPublished || isAutoSave;
      opts = Object.assign({}, opts, {
        buffer
      });
    }

    if (data === undefined) {
      data = serialize(state, opts);
      opts = Object.assign({}, opts, {
        data
      });
    }

    logSeleniumEvent('formPreSave');
    const baseContentUrl = apiUrl && apiUrl(state) || getBaseContentUrl({
      baseUrl: getBaseApiUrl(state),
      categoryId: getCategoryId(state),
      subcategory: getSubcategory(state),
      contentId: getContentModelId(state)
    });
    const editingInfo = getEditingInfo(state);
    const defaultQuery = getSaveQuery(editingInfo, shouldAvoidSnakeCasing);
    const query = modifySaveQuery ? modifySaveQuery(defaultQuery, state) : defaultQuery;
    const request = requestSave({
      baseContentUrl,
      buffer,
      data,
      query
    });
    dispatch(requestedContentSave());
    const success = makeSuccess(dispatch, config, opts);
    const error = makeError(dispatch, getState, config, opts);
    return request.then(success).catch(error).finally(onComplete);
  };
};

const makeAutoSaveAction = save => opts => (dispatch, getState) => {
  if (!autoSaveEnabled() || !getHasUnsavedChanges(getState())) {
    return Promise.resolve({});
  }

  return dispatch(save(Object.assign({}, opts, {
    isAutoSave: true
  })));
};

const makeAutoSaveDebouncedAction = ({
  maxWaitForAutosave
}, autoSave) => {
  const options = {};

  if (maxWaitForAutosave) {
    options.maxWait = maxWaitForAutosave;
    options.leading = false;
    options.trailing = true;
  }

  return debounce((dispatch, opts) => dispatch(autoSave(opts)), AUTOSAVE_DEBOUNCE_MS, options);
};

const withWait = ({
  wait
}, action) => {
  if (wait) {
    return opts => (dispatch, getState) => wait(getState()).then(() => dispatch(action(opts)));
  }

  return action;
};
/**
 * Construct the save action creators for an editor. Should only be called once.
 * @param {Object} config - The save configuration options:
 * @param {function} config.serialize - A function that accepts the current state and returns the data to save.
 * @param {function} [config.getIsSaveEnabled=defaultIsSaveEnabled] - A function that accepts the current state and returns if a save is allowed.
 * @param {boolean} [config.setModuleErrorsAfterSave=true] - If true, module errors will be set in the moduleErrorReducer.
 * @param {number} [config.maxWaitForAutosave] - An optional debounce timeout -- forces save after `maxWaitForAutosave` milliseconds.
 * @param {function} [config.onComplete=emptyFunction] - An optional function to call after both success and failure.
 * @param {function} [config.modifyOptions=defaultModifyOptions] - An optional function to modify opts before save
 * @param {function} [config.wait] - An optional function that returns a promise to delay action until resolved. Accepts current state.
 * @param {boolean} [config.isStructuredContent] - Overrides default makeSaveAction with makeStructuredContentSaveAction which allows consumers to perform save requests without the buffer
 * The resulting actions will take in an optional `opts` object.
 * @param {Object} opts - Save action options.
 * @param {boolean} [data] - Override data to use in place of calling `config.serialize`.
 * @param {boolean} [isAutoSave] - Was this triggered manually or by making a change? Note this will always be overridden to true when calling `autoSave` or `autoSaveDebounced`.
 * @param {boolean} [buffer] - Overrides default buffer logic, can be used to force a revision if set to true.
 */


export default function makeSaveActions(config) {
  let savePromise = null;
  let _autoSaveDebounced = null;
  const {
    isStructuredContent = false,
    setModuleErrorsAfterSave
  } = config;

  if (setModuleErrorsAfterSave === undefined) {
    config = Object.assign({}, config, {
      setModuleErrorsAfterSave: true
    });
  }

  const baseSave = isStructuredContent ? makeStructuredContentSaveAction(config) : makeSaveAction(config);

  const saveWithoutWait = (opts = {}) => (dispatch, getState) => {
    if (getIsSaveInProgress(getState())) {
      return savePromise;
    }

    if (!opts.isAutoSave) {
      // If the save button is hit manually, cancel any queued up auto-saves
      _autoSaveDebounced.cancel();
    }

    savePromise = dispatch(baseSave(opts));
    return savePromise;
  };

  const save = withWait(config, saveWithoutWait);
  const autoSaveWithoutWait = makeAutoSaveAction(saveWithoutWait);
  const autoSave = withWait(config, autoSaveWithoutWait); // This two-function setup is required to make debounce work properly with redux-thunk

  _autoSaveDebounced = makeAutoSaveDebouncedAction(config, autoSave);

  const autoSaveDebounced = opts => dispatch => _autoSaveDebounced(dispatch, opts);

  return {
    save,
    autoSave,
    autoSaveDebounced
  };
}