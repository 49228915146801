import { createSelector } from 'reselect';

const getIconsSelect = state => state.resources.icons.iconsSelect;

const getIsFetching = state => state.resources.icons.isFetching;

const getSelectedIcons = state => state.resources.icons.icons;

export const getIcon = (state, props) => state.resources.icons.icons[props.name];
export const getIconFetchStatus = (state, props) => state.resources.icons.requestStatus[props.name];
export const makeGetIconData = () => {
  return createSelector([getIconsSelect, getIsFetching, getSelectedIcons], (iconsSelect, isFetching, selectedIcons) => {
    const {
      icons,
      totalIcons,
      hasMoreIcons
    } = iconsSelect;
    return {
      hasMoreIcons,
      icons,
      isFetching,
      selectedIcons,
      totalIcons
    };
  });
};