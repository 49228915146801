import I18n from 'I18n';
import { GENERAL } from 'self-service-api/constants/UpgradeProducts';
import { getProductNameText, getProductNameTextFromApiName } from 'ui-addon-upgrades/_core/common/adapters/getProductNameText';

const _getButtonText = (i18nKey, productTitle) => {
  return I18n.text(`upgrades.upgradeButton.${i18nKey}`, {
    productTitle
  });
};

export const getUpgradeButtonText = (upgradeType, upgradeProduct) => {
  if (upgradeProduct === GENERAL) return I18n.text('upgrades.upgradeButton.default');
  const productTitle = getProductNameText(upgradeProduct);
  return _getButtonText(upgradeType, productTitle);
};
export const getUpgradeButtonTextFromApiName = (upgradeType, apiName) => {
  if (apiName === 'GENERAL') {
    return I18n.text('upgrades.upgradeButton.default');
  }

  const productTitle = getProductNameTextFromApiName(apiName);
  return _getButtonText(upgradeType, productTitle);
};