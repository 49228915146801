export function getPagesFromMenu(menuPagesTree) {
  const pages = [];
  if (!menuPagesTree) return pages;
  menuPagesTree.forEach(menuPage => {
    pages.push(menuPage);

    if (menuPage.children) {
      pages.push(...getPagesFromMenu(menuPage.children));
    }
  });
  return pages;
}