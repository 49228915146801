'use es6';

import * as actionTypes from 'ContentEditorUI/redux/actions/actionTypes';
import { fetch } from 'ContentEditorUI/api/ContentEditApi';
import { MODAL_TYPES } from 'ContentEditorUI/lib/modalConstants';
import { getCategoryNameFromRawContentSchema } from 'ContentEditorUI/lib/categories/utils';
import { fetchAddableSections } from 'ContentEditorUI/redux/actions/templateInfoActions';
import { getIsUngatedForMergeInDefaultValues, getIsUngatedForDndAreasInCustomEditor } from 'ContentEditorUI/redux/selectors/authSelectors';
import { fetchCustomFontsByThemePath, fetchThemeAndSettingsByPath } from 'ContentEditorUI/redux/actions/themeActions';
import { getFeatureFlag } from 'ContentEditorUI/redux/selectors/featureFlagsSelectors';
import { getIsCustomEmail } from 'ContentEditorUI/redux/selectors/extraInitialStateSelectors';
import { getEditingInfo } from 'ContentEditorUI/redux/selectors/editingInfoSelectors';
import { captureMessage } from 'ContentEditorUI/lib/exceptions';
import { FEATURE_FLAGS } from '../constants';
export const startApp = () => ({
  type: actionTypes.APP_STARTED
});
export const showLoading = () => ({
  type: actionTypes.SHOW_LOADING
});
export const hideLoading = () => ({
  type: actionTypes.HIDE_LOADING
});
export const setIframeReady = () => ({
  type: actionTypes.SET_IFRAME_READY
});
export const setIframeRendered = () => ({
  type: actionTypes.SET_IFRAME_RENDERED
});
export const setFlexAreaIndex = index => ({
  type: actionTypes.SET_FLEX_AREA_INDEX,
  index
});
export const setIframeHidden = iframeHidden => ({
  type: actionTypes.SET_IFRAME_HIDDEN,
  payload: {
    iframeHidden
  }
});
export const setIframeLoading = (options = {
  scrollIfAnyBoundsOutOfView: false
}) => ({
  type: actionTypes.SET_IFRAME_LOADING,
  payload: {
    startedLoadingAt: Date.now(),
    scrollIfAnyBoundsOutOfView: options.scrollIfAnyBoundsOutOfView
  }
});
/**
 * The sidebar is now ready to render all of it's contents.
 * The iframe, and any relevant modules are fully loaded.
 */

export const setAddModuleSidebarLoaded = () => ({
  type: actionTypes.SET_ADD_MODULE_SIDEBAR_LOADED
});
export const reload = () => dispatch => {
  dispatch(showLoading());
  window.location.reload(); // Is this sort of side-effect a no-no in an actionCreator?
};
export const reloadWithoutWarning = () => dispatch => {
  dispatch({
    type: actionTypes.RELOAD_WITHOUT_WARNING
  });
  window.location.reload();
};
export const fetchContentSchemaSucceeded = response => (dispatch, getState) => {
  // Fetch sections after response success
  // Needed so we which sections to show when clicking add from Remy
  const hasSectionAccess = getFeatureFlag(FEATURE_FLAGS.sections);
  const themePath = response.theme_path;

  if (themePath && hasSectionAccess) {
    dispatch(fetchAddableSections(themePath));
  }

  return dispatch({
    type: actionTypes.FETCH_CONTENT_SCHEMA_SUCCEEDED,
    response: Object.assign({
      categoryName: getCategoryNameFromRawContentSchema(response)
    }, response),
    // Little hack, more info in moduleReducer
    isUngatedForMergeInDefaultValues: getIsUngatedForMergeInDefaultValues(getState()),
    isCustomEmailUngatedForFlexAreasAndNewSidebar: getIsUngatedForDndAreasInCustomEditor(getState()) && getIsCustomEmail(getState())
  });
};

const fetchContentSchemaFailed = error => dispatch => {
  if (error.redirectUrl) {
    window.location = error.redirectUrl;
  }

  console.error('Error fetching content schema', error);
  return dispatch({
    type: actionTypes.FETCH_CONTENT_SCHEMA_FAILED,
    error
  });
};

export const updateEditingInfo = updatedInfo => ({
  type: actionTypes.UPDATE_EDITING_INFO,
  updatedInfo
});
export const onFetchContentSchemaSuccess = ({
  result
}) => dispatch => {
  dispatch(fetchCustomFontsByThemePath(result.theme_path));

  if (result.theme_path) {
    dispatch(fetchThemeAndSettingsByPath(result.theme_path));
  }

  return dispatch(fetchContentSchemaSucceeded(result));
};
export const onFetchContentSchemaFailed = ({
  result
}) => dispatch => {
  return dispatch(fetchContentSchemaFailed(result));
};
export const fetchContentSchema = (contentId, preProcessFunc, includeUsedModuleSchemas = false) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.FETCH_CONTENT_SCHEMA_STARTED
  });

  const preProcessSchema = resp => {
    let schema = resp;

    if (typeof preProcessFunc === 'function') {
      schema = preProcessFunc(resp, getState(), dispatch);
    }

    return schema;
  };

  return fetch(contentId, includeUsedModuleSchemas).then(preProcessSchema).then(result => dispatch(onFetchContentSchemaSuccess({
    result
  })), result => dispatch(onFetchContentSchemaFailed({
    result
  })));
};
export const iframeClicked = () => ({
  type: actionTypes.IFRAME_CLICKED
});
export const requestedContentSave = () => ({
  type: actionTypes.CONTENT_SAVE_REQUEST_STARTED
});
export const contentSaveSucceeded = payload => ({
  type: actionTypes.CONTENT_SAVE_REQUEST_SUCCEEDED,
  payload
});
export const contentSaveFailed = error => ({
  type: actionTypes.CONTENT_SAVE_REQUEST_FAILED,
  error
});
export const requestedContentPublish = () => ({
  type: actionTypes.CONTENT_PUBLISH_REQUEST_STARTED
});
export const contentPublishSucceeded = () => ({
  type: actionTypes.CONTENT_PUBLISH_REQUEST_SUCCEEDED
});
export const contentPublishFailed = error => ({
  type: actionTypes.CONTENT_PUBLISH_REQUEST_FAILED,
  error
});
export const permanentlyDisableSaving = () => ({
  type: actionTypes.PERMANENTLY_DISABLE_SAVING
});
export const closeModal = () => ({
  type: actionTypes.CLOSE_MODAL
});
export const openModal = (modalName, metadata) => ({
  type: actionTypes.OPEN_MODAL,
  modalName,
  metadata
});
export const openConfirmEditGlobal = globalId => ({
  type: actionTypes.OPEN_MODAL,
  modalName: MODAL_TYPES.CONFIRM_EDIT_GLOBAL,
  metadata: {
    id: globalId
  }
});
export const closePopoutEditor = () => ({
  type: actionTypes.CLOSE_POPOUT_EDITOR
});
export const openPopoutEditor = fieldKeyArray => ({
  type: actionTypes.OPEN_POPOUT_EDITOR,
  fieldKeyArray
});
export const hideIframe = () => dispatch => {
  dispatch(openModal(MODAL_TYPES.FORCE_IFRAME_HIDE));
};
export const setDirtyFlag = (isDirty, isHardSavePending = false, isFromAutoSaveContainer = false) => ({
  type: actionTypes.SET_DIRTY_FLAG,
  payload: {
    isDirty,
    isHardSavePending,
    isFromAutoSaveContainer
  }
});
export const validationFinished = ({
  valid,
  errors
}) => ({
  type: actionTypes.EDIT_FORM_VALIDATION_FINISHED,
  valid,
  errors
});
export const togglePublishModalOpen = open => ({
  type: actionTypes.TOGGLE_PUBLISH_MODAL_OPEN,
  open
});
export const togglePublishOptionsModalOpen = open => ({
  type: actionTypes.TOGGLE_PUBLISH_OPTIONS_MODAL_OPEN,
  open
});
export const toggleAddToWorkflowModalOpen = () => openModal(MODAL_TYPES.ADD_TO_WORKFLOW_AFTER_PUBLISH);
export const toggleApplyChangesModalOpen = (open, opts = null) => ({
  type: actionTypes.TOGGLE_APPLY_CHANGES_MODAL_OPEN,
  open,
  payload: opts
});
export const updateOptimisticLockingForRequest = ({
  hasSaved,
  xLastModifiedTimestampHeader
}) => dispatch => {
  const newInfoProperties = {};
  newInfoProperties.hasSaved = hasSaved;

  if (xLastModifiedTimestampHeader) {
    newInfoProperties.last_edit_ticks = xLastModifiedTimestampHeader;
  }

  if (hasSaved) {
    newInfoProperties.lastModifiedDate = xLastModifiedTimestampHeader ? new Date(parseInt(xLastModifiedTimestampHeader, 10)) : new Date();
    newInfoProperties.lastModified = newInfoProperties.lastModifiedDate.toUTCString();
  }

  dispatch(updateEditingInfo(newInfoProperties));
};
export const updateOptimisticLockingOnFetchContent = ({
  updated: trueUpdatedTimestamp,
  updatedById
}) => (dispatch, getState) => {
  const {
    last_edit_ticks: maybeLastEditTicksString
  } = getEditingInfo(getState());
  const last_edit_ticks = parseInt(maybeLastEditTicksString, 10);

  if (trueUpdatedTimestamp <= last_edit_ticks) {
    return;
  }

  captureMessage('Optimistic locking out of sync: Server `updated` timestamp > X-Last-Modified-Timestamp header', {
    xLastModifiedTimestamp: last_edit_ticks,
    trueUpdatedTimestamp
  });
  const lastModifiedDate = new Date(parseInt(trueUpdatedTimestamp, 10));
  dispatch(updateEditingInfo({
    last_edit_ticks: trueUpdatedTimestamp,
    lastModified: trueUpdatedTimestamp,
    lastModifiedDate,
    updatedById
  }));
};
export const setCritsitFlags = payload => ({
  type: actionTypes.SET_CRITSIT_FLAGS,
  payload
});
export const displayExitPreviewAndLayoutButton = () => {
  return {
    type: actionTypes.DISPLAY_EXIT_PREVIEW_AND_LAYOUT_BUTTON
  };
};
export const hideExitPreviewAndLayoutButton = () => {
  return {
    type: actionTypes.CLOSE_EXIT_PREVIEW_AND_LAYOUT_BUTTON
  };
};
export const dismissScopesHaveChangedModal = () => {
  return {
    type: actionTypes.DISMISS_SCOPES_HAVE_CHANGED_MODAL
  };
};
export const showMatchingAppModal = location => {
  return {
    type: actionTypes.SHOW_MATCHING_APP_MODAL,
    payload: {
      location
    }
  };
};
export const hideMatchingAppModal = () => {
  return {
    type: actionTypes.HIDE_MATCHING_APP_MODAL
  };
};
export const redirectToManageSectionsPanel = () => {
  return {
    type: actionTypes.REDIRECT_TO_DELETE_SECTIONS
  };
};
export const setSelectedUpgradableModule = selectedPqlId => {
  return {
    type: actionTypes.UPDATE_SELECTED_UPGRADABLE_MODULE_PQL,
    payload: {
      selectedPqlId
    }
  };
};
export const clearAddSectionMissingModuleErrors = () => ({
  type: actionTypes.CLEAR_ADD_SECTION_MISSING_MODULE_ERRORS
});
export const toggleRunTestModal = type => {
  return {
    type: actionTypes.TOGGLE_RUN_TEST_MODAL,
    payload: type
  };
};
export const setTestType = type => {
  return {
    type: actionTypes.SET_TEST_TYPE,
    payload: type
  };
};
export const togglePostPublishDomainSetupModal = type => {
  return {
    type: actionTypes.TOGGLE_POST_PUBLISH_DOMAIN_SETUP_MODAL,
    payload: type
  };
};