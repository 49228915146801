'use es6';

import { SET_MODULE_ERRORS, FETCH_MODULE_ERRORS_STARTED, FETCH_MODULE_ERRORS_SUCCEEDED, FETCH_MODULE_ERRORS_FAILED } from 'ContentEditorUI/redux/actions/actionTypes';
import { fetch } from 'ContentEditorUI/api/ModuleErrorsApi';
export const setModuleErrors = response => {
  return {
    type: SET_MODULE_ERRORS,
    response
  };
}; // REQUEST FAILURES ---------------------->

const fetchModuleErrorsFailure = error => {
  return {
    type: FETCH_MODULE_ERRORS_FAILED,
    error
  };
}; // REQUEST SUCCESSES --------------------->


const fetchModuleErrorsSuccess = response => {
  return {
    type: FETCH_MODULE_ERRORS_SUCCEEDED,
    response
  };
}; // REQUESTS ------------------------------>


export const fetchModuleErrors = contentId => dispatch => {
  dispatch({
    type: FETCH_MODULE_ERRORS_STARTED
  });
  fetch(contentId).then(resp => {
    if (resp) {
      dispatch(fetchModuleErrorsSuccess(resp));
    } else {
      dispatch(fetchModuleErrorsFailure(resp));
    }
  }).catch(resp => dispatch(fetchModuleErrorsFailure(resp)));
};