'use es6';

import guessCountryFromDialCode from './guessCountryFromDialCode';
export default ((value, numberFormat = '') => {
  let numberText = value;
  let pattern = numberFormat;

  if (!pattern) {
    numberText = value.replace(/\D/g, '');
    const country = value[0] === '+' && guessCountryFromDialCode(numberText);
    pattern = country && country.format;
  }

  if (!pattern) {
    return value;
  } // for all strings with length less than 3, just return it


  if (numberText && numberText.length < 2 || !pattern) {
    return `+${numberText.replace(/\D/g, '')}`;
  }

  const formattedObject = pattern.split('').reduce((acc, character) => {
    if (acc.remainingText.length === 0) {
      return acc;
    }

    if (character !== '.') {
      return {
        formattedText: acc.formattedText + character,
        remainingText: acc.remainingText
      };
    }

    return {
      formattedText: acc.formattedText + acc.remainingText[0],
      remainingText: acc.remainingText.slice(1)
    };
  }, {
    formattedText: '',
    remainingText: numberText
  });
  return formattedObject.formattedText + formattedObject.remainingText;
});