'use es6';

import { SET_MODULE_ERRORS, CONTENT_SAVE_REQUEST_SUCCEEDED, FETCH_MODULE_ERRORS_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import Immutable from 'immutable';
import { convertObjectToCamelCase } from 'ContentEditorUI/utils/helpers';
const EMPTY_LIST = Immutable.List();
export default ((state = EMPTY_LIST, {
  type,
  response,
  payload
}) => {
  switch (type) {
    case SET_MODULE_ERRORS:
      return Immutable.fromJS(response.errors.map(convertObjectToCamelCase));

    case CONTENT_SAVE_REQUEST_SUCCEEDED:
      {
        const {
          config: {
            setModuleErrorsAfterSave
          },
          response: {
            data: {
              errors = []
            }
          }
        } = payload;

        if (setModuleErrorsAfterSave) {
          return Immutable.fromJS(errors.map(convertObjectToCamelCase));
        }

        return state;
      }

    case FETCH_MODULE_ERRORS_SUCCEEDED:
      return Immutable.fromJS(response);

    default:
      return state;
  }
});