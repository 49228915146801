import { createGenericFetchAction } from 'ContentData/helpers/reduxHelpers';
import * as tagsClient from 'ContentData/api/Tags';
import { getTagCache } from 'ContentData/selectors/tags';
import { TAGS_FETCH_TAG, TAGS_FETCH_TAG_BY_SLUG, TAGS_FETCH_TAG_BY_NAME, TAGS_FETCH_TAGS, TAGS_SAVE_TAG_CACHE, TAGS_CLEAR_CACHE } from 'ContentData/actions/ActionTypes';
import { ID, NAME, ALL } from 'ContentUtils/enums/TagDefaultValueTypes';
const fetchTagAction = createGenericFetchAction(TAGS_FETCH_TAG, tagsClient.fetchTag);
const fetchTagBySlugAction = createGenericFetchAction(TAGS_FETCH_TAG_BY_SLUG, tagsClient.fetchTagBySlug);
const fetchTagByNameAction = createGenericFetchAction(TAGS_FETCH_TAG_BY_NAME, tagsClient.fetchTagByName);
const fetchTagsAction = createGenericFetchAction(TAGS_FETCH_TAGS, tagsClient.fetchTags);
export function fetchTag(value, tagValue) {
  if (tagValue === ID) {
    return fetchTagAction(value);
  }

  if (tagValue === NAME) {
    return fetchTagByNameAction(value);
  }

  if (value && tagValue === ALL) {
    return fetchTagByNameAction(value.name);
  }

  return fetchTagBySlugAction(value);
}
export function fetchTags(query) {
  return fetchTagsAction(query);
}
export function saveCache(value, tagValue) {
  return (dispatch, getState) => {
    const tag = getTagCache(getState(), {
      value,
      tagValue
    });
    dispatch({
      type: TAGS_SAVE_TAG_CACHE,
      tag
    });
  };
}
export function clearCache() {
  return {
    type: TAGS_CLEAR_CACHE
  };
}