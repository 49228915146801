import { createSelector } from 'reselect';
import { getAllSortedAddableModuleSchemas, isRestrictedBlogModule, getSortedStarterTemplateModules, getCustomSortedBuiltInModuleSchemas, getBuiltInTypesByModuleId, getUpgradableModuleTypeToAccessMap } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors'; // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Cont... Remove this comment to see the full error message

import { getIsStarterPageTemplate } from 'ContentEditorUI/redux/selectors/templateInfoSelectors';
import { getThemePath, getParentThemePath } from 'ContentEditorUI/redux/selectors/themeSelectors';
import { getIsBlogPost } from './contentReadOnlyDataSelectors';
import { filterOutParentThemeModulesOverriddenInChildTheme, isModuleAPartOfTheme } from 'ContentEditorUI/lib/themeUtils';
import { PATAGONIA_ADD_PANEL_SUB_CATEGORIES } from 'ContentEditorUI/constants/Sidebar';
export const getCategoryToModulesMap = createSelector([getAllSortedAddableModuleSchemas, getThemePath, getParentThemePath, getIsBlogPost, getIsStarterPageTemplate, getCustomSortedBuiltInModuleSchemas, getBuiltInTypesByModuleId, getUpgradableModuleTypeToAccessMap], (allSortedAddableModuleSchemas, themePath, parentThemePath, isBlogPost, isStarterPageTemplate, sortedBuiltinModules, builtInTypesByModuleIntegerId, upgradableModuleTypeToAccessMap) => {
  const categoryToModulesMap = {};
  const filteredModuleSchemas = isStarterPageTemplate ? // only show starter template modules on stater templates
  getSortedStarterTemplateModules(sortedBuiltinModules, builtInTypesByModuleIntegerId, upgradableModuleTypeToAccessMap) : //filter parent overridden in child modules immediately to lessen items in module schema to loop through
  filterOutParentThemeModulesOverriddenInChildTheme(allSortedAddableModuleSchemas, themePath, parentThemePath);
  const hasTheme = Boolean(themePath);
  filteredModuleSchemas.forEach(m => {
    const isNotBlogOrRestrictedBlog = !isBlogPost || !isRestrictedBlogModule(m);
    const isAvailableForNewContent = m.isAvailableForNewContent !== false;
    const hasAccessToModule = !upgradableModuleTypeToAccessMap.hasOwnProperty(builtInTypesByModuleIntegerId[m.moduleId]) || upgradableModuleTypeToAccessMap[builtInTypesByModuleIntegerId[m.moduleId]];

    if (isNotBlogOrRestrictedBlog && isAvailableForNewContent && hasAccessToModule) {
      if (categoryToModulesMap[PATAGONIA_ADD_PANEL_SUB_CATEGORIES.MODULES.ALL]) {
        categoryToModulesMap[PATAGONIA_ADD_PANEL_SUB_CATEGORIES.MODULES.ALL].push(m);
      } else {
        categoryToModulesMap[PATAGONIA_ADD_PANEL_SUB_CATEGORIES.MODULES.ALL] = [m];
      }

      if (hasTheme && isModuleAPartOfTheme(m.path, themePath, parentThemePath)) {
        // TODO: Circle back to this and clean it up into a single util
        if (categoryToModulesMap[PATAGONIA_ADD_PANEL_SUB_CATEGORIES.MODULES.THEME]) {
          categoryToModulesMap[PATAGONIA_ADD_PANEL_SUB_CATEGORIES.MODULES.THEME].push(m);
        } else {
          categoryToModulesMap[PATAGONIA_ADD_PANEL_SUB_CATEGORIES.MODULES.THEME] = [m];
        }
      }

      if (m.categories && m.categories.length) {
        m.categories.forEach(category => {
          const categoryType = category.toUpperCase();

          if (categoryToModulesMap[categoryType]) {
            categoryToModulesMap[categoryType].push(m);
          } else {
            categoryToModulesMap[categoryType] = [m];
          }
        });
      }
    }
  });
  return categoryToModulesMap;
});