import { DONE, NOT_STARTED } from '../constants/TourStates';
export default (tour => {
  const step = tour.getStep();
  const tourSteps = tour.getConfig().steps;

  if (step === NOT_STARTED) {
    return tourSteps[0];
  }

  const nextIndex = tourSteps.indexOf(step) + 1;
  return nextIndex >= tourSteps.length ? DONE : tourSteps[nextIndex];
});