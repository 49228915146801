import { CompletionStatuses } from '../../../../../constants';
import PortalIdParser from 'PortalIdParser';
export const addHyperlinkToPillarPageAudit = (links, attachedCluster) => {
  const portalId = PortalIdParser.get() || 0;
  const {
    clusterPhrase,
    subtopicPhrase,
    pillarPageUrl,
    clusterId
  } = attachedCluster;

  if (!subtopicPhrase || !clusterPhrase || !links) {
    return {
      status: CompletionStatuses.NOT_APPLICABLE
    };
  }

  if (!pillarPageUrl) {
    const clusterLink = `/content-strategy/${portalId}/clusters/${clusterId}`;
    return {
      status: CompletionStatuses.INCOMPLETE,
      clusterPhrase,
      clusterLink
    };
  }

  const isLinkedToPillarPage = links.find(link => {
    const {
      href
    } = link;
    return href && href.includes(pillarPageUrl);
  });

  if (isLinkedToPillarPage) {
    return {
      status: CompletionStatuses.COMPLETE,
      clusterPhrase
    };
  }

  const anchor = `<a href=${pillarPageUrl}>${clusterPhrase}</a>`;
  return {
    status: CompletionStatuses.INCOMPLETE,
    clusterPhrase,
    anchor
  };
};