import createChainablePropType from './createChainablePropType';
const tshirtLonghandMap = {
  xs: 'extra-small',
  sm: 'small',
  md: 'medium',
  lg: 'large',
  xl: 'extra-large'
};
const tshirtShorthandMap = {
  'extra-small': 'xs',
  small: 'sm',
  medium: 'md',
  large: 'lg',
  'extra-large': 'xl'
};
/**
 * @param {string} size
 * @returns {string} The shorthand size corresponding to the given longhand size, or `size` itself.
 */

export const toShorthandSize = size => tshirtShorthandMap[size] || size;
const shortNames = Object.keys(tshirtLonghandMap);
/**
 * @param  [] supportedSizes
 * @param  [] extras - add custom size names here and maybe we'll let it slide
 * @default ['xs', 'sm', 'md', 'lg', 'xl']
 */

export const propTypeForSizes = (supportedSizes = shortNames, extras = []) => {
  if (!Array.isArray(supportedSizes)) {
    throw new Error(`expected sizes array, got ${supportedSizes}`);
  }

  if (!supportedSizes.length || !supportedSizes.every(size => tshirtLonghandMap[size])) {
    throw new Error(`expected sizes array to include only valid sizes from [xs, sm, md, lg, xl], got [${supportedSizes.join(', ')}]`);
  }

  const mostValues = [...supportedSizes, ...supportedSizes.map(k => tshirtLonghandMap[k])];
  const allValues = [...mostValues, ...extras];

  const validator = (props, propName, componentName) => {
    if (props[propName]) {
      const value = props[propName];

      if (allValues.indexOf(value) === -1) {
        return new Error(`${componentName}: Invalid ${propName} value. Expected T-Shirt Size (${mostValues.join(', ')}), got "${value}".`);
      }
    }

    return null;
  };

  return createChainablePropType(validator, 'tshirtSize', {
    sizes: supportedSizes,
    aliases: tshirtLonghandMap
  });
};
export const allSizes = propTypeForSizes();