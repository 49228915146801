import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import http from 'hub-http/clients/apiClient';
import ContentRoutes from 'ContentUtils/Routes';
import I18n from 'I18n'; // @ts-expect-error Untyped module

import { getActiveDomainName } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { createBlogPost as createBlogPostApi, createBlogListingPage as createBlogListingPageApi } from 'ContentEditorUI/api/ContentActionsApi'; // @ts-expect-error Untyped module

import BlogsApi from 'ContentEditorUI/api/BlogsApi'; // @ts-expect-error Untyped module

import { showErrorWithHTML } from 'ContentEditorUI/utils/errorUtils';
import UsageTracker from 'ContentEditorUI/utils/UsageTracker';
import { CONTENT_MANAGEMENT_ACTION } from '../constants/contentManagement';
const BLOG_URI = 'blog-settings/v1/blogs';
const DEFAULT_OPTIONS = {
  offset: 0,
  limit: 300,
  order: 'name',
  property: ['absoluteUrl', 'id', 'portalId', 'name', 'resolvedDomain', 'translatedFromId']
};

const useBlogEmptyStateData = () => {
  const domainName = useSelector(getActiveDomainName);
  const [requestStatus, setRequestStatus] = useState('UNINITIALIZED');
  const [blogsData, setBlogsData] = useState({
    total: 0,
    blogs: []
  });
  const hasNoBlogs = requestStatus === 'SUCCEEDED' && blogsData.total === 0;
  const blogInCurrentDomain = blogsData.blogs.find(blog => blog.resolvedDomain === domainName && !blog.translatedFromId);
  const hasNoBlogInCurrentDomain = requestStatus === 'SUCCEEDED' && !blogInCurrentDomain;
  useEffect(() => {
    if (requestStatus === 'UNINITIALIZED') {
      setRequestStatus('PENDING');
      http.get(BLOG_URI, {
        query: DEFAULT_OPTIONS
      }).then(({
        total,
        objects
      }) => {
        setBlogsData({
          total,
          blogs: objects
        });
        setRequestStatus('SUCCEEDED');
      }, () => setRequestStatus('FAILED')).catch(() => setRequestStatus('FAILED'));
    }
  }, [requestStatus]);
  const createBlogPost = useCallback(() => {
    if (blogInCurrentDomain) {
      createBlogPostApi(blogInCurrentDomain.id, blogInCurrentDomain.language).then(({
        id,
        categoryId
      }) => {
        UsageTracker.trackAsBeacon('editorInteraction', {
          action: CONTENT_MANAGEMENT_ACTION,
          event: 'create-blog-post-success'
        });
        window.location.href = ContentRoutes.edit(id, categoryId);
      }).catch(error => {
        UsageTracker.trackEditorInteraction({
          action: CONTENT_MANAGEMENT_ACTION,
          event: 'create-blog-post-failed'
        });
        showErrorWithHTML({
          response: error,
          action: 'create-blogPost'
        });
      });
    }
  }, [blogInCurrentDomain]);
  const createBlogListingPage = useCallback(() => {
    if (!blogInCurrentDomain) {
      return;
    }

    const name = I18n.text('contentManagementPanel.table.defaultBlogListingPageName', {
      blogName: blogInCurrentDomain.name
    });
    createBlogListingPageApi(blogInCurrentDomain.id, name).then(({
      id,
      categoryId
    }) => {
      UsageTracker.trackAsBeacon('editorInteraction', {
        action: CONTENT_MANAGEMENT_ACTION,
        event: 'create-blog-listing-page-success'
      });
      BlogsApi.updateBlog(blogInCurrentDomain.id, {
        listingPageId: id
      }).then(() => {
        window.location.href = ContentRoutes.edit(id, categoryId);
      }).catch();
    }).catch(error => {
      UsageTracker.trackEditorInteraction({
        action: CONTENT_MANAGEMENT_ACTION,
        event: 'create-blog-listing-page-failed'
      });
      showErrorWithHTML({
        response: error,
        action: 'create-blogListingPage'
      });
    });
  }, [blogInCurrentDomain]);
  return {
    hasNoBlogs,
    hasNoBlogInCurrentDomain,
    createBlogPost,
    createBlogListingPage
  };
};

export default useBlogEmptyStateData;