import memoize from 'react-utils/memoize';

const capitalize = str => str[0].toUpperCase() + str.slice(1);

const uncapitalize = str => str[0].toLowerCase() + str.slice(1);

export const getDefaultPropName = memoize( // @ts-expect-error Memoize supports number & string, but we only support string
field => `default${capitalize(field)}`);
export const getOnChangeName = memoize(field => {
  if (field === 'value') {
    return 'onChange';
  } // @ts-expect-error Memoize supports number & string, but we only support string


  return `on${capitalize(field)}Change`;
});
export const getPropFromDefaultName = memoize(defaultProp => {
  if (!defaultProp) {
    return '';
  } // @ts-expect-error Memoize supports number & string, but we only support string


  return uncapitalize(defaultProp.replace('default', ''));
});