'use es6';

import enviro from 'enviro';
import { isGroupedAction, isIgnoredAction } from 'ContentEditorUI/utils/undoRedoUtils';
import { withUndoableHistory, GROUP_SPLIT_TYPES } from 'ContentEditorUI/redux/reducers/withUndoableHistory';
import { MODULE_REDUCER_CHANGE_UNDO, MODULE_REDUCER_CHANGE_REDO, UPDATE_FAKE_UNDOABLE_ITEMS } from 'ContentEditorUI/redux/actions/actionTypes';
const ACTION_TYPES_TO_IGNORE = [UPDATE_FAKE_UNDOABLE_ITEMS];

const filter = action => {
  return !isIgnoredAction(action.type, ACTION_TYPES_TO_IGNORE);
}; // Groups actions if the returned action type matches the previous groupBy result


const groupBy = action => {
  const {
    undoData
  } = action;

  if (isGroupedAction(action.type)) {
    if (undoData && undoData.groupKey) {
      return undoData.groupKey;
    }

    return action.type;
  }

  return null;
}; // Adds granularity control for grouped action types


const FORCE_SPLIT_AFTER_MS = 2000;

const splitGrouping = (action, history) => {
  const {
    undoData
  } = action; // If bypassSplit is set, skip all split logic

  if (undoData && undoData.bypassSplit) {
    return GROUP_SPLIT_TYPES.DO_NOT_SPLIT;
  } // If splitNext is set, include this action in the current grouping,
  // but the next action should start a new grouping.


  if (undoData && undoData.splitNext) {
    return GROUP_SPLIT_TYPES.SPLIT_NEXT;
  } // Check if enough time has passed since the start of this grouping to split it.


  const currentTime = Date.now();
  const elapsedTime = currentTime - history.get('currentPresentStart');

  if (elapsedTime > FORCE_SPLIT_AFTER_MS) {
    return GROUP_SPLIT_TYPES.SPLIT_NOW;
  } // Allow this action to be grouped


  return GROUP_SPLIT_TYPES.DO_NOT_SPLIT;
};

const createUndoableModuleReducer = reducer => withUndoableHistory(reducer, {
  debug: enviro.debug('DEBUG_UNDO_REDO') || false,
  filter,
  groupBy,
  ignoreInitialState: true,
  // Don't allow undoing back to the empty state (pre-FETCH_CONTENT_SCHEMA_SUCCEEDED)
  limit: 50,
  redoType: MODULE_REDUCER_CHANGE_REDO,
  splitGrouping,
  undoType: MODULE_REDUCER_CHANGE_UNDO
});

export default createUndoableModuleReducer;