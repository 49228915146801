//legacyModule:hubspot.ContentEditorUI.lib.SaveShortcutChecker

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use es6';

export default {
  isSaveShortcut(event) {
    const sPressed = event.keyCode === 83;
    const ctrlPressed = event.ctrlKey; // for checking non-OSX

    const altPressed = event.altKey; // for checking ctrl+alt together

    const cmdPressed = event.metaKey; // cmd on OSX, Win key on Windows

    return sPressed && (cmdPressed || ctrlPressed && !altPressed);
  },

  listenForSaveRequest(callback) {
    window.addEventListener('keydown', event => {
      if (!this.isSaveShortcut(event)) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      callback();
    });
  }

};