import { ASSIGN_SEAT, BUY_ADDITIONAL_SEATS, REQUEST_SEAT, VIEW_ACCOUNT } from 'ui-addon-upgrades/_core/purchaseMotions/PurchaseMotionTypes';
import { CMS_HUB_STARTER, CRM_SUITE_STARTER_BUNDLE, MARKETING_HUB_STARTER, OPERATIONS_HUB_STARTER, SALES_HUB_STARTER, SERVICE_HUB_STARTER } from 'self-service-api/constants/ApiNames';
import ApiNameToUpgradeProductMap from 'self-service-api/constants/ApiNameToUpgradeProductMap';
import { ENTERPRISE, STARTER } from 'self-service-api/constants/ProductLevels';
import ProductTierOrders from 'self-service-api/constants/ProductTierOrders';
import { MARKETING_STARTER, MARKETING_STARTER_EMAIL, SUITE_STARTER } from 'self-service-api/constants/UpgradeProducts'; // @ts-expect-error dependency missing types

import UpgradeProductToApiNameMap from 'self-service-api/constants/UpgradeProductToApiNameMap';
import { validateApiName } from '../_core/common/data/upgradeData/validators/validateApiName';
import { getHasAcceptanceTestOverride, HAS_PAID_OVERRIDE } from '../_core/utils/commMethodOverrides';
import sortByTier, { sortByTierFromApiName } from './sortByTier';
import { getHubAndTier, getHubAndTierFromApiName } from './utils'; // getCorrectedUpgradeData isn't called downstream, so we need to account
// for the MARKETING_STARTER product here

export const correctProduct = upgradeProduct => {
  if (upgradeProduct === MARKETING_STARTER) {
    return MARKETING_STARTER_EMAIL;
  }

  return upgradeProduct;
};
export const getIsSuiteStarterEligible = (ownedProducts, isFreePortal = true) => {
  const isPaidOverride = HAS_PAID_OVERRIDE && getHasAcceptanceTestOverride();

  if (isPaidOverride) {
    return false;
  } // free portals are always eligible


  if (isFreePortal || !ownedProducts || ownedProducts.length === 0) {
    return true;
  } // portals that own any number of starter hubs are eligible
  // if any other product is owned the portal is ineligible
  // portals that only own starter hubs should only have seat motions for starter products


  const starterProducts = [MARKETING_HUB_STARTER, SALES_HUB_STARTER, SERVICE_HUB_STARTER, OPERATIONS_HUB_STARTER, CMS_HUB_STARTER];
  return !ownedProducts.some(product => !starterProducts.includes(product.productApiName));
};
export const correctForSuiteStarter = (upgradeProducts, fallbackUpgradeProduct, ownedProducts, isFreePortal = true) => {
  // if the user is not eligible for suite starter
  // and the recommended product is going to be suite starter
  // we need to recommend an individual starter hub instead of the suite
  if (upgradeProducts[0] === SUITE_STARTER && !getIsSuiteStarterEligible(ownedProducts, isFreePortal)) {
    return upgradeProducts.find(upgradeProduct => {
      const {
        hub,
        tier
      } = getHubAndTier(upgradeProduct);

      if (tier === STARTER.toLocaleLowerCase() && hub !== 'suite') {
        return upgradeProduct;
      }

      return undefined;
    }) || fallbackUpgradeProduct;
  }

  return fallbackUpgradeProduct;
};
export const correctForSuiteStarterFromApiName = (apiNames, fallbackApiName, ownedProducts, isFreePortal = true, hasSeatPurchaseMotions = false) => {
  // if the user is not eligible for suite starter
  // and the recommended product is going to be suite starter
  // we need to recommend an individual hub instead of the suite
  if (apiNames[0] === CRM_SUITE_STARTER_BUNDLE && !getIsSuiteStarterEligible(ownedProducts, isFreePortal)) {
    // skip finding a starter product if we need to account for seats
    if (!isFreePortal && hasSeatPurchaseMotions && fallbackApiName !== CRM_SUITE_STARTER_BUNDLE) {
      return fallbackApiName;
    }

    return apiNames.find(apiName => {
      const {
        hub,
        tier
      } = getHubAndTierFromApiName(apiName);

      if (tier === STARTER.toLocaleLowerCase() && hub !== 'suite') {
        return apiName;
      }

      return undefined;
    }) || fallbackApiName;
  }

  return fallbackApiName;
};
export const getProductsInAboveTiers = upgradeProduct => {
  const correctedProduct = correctProduct(upgradeProduct);
  const {
    tier
  } = getHubAndTier(correctedProduct);

  if (tier === ENTERPRISE) {
    return [];
  }

  const productApiName = UpgradeProductToApiNameMap[correctedProduct];

  if (!productApiName) {
    return [];
  }

  const tieredApiNameGroup = ProductTierOrders.filter(productTierOrder => productTierOrder.indexOf(productApiName) !== -1)[0];

  if (!tieredApiNameGroup) {
    return [];
  }

  const apiNameIndex = tieredApiNameGroup.indexOf(productApiName);
  const currentAndHigherTieredApiNames = tieredApiNameGroup.slice(apiNameIndex, tieredApiNameGroup.length);
  const currentAndHigherTieredUpgradeProducts = [];
  currentAndHigherTieredApiNames.forEach(apiName => {
    const product = ApiNameToUpgradeProductMap[apiName];

    if (product) {
      currentAndHigherTieredUpgradeProducts.push(product);
    }
  });
  return sortByTier(currentAndHigherTieredUpgradeProducts);
};
export const getSeatedPurchaseMotionProducts = purchaseMotions => {
  const seatedPurchaseMotionProducts = [];
  Object.keys(purchaseMotions).forEach(apiName => {
    const purchaseMotion = purchaseMotions[apiName];
    if (!purchaseMotion) return;
    purchaseMotion.forEach(subkey => {
      if (subkey.key === ASSIGN_SEAT || subkey.key === REQUEST_SEAT) {
        const upgradeProductFromApiName = ApiNameToUpgradeProductMap[apiName];

        if (upgradeProductFromApiName) {
          seatedPurchaseMotionProducts.push(upgradeProductFromApiName);
        }
      }
    });
  });
  return seatedPurchaseMotionProducts;
};
export const getPrioritizedUpgradeProduct = (upgradeProducts, purchaseMotions, ownedProducts, isFreePortal) => {
  const seatedPurchaseMotionProducts = getSeatedPurchaseMotionProducts(purchaseMotions); // no seat purchase motions

  if (!(seatedPurchaseMotionProducts && seatedPurchaseMotionProducts.length > 0)) {
    const correctedProduct = correctForSuiteStarter(upgradeProducts, correctProduct(upgradeProducts[0]), ownedProducts, isFreePortal);
    return correctedProduct;
  } // there are seat purchase motions


  const upgradeProductAndHigherTiers = upgradeProducts.map(product => getProductsInAboveTiers(product)).flat(); // first seated purchase motion for product and higher tiers in upgradeProducts

  const prioritizedProduct = upgradeProductAndHigherTiers.find(product => seatedPurchaseMotionProducts.includes(product));

  if (prioritizedProduct) {
    const correctedProduct = correctForSuiteStarter(upgradeProducts, prioritizedProduct, ownedProducts, isFreePortal);
    return correctedProduct;
  }

  const correctedProduct = correctForSuiteStarter(upgradeProducts, correctProduct(upgradeProducts[0]), ownedProducts, isFreePortal);
  return correctedProduct;
};
export const getProductsInAboveTiersFromApiName = apiName => {
  const {
    tier
  } = getHubAndTierFromApiName(apiName);

  if (tier === ENTERPRISE) {
    return [];
  }

  const tieredApiNameGroup = ProductTierOrders.filter(productTierOrder => productTierOrder.indexOf(apiName) !== -1)[0];

  if (!tieredApiNameGroup) {
    return [];
  }

  const apiNameIndex = tieredApiNameGroup.indexOf(apiName);
  const currentAndHigherTieredApiNames = tieredApiNameGroup.slice(apiNameIndex, tieredApiNameGroup.length);
  const sortedApiNames = sortByTierFromApiName(currentAndHigherTieredApiNames);
  return sortedApiNames.slice(0, sortedApiNames.indexOf(apiName) + 1);
};
export const getSeatedPurchaseMotionApiNames = rawPurchaseMotions => {
  const seatedPurchaseMotionApiNames = [];
  Object.keys(rawPurchaseMotions).forEach(apiName => {
    const purchaseMotion = rawPurchaseMotions[apiName];
    if (!purchaseMotion) return;
    purchaseMotion.forEach(subkey => {
      if (subkey.key === ASSIGN_SEAT || subkey.key === REQUEST_SEAT || // Personalized Pricing Page uses MERCH_CARD location, which returns the following for seat scenarios
      subkey.key === VIEW_ACCOUNT || subkey.key === BUY_ADDITIONAL_SEATS) {
        seatedPurchaseMotionApiNames.push(apiName);
      }
    });
  });
  return seatedPurchaseMotionApiNames;
};
export const buildApiNameFromRecommendedHub = (recommendedHub, apiName) => {
  const splitApiName = apiName.split('-');
  splitApiName[0] = recommendedHub.toLocaleLowerCase();
  const recommendedApiName = splitApiName.join('-');

  if (validateApiName(recommendedApiName)) {
    return recommendedApiName;
  }

  return apiName;
}; // Validate recommended hub is one of the possible apiName hubs

const isValidRecommendation = (apiNames, recommendedHub) => {
  if (!recommendedHub || typeof recommendedHub !== 'string' || !Array.isArray(apiNames)) {
    return false;
  } // Recommendation should be of the same product line as one of the apiNames


  return apiNames.filter(apiName => validateApiName(apiName)).some(apiName => {
    const {
      hub
    } = getHubAndTierFromApiName(apiName);
    return hub.toUpperCase() === recommendedHub.toUpperCase();
  });
};

export const shouldUseRecommendedHub = (seatedPurchaseMotionProducts, apiNames, recommendedHub) => {
  return !!(isValidRecommendation(apiNames, recommendedHub) && !(seatedPurchaseMotionProducts && seatedPurchaseMotionProducts.length > 0));
};
export const getPrioritizedUpgradeProductFromApiName = (apiNames, purchaseMotions, ownedProducts, isFreePortal, recommendedHub) => {
  const seatedPurchaseMotionProducts = getSeatedPurchaseMotionApiNames(purchaseMotions);
  const hasSeatPurchaseMotions = seatedPurchaseMotionProducts && seatedPurchaseMotionProducts.length > 0; // no seat purchase motions

  if (!hasSeatPurchaseMotions) {
    if (recommendedHub && shouldUseRecommendedHub(seatedPurchaseMotionProducts, apiNames, recommendedHub)) {
      return buildApiNameFromRecommendedHub(recommendedHub, apiNames[0]);
    }

    const correctedApiName = correctForSuiteStarterFromApiName(apiNames, apiNames[0], ownedProducts, isFreePortal, hasSeatPurchaseMotions);
    return correctedApiName;
  } // there are seat purchase motions


  const upgradeProductAndHigherTiers = apiNames.map(product => getProductsInAboveTiersFromApiName(product)).flat(); // first seated purchase motion for product and higher tiers in apiNames

  const prioritizedProduct = upgradeProductAndHigherTiers.find(product => seatedPurchaseMotionProducts.includes(product));

  if (prioritizedProduct) {
    const correctedApiName = correctForSuiteStarterFromApiName(apiNames, prioritizedProduct, ownedProducts, isFreePortal, hasSeatPurchaseMotions);
    return correctedApiName;
  }

  const correctedApiName = correctForSuiteStarterFromApiName(apiNames, apiNames[0], ownedProducts, isFreePortal, hasSeatPurchaseMotions);
  return correctedApiName;
};
export default getPrioritizedUpgradeProduct;