'use es6';

import http from 'hub-http/clients/apiClient';

function createAbTest(baseContentUrl, data) {
  return http.post(`${baseContentUrl}/create-variation`, {
    timeout: 30000,
    data,
    query: {
      _buffer: true
    }
  });
}

function cancelAbTest(baseContentUrl, testId) {
  return http.post(`${baseContentUrl}/cancel-ab-test/${testId}`, {
    timeout: 30000,
    data: {}
  });
}

function endAbTest(baseContentUrl, testId, winnerId) {
  return http.post(`${baseContentUrl}/end-ab-test/${testId}`, {
    timeout: 30000,
    data: winnerId
  });
}

function renameMasterVariation(baseContentUrl, name) {
  return http.put(baseContentUrl, {
    data: {
      name
    }
  });
}

function renameTestVariation(baseContentUrl, name) {
  return http.put(baseContentUrl, {
    data: {
      name
    }
  });
}

export default {
  createAbTest,
  cancelAbTest,
  endAbTest,
  renameMasterVariation,
  renameTestVariation
};