import I18n from 'I18n';
import Raven from 'raven-js';
import { useState } from 'react';
import { showErrorNotification } from '../utils/notifications';
const FAILED_TO_CALL_ONREADY_KEY = 'Failed to call the onReady on the collaboration sidebar embedded app';
export default function useHandleIFrameError(props) {
  const onContentErrorRef = props && props.onContentErrorRef;
  const [IFrameError, setIFrameError] = useState(null);

  const handleSidebarInitError = error => {
    setIFrameError(error);
    showErrorNotification({
      message: I18n.text('collaborationSidebar.notifications.failure.collaborationSidebar'),
      sticky: true
    });

    if (onContentErrorRef && onContentErrorRef.current) {
      onContentErrorRef.current(error);
    }
  };

  const handleSidebarReadyError = error => {
    setIFrameError(error);
    showErrorNotification({
      message: I18n.text('collaborationSidebar.notifications.failure.collaborationSidebar'),
      sticky: true
    });
    Raven.captureException(new Error(FAILED_TO_CALL_ONREADY_KEY), {
      fingerprint: [FAILED_TO_CALL_ONREADY_KEY]
    });

    if (onContentErrorRef && onContentErrorRef.current) {
      onContentErrorRef.current(error);
    }
  };

  return {
    handleSidebarInitError,
    handleSidebarReadyError,
    IFrameError
  };
}