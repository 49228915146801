import I18n from 'I18n';
const VALID_SEATS = ['core', 'sales', 'service'];

const createOwnedProductString = parsedProducts => {
  const init = I18n.text(`upgrades.products.${parsedProducts[0]}`);
  parsedProducts.shift();
  return parsedProducts.reduce((accumulator, currentValue) => {
    return accumulator.concat(', '.concat(I18n.text(`upgrades.products.${currentValue}`)));
  }, init);
};

export const getSeatSelectOptions = (response, parsedProducts) => {
  const init = [];
  const seatSelectOptions = response.reduce((accumulator, currentValue) => {
    const {
      maxAssignableSeats,
      seatName
    } = currentValue;
    const seat = seatName.split('-');

    if (maxAssignableSeats > 0 && VALID_SEATS.includes(seat[0])) {
      return [...accumulator, {
        text: I18n.text(`upgrades.requestSeatModal.seatInputSelect.${seat[0]}.title`),
        value: seatName,
        help: I18n.text(`upgrades.requestSeatModal.seatInputSelect.${seat[0]}.subtitle`, {
          seatLevel: I18n.text(`upgrades.productCatalog.tableHeader.${seat[1] === 'pro' ? 'professional' : seat[1]}`),
          ownedHubs: createOwnedProductString(parsedProducts)
        })
      }];
    }

    return accumulator;
  }, init);
  return seatSelectOptions;
};