'use es6';

import UsageTracker from 'ContentEditorUI/utils/UsageTracker';
import { getSchemaForId, getIsDefaultFormModule } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors';
import UrlTypes from 'ContentUtils/enums/UrlTypes';
export const trackFormInsert = (state, moduleSchemaId) => {
  const moduleSchema = getSchemaForId(state, moduleSchemaId);

  if (getIsDefaultFormModule(state, moduleSchema)) {
    const formsTracker = UsageTracker.getCachedClonedTracker('forms', {
      properties: {
        namespace: 'forms'
      }
    });

    if (formsTracker) {
      formsTracker.track('cosModuleInteraction', {
        action: 'insert-form'
      });
      formsTracker.track('cosModuleActivation', {
        action: 'insert-form'
      });
      formsTracker.track('cosModuleUsage', {
        action: 'insert-form'
      });
    }
  }
};
export const trackFieldAction = (event, properties) => {
  UsageTracker.trackEditorInteraction(Object.assign({
    action: 'Module Form Action',
    event
  }, properties));
};
export const trackCustomEvent = (event, properties) => {
  UsageTracker.track(event, Object.assign({}, properties));
};
export const trackModuleEdit = (defaultType, key, value, trackRaw = false) => {
  const {
    color,
    src
  } = value || {};

  if (defaultType === 'video' || defaultType === 'video_email') {
    if ((key.includes('oembed_thumbnail_play_button_color') || key.includes('play_button_color')) && color && color.length % 3 === 1 // 3 or 6 hex value plus # symbol (4 or 7) is valid, 1 is impossible (color value is null)
    ) {
        trackFieldAction('video-change-player-color');
      }

    if (key.includes('oembed_thumbnail') && src !== '') {
      trackFieldAction('embed-add-custom-thumbnail');
    }
  }

  if (defaultType === 'button') {
    const urlType = value && value.url && value.url.type;

    if (key.includes('link') && urlType === UrlTypes.PAYMENT) {
      trackFieldAction('button-payment-url-type-selected');
    }
  }

  if (trackRaw) {
    const flattenedKey = key instanceof Array ? key.join('-') : key;
    trackFieldAction(flattenedKey, {
      module: defaultType,
      value
    });
  }
};
export const trackStyleFieldEdit = styleFieldType => {
  trackFieldAction('Edited style field', {
    fieldType: styleFieldType
  });
};