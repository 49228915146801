'use es6';

import { createSelector } from 'reselect';
import { basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';
import { getModuleById, getModuleOrUneditableModuleById, getGlobalGroupOrPartialByPath } from './moduleSelectors';
import { getSchemaForModule } from './moduleSchemaSelectors';
import { isV1GlobalModule } from 'ContentEditorUI/data/moduleUtils';
import { getOpenModal, getOpenModalMetadata } from 'ContentEditorUI/redux/selectors/modalSelectors';
import { MODAL_TYPES } from 'ContentEditorUI/lib/modalConstants';
const getIdForOpenConfirmEditGlobalModal = createSelector(getOpenModal, getOpenModalMetadata, (openModal, modalMetadata) => {
  if (openModal === MODAL_TYPES.CONFIRM_EDIT_GLOBAL) {
    return modalMetadata.id;
  }

  return null;
});
export const getSelectedGlobalContentPath = basicSelectorWithStats(state => {
  const selectedGlobalContentId = getIdForOpenConfirmEditGlobalModal(state);

  if (selectedGlobalContentId) {
    const module = getModuleById(state, selectedGlobalContentId);

    if (module) {
      const moduleSpec = getSchemaForModule(state, module);

      if (moduleSpec) {
        return moduleSpec.path;
      }
    } else {
      const globalGroupOrPartial = getGlobalGroupOrPartialByPath(state, selectedGlobalContentId);

      if (globalGroupOrPartial) {
        return globalGroupOrPartial.path;
      }
    }
  }

  return null;
});
export const getSchemaIdForSelectedGlobalModule = basicSelectorWithStats(state => {
  const selectedGlobalContentId = getIdForOpenConfirmEditGlobalModal(state);

  if (selectedGlobalContentId) {
    const module = getModuleOrUneditableModuleById(state, selectedGlobalContentId);

    if (module) {
      const moduleSpec = getSchemaForModule(state, module);

      if (moduleSpec) {
        return moduleSpec.id;
      }
    }
  }

  return null;
});
export const getIsSelectedGlobalContentV1 = basicSelectorWithStats(state => {
  const selectedGlobalContentId = getIdForOpenConfirmEditGlobalModal(state);

  if (selectedGlobalContentId) {
    const module = getModuleOrUneditableModuleById(state, selectedGlobalContentId);

    if (module) {
      return isV1GlobalModule(module);
    }
  }

  return false;
});