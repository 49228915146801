import { IS_OPEN, SELECTED_ASSET, COMMENT_THREAD, TAB, HIDE_EMBEDDED_COMMENTS, APP_NAME, OBJECT_ID, OBJECT_TYPE, CTA_URL, MODULES_WITH_REPLIES, IS_EMBEDDED_COMMENTS_ENABLED, IS_COMMENT_MODE_ACTIVE, BROADCAST_COMMENT_MESSAGE, EMBEDDED_COMMENT_SIDEBAR_INTERACTIONS, HIGHLIGHT_COMMENTABLE_AREA, SCROLL_TO_COMMENTABLE_AREA, COMMENTS_FILTER, CTA_COMMENT_ID, ASSETS_LAST_UPDATED, NOTIFICATION_SETTINGS_OPEN } from '../constants/CollaborationSidebarStateProperties';
import { COMMENT_FILTER_VALUES } from '../constants/commentFilterConstants';
import { TABS } from '../constants/tabs';
import createCommentInteractionsState from './CommentInteractions';
import { getCommentIdParam } from '../utils/urls';
/**
 * This record tracks all the sidebar state
 * that's of concern to the host app.
 */

/**
 * If adding a new piece of state:
 * make sure it's added and deployed in collaboration-sidebar-embeddable
 * before it's used by any app through the collaboration-sidebar lib.
 * Trying to set a non-existant property into an immutable record will cause a runtime error!!
 */

/**
 * If removing a piece of state:
 * First deprecate it's usage (without removing it from the state) and ensure that's deployed
 * across all apps. After it's not being used in any app or the embeddable, you can remove it from here.
 */

/**
 * Do not refactor the types of these state values as you
 * cannot keep them in sync between the library and embeddable.
 * Always migrate to a new state while maintaining
 * the existing state values before being able to deprecate them.
 */

function createCollaborationSidebarState(overrides) {
  return Object.assign({
    [IS_OPEN]: false,
    [TAB]: TABS.COMMENTS,
    [SELECTED_ASSET]: null,
    [COMMENT_THREAD]: null,
    [HIDE_EMBEDDED_COMMENTS]: false,
    [APP_NAME]: null,
    [OBJECT_ID]: null,
    [OBJECT_TYPE]: null,
    [CTA_URL]: null,
    [MODULES_WITH_REPLIES]: {},
    [IS_EMBEDDED_COMMENTS_ENABLED]: false,
    [IS_COMMENT_MODE_ACTIVE]: false,
    [BROADCAST_COMMENT_MESSAGE]: null,
    [EMBEDDED_COMMENT_SIDEBAR_INTERACTIONS]: createCommentInteractionsState(),
    [COMMENTS_FILTER]: COMMENT_FILTER_VALUES.open,
    [CTA_COMMENT_ID]: getCommentIdParam(),
    [ASSETS_LAST_UPDATED]: {},
    [NOTIFICATION_SETTINGS_OPEN]: false,
    // Deprecated
    [SCROLL_TO_COMMENTABLE_AREA]: null,
    [HIGHLIGHT_COMMENTABLE_AREA]: null
  }, overrides || {});
}

export default createCollaborationSidebarState;