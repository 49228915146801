import PropTypes from 'prop-types';
export const AddAdditionalSeatsPropsInterface = {
  className: PropTypes.string,
  use: PropTypes.string,
  trackingSource: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  offerProductConfigurations: PropTypes.arrayOf(PropTypes.shape({
    offerId: PropTypes.number.isRequired,
    productConfigurations: PropTypes.arrayOf(PropTypes.shape({
      skuId: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired
    }).isRequired).isRequired
  }).isRequired),
  children: PropTypes.node
};