import { createSelector } from 'reselect';
import { createGenericOptionsSelector, tryParseNumber } from 'ContentData/helpers/reduxHelpers';
export const getEmails = state => state.resources.emails.emails;
export const getEmail = (state, props) => state.resources.emails.emails[props.id];
export const getIsEmailUnpublished = (state, props) => {
  const email = state.resources.emails.emails[props.id];
  return email && !email.isPublished;
};
export const getEmailBuffers = state => state.resources.emails.emailBuffers;
export const getEmailCache = (state, props) => state.resources.emails.emailsCache[props.id];
export const getEmailsCache = state => state.resources.emails.emailsCache;
export const getEmailsCacheRequestStatus = state => state.resources.emails.emailsCacheRequestStatus;
export const getEmailFetchStatus = (state, props) => state.resources.emails.requestStatus[props.id];
export const getEmailOptions = createGenericOptionsSelector(getEmailsCache);
export const getDefaultEmailOptions = createGenericOptionsSelector(getEmails);

function _getAutomatedEmailOptions(emailsMap, showLegacyEmails = false) {
  return Object.keys(emailsMap).reduce((emailOptions, emailId) => {
    const email = emailsMap[emailId];
    const {
      emailType,
      name = ''
    } = email || {};

    if (showLegacyEmails || emailType === 'AUTOMATED_EMAIL' || emailType === 'AUTOMATED_AB_EMAIL') {
      emailOptions.push({
        value: tryParseNumber(emailId),
        text: name
      });
    }

    return emailOptions;
  }, []);
}

export const getAutomatedEmailOptions = createSelector([getEmailsCache], emailsCache => _getAutomatedEmailOptions(emailsCache));

const getShowLegacyEmails = (state, props) => props.showLegacyEmails;

export const getDefaultAutomatedEmailOptions = createSelector([getEmails, getShowLegacyEmails], (emails, showLegacyEmails) => _getAutomatedEmailOptions(emails, showLegacyEmails));
export const makeGetIsEmailValid = () => {
  return createSelector([getEmail], email => email && email.isPublished);
};
export const makeGetIsEmailClassic = () => {
  return createSelector([getEmail], email => email && email.emailTemplateMode === 'DESIGN_MANAGER');
};
export const getHasFetchedIsLegacyEmailConvertible = (state, props) => {
  const {
    id
  } = props;
  const email = getEmail(state, {
    id
  }); // If email is draft or template mode is DND, we know the
  // is-convertible-to-drag-and-drop endpoint will fail so don't
  // bother fetching that info

  if (email && (!email.isPublished || email.emailTemplateMode === 'DRAG_AND_DROP')) return true;
  return state.resources.emails.isLegacyEmailConvertible[props.id] !== undefined;
};
export const getIsLegacyEmailConvertible = (state, props) => {
  const {
    id
  } = props;
  const {
    emailTemplateMode,
    isPublished
  } = getEmail(state, {
    id
  }) || {};
  if (!isPublished) return false;
  if (emailTemplateMode === 'DRAG_AND_DROP') return true;
  return state.resources.emails.isLegacyEmailConvertible[id] || false;
};