import { PreferencesClient } from './api';
export class HubUserAttributesClient extends PreferencesClient {
  constructor(caller) {
    super(caller, '/user-attributes/v1/app-preferences/fetch', '/user-attributes/v1/app-preferences/write', '/user-attributes/v1/app-preferences/delete');
  }

  static forCaller(caller) {
    return new HubUserAttributesClient(caller);
  }

}
export class UserAttributesClient extends PreferencesClient {
  constructor(caller) {
    super(caller, '/user-attributes/v1/app-preferences/user-attributes/fetch', '/user-attributes/v1/app-preferences/user-attributes/write', '/user-attributes/v1/app-preferences/user-attributes/delete');
  }

  static forCaller(caller) {
    return new UserAttributesClient(caller);
  }

}
export class HubsSettingClient extends PreferencesClient {
  constructor(caller) {
    super(caller, '/hubs-settings/v2/hubs-setting/fetch', '/hubs-settings/v2/hubs-setting/write', '/hubs-settings/v2/hubs-setting/delete');
  }

  static forCaller(caller) {
    return new HubsSettingClient(caller);
  }

}