import { MARKETING_STARTER, MARKETING_STARTER_EMAIL } from 'self-service-api/constants/UpgradeProducts';
// Consolidate marketing starter upgrade product under the marketing-starter-email upgrade product
export const getCorrectedUpgradeData = upgradeData => {
  let preferredUpgradeProduct = upgradeData.upgradeProduct;

  if (preferredUpgradeProduct === MARKETING_STARTER) {
    preferredUpgradeProduct = MARKETING_STARTER_EMAIL;
  }

  return Object.assign({}, upgradeData, {
    upgradeProduct: preferredUpgradeProduct
  });
};