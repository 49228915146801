'use es6';

import { IFRAME_CLICKED, SET_VALIDATION_POPOVER_OPEN } from 'ContentEditorUI/redux/actions/actionTypes';
import { Map as ImmutableMap } from 'immutable';

const UserInterface = (state = ImmutableMap({
  validationPopoverOpen: false
}), action) => {
  switch (action.type) {
    case IFRAME_CLICKED:
      return state.set('validationPopoverOpen', false);

    case SET_VALIDATION_POPOVER_OPEN:
      return state.set('validationPopoverOpen', action.isOpen);

    default:
      return state;
  }
};

export default UserInterface;