export function getStateProp(state, prop) {
  if (!state) {
    return null;
  }

  if (state[prop] != null || state[prop] !== undefined) {
    return state[prop];
  }

  if (typeof state.get === 'function') {
    return state.get(prop);
  }

  return null;
}