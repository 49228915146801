'use es6';

export const domainIsLegacy = domain => {
  return !!domain.get('is_legacy_domain') || !!domain.get('isLegacyDomain');
};
export const domainIsStaging = domain => {
  return domain.get('full_category_key') === '1:staged_page' || domain.get('fullCategoryKey') === '1:staged_page';
};
const FREE_DOMAIN_REGEX = /^.+\.hubspotpagebuilder(qa)?(-[A-z0-9]+)?\..+/;
export const domainIsFreePageBuilder = domain => domain && !!domain.get('domain').match(FREE_DOMAIN_REGEX);
export const getDisplayForDomain = domain => {
  const protocol = domain.get('is_ssl_only') || domain.get('isSslOnly') ? 'https://' : 'http://';
  const domainPath = domain.get('domain');
  return `${protocol}${domainPath}/`;
};