import { combineReducers } from 'redux';
import { createGenericRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
import { PENDING, UNINITIALIZED, SUCCEEDED, FAILED } from 'ContentUtils/constants/RequestStatus';
import * as ActionTypes from 'ContentData/actions/ActionTypes';
const {
  PAGES_FETCH_PAGE,
  PAGES_FETCH_PAGES,
  PAGES_SAVE_PAGE_CACHE,
  PAGES_CLEAR_CACHE
} = ActionTypes;

function pages(state = {}, action) {
  switch (action.type) {
    case PAGES_FETCH_PAGE:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [response.id]: response
        });
      }

    case PAGES_SAVE_PAGE_CACHE:
      {
        const {
          page
        } = action;
        if (!page) return state;
        return Object.assign({}, state, {
          [page.id]: page
        });
      }

    default:
      return state;
  }
}

function pagesCache(state = {}, action) {
  switch (action.type) {
    case PAGES_FETCH_PAGES:
      {
        const {
          response
        } = action;
        if (!response) return state;
        const {
          objects
        } = response;
        if (!objects) return state;
        return objects.reduce((hash, page) => {
          hash[page.id] = page;
          return hash;
        }, Object.assign({}, state));
      }

    case PAGES_CLEAR_CACHE:
      {
        if (Object.keys(state).length) return {};
        return state;
      }

    default:
      return state;
  }
}

function pagesCacheOrder(state = [], action) {
  switch (action.type) {
    case PAGES_FETCH_PAGES:
      {
        const {
          response
        } = action;
        if (!(response && response.objects)) return state;
        return response.objects.reduce((hash, page) => {
          hash.push(page.id);
          return hash;
        }, [...state]);
      }

    case PAGES_CLEAR_CACHE:
      {
        if (state.length) return [];
        return state;
      }

    default:
      return state;
  }
}

function pagesRequestStatus(state = UNINITIALIZED, action) {
  switch (action.type) {
    case PAGES_FETCH_PAGES:
      {
        const {
          response,
          error
        } = action;
        let requestStatus = PENDING;
        if (response) requestStatus = SUCCEEDED;
        if (error) requestStatus = FAILED;
        return requestStatus;
      }

    default:
      return state;
  }
}

export default combineReducers({
  pages,
  pagesCache,
  pagesCacheOrder,
  pagesRequestStatus,
  requestStatus: createGenericRequestStatusReducer(PAGES_FETCH_PAGE)
});