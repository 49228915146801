'use es6';

import Immutable from 'immutable';
import { ADD_PAGES, FETCH_PAGE_SUCCESS } from 'ContentEditorUI/redux/actions/actionTypes';

const pagesReducer = (state = Immutable.List(), {
  type,
  payload
}) => {
  switch (type) {
    case ADD_PAGES:
      {
        const newPages = payload.filter(payloadPage => {
          return !state.find(page => page.get('id') === payloadPage.id);
        });
        return state.concat(Immutable.fromJS(newPages));
      }

    case FETCH_PAGE_SUCCESS:
      {
        const alreadyExists = state.find(page => page.get('id') === payload.id);
        return alreadyExists ? state : state.push(Immutable.fromJS(payload));
      }

    default:
      return state;
  }
};

export default pagesReducer;