'use es6';

import { CLEAR_PREVIEW_STATE, SET_DEVICE_TYPE, UPDATE_SMART_CONTENT_SELECTOR_VALUE, UPDATE_PREVIEW_DRILLDOWN, UPDATE_CONTACT, UPDATE_AMP_AND_LISTING } from 'ContentEditorUI/redux/actions/actionTypes';
import { Map as ImmutableMap } from 'immutable';
const initialState = new ImmutableMap({
  selectedDevice: '',
  ampPreviewSelected: false,
  selectedTabIndex: 0,
  previewSource: '',
  country: '',
  language: '',
  drillDownRule: '',
  contact: ''
});

const previewInfoReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case CLEAR_PREVIEW_STATE:
      return state.merge({
        selectedDevice: '',
        ampPreviewSelected: false,
        selectedTabIndex: 0,
        previewSource: '',
        country: '',
        language: '',
        drillDownRule: '',
        contact: ''
      });

    case SET_DEVICE_TYPE:
      return state.merge({
        selectedDevice: payload.device,
        ampPreviewSelected: payload.ampEnabled
      });

    case UPDATE_SMART_CONTENT_SELECTOR_VALUE:
      return state.merge({
        [payload.key]: payload.value
      });

    case UPDATE_PREVIEW_DRILLDOWN:
      return state.merge({
        previewSource: payload.source,
        drillDownRule: payload.drillDownRule
      });

    case UPDATE_CONTACT:
      return state.merge({
        contact: payload.contact
      });

    case UPDATE_AMP_AND_LISTING:
      return state.merge({
        ampPreviewSelected: payload.amp,
        selectedTabIndex: payload.listing
      });

    default:
      return state;
  }
};

export default previewInfoReducer;