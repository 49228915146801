'use es6';

import { getCountryOptions } from './getCountryOptions';
let phoneOptions = [];
export default (phoneData => {
  if (phoneOptions.length) {
    return phoneOptions;
  }

  const countryOptions = getCountryOptions();
  phoneOptions = countryOptions.filter(option => {
    return phoneData.find(c => c.iso2 === option.value);
  }).map(option => {
    const dialCode = phoneData.find(c => c.iso2 === option.value).dialCode;
    return Object.assign({}, option, {
      text: `${option.text} +${dialCode}`
    });
  });
  return phoneOptions;
});