export default class IframeStylesManager {
  constructor() {
    this.iframeWrapperElement = null;
  }

  init(wrapperElement) {
    this.iframeWrapperElement = wrapperElement;
  }

  getWrapperElement() {
    return this.iframeWrapperElement;
  }

  requestIframeStyleChangesFromProxy(__styleObjects) {
    throw new Error('requestIframeStyleChangesFromProxy must be implemented by a child class');
  }

  requestImmediateIframeStyleChange(styleObjects) {
    window.requestAnimationFrame(() => {
      styleObjects.forEach(styleObject => {
        if (!this.iframeWrapperElement) {
          return;
        }

        const {
          styleKey,
          styleValue
        } = styleObject;
        this.iframeWrapperElement.style.setProperty(styleKey, styleValue);
      });
    });
  }

}