'use es6';

import { getAudienceAccessType } from 'ContentUtils/helpers/AudienceAccessHelpers';
import { FETCH_AVAILABLE_PAGE_STYLESHEETS_SUCCEEDED, FETCH_PAGE_STYLESHEET_INFO_SUCCEEDED, FETCH_HUBDB_TABLES_SUCCEEDED, FETCH_DATA_QUERIES_SUCCEEDED, FETCH_CONTACT_LISTS_FOR_CONTENT_SUCCEEDED, FETCH_CONTACT_LISTS_SUCCEEDED, UPDATE_PAGE_SETTINGS_AUDIENCE_ACCESS, UPDATE_PAGE_CONTENT_MODEL_AUDIENCE_ACCESS_LISTS, FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_SUCCEEDED, FETCH_CONTACT_LIST_REGISTRATIONS_SUCCEEDED, FETCH_CRM_OBJECT_TYPES_SUCCEEDED, FETCH_CRM_OBJECT_SCHEMA_SUCCEEDED } from 'page-editor-ui/redux/actions/actionTypes';
import { FETCH_CONTENT_SCHEMA_SUCCEEDED, UPDATE_CONTENT_MODEL_DOMAIN } from 'ContentEditorUI/redux/actions/actionTypes';
import { fromJS, List, Map as ImmutableMap } from 'immutable';
import withBaseSettings from 'ContentEditorUI/redux/reducers/settings/withBaseSettings';
const initialState = ImmutableMap({
  featuredImageThumbnailUrls: ImmutableMap(),
  registeredContacts: 0,
  unregisteredContacts: 0
});

function mergeNeededListData(updatedSelectedLists, oldNeededListData = new List(), allLists = new List()) {
  return updatedSelectedLists.reduce((acc, listId) => {
    if (!acc.find(list => list.get('listId') === listId)) {
      const listData = allLists.find(list => list.get('listId') === listId);

      if (listData) {
        return acc.push(listData);
      }
    }

    return acc;
  }, oldNeededListData);
}

const pageSettingsReducer = (state = initialState, {
  type,
  payload,
  response
}) => {
  switch (type) {
    case FETCH_AVAILABLE_PAGE_STYLESHEETS_SUCCEEDED:
      return state.set('availablePageStylesheets', fromJS(payload));

    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          content,
          settings
        } = response;
        return state.merge({
          audienceAccess: getAudienceAccessType(content),
          domain: content.resolvedDomain,
          domainStylesheets: settings.attachedStylesheets,
          masterPagesForMultiLang: List.of(),
          // Save this without any parsing (we use the simple name in the content model until save/fetch)
          originalDynamicPageSourceValue: content.dynamicPageDataSourceId
        });
      }

    case FETCH_PAGE_STYLESHEET_INFO_SUCCEEDED:
      return state.merge({
        templateStylesheets: payload.layout_attached_stylesheets,
        domainStylesheets: payload.domain_attached_stylesheets
      });

    case FETCH_HUBDB_TABLES_SUCCEEDED:
      return state.set('availableHubDbTables', fromJS(payload));

    case FETCH_DATA_QUERIES_SUCCEEDED:
      return state.set('availableDataQueries', fromJS(payload));

    case FETCH_CONTACT_LISTS_FOR_CONTENT_SUCCEEDED:
      return state.set('contactListsForContent', fromJS(payload.lists));

    case FETCH_CONTACT_LISTS_SUCCEEDED:
      return state.set('contactLists', fromJS(payload.lists.map(l => l.list)));

    case UPDATE_PAGE_SETTINGS_AUDIENCE_ACCESS:
      return state.set('audienceAccess', payload);

    case UPDATE_PAGE_CONTENT_MODEL_AUDIENCE_ACCESS_LISTS:
      return state.set('contactListsForContent', mergeNeededListData(payload, state.get('contactListsForContent'), state.get('contactLists')));

    case UPDATE_CONTENT_MODEL_DOMAIN:
      return state.set('domain', payload.domain);

    case FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_SUCCEEDED:
      return state.set('featuredImageThumbnailUrls', ImmutableMap(payload));

    case FETCH_CONTACT_LIST_REGISTRATIONS_SUCCEEDED:
      return state.merge({
        emailsNotSent: payload.emailsNotSent,
        emailsSent: payload.emailsSent,
        registeredContacts: payload.registered,
        unregisteredContacts: payload.unregistered
      });

    case FETCH_CRM_OBJECT_TYPES_SUCCEEDED:
      return state.set('availableCrmObjectTypes', fromJS(payload));

    case FETCH_CRM_OBJECT_SCHEMA_SUCCEEDED:
      return state.set('crmObjectSchema', fromJS(payload));

    default:
      return state;
  }
};

export default withBaseSettings(pageSettingsReducer);