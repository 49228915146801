import { useMemo } from 'react';
import { SUPPORTED_HUBS_REQUIRED_SCOPES } from '../constants';
import { hasScope } from '../utils/auth';
export default function useHasSidebarPermission({
  appHub
}) {
  return useMemo(() => {
    if (!appHub) {
      return false;
    }

    if (Array.isArray(appHub)) {
      return Boolean(appHub.find(hub => hasScope(SUPPORTED_HUBS_REQUIRED_SCOPES[hub])));
    }

    return hasScope(SUPPORTED_HUBS_REQUIRED_SCOPES[appHub]);
  }, [appHub]);
}