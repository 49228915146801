import http from 'hub-http/clients/apiClient';
export const DEFAULT_LIMIT = 500;
export const fetchPage = (url, results, query = {}) => {
  const {
    total,
    offset,
    limit
  } = results;
  let {
    objects
  } = results;
  const nextOffset = offset + limit;

  if (!objects) {
    objects = [];
  }

  if (total > nextOffset) {
    return http.get(url, {
      query: Object.assign({
        offset: nextOffset,
        limit
      }, query)
    }).then(next => {
      return fetchPage(url, next, query).then(end => [...results.objects, ...end]);
    });
  }

  return Promise.resolve(objects);
};
export const fetchFirstPage = (url, limit = DEFAULT_LIMIT, query = {}) => http.get(url, {
  query: Object.assign({
    limit
  }, query)
});
export const fetchPaginated = (url, limit = DEFAULT_LIMIT, query = {}) => {
  return fetchFirstPage(url, limit, query).then(results => {
    return fetchPage(url, results, query);
  });
};