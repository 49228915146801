'use es6';

import { OPENED_PANEL, CLOSED_PANEL, OPENED_PREVIOUS_PANEL, OPENED_BAYMAX_SIDEBAR, CLOSED_BAYMAX_SIDEBAR, BAYMAX_SIDEBAR_ANIMATION_FINISHED, OPENED_PATAGONIA_SIDEBAR, CLOSED_PATAGONIA_SIDEBAR, PATAGONIA_SIDEBAR_ANIMATION_FINISHED, CLOSE_ADD_SECTION_PANEL, SET_PATAGONIA_ACTIVE_PANEL } from 'ContentEditorUI/redux/actions/actionTypes'; //once we have other panels we want to use we can add them here

export const closePanel = activePanel => ({
  type: CLOSED_PANEL,
  name: activePanel
});

const closeExistingPanelIfNeeded = (dispatch, getState) => {
  const currentPanel = getState().activePanel;

  if (currentPanel) {
    dispatch(closePanel(currentPanel));
  }
};

export const openPanel = (panelName, options = {
  scrollToModuleInList: null
}) => {
  return (dispatch, getState) => {
    closeExistingPanelIfNeeded(dispatch, getState);
    dispatch({
      type: OPENED_PANEL,
      name: panelName,
      payload: {
        scrollToModuleInList: options.scrollToModuleInList
      }
    });
  };
};
export const openPreviousPanel = () => {
  return (dispatch, getState) => {
    closeExistingPanelIfNeeded(dispatch, getState);
    dispatch({
      type: OPENED_PREVIOUS_PANEL
    });
  };
};
export const openBaymaxSidebar = () => ({
  type: OPENED_BAYMAX_SIDEBAR
});
export const closeBaymaxSidebar = () => ({
  type: CLOSED_BAYMAX_SIDEBAR
});
export const baymaxSidebarAnimationFinished = () => ({
  type: BAYMAX_SIDEBAR_ANIMATION_FINISHED
});
export const openPatagoniaSidebar = () => ({
  type: OPENED_PATAGONIA_SIDEBAR
});
export const closePatagoniaSidebar = () => ({
  type: CLOSED_PATAGONIA_SIDEBAR
});
export const patagoniaSidebarAnimationFinished = () => ({
  type: PATAGONIA_SIDEBAR_ANIMATION_FINISHED
});
export const closeAddSectionPanel = () => ({
  type: CLOSE_ADD_SECTION_PANEL
});
export const setActivePatagoniaPanel = panelType => ({
  type: SET_PATAGONIA_ACTIVE_PANEL,
  payload: panelType
});