'use es6';

import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TourContext from 'ui-shepherd-react/contexts/TourContext';
import { getIsInDefaultBreakpointMode } from 'ContentEditorUI/redux/selectors/styleBreakpointsSelectors';
import { getProgressionToTrack } from 'ContentEditorUI/redux/selectors/onboardingTourStateSelectors';
import { clearTourProgressionToTrack as clearTourProgressionToTrackAction } from 'ContentEditorUI/redux/actions/onboardingTourStateActions';
import UsageTracker from 'ContentEditorUI/utils/UsageTracker';

const mapStateToProps = state => {
  return {
    isInDefaultBreakpointMode: getIsInDefaultBreakpointMode(state),
    progressionToTrack: getProgressionToTrack(state)
  };
};

const mapDispatchToProps = {
  clearTourProgressionToTrack: clearTourProgressionToTrackAction
};

const OnboardingTourController = ({
  isInDefaultBreakpointMode,
  progressionToTrack,
  clearTourProgressionToTrack
}) => {
  const {
    tour
  } = useContext(TourContext);
  useEffect(() => {
    const currentStep = tour.getStep();

    switch (currentStep) {
      case 'breakpointMode':
        {
          if (!isInDefaultBreakpointMode) {
            tour.next();
          }

          break;
        }

      case 'defaultMode':
        {
          if (isInDefaultBreakpointMode) {
            tour.next();
          }

          break;
        }

      default:
    }
  });
  useEffect(() => {
    if (progressionToTrack.size !== 0) {
      UsageTracker.trackEditorInteraction(progressionToTrack.toJS());
      clearTourProgressionToTrack();
    }
  });
  return null;
};

OnboardingTourController.propTypes = {
  isInDefaultBreakpointMode: PropTypes.bool.isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(OnboardingTourController);