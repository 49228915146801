export const FETCH_HUBDB_TABLE = 'SCLIB/FETCH_HUBDB_TABLE';
export const FETCH_ALL_ROWS = 'SCLIB/FETCH_ALL_ROWS';
export const FETCH_ROW = 'SCLIB/FETCH_ROW';
export const FETCH_MODULE_SCHEMA = 'SCLIB/FETCH_MODULE_SCHEMA';
export const FETCH_ALL_TABLES = 'SCLIB/FETCH_ALL_TABLES';
export const UPDATE_INSTANCE_VALUES = 'SCLIB/UPDATE_INSTANCE_VALUES';
export const SET_ACTIVE_HUBDB_ROW = 'SCLIB/SET_ACTIVE_HUBDB_ROW';
export const CLEAR_ACTIVE_HUBDB_ROW = 'SCLIB/CLEAR_ACTIVE_HUBDB_ROW';
export const SET_HUBDB_ROWS_AND_SCHEMA = 'SCLIB/SET_HUBDB_ROWS_AND_SCHEMA';
export const UPDATE_TABLE_ROWS = 'SCLIB/UPDATE_TABLE_ROWS';
export const UPDATE_CELL_ATTEMPTED = 'SCLIB/UPDATE_CELL_ATTEMPTED';
export const UPDATE_CELL_SUCCEEDED = 'SCLIB/UPDATE_CELL_SUCCEEDED';
export const UPDATE_ROW_ATTEMPTED = 'SCLIB/UPDATE_ROW_ATTEMPTED';
export const UPDATE_ROW_SUCCEEDED = 'SCLIB/UPDATE_ROW_SUCCEEDED';
export const UNDO_EDIT = 'SCLIB/UNDO_EDIT';
export const REDO_EDIT = 'SCLIB/REDO_EDIT';
export const UPDATE_INSTANCE_PROPERTY_META_DATA = 'SCLIB/UPDATE_INSTANCE_PROPERTY_META_DATA';
export const UPDATE_CURRENT_INSTANCE_VALUE = 'SCLIB/UPDATE_CURRENT_INSTANCE_VALUE';