import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const contentsProperty = Symbol('contents');
const resultProperty = Symbol('result');

function isJsonOk(maybeJsonOk) {
  return typeof maybeJsonOk === 'object' && maybeJsonOk !== null && '@result' in maybeJsonOk && // @ts-expect-error this will work in TS 4.9 https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-9.html#unlisted-property-narrowing-with-the-in-operator
  maybeJsonOk['@result'] === 'OK';
}

function isJsonErr(maybeJsonErr) {
  return typeof maybeJsonErr === 'object' && maybeJsonErr !== null && '@result' in maybeJsonErr && // @ts-expect-error this will work in TS 4.9 https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-9.html#unlisted-property-narrowing-with-the-in-operator
  maybeJsonErr['@result'] === 'ERR';
}

export function isOk(result) {
  return result[resultProperty] === 'OK';
}
export function isErr(result) {
  return result[resultProperty] === 'ERR';
}
export function parseJsonResult(json) {
  if (isJsonOk(json)) {
    const {
      '@result': resultKind
    } = json,
          rest = _objectWithoutPropertiesLoose(json, ["@result"]);

    const contents = '@ok' in rest ? rest['@ok'] : rest;
    return {
      [resultProperty]: resultKind,
      [contentsProperty]: contents
    };
  } else if (isJsonErr(json)) {
    const {
      '@result': resultKind
    } = json,
          rest = _objectWithoutPropertiesLoose(json, ["@result"]);

    const contents = '@err' in rest ? rest['@err'] : rest;
    return {
      [resultProperty]: resultKind,
      [contentsProperty]: contents
    };
  } else {
    throw new Error('Provided value was not a valid Result');
  }
}
export function unwrapOrElseThrow(result) {
  if (isErr(result)) {
    throw new Error('Tried to unwrap an Err Result');
  }

  return result[contentsProperty];
}
export function unwrapErrOrElseThrow(result) {
  if (isOk(result)) {
    throw new Error('Tried to unwrap the error of an Ok Result');
  }

  return result[contentsProperty];
}
export function resultOf(value) {
  return {
    [resultProperty]: 'OK',
    [contentsProperty]: value
  };
}
export function resultOfErr(error) {
  return {
    [resultProperty]: 'ERR',
    [contentsProperty]: error
  };
}