import { Map as ImmutableMap, Set as ImmutableSet, OrderedSet } from 'immutable';
import { UPDATE_EMAIL_TYPE, FETCH_CONTENT_SCHEMA_SUCCEEDED, SELECT_BREAKPOINT, SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP, SELECTED_ITEM_UPDATED, SAVED_MODULE, NAVIGATE_TO_PREVIEW, OPENED_BAYMAX_SIDEBAR, CLOSED_BAYMAX_SIDEBAR, BAYMAX_SIDEBAR_ANIMATION_FINISHED, OPENED_PATAGONIA_SIDEBAR, CLOSED_PATAGONIA_SIDEBAR, PATAGONIA_SIDEBAR_ANIMATION_FINISHED, OPENED_PANEL, OPENED_PREVIOUS_PANEL, SELECTED_ITEM_REVERTED_TO_PREVIOUS, INPAGE_UI_STATE_CLEARED, SELECTED_ITEM_CLEARED, CLOSED_PANEL, SET_SCROLL_TO_IN_MODULE_LIST, FLUSH_SIDEBAR_HIDDEN_STATE, LAYOUT_SECTION_MODULE_DELETED, LAYOUT_SECTION_ROW_DELETED, LAYOUT_SECTION_COLUMN_DELETED, DELETED_MODULE, OPEN_TREE_NODES, CLOSE_TREE_NODES, SELECTED_SUB_CATEGORY_UPDATE, SELECTED_CATEGORY_UPDATE, SET_SIDEBAR_SEARCH_TERM, SET_SIDEBAR_ACTIVE_MATCH, SET_SIDEBAR_MATCH_IDS, OPEN_EXPANDABLE_MENU, CLEAR_OPEN_EXPANDABLE_MENU, SAVE_SIDEBAR_SCROLL_TOP, SET_PATAGONIA_ACTIVE_PANEL, SHOW_ALL_ROWS_FOR_DND_AREA, SHOW_STANDARD_ROWS_FOR_DND_AREA, LOCATION_CHANGE, FETCH_ADDABLE_SECTIONS_SUCCESS, SAVE_SECTION_SUCCESS, UPDATE_SIDEBAR_SEARCH_QUERY } from 'ContentEditorUI/redux/actions/actionTypes';
import { SIDEBAR_PANELS } from 'ContentEditorUI/lib/sidebar/constants'; // @ts-expect-error Untyped module

import { getPaneNameFromPath } from 'ContentEditorUI/utils/helpers';
import { PATAGONIA_DEFAULT_SELECTED_SUB_CATEGORIES, NAV_TABS_SHOW_SIDEBAR, PATAGONIA_ADD_PANEL_CATEGORIES, PATAGONIA_ADD_PANEL_SUB_CATEGORIES } from 'ContentEditorUI/constants/Sidebar'; // @ts-expect-error Untyped module

import SidebarIframeStylesManager from 'ContentEditorUI/utils/SidebarIframeStylesManager';
import Categories from 'ContentUtils/constants/Categories';
import { produce } from 'immer';
const initialSidebarStateAsJsObject = {
  activePanel: '',
  defaultPanel: '',
  previousPanel: '',
  sidebarHidden: false,
  sidebarCollapsed: false,
  selectedItemId: null,
  patagoniaSidebarCollapsed: true,
  scrollToModule: null,
  selectedModuleWasFakeModule: false,
  category: PATAGONIA_ADD_PANEL_CATEGORIES.MODULES,
  subCategory: null,
  defaultSelectedSubCategoryMap: PATAGONIA_DEFAULT_SELECTED_SUB_CATEGORIES,
  // Since the sidebar iframe is visible during the collapse animation, we use this animation end
  // state to wait until the iframe is hidden
  sidebarCollapseAnimationFinished: false,
  patagoniaSidebarCollapseAnimationFinished: true,
  // Store open and closed content sidebar tree nodes
  closedTreeNodes: new Set([]),
  // State for maintining search results between sidebar tabs
  sidebarSearchTerm: '',
  sidebarActiveMatch: '',
  sidebarMatchIds: [],
  // State for managing what dnd areas show all rows
  dndAreasThatShowAllRows: new Set([]),
  // State for managing open dropdown menus
  openExpandableMenuNodeId: '',
  sidebarScrollTop: 0,
  searchQuery: ''
};

const setPanelHelperImmer = (draft, newPanel, options = {}) => {
  const {
    clearOutPreviousPanel = false,
    scrollToModuleInList = null
  } = options;
  const existingPanel = clearOutPreviousPanel === true ? '' : draft.activePanel; // Don't change previous panel if it matches current panel

  const newPreviousPanel = newPanel !== existingPanel ? existingPanel : draft.previousPanel;
  Object.assign(draft, {
    previousPanel: newPreviousPanel,
    activePanel: newPanel,
    scrollToModule: scrollToModuleInList
  });
  return draft;
};

const setPanelToDefaultHelperImmer = (draft, {
  clearOutPreviousPanel = true
} = {}) => {
  return setPanelHelperImmer(draft, draft.defaultPanel, {
    clearOutPreviousPanel
  });
};

let previousRoutePathImmer = null;

const isFakeModuleWithNoSidebarUI = id => ['post_body', 'name'].includes(id);

const getPreviousPanelHelperImmer = draft => draft.previousPanel || draft.defaultPanel;

const setPreviousPanelHelperImmer = (draft, {
  clearOutPreviousPanel = false,
  clearOutActivePanel = false
}) => {
  const previousPanel = clearOutActivePanel ? '' : getPreviousPanelHelperImmer(draft);
  return setPanelHelperImmer(draft, previousPanel, {
    clearOutPreviousPanel
  });
};

const sidebarStateReducerImmer = (state = initialSidebarStateAsJsObject, action) => produce(state, draft => {
  const {
    type,
    name,
    payload,
    response
  } = action;

  switch (type) {
    case UPDATE_EMAIL_TYPE:
      return setPanelToDefaultHelperImmer(draft);

    case LOCATION_CHANGE:
      {
        // TODO, eventually cleanup and unify with other logic that sets
        // `activePanel` at
        // https://git.hubteam.com/HubSpot/ContentEditorUI/blob/52a0c94d5cc1258744c6424b65afb8348096b423/PageEditorUI/static/js/Application.js#L102-L105
        const {
          pathname
        } = payload;
        const paneName = getPaneNameFromPath(pathname);

        if (paneName === 'optimize') {
          draft.activePanel = 'optimizer';
        }

        if (previousRoutePathImmer === 'preview' || previousRoutePathImmer === 'revisions') {
          // When leaving preview or revisions, reset the sidebar panel to it's default.
          draft.activePanel = draft.defaultPanel;
          draft.sidebarHidden = false;
        } else if (!NAV_TABS_SHOW_SIDEBAR[paneName]) {
          draft.sidebarHidden = true;
        } else if (draft.sidebarHidden) {
          draft.sidebarHidden = false;
        } else if (paneName === 'optimize') {
          Object.assign(draft, {
            sidebarCollapsed: false,
            sidebarCollapseAnimationFinished: false,
            patagoniaSidebarCollapsed: true,
            patagoniaSidebarCollapseAnimationFinished: true
          });
        }

        previousRoutePathImmer = paneName;
        return draft;
      }

    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const contentSchema = response;
        const {
          schema
        } = contentSchema;
        const previousDefaultPanel = draft.defaultPanel;
        const hasAnyFlexColumns = schema.widget_containers && Object.keys(schema.widget_containers).length > 0;
        const hasAnyLayoutSections = schema.layout_sections && Object.keys(schema.layout_sections).length > 0;
        const hasRichTextFieldWithModuleInsertion = response.content.categoryId === Categories.blog_post.id;
        const hasAnyDndAreas = hasAnyLayoutSections || hasRichTextFieldWithModuleInsertion;

        if (!hasAnyFlexColumns && !hasAnyDndAreas && previousDefaultPanel && previousDefaultPanel === SIDEBAR_PANELS.addWidget) {
          const activePanelUnchanged = draft.activePanel === previousDefaultPanel;

          if (activePanelUnchanged) {
            draft.activePanel = SIDEBAR_PANELS.widgetList;
          }

          draft.defaultPanel = SIDEBAR_PANELS.widgetList;
          return draft;
        }

        return draft;
      }

    case FETCH_ADDABLE_SECTIONS_SUCCESS:
      {
        const {
          marketerSectionsCount,
          allSections
        } = payload; // Edge case: if there are `Saved sections`, but there are `Theme sections`
        // Show themes as the default (because it has content)

        if (marketerSectionsCount === 0 && allSections.length > 0) {
          draft.defaultSelectedSubCategoryMap.SECTIONS = PATAGONIA_ADD_PANEL_SUB_CATEGORIES.SECTIONS.THEME;
        }

        return draft;
      }
    // Note: NOT covering DELETE_SECTION_SUCCESS does not in itself
    // have enough info and feels like enough of edge case to ignore

    case SAVE_SECTION_SUCCESS:
      {
        // If a section is successfully saved, reset to the saved
        // (the top sub category on top)
        draft.defaultSelectedSubCategoryMap.SECTIONS = PATAGONIA_ADD_PANEL_SUB_CATEGORIES.SECTIONS.SAVED;
        return draft;
      }

    case SELECT_BREAKPOINT:
      {
        const {
          selectedBreakpoint,
          isPatagonia
        } = payload;
        const activeSidebarPanel = draft.activePanel;
        const isSwitchingToNonDefaultBreakpoint = selectedBreakpoint !== 'default';
        const currentPanelIsAddModule = activeSidebarPanel === SIDEBAR_PANELS.addWidget;

        if (isSwitchingToNonDefaultBreakpoint && currentPanelIsAddModule && !isPatagonia) {
          draft.activePanel = SIDEBAR_PANELS.widgetList;
        }

        return draft;
      }

    case SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP:
    case SELECTED_ITEM_UPDATED:
      {
        const {
          id,
          doNotShowSidebarForm,
          isPatagonia
        } = payload; // Handle selection of post body and name "fake" modules differently

        if (isFakeModuleWithNoSidebarUI(id)) {
          draft.selectedModuleWasFakeModule = true;
          return setPanelToDefaultHelperImmer(draft, {
            clearOutPreviousPanel: false
          });
        } else if (draft.selectedModuleWasFakeModule) {
          draft.selectedModuleWasFakeModule = false;
        }

        const isPatagoniaSidebarCollapsed = draft.patagoniaSidebarCollapsed;
        const newPanel = isPatagonia && isPatagoniaSidebarCollapsed && doNotShowSidebarForm ? '' : SIDEBAR_PANELS.edit;
        setPanelHelperImmer(draft, newPanel);

        if (doNotShowSidebarForm && (!isPatagonia || isPatagoniaSidebarCollapsed)) {
          return draft;
        }

        Object.assign(draft, {
          selectedItemId: id,
          sidebarCollapsed: false,
          sidebarCollapseAnimationFinished: false,
          patagoniaSidebarCollapsed: false,
          patagoniaSidebarCollapseAnimationFinished: false
        });
        return draft;
      }
    // Only happens in non-Baymax

    case SAVED_MODULE:
      draft.activePanel = SIDEBAR_PANELS.widgetList;
      return draft;

    case NAVIGATE_TO_PREVIEW:
      return setPanelHelperImmer(draft, SIDEBAR_PANELS.preview);

    case OPENED_BAYMAX_SIDEBAR:
      Object.assign(draft, {
        sidebarCollapsed: false,
        sidebarCollapseAnimationFinished: false
      });
      return draft;

    case CLOSED_BAYMAX_SIDEBAR:
      Object.assign(draft, {
        sidebarCollapsed: true,
        sidebarCollapseAnimationFinished: false
      });
      return draft;

    case BAYMAX_SIDEBAR_ANIMATION_FINISHED:
      draft.sidebarCollapseAnimationFinished = true;
      return draft;

    case OPENED_PATAGONIA_SIDEBAR:
      Object.assign(draft, {
        patagoniaSidebarCollapsed: false,
        patagoniaSidebarCollapseAnimationFinished: !state.patagoniaSidebarCollapsed
      });
      return draft;

    case CLOSED_PATAGONIA_SIDEBAR:
      Object.assign(draft, {
        patagoniaSidebarCollapsed: true,
        patagoniaSidebarCollapseAnimationFinished: false,
        activePanel: ''
      });
      return draft;

    case PATAGONIA_SIDEBAR_ANIMATION_FINISHED:
      draft.patagoniaSidebarCollapseAnimationFinished = true;
      return draft;

    case SET_PATAGONIA_ACTIVE_PANEL:
      if (payload === SIDEBAR_PANELS.addWidget && state.activePanel !== SIDEBAR_PANELS.addWidget) {
        SidebarIframeStylesManager.animateCoveringVeil(true);
      }

      return setPanelHelperImmer(draft, payload);

    case OPENED_PANEL:
      return setPanelHelperImmer(draft, name, payload);

    case OPENED_PREVIOUS_PANEL:
    case SELECTED_ITEM_REVERTED_TO_PREVIOUS:
      return setPreviousPanelHelperImmer(draft, {
        clearOutPreviousPanel: true
      });

    case INPAGE_UI_STATE_CLEARED:
      if (draft.previousPanel === SIDEBAR_PANELS.edit) {
        // Prevent going "back" to the edit panel when no module is selected
        // Note, this _should not_ happen, but does in some edge cases (like blurring in optimize)
        return setPanelToDefaultHelperImmer(draft);
      } else {
        return setPreviousPanelHelperImmer(draft, {
          clearOutPreviousPanel: true
        });
      }

    case SELECTED_ITEM_CLEARED:
      {
        draft.selectedItemId = null;

        if (draft.selectedModuleWasFakeModule) {
          return draft.selectedModuleWasFakeModule = false;
        } else {
          if (payload.closePatagoniaSidebar) {
            Object.assign(draft, {
              patagoniaSidebarCollapsed: true,
              patagoniaSidebarCollapseAnimationFinished: true
            });
          }

          return setPreviousPanelHelperImmer(draft, {
            clearOutPreviousPanel: !!payload.isPatagonia,
            clearOutActivePanel: !!payload.isPatagonia
          });
        }
      }

    case DELETED_MODULE:
    case LAYOUT_SECTION_MODULE_DELETED:
    case LAYOUT_SECTION_COLUMN_DELETED:
    case LAYOUT_SECTION_ROW_DELETED:
      {
        const {
          id,
          moduleId,
          columnId,
          rowId
        } = payload;
        const itemId = id || moduleId || columnId || rowId;

        if (itemId === draft.selectedItemId) {
          draft.selectedItemId = null;
          return setPanelToDefaultHelperImmer(draft);
        }

        return draft;
      }

    case SET_SCROLL_TO_IN_MODULE_LIST:
      {
        const {
          moduleId
        } = payload;
        draft.scrollToModule = moduleId;
        return draft;
      }
    // Only used in non-Baymax (classic) editors

    case CLOSED_PANEL:
      draft.previousPanel = '';
      draft.activePanel = '';
      return draft;

    case FLUSH_SIDEBAR_HIDDEN_STATE:
      {
        const {
          paneName
        } = payload;

        if (previousRoutePathImmer === 'preview' || previousRoutePathImmer === 'revisions') {
          // When leaving preview or revisions, reset the sidebar panel to it's default.
          draft.sidebarHidden = false;
        } else if (!NAV_TABS_SHOW_SIDEBAR[paneName]) {
          draft.sidebarHidden = true;
        } else if (draft.sidebarHidden) {
          draft.sidebarHidden = false;
        } else if (paneName === 'optimize') {
          Object.assign(draft, {
            sidebarCollapsed: false,
            sidebarCollapseAnimationFinished: false,
            patagoniaSidebarCollapsed: false,
            patagoniaSidebarCollapseAnimationFinished: false
          });
        }

        return draft;
      }

    case OPEN_TREE_NODES:
      {
        const {
          nodesToOpen
        } = payload;
        const closedTreeNodes = draft.closedTreeNodes;
        nodesToOpen.forEach(node => {
          closedTreeNodes.delete(node);
        });
        return draft;
      }

    case CLOSE_TREE_NODES:
      {
        const {
          nodesToClose
        } = payload;
        const closedTreeNodes = draft.closedTreeNodes;
        nodesToClose.forEach(node => {
          closedTreeNodes.add(node);
        });
        return draft;
      }

    case SELECTED_CATEGORY_UPDATE:
      if (state.category === payload.category) return draft;
      draft.category = payload.category;
      SidebarIframeStylesManager.animateCoveringVeil(false);

      if (payload.subCategory) {
        draft.subCategory = payload.subCategory;
      }

      return draft;

    case SELECTED_SUB_CATEGORY_UPDATE:
      draft.subCategory = payload.subCategory;
      return draft;

    case SET_SIDEBAR_SEARCH_TERM:
      {
        const {
          newSearchTerm
        } = payload;
        draft.sidebarSearchTerm = newSearchTerm;
        return draft;
      }

    case SET_SIDEBAR_ACTIVE_MATCH:
      {
        const {
          newActiveMatch
        } = payload;
        draft.sidebarActiveMatch = newActiveMatch;
        return draft;
      }

    case SET_SIDEBAR_MATCH_IDS:
      {
        const {
          newMatchIds
        } = payload;
        draft.sidebarMatchIds = newMatchIds;
        return draft;
      }

    case OPEN_EXPANDABLE_MENU:
      {
        const {
          newOpenExpandableMenuNodeId
        } = payload;
        draft.openExpandableMenuNodeId = newOpenExpandableMenuNodeId;
        return draft;
      }

    case CLEAR_OPEN_EXPANDABLE_MENU:
      {
        draft.openExpandableMenuNodeId = '';
        return draft;
      }

    case SAVE_SIDEBAR_SCROLL_TOP:
      {
        const {
          newSidebarScrollTop
        } = payload;
        draft.sidebarScrollTop = newSidebarScrollTop;
        return draft;
      }

    case SHOW_ALL_ROWS_FOR_DND_AREA:
      {
        const {
          idToAdd
        } = payload;
        const dndAreasThatShowAllRows = draft.dndAreasThatShowAllRows;
        dndAreasThatShowAllRows.add(idToAdd);
        return draft;
      }

    case SHOW_STANDARD_ROWS_FOR_DND_AREA:
      {
        const {
          idToRemove
        } = payload;
        const dndAreasThatShowAllRows = draft.dndAreasThatShowAllRows;
        dndAreasThatShowAllRows.delete(idToRemove);
        return draft;
      }

    case UPDATE_SIDEBAR_SEARCH_QUERY:
      {
        const {
          searchQuery
        } = payload;
        draft.searchQuery = searchQuery;
        return draft;
      }

    default:
      return draft;
  }
});

const initialSidebarState = ImmutableMap({
  activePanel: '',
  defaultPanel: '',
  previousPanel: '',
  sidebarHidden: false,
  sidebarCollapsed: false,
  selectedItemId: null,
  patagoniaSidebarCollapsed: true,
  scrollToModule: null,
  selectedModuleWasFakeModule: false,
  category: PATAGONIA_ADD_PANEL_CATEGORIES.MODULES,
  subCategory: null,
  defaultSelectedSubCategoryMap: ImmutableMap(PATAGONIA_DEFAULT_SELECTED_SUB_CATEGORIES),
  // Since the sidebar iframe is visible during the collapse animation, we use this animation end
  // state to wait until the iframe is hidden
  sidebarCollapseAnimationFinished: false,
  patagoniaSidebarCollapseAnimationFinished: true,
  // Store open and closed content sidebar tree nodes
  closedTreeNodes: ImmutableSet([]),
  // State for maintining search results between sidebar tabs
  sidebarSearchTerm: '',
  sidebarActiveMatch: '',
  sidebarMatchIds: OrderedSet([]),
  // State for managing what dnd areas show all rows
  dndAreasThatShowAllRows: ImmutableSet([]),
  // State for managing open dropdown menus
  openExpandableMenuNodeId: '',
  sidebarScrollTop: 0,
  searchQuery: ''
});
let previousRoutePath = null;

const setPanelHelper = (existingState, newPanel, options = {}) => {
  const {
    clearOutPreviousPanel = false,
    scrollToModuleInList = null
  } = options;
  const existingPanel = clearOutPreviousPanel === true ? '' : existingState.get('activePanel'); // Don't change previous panel if it matches current panel

  const newPreviousPanel = newPanel !== existingPanel ? existingPanel : existingState.get('previousPanel');
  const newState = existingState.set('previousPanel', newPreviousPanel).set('activePanel', newPanel).set('scrollToModule', scrollToModuleInList);
  return newState;
};

const setPanelToDefaultHelper = (state, {
  clearOutPreviousPanel = true
} = {}) => {
  return setPanelHelper(state, state.get('defaultPanel'), {
    clearOutPreviousPanel
  });
};

const getPreviousPanelHelper = state => state.get('previousPanel') || state.get('defaultPanel');

const setPreviousPanelHelper = (state, {
  clearOutPreviousPanel = false,
  clearOutActivePanel = false
}) => {
  const previousPanel = clearOutActivePanel ? '' : getPreviousPanelHelper(state);
  return setPanelHelper(state, previousPanel, {
    clearOutPreviousPanel
  });
};

const sidebarStateReducer = (state = initialSidebarState, action) => {
  const {
    type,
    name,
    payload,
    response
  } = action;

  switch (type) {
    case UPDATE_EMAIL_TYPE:
      return setPanelToDefaultHelper(state);

    case LOCATION_CHANGE:
      {
        // TODO, eventually cleanup and unify with other logic that sets
        // `activePanel` at
        // https://git.hubteam.com/HubSpot/ContentEditorUI/blob/52a0c94d5cc1258744c6424b65afb8348096b423/PageEditorUI/static/js/Application.js#L102-L105
        const {
          pathname
        } = payload;
        const paneName = getPaneNameFromPath(pathname);

        if (paneName === 'optimize') {
          state = state.set('activePanel', 'optimizer');
        }

        if (previousRoutePath === 'preview' || previousRoutePath === 'revisions') {
          // When leaving preview or revisions, reset the sidebar panel to it's default.
          state = state.set('activePanel', state.get('defaultPanel'));
          state = state.set('sidebarHidden', false);
        } else if (!NAV_TABS_SHOW_SIDEBAR[paneName]) {
          state = state.set('sidebarHidden', true);
        } else if (state.get('sidebarHidden')) {
          state = state.set('sidebarHidden', false);
        } else if (paneName === 'optimize') {
          state = state.merge({
            sidebarCollapsed: false,
            sidebarCollapseAnimationFinished: false,
            patagoniaSidebarCollapsed: true,
            patagoniaSidebarCollapseAnimationFinished: true
          });
        }

        previousRoutePath = paneName;
        return state;
      }

    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const contentSchema = response;
        const {
          schema
        } = contentSchema;
        const previousDefaultPanel = state.get('defaultPanel');
        const hasAnyFlexColumns = schema.widget_containers && Object.keys(schema.widget_containers).length > 0;
        const hasAnyLayoutSections = schema.layout_sections && Object.keys(schema.layout_sections).length > 0;
        const hasRichTextFieldWithModuleInsertion = response.content.categoryId === Categories.blog_post.id;
        const hasAnyDndAreas = hasAnyLayoutSections || hasRichTextFieldWithModuleInsertion;

        if (!hasAnyFlexColumns && !hasAnyDndAreas && previousDefaultPanel && previousDefaultPanel === SIDEBAR_PANELS.addWidget) {
          const activePanelUnchanged = state.get('activePanel') === previousDefaultPanel;

          if (activePanelUnchanged) {
            state = state.set('activePanel', SIDEBAR_PANELS.widgetList);
          }

          return state.set('defaultPanel', SIDEBAR_PANELS.widgetList);
        }

        return state;
      }

    case FETCH_ADDABLE_SECTIONS_SUCCESS:
      {
        const {
          marketerSectionsCount,
          allSections
        } = payload; // Edge case: if there are `Saved sections`, but there are `Theme sections`
        // Show themes as the default (because it has content)

        if (marketerSectionsCount === 0 && allSections.length > 0) {
          return state.setIn(['defaultSelectedSubCategoryMap', PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS], PATAGONIA_ADD_PANEL_SUB_CATEGORIES.SECTIONS.THEME);
        }

        return state;
      }
    // Note: NOT covering DELETE_SECTION_SUCCESS does not in itself
    // have enough info and feels like enough of edge case to ignore

    case SAVE_SECTION_SUCCESS:
      {
        // If a section is successfully saved, reset to the saved
        // (the top sub category on top)
        return state.setIn(['defaultSelectedSubCategoryMap', PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS], PATAGONIA_ADD_PANEL_SUB_CATEGORIES.SECTIONS.SAVED);
      }

    case SELECT_BREAKPOINT:
      {
        const {
          selectedBreakpoint,
          isPatagonia
        } = payload;
        const activeSidebarPanel = state.get('activePanel');
        const isSwitchingToNonDefaultBreakpoint = selectedBreakpoint !== 'default';
        const currentPanelIsAddModule = activeSidebarPanel === SIDEBAR_PANELS.addWidget;
        return isSwitchingToNonDefaultBreakpoint && currentPanelIsAddModule && !isPatagonia ? state.set('activePanel', SIDEBAR_PANELS.widgetList) : state;
      }

    case SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP:
    case SELECTED_ITEM_UPDATED:
      {
        const {
          id,
          doNotShowSidebarForm,
          isPatagonia
        } = payload; // Handle selection of post body and name "fake" modules differently

        if (isFakeModuleWithNoSidebarUI(id)) {
          state = state.set('selectedModuleWasFakeModule', true);
          return setPanelToDefaultHelper(state, {
            clearOutPreviousPanel: false
          });
        } else if (state.get('selectedModuleWasFakeModule')) {
          state = state.set('selectedModuleWasFakeModule', false);
        }

        const isPatagoniaSidebarCollapsed = state.get('patagoniaSidebarCollapsed');
        const newPanel = isPatagonia && isPatagoniaSidebarCollapsed && doNotShowSidebarForm ? '' : SIDEBAR_PANELS.edit;
        state = setPanelHelper(state, newPanel);

        if (doNotShowSidebarForm && (!isPatagonia || isPatagoniaSidebarCollapsed)) {
          return state;
        }

        return state.merge({
          selectedItemId: id,
          sidebarCollapsed: false,
          sidebarCollapseAnimationFinished: false,
          patagoniaSidebarCollapsed: false,
          patagoniaSidebarCollapseAnimationFinished: false
        });
      }
    // Only happens in non-Baymax

    case SAVED_MODULE:
      return state.set('activePanel', SIDEBAR_PANELS.widgetList);

    case NAVIGATE_TO_PREVIEW:
      return setPanelHelper(state, SIDEBAR_PANELS.preview);

    case OPENED_BAYMAX_SIDEBAR:
      return state.merge({
        sidebarCollapsed: false,
        sidebarCollapseAnimationFinished: false
      });

    case CLOSED_BAYMAX_SIDEBAR:
      return state.merge({
        sidebarCollapsed: true,
        sidebarCollapseAnimationFinished: false
      });

    case BAYMAX_SIDEBAR_ANIMATION_FINISHED:
      return state.set('sidebarCollapseAnimationFinished', true);

    case OPENED_PATAGONIA_SIDEBAR:
      return state.merge({
        patagoniaSidebarCollapsed: false,
        patagoniaSidebarCollapseAnimationFinished: !state.get('patagoniaSidebarCollapsed')
      });

    case CLOSED_PATAGONIA_SIDEBAR:
      return state.merge({
        patagoniaSidebarCollapsed: true,
        patagoniaSidebarCollapseAnimationFinished: false,
        activePanel: ''
      });

    case PATAGONIA_SIDEBAR_ANIMATION_FINISHED:
      return state.set('patagoniaSidebarCollapseAnimationFinished', true);

    case SET_PATAGONIA_ACTIVE_PANEL:
      if (payload === SIDEBAR_PANELS.addWidget && state.get('activePanel') !== SIDEBAR_PANELS.addWidget) {
        SidebarIframeStylesManager.animateCoveringVeil(true);
      }

      return setPanelHelper(state, payload);

    case OPENED_PANEL:
      return setPanelHelper(state, name, payload);

    case OPENED_PREVIOUS_PANEL:
    case SELECTED_ITEM_REVERTED_TO_PREVIOUS:
      return setPreviousPanelHelper(state, {
        clearOutPreviousPanel: true
      });

    case INPAGE_UI_STATE_CLEARED:
      if (state.get('previousPanel') === SIDEBAR_PANELS.edit) {
        // Prevent going "back" to the edit panel when no module is selected
        // Note, this _should not_ happen, but does in some edge cases (like blurring in optimize)
        return setPanelToDefaultHelper(state);
      } else {
        return setPreviousPanelHelper(state, {
          clearOutPreviousPanel: true
        });
      }

    case SELECTED_ITEM_CLEARED:
      {
        state = state.set('selectedItemId', null);

        if (state.get('selectedModuleWasFakeModule')) {
          return state.set('selectedModuleWasFakeModule', false);
        } else {
          if (payload.closePatagoniaSidebar) {
            state = state.merge({
              patagoniaSidebarCollapsed: true,
              patagoniaSidebarCollapseAnimationFinished: true
            });
          }

          return setPreviousPanelHelper(state, {
            clearOutPreviousPanel: !!payload.isPatagonia,
            clearOutActivePanel: !!payload.isPatagonia
          });
        }
      }

    case DELETED_MODULE:
    case LAYOUT_SECTION_MODULE_DELETED:
    case LAYOUT_SECTION_COLUMN_DELETED:
    case LAYOUT_SECTION_ROW_DELETED:
      {
        const {
          id,
          moduleId,
          columnId,
          rowId
        } = payload;
        const itemId = id || moduleId || columnId || rowId;

        if (itemId === state.get('selectedItemId')) {
          state = state.set('selectedItemId', null);
          return setPanelToDefaultHelper(state);
        }

        return state;
      }

    case SET_SCROLL_TO_IN_MODULE_LIST:
      {
        const {
          moduleId
        } = payload;
        return state.set('scrollToModule', moduleId);
      }
    // Only used in non-Baymax (classic) editors

    case CLOSED_PANEL:
      return state.set('previousPanel', '').set('activePanel', '');

    case FLUSH_SIDEBAR_HIDDEN_STATE:
      {
        const {
          paneName
        } = payload;

        if (previousRoutePath === 'preview' || previousRoutePath === 'revisions') {
          // When leaving preview or revisions, reset the sidebar panel to it's default.
          state = state.set('sidebarHidden', false);
        } else if (!NAV_TABS_SHOW_SIDEBAR[paneName]) {
          state = state.set('sidebarHidden', true);
        } else if (state.get('sidebarHidden')) {
          state = state.set('sidebarHidden', false);
        } else if (paneName === 'optimize') {
          state = state.merge({
            sidebarCollapsed: false,
            sidebarCollapseAnimationFinished: false,
            patagoniaSidebarCollapsed: false,
            patagoniaSidebarCollapseAnimationFinished: false
          });
        }

        return state;
      }

    case OPEN_TREE_NODES:
      {
        const {
          nodesToOpen
        } = payload;
        const closedTreeNodes = state.get('closedTreeNodes');
        const newClosedTreeNodes = closedTreeNodes.subtract(nodesToOpen);
        state = state.set('closedTreeNodes', newClosedTreeNodes);
        return state;
      }

    case CLOSE_TREE_NODES:
      {
        const {
          nodesToClose
        } = payload;
        const closedTreeNodes = state.get('closedTreeNodes');
        const newClosedTreeNodes = closedTreeNodes.union(nodesToClose);
        state = state.set('closedTreeNodes', newClosedTreeNodes);
        return state;
      }

    case SELECTED_CATEGORY_UPDATE:
      if (state.get('category') === payload.category) return state;
      state = state.set('category', payload.category);
      SidebarIframeStylesManager.animateCoveringVeil(false);

      if (payload.subCategory) {
        state = state.set('subCategory', payload.subCategory);
      }

      return state;

    case SELECTED_SUB_CATEGORY_UPDATE:
      return state.set('subCategory', payload.subCategory);

    case SET_SIDEBAR_SEARCH_TERM:
      {
        const {
          newSearchTerm
        } = payload;
        return state.set('sidebarSearchTerm', newSearchTerm);
      }

    case SET_SIDEBAR_ACTIVE_MATCH:
      {
        const {
          newActiveMatch
        } = payload;
        return state.set('sidebarActiveMatch', newActiveMatch);
      }

    case SET_SIDEBAR_MATCH_IDS:
      {
        const {
          newMatchIds
        } = payload;
        const newImmutableMatchIds = OrderedSet(newMatchIds);
        return state.set('sidebarMatchIds', newImmutableMatchIds);
      }

    case OPEN_EXPANDABLE_MENU:
      {
        const {
          newOpenExpandableMenuNodeId
        } = payload;
        return state.merge({
          openExpandableMenuNodeId: newOpenExpandableMenuNodeId
        });
      }

    case CLEAR_OPEN_EXPANDABLE_MENU:
      {
        return state.merge({
          openExpandableMenuNodeId: ''
        });
      }

    case SAVE_SIDEBAR_SCROLL_TOP:
      {
        const {
          newSidebarScrollTop
        } = payload;
        return state.set('sidebarScrollTop', newSidebarScrollTop);
      }

    case SHOW_ALL_ROWS_FOR_DND_AREA:
      {
        const {
          idToAdd
        } = payload;
        const dndAreasThatShowAllRows = state.get('dndAreasThatShowAllRows');
        const newDndAreasThatShowAllRows = dndAreasThatShowAllRows.add(idToAdd);
        return state.set('dndAreasThatShowAllRows', newDndAreasThatShowAllRows);
      }

    case SHOW_STANDARD_ROWS_FOR_DND_AREA:
      {
        const {
          idToRemove
        } = payload;
        const dndAreasThatShowAllRows = state.get('dndAreasThatShowAllRows');
        const newDndAreasThatShowAllRows = dndAreasThatShowAllRows.delete(idToRemove);
        return state.set('dndAreasThatShowAllRows', newDndAreasThatShowAllRows);
      }

    case UPDATE_SIDEBAR_SEARCH_QUERY:
      {
        const {
          searchQuery
        } = payload;
        return state.set('searchQuery', searchQuery);
      }

    default:
      return state;
  }
};

export { sidebarStateReducer, initialSidebarState, sidebarStateReducerImmer, initialSidebarStateAsJsObject };