'use es6';

import { Map as ImmutableMap } from 'immutable';
import { FETCH_CONTENT_SCHEMA_SUCCEEDED, MAB_EXPERIMENT_MODAL_LOADING, MAB_EXPERIMENT_MODAL_LOADED } from 'ContentEditorUI/redux/actions/actionTypes';

const mabExperimentReducer = (state = ImmutableMap(), {
  response,
  type
}) => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      return state.merge(Object.assign({
        isMabMaster: response.content.mabMaster,
        isMabVariant: response.content.mabVariant
      }, response.mab_experiment));

    case MAB_EXPERIMENT_MODAL_LOADING:
      return state.set('loading', true);

    case MAB_EXPERIMENT_MODAL_LOADED:
      return state.set('loading', false);

    default:
      return state;
  }
};

export default mabExperimentReducer;