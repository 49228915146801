import * as InternalFileAccess from './internal/fileAccess';
export const VISIBLE_IN_APP_PUBLIC_TO_ALL_INDEXABLE = InternalFileAccess.VISIBLE_IN_APP_PUBLIC_TO_ALL_INDEXABLE;
export const VISIBLE_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE = InternalFileAccess.VISIBLE_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE;
export const VISIBLE_IN_APP_PRIVATE_NOT_INDEXABLE = InternalFileAccess.VISIBLE_IN_APP_PRIVATE_NOT_INDEXABLE;
export const HIDDEN_IN_APP_PRIVATE_NOT_INDEXABLE = InternalFileAccess.HIDDEN_IN_APP_PRIVATE_NOT_INDEXABLE;
export const HIDDEN_IN_APP_PUBLIC_TO_ALL_INDEXABLE = InternalFileAccess.HIDDEN_IN_APP_PUBLIC_TO_ALL_INDEXABLE;
export const HIDDEN_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE = InternalFileAccess.HIDDEN_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE;
export const PickerFileAccessOptions = {
  VISIBLE_IN_APP_PUBLIC_TO_ALL_INDEXABLE,
  VISIBLE_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE,
  VISIBLE_IN_APP_PRIVATE_NOT_INDEXABLE
};
export const UploadFileAccessOptions = Object.assign({}, PickerFileAccessOptions, {
  HIDDEN_IN_APP_PRIVATE_NOT_INDEXABLE,
  HIDDEN_IN_APP_PUBLIC_TO_ALL_INDEXABLE,
  HIDDEN_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE
});