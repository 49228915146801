'use es6';

import { getLocalSetting } from './LocalEnv';
const LOCAL_OVERRIDDEN = 'true';
const LOCAL_NOT_OVERRIDDEN = 'false';
const LOCAL = 'local';
const APP = 'app';
const TOOLS = 'tools';
const windowOrigin = window.location.origin || // IE
`${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

const getLocalEnvSetting = name => getLocalSetting(name, LOCAL);

const toLocal = url => url.replace(APP, LOCAL).replace(TOOLS, LOCAL);

export const getOrigin = url => {
  if (url.indexOf('//') === 0) {
    url = `${window.location.protocol}${url}`;
  } else if (url.indexOf('/') === 0) {
    return windowOrigin;
  }

  const parts = url.split('://');

  if (parts.length > 1) {
    return `${parts[0]}://${parts[1].split('/')[0]}`;
  } else {
    return windowOrigin;
  }
}; // Allows for a complete local src URL override or using a local instead of app

export const getIFrameSrc = (iFrameName, url) => {
  const localEnvSetting = getLocalEnvSetting(iFrameName);

  if (localEnvSetting === LOCAL_NOT_OVERRIDDEN) {
    return url;
  }

  if (localEnvSetting === LOCAL_OVERRIDDEN) {
    return toLocal(url);
  }

  return localEnvSetting || url;
};