import { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import debounce from 'react-utils/debounce';
import { PulserOverlay } from '../pulser/PulserOverlay';
export const usePulserContainer = target => {
  const [pulserContainer, setPulserContainer] = useState(); // Use the first popoverId if multiple popovers are attached to the target.
  // This may not be the popoverId for the current tour step, but ensures the pulser
  // element is inserted before/below all popovers/floating components.

  const popoverId = (target && target.getAttribute('data-popover-id') || '').split(' ')[0];
  useEffect(() => {
    if (!target || !popoverId) {
      return undefined;
    } // Insert pulser dom before tour popover for making popover be above pulser overlay


    const popoverElement = document.getElementById(popoverId);
    const pulserElement = document.createElement('div');
    pulserElement.id = `pulser-for-${popoverId}`;

    if (popoverElement && popoverElement.parentNode) {
      popoverElement.parentNode.insertBefore(pulserElement, popoverElement);
    }

    setPulserContainer(pulserElement);
    return () => {
      if (pulserElement && pulserElement.parentNode) {
        pulserElement.parentNode.removeChild(pulserElement);
      }
    };
  }, [popoverId, target]);
  return pulserContainer;
};
export const usePulserOverlay = pulserRef => {
  const pulserOverlayRef = useRef(new PulserOverlay());
  const [overlayPath, setOverlayPath] = useState();
  const debouncedSetOverlayPath = useMemo(() => {
    return debounce(() => {
      setOverlayPath(pulserOverlayRef.current.overlayPath);
    }, 5);
  }, []); // expose addPulserPathToOverlay to pulser spotlight component
  // recalculate transparent holes in pulser overlay

  useImperativeHandle(pulserRef, () => ({
    repositionPulserOverlay: (pulserPath, oldPulserPath) => {
      pulserOverlayRef.current.generateOverlayPath(pulserPath, oldPulserPath);
      debouncedSetOverlayPath();
    }
  }), [debouncedSetOverlayPath]);
  return overlayPath;
};