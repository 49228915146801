/* hs-eslint ignored failing-rules */

/* eslint-disable promise/catch-or-return */
'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import { fetch, fetchHardSavedContent as fetchLive, deleteBuffer } from 'ContentEditorUI/api/ContentApi';
import { FETCH_CONTENT_SUCCEEDED, FETCH_CONTENT_FAILED, FETCH_HARD_SAVED_CONTENT_MODEL_SUCCEEDED, FETCH_HARD_SAVED_CONTENT_MODEL_FAILED, FETCH_CONTENT_STARTED, FETCH_HARD_SAVED_CONTENT_MODEL_STARTED } from 'ContentEditorUI/redux/actions/actionTypes';
import { getContentModelId, getSubcategory } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getCategoryId } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { showLoading, hideLoading, updateOptimisticLockingOnFetchContent } from 'ContentEditorUI/redux/actions/appActions';
import UsageTracker from 'ContentEditorUI/utils/UsageTracker';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import I18n from 'I18n';
import { getBaseContentUrl } from 'ContentEditorUI/api/ContentApi';
import { captureMessage } from 'ContentEditorUI/lib/exceptions'; // REQUEST FAILURES ---------------------->

const fetchContentFailure = error => {
  return {
    type: FETCH_CONTENT_FAILED,
    payload: error
  };
};

const fetchHardSavedContentFailure = error => {
  return {
    type: FETCH_HARD_SAVED_CONTENT_MODEL_FAILED,
    payload: error
  };
}; // REQUEST SUCCESSES --------------------->


const fetchContentSuccess = content => {
  return {
    type: FETCH_CONTENT_SUCCEEDED,
    payload: content
  };
};

const fetchHardSavedContentSuccess = content => {
  return {
    type: FETCH_HARD_SAVED_CONTENT_MODEL_SUCCEEDED,
    payload: content
  };
}; // REQUESTS ------------------------------>


export const fetchContent = (_ref) => {
  let {
    isFetchAfterPublish = false
  } = _ref,
      opts = _objectWithoutPropertiesLoose(_ref, ["isFetchAfterPublish"]);

  return (dispatch, getState) => {
    dispatch({
      type: FETCH_CONTENT_STARTED
    });

    const success = resp => {
      if (isFetchAfterPublish) {
        dispatch(updateOptimisticLockingOnFetchContent(resp));
      }

      dispatch(fetchContentSuccess(resp));
    };

    const error = resp => dispatch(fetchContentFailure(resp));

    const baseContentUrl = getBaseContentUrl(Object.assign({
      contentId: getContentModelId(getState()),
      categoryId: getCategoryId(getState()),
      subcategory: getSubcategory(getState())
    }, opts));
    return fetch(baseContentUrl).then(success, error);
  };
};
export const fetchHardSavedContent = (_ref2) => {
  let {
    isFetchAfterPublish = false
  } = _ref2,
      opts = _objectWithoutPropertiesLoose(_ref2, ["isFetchAfterPublish"]);

  return (dispatch, getState) => {
    dispatch({
      type: FETCH_HARD_SAVED_CONTENT_MODEL_STARTED
    });

    const success = resp => {
      if (isFetchAfterPublish) {
        dispatch(updateOptimisticLockingOnFetchContent(resp));
      }

      dispatch(fetchHardSavedContentSuccess(resp));
    };

    const error = resp => dispatch(fetchHardSavedContentFailure(resp));

    const baseContentUrl = getBaseContentUrl({
      baseUrl: opts.baseUrl,
      contentId: getContentModelId(getState()),
      categoryId: getCategoryId(getState()),
      subcategory: getSubcategory(getState())
    });
    fetchLive({
      baseContentUrl,
      query: opts.query
    }).then(success, error);
  };
};
export const deleteUnpublishedChanges = opts => (dispatch, getState) => {
  UsageTracker.trackEditorInteraction({
    action: 'Revisions Action',
    event: 'cleared-buffer-attempt'
  });
  dispatch(showLoading());
  const baseContentUrl = getBaseContentUrl(Object.assign({
    contentId: getContentModelId(getState()),
    categoryId: getCategoryId(getState()),
    subcategory: getSubcategory(getState())
  }, opts));
  deleteBuffer(baseContentUrl).then(() => {
    UsageTracker.trackEditorInteraction({
      action: 'Revisions Action',
      event: 'cleared-buffer'
    });
    window.location.reload();
  }, err => {
    dispatch(hideLoading());
    UsageTracker.trackEditorInteraction({
      action: 'Revisions Action',
      event: 'cleared-buffer-failed'
    });
    FloatingAlertStore.addAlert({
      type: 'danger',
      titleText: I18n.text('unpublishedChanges.errorClearingBuffer.title'),
      message: I18n.text('unpublishedChanges.errorClearingBuffer.title')
    });
    captureMessage('Error clearing buffer for email', {
      err
    });
  });
};