'use es6';

import * as FieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
import { makeErrorThrowingAsyncComponent } from 'ContentEditorUI/components/shared/makeAsyncUtils'; // Editor field overrides

const AsyncRichTextField = makeErrorThrowingAsyncComponent(() => {
  return Promise.all([import(
  /* webpackChunkName: 'RichTextField', webpackPrefetch: true */
  './PageLazyRichTextField'), importDlb('codemirror-dlb/bundle.js')]).then(([mod]) => mod.default()).catch(() => {
    throw new Error('Error loading CodeMirror or dependent module');
  });
});
const AsyncHtmlField = makeErrorThrowingAsyncComponent(() => {
  return Promise.all([import(
  /* webpackChunkName: 'HtmlField' */
  './LazyHtmlField'), importDlb('codemirror-dlb/bundle.js')]).then(([mod]) => mod.default()).catch(() => {
    throw new Error('Error loading CodeMirror or dependent module');
  });
});
export default {
  [FieldTypes.RICH_TEXT]: AsyncRichTextField,
  [FieldTypes.HUBL]: AsyncHtmlField,
  [FieldTypes.HTML]: AsyncHtmlField
};