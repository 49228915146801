'use es6';

import Immutable from 'immutable';
import { EDIT_FORM_VALIDATION_FINISHED } from 'ContentEditorUI/redux/actions/actionTypes';
const initialState = {
  isValid: undefined,
  errors: Immutable.Map() // Map of errorKey => { type, message, pane }

};

const FormStatus = (state = initialState, {
  type,
  valid,
  errors
}) => {
  switch (type) {
    case EDIT_FORM_VALIDATION_FINISHED:
      return Object.assign({}, state, {
        isValid: valid,
        errors: Immutable.fromJS(errors)
      });

    default:
      return state;
  }
};

export default FormStatus;