'use es6';

export default (() => {
  const result = {};
  const params = location.search.slice(1);

  if (params) {
    const pairs = params.split('&');
    pairs.forEach(pair => {
      if (pair) {
        const keyAndValue = pair.split('=');

        if (keyAndValue.length > 1) {
          result[keyAndValue[0]] = decodeURIComponent(keyAndValue[1]);
        }
      }
    });
  }

  return result;
});