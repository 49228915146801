import PropTypes from 'prop-types';
import createChainablePropType from './createChainablePropType';
let propType;
export function getIconNamePropType() {
  if (!propType) {
    let iconNames = []; // Optimization: omit this require in production builds (which skip proptype validation)

    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const iconNamesModule = require('icons/iconNames');

      iconNames = iconNamesModule.default || iconNamesModule;
    }

    propType = PropTypes.oneOf(iconNames);
  }

  return createChainablePropType(propType, 'iconName');
}