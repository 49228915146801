import { createSelector } from 'reselect';
import defaultArtifact from '../lib/defaultArtifact';
import { evictDefault } from '../lib/evict';
import { invariant, isFunction, isObject } from '../lib/helperFunctions';

const validateArtifactData = artifactData => {
  invariant(isObject(artifactData), 'Artifact must be an object');

  if (!artifactData.requestKey) {
    return 'Artifacts must have a requestKey';
  } else if (!artifactData.requestState) {
    return 'Artifacts must have a requestState';
  }

  return null;
};

const createArtifactBuilder = () => {
  let artifactId = 0;
  return artifactData => {
    if (artifactData) {
      const error = validateArtifactData(artifactData);
      invariant(!error, error);
      return Object.assign({}, artifactData, {
        id: artifactId++
      });
    }

    return null;
  };
};

export default ((getArtifactData, evict = evictDefault) => {
  invariant(isFunction(getArtifactData), 'getArtifactData must be a function');
  invariant(isFunction(evict), 'evict must be a function');
  const buildArtifact = createArtifactBuilder();
  const initialState = {
    artifacts: {},
    latestArtifacts: {}
  };
  return (state = initialState, action) => {
    const {
      artifacts,
      latestArtifacts
    } = state;
    const artifactData = getArtifactData(action);
    const artifact = buildArtifact(artifactData);
    const nextArtifacts = evict(artifacts, action, artifact);
    invariant(isObject(nextArtifacts), 'return value from eviction must always be an object');

    if (artifact) {
      const {
        id,
        requestKey
      } = artifact;
      return Object.assign({}, state, {
        artifacts: Object.assign({}, nextArtifacts, {
          [id]: artifact
        }),
        latestArtifacts: Object.assign({}, latestArtifacts, {
          [requestKey]: id
        })
      });
    }

    return Object.assign({}, state, {
      artifacts: nextArtifacts
    });
  };
});

const getRequestKey = (state, props = {}) => props.requestKey;

const getArtifacts = state => state.artifacts;

const getLatestArtifactIds = state => state.latestArtifacts;

export const getLatestArtifact = createSelector([getArtifacts, getLatestArtifactIds, getRequestKey], (artifacts, latestArtifactIds, requestKey) => {
  const artifact = artifacts[latestArtifactIds[requestKey]];
  return artifact || defaultArtifact;
});