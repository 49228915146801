'use es6';

import { FETCH_CONTENT_SCHEMA_SUCCEEDED, UNIVERSAL_APP_IS_READY } from 'ContentEditorUI/redux/actions/actionTypes';
import { createReducer } from 'ContentEditorUI/redux/utils';
import { BLOG_LISTING_PAGES_API_BASE_URL, PAGES_API_BASE_URL } from 'page-editor-ui/Constants';
const CategoryBaseUrlMap = {
  7: BLOG_LISTING_PAGES_API_BASE_URL
};

const getBaseApiUrl = categoryId => {
  return CategoryBaseUrlMap[categoryId] || PAGES_API_BASE_URL;
};

const handlers = {
  [FETCH_CONTENT_SCHEMA_SUCCEEDED]: (state, {
    response
  }) => {
    return Object.assign({}, state, {
      baseApiUrl: getBaseApiUrl(response.content.categoryId)
    });
  },
  [UNIVERSAL_APP_IS_READY]: state => {
    return Object.assign({}, state, {
      tempCanUseQuickSpot: true
    });
  }
};
export const extraInitialStateReducer = createReducer({}, handlers);