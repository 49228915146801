'use es6';

import useMount from 'ContentEditorUI/hooks/useMount';
import { removeBaymaxSkeleton, removePatagoniaSkeleton } from 'ContentEditorUI/lib/layoutDom';

const CleanupBaymaxSkeleton = () => {
  useMount(removeBaymaxSkeleton);
  useMount(removePatagoniaSkeleton);
  return null;
};

export default CleanupBaymaxSkeleton;