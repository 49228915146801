/* hs-eslint ignored failing-rules */

/* eslint-disable promise/catch-or-return */
'use es6';

import quickFetch from 'quick-fetch';
import { fetchOptinAvailability } from 'ui-addon-opt/api/OptinsAPI';
import { OPTIN_VIEW_NEW, OPTIN_VIEW_CURRENT } from 'ui-addon-opt/constants/UIOptConstants';
import { OPT_STATE_OPTED_IN } from './OptState';
export const toOptinView = availability => availability.state === OPT_STATE_OPTED_IN ? OPTIN_VIEW_NEW : OPTIN_VIEW_CURRENT;
export const getInitialOptinView = optinName => {
  const quickFetchKey = `optin-${optinName}`;
  const quickFetchedOptinAvailability = quickFetch.getRequestStateByName(quickFetchKey);

  if (quickFetchedOptinAvailability) {
    return new Promise((resolve, reject) => {
      quickFetchedOptinAvailability.whenFinished(availability => {
        resolve(availability.optinView);
      });
      quickFetchedOptinAvailability.onError(() => {
        fetchOptinAvailability(optinName).then(availability => {
          resolve(toOptinView(availability));
        }, reject);
      });
    });
  }

  return new Promise((resolve, reject) => {
    fetchOptinAvailability(optinName).then(availability => {
      resolve(toOptinView(availability));
    }, reject);
  });
};