import { combineReducers } from 'redux';
import * as ActionTypes from 'ContentData/actions/ActionTypes';
import * as RequestStatusTypes from 'ContentUtils/constants/RequestStatus';
import { isNullOrUndefined } from 'ContentUtils/helpers/ObjectHelpers';
const {
  BRAND_SETTINGS_FETCH_COLORS,
  BRAND_SETTINGS_FETCH_STARTED,
  BRAND_SETTINGS_FETCH_SUCCEEDED,
  BRAND_SETTINGS_FETCH_FAILED,
  BRAND_SETTINGS_BUSINESS_UNIT_FETCH,
  BRAND_SETTINGS_LOGO_FETCH,
  BRAND_SETTINGS_BRAND_KIT_FETCH
} = ActionTypes;
const {
  FAILED,
  PENDING,
  SUCCEEDED,
  UNINITIALIZED
} = RequestStatusTypes;

const brandSettings = (state = {}, action) => {
  switch (action.type) {
    case BRAND_SETTINGS_FETCH_SUCCEEDED:
      {
        const {
          brandData,
          businessUnitId
        } = action;
        const {
          branding,
          brandKitData
        } = brandData;
        let brandLogos = [];
        let currentBrandKit = {};
        brandKitData.forEach(_brandKit => {
          const {
            brandKit,
            logos
          } = _brandKit;
          brandLogos = brandLogos.concat(logos);

          if (!businessUnitId && brandKit.primaryBrandKit || businessUnitId && businessUnitId === brandKit.businessUnitId) {
            currentBrandKit = _brandKit;
          }
        });
        const {
          colors: brandColors = []
        } = currentBrandKit;
        const colors = brandColors.map(colorData => colorData.color);
        let primaryLogo = null;
        const logos = brandLogos.map(logo => {
          if (!primaryLogo && logo.primaryLogo) primaryLogo = logo;
          logo.src = logo.logoUrl;
          logo.alt = logo.logoAltText;
          return logo;
        });

        if (primaryLogo) {
          primaryLogo['src'] = primaryLogo['logoUrl'];
          primaryLogo['alt'] = primaryLogo['logoAltText'];
        }

        return Object.assign({}, state, {
          colors,
          logos,
          primaryLogo
        }, branding);
      }

    case BRAND_SETTINGS_FETCH_COLORS:
      {
        const {
          response
        } = action;
        if (!(response && response.length)) return state;
        return Object.assign({}, state, {
          colors: response.map(colorData => colorData.color)
        });
      }

    default:
      return state;
  }
};

const colorsRequestStatus = (state = UNINITIALIZED, action) => {
  switch (action.type) {
    case BRAND_SETTINGS_FETCH_COLORS:
      {
        const {
          response,
          error
        } = action;
        let requestStatus = PENDING;
        if (response) requestStatus = SUCCEEDED;
        if (error) requestStatus = FAILED;
        return requestStatus;
      }

    case BRAND_SETTINGS_FETCH_SUCCEEDED:
      {
        return SUCCEEDED;
      }

    default:
      return state;
  }
};

const requestStatus = (state = UNINITIALIZED, action) => {
  switch (action.type) {
    case BRAND_SETTINGS_FETCH_STARTED:
      {
        return PENDING;
      }

    case BRAND_SETTINGS_FETCH_SUCCEEDED:
      {
        return SUCCEEDED;
      }

    case BRAND_SETTINGS_FETCH_FAILED:
      {
        return FAILED;
      }

    default:
      return state;
  }
};

const businessUnitId = (state = null, action) => {
  const {
    type,
    businessUnitId: _businessUnitId
  } = action;

  if (type === BRAND_SETTINGS_BUSINESS_UNIT_FETCH && !isNullOrUndefined(_businessUnitId)) {
    return _businessUnitId;
  } else {
    return state;
  }
};

const brandKitId = (state = null, action) => {
  const {
    type,
    brandKitId: _brandKitId
  } = action;

  if (type === BRAND_SETTINGS_BRAND_KIT_FETCH && _brandKitId) {
    return _brandKitId;
  } else {
    return state;
  }
};

const brandedLogo = (state = null, action) => {
  if (action.type === BRAND_SETTINGS_LOGO_FETCH && action.brandedLogo) {
    const {
      logoUrl,
      logoAltText
    } = action.brandedLogo;
    return {
      src: logoUrl,
      alt: logoAltText
    };
  } else {
    return state;
  }
};

export default combineReducers({
  brandedLogo,
  brandSettings,
  businessUnitId,
  brandKitId,
  colorsRequestStatus,
  requestStatus
});