import emptyFunction from 'react-utils/emptyFunction';

const isPromise = obj => obj && typeof obj.then === 'function';

export default function handleCallback(callback, doAfter = emptyFunction, callbackParams, chainable) {
  if (Array.isArray(callback)) {
    const results = [];
    const promises = callback.reduce((promiseList, callbackFunction) => {
      const result = callbackFunction(callbackParams);

      if (isPromise(result)) {
        return promiseList.concat(result);
      }

      results.push(result);
      return promiseList;
    }, []);

    if (promises.length > 0) {
      const promise = Promise.all(promises).then(doAfter).catch(err => {
        doAfter();
        throw err;
      });

      if (chainable) {
        return promise;
      }

      promise.catch(err => {
        setTimeout(() => {
          throw err;
        });
      });
      return undefined;
    }

    doAfter();
    return results;
  }

  const result = callback(callbackParams);

  if (isPromise(result)) {
    result.then(doAfter).catch(error => {
      doAfter();
      throw error;
    });
    return undefined;
  }

  doAfter();
  return result;
}