import { getWidgetElement } from 'ContentEditorUI/lib/inpage/getWidgetElementDom';
import { getFlexAreaWrapper } from 'ContentEditorUI/lib/inpage/getFlexAreaWrapper';
export { getWidgetElement, getFlexAreaWrapper }; // Similar to InpageEditorUI/lib/scrollHashUtils.js@appendCurrentScrollPositionHashToUrl
// this saves the scroll position of the passed url into a fragment, which
// ultimately gets extracted and scrolled to after iframe refresh

export const getPreviewRefreshUrl = (url, scrollX, scrollY) => {
  const previewRefreshUrl = new URL(url);
  previewRefreshUrl.hash = `hsScrollTo=${scrollX},${scrollY}`; // Must cache bust with query param else iframes will not refresh when
  // replacing its src with the same url but with a new hash

  previewRefreshUrl.searchParams.set('cacheBust', Date.now().toString());
  return previewRefreshUrl.toString();
};