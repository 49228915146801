/* hs-eslint ignored failing-rules */

/* eslint-disable no-prototype-builtins */
import { pick } from 'underscore';
import { DISPLAY, CHOICES, LABEL, OCCURRENCE, MIN, MAX, NAME } from 'ContentUtils/constants/CustomWidgetFieldAttributes';
import { GraphQLScalars } from 'ContentUtils/constants/GraphQLScalars';
import * as CustomWidgetFieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
import NumberFormatTypes from 'ContentUtils/constants/NumberFormatTypes';
export const getModuleSchemaValues = (dataQuerySchema, existingValues) => {
  const relevantKeys = Object.keys(dataQuerySchema); // GraphQL schemas can change when the query changes so we need
  // gurantee we're only using the keys in the most updated query.

  return pick(existingValues, relevantKeys);
};

const dataFieldDefaults = (dataQuerySchema, existingValues) => {
  const usableParameters = getModuleSchemaValues(dataQuerySchema, existingValues);
  Object.keys(dataQuerySchema).forEach(gqlVariableName => {
    if (usableParameters.hasOwnProperty(gqlVariableName)) {
      dataQuerySchema[gqlVariableName].value = usableParameters[gqlVariableName];
    }
  });
  return dataQuerySchema;
};

export const moduleDataVariablesToFields = dataQueryVariables => {
  return Object.keys(dataQueryVariables).filter(dataQueryKey => dataQueryVariables[dataQueryKey]['dataTabVariable']).map(variableKey => {
    const variable = dataQueryVariables[variableKey];
    const {
      arrayValue,
      propertyLabel = null,
      variableName = null,
      propertyName = null,
      defaultValue = null,
      nonNullable
    } = variable;
    const commonProps = {
      [NAME]: variableName,
      id: variableName,
      default: defaultValue,
      [LABEL]: propertyLabel || propertyName
    };

    if (arrayValue) {
      commonProps[OCCURRENCE] = {
        [MIN]: nonNullable ? 1 : 0,
        [MAX]: 999
      };
    }

    switch (variable.type) {
      case GraphQLScalars.Enum:
        {
          return Object.assign({}, commonProps, {
            type: CustomWidgetFieldTypes.CHOICE,
            [DISPLAY]: 'select',
            [CHOICES]: variable.options.map(opt => [opt.name, opt.label])
          });
        }

      case GraphQLScalars.Currency:
        {
          return Object.assign({}, commonProps, {
            type: CustomWidgetFieldTypes.NUMBER,
            format: NumberFormatTypes.INTEGER,
            [DISPLAY]: 'text'
          });
        }

      case GraphQLScalars.Int:
      case GraphQLScalars.Number:
      case GraphQLScalars.Float:
        {
          return Object.assign({}, commonProps, {
            type: CustomWidgetFieldTypes.NUMBER,
            [DISPLAY]: 'text'
          });
        }

      case GraphQLScalars.String:
        {
          return Object.assign({}, commonProps, {
            type: CustomWidgetFieldTypes.TEXT
          });
        }

      case GraphQLScalars.ID:
        {
          return Object.assign({}, commonProps, {
            type: CustomWidgetFieldTypes.TEXT
          });
        }

      case GraphQLScalars.Date:
        {
          return Object.assign({}, commonProps, {
            type: CustomWidgetFieldTypes.DATE
          });
        }

      case GraphQLScalars.DateTime:
        {
          return Object.assign({}, commonProps, {
            type: CustomWidgetFieldTypes.DATE_TIME
          });
        }

      case GraphQLScalars.Boolean:
        {
          return Object.assign({}, commonProps, {
            type: CustomWidgetFieldTypes.BOOLEAN
          });
        }

      default:
        return null;
    }
  });
};
export const getContentSchemaFields = (dataQueryVariableSchema, moduleDataQueryVariables) => {
  const fieldDefaults = dataFieldDefaults(dataQueryVariableSchema, moduleDataQueryVariables);
  return moduleDataVariablesToFields(fieldDefaults);
};