/* hs-eslint ignored failing-rules */

/* eslint-disable promise/catch-or-return */
'use es6';

import { MAB_EXPERIMENT_MODAL_LOADING, MAB_EXPERIMENT_MODAL_LOADED, SHOW_LOADING, HIDE_LOADING } from 'ContentEditorUI/redux/actions/actionTypes';
import ContentRoutes from 'ContentUtils/Routes';
import { getCategoryId } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getContentModelId, getSubcategory } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getMABExperimentId, getMABExperimentMasterVariant, getMABExperimentMasterVariantId } from 'ContentEditorUI/redux/selectors/mabExperimentSelectors';
import MABExperimentApi from 'page-editor-ui/api/MABExperimentApi';
import { setTemporaryNewMABTestBit } from 'ContentEditorUI/utils/temporaryLocalStorageState';
import { showErrorWithHTML } from 'ContentEditorUI/utils/errorUtils';
import UsageTracker from 'ContentEditorUI/utils/UsageTracker';
import { getHasCurrentTranslations } from 'ContentEditorUI/redux/selectors/translationSelectors';
import { getAdditionalTranslationOptionsForSaveError } from 'ContentEditorUI/utils/errorUtils';
export const createMABExperiment = (metric, masterVariationName, variantNames) => {
  return (dispatch, getState) => {
    const appState = getState();
    const id = getContentModelId(appState);
    const categoryId = getCategoryId(appState);
    const subcategory = getSubcategory(appState);
    const hasCurrentTranslations = getHasCurrentTranslations(appState);
    dispatch({
      type: MAB_EXPERIMENT_MODAL_LOADING
    });
    const data = {
      masterVariationName,
      metric,
      variantNames
    };

    if (hasCurrentTranslations) {
      UsageTracker.trackAsBeacon('editorInteraction', {
        action: 'Experiment Action',
        event: 'clicked-create-mab-experiment-for-multi-lang'
      });
    } else {
      UsageTracker.trackAsBeacon('editorInteraction', {
        action: 'Experiment Action',
        event: 'clicked-create-mab-experiment'
      });
    }

    MABExperimentApi.createMABTest(categoryId, subcategory, id, data).then(({
      mabExperiment,
      master,
      variants
    }) => {
      if (!mabExperiment || !master || !variants || variants.length === 0) {
        return;
      }

      setTemporaryNewMABTestBit();
      window.location.href = ContentRoutes.edit(variants[0].id, categoryId);
    }).catch(error => {
      showErrorWithHTML({
        response: error,
        action: 'mabExperimentCreationFailed',
        additionalTranslationOptions: getAdditionalTranslationOptionsForSaveError(error.responseJSON, categoryId, subcategory)
      });
      dispatch({
        type: MAB_EXPERIMENT_MODAL_LOADED
      });
    });
  };
};
export const startMABExperiment = () => {
  return (dispatch, getState) => {
    const appState = getState();
    const masterVariation = getMABExperimentMasterVariant(appState);
    const id = masterVariation.get('id');
    const masterVariationName = masterVariation.get('name');
    const categoryId = getCategoryId(appState);
    const subcategory = getSubcategory(appState);
    const experimentId = getMABExperimentId(appState);
    dispatch({
      type: SHOW_LOADING
    });
    UsageTracker.trackEditorInteraction({
      action: 'Experiment Action',
      event: 'clicked-start-mab-experiment'
    });
    MABExperimentApi.startMABTest(categoryId, subcategory, experimentId).then(() => {
      window.location.href = ContentRoutes.details(id, categoryId, subcategory, null, {
        tab: 'testResults'
      });
    }).catch(error => showErrorWithHTML({
      action: 'mabExperimentStartFailed',
      response: error,
      additionalTranslationOptions: Object.assign({}, getAdditionalTranslationOptionsForSaveError(error.responseJSON, categoryId, subcategory), {
        masterVariationName,
        masterVariantSettingsURL: `${ContentRoutes.edit(id, categoryId)}/settings`
      })
    })).then(() => dispatch({
      type: HIDE_LOADING
    })).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
};
export const updateMABExperimentVariants = (newVariantNames, variantsToDelete, variantsToRename) => {
  return (dispatch, getState) => {
    const appState = getState();
    const categoryId = getCategoryId(appState);
    const subcategory = getSubcategory(appState);
    const experimentId = getMABExperimentId(appState);
    dispatch({
      type: MAB_EXPERIMENT_MODAL_LOADING
    });
    UsageTracker.trackEditorInteraction({
      action: 'Experiment Action',
      event: 'clicked-update-mab-experiment'
    });
    const deleteVariantsPromise = variantsToDelete.isEmpty() ? Promise.resolve() : MABExperimentApi.deleteVariants(categoryId, subcategory, variantsToDelete.map(variant => variant.get('id')).toArray());
    const renameVariantsPromises = variantsToRename.map(variant => MABExperimentApi.renameVariant(categoryId, subcategory, variant)).toArray();
    Promise.all([...renameVariantsPromises, deleteVariantsPromise]).then(() => !newVariantNames.isEmpty() ? MABExperimentApi.addMABVariants(categoryId, subcategory, experimentId, newVariantNames.toArray()) : MABExperimentApi.getMABTest(categoryId, subcategory, experimentId)).then(response => {
      if (response.variants && response.variants[0] && response.variants[0].id) {
        return response.variants[0].id;
      }

      return response.master.id;
    }).then(nextPageId => {
      window.location.href = ContentRoutes.edit(nextPageId, categoryId);
    }).catch(error => {
      showErrorWithHTML({
        response: error,
        action: 'mabExperimentEditFailed'
      });
      dispatch({
        type: MAB_EXPERIMENT_MODAL_LOADED
      });
    });
  };
};
export const deleteMABExperiment = ({
  updatedMasterVariation
}) => (dispatch, getState) => {
  const appState = getState();
  const categoryId = getCategoryId(appState);
  const subcategory = getSubcategory(appState);
  const experimentId = getMABExperimentId(appState);
  const masterVariantId = getMABExperimentMasterVariantId(appState);
  dispatch({
    type: MAB_EXPERIMENT_MODAL_LOADING
  });
  UsageTracker.trackEditorInteraction({
    action: 'Experiment Action',
    event: 'clicked-update-mab-experiment'
  });
  const masterRenamePromise = updatedMasterVariation ? MABExperimentApi.renameVariant(categoryId, subcategory, updatedMasterVariation) : Promise.resolve();
  masterRenamePromise.then(() => MABExperimentApi.deleteExperiment(categoryId, subcategory, experimentId).then(() => {
    window.location.href = ContentRoutes.edit(masterVariantId, categoryId);
  }).catch(error => {
    showErrorWithHTML({
      response: error,
      action: 'mabExperimentEditFailed'
    });
  }));
};