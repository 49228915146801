export function callIfPossible(prop, ...args) {
  if (typeof prop !== 'function') {
    return prop;
  }

  return prop(...args);
}
/**
 * Combines multiple event handlers into a single function.
 *
 * @example
 *  onClick={sequence(this.handleClick, onClick)}
 * @param  {...Function} handlers One or more event handlers to fire from the returned function
 * @returns {Function}
 */

export function sequence(...handlers) {
  return (...args) => {
    handlers.forEach(handler => typeof handler === 'function' && handler(...args));
  };
}
let sequenceCache;
const resultKey = {};
/**
 * Combines multiple event handlers into a single function. Unlike `sequence`, `memoizedSequence`
 * uses a cache to ensure that the same function instance is returned every time that you call it
 * with the same arguments, preventing unnecessary React updates.
 *
 * @example
 *  onClick={memoizedSequence(this.handleClick, onClick)}
 * @param  {...Function} handlers One or more event handlers to fire from the returned function
 * @returns {Function}
 */

export function memoizedSequence(...handlers) {
  const validHandlers = handlers.filter(handler => typeof handler === 'function');
  if (validHandlers.length === 0) return undefined;
  if (validHandlers.length === 1) return validHandlers[0]; // If this sequence has been generated before, return it

  let cache = sequenceCache;
  validHandlers.forEach(handler => {
    cache = cache && cache instanceof WeakMap && cache.get(handler);
  });
  if (cache instanceof WeakMap && cache.has(resultKey)) return cache.get(resultKey); // Otherwise, generate a new sequence and save it in the cache

  const newSequence = sequence(...validHandlers);
  if (sequenceCache === undefined) sequenceCache = new WeakMap();
  let newCache = sequenceCache;
  validHandlers.forEach(handler => {
    if (!(newCache.get(handler) instanceof WeakMap)) {
      newCache.set(handler, new WeakMap());
    }

    newCache = newCache.get(handler);
  });
  newCache.set(resultKey, newSequence);
  return newSequence;
}
let wrapRefObjectCache;

const makeCallbackForRefObject = ref => {
  return el => {
    ref.current = el;
  };
};
/**
 * Returns a callback wrapper around the given ref object.
 * @param {Object} ref
 */


export function wrapRefObject(ref) {
  if (ref == null) return ref;
  if (wrapRefObjectCache === undefined) wrapRefObjectCache = new WeakMap();
  if (wrapRefObjectCache.has(ref)) return wrapRefObjectCache.get(ref);
  const callback = makeCallbackForRefObject(ref);
  wrapRefObjectCache.set(ref, callback);
  return callback;
}