import { HS_INPAGE } from '../constants/hsSettings';
import { getPluginSetting } from '../utils';
const NAMESPACE = 'rich-text';
let _usageTracker = null;
export const getUsageTracker = (usageTracker, appName) => {
  if (_usageTracker) {
    return _usageTracker;
  }

  _usageTracker = usageTracker.clone({
    properties: {
      namespace: NAMESPACE,
      inApp: appName || 'unknown'
    }
  });
  return _usageTracker;
};
export const getTrackInteraction = ({
  usageTracker,
  appName,
  trackInteraction: _trackInteraction,
  inpage = false
}) => {
  return (eventName, action, meta = {}) => {
    const _eventName = eventName === 'Edit' ? 'tinymceEdit' : eventName;

    if (usageTracker) {
      const tracker = getUsageTracker(usageTracker, appName);
      tracker.track(_eventName, Object.assign({
        action,
        inline: inpage ? 'yes' : 'no'
      }, meta));
    } else if (_trackInteraction) {
      _trackInteraction(_eventName, Object.assign({
        action,
        inline: inpage ? 'yes' : 'no',
        inApp: appName || 'unknown'
      }, meta));
    }
  };
};
export const trackInteraction = (editor, eventName, action, meta = {}) => {
  const _trackInteraction = getTrackInteraction({
    usageTracker: getPluginSetting(editor, 'usageTracker'),
    trackInteraction: getPluginSetting(editor, 'trackInteraction'),
    appName: getPluginSetting(editor, 'appName'),
    inpage: editor.settings[HS_INPAGE]
  });

  _trackInteraction(eventName, action, meta);
};