import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';

/**
 * GET optins/v1/availability/{optinName}
 * @param {string} optinName
 * @returns {Promise}
 */
export const fetchOptin = (optinName, ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`/optins/v1/availability/${optinName}`);
};
/**
 * PUT optins/v1/availability/{optinName}/{newState}
 * @param {string} optinName
 * @param {string} newState
 * @param {number} currentActionAt
 * @returns {Promise}
 */

export const updateOptin = (optinName, newState, currentActionAt, ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.put(`/optins/v1/availability/${optinName}/${newState}?currentActionAt=${currentActionAt}`);
};