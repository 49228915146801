'use es6';

import I18n from 'I18n';
export default ((value, type, customTzName = '') => {
  let momentValue = I18n.moment[type || 'userTz'](value);

  if (customTzName && I18n.moment.tz.zone(customTzName)) {
    const convertedTzMomentValue = I18n.moment.utc(value).tz(customTzName);
    momentValue = convertedTzMomentValue;
  }

  return new Date(momentValue.year(), momentValue.month(), momentValue.date(), momentValue.hours(), momentValue.minutes(), momentValue.seconds(), momentValue.milliseconds());
});