'use es6';

import { connect } from 'react-redux';
import { Component } from 'react';
import { MODAL_TYPES } from 'ContentEditorUI/lib/modalConstants';
import { getOpenModal, getHasOpenPopOutEditor } from 'ContentEditorUI/redux/selectors/modalSelectors';
import { ModalTypes } from 'smart-content-core/constants';
const FILE_MANAGER_DRAWER_OPEN_EVENT = 'FileManagerLib:Drawer:open';
const FILE_MANAGER_DRAWER_CLOSE_EVENT = 'FileManagerLib:Drawer:close';
const {
  MANAGE_DEFINITION_PANEL
} = ModalTypes;
const {
  FORCE_IFRAME_HIDE
} = MODAL_TYPES; // ensures preview iframe doesn't prevent files being drag & dropped on file picker or dropzones
// bug: https://bugs.chromium.org/p/chromium/issues/detail?id=906137

class AutoHidePreviewFrameWhenDragDropModalIsOpen extends Component {
  constructor(props) {
    super(props);
    this.isChrome = navigator.userAgent.includes('Chrome') && navigator.vendor.includes('Google Inc');
    this.iframesTemporarilyHidden = [];
    this.hideIframes = this.hideIframes.bind(this);
    this.reshowIframes = this.reshowIframes.bind(this);
  }

  componentDidMount() {
    this.attachEventListeners();
  }

  componentDidUpdate(prevProps) {
    if (this.isChrome && prevProps.shouldHide !== this.props.shouldHide) {
      if (this.props.shouldHide) {
        this.hideIframes();
      } else {
        this.reshowIframes();
      }
    }
  }

  componentWillUnmount() {
    this.detachEventListeners();
  }

  attachEventListeners() {
    if (this.isChrome) {
      // The "new" file manager emits a custom event on the document when it opens and closes
      document.addEventListener(FILE_MANAGER_DRAWER_OPEN_EVENT, this.hideIframes);
      document.addEventListener(FILE_MANAGER_DRAWER_CLOSE_EVENT, this.reshowIframes);
    }
  }

  detachEventListeners() {
    if (this.isChrome) {
      document.removeEventListener(FILE_MANAGER_DRAWER_OPEN_EVENT, this.hideIframes);
      document.removeEventListener(FILE_MANAGER_DRAWER_CLOSE_EVENT, this.reshowIframes);
    }
  }

  hideIframes() {
    const iframe = document.querySelector('#contentEditor-preview');

    if (iframe) {
      iframe.style.pointerEvents = 'none';
      this.iframesTemporarilyHidden.push(iframe);
    }
  }

  reshowIframes() {
    this.iframesTemporarilyHidden.forEach(iframe => {
      iframe.style.pointerEvents = '';
    });
    this.iframesTemporarilyHidden = [];
  }

  render() {
    return null;
  }

}

const mapStateToProps = state => {
  const openModal = state.inpageModals.get('smartModalType') || getOpenModal(state);
  const hasModelOpenThatNeedDragAndDrop = [MANAGE_DEFINITION_PANEL, FORCE_IFRAME_HIDE].includes(openModal);
  return {
    shouldHide: hasModelOpenThatNeedDragAndDrop || getHasOpenPopOutEditor(state)
  };
};

export default connect(mapStateToProps)(AutoHidePreviewFrameWhenDragDropModalIsOpen);