// Note, these are not real Redux actions. Rather they are merely some Redux action-like code,
// to begin moving parts of the InpageEditorUI code away from direct event passing (ideally
// isolating that a few specific places which can then be more easily ported to Redux state)
//
// Also, this is different from event's posted on the bus with with action == reduxAction. Those are
// events which get routed directly to redux actions in the parent to update parent frame redux
// state. However, _these_ are different because they are still "legacy" events in the parent (e.g.
// events the parent directly subcribes to on the EventBus)
export default {
  // ADD CONTROLLER
  ADD_MODULE_TO_FLEX_COLUMN_FROM_INPAGE_SIDEBAR: 'ADD_MODULE_TO_FLEX_COLUMN_FROM_INPAGE_SIDEBAR',
  ADD_MODULE_TO_LAYOUT_SECTION_FROM_INPAGE_SIDEBAR: 'ADD_MODULE_TO_LAYOUT_SECTION_FROM_INPAGE_SIDEBAR',
  ADD_CUSTOM_SECTION_TO_DND_AREA_FROM_INPAGE_SIDEBAR: 'ADD_CUSTOM_SECTION_TO_DND_AREA_FROM_INPAGE_SIDEBAR',
  ADD_CUSTOM_LAYOUT_TO_DND_AREA_FROM_INPAGE_SIDEBAR: 'ADD_CUSTOM_LAYOUT_TO_DND_AREA_FROM_INPAGE_SIDEBAR',
  // MODULE HTML
  MODULE_HTML_REQUESTED: 'MODULE_HTML_REQUESTED',
  // Inpage and Sidebar iframes
  RELOAD_INPAGE_IFRAME: 'RELOAD_INPAGE_IFRAME',
  SAVE_AND_REFRESH_PREVIEW: 'SAVE_AND_REFRESH_PREVIEW',
  // LAYOUT CONTROLLER
  UPDATE_CONTAINERS: 'UPDATE_CONTAINERS',
  LOG_LAYOUT_SECTION_EVENT: 'LOG_LAYOUT_SECTION_EVENT',
  // SELENIUM TESTS
  LOG_SELENIUM_EVENT: 'LOG_SELENIUM_EVENT',
  // EDIT CONTROLLER
  SHOW_MODULE_HOVER_MENU: 'SHOW_MODULE_HOVER_MENU',
  DOCUMENT_CLICK: 'DOCUMENT_CLICK',
  HIDE_MODULE_HOVER_MENU: 'HIDE_MODULE_HOVER_MENU',
  IFRAME_WINDOW_RESIZE: 'IFRAME_WINDOW_RESIZE',
  POSITION_MODULE_MENU: 'POSITION_MODULE_MENU',
  // WIDGET EDITOR
  SHOW_EDIT_FORM: 'SHOW_EDIT_FORM',
  UPDATE_WIDGET: 'UPDATE_WIDGET',
  WIDGET_UPDATE_PENDING: 'WIDGET_UPDATE_PENDING',
  UPDATE_MODULE_META_DATA: 'UPDATE_MODULE_META_DATA',
  INPAGE_MERGE_MODULE_BODY: 'INPAGE_MERGE_MODULE_BODY',
  // STYLES
  UPDATE_COMPUTED_STYLES_FOR_SELECTORS: 'UPDATE_COMPUTED_STYLES_FOR_SELECTORS',
  REACT_ERROR_IN_IFRAME: 'REACT_ERROR_IN_IFRAME',
  LOG_LAME_ERROR_MESSAGE_TO_CONSOLE: 'LOG_LAME_ERROR_MESSAGE_TO_CONSOLE',
  SENTRY_MESSAGE_IN_IFRAME: 'SENTRY_MESSAGE_IN_IFRAME',
  TRACK_INPAGE_USAGE_EVENT: 'TRACK_INPAGE_USAGE_EVENT'
};