import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import Raven from 'raven-js';
import { upgradeProductSurveyResponseMap } from '../../trial/SurveyResponses';
import { SURVEY_RESPONSES_KEY } from '../constants/hubSettingsKeys';
export const getSurveyResponses = async () => {
  try {
    const response = await http.get(`trials/v1/json/for-portal/${SURVEY_RESPONSES_KEY}?portalId=${PortalIdParser.get()}`);
    return response.jsonData;
  } catch (error) {
    const requestError = error;

    if (!('status' in requestError) || requestError.status !== 404) {
      console.error('Error getting survey responses', requestError);
      Raven.captureException(requestError);
    }

    return upgradeProductSurveyResponseMap;
  }
};
export async function setSurveyResponses(surveyResponses) {
  try {
    await http.put(`trials/v1/json/for-portal`, {
      data: {
        lookupKey: SURVEY_RESPONSES_KEY,
        jsonData: surveyResponses
      }
    });
  } catch (error) {
    console.error('Error saving survey responses', error);
    Raven.captureException(error);
  }
}