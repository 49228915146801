import { SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP, SELECTED_ITEM_UPDATED, SELECTED_ITEM_CLEARED, CLEAR_SMART_VIEW, UPDATE_SMART_VIEW, UPDATED_MODULE_SMART_RULES } from 'ContentEditorUI/redux/actions/actionTypes';
import produce from 'immer';
const initialState = {
  criterionId: null,
  moduleId: null,
  order: null,
  targetType: null,
  smartViewUpdatedAt: null
};
export const smartViewReducer = (state = initialState, action) => produce(state, draft => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP:
    case SELECTED_ITEM_UPDATED:
    case CLEAR_SMART_VIEW:
    case SELECTED_ITEM_CLEARED:
      {
        draft.criterionId = null;
        draft.moduleId = null;
        draft.order = null;
        draft.targetType = null;
        draft.smartViewUpdatedAt = Date.now();
        return draft;
      }

    case UPDATE_SMART_VIEW:
      {
        draft.criterionId = payload.criterionId;
        draft.moduleId = payload.moduleId;
        draft.order = payload.order;
        draft.targetType = payload.targetType;
        draft.smartViewUpdatedAt = Date.now();
        return draft;
      }

    case UPDATED_MODULE_SMART_RULES:
      {
        draft.smartViewUpdatedAt = Date.now();
        return draft;
      }

    default:
      return draft;
  }
});
export default smartViewReducer;