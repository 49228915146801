'use es6';

import { MODULE_REDUCER_CHANGE_UNDO, MODULE_REDUCER_CHANGE_REDO, MODULE_REDUCER_CLEAR_UNDO_REDO, MODULE_REDUCER_MAYBE_CLEAR_EMAIL_SELECTION } from 'ContentEditorUI/redux/actions/actionTypes';
import { getSelectedItemId, getHasSelectionState } from 'ContentEditorUI/redux/selectors/selectionSelectors';
import { getModuleById } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { clearSelectedItem } from 'ContentEditorUI/redux/actions/selectionActions';
import { UNDO_EDIT as STRUCTURED_CONTENT_UNDO, REDO_EDIT as STRUCTURED_CONTENT_REDO } from 'structured-content-lib/redux/actions/ActionTypes';
import { getIsDataAuthoringModeActive } from 'ContentEditorUI/redux/selectors/instanceEditingSelectors';
export const undoModuleReducerChange = () => (dispatch, getState) => {
  const oldState = getState();

  if (getIsDataAuthoringModeActive(oldState)) {
    dispatch({
      type: STRUCTURED_CONTENT_UNDO
    });
    return;
  }

  dispatch({
    type: MODULE_REDUCER_CHANGE_UNDO
  });
  const newState = getState(); // This code is checking for instances where someone adds a module,
  // that module is focused, and then the user undo's the add module action
  // We check to see if the modules are different and if there is still
  // a selected item in the current state, and if so, if that module still
  // exists in the current state's module list
  // If it doesn't, then the undo action must have removed a module that was focused

  if (!getHasSelectionState(newState)) {
    // Email doesn't use the selection redux slice, so we can't do our "clear selected item"
    dispatch({
      type: MODULE_REDUCER_MAYBE_CLEAR_EMAIL_SELECTION,
      newState
    });
    return;
  }

  if (oldState.modules !== newState.modules) {
    const selectedItemId = getSelectedItemId(newState);

    if (selectedItemId && !getModuleById(newState, selectedItemId)) {
      dispatch(clearSelectedItem());
    }
  }
};
export const redoModuleReducerChange = () => (dispatch, getState) => {
  if (getIsDataAuthoringModeActive(getState())) {
    dispatch({
      type: STRUCTURED_CONTENT_REDO
    });
    return;
  }

  dispatch({
    type: MODULE_REDUCER_CHANGE_REDO
  });
};
export const clearUndoRedoModuleReducerChange = () => ({
  type: MODULE_REDUCER_CLEAR_UNDO_REDO
});