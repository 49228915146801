import PortalIdParser from 'PortalIdParser';
import { isUnsafeUrl } from 'UIComponents/utils/UnsafeUrl'; // @ts-expect-error URLParts not typed

import UrlParts from 'PatternValidationJS/regex/parts/UrlParts';
export const URL_REGEX_WITH_REQUIRED_PROTOCOL_REGEX = new RegExp(`^(${UrlParts.protocol})?${UrlParts.domain}(?:${UrlParts.path}(?:${UrlParts.file})?)?${UrlParts.search}?$`, 'i');
export const URL_REGEX_WITHOUT_PROTOCOL_REGEX = new RegExp(`${UrlParts.domain}(?:${UrlParts.path}(?:${UrlParts.file})?)?${UrlParts.search}?$`, 'i');
const PORTAL_ID_FROM_PATH_REGEX = /\/(?:[A-Za-z0-9-_]*)\/(\d+)(?:\/|$)/;
const FULL_HS_URL_REGEX = /^http(s)?:\/\/[a-z0-9-]+\.hubspot(qa)?.com/;
/**
 * Check if current path and given URL has matching portal ID
 *
 * @param {String} url
 * @returns {Boolean} true if portal ID matches
 */

export const hasMatchingPortalId = url => {
  const portalIdFromPath = PortalIdParser.getPortalIdFromPath();
  const portalIdFromRef = PortalIdParser.parsePortalIdFromString(url, PORTAL_ID_FROM_PATH_REGEX);
  return portalIdFromRef === portalIdFromPath;
};
/**
 * Validate a redirect URL by checking that it:
 *  - is safe (against XSS)
 *  - has a matching portalId with the current location path
 *  - is either a
 *    - relative URL (ex. /its/so/relative)
 *    - full HubSpot URL (ex. https://app.hubspot.com/path)
 *
 * @param {String} url
 * @returns {Boolean} true if valid app redirect URL
 */

export const isValidAppRedirect = url => {
  if (!url) {
    return false;
  }

  const isRelativeUrl = url.indexOf('/') === 0;
  const isFullHubSpotUrl = FULL_HS_URL_REGEX.test(url);
  return !isUnsafeUrl(url) && hasMatchingPortalId(url) && (isRelativeUrl || isFullHubSpotUrl);
};