'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import { buildDndAreaCellSelector, buildDndAreaRowSelector, buildDndAreaUniqueRowSelector } from '../dom/dndAreaDomSelectors';
import { getGradientDirectionTypeFromObject } from './implementations/backgroundLinearGradient';
import { convertRgbaToHexAndAlphaInt } from 'ContentUtils/helpers/ColorsHelpers';
export const getDefaultDisplayStyleType = allStyleValues => {
  const needsDisplayFlex = allStyleValues.verticalAlignment || allStyleValues.flexboxPositioning;
  return needsDisplayFlex ? 'flex !important' : 'block !important';
};
export const hasBackgroundImageOrGradient = el => {
  const computedStyles = window.getComputedStyle(el);
  return computedStyles['background-image'].indexOf('none') === -1;
};
export const hasSolidColorBackground = el => {
  const computedStyles = window.getComputedStyle(el);
  return computedStyles['background-color'].indexOf('rgba(0, 0, 0, 0)') === -1;
};
export const isTransparentElement = el => {
  return !hasSolidColorBackground(el) && !hasBackgroundImageOrGradient(el);
}; // Will recursively walk up the tree until it finds a non transparent parent element and returns
// that background color

export const calculateBackgroundColorForElement = el => {
  if (hasSolidColorBackground(el)) {
    return window.getComputedStyle(el)['background-color'];
  } else if (el.parentNode && el.parentNode.style) {
    return calculateBackgroundColorForElement(el.parentNode);
  }

  return 'white';
};
export const getBackgroundColorFromLayoutStyles = layoutStyles => {
  if (!layoutStyles || !layoutStyles.backgroundColor) {
    return null;
  }

  return Object.assign({}, convertRgbaToHexAndAlphaInt(layoutStyles.backgroundColor));
};
export const getBackgroundImageFromLayoutStyles = layoutStyles => {
  if (!layoutStyles || !layoutStyles.backgroundImage) {
    return null;
  }

  return layoutStyles.backgroundImage;
};

const convertBackgroundGradient = backgroundGradient => {
  const convertedColorStops = [];
  backgroundGradient.colors.forEach(stop => {
    const convertedColor = convertRgbaToHexAndAlphaInt(stop.color);
    convertedColorStops.push(Object.assign({}, stop, {
      color: convertedColor
    }));
  });
  const directionType = getGradientDirectionTypeFromObject(backgroundGradient.sideOrCorner);
  return Object.assign({}, backgroundGradient, {
    colors: convertedColorStops,
    sideOrCorner: directionType
  });
};

export const getBackgroundGradientFromLayoutStyles = layoutStyles => {
  if (!layoutStyles || !layoutStyles.backgroundGradient || !layoutStyles.backgroundGradient.colors) {
    return null;
  }

  return convertBackgroundGradient(layoutStyles.backgroundGradient);
};
export const API_BACKGROUND_TYPES = {
  COLOR: 'COLOR',
  IMAGE: 'IMAGE',
  GRADIENT: 'GRADIENT'
};
const BACKGROUND_TYPES = {
  BG_COLOR_TYPE: 'BG_COLOR_TYPE',
  BG_IMAGE_TYPE: 'BG_IMAGE_TYPE',
  BG_GRADIENT_TYPE: 'BG_GRADIENT_TYPE'
};
export const getBackgroundLayersFromLayoutStyles = (layoutStyles, activeBreakpoint) => {
  if (layoutStyles && activeBreakpoint && layoutStyles.breakpointStyles && layoutStyles.breakpointStyles[activeBreakpoint] && layoutStyles.breakpointStyles[activeBreakpoint].backgroundLayers) {
    const backgroundLayers = layoutStyles.breakpointStyles[activeBreakpoint].backgroundLayers;
    return backgroundLayers.map(layer => {
      const {
        type
      } = layer,
            layerData = _objectWithoutPropertiesLoose(layer, ["type"]);

      switch (type) {
        case API_BACKGROUND_TYPES.COLOR:
          return Object.assign({
            type: BACKGROUND_TYPES.BG_COLOR_TYPE
          }, Object.keys(layerData).length > 0 ? convertRgbaToHexAndAlphaInt(layerData) : null);

        case API_BACKGROUND_TYPES.IMAGE:
          return Object.assign({
            type: BACKGROUND_TYPES.BG_IMAGE_TYPE
          }, layerData);

        case API_BACKGROUND_TYPES.GRADIENT:
          return Object.assign({
            type: BACKGROUND_TYPES.BG_GRADIENT_TYPE
          }, layerData && layerData.colors ? convertBackgroundGradient(layerData) : null);

        default:
          throw new Error(`Invalid background layer: ${JSON.stringify(layer)}`);
      }
    });
  } else {
    return null;
  }
};
export const getHiddenFromLayoutStyles = (layoutStyles, activeBreakpoint) => {
  if (layoutStyles && activeBreakpoint && layoutStyles.breakpointStyles && layoutStyles.breakpointStyles[activeBreakpoint] && layoutStyles.breakpointStyles[activeBreakpoint].hidden !== null) {
    return layoutStyles.breakpointStyles[activeBreakpoint].hidden;
  } else {
    return null;
  }
};
export const getMarginFromLayoutStyles = (layoutStyles, activeBreakpoint) => {
  if (!layoutStyles) {
    return null;
  }

  if (activeBreakpoint && layoutStyles.breakpointStyles && layoutStyles.breakpointStyles[activeBreakpoint] && layoutStyles.breakpointStyles[activeBreakpoint].margin) {
    return layoutStyles.breakpointStyles[activeBreakpoint].margin;
  } else if (layoutStyles.margin) {
    return layoutStyles.margin;
  } else {
    return null;
  }
};
export const getPaddingFromLayoutStyles = (layoutStyles, activeBreakpoint) => {
  if (!layoutStyles) {
    return null;
  }

  if (activeBreakpoint && layoutStyles.breakpointStyles && layoutStyles.breakpointStyles[activeBreakpoint] && layoutStyles.breakpointStyles[activeBreakpoint].padding) {
    return layoutStyles.breakpointStyles[activeBreakpoint].padding;
  } else if (layoutStyles.padding) {
    return layoutStyles.padding;
  } else {
    return null;
  }
};
export const getMobilePaddingFromLayoutStyles = layoutStyles => {
  if (!layoutStyles || !layoutStyles.mobilePadding) {
    return null;
  }

  return layoutStyles.mobilePadding;
};
export const getSectionMaxWidthFromLayoutStyles = layoutStyles => {
  if (!layoutStyles || !layoutStyles.maxWidthSectionCentering) {
    return null;
  }

  return layoutStyles.maxWidthSectionCentering;
};
export const getSectionForceFullWidthFromLayoutStyles = layoutStyles => {
  if (!layoutStyles || !layoutStyles.forceFullWidthSection) {
    return null;
  }

  return layoutStyles.forceFullWidthSection;
};
export const getFlexboxPositioningFromLayoutStyles = layoutStyles => {
  if (!layoutStyles || !layoutStyles.flexboxPositioning) {
    return null;
  }

  return layoutStyles.flexboxPositioning;
}; // Gets the unit of the current style input
// Note, this assumes all units are ascii chars (except for `%`)

const NUMBER__WITH_UNIT_REGEX = /(\d*(\.?\d+)?)\s*([A-Za-z]+|%)/;
export const getUnit = (currentValue, computedValue) => {
  const valueToCheck = currentValue != null ? currentValue : computedValue;

  if (typeof valueToCheck === 'string') {
    const matches = valueToCheck.match(NUMBER__WITH_UNIT_REGEX);

    if (matches && matches[1] && matches[3]) {
      return matches[3];
    }
  } // default to px (i.e currentValue = 5 => return px)


  return 'px';
};
/** Converts something like
 * {
 *  margin: {
 *    top: {
 *      value: 25, units: 'px'
 *    }
 *  }
 * }
 * to
 * margin-top { '25px' }
 */

export const convertMarginPaddingBreakpointStylesToCssFormat = styles => {
  const defaultStylesObject = {};
  Object.keys(styles).forEach(key => {
    Object.keys(styles[key]).forEach(dir => {
      const value = styles[key][dir].value;
      let styleValue;

      if (value === null) {
        styleValue = null;
      } else {
        styleValue = `${value}${styles[key][dir].units}`;
      }

      defaultStylesObject[`${key}-${dir}`] = styleValue;
    });
  });
  return defaultStylesObject;
};
const allowedUnitsByDirection = {
  top: 'topUnits',
  bottom: 'bottomUnits',
  left: 'leftUnits',
  right: 'rightUnits'
};
export const convertMarginPaddingToLayoutStylesFormat = styles => {
  const newLayoutStylesObject = {};
  Object.keys(styles).forEach(key => {
    const value = styles[key];
    const [marginOrPadding, direction] = key.split('-');
    newLayoutStylesObject[marginOrPadding] = newLayoutStylesObject[marginOrPadding] || {};
    const parsedNumber = parseFloat(value);
    newLayoutStylesObject[marginOrPadding][direction] = isNaN(parsedNumber) ? null : parsedNumber;
    const unitKey = allowedUnitsByDirection[direction];
    newLayoutStylesObject[marginOrPadding][unitKey] = isNaN(parsedNumber) ? null : getUnit(value);
  });
  return newLayoutStylesObject;
};
export const convertMarginPaddingToBreakpointStylesFormat = styles => {
  const newLayoutStylesObject = {};
  Object.keys(styles).forEach(key => {
    const value = styles[key];
    const [marginOrPadding, direction] = key.split('-');
    newLayoutStylesObject[marginOrPadding] = newLayoutStylesObject[marginOrPadding] || {};
    const parsedNumber = parseFloat(value);

    if (isNaN(parsedNumber)) {
      newLayoutStylesObject[marginOrPadding][direction] = null;
    } else {
      newLayoutStylesObject[marginOrPadding][direction] = {};
      newLayoutStylesObject[marginOrPadding][direction].value = parsedNumber; // TODO: Support more than just px units here
      // Should support more than just px now

      newLayoutStylesObject[marginOrPadding][direction].units = getUnit(value);
    }
  });
  return newLayoutStylesObject;
};
export const buildTreeNodeDomSelector = treeNode => {
  if (treeNode.isRow()) {
    const rendererRowName = treeNode.getRendererCompatibleRowName();
    return buildDndAreaRowSelector(rendererRowName);
  }

  return buildDndAreaCellSelector(treeNode.getName());
};
export const getTreeNodeDomProperties = treeNode => {
  let domNodeSelector;
  let rendererName;

  if (treeNode.isRow()) {
    rendererName = treeNode.getRendererCompatibleRowName();
    domNodeSelector = buildDndAreaUniqueRowSelector(treeNode.getParent().getName(), treeNode.getName());
  } else {
    rendererName = treeNode.getName();
    domNodeSelector = buildDndAreaCellSelector(rendererName);
  }

  return {
    domNodeSelector,
    rendererName
  };
};