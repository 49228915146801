/* hs-eslint ignored failing-rules */

/* eslint-disable promise/catch-or-return */
'use es6';

import * as actionTypes from 'page-editor-ui/redux/actions/actionTypes';
import http from 'hub-http/clients/apiClient';
import { HUBDB_TABLES_URL, DATA_QUERIES_URL, STYLESHEETS_URL_START, STYLESHEETS_URL_END, AVAILABLE_STYLESHET_URL, CRM_OBJECT_TYPES_URL, CRM_OBJECT_SCHEMA_URL } from 'ContentEditorUI/lib/pageSettings/constants';
import ContactsApi from 'ContentEditorUI/api/ContactsApi';
import FileManagerApi from 'ContentEditorUI/api/FileManagerApi';
import ListRegistrationsAPI from 'ContentEditorUI/js/api/ListRegistrationsApi'; // UPDATE STATE ---------------------->

export const updateAudienceAccess = access => {
  return {
    type: actionTypes.UPDATE_PAGE_SETTINGS_AUDIENCE_ACCESS,
    payload: access
  };
}; // REQUEST FAILURES ---------------------->

const fetchHubDbTablesFailed = error => {
  return {
    type: actionTypes.FETCH_HUBDB_TABLES_FAILED,
    payload: error
  };
};

const fetchDataQueriesFailed = error => {
  return {
    type: actionTypes.FETCH_DATA_QUERIES_FAILED,
    payload: error
  };
};

const fetchPageStylesheetInfoFailed = error => {
  return {
    type: actionTypes.FETCH_PAGE_STYLESHEET_INFO_FAILED,
    payload: error
  };
};

const fetchAvailablePageStylesheetsFailed = error => {
  return {
    type: actionTypes.FETCH_AVAILABLE_PAGE_STYLESHEETS_FAILED,
    payload: error
  };
};

const fetchContactListsFailed = error => {
  return {
    type: actionTypes.FETCH_CONTACT_LISTS_FAILED,
    payload: error
  };
};

const fetchContactListsForContentFailed = error => {
  return {
    type: actionTypes.FETCH_CONTACT_LISTS_FOR_CONTENT_FAILED,
    payload: error
  };
};

const fetchContactListRegistrationsFailed = error => {
  return {
    type: actionTypes.FETCH_CONTACT_LIST_REGISTRATIONS_FAILED,
    payload: error
  };
};

const fetchFeaturedImageThumbnailUrlsFailed = error => {
  return {
    type: actionTypes.FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_FAILED,
    payload: error
  };
};

const fetchCrmObjectTypesFailed = error => {
  return {
    type: actionTypes.FETCH_CRM_OBJECT_TYPES_FAILED,
    payload: error
  };
};

const fetchCrmObjectSchemaFailed = error => {
  return {
    type: actionTypes.FETCH_CRM_OBJECT_SCHEMA_FAILED,
    payload: error
  };
}; // REQUEST SUCCESSES --------------------->


const fetchHubDbTablesSucceeded = hubDbTables => {
  return {
    type: actionTypes.FETCH_HUBDB_TABLES_SUCCEEDED,
    payload: hubDbTables
  };
};

const fetchDataQueriesSucceeded = dataQueries => {
  return {
    type: actionTypes.FETCH_DATA_QUERIES_SUCCEEDED,
    payload: dataQueries
  };
};

const fetchPageStylesheetInfoSucceeded = stylesheetInfo => {
  return {
    type: actionTypes.FETCH_PAGE_STYLESHEET_INFO_SUCCEEDED,
    payload: stylesheetInfo
  };
};

const fetchAvailablePageStylesheetsSucceeded = stylesheets => {
  return {
    type: actionTypes.FETCH_AVAILABLE_PAGE_STYLESHEETS_SUCCEEDED,
    payload: stylesheets
  };
};

const fetchContactListsSucceeded = lists => {
  return {
    type: actionTypes.FETCH_CONTACT_LISTS_SUCCEEDED,
    payload: lists
  };
};

const fetchContactListsForContentSucceeded = lists => {
  return {
    type: actionTypes.FETCH_CONTACT_LISTS_FOR_CONTENT_SUCCEEDED,
    payload: lists
  };
};

const fetchContactListRegistrationsSucceeded = registrations => {
  return {
    type: actionTypes.FETCH_CONTACT_LIST_REGISTRATIONS_SUCCEEDED,
    payload: registrations
  };
};

const fetchFeaturedImageThumbnailUrlsSucceeded = imageUrls => {
  return {
    type: actionTypes.FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_SUCCEEDED,
    payload: imageUrls
  };
};

const fetchCrmObjectTypesSucceeded = objectTypes => {
  return {
    type: actionTypes.FETCH_CRM_OBJECT_TYPES_SUCCEEDED,
    payload: objectTypes
  };
};

const fetchCrmObjectSchemaSucceeded = objectSchema => {
  return {
    type: actionTypes.FETCH_CRM_OBJECT_SCHEMA_SUCCEEDED,
    payload: objectSchema
  };
}; // REQUESTS ------------------------------>


export const fetchHubDbTables = () => dispatch => {
  dispatch({
    type: actionTypes.FETCH_HUBDB_TABLES_STARTED
  });

  const success = resp => {
    if (resp && resp.objects) {
      dispatch(fetchHubDbTablesSucceeded(resp.objects));
    } else {
      fetchHubDbTablesFailed(resp);
    }
  };

  const error = err => dispatch(fetchHubDbTablesFailed(err));

  http.get(HUBDB_TABLES_URL, {
    query: {
      publishedAt__gt: 0,
      useForPages: true
    }
  }).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchDataQueries = () => dispatch => {
  dispatch({
    type: actionTypes.FETCH_DATA_QUERIES_STARTED
  });

  const success = resp => {
    if (resp && Array.isArray(resp)) {
      dispatch(fetchDataQueriesSucceeded(resp));
    } else {
      fetchDataQueriesFailed(resp);
    }
  };

  const error = err => dispatch(fetchDataQueriesFailed(err));

  http.get(DATA_QUERIES_URL).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchCrmObjectTypes = () => dispatch => {
  dispatch({
    type: actionTypes.FETCH_CRM_OBJECT_TYPES_STARTED
  });

  const success = resp => {
    if (resp) {
      dispatch(fetchCrmObjectTypesSucceeded(resp));
    } else {
      dispatch(fetchCrmObjectTypesFailed(resp));
    }
  };

  const error = err => dispatch(fetchCrmObjectTypesFailed(err));

  http.get(CRM_OBJECT_TYPES_URL).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchCrmObjectSchema = objectType => dispatch => {
  dispatch({
    type: actionTypes.FETCH_CRM_OBJECT_SCHEMA_STARTED
  });

  const success = resp => {
    if (resp) {
      dispatch(fetchCrmObjectSchemaSucceeded(resp));
    } else {
      dispatch(fetchCrmObjectSchemaFailed(resp));
    }
  };

  const error = err => dispatch(fetchCrmObjectSchemaFailed(err));

  http.get(`${CRM_OBJECT_SCHEMA_URL}/${objectType}`).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchPageStylesheetInfo = contentId => dispatch => {
  dispatch({
    type: actionTypes.FETCH_PAGE_STYLESHEET_INFO_STARTED
  });

  const success = resp => {
    if (resp) {
      dispatch(fetchPageStylesheetInfoSucceeded(resp));
    } else {
      dispatch(fetchPageStylesheetInfoFailed(resp));
    }
  };

  const error = resp => dispatch(fetchPageStylesheetInfoFailed(resp));

  http.get(`${STYLESHEETS_URL_START}/${contentId}/${STYLESHEETS_URL_END}`).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchAvailablePageStylesheets = () => dispatch => {
  dispatch({
    type: actionTypes.FETCH_AVAILABLE_PAGE_STYLESHEETS_STARTED
  });

  const success = resp => {
    if (resp) {
      dispatch(fetchAvailablePageStylesheetsSucceeded(resp));
    } else {
      dispatch(fetchAvailablePageStylesheetsFailed(resp));
    }
  };

  const error = resp => dispatch(fetchAvailablePageStylesheetsFailed(resp));

  http.get(AVAILABLE_STYLESHET_URL).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchContactLists = search => dispatch => {
  dispatch({
    type: actionTypes.FETCH_CONTACT_LISTS_STARTED
  });

  const success = resp => {
    if (resp) {
      dispatch(fetchContactListsSucceeded(resp));
    } else {
      dispatch(fetchContactListsFailed(resp));
    }
  };

  const error = resp => dispatch(fetchContactListsFailed(resp));

  ContactsApi.fetchLists(search).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchContactListsForContent = listIds => dispatch => {
  dispatch({
    type: actionTypes.FETCH_CONTACT_LISTS_FOR_CONTENT_STARTED
  });

  const success = resp => {
    if (resp) {
      dispatch(fetchContactListsForContentSucceeded(resp));
    } else {
      dispatch(fetchContactListsForContentFailed(resp));
    }
  };

  const error = resp => dispatch(fetchContactListsForContentFailed(resp));

  ContactsApi.fetchSpecificLists(listIds).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchContactListRegistrations = listIds => dispatch => {
  dispatch({
    type: actionTypes.FETCH_CONTACT_LIST_REGISTRATIONS_STARTED
  });

  const success = resp => dispatch(fetchContactListRegistrationsSucceeded(resp));

  const error = resp => dispatch(fetchContactListRegistrationsFailed(resp));

  ListRegistrationsAPI.fetchListRegistrations(listIds).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchFeaturedImageThumbnailUrls = imageUrls => dispatch => {
  dispatch({
    type: actionTypes.FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_STARTED
  });

  const success = resp => {
    if (resp) {
      dispatch(fetchFeaturedImageThumbnailUrlsSucceeded(resp));
    } else {
      dispatch(fetchFeaturedImageThumbnailUrlsFailed(resp));
    }
  };

  const error = resp => dispatch(fetchFeaturedImageThumbnailUrlsFailed(resp));

  FileManagerApi.fetchImageThumbnailUrls(imageUrls).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};