import testQuota from './utils/quota';
import { NULL_REPR, NAMESPACE_SUFFIX } from './constants';
import getStoreName from './utils/getStoreName';
import { getRecordSizesAsync } from './utils/getRecordSizes';
import getRecordSizeDebugString from './utils/getRecordSizeDebugString';
import getNaiveObjectSize from './utils/getNaiveObjectSize';
/**
 * Several errors are possible when using IndexedDB.
 * This is a non-exhaustive list of errors that we specifically throw.
 */

const POSSIBLE_ERRORS = Object.freeze({
  alreadyOpen: 'A backend connection is already open. To open another connection, create a new Superstore instance.',
  asyncOnly: 'The IndexedDB backend only supports asynchronous calls.',
  blocked: "Superstore can't create a store because open connections are blocking it." + "Make sure you're closing your store connections with `.close()`.",
  browserPrivacy: 'HubSpot superstore library: indexedDB.open failed. ' + 'Common reasons: browser security settings, Firefox Private windows. ' + 'Original error: ',
  needBackend: 'No backend connection is open. Open a new database or connect to an existing one using .open()',
  noEvent: 'the wrapped call did not return an event',
  unsupported: 'IndexedDB is not supported in this environment.',
  unknown: 'Unknown backend error'
});

const isNullRepr = val => {
  try {
    if (typeof val === 'object') {
      const reprKeys = Object.keys(NULL_REPR);
      const valKeys = Object.keys(val);

      if (reprKeys.length === valKeys.length) {
        return reprKeys.every(key => {
          const foundKey = valKeys[valKeys.indexOf(key)];
          return NULL_REPR[key] === val[foundKey];
        });
      }
    }

    return false;
  } catch (e) {
    return false;
  }
};

const unsupported = new Error(POSSIBLE_ERRORS.unsupported);
const defaultHandlers = {
  error: [({
    error
  }, {
    reject
  }) => reject(new Error(error && error.message || POSSIBLE_ERRORS.unknown))],
  success: [({
    result
  }, {
    resolve
  }) => resolve(result)]
};
/**
 * Wrap an IndexedDB operation in a Promise. Use this if you plan
 * on building custom things, e.g. with indexes or cursors.
 *
 * @param klass - IndexedDB API where the operation you want to wrap is declared (e.g., IDBObjectStore)
 * @param method - name of the operation to be wrapped in a Promise, as a string
 * @param args - array of the arguments you will pass to this API
 * @param handlers - event handlers, especially for errors that may throw.
 * @param externalRequest – IndexedDBTransaction to make.
 * @param retry – true if we should retry this upon error.
 */

export const wrapCall = (klass, method, args = [], handlers = defaultHandlers, externalRequest, retry = true) => {
  return new Promise((resolve, reject) => {
    try {
      let request = klass[method](...args);

      if (!request && externalRequest) {
        request = externalRequest;
      } else if (!request) {
        reject(new Error(POSSIBLE_ERRORS.noEvent));
      }

      Object.keys(handlers).forEach(eventName => {
        const [eventHandler, options] = handlers[eventName];
        request.addEventListener(eventName, () => eventHandler(request, {
          resolve,
          reject
        }), Object.assign({
          once: true
        }, options));
      });
    } catch (e) {
      if (retry && klass instanceof IDBObjectStore && e.name === 'TransactionInactiveError') {
        // IE11 likes to render transactions inactive after every single operation.
        // Try creating a new transaction and running the operation again.
        const {
          mode,
          db
        } = klass.transaction;
        const transaction = db.transaction(klass.name, mode);
        const store = transaction.objectStore(klass.name);
        wrapCall(store, method, args, handlers, externalRequest, false).then(resolve).catch(reject);
      } else {
        reject(e);
      }
    }
  });
};

const commit = transaction => {
  if (transaction && transaction.commit && typeof transaction.commit === 'function') {
    return wrapCall(transaction, 'commit', [], Object.assign({}, defaultHandlers, {
      complete: [({
        result
      }, {
        resolve
      }) => resolve(result)]
    }), transaction);
  } else {
    // IDBTransaction.commit() is only implemented in browsers supporting IndexedDB 3.0
    // Other browsers will just autocommit.
    return Promise.resolve();
  }
};
/**
 * Superstore backend which uses the browser's IndexedDB API. Compared to the LocalStorage backend,
 * IndexedDB can store more kinds of keys and values, is more durable, offers higher performance and more storage.
 *
 * IndexedDB is supported in all HubSpot supported browsers.
 *
 * This backend only supports asynchronous calls. Make sure to catch promise rejections.
 *
 * To create a Superstore instance using this backend:
 *
 * ```js
 * import Superstore, { IndexedDB } from 'superstore';
 *
 * const conn = new Superstore({
 *   backend: IndexedDB,
 *   namespace: 'example',
 * });
 * conn.open().then(store => {
 *   store.set('foo', bar)
 * });
 * ```
 *
 */


export class SuperstoreIDB {
  /** @hidden */

  /** @hidden */

  /** @hidden */
  constructor(opts) {
    if (!opts.async) {
      throw new Error(POSSIBLE_ERRORS.asyncOnly);
    }

    this._database = undefined;
    this._objectStoreName = NAMESPACE_SUFFIX;
    this._dbName = getStoreName(opts);
  }
  /**
   * Must be called before any operations on the database.
   * @hidden
   */


  _checkInit() {
    if (!this._database) {
      return Promise.reject(new Error(POSSIBLE_ERRORS.needBackend));
    }

    return Promise.resolve();
  }
  /**
   * Open a connection to an IndexedDB database and create a store inside it.
   * To ensure the best performance, re-use open connections rather than calling this method repeatedly.
   *
   * ```js
   * new Superstore({ backend: IndexedDB })).open().then(store => {
   *   // store is ready for operations
   * });
   * ```
   */


  open() {
    if (this._database) {
      return Promise.reject(new Error(POSSIBLE_ERRORS.alreadyOpen));
    }

    try {
      // In Firefox, if cookies are disabled, just evaluating this expression throws a SecurityError.
      // In private mode, if cookies are enabled, it evaluates to null.
      if (!window.indexedDB) {
        return Promise.reject(unsupported);
      }
    } catch (_) {
      return Promise.reject(unsupported);
    }

    const openWithVersion = (version = 1) => {
      return wrapCall(window.indexedDB, 'open', [this._dbName, version], Object.assign({}, defaultHandlers, {
        upgradeneeded: [({
          result,
          transaction
        }, {
          resolve
        }) => {
          if (!result.objectStoreNames.contains(this._objectStoreName)) {
            result.createObjectStore(this._objectStoreName);
          }

          transaction.addEventListener('complete', () => resolve(result), {
            once: true
          });
        }],
        blocked: [(_, {
          reject
        }) => reject(new Error(POSSIBLE_ERRORS.blocked))],
        error: [({
          error
        }, {
          reject
        }) => reject(new Error(`${POSSIBLE_ERRORS.browserPrivacy}${error}`))]
      }));
    };

    return openWithVersion().then(db => {
      if (!db.objectStoreNames.contains(this._objectStoreName)) {
        db.close(); // the current version doesn't have stores we need.
        // trigger a versionChange event and create a new object store.
        // (object store creation only works inside a versionchange transaction)
        // @TODO: if version > Number.MAX_SAFE_INTEGER, we're going to have to nuke the db

        return openWithVersion(db.version + 1).then(incrementedDB => {
          this._database = incrementedDB;
          return this;
        });
      } else {
        this._database = db;
        return this;
      }
    });
  }
  /**
   * Close the connection to the database. Data is not deleted.
   *
   * ```js
   * const conn = new Superstore({ backend: IndexedDB });
   * conn.open()
   *   .then(store => {
   *     // use the store
   *   })
   *   .then(() => conn.close())
   * ```
   */


  close() {
    return this._checkInit().then(() => {
      this._database.close(); // yes, this is sync and void


      delete this._database; // don't worry, this doesn't actually delete the database, just the connection
    });
  }
  /**
   * Determine if there is a record in the store stored with `key`.
   * If `key` points to a value of undefined, the result is still true.
   *
   * ```js
   * store.has('key').then(keyExists => {
   *   // use the boolean `keyExists` value
   * });
   * ```
   */


  has(key) {
    return this._checkInit().then(() => this._database.transaction(this._objectStoreName, 'readonly')).then(transaction => {
      const store = transaction.objectStore(this._objectStoreName);
      return wrapCall(store, 'openCursor', [key]).then(cursor => {
        return commit(transaction).then(() => !!cursor);
      });
    });
  }
  /**
   * Get the value in the store located by `key`.
   * If `key` doesn't point to anything, returns undefined.
   *
   * ```js
   * store.get('key').then(value => {
   *   // `value` will be whatever was stored addressed by `key`, or undefined
   * });
   * ```
   */


  get(key) {
    return this._checkInit().then(() => this._database.transaction(this._objectStoreName, 'readonly')).then(transaction => {
      const store = transaction.objectStore(this._objectStoreName);
      return wrapCall(store, 'get', [key]).then(value => {
        return commit(transaction).then(() => isNullRepr(value) ? null : value);
      });
    });
  }
  /**
   * Gets all keys set in the store.
   * Optionally, takes an argument `query` with an IDBKeyRange that can be used to filter returned keys.
   * Optionally, takes an argument `count` that limits the results to a maximum of `count` items.
   * If no keys are set (in the specified range) or the count is 0, returns an empty array.
   *
   * ```js
   * store.getAllKeys().then(keys => {
   *   // `keys` will be an array containing all keys in the store
   * });
   * ```
   */


  getAllKeys(query, count) {
    return this._checkInit().then(() => this._database.transaction(this._objectStoreName, 'readonly')).then(transaction => {
      const store = transaction.objectStore(this._objectStoreName);
      const idbArgs = [];

      if (typeof query !== 'undefined') {
        idbArgs.push(query);

        if (typeof count !== 'undefined') {
          idbArgs.push(count);
        }
      }

      return wrapCall(store, 'getAllKeys', idbArgs).then(keys => {
        return commit(transaction).then(() => keys);
      });
    });
  }
  /**
   * Run `callback` on every record in the store.
   * @param callback
   * @returns
   */


  getAllRecords(callback) {
    return this._checkInit().then(() => this._database.transaction(this._objectStoreName, 'readonly')).then(transaction => {
      const store = transaction.objectStore(this._objectStoreName);
      let recordCount = 0;

      const onSuccess = ({
        result
      }, {
        resolve
      }) => {
        if (result === null) {
          resolve(recordCount);
        } else {
          callback(result.key, result.value);
          recordCount++;
          result.continue();
        }
      };

      return wrapCall(store, 'openCursor', [], Object.assign({}, defaultHandlers, {
        success: [onSuccess, {
          once: false
        }]
      }));
    });
  }
  /**
   * Set `value` in the store located by `key`.
   * Anything that works with [the structured clone algorithm](https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm) can be stored.
   * If `key` already points to a value in this store, it will be overwritten with `value`.
   * Resolves with the set value.
   *
   * ```js
   * store.set('key', value).then(() => {
   *   // resolves with `value`, if you need it
   * });
   * ```
   */


  set(key, value) {
    // IE and Edge can't store null as an indexeddb value. Force the value to something else before storing.
    if (value === null) {
      value = NULL_REPR;
    }

    return this._checkInit().then(testQuota).then(() => this._database.transaction(this._objectStoreName, 'readwrite')).then(transaction => {
      const store = transaction.objectStore(this._objectStoreName);
      return wrapCall(store, 'openCursor', [key]).then(cursor => !!cursor).then(hasKey => {
        const addOrPut = hasKey ? 'put' : 'add';
        return wrapCall(store, addOrPut, [value, key]).then(() => {
          return commit(transaction).then(() => value);
        });
      }).catch(err => {
        return getRecordSizesAsync(this.getAllRecords.bind(this)).then(recordSizes => {
          const recordSizesLog = getRecordSizeDebugString(recordSizes);
          return Promise.reject(new Error(`Encountered error: ${err} while setting ${key}:(${getNaiveObjectSize(value)}b), ${recordSizesLog}`));
        });
      });
    });
  }
  /**
   * Delete the pair in the store located by `key`.
   * The method resolves regardless of whether `key` pointed to anything, as long as no errors were raised.
   * Resolves the deleted value, or undefined if nothing was deleted
   *
   * ```js
   * store.delete('key').then(value => {
   *   // `value` will be a structured clone of the second argument to store.set('key', value)
   * });
   * ```
   */


  delete(key) {
    return this._checkInit().then(() => this._database.transaction(this._objectStoreName, 'readwrite')).then(transaction => {
      const store = transaction.objectStore(this._objectStoreName);
      return wrapCall(store, 'get', [key]).then(value => {
        return wrapCall(store, 'delete', [key]).then(() => {
          return commit(transaction).then(() => value);
        });
      });
    });
  }
  /**
   * Remove all keys, values and indexes from the store.
   * Does not destroy the store. An IDB database and object store will remain in place.
   *
   * ```js
   * store
   *   .set('foo', 'bar')
   *   .then(() => store.clear())
   *   .then(() => store.has('foo'))
   *   .then(fooExists => {
   *      // fooExists === false
   *    });
   * ```
   */


  clear() {
    return this._checkInit().then(() => this._database.transaction(this._objectStoreName, 'readwrite')).then(transaction => {
      const store = transaction.objectStore(this._objectStoreName);
      return wrapCall(store, 'clear').then(() => commit(transaction)).then();
    });
  }

}
export default function (opts) {
  return new SuperstoreIDB(opts);
}