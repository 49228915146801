'use es6';

import { connect } from 'react-redux';
import { getSaveState } from 'ContentEditorUI/redux/selectors/saveDataSelectors';
import { getLastSaveDate } from 'ContentEditorUI/redux/selectors/editingInfoSelectors';
import { getHasPermissionsToSave } from 'page-editor-ui/redux/selectors/saveSelectors';
import { routeToPage } from 'ContentEditorUI/redux/actions/routingActions';
import BaymaxSaveLabel from 'ContentEditorUI/components/shared/BaymaxSaveLabel';

const mapStateToProps = state => {
  return {
    allowedSave: getHasPermissionsToSave(state),
    lastSave: getLastSaveDate(state),
    saveState: getSaveState(state)
  };
};

export default connect(mapStateToProps, {
  routeToPage
})(BaymaxSaveLabel);