import http from 'hub-http/clients/apiClient';
const CONFIG_BASE_URL = 'product-approvals-configuration/v1';
const BASE_URL = 'product-approvals/v1';
export default {
  fetchProductApprovalsConfig: ({
    approvalType,
    approvalAction
  }) => http.get(`${CONFIG_BASE_URL}/approval-configurations/configuration/${approvalType}/${approvalAction}`),
  fetchApprovers: (approvalType, approvalAction) => http.get(`${CONFIG_BASE_URL}/approval-configurations/approvers`, {
    query: {
      approvalType,
      approvalAction
    }
  }),
  fetchLatestApproval: query => http.get(`${BASE_URL}/approvals/latest`, {
    query
  }),

  fetchSkipConfig(approvalType, approvalAction) {
    return http.get(`${CONFIG_BASE_URL}/approval-configurations/configuration/${approvalType}/${approvalAction}/skip`);
  },

  createApproval: data => http.post(`${BASE_URL}/approvals`, {
    data
  }),
  approveApproval: (approval, note) => http.put(`${BASE_URL}/approvals/${approval.id}/approve`, {
    data: {
      note
    }
  }),
  cancelApproval: (approval, note) => http.put(`${BASE_URL}/approvals/${approval.id}/cancel`, {
    data: {
      note
    }
  }),
  rejectApproval: (approval, rejectionNote) => http.put(`${BASE_URL}/approvals/${approval.id}/reject`, {
    data: {
      rejectionNote
    }
  }),
  updateApproval: (approval, approverOwnerIds) => http.put(`${BASE_URL}/approvals/${approval.id}/update`, {
    data: {
      approverOwnerIds
    }
  }),
  requestAccess: (approvalType, approvalAction, adminToNotifyUserId, message) => http.post(`${CONFIG_BASE_URL}/approval-configurations/request-enabling-approvals`, {
    data: {
      approvalType,
      approvalAction,
      message,
      adminToNotifyUserId
    }
  })
};