import { findDOMNode } from 'react-dom';
import http from 'hub-http/clients/apiClient';
import { parse, stringify } from 'hub-http/helpers/params';
import { getFullUrl } from 'hubspot-url-utils';
import I18n from 'I18n';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import PortalIdParser from 'PortalIdParser';
import Raven from 'raven-js';
import { i18nKeysByApiName, i18nKeysByUpgradeProduct } from 'ui-addon-upgrades/_core/common/adapters/getProductNameText';
import { sourceKeys, sources } from 'ui-addon-upgrades/_core/common/data/upgradeData/properties/sources';
import { getIframeModalHost } from '../_core/utils/getIframeModalHost';
import { getOwnedProducts } from 'self-service-api/api/getProducts';
import { submitInteraction } from 'self-service-api/api/submitInteraction'; // @ts-expect-error dependency missing types

import UpgradeProductToApiNameMap from 'self-service-api/constants/UpgradeProductToApiNameMap';
import { trackFailedApiNameMap } from '../_core/utils/metricUtils';
import { UPGRADE_BANNER_TYPES } from './types';
export let UpgradePointStatus;

(function (UpgradePointStatus) {
  UpgradePointStatus["STALE"] = "STALE";
  UpgradePointStatus["ACTIVE"] = "ACTIVE";
  UpgradePointStatus["ARCHIVED"] = "ARCHIVED";
  UpgradePointStatus["PLANNED"] = "PLANNED";
})(UpgradePointStatus || (UpgradePointStatus = {}));

const upgradePointPromises = {}; // viewedUpgradePointsCache is a module cache that stores whether a user has
// viewed an upgrade point, via a unique upgradeKey. This allows to us to
// prevent usage trackers from firing more than once per app session.

export const viewedUpgradePointsCache = {};
export const updateViewedUpgradePointsCache = (upgradeKey, onCacheUpdate) => {
  if (!viewedUpgradePointsCache[upgradeKey]) {
    viewedUpgradePointsCache[upgradeKey] = true;
    if (onCacheUpdate) onCacheUpdate();
  }
};
export function getApp() {
  return window.location.pathname.split('/')[1];
}
export function getScreen() {
  return window.location.pathname;
}
export function getHubAndTier(upgradeProduct) {
  return {
    hub: upgradeProduct.split('-')[0],
    tier: upgradeProduct.split('-')[1]
  };
}
export function getHubAndTierFromApiName(apiName) {
  const hub = apiName.split('-')[0];
  return {
    hub: hub === 'crm' ? 'suite' : hub,
    tier: apiName.split('-')[2]
  };
}
export function verifyMessage(ref, event) {
  const node = findDOMNode(ref.current);
  return node && node.contentWindow === event.source;
}
export function matchMessage(event, message) {
  return event.data === message;
}
export function createFrameSrc({
  upgradeKey,
  app,
  screen,
  portalId,
  params
}) {
  const formattedParams = params ? `&${params}` : '';
  return `${getIframeModalHost()}/ums-modal/${portalId}/${upgradeKey}?app=${app}&screen=${screen}${formattedParams}`;
}
export function getFrameSrc(upgradeKey) {
  return createFrameSrc({
    upgradeKey,
    app: getApp(),
    screen: getScreen(),
    portalId: PortalIdParser.get(),
    params: stringify(parse(window.location.search.substring(1)))
  });
}

function fetchUpgradePoint(upgradeKey) {
  if (!upgradeKey) {
    return Promise.resolve();
  }

  return http.get(`${getFullUrl('app-api', {})}/upgrade-management-service/v1/upgrade-configs/${upgradeKey}`).catch(error => {
    // 0 indicates user aborted requests, which we don't want to log as errors
    if (error && error.status !== 0) {
      const message = error.status === 404 ? `upgradeKey ${upgradeKey} may not exist in the UMS.` : 'UpgradeManagement API Failure';
      Raven.captureException(error, {
        extra: {
          message
        }
      });
    }

    throw error;
  });
} // We delay the the api call to give performance priority to the consuming app.
// This originally was done so bc we only used the response for tracking.
// However, now we use the response to determine if a Personalized Pricing Page redirect should occur.
// Reduced this from 5000 to 2000 because we need this response to determine the Personalized Pricing Page redirect. May reduce more.


export function delayUntilIdle(callback) {
  window.setTimeout(callback, 2000);
}
export function getOwnedProductsPromise({
  delayCall = true
}) {
  const ownedProductsPromise = new Promise(resolve => {
    if (delayCall) {
      delayUntilIdle(() => {
        resolve(getOwnedProducts());
      });
    } else {
      resolve(getOwnedProducts());
    }
  });
  return ownedProductsPromise;
}
export function getUpgradePointPromise({
  upgradeKey,
  delayCall = true //modals aren't needed right away so delay loading them to improve page performance

}) {
  if (upgradePointPromises[upgradeKey]) {
    return upgradePointPromises[upgradeKey];
  }

  const upgradePointPromise = new Promise(resolve => {
    if (delayCall) {
      delayUntilIdle(() => {
        resolve(fetchUpgradePoint(upgradeKey));
      });
    } else {
      resolve(fetchUpgradePoint(upgradeKey));
    }
  });
  upgradePointPromises[upgradeKey] = upgradePointPromise;
  return upgradePointPromise;
}
export const getRepName = (repInfo = {}) => {
  if (repInfo && repInfo.name) {
    return repInfo.name;
  }

  return 'Not assigned';
};

const getApiName = (upgradeData, canViewNewSeats = false) => {
  // this is set upstream and we blindly trust it was done correctly
  if (upgradeData.apiName) {
    return upgradeData.apiName;
  }

  if (canViewNewSeats) {
    return upgradeData.apiNames && upgradeData.apiNames[0] || UpgradeProductToApiNameMap[upgradeData.upgradeProduct];
  }

  return undefined;
};

export function getUpgradeDataTrackingProperties(upgradeData, isUpgradePoint = false, isUpgradePointResponse = false, canViewNewSeats = false) {
  const {
    upgradeProduct,
    upgradeProducts,
    apiNames
  } = upgradeData;
  const apiNameToUse = getApiName(upgradeData, canViewNewSeats);

  const getHubAndTierValues = () => {
    if (!canViewNewSeats) {
      return getHubAndTier(isUpgradePointResponse && upgradeProducts ? upgradeProducts[0] : upgradeProduct);
    } else {
      return getHubAndTierFromApiName(isUpgradePointResponse && apiNames ? apiNames[0] : apiNameToUse);
    }
  };

  const {
    hub,
    tier
  } = getHubAndTierValues();
  const trackingProperties = {
    app: upgradeData.app || getApp(),
    screen: upgradeData.screen || getScreen(),
    hub,
    tier,
    upgradeProduct,
    apiName: apiNameToUse
  };

  if (isUpgradePoint) {
    Object.assign(trackingProperties, {
      feature: upgradeData.feature,
      upgradeKey: upgradeData.upgradeKey,
      pointType: upgradeData.pointType,
      status: upgradeData.status,
      primaryColor: upgradeData.primaryColor,
      team: upgradeData.team,
      title: upgradeData.title,
      subtitle: upgradeData.subtitle
    });
  }

  if (!isUpgradePointResponse) {
    Object.assign(trackingProperties, {
      uniqueId: upgradeData.uniqueId,
      source: upgradeData.source,
      upgradeSource: upgradeData.upgradeSource
    });
  }

  return trackingProperties;
}
export const getSource = (app, screen, uniqueId) => `${app}-${screen}-${sources[sourceKeys.GENERAL]}-${uniqueId}`;
export const getBannerI18nTitleKey = (titleI18nPrefix, featureTranslationKey) => {
  if (isValidI18nKey(`${titleI18nPrefix}.${featureTranslationKey}`)) {
    return `${titleI18nPrefix}.${featureTranslationKey}`;
  }

  return `${titleI18nPrefix}.placeholder`;
}; // remove branding banners have static subtext and don't call this function

export const getBannerI18nSubTitleKey = (subtitleI18nPrefix, featureTranslationKey) => {
  if (isValidI18nKey(`${subtitleI18nPrefix}.${featureTranslationKey}`)) {
    return `${subtitleI18nPrefix}.${featureTranslationKey}`;
  }

  return `${subtitleI18nPrefix}.placeholder`;
};
export const getProductI18nName = (subtitleI18nPrefix, upgradeProduct) => {
  const keyByProduct = i18nKeysByUpgradeProduct[upgradeProduct];

  if (keyByProduct && isValidI18nKey(`${subtitleI18nPrefix}.${keyByProduct}`)) {
    return I18n.text(`${subtitleI18nPrefix}.${keyByProduct}`);
  }

  return null;
};
export const getProductI18nNameFromApiName = (subtitleI18nPrefix, apiName) => {
  const keyByProduct = i18nKeysByApiName[apiName];

  if (keyByProduct && isValidI18nKey(`${subtitleI18nPrefix}.${keyByProduct}`)) {
    return I18n.text(`${subtitleI18nPrefix}.${keyByProduct}`);
  }

  return null;
};
export const warnArchivedUpgradePointUse = upgradeKey => {
  console.warn(`Upgrade point '${upgradeKey}' is archived, and should not be used in-app. If this upgrade point should not be archived, it can be restored through the UMS at https://tools.hubteamqa.com/ums/upgrade-points/${upgradeKey}.`);
};

const getStarterTierPurchaseMotionsData = purchaseMotionsObj => {
  const starterTierPurchaseMotionsData = {};
  Object.keys(purchaseMotionsObj).forEach(apiName => {
    const {
      tier
    } = getHubAndTierFromApiName(apiName);

    if (tier === 'starter') {
      const purchaseMotionsByApiName = purchaseMotionsObj[apiName];
      const purchaseMotions = purchaseMotionsByApiName && purchaseMotionsByApiName.map(purchaseMotionEntry => purchaseMotionEntry.key);
      starterTierPurchaseMotionsData[apiName] = purchaseMotions;
    }
  });
  return starterTierPurchaseMotionsData;
};

export const getSuiteStarterPurchaseMotions = (purchaseMotionsObj, fallbackPurchaseMotions) => {
  const starterTierPurchaseMotionData = getStarterTierPurchaseMotionsData(purchaseMotionsObj);
  const purchaseMotionValues = Object.values(starterTierPurchaseMotionData);
  const ownsSuiteStarter = purchaseMotionValues.every(purchaseMotions => purchaseMotions[0] === purchaseMotionValues[0][0]);

  if (ownsSuiteStarter) {
    return purchaseMotionValues[0];
  } else {
    return fallbackPurchaseMotions;
  }
};
export const mapPurchaseMotionApiName = (canViewNewSeats = false, upgradeProduct, source, apiName) => {
  if (canViewNewSeats && !apiName) {
    if (UpgradeProductToApiNameMap[upgradeProduct]) {
      return UpgradeProductToApiNameMap[upgradeProduct];
    } else {
      trackFailedApiNameMap(source, 'no api name', upgradeProduct);
      Raven.captureMessage(`failed to map upgrade product to apiName ${apiName} in ${source}`);
    }
  }

  return apiName;
};
export const trackPotentialBannerInteraction = (pointType, upgradeKey) => {
  if (pointType && upgradeKey && Object.keys(UPGRADE_BANNER_TYPES).includes(pointType)) {
    return submitInteraction(upgradeKey);
  }

  return Promise.resolve();
};
export function validateCommonUpgradeConfig(upgradeConfig) {
  if ((!upgradeConfig.apiNames || upgradeConfig.apiNames.length === 0) && (!upgradeConfig.upgradeProducts || upgradeConfig.upgradeProducts.length === 0)) {
    throw Error('Expected either upgradeProducts or apiNames to be defined');
  }
}
export function validateBannerUpgradeConfig(upgradeConfig) {
  validateCommonUpgradeConfig(upgradeConfig);

  if (!upgradeConfig.featureTranslationKey) {
    throw Error(`Expected featureTranslationKey to be defined for ${upgradeConfig.upgradeKey}`);
  }
}
export function validateModalUpgradeConfig(upgradeConfig) {
  validateCommonUpgradeConfig(upgradeConfig);

  if (!upgradeConfig.title) {
    throw Error('Expected title to be defined');
  }

  if (!upgradeConfig.subtitle) {
    throw Error('Expected subtitle to be defined');
  }

  if (!upgradeConfig.visualType) {
    throw Error('Expected visualType to be defined');
  }

  if (!upgradeConfig.visualKey) {
    throw Error('Expected visualKey to be defined');
  }
}
/**
 * Unit and integration testing only
 */

function resetUpgradePointCaches() {
  Object.keys(upgradePointPromises).forEach(upgradeKey => {
    delete upgradePointPromises[upgradeKey];
  });
  Object.keys(viewedUpgradePointsCache).forEach(upgradeKey => {
    delete viewedUpgradePointsCache[upgradeKey];
  });
}

export const __TEST_UTILS__ = {
  resetUpgradePointCaches
};