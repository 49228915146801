/* hs-eslint ignored failing-rules */

/* eslint-disable no-empty */
'use es6';

let debug = false;

try {
  debug = !!localStorage.getItem('INTERFRAME_DEBUG');
} catch (e) {}

export function isDebug() {
  return debug;
}

function log(arg, ...rest) {
  let prefix = '[Interframe]';

  if (typeof arg === 'string') {
    prefix = `${prefix} ${arg}`;
  }

  console.log(prefix, ...rest);
}

export function getLogger() {
  if (isDebug()) {
    return log;
  }

  return () => {};
}