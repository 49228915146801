module.exports = {
  "pageLoad": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": [
        "site-page-editor",
        "landing-page-editor",
        "blog-listing-page-editor"
      ],
      "subscreen": "string",
      "themePath": {
        "type": "string",
        "isOptional": true
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "publishOnConnectedCustomDomainActivation": {
    "name": "publish-on-connected-custom-domain",
    "class": "activation",
    "properties": {
      "skipPublishRedirect": {
        "type": "string",
        "isOptional": true
      },
      "dynamicPagesSource": {
        "type": "string",
        "isOptional": true
      },
      "isDragAndDropPage": {
        "type": "string",
        "isOptional": true
      },
      "isSuccessful": {
        "type": "string",
        "isOptional": true
      },
      "publishType": {
        "type": "string",
        "isOptional": true
      },
      "isSchedulingUpdate": {
        "type": "string",
        "isOptional": true
      },
      "dynamicPagesSourceValue": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "publishOnConnectedCustomDomainUsage": {
    "name": "publish-on-connected-custom-domain",
    "class": "usage",
    "properties": {
      "skipPublishRedirect": {
        "type": "string",
        "isOptional": true
      },
      "dynamicPagesSource": {
        "type": "string",
        "isOptional": true
      },
      "isDragAndDropPage": {
        "type": "string",
        "isOptional": true
      },
      "isSuccessful": {
        "type": "string",
        "isOptional": true
      },
      "publishType": {
        "type": "string",
        "isOptional": true
      },
      "isSchedulingUpdate": {
        "type": "string",
        "isOptional": true
      },
      "dynamicPagesSourceValue": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "cosModuleInteraction": {
    "name": "cos module interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "cosModuleActivation": {
    "name": "cos module interaction",
    "class": "activation",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "cosModuleUsage": {
    "name": "cos module interaction",
    "class": "usage",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "partnerMatchingApp": {
    "name": "Partner Matching",
    "class": "interaction",
    "namespace": "page-publishing",
    "properties": {
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "publishActivation": {
    "name": "publish",
    "class": "activation",
    "properties": {
      "skipPublishRedirect": {
        "type": "string",
        "isOptional": true
      },
      "dynamicPagesSource": {
        "type": "string",
        "isOptional": true
      },
      "isDragAndDropPage": {
        "type": "string",
        "isOptional": true
      },
      "isSuccessful": {
        "type": "string",
        "isOptional": true
      },
      "publishType": {
        "type": "string",
        "isOptional": true
      },
      "isSchedulingUpdate": {
        "type": "string",
        "isOptional": true
      },
      "dynamicPagesSourceValue": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "publishUsage": {
    "name": "publish",
    "class": "usage",
    "properties": {
      "skipPublishRedirect": {
        "type": "string",
        "isOptional": true
      },
      "dynamicPagesSource": {
        "type": "string",
        "isOptional": true
      },
      "isDragAndDropPage": {
        "type": "string",
        "isOptional": true
      },
      "isSuccessful": {
        "type": "string",
        "isOptional": true
      },
      "publishType": {
        "type": "string",
        "isOptional": true
      },
      "isSchedulingUpdate": {
        "type": "string",
        "isOptional": true
      },
      "dynamicPagesSourceValue": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "scheduleUpdateActivation": {
    "name": "schedule update",
    "class": "activation",
    "properties": {
      "skipPublishRedirect": {
        "type": "string",
        "isOptional": true
      },
      "publishType": {
        "type": "string",
        "isOptional": true
      },
      "isSchedulingUpdate": {
        "type": "string",
        "isOptional": true
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "scheduleUpdateUsage": {
    "name": "schedule update",
    "class": "usage",
    "properties": {
      "skipPublishRedirect": {
        "type": "string",
        "isOptional": true
      },
      "publishType": {
        "type": "string",
        "isOptional": true
      },
      "isSchedulingUpdate": {
        "type": "string",
        "isOptional": true
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "cancelPublish": {
    "name": "cancel scheduled publish",
    "class": "usage",
    "properties": {
      "pageType": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "cancelUpdate": {
    "name": "cancel scheduled update",
    "class": "usage",
    "properties": {
      "pageType": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "scheduleUpdateOnboardingAcknowledged": {
    "name": "scheduled update onboarding acknowledged",
    "class": "interaction",
    "properties": {
      "pageType": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "addWebsitePageTitleActivation": {
    "name": "add website page title",
    "class": "activation",
    "properties": {
      "pageType": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "editorViewInteraction": {
    "name": "editor interaction",
    "class": "view",
    "properties": {
      "namespace": {
        "type": "string",
        "isOptional": true
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "content-editor-ui"
  },
  "editorInteraction": {
    "name": "editor interaction",
    "class": "interaction",
    "properties": {
      "isPositiveFeedback": {
        "type": "boolean",
        "isOptional": true
      },
      "feedbackText": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string"
      },
      "event": {
        "type": "string"
      },
      "moduleType": {
        "type": "string",
        "isOptional": true
      },
      "moduleLabel": {
        "type": "string",
        "isOptional": true
      },
      "breakpointPreviewWidth": {
        "type": "string",
        "isOptional": true
      },
      "pageHasBreakpoints": {
        "type": "string",
        "isOptional": true
      },
      "nodetype": {
        "type": "string",
        "isOptional": true
      },
      "draggedItemType": {
        "type": "string",
        "isOptional": true
      },
      "insertType": {
        "type": "string",
        "isOptional": true
      },
      "namespace": {
        "type": "string",
        "isOptional": true
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "content-editor-ui"
  },
  "xrayPageView": {
    "name": "page view",
    "class": "view",
    "properties": {
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "pageType": "string"
    },
    "namespace": "xray-panel"
  },
  "sectionView": {
    "name": "section view",
    "class": "view",
    "properties": {
      "section": {
        "type": "string"
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": "string",
      "pageType": "string"
    },
    "namespace": "xray-panel"
  },
  "clickedFixLink": {
    "name": "xray-interaction",
    "class": "interaction",
    "properties": {
      "recommendationType": "string",
      "screen": "string",
      "subscreen": "string",
      "pageType": "string"
    },
    "namespace": "xray-panel"
  },
  "expandedRecommendationGroup": {
    "name": "xray-interaction",
    "class": "interaction",
    "properties": {
      "group": "string",
      "screen": "string",
      "subscreen": "string",
      "pageType": "string"
    },
    "namespace": "xray-panel"
  },
  "clickedLearnMoreLink": {
    "name": "xray-interaction",
    "class": "interaction",
    "properties": {
      "recommendationType": "string",
      "linkAddress": "string",
      "screen": "string",
      "subscreen": "string",
      "pageType": "string"
    },
    "namespace": "xray-panel"
  },
  "clickedCreateTopic": {
    "name": "xray-interaction",
    "class": "interaction",
    "namespace": "xray-panel",
    "properties": {
      "screen": "string",
      "subscreen": "string",
      "pageType": "string"
    }
  },
  "clickedAttachTopic": {
    "name": "xray-interaction",
    "class": "interaction",
    "namespace": "xray-panel",
    "properties": {
      "screen": "string",
      "subscreen": "string",
      "pageType": "string"
    }
  },
  "clickedDetachTopic": {
    "name": "xray-interaction",
    "class": "interaction",
    "namespace": "xray-panel",
    "properties": {
      "screen": "string",
      "subscreen": "string",
      "pageType": "string"
    }
  },
  "inlineEditInteraction": {
    "name": "inline edit interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "menu-open",
          "menu-selection",
          "tiny-mce-toolbar-menu-selection",
          "tone-selection",
          "click-generate",
          "click-try-again",
          "preview-window-rewrite",
          "insert-content",
          "preview-window-close",
          "preview-window-previous",
          "preview-window-next",
          "click-settings-link"
        ]
      },
      "actionType": {
        "type": [
          "slash-command",
          "selection-command"
        ]
      },
      "selected": {
        "isOptional": true,
        "type": "string"
      },
      "choice": {
        "isOptional": true,
        "type": [
          "friendly",
          "professional",
          "witty",
          "heartfelt",
          "educational"
        ]
      },
      "command": {
        "type": "string",
        "isOptional": true
      },
      "text": {
        "type": "string",
        "isOptional": true
      },
      "result": {
        "type": "string",
        "isOptional": true
      },
      "usageDescription": {
        "type": "string",
        "isOptional": true
      },
      "inline": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      },
      "regenerateClickCount": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "content-assistance-lib"
  },
  "contentGenerationModalInteraction": {
    "name": "content generation modal interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "open-modal",
          "close-modal",
          "click-generate",
          "click-generate-more",
          "click-trigger-button",
          "fetch-results",
          "choose-result",
          "video-started",
          "video-completed",
          "turn-on-content-assistant",
          "click-maybe-later",
          "click-settings-link",
          "click-go-back"
        ]
      },
      "usageDescription": {
        "type": "string"
      },
      "text": {
        "type": "string",
        "isOptional": true
      },
      "approximateTokenCount": {
        "type": "number",
        "isOptional": true
      },
      "results": {
        "type": "array",
        "isOptional": true
      },
      "completionTokens": {
        "type": "number",
        "isOptional": true
      },
      "promptTokens": {
        "type": "number",
        "isOptional": true
      },
      "result": {
        "type": "string",
        "isOptional": true
      },
      "videoId": {
        "type": "number",
        "isOptional": true
      },
      "command": {
        "type": "string",
        "isOptional": true
      },
      "choice": {
        "isOptional": true,
        "type": "string"
      },
      "regenerateClickCount": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "content-assistance-lib"
  },
  "blogQuickCreateModalOpened": {
    "name": "blog quick create modal action",
    "class": "interaction",
    "namespace": "content-components"
  },
  "blogQuickCreateModalClickedCreate": {
    "name": "blog quick create modal action",
    "class": "interaction",
    "namespace": "content-components"
  },
  "audienceAccessInteraction": {
    "name": "audience access interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "click-radio-all",
          "click-radio-password",
          "click-radio-sso",
          "click-radio-specificContacts",
          "click-radio-ssoWithSpecificContacts",
          "show-password-help",
          "show-sso-help",
          "show-specificContacts-help",
          "show-ssoWithSpecificContacts-help",
          "change-lists",
          "click-kb-article",
          "show-sso-disabled-tooltip",
          "show-registration-disabled-tooltip"
        ]
      }
    },
    "namespace": "content-components"
  },
  "partnerMatchingAppInteraction": {
    "name": "partner matching app interaction",
    "namespace": "partner-matching-app",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "app": {
        "type": "string"
      }
    }
  },
  "colorImportInteraction": {
    "name": "color import modal interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "submit-url",
          "website-colors-retrieved",
          "toggle-color-selection",
          "import-colors"
        ]
      }
    },
    "namespace": "content-components"
  },
  "setupCustomDomainModalInteraction": {
    "name": "setup custom domain modal interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "view",
          "clicked-add-custom-domain-button"
        ]
      },
      "screen": [
        "detail"
      ]
    },
    "namespace": "content-components"
  },
  "ContentComponentsInteraction": {
    "name": "ContentComponents interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "custom-fonts-upload-modal-opened",
          "custom-fonts-upload-modal-closed",
          "custom-fonts-create-font",
          "custom-fonts-edit-font",
          "custom-fonts-delete-font"
        ]
      }
    },
    "namespace": "content-components"
  },
  "contentCreateAutoTranslationActionUsage": {
    "name": "content-create-autotranslation-action",
    "class": "usage",
    "properties": {
      "action": [
        "create-autotranslation"
      ],
      "status": [
        "success",
        "fail"
      ],
      "screen": {
        "type": "string"
      },
      "subscreen": {
        "type": "string"
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "content-components"
  },
  "contentCreateAutoTranslationActionActivation": {
    "name": "content-create-autotranslation-action",
    "class": "activation",
    "properties": {
      "screen": {
        "type": "string"
      },
      "subscreen": {
        "type": "string"
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "content-components"
  },
  "componentInteraction": {
    "name": "component interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "app": "string"
    },
    "namespace": "editor-platform"
  },
  "tinymceEdit": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "method": {
        "type": "string",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceCommand": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "value": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymcePaste": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "type": [
        "html",
        "plaintext",
        "unknown"
      ],
      "pasteSource": {
        "type": "string",
        "isOptional": true
      },
      "hasImages": {
        "type": "boolean",
        "isOptional": true
      },
      "overwriteExisting": {
        "type": "boolean",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEvent": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEditSourceCode": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "method": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceChangeColor": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "method": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceColorPopoverMode": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "mode": {
        "type": "string"
      },
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceChangeColorPopover": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "mode": {
        "type": "string",
        "isOptional": true
      },
      "version": {
        "type": "string",
        "isOptional": true
      },
      "format": {
        "type": "string",
        "isOptional": true
      },
      "had-existing-color": {
        "type": "boolean",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceCta": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "context": {
        "type": "string",
        "isOptional": true
      },
      "editType": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceFormatting": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "method": {
        "type": "string",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "isCustomFont": {
        "type": "boolean",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceLink": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "nofollow": {
        "type": "boolean",
        "isOptional": true
      },
      "sponsored": {
        "type": "boolean",
        "isOptional": true
      },
      "ugc": {
        "type": "boolean",
        "isOptional": true
      },
      "externalLink": {
        "type": "boolean",
        "isOptional": true
      },
      "isImage": {
        "type": "boolean",
        "isOptional": true
      },
      "isIcon": {
        "type": "boolean",
        "isOptional": true
      },
      "linkType": {
        "type": "string",
        "isOptional": true
      },
      "isValidLink": {
        "type": "boolean",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceImage": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "method": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceContentGeneration": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEditImage": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "editType": {
        "type": "string",
        "isOptional": true
      },
      "fileSource": {
        "type": [
          "AI_GENERATED",
          "CANVA",
          "SHUTTERSTOCK"
        ],
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEmbed": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertEmbed": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "service": {
        "type": "string",
        "isOptional": true
      },
      "tab": [
        "general",
        "embed",
        "paste"
      ],
      "hasDimensions": {
        "type": "boolean",
        "isOptional": true
      },
      "useIframeEmbeds": {
        "type": "boolean",
        "isOptional": true
      },
      "hasScripts": {
        "type": "boolean",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEditEmbed": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "editType": {
        "type": "string",
        "isOptional": true
      },
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymcePersonalize": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "category": "string",
      "fqn": "string",
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertPersonalizationToken": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "tokenName": "string",
      "tokenCategory": {
        "type": "string",
        "isOptional": true
      },
      "isHubspotDefined": {
        "type": "boolean",
        "isOptional": true
      },
      "defaultValueType": [
        "global",
        "local",
        "empty"
      ],
      "fieldType": {
        "type": "string"
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEditPersonalizationToken": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "tokenName": "string",
      "tokenCategory": {
        "type": "string",
        "isOptional": true
      },
      "isHubspotDefined": {
        "type": "boolean",
        "isOptional": true
      },
      "defaultValueType": [
        "global",
        "local",
        "empty"
      ],
      "fieldType": {
        "type": "string"
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertRssToken": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "tokenName": "string",
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceVideo": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "type": "string",
      "method": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertVideo": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "type": "string",
      "replaced": {
        "type": "boolean",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEditVideo": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "editType": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertEmoji": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "insertCount": {
        "type": "number",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEmoji": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertSnippet": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceSnippet": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertTable": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "columns": {
        "type": "number",
        "isOptional": true
      },
      "rows": {
        "type": "number",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEditTable": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "command": {
        "type": "string",
        "isOptional": true
      },
      "editType": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceKeyboardEscape": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "format": "string",
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceAnchor": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "insertLocation": {
        "type": "string",
        "isOptional": true
      },
      "dragStage": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceVisualBlocks": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "active": "boolean",
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertCallout": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "type": {
        "type": "string"
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEditCallout": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "editType": {
        "type": "string"
      },
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceCharmap": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceFindReplace": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertGoogleDoc": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "useUrlInput": {
        "type": "boolean",
        "isOptional": true
      },
      "isPostBody": {
        "type": "boolean",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceAddNewGoogleAccount": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceCancelGoogleDoc": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "uiSource": {
        "type": "string",
        "isOptional": true
      },
      "errorMessage": {
        "type": "string",
        "isOptional": true
      },
      "useUrlInput": {
        "type": "boolean",
        "isOptional": true
      },
      "message": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEditIcon": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "aspectChanged": {
        "type": "string",
        "isOptional": true
      },
      "position": {
        "type": "string",
        "isOptional": true
      },
      "color": {
        "type": "string",
        "isOptional": true
      },
      "name": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "size": {
        "type": "string",
        "isOptional": true
      },
      "title": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "setName": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertIcon": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "setName": {
        "type": "string",
        "isOptional": true
      },
      "name": {
        "type": "string",
        "isOptional": true
      },
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "smartRulesSelectInteraction": {
    "name": "Smart Rule Selected",
    "class": "interaction",
    "version": "v2",
    "properties": {
      "smartType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "smart-content"
  },
  "smartContentCriterionInteraction": {
    "name": "Criterion Interaction",
    "class": "interaction",
    "version": "v2",
    "properties": {
      "name": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "targetType": {
        "type": "string",
        "isOptional": true
      },
      "moduleName": {
        "type": "string",
        "isOptional": true
      },
      "smartType": {
        "isOptional": true,
        "type": "string"
      }
    },
    "namespace": "smart-content"
  },
  "smartContentDefinitionActivation": {
    "name": "activation",
    "class": "activation",
    "properties": {
      "smartType": {
        "isOptional": true,
        "type": "string"
      }
    },
    "namespace": "smart-content"
  },
  "smartContentDefinitionInteraction": {
    "name": "Definition Interaction",
    "class": "interaction",
    "version": "v2",
    "properties": {
      "contentType": {
        "type": "string",
        "isOptional": true
      },
      "smartType": {
        "type": "string",
        "isOptional": true
      },
      "targetType": {
        "type": "string",
        "isOptional": true
      },
      "moduleName": {
        "type": "string",
        "isOptional": true
      },
      "name": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "smart-content"
  },
  "fileManagerLib": {
    "name": "File Manager Lib",
    "class": "usage",
    "properties": {
      "componentName": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerSetPanelExploreFiles": {
    "name": "Explore Files",
    "class": "interaction",
    "properties": {
      "panel": "string",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerExploreFiles": {
    "name": "Explore Files",
    "class": "interaction",
    "namespace": "file-picker",
    "version": "v2",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "fileManagerImageOptimization": {
    "name": "Change image optimization setting",
    "class": "interaction",
    "properties": {
      "setting": "string",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerManageFiles": {
    "name": "Manage Files",
    "class": "interaction",
    "properties": {
      "count": {
        "type": "number",
        "isOptional": true
      },
      "fileSource": {
        "type": [
          "AI_GENERATED",
          "CANVA",
          "SHUTTERSTOCK"
        ],
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerManageFolders": {
    "name": "Manage Folders",
    "class": "interaction",
    "namespace": "file-picker",
    "version": "v2",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "fileManagerBrowseShutterstock": {
    "name": "Browse Shutterstock",
    "class": "interaction",
    "namespace": "file-picker",
    "version": "v2",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "fileManagerAlert": {
    "name": "Alert interaction",
    "class": "interaction",
    "namespace": "file-picker",
    "version": "v2",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "fileManagerBulkImport": {
    "name": "Bulk Import Images",
    "class": "interaction",
    "properties": {
      "action": "string",
      "imageCount": {
        "type": "number",
        "isOptional": true
      },
      "isImageFile": {
        "type": "boolean",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerLibErrorDisplay": {
    "name": "Error display",
    "class": "view",
    "properties": {
      "type": "string",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerVideoOptIn": {
    "name": "video optin",
    "class": "interaction",
    "properties": {
      "hasOptedBefore": {
        "type": "boolean",
        "isOptional": true
      },
      "optInStatus": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerVideoInteraction": {
    "name": "video interaction",
    "class": "interaction",
    "properties": {
      "videoTitle": {
        "type": "string",
        "isOptional": true
      },
      "language": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerUpload": {
    "name": "upload file",
    "class": "interaction",
    "properties": {
      "size": {
        "type": "number",
        "isOptional": true
      },
      "errorType": {
        "type": "string",
        "isOptional": true
      },
      "statusCode": {
        "type": "number",
        "isOptional": true
      },
      "videoOptInStatus": {
        "type": "string",
        "isOptional": true
      },
      "videoDuration": {
        "type": "number",
        "isOptional": true
      },
      "videoHostingProvider": {
        "type": [
          "mux"
        ],
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerUploadDeduplication": {
    "name": "check upload duplicates",
    "class": "interaction",
    "properties": {
      "hasData": {
        "type": "boolean",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "size": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerVideoPQL": {
    "name": "Video PQL",
    "class": "interaction",
    "properties": {
      "alert": {
        "type": "string",
        "isOptional": true
      },
      "limit": {
        "type": "number",
        "isOptional": true
      },
      "quantityRemaining": {
        "type": "number",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerImageGeneration": {
    "name": "image generation",
    "class": "interaction",
    "properties": {
      "errorCount": {
        "type": "number",
        "isOptional": true
      },
      "isFeedbackPositive": {
        "type": "boolean",
        "isOptional": true
      },
      "feedbackText": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  }
};