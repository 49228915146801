import { createSelector } from 'reselect';
import { createGenericOptionsSelector } from 'ContentData/helpers/reduxHelpers';
import { FormInlineEditingOptinName } from 'ContentData/constants/Optins';
import { getIsOptedIn, getCanActionOptIn, getRequestStatus } from 'ContentData/selectors/optin';
import * as RequestStatus from 'ContentUtils/constants/RequestStatus';
import { getLangFromFormsLang } from 'ContentData/utils/forms';
export const getForm = (state, props) => state.resources.forms.forms[props.formGuid];
export const getDraftForm = (state, props) => {
  const formGuid = props.value ? props.value.form_id : props.formGuid;
  return state.resources.forms.formsDrafts[formGuid];
};
export const getDraftSaveRequestState = state => state.resources.forms.draftSaveRequestStatus;
export const getIsSaving = state => getDraftSaveRequestState(state) === RequestStatus.PENDING;
export const getIsSavingFailed = state => getDraftSaveRequestState(state) === RequestStatus.FAILED;
export const getIsSavingPreconditionFailed = state => getDraftSaveRequestState(state) === RequestStatus.PRECONDITION_FAILED;
export const getForms = state => state.resources.forms.forms;
export const getFormCache = (state, props) => state.resources.forms.formsCache[props.formGuid];
export const getFormsCache = state => state.resources.forms.formsCache;
export const getFormsCacheRequestStatus = state => state.resources.forms.formsCacheRequestStatus;
export const getFormFetchStatus = (state, props) => state.resources.forms.requestStatus[props.formGuid];
export const getFormFetchDraftStatus = (state, props) => state.resources.forms.draftRequestStatus[props.formGuid];
export const getFormsOptions = createGenericOptionsSelector(getFormsCache);
export const getDefaultFormsOptions = createGenericOptionsSelector(getForms);
export const getContactProperties = state => state.resources.forms.properties.contacts;
export const getSubscriptions = state => state.resources.forms.subscriptions;
export const getConsentCopy = (state, formDefinition) => formDefinition && formDefinition.metaData ? state.resources.forms.consentCopy[getLangFromFormsLang(formDefinition.metaData)] : {};
export const getIsOptedIntoInlineEdit = state => getIsOptedIn(state, FormInlineEditingOptinName);
export const getCanActionInlineEditOptIn = state => getCanActionOptIn(state, FormInlineEditingOptinName);
export const getInlineOptedInRequestSucceeded = state => getRequestStatus(state, FormInlineEditingOptinName) === RequestStatus.SUCCEEDED;
export const getParentPages = (state, formGuid) => state.resources.forms.parentPages[formGuid] || []; // This might not be run in the PageEditor so isn't gurranteed shape.

const getCurrentPageId = state => {
  try {
    return state.contentSchema.content.id;
  } catch (error) {
    return null;
  }
};
/**
 * Need to handle pages case, where it'll be used in 1 page (that happens to be the one were on)
 * and design manager case where it could be used in no pages.
 */


export const getShouldShowPagesModal = (state, props) => {
  const formGuid = props.value ? props.value.form_id : props.formGuid;
  const dismissedPagesWarning = state.resources.forms.dismissedPagesWarning;
  const pages = getParentPages(state, formGuid);
  const pageId = getCurrentPageId(state);

  if (dismissedPagesWarning) {
    return false;
  } // Remove pages that aren't the current one


  const filteredPages = pages.filter(({
    id
  }) => parseInt(id, 10) !== pageId);
  return filteredPages.length > 0;
};
export const makeGetContactPropertiesAsOptions = formDefinition => {
  return createSelector([getContactProperties], (contactProperties = []) => contactProperties.map(({
    displayName,
    properties
  }) => ({
    text: displayName,
    options: properties.reduce((acc, {
      property
    }) => {
      if (!property.calculated && !property.hidden) {
        acc = [...acc, {
          text: property.label,
          value: property,
          disabled: formDefinition && formDefinition.containsProperty(property)
        }];
      }

      return acc;
    }, [])
  })));
};
export const getFormWorkflowDependencies = (state, props) => state.resources.forms.formWorkflowDependencies[props.formGuid];

const getFormWorkflowDependenciesDeleteRequestStatus = state => state.resources.forms.formWorkflowDependenciesDeleteRequestStatus;

export const getFormWorkflowDependencyDeleteRequestStatus = (state, props = {}) => {
  const dependenciesDeleteRequestStatus = getFormWorkflowDependenciesDeleteRequestStatus(state);
  const {
    formGuid,
    flowId
  } = props;
  const formRequestStatuses = dependenciesDeleteRequestStatus[formGuid] || {};
  return formRequestStatuses[flowId];
};