import { useReducer } from 'react';
import { loadAndOpenSalesChat } from './_core/utils/loadAndOpenSalesChat';
const INITIAL_FRAME_DIMENSIONS = {
  height: '500px',
  width: '900px'
};
export let FrameAction;

(function (FrameAction) {
  FrameAction["LOAD_FRAME"] = "LOAD_FRAME";
  FrameAction["FRAME_READY"] = "FRAME_READY";
  FrameAction["CLOSE_FRAME"] = "CLOSE_FRAME";
  FrameAction["HIDE_PARENT_MODAL"] = "HIDE_PARENT_MODAL";
  FrameAction["RESIZE_PARENT_MODAL"] = "RESIZE_PARENT_MODAL";
  FrameAction["OPEN_CHAT"] = "OPEN_CHAT";
})(FrameAction || (FrameAction = {}));

export const EXPECTED_MESSAGES = Object.keys(FrameAction);
const initialFrameState = {
  loadFrame: false,
  frameReady: false,
  showParentModal: true,
  frameDimensions: INITIAL_FRAME_DIMENSIONS
};

const frameReducer = (state, action) => {
  const {
    LOAD_FRAME,
    FRAME_READY,
    CLOSE_FRAME,
    HIDE_PARENT_MODAL,
    RESIZE_PARENT_MODAL,
    OPEN_CHAT
  } = FrameAction;

  switch (action.type) {
    case LOAD_FRAME:
      return Object.assign({}, state, {
        loadFrame: true
      });

    case FRAME_READY:
      return Object.assign({}, state, {
        frameReady: true
      });

    case HIDE_PARENT_MODAL:
      return Object.assign({}, state, {
        showParentModal: false
      });

    case RESIZE_PARENT_MODAL:
      return Object.assign({}, state, {
        showParentModal: true,
        frameDimensions: Object.assign({}, state.frameDimensions, {}, action.payload)
      });

    case CLOSE_FRAME:
      return initialFrameState;

    case OPEN_CHAT:
      loadAndOpenSalesChat(action.payload);
      return state;

    default:
      return state;
  }
};

export const useModalFrameReducer = () => {
  const [frameState, dispatchFrameAction] = useReducer(frameReducer, initialFrameState);
  return [frameState, dispatchFrameAction];
};