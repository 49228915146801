import { useMemo } from 'react';
import { ApprovalTypes } from '../types';

const validateUserScope = (approvalData, action, user) => {
  const approvalType = approvalData.meta.approvalType;
  return approvalType === ApprovalTypes.MARKETING_EMAIL && user.scopes.includes(`email-approval-${action}`) || approvalType === ApprovalTypes.BLOG_POST && user.scopes.includes(`blog-approval-${action}`) || approvalType === ApprovalTypes.LANDING_PAGE && user.scopes.includes(`landingpages-approval-${action}`) || approvalType === ApprovalTypes.SITE_PAGE && user.scopes.includes(`pages-approval-${action}`) || approvalType === ApprovalTypes.DEAL && user.scopes.includes(`deal-approval-${action}`);
};

export const useHasReadWriteScope = (approvalData, action) => {
  const user = approvalData.meta.currentUser;
  return useMemo(() => validateUserScope(approvalData, action, user), [approvalData, action, user]);
};