'use es6';

import { Map as ImmutableMap } from 'immutable';
import { EDIT_SIDEBAR_ACTIVE_TAB_UPDATED, SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP, SELECTED_ITEM_UPDATED, SELECTED_ITEM_CLEARED, SELECTED_ITEM_REVERTED_TO_PREVIOUS, CLOSED_PANEL, SELECTED_FIELD_UPDATED } from 'ContentEditorUI/redux/actions/actionTypes';
import { EDIT_SIDEBAR_PANELS } from 'ContentEditorUI/lib/sidebar/constants';
import { SET_APPLY_CHANGES_BUTTON_OPEN } from '../actions/actionTypes';
const DEFAULT_EDIT_TAB = EDIT_SIDEBAR_PANELS.edit;
const initialState = new ImmutableMap({
  activeTab: DEFAULT_EDIT_TAB,
  previousTab: DEFAULT_EDIT_TAB,
  applyChangesButtonOpen: false,
  selectedField: null
});

const editSidebarReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case EDIT_SIDEBAR_ACTIVE_TAB_UPDATED:
      {
        const {
          activeTab
        } = payload;
        return state.merge({
          activeTab,
          previousTab: state.get('activeTab'),
          selectedField: null
        });
      }

    case SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP:
    case SELECTED_ITEM_UPDATED:
      {
        const {
          activeTab
        } = payload;
        return state.merge({
          activeTab: activeTab || DEFAULT_EDIT_TAB,
          previousTab: state.get('activeTab'),
          selectedField: null
        });
      }

    case SELECTED_ITEM_CLEARED:
      return state.merge({
        activeTab: DEFAULT_EDIT_TAB,
        previousTab: state.get('activeTab'),
        selectedField: null
      });

    case SELECTED_ITEM_REVERTED_TO_PREVIOUS:
      return state.merge({
        activeTab: state.get('previousTab'),
        previousTab: DEFAULT_EDIT_TAB
      });

    case CLOSED_PANEL:
      return state.merge({
        activeTab: '',
        previousTab: ''
      });

    case SET_APPLY_CHANGES_BUTTON_OPEN:
      return state.merge({
        applyChangesButtonOpen: payload.value
      });

    case SELECTED_FIELD_UPDATED:
      return state.set('selectedField', payload.selectedField);

    default:
      return state;
  }
};

export default editSidebarReducer;