export const getProductInfoFromSkuId = (products, id) => {
  return products.find(product => {
    return product.productVersions.find(productVersion => {
      return productVersion.skuId === id;
    });
  }) || null;
};
export const getProductInfoFromQuantitySkuId = (products, id) => {
  return products.find(product => {
    return product.productVersions.find(productVersion => {
      return productVersion.quantityPackDetails.find(quantityPack => {
        return quantityPack.skuId === id;
      });
    });
  }) || null;
};
export const getSkuMap = products => {
  const productVersions = products.reduce((acc, product) => {
    const keyedVersions = {};
    product.productVersions.forEach(version => keyedVersions[version.skuId] = Object.assign({}, version, {
      quantityPackDetails: version.quantityPackDetails.map(quantityPack => Object.assign({}, quantityPack, {
        productVersionSkuId: version.skuId
      }))
    }));
    return Object.assign({}, acc, {}, keyedVersions);
  }, {});
  return Object.values(productVersions).reduce((acc, productVersion) => {
    const skus = Object.assign({}, acc, {
      [productVersion.skuId]: productVersion
    });
    productVersion.quantityPackDetails.forEach(quantityPack => {
      skus[quantityPack.skuId] = quantityPack;
    });
    return skus;
  }, {});
};
export const getProductMap = products => {
  const productMap = products.reduce((acc, product) => {
    const {
      id
    } = product;
    return Object.assign({}, acc, {
      [id]: Object.assign({}, product, {
        productVersions: product.productVersions.map(({
          skuId
        }) => skuId)
      })
    });
  }, {});
  return productMap;
};