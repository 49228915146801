import { hubletApi } from 'hub-http/middlewares/core';
import http from 'hub-http/clients/noAuthApiClient';

const submitCsat = ({
  contactId,
  surveyId
}) => {
  return http.post('feedback/public/v1/submit/csat', {
    data: {
      channel: 'WEB',
      contactId,
      portalId: '53',
      surveyChannel: 'WEB',
      surveyId,
      value: 2,
      webAnalytics: {
        pageTitle: document.title,
        pageUrl: window.location.href,
        userAgent: window.navigator.userAgent
      }
    }
  });
};

const submitFollowup = ({
  followUp,
  surveyId,
  submissionId
}) => {
  return http.post('feedback/public/v1/submission-follow-up/csat', {
    api: hubletApi('feedback', 'hubapi'),
    data: {
      followUp,
      portalId: '53',
      rating: 2,
      submissionId,
      surveyId,
      surveyType: 'CSAT'
    }
  });
};

/**
 * To only submit followup feedback we have to do it in two parts, first submit CSAT with mock rating
 * Then submit followup.
 * @param {string} followUp
 * @param {string} surveyId
 * @param {number} contactId
 * @returns {Promise}
 */
export const submitFeedback = ({
  followUp,
  surveyId,
  contactId
}) => {
  return submitCsat({
    contactId,
    surveyId
  }).then(({
    submissionId
  }) => submitFollowup({
    followUp,
    surveyId,
    submissionId
  }));
};