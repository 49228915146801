import { userInfoSync } from 'hub-http/userInfo';
import { useEffect, useState, useCallback } from 'react';
import { MSG_TYPE_UPDATE_COMMENT_META_DESCRIPTION } from 'collaboration-sidebar/constants/IFrameMessageTypes';
export default function useUpdateMetaDescriptions({
  metaDescription,
  moduleParentComment,
  ctaUrl,
  broadcastCommentsMessage,
  userInfo
}) {
  const [updatedDescription, setUpdatedDescription] = useState(moduleParentComment && moduleParentComment.metaDescription);
  const getUserId = useCallback(() => {
    // use a callback to avoid calling userInfoSync implicitly from dependents' unit tests
    const {
      user: {
        user_id
      }
    } = userInfo || userInfoSync();
    return user_id;
  }, [userInfo]);
  useEffect(() => {
    // If the description options has changed, update it on the comment.
    if (metaDescription && moduleParentComment && moduleParentComment.metaDescription && JSON.stringify(updatedDescription) !== JSON.stringify(metaDescription) && (moduleParentComment.metaDescription.options && metaDescription.options && JSON.stringify(metaDescription.options) !== JSON.stringify(moduleParentComment.metaDescription.options) || metaDescription.translationKey !== moduleParentComment.metaDescription.translationKey) && getUserId() === moduleParentComment.userId) {
      // @ts-expect-error missing locationPath
      setUpdatedDescription(metaDescription); // TODO is it a bug that we don't set locationPath when updating meta description?

      broadcastCommentsMessage({
        type: MSG_TYPE_UPDATE_COMMENT_META_DESCRIPTION,
        comment: moduleParentComment,
        ctaUrl: ctaUrl || window.location.href,
        updatedMetaDescription: Object.assign({}, moduleParentComment.metaDescription, {}, metaDescription)
      });
    }
  }, [getUserId, broadcastCommentsMessage, ctaUrl, metaDescription, moduleParentComment, updatedDescription]);
}