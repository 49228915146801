'use es6';

import { createSelector } from 'reselect';
import { getContentTypeLimit } from 'ContentEditorUI/redux/selectors/limitSelectors';
import { getIsLandingPage, getIsSitePage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getPageLimitRequestState, getMlangSitePagesLimitRequestState } from './pageRequestStatesSelectors';
import { basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';
export const getPageLimitRequest = basicSelectorWithStats(state => {
  return getPageLimitRequestState(state);
});
export const getPageLimitLangKey = basicSelectorWithStats(state => {
  if (getIsLandingPage(state)) {
    return 'landingPage';
  } else if (getIsSitePage(state)) {
    return 'sitePage';
  }

  return null;
});
export const getShouldUseCmsStarterUpgradePoint = basicSelectorWithStats(state => {
  const pageLimit = getContentTypeLimit(state);
  return Boolean(pageLimit && pageLimit.pageLimit && pageLimit.pageLimit > 15 && pageLimit.quantityUsed < 40);
});
export const getNumberOfPagesOverLimit = basicSelectorWithStats(state => {
  const pageLimit = getContentTypeLimit(state);
  return pageLimit && pageLimit.pageLimit ? pageLimit.quantityUsed - pageLimit.pageLimit : 0;
});
export const getPageLimitValue = basicSelectorWithStats(state => {
  const pageLimit = getContentTypeLimit(state);
  return pageLimit && pageLimit.pageLimit;
}); // MULTI-LANGUAGE SITE PAGE LIMITS

export const getMlangSitePagesLimitRequest = basicSelectorWithStats(state => {
  return getMlangSitePagesLimitRequestState(state);
});
export const getHasExceededMlangSitePagesLimit = createSelector([getContentTypeLimit], pageLimit => Boolean(pageLimit && pageLimit.mlangSitePagesLimit && pageLimit.mlangSitePagesQuantityUsed > pageLimit.mlangSitePagesLimit));
export const getHasReachedMlangSitePagesLimit = createSelector([getContentTypeLimit], pageLimit => Boolean(pageLimit && pageLimit.mlangSitePagesLimit && pageLimit.mlangSitePagesQuantityUsed >= pageLimit.mlangSitePagesLimit));