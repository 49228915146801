'use es6';

import { Map as ImmutableMap } from 'immutable';
import { UPDATED_TEST_FLAGS } from 'ContentEditorUI/redux/actions/actionTypes';

const testFlagsReducer = (state = ImmutableMap(), {
  type,
  payload
}) => {
  switch (type) {
    case UPDATED_TEST_FLAGS:
      return new ImmutableMap(payload);

    default:
      return state;
  }
};

export default testFlagsReducer;