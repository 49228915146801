module.exports = {
  "optin-interaction": {
    "name": "optin interaction",
    "class": "interaction",
    "properties": {
      "name": "string",
      "action": [
        "opted in",
        "opted out",
        "clicked to view article",
        "clicked to submit feedback"
      ]
    },
    "namespace": "optin"
  },
  "optin-view": {
    "name": "optin view",
    "class": "view",
    "properties": {
      "name": "string"
    },
    "namespace": "optin"
  }
};