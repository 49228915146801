import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';
import { FETCH_DEFAULTS } from 'ContentData/constants/apiDefaults';

/**
 * GET meetings/v1/link/slug/{slug}
 * The slug is anything after /meetings/ in the meetings link
 */
export const fetchMeeting = (slug, ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`meetings/v1/link/slug/${slug}`);
};
/**
 * GET meetings/v2/link/portal
 */

export const fetchMeetings = (query = {}, ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('meetings/v2/link/portal', {
    query: Object.assign({}, FETCH_DEFAULTS.meetings.query, {}, query)
  });
};