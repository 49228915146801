import I18n from 'I18n';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import * as UpgradeProducts from 'self-service-api/constants/UpgradeProducts';
import * as ApiNames from 'self-service-api/constants/ApiNames';
import Raven from 'raven-js';
import { trackFailedApiNameMap } from '../../utils/metricUtils';
export const i18nKeysByUpgradeProduct = {
  [UpgradeProducts.SALES_PRO]: 'salesPro',
  [UpgradeProducts.SALES_STARTER]: 'salesStarter',
  [UpgradeProducts.SALES_PROFESSIONAL]: 'salesProfessional',
  [UpgradeProducts.SALES_ENTERPRISE]: 'salesEnterprise',
  [UpgradeProducts.SUITE_STARTER]: 'suiteStarter',
  [UpgradeProducts.MARKETING_STARTER]: 'marketingStarter',
  [UpgradeProducts.MARKETING_STARTER_EMAIL]: 'marketingStarterEmail',
  [UpgradeProducts.MARKETING_BASIC]: 'marketingBasic',
  [UpgradeProducts.MARKETING_PRO]: 'marketingProfessional',
  [UpgradeProducts.MARKETING_ENTERPRISE]: 'marketingEnterprise',
  [UpgradeProducts.SERVICE_STARTER]: 'serviceStarter',
  [UpgradeProducts.SERVICE_PROFESSIONAL]: 'serviceProfessional',
  [UpgradeProducts.SERVICE_ENTERPRISE]: 'serviceEnterprise',
  [UpgradeProducts.OPERATIONS_STARTER]: 'operations-starter',
  [UpgradeProducts.OPERATIONS_PROFESSIONAL]: 'operations-professional',
  [UpgradeProducts.OPERATIONS_ENTERPRISE]: 'operations-enterprise',
  [UpgradeProducts.ADS]: 'ads',
  [UpgradeProducts.WEBSITE]: 'website',
  [UpgradeProducts.REPORTING]: 'reporting',
  [UpgradeProducts.DEDICATED_IP]: 'dedicatedIp',
  [UpgradeProducts.TRANSACTIONAL_EMAIL]: 'transactionalEmail',
  [UpgradeProducts.ADDITIONAL_ACCOUNT]: 'additionalAccount',
  [UpgradeProducts.DESIGNATED_TECHNICAL_SUPPORT]: 'designatedTechnicalSupport',
  [UpgradeProducts.IN_PERSON_TRAINING]: 'inPersonTraining',
  [UpgradeProducts.ENTERPRISE]: 'enterprise',
  [UpgradeProducts.CMS_ENTERPRISE]: 'cmsEnterprise',
  [UpgradeProducts.CMS_PROFESSIONAL]: 'cmsProfessional',
  [UpgradeProducts.CMS_STARTER]: 'cmsStarter',
  [UpgradeProducts.CUSTOM_SSL]: 'customSsl',
  [UpgradeProducts.DOMAINS_LIMIT_INCREASE]: 'domains-limit-increase',
  [UpgradeProducts.SANDBOX_LIMIT_INCREASE]: 'sandbox-limit-increase',
  [UpgradeProducts.CALLING_LIMIT_INCREASE]: 'calling-limit-increase',
  [UpgradeProducts.PHONE_LIMIT_INCREASE]: 'phone-limit-increase',
  [UpgradeProducts.TEAMS_LIMIT_INCREASE]: 'teams-limit-increase',
  [UpgradeProducts.WHATSAPP_LIMIT_INCREASE]: 'whatsapp-limit-increase',
  [UpgradeProducts.VIDEO_LIMIT_INCREASE]: 'video-limit-increase',
  [UpgradeProducts.MARKETING_SMS_ADDON]: 'marketing-sms',
  [UpgradeProducts.MARKETING_SMS_LIMIT_INCREASE]: 'marketing-sms-limit-increase',
  [UpgradeProducts.STARTER_CONTACTS]: 'starter-contacts',
  [UpgradeProducts.ESIGNATURE_LIMIT_INCREASE]: 'esignature-limit-increase',
  [UpgradeProducts.WORKFLOWS_ADDON]: 'workflows-addon',
  [UpgradeProducts.WORKFLOWS_LIMIT_INCREASE]: 'workflows-limit-increase',
  [UpgradeProducts.USERS_LIMIT_INCREASE]: 'users-limit-increase',
  [UpgradeProducts.CUSTOM_OBJECTS_LIMIT_INCREASE]: 'custom-objects-limit-increase'
};
export const i18nKeysByApiName = {
  [ApiNames.SALES_HUB_STARTER]: 'salesStarter',
  [ApiNames.SALES_HUB_PROFESSIONAL]: 'salesPro',
  [ApiNames.SALES_HUB_ENTERPRISE]: 'salesEnterprise',
  [ApiNames.MARKETING_HUB_BASIC]: 'marketingBasic',
  [ApiNames.MARKETING_HUB_STARTER]: 'marketingStarter',
  [ApiNames.MARKETING_HUB_PROFESSIONAL]: 'marketingProfessional',
  [ApiNames.MARKETING_HUB_ENTERPRISE]: 'marketingEnterprise',
  [ApiNames.SERVICE_HUB_STARTER]: 'serviceStarter',
  [ApiNames.SERVICE_HUB_PROFESSIONAL]: 'serviceProfessional',
  [ApiNames.SERVICE_HUB_ENTERPRISE]: 'serviceEnterprise',
  [ApiNames.OPERATIONS_HUB_STARTER]: 'operations-starter',
  [ApiNames.OPERATIONS_HUB_PROFESSIONAL]: 'operations-professional',
  [ApiNames.OPERATIONS_HUB_ENTERPRISE]: 'operations-enterprise',
  [ApiNames.CMS_HUB_STARTER]: 'cmsStarter',
  [ApiNames.CMS_HUB_PROFESSIONAL]: 'cmsProfessional',
  [ApiNames.CMS_HUB_ENTERPRISE]: 'cmsEnterprise',
  [ApiNames.CRM_SUITE_STARTER_BUNDLE]: 'crm-suite-starter-bundle',
  [ApiNames.CRM_SUITE_PROFESSIONAL_BUNDLE]: 'crm-suite-professional-bundle',
  [ApiNames.CRM_SUITE_ENTERPRISE_BUNDLE]: 'crm-suite-enterprise-bundle',
  [ApiNames.ADS_LIMIT_INCREASE]: 'ads',
  [ApiNames.REPORTING_LIMIT_INCREASE]: 'reporting',
  [ApiNames.DEDICATED_IP]: 'dedicatedIp',
  [ApiNames.TRANSACTIONAL_EMAIL]: 'transactionalEmail',
  [ApiNames.CUSTOM_SSL]: 'customSsl',
  [ApiNames.MARKETING_SMS_ADDON]: 'marketing-sms',
  [ApiNames.MARKETING_SMS_LIMIT_INCREASE]: 'marketing-sms-limit-increase',
  [ApiNames.DOMAINS_LIMIT_INCREASE]: 'domains-limit-increase',
  [ApiNames.SANDBOX_LIMIT_INCREASE]: 'sandbox-limit-increase',
  [ApiNames.CALLING_LIMIT_INCREASE]: 'calling-limit-increase',
  [ApiNames.PHONE_LIMIT_INCREASE]: 'phone-limit-increase',
  [ApiNames.TEAMS_LIMIT_INCREASE]: 'teams-limit-increase',
  [ApiNames.WHATSAPP_LIMIT_INCREASE]: 'whatsapp-limit-increase',
  [ApiNames.VIDEO_LIMIT_INCREASE]: 'video-limit-increase',
  [ApiNames.ESIGNATURE_LIMIT_INCREASE]: 'esignature-limit-increase',
  [ApiNames.WORKFLOWS_LIMIT_INCREASE]: 'workflows-limit-increase',
  [ApiNames.USERS_LIMIT_INCREASE]: 'users-limit-increase',
  [ApiNames.CUSTOM_OBJECTS_LIMIT_INCREASE]: 'custom-objects-limit-increase',
  [ApiNames.SERVICE_HUB_PROFESSIONAL_ONBOARDING]: 'service-hub-professional-onboarding',
  [ApiNames.SALES_HUB_PROFESSIONAL_ONBOARDING]: 'sales-hub-professional-onboarding'
};
export const getProductNameTextFromApiName = apiName => {
  const productTitleKey = i18nKeysByApiName[apiName];

  if (!isValidI18nKey(`upgrades.products.${productTitleKey}`)) {
    trackFailedApiNameMap('getProductNameTextFromApiName', apiName, 'no upgrade product');
    Raven.captureMessage(`failed to map get product name text from apiName ${apiName} in getProductNameTextFromApiName`);
  }

  return I18n.text(`upgrades.products.${productTitleKey}`);
};
export const getProductNameText = upgradeProduct => {
  const productTitleKey = i18nKeysByUpgradeProduct[upgradeProduct];
  return I18n.text(`upgrades.products.${productTitleKey}`);
};
export const getProductNameTextOrDefault = upgradeProduct => {
  const productTitleKey = i18nKeysByUpgradeProduct[upgradeProduct];
  const messageKey = `upgrades.products.${productTitleKey}`;
  if (isValidI18nKey(messageKey)) return I18n.text(messageKey);
  return I18n.text('upgrades.products.default');
};
export const getProductNameTextFromApiNameOrDefault = apiName => {
  const productTitleKey = i18nKeysByApiName[apiName];
  const messageKey = `upgrades.products.${productTitleKey}`;

  if (!isValidI18nKey(messageKey)) {
    trackFailedApiNameMap('getProductNameTextFromApiNameOrDefault', apiName, 'no upgrade product');
    Raven.captureMessage(`failed to map get product name text from apiName ${apiName} in getProductNameTextFromApiNameOrDefault`);
  }

  if (isValidI18nKey(messageKey)) return I18n.text(messageKey);
  return I18n.text('upgrades.products.default');
};