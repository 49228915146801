import PortalIdParser from 'PortalIdParser';
import enviro from 'enviro';
import { na1 as DEFAULT_API_HUBLET } from 'hubspot-url-utils/hublets';

function getEnv() {
  return enviro.getShort('filemanager');
}

export const constructShard = (apexDomainSuffix, formattedShardIndex) => {
  const portalId = PortalIdParser.get();
  return `${portalId}.fs1.hubspotusercontent${apexDomainSuffix}${formattedShardIndex}.net`;
};
export const getShardDomainSuffixFromEnv = env => env === 'qa' ? 'qa' : '';
export const isNonDefaultHublet = () => enviro.getHublet() !== DEFAULT_API_HUBLET;
export function getCdnHostnameForNonDefaultHublet() {
  const shardApexDomainSuffix = getShardDomainSuffixFromEnv(getEnv());
  return constructShard(shardApexDomainSuffix, `-${enviro.getHublet()}`);
}
export const getCdnHostnameForPortal = () => {
  return getCdnHostnameForNonDefaultHublet();
};