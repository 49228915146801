import { CLOSE_CLONE_REQUEST_MODAL, LAYOUT_SECTION_REQUESTED_CLONE } from 'ContentEditorUI/redux/actions/actionTypes';
import produce from 'immer';
const INITIAL_STATE = {
  actionToDispatch: null,
  typeToClone: null
};

const cloneRequestReducer = (state = INITIAL_STATE, action) => produce(state, draft => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case CLOSE_CLONE_REQUEST_MODAL:
      {
        draft.actionToDispatch = null;
        draft.typeToClone = null;
        return draft;
      }

    case LAYOUT_SECTION_REQUESTED_CLONE:
      {
        const {
          actionToDispatch,
          typeToClone
        } = payload;
        draft.actionToDispatch = actionToDispatch;
        draft.typeToClone = typeToClone;
        return draft;
      }

    default:
      {
        return draft;
      }
  }
});

export default cloneRequestReducer;