import { sanitize } from 'content-assistance-lib/utils/sanitize';
import getLangLocale from 'I18n/utils/getLangLocale';
export const CONTENT_ASSISTANCE_URL = 'content-assistance/v2/command-executions';
const RESULT_POLL_TIMEOUT = 200;
const ML_FEEDBACK_URL = 'ml-nlp/v1/feedbacks/text-generation';
export const getExecutionIdUrl = executionId => {
  return `content-assistance/v3/command-executions/${executionId}`;
}; // This should be set once per app load with the value
// returned from the first time we hit the content assistance endpoint,
// and then sent to the content assistance end point for every other request made.

let appSessionId; // see https://tools.hubteamqa.com/puma/framework-app-settings/contentAssistanceCommands/entries
// new commands should be added there

export const fetchContentAssistanceGeneratedContent = ({
  http,
  commandId,
  parameters,
  resultCount = 1,
  // Language code, e.g. `es-es`
  language,
  objectId
}) => {
  return http.post(CONTENT_ASSISTANCE_URL, {
    data: {
      commandId,
      parameters,
      resultCount,
      // Fallback to i18n's language, if none is supplied
      language: language || getLangLocale(),
      sessionId: appSessionId,
      objectId
    },
    timeout: 30000
  }).then(({
    executionId,
    sessionId
  }) => {
    if (!appSessionId) {
      appSessionId = sessionId;
    }

    const executionIdUrl = getExecutionIdUrl(executionId);

    const poll = () => {
      return http.getWithResponse(executionIdUrl).then(resp => {
        // 202 means the results aren't ready yet, call again
        if (resp.status === 202) {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              return poll().then(result => resolve(result)).catch(reject);
            }, RESULT_POLL_TIMEOUT);
          });
        }

        const responseJSON = resp.responseJSON;

        if (responseJSON.statusCode === 400) {
          throw responseJSON;
        }

        return responseJSON;
      }).catch(error => {
        /*
          Bubble this error up to the outer promise
          so all errors can be caught in 1 catch
        */
        throw error;
      });
    };

    return poll();
  }).then(data => {
    const response = data;
    const {
      results: resultsOrNull,
      correlationId
    } = response;
    const results = resultsOrNull || [];
    const normalizedResults = [];
    results.forEach(apiResult => {
      if (apiResult.value) {
        // Sanitize the text
        const unsanitizedValue = apiResult.value;
        const sanitizedValue = sanitize(unsanitizedValue);
        apiResult.text = typeof sanitizedValue === 'string' ? sanitizedValue : ''; // Annotate the result with the correlationId so we can use it for tracking later

        apiResult.correlationId = correlationId;
        normalizedResults.push(apiResult);
      }
    });
    return normalizedResults;
  });
};
export const generateFeedbackAnnotatedResults = (generatedContent, results, shouldSendPositiveFeedback = true) => {
  const {
    text,
    correlationId
  } = generatedContent;
  return results.map(result => {
    const resultCopy = Object.assign({}, result);

    if (shouldSendPositiveFeedback && correlationId === resultCopy.correlationId && text === resultCopy.text) {
      // Override the text generated from the endpoint with
      // what the user actually inserted (if they changed some stuff)
      resultCopy.text = text;
      resultCopy.isPositiveFeedback = true;
    } else {
      resultCopy.isPositiveFeedback = false;
    }

    return resultCopy;
  });
};
export const getFeedbackTriggerButtonResults = (currentInputText, generatedContent, results) => {
  const {
    correlationId,
    text
  } = generatedContent;
  return results.map(result => {
    const resultCopy = Object.assign({}, result); // Default feedback to negative

    resultCopy.isPositiveFeedback = false;

    if (correlationId === resultCopy.correlationId && text === resultCopy.text) {
      // Only successful when it was inserted and not changed
      if (resultCopy.text === currentInputText && results.length === 1) {
        resultCopy.isPositiveFeedback = true;
      } else {
        // Override the text generated from the endpoint with
        // what the user actually inserted (if they changed some stuff)
        resultCopy.text = currentInputText;
      }
    }

    return resultCopy;
  });
};
export const sendFeedbackToML = ({
  http,
  feedbackAnnotatedCompletions
}) => {
  const allFeedbackRequests = [];
  feedbackAnnotatedCompletions.forEach(feedback => {
    const {
      isPositiveFeedback,
      value,
      correlationId
    } = feedback;
    const data = {
      correlationId,
      sessionId: appSessionId,
      feedbacks: [{
        value,
        label: isPositiveFeedback
      }]
    };
    allFeedbackRequests.push(http.post(ML_FEEDBACK_URL, {
      data
    }));
  });
  return Promise.all(allFeedbackRequests);
};