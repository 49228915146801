var ErrorSubCategory;

(function (ErrorSubCategory) {
  ErrorSubCategory["ACTIVE_APPROVAL_ALREADY_EXISTS"] = "ACTIVE_APPROVAL_ALREADY_EXISTS";
  ErrorSubCategory["APPROVAL_IS_ALREADY_APPROVED_ERROR"] = "APPROVAL_IS_ALREADY_APPROVED_ERROR";
  ErrorSubCategory["APPROVAL_IS_ALREADY_CANCELED_ERROR"] = "APPROVAL_IS_ALREADY_CANCELED_ERROR";
  ErrorSubCategory["APPROVAL_IS_ALREADY_REJECTED_ERROR"] = "APPROVAL_IS_ALREADY_REJECTED_ERROR";
  ErrorSubCategory["APPROVAL_REJECTIONS_GATED"] = "APPROVAL_REJECTIONS_GATED";
  ErrorSubCategory["APPROVAL_STEP_IS_ALREADY_APPROVED_ERROR"] = "APPROVAL_STEP_IS_ALREADY_APPROVED_ERROR";
  ErrorSubCategory["APPROVAL_STEP_IS_ALREADY_REJECTED_ERROR"] = "APPROVAL_STEP_IS_ALREADY_REJECTED_ERROR";
  ErrorSubCategory["APPROVAL_STEP_TRANSITION_NOT_PERMITTED"] = "APPROVAL_STEP_TRANSITION_NOT_PERMITTED";
  ErrorSubCategory["APPROVAL_TRANSITION_NOT_PERMITTED"] = "APPROVAL_TRANSITION_NOT_PERMITTED";
  ErrorSubCategory["APPROVAL_TYPE_DOES_NOT_SUPPORT_APPROVAL_ACTION"] = "APPROVAL_TYPE_DOES_NOT_SUPPORT_APPROVAL_ACTION";
  ErrorSubCategory["APPROVALS_NOT_ENABLED"] = "APPROVALS_NOT_ENABLED";
  ErrorSubCategory["APPROVER_NOT_VALID"] = "APPROVER_NOT_VALID";
  ErrorSubCategory["APPROVER_OWNER_ID_DOES_NOT_EXIST"] = "APPROVER_OWNER_ID_DOES_NOT_EXIST";
  ErrorSubCategory["CAN_NOT_UPDATE_APPROVAL_IF_NOT_PENDING"] = "CAN_NOT_UPDATE_APPROVAL_IF_NOT_PENDING";
  ErrorSubCategory["CAN_NOT_UPDATE_APPROVAL_REMOVING_APPROVED_STEPS"] = "CAN_NOT_UPDATE_APPROVAL_REMOVING_APPROVED_STEPS";
  ErrorSubCategory["FEATURE_NOT_SUPPORTED_FOR_THIS_TYPE"] = "FEATURE_NOT_SUPPORTED_FOR_THIS_TYPE";
  ErrorSubCategory["PORTAL_NOT_ALLOWED"] = "PORTAL_NOT_ALLOWED";
  ErrorSubCategory["REQUEST_ENABLING_NOTIFICATION_ALREADY_ENABLED"] = "REQUEST_ENABLING_NOTIFICATION_ALREADY_ENABLED";
  ErrorSubCategory["REQUEST_ENABLING_NOTIFICATION_CANNOT_BE_SENT"] = "REQUEST_ENABLING_NOTIFICATION_CANNOT_BE_SENT";
  ErrorSubCategory["REQUEST_ENABLING_NOTIFICATION_GATED"] = "REQUEST_ENABLING_NOTIFICATION_GATED";
  ErrorSubCategory["REQUEST_ENABLING_NOTIFICATION_RECIPIENT_NOT_SUPER_ADMIN"] = "REQUEST_ENABLING_NOTIFICATION_RECIPIENT_NOT_SUPER_ADMIN";
  ErrorSubCategory["REQUEST_ENABLING_NOTIFICATION_REQUESTER_IS_SUPER_ADMIN"] = "REQUEST_ENABLING_NOTIFICATION_REQUESTER_IS_SUPER_ADMIN";
  ErrorSubCategory["REQUEST_ENABLING_NOTIFICATION_WAS_ALREADY_SENT"] = "REQUEST_ENABLING_NOTIFICATION_WAS_ALREADY_SENT";
  ErrorSubCategory["REQUESTER_NON_VALID_FOR_CANCELLATION"] = "REQUESTER_NON_VALID_FOR_CANCELLATION";
  ErrorSubCategory["REQUESTER_SAME_USER_AS_THE_APPROVER"] = "REQUESTER_SAME_USER_AS_THE_APPROVER";
  ErrorSubCategory["UNKNOWN_ERROR"] = "UNKNOWN_ERROR";
  ErrorSubCategory["UNSUPPORTED_RULE"] = "UNSUPPORTED_RULE";
  ErrorSubCategory["USER_IS_NOT_AN_APPROVER"] = "USER_IS_NOT_AN_APPROVER";
})(ErrorSubCategory || (ErrorSubCategory = {}));

export default ErrorSubCategory;