import { useCallback, useEffect, useMemo } from 'react';
import useFetchComments from './useFetchComments';
import useCollaborationSidebarState from './useCollaborationSidebarState';
import useIsCommentsDataStale from './useIsCommentsDataStale';
import useTrackLiveRefresh from './useTrackLiveRefresh';
export default function useHasUnreadComments(objectType, objectId) {
  const {
    fetchComments,
    comments,
    loading,
    updateCache: updateCommentsCache
  } = useFetchComments();
  const {
    assetsLastUpdated
  } = useCollaborationSidebarState();
  const isCommentsDataStale = useIsCommentsDataStale({
    objectType,
    objectId,
    assetsLastUpdated
  });
  useEffect(() => {
    fetchComments({
      objectType,
      objectId,
      refetch: isCommentsDataStale
    });
  }, [fetchComments, objectId, objectType, isCommentsDataStale]);
  const unreadCommentsCount = useMemo(() => {
    const unreadComments = comments.filter(comment => !comment.read);
    return unreadComments.length;
  }, [comments]); // TODO: replace hasUnreadComments with the unreadCommentsCount after the MO:CollaborationSidebar:UnreadComments is ungated at 100%

  const hasUnreadComments = unreadCommentsCount > 0;
  const markCommentAsRead = useCallback(updatedCommentId => {
    if (!objectId) {
      return;
    }

    const updatedComments = comments.map(comment => {
      if (comment.id === updatedCommentId) {
        return Object.assign({}, comment, {
          read: true
        });
      }

      return comment;
    });
    updateCommentsCache({
      objectType,
      objectId,
      updatedComments
    });
  }, [comments, objectId, objectType, updateCommentsCache]);
  useTrackLiveRefresh({
    unreadCommentsCount,
    unreadCommentsLoading: loading
  });
  return {
    hasUnreadComments,
    unreadCommentsCount,
    refetchComments: () => fetchComments({
      objectType,
      objectId,
      refetch: true
    }),
    markCommentAsRead
  };
}