import { HORIZONTAL_SIDES, VERTICAL_SIDES } from 'ContentUtils/constants/Border';
export const isValidAlphaDec = alpha => {
  if (isNaN(alpha) || typeof alpha !== 'number' || alpha < 0 || alpha > 1) {
    return false;
  }

  return true;
};
export const isValidAlphaInt = alpha => {
  const isNotANumericAlpha = isNaN(alpha) || typeof alpha !== 'number';
  const isValidAlphaRange = alpha >= 0 && alpha <= 100;
  return alpha == null || !isNotANumericAlpha && isValidAlphaRange;
};
export const isValidRGB = colorChannels => {
  const rgb = ['r', 'g', 'b']; // The RGB values all have the same valid range, check if any one of them is bad

  const hasValidRGBValues = rgb.every(channel => {
    if (isNaN(colorChannels[channel]) || typeof colorChannels[channel] !== 'number' || colorChannels[channel] < 0 || colorChannels[channel] > 255) {
      return false;
    }

    return true;
  }); // If there are no bad RGB values and we made is this far, than this is a valid RGBA object

  if (hasValidRGBValues) {
    return true;
  } else {
    return false;
  }
};
export const isValidRGBA = colorChannels => {
  return isValidAlphaDec(colorChannels.a) && isValidRGB(colorChannels);
};
export const isValidRgbString = colorString => {
  return /^rgb\((\d{1,3}%?),\s*(\d{1,3}%?),\s*(\d{1,3}%?)\)$/i.test(colorString);
};
export const isValidRgbaString = colorString => {
  return /^rgba\((\d{1,3}%?),\s*(\d{1,3}%?),\s*(\d{1,3}%?),\s*(\d*(?:\.\d+)?)\)$/i.test(colorString);
};
export const isValidHex = hex => {
  return hex == null || /^#([0-9A-F]{6}|[0-9A-F]{3})$/i.test(hex);
};
export const isValidGradientDirection = sideOrCornerObject => {
  const {
    verticalSide,
    horizontalSide
  } = sideOrCornerObject;

  if (!verticalSide && !horizontalSide) {
    console.warn('Gradient sideOrCorner key must specify the keys "verticalSide" and/or "horizonalSide"');
    return false;
  }

  if (verticalSide && verticalSide !== VERTICAL_SIDES.TOP && verticalSide !== VERTICAL_SIDES.BOTTOM) {
    return false;
  }

  if (horizontalSide && horizontalSide !== HORIZONTAL_SIDES.LEFT && horizontalSide !== HORIZONTAL_SIDES.RIGHT) {
    return false;
  }

  return true;
};
export const convertColorBitToHex = color => {
  let hex = Number(color).toString(16);
  if (hex.length < 2) hex = `0${hex}`;
  return hex;
};
export const convertRgbToHex = ({
  r,
  g,
  b
}) => {
  if (r == null || g == null || b == null) return null;
  const red = convertColorBitToHex(r);
  const green = convertColorBitToHex(g);
  const blue = convertColorBitToHex(b);
  const hex = `#${red}${green}${blue}`;

  if (!isValidHex(hex)) {
    throw new Error('RGB value passed to convertRgbToHex results in invalid hex value.');
  }

  return hex;
};
export const convertAlphaDecToInt = alphaDec => {
  if (typeof alphaDec !== 'number') {
    throw new Error('Invalid number passed to convertAlphaDecToInt.');
  }

  const alphaInt = alphaDec * 100;

  if (!isValidAlphaInt(alphaInt)) {
    throw new Error('Alpha decimal value results in invalid integer value.');
  }

  return alphaInt;
};
export const convertAlphaIntToDec = alphaInt => {
  if (alphaInt != null && typeof alphaInt !== 'number') {
    throw new Error('Invalid number passed to convertAlphaIntToDec.');
  }

  if (alphaInt == null) return null;
  const alphaDec = alphaInt / 100;

  if (!isValidAlphaDec(alphaDec)) {
    throw new Error('Alpha integer value results in invalid decimal value.');
  }

  return alphaDec;
};
export const convertRgbaToHexAndAlphaInt = ({
  r,
  g,
  b,
  a = 1.0
}) => {
  return {
    hex: convertRgbToHex({
      r,
      g,
      b
    }),
    alpha: convertAlphaDecToInt(a)
  };
};
export const convertRgbStringToRgbObject = rgbString => {
  if (typeof rgbString !== 'string') {
    throw new Error('No css string provided');
  }

  const rgbStringArray = rgbString.split('(')[1].split(')')[0].split(',');
  const rgbNumberArray = rgbStringArray.map(stringNum => parseFloat(stringNum));
  const rgbObject = {
    r: rgbNumberArray[0],
    g: rgbNumberArray[1],
    b: rgbNumberArray[2]
  };

  if (!isValidRGB(rgbObject)) {
    throw new Error('RGB value parsed from css string invalid');
  }

  return rgbObject;
};
export const convertRgbaStringToRgbaObject = rgbaString => {
  if (typeof rgbaString !== 'string') {
    throw new Error('No css string provided');
  }

  const rgbaStringArray = rgbaString.split('(')[1].split(')')[0].split(',');
  const rgbaNumberArray = rgbaStringArray.map(stringNum => parseFloat(stringNum));
  const rgbaObject = {
    r: rgbaNumberArray[0],
    g: rgbaNumberArray[1],
    b: rgbaNumberArray[2],
    a: typeof rgbaNumberArray[3] === 'number' ? rgbaNumberArray[3] : 1
  };

  if (!isValidRGBA(rgbaObject)) {
    throw new Error('RGBA value parsed from css string invalid');
  }

  return rgbaObject;
};
export const convertHexAndAlphaToRgbaObject = (hex, alpha = 1) => {
  if (!isValidHex(hex)) {
    throw new Error('Invalid hex value supplied to convertHexAndAlphaToRgbaObject.');
  }

  if (!hex) return {
    r: null,
    g: null,
    b: null,
    a: alpha
  };
  hex = hex.replace('#', ''); // 3 characters is a valid hex, double it to 6 characters for the sake of
  // computed rgba values if needed. Changes "abc" to "aabbcc"

  if (hex.length === 3) {
    const sixDigitHex = hex.split('').reduce((accumulator, currentValue) => {
      return accumulator + currentValue + currentValue;
    }, '');
    hex = sixDigitHex;
  }

  const rgba = {
    r: parseInt(hex.substring(0, 2), 16),
    g: parseInt(hex.substring(2, 4), 16),
    b: parseInt(hex.substring(4, 6), 16),
    a: alpha
  };

  if (!isValidRGBA(rgba)) {
    throw new Error('Invalid hex value converts to out of range color channel values. Valid range for each channel is (0 - 255)');
  }

  return rgba;
};
export const convertHexAndAlphaIntToRgbaObject = (hex, opacity = 100) => {
  return convertHexAndAlphaToRgbaObject(hex, convertAlphaIntToDec(opacity));
};