import { createQueue, dispatchFunctionAsync } from './common/helpers';
import { safeGuardKey } from './storageKeys';
export const createEventPool = ({
  getTempStorage,
  setTempStorage
}) => {
  const eventQueue = createQueue();
  return {
    peek: eventQueue.peek,
    push: event => {
      eventQueue.enqueue(event); // This method attempts to save the Event to a safe-guard storage in case
      // that Events are not dispatched by any of the other safe-guard methods

      dispatchFunctionAsync(() => {
        try {
          const eventsRaw = getTempStorage(safeGuardKey) || '[]';
          const events = JSON.parse(eventsRaw);
          setTempStorage(safeGuardKey, // Add the Event to the End of the Array
          JSON.stringify(events.concat(event)));
        } catch (err) {
          // In case the storage is corrupt, simply add the current Event
          setTempStorage(safeGuardKey, JSON.stringify([event]));
        }
      });
    },
    flush: () => {
      const events = [];

      do {
        const event = eventQueue.dequeue();

        if (event) {
          events.unshift(event);
        }
      } while (eventQueue.peek()); // Clears all the remaining Events from the safe-guard storage
      // since the Events are being sent over the Network


      dispatchFunctionAsync(() => setTempStorage(safeGuardKey, '[]'));
      return events;
    }
  };
};