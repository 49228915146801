'use es6';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getComputedCombinedEditorStyles, getComputedNonModuleStyleCssString } from 'ContentEditorUI/redux/selectors/styleSelectors';
import { updateCss as updateCssAction } from 'ContentEditorUI/redux/actions/baseContentModelActions';
import { fetchPageCssAssets as fetchPageCssAssetsAction, updateEditorRenderedCssAsset as updateEditorRenderedCssAssetAction } from 'ContentEditorUI/redux/actions/contentAssetsActions';
import { getHasUnsavedChanges } from 'ContentEditorUI/redux/selectors/saveDataSelectors';
import { getShouldFetchNewCssAssetsOnSave } from 'ContentEditorUI/redux/selectors/contentAssetsSelectors';

const mapStateToProps = state => {
  return {
    computedCombinedEditorStyleCssString: getComputedCombinedEditorStyles(state),
    computedNonModuleStyleCssString: getComputedNonModuleStyleCssString(state),
    hasUnsavedChanges: getHasUnsavedChanges(state),
    shouldFetchNewCssAssetsOnSave: getShouldFetchNewCssAssetsOnSave(state)
  };
};

const mapDispatchToProps = {
  updateCss: updateCssAction,
  fetchPageCssAssets: fetchPageCssAssetsAction,
  updateEditorRenderedCssAsset: updateEditorRenderedCssAssetAction
};

class StyleCssMonitorContainer extends Component {
  static propTypes() {
    return {
      updateCss: PropTypes.func.isRequired,
      computedCombinedEditorStyleCssString: PropTypes.string,
      computedNonModuleStyleCssString: PropTypes.string,
      hasUnsavedChanges: PropTypes.bool.isRequired,
      shouldFetchNewCssAssetsOnSave: PropTypes.bool.isRequired
    };
  }

  componentDidUpdate(prevProps) {
    const {
      updateCss,
      computedCombinedEditorStyleCssString,
      computedNonModuleStyleCssString,
      hasUnsavedChanges,
      fetchPageCssAssets,
      updateEditorRenderedCssAsset,
      shouldFetchNewCssAssetsOnSave
    } = this.props; // If we just finished a save, fetch the "source of truth" for how CSS
    // should render on the page from the renderer

    if (shouldFetchNewCssAssetsOnSave && prevProps.hasUnsavedChanges && !hasUnsavedChanges) {
      fetchPageCssAssets();
    }

    if (!Object.keys(prevProps.computedNonModuleStyleCssString).length && !Object.keys(computedNonModuleStyleCssString).length && prevProps.computedCombinedEditorStyleCssString === computedCombinedEditorStyleCssString) {
      return;
    } // This updates the style block in the css assets array in contentAssetsReducer


    updateEditorRenderedCssAsset(computedCombinedEditorStyleCssString); // This action seems to help with persisting body styles :shrug:

    if (computedNonModuleStyleCssString !== prevProps.computedNonModuleStyleCssString) {
      updateCss({
        css: computedNonModuleStyleCssString
      });
    }
  }

  render() {
    return null;
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(StyleCssMonitorContainer);