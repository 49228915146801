'use es6';

import { fromJS } from 'immutable';
import { UPDATE_USER_ATTRIBUTE_ALSO_CACHED_IN_SUPERSTORE, UPDATE_SUPERSTORE_USER_SETTING, UPDATE_SUPERSTORE_PER_CONTENT_SETTING, FETCH_USER_ATTRIBUTES_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';

const locallyCachedSettingsReducer = (state = fromJS({
  perUser: {},
  perContent: {},
  errorLoadingSettings: null
}), {
  type,
  key,
  value,
  parsedAttributes
}) => {
  switch (type) {
    case UPDATE_USER_ATTRIBUTE_ALSO_CACHED_IN_SUPERSTORE:
    case UPDATE_SUPERSTORE_USER_SETTING:
      return state.setIn(['perUser', key], value);

    case UPDATE_SUPERSTORE_PER_CONTENT_SETTING:
      return state.setIn(['perContent', key], value);

    case FETCH_USER_ATTRIBUTES_SUCCEEDED:
      return state.mergeDeepIn(['perUser'], fromJS(parsedAttributes));

    default:
      return state;
  }
};

export default locallyCachedSettingsReducer;