// Jacked from other repos, for reference:
// https://git.hubteam.com/HubSpot/WorkflowsReactUI/blob/master/static/js/utils/sanitize.js
// https://git.hubteam.com/HubSpot/outbox/blob/master/static/js/utils/SanitizeHelper.js
// https://git.hubteam.com/HubSpot/outbox/blob/master/static/js/utils/SanitizeConfiguration.js
import { memoize } from 'underscore';
import { configureSanitize } from 'ContentComponents/cmv2/sanitize/sanitizeConfig'; // @ts-expect-error no type config yet

let sanitizers = {};
let Sanitize;
let sanitizeDocument;

const initialize = () => {
  sanitizeDocument = document.implementation.createHTMLDocument('');

  try {
    sanitizeDocument.domain = window.location.hostname.indexOf('qa') >= 0 ? 'hubspotqa.com' : 'hubspot.com';
  } catch (e) {// IE11 needs to have this but it throws an error in Chrome.
  }

  Sanitize = configureSanitize(sanitizeDocument);
};

const BLACKLISTED_STYLES = ['position', 'height', 'width', 'top', 'bottom', 'left', 'right', 'z-index', 'clear', 'float', 'display', 'visibility', 'opacity', 'background', 'background-color', 'background-image', 'white-space'];
export const removeStyles = ({
  node
}) => {
  if (node && node.style && node.style.length > 0 && node.style.removeProperty) {
    BLACKLISTED_STYLES.forEach(style => node.style.removeProperty(style));
  }

  return null;
};
export const stripHtmlFromString = html => {
  return html.replace(/<[^>]+>/g, '');
};
export const clean = memoize((text, config, transformers = [removeStyles]) => {
  if (!Sanitize || !sanitizeDocument) {
    initialize();
  }

  const div = sanitizeDocument.createElement('div');
  const {
    body: element
  } = sanitizeDocument;

  if (text && text !== '') {
    if (!config) {
      config = Sanitize.Config.RELAXED;
    }

    const key = JSON.stringify(config);
    element.innerHTML = text;

    if (!sanitizers[key]) {
      sanitizers = Object.assign({}, sanitizers, {
        [key]: new Sanitize(Object.assign({}, config, {
          transformers
        }))
      });
    }

    try {
      const sanitizer = sanitizers[key];
      const fragment = sanitizer.clean_node(element);
      div.appendChild(fragment.cloneNode(true));
    } catch (err) {
      console.error(`Error sanitizing text '${text}'.`, err);
    }
  }

  element.innerHTML = '';
  return div.innerHTML;
});