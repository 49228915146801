import RowWithSortedColumns from './RowWithSortedColumns';

/*
  When cloning/creating static sections, we need to update the modules
  to new instances - and copy everything else.
*/
const createModuleInstances = (modules, staticSectionName) => {
  const processedModules = {};
  Object.values(modules).forEach(moduleValue => {
    // instance module key is static section name combined with module name
    const newModuleName = `${staticSectionName}_${moduleValue.name}`;
    const newModuleValue = Object.assign({}, moduleValue); // module_id currently not set on params BE wise.
    // But is needed in ContentEditorUI

    newModuleValue.params = Object.assign({}, newModuleValue.params, {
      module_id: newModuleValue.module_id
    });
    processedModules[newModuleName] = newModuleValue;
  });
  return processedModules;
};

const processValue = (value, newName) => {
  return {
    cssClass: value.cssClass,
    label: value.label,
    id: value.id,
    type: value.type,
    styles: value.styles,
    params: {
      fixed_layout_section_id: value.params.fixed_layout_section_id,
      id: newName,
      modules: createModuleInstances(value.params.modules, newName)
    }
  };
};

const generateStaticSectionModules = (treeRef, {
  value: staticSectionValue,
  name
}) => {
  // const staticSectionValue = this.getValue();
  if (!staticSectionValue) {
    return [];
  }

  const modules = staticSectionValue.params.modules;
  const staticSectionModules = Object.entries(modules).map(([key, value]) => {
    // const tree = this._treeRef;
    return treeRef.createNewCell({
      name: key,
      parentRowName: name,
      value
    });
  }); // Sort them, for the Sidebar Tree (plus easier to debug, and more efficient Redux)

  return staticSectionModules.sort((a, b) => {
    return b.getValue().order - a.getValue().order;
  });
};

export default class StaticSection extends RowWithSortedColumns {
  // Internal
  constructor(treeRef, {
    name,
    parentCellName,
    value
  }) {
    super(treeRef, {
      name,
      parentCellName,
      value: value && processValue(value, name)
    });
    this._staticSectionModules = [];
    this._staticSectionModules = generateStaticSectionModules(treeRef, {
      name,
      value
    });
    this._fixed_layout_section_id = value ? value.params.fixed_layout_section_id : null;
  }

  cloneAndChange({
    name,
    parentCellName,
    treeRef,
    value
  }) {
    name = name || this._name;
    parentCellName = parentCellName || this._parentCellName;
    treeRef = treeRef || this._treeRef;
    value = value || this._value;

    if (value) {
      // StaticSection params have modules
      // modules instances are:
      //  static modules name (given by dev)
      //  + name of the static section
      value = processValue(value, name);
    }

    return treeRef.createNewStaticSection({
      treeRef,
      name,
      parentCellName,
      value
    });
  }

  createFullClone(params) {
    return this.cloneAndChange(params);
  }

  isStaticSection() {
    return true;
  }

  getModuleChildren() {
    return this._staticSectionModules;
  }

  getHasModuleChildren() {
    return this._staticSectionModules.length > 0;
  }

  getId() {
    return this._fixed_layout_section_id;
  }

  hasModuleChild(name) {
    const value = this.getValue();

    if (!value) {
      return false;
    }

    const {
      modules = {}
    } = value.params;
    return Boolean(modules[name]);
  }

}