/**
 * Note: This base selector type was taken from the Reselect project.
 * @see https://github.com/reduxjs/reselect/blob/32b66389b9fbf546690f89b0ec1bb05dbf6d3463/src/types.ts#L10-L26
 *
 * A standard selector function, which takes three generic type arguments:
 *
 * @param State The first value, often a Redux root state object
 * @param Result The final result returned by the selector
 * @param Params All additional arguments passed into the selector
 */
// A way to wrap non-memoized selectors so they give stats too (when SELECTOR_STATS is true)
export const basicSelectorWithStats = basicUnmemoizedSelector => {
  // @ts-expect-error SELECTOR_STATS is a global variable
  if (!(typeof SELECTOR_STATS !== 'undefined' && SELECTOR_STATS)) {
    return basicUnmemoizedSelector;
  }

  let recomputations = 0;
  let durations = [];

  const wrappedSelector = function wrappedSelector() {
    recomputations += 1;
    const start = performance.now(); // apply arguments instead of spreading for performance.

    const result = basicUnmemoizedSelector.apply(null, // eslint-disable-next-line
    arguments);
    const end = performance.now();
    durations.push(end - start);
    return result;
  };

  wrappedSelector.resultFunc = basicUnmemoizedSelector;
  wrappedSelector.dependencies = [];

  wrappedSelector.recomputations = () => recomputations;

  wrappedSelector.resetRecomputations = () => recomputations = 0;

  wrappedSelector.durations = () => durations;

  wrappedSelector.resetDurations = () => durations = [];

  wrappedSelector.notMemoized = true;
  return wrappedSelector;
}; // A away to wrap non-memoized selectors that we don't care to collect stats on. Such as selectors
// that are trivial state getters

export const basicSelector = basicUnmemoizedSelector => {
  // @ts-expect-error SELECTOR_STATS is a global variable
  if (typeof SELECTOR_STATS !== 'undefined' && SELECTOR_STATS) {
    basicUnmemoizedSelector.ignoredForStats = true;
  }

  return basicUnmemoizedSelector;
};
export const getIsSelectorWithStats = selector => {
  if (typeof selector !== 'function') {
    return false;
  }

  const tmp = selector;
  return Array.isArray(tmp.dependencies) && typeof tmp.notMemoized === 'boolean' && typeof tmp.durations === 'function' && typeof tmp.recomputations === 'function' && typeof tmp.resetDurations === 'function' && typeof tmp.resetRecomputations === 'function' && typeof tmp.resultFunc === 'function';
};