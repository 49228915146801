const filterTinymcePlaceholderImgs = img => !img.className.includes('mce-');

export const getImagesWithEmptyAltText = images => {
  if (!images) {
    return [];
  }

  return images.filter(filterTinymcePlaceholderImgs).filter(img => {
    const {
      alt,
      role
    } = img;
    return alt === '' && role !== 'presentation';
  });
};