'use es6';

import { createSelector } from 'reselect';
export const createUndoableSelector = (selector, key) => {
  return createSelector([selector], result => {
    if (result) {
      return result.get(key);
    }

    return null;
  });
};
export const createCanUndoSelector = selector => {
  return createSelector([selector], result => {
    if (result) {
      return result.get('past').size > 0;
    }

    return false;
  });
};
export const createCanRedoSelector = selector => {
  return createSelector([selector], result => {
    if (result) {
      return result.get('future').size > 0;
    }

    return false;
  });
};
export const createLastUndoableActionTypeSelector = selector => {
  return createSelector([selector], result => {
    if (result && result.has('pastActionTypes')) {
      return result.get('pastActionTypes').last();
    }

    return '';
  });
};
export const createLastUndoneActionTypeSelector = selector => {
  return createSelector([selector], result => {
    if (result && result.has('futureActionTypes')) {
      return result.get('futureActionTypes').last();
    }

    return '';
  });
};
export const createRedoCountSelector = selector => {
  return createSelector([selector], result => {
    if (result) {
      return result.get('redoCount');
    }

    return 0;
  });
};
export const createUndoCountSelector = selector => {
  return createSelector([selector], result => {
    if (result) {
      return result.get('undoCount');
    }

    return 0;
  });
};
export const createUndoRedoCountSelector = selector => {
  return createSelector([selector], result => {
    if (result) {
      const redoCount = result.get('redoCount') || 0;
      const undoCount = result.get('undoCount') || 0;
      return undoCount + redoCount;
    }

    return 0;
  });
};