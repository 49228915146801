'use es6';

const PREFIX = 'content:edit'; // This prefix localStorage should only be used to set boolean values

export default function (key) {
  try {
    return JSON.parse(localStorage.getItem(`${PREFIX}:${key}`));
  } catch (e) {
    return true;
  }
}