import { validateApp } from 'ui-addon-upgrades/_core/common/data/upgradeData/validators/validateApp';
import { validateScreen } from 'ui-addon-upgrades/_core/common/data/upgradeData/validators/validateScreen';
import { validateUniqueId } from 'ui-addon-upgrades/_core/common/data/upgradeData/validators/validateUniqueId';
import { validateUpgradeProduct } from 'ui-addon-upgrades/_core/common/data/upgradeData/validators/validateUpgradeProduct';

const _getValidationStatus = (properties, validators) => {
  const validationStatuses = [];
  Object.keys(properties).forEach(key => {
    const validator = validators[key];

    if (!validator) {
      return true;
    }

    validationStatuses.push(validator(properties[key]));
    return undefined;
  });

  if (validationStatuses.indexOf(false) !== -1) {
    return false;
  }

  return true;
};

export default (upgradeData => {
  const PROPERTIES = {
    app: upgradeData.app,
    screen: upgradeData.screen,
    upgradeProduct: upgradeData.upgradeProduct,
    uniqueId: upgradeData.uniqueId
  };
  const PROPERTY_VALIDATORS = {
    app: validateApp,
    screen: validateScreen,
    uniqueId: validateUniqueId,
    upgradeProduct: validateUpgradeProduct
  };
  return _getValidationStatus(PROPERTIES, PROPERTY_VALIDATORS);
});