'use es6';

import { createSelector } from 'reselect';
import I18n from 'I18n';
import { getIsPublished, getPatagoniaPublishType, getPublishType } from 'ContentEditorUI/redux/selectors/publishSelectors';
import { getHasStagingDomain } from 'ContentEditorUI/redux/selectors/contentSchemaSelectors';
import { getIsSitePage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getContentModelPageExpiryDate, getContentModelPageExpiryEnabled, getPublishDate, getShouldPublishImmediately } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getCurrentDomainIsNotPermitted, getDomainExclusionReason } from 'ContentEditorUI/redux/selectors/portalDomainsSelectors';
import { getIsAllowedToPublishWithHubdb } from 'page-editor-ui/redux/selectors/pageSettingsSelectors';
import { getHasExceededMlangSitePagesLimit } from 'page-editor-ui/redux/selectors/pageLimitSelectors';
import { STATE_KEYS } from 'ContentEditorUI/lib/publishing/constants';
import * as SimpleDate from 'UIComponents/core/SimpleDate';
import { getHasLandingPagesPublishScope, getHasSitePagesPublishScope } from 'page-editor-ui/redux/selectors/authSelectors';
import { getCannotPublishOnDomainReason } from 'ContentEditorUI/utils/validationUtils';
import { getActiveDomain } from 'ContentEditorUI/redux/selectors/portalDomainsSelectors';
import cannotPublishOnDomainReasons from 'ContentEditorUI/constants/cannotPublishOnDomainReasons';
import { getIsPublishEnabledBase } from 'ContentEditorUI/redux/selectors/publishSelectors';
import { getHardSavedContentModelRequest } from 'ContentEditorUI/redux/selectors/requestStatesSelectors';
import { requestStarted, requestSucceeded } from 'redux-request-state/util/requestStateCheckers';
import { getHardSavedContentModelIsScheduled } from 'ContentEditorUI/redux/selectors/hardSavedContentModelSelectors';
import { getHardSavedContentModelIsPublishedNotScheduled } from 'ContentEditorUI/redux/selectors/hardSavedContentModelSelectors';
import { getHasAccountTrustScope, getHasPagesSchedulingUpdateAccess, getHasBlogListingPagesWriteScope, getIsUngatedForPatagonia } from 'ContentEditorUI/redux/selectors/authSelectors';
import { getAccountVerificationStatus } from 'ContentEditorUI/redux/selectors/accountVerificationStatusSelectors';
import { getIsBlogListingPage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getDomainExclusionReasonRequest } from 'ContentEditorUI/redux/selectors/requestStatesSelectors'; // Checks that hard-saved page (not the buffer) is in the scheduled state

export const getIsHardSavedContentScheduledToPublish = createSelector([getHardSavedContentModelRequest, getHardSavedContentModelIsScheduled], (hardSavedContentModelRequest, hardSavedContentModelIsScheduled) => {
  if (!requestStarted(hardSavedContentModelRequest)) {
    return !!hardSavedContentModelIsScheduled;
  }

  return false;
});
export const getIsLive = createSelector([getIsPublished, getIsHardSavedContentScheduledToPublish], (isPublished, isPageScheduled) => {
  return isPublished && !isPageScheduled;
});
export const getMaxPublishDateFromExpiryDate = createSelector([getContentModelPageExpiryDate, getContentModelPageExpiryEnabled], (pageExpiryDate, pageExpiryEnabled) => {
  if (pageExpiryEnabled && pageExpiryDate) {
    return SimpleDate.fromMoment(I18n.moment(pageExpiryDate));
  }

  return null;
}); // This according to the publish state of the hard-saved page

export const getPublishStateKey = createSelector([getIsPublished, getIsHardSavedContentScheduledToPublish], (isPublished, isScheduledToPublish) => {
  if (isScheduledToPublish) {
    return STATE_KEYS.SCHEDULED;
  } else if (isPublished) {
    return STATE_KEYS.PUBLISHED;
  }

  return STATE_KEYS.DRAFT;
});
export const getHasPermissionsToPublish = createSelector([getIsSitePage, getHasLandingPagesPublishScope, getHasSitePagesPublishScope, getCurrentDomainIsNotPermitted, getHasAccountTrustScope, getHasBlogListingPagesWriteScope, getIsBlogListingPage], (isSitePage, hasPermissionsToPublishLandingPage, hasPermissionsToPublishSitePage, currentDomainIsNotPermitted, hasAccountTrust, hasBlogListingPagesWriteScope, isBlogListingPage) => {
  let userPermission;

  if (isSitePage) {
    userPermission = hasPermissionsToPublishSitePage;
  } else if (isBlogListingPage) {
    userPermission = hasBlogListingPagesWriteScope;
  } else {
    userPermission = hasPermissionsToPublishLandingPage;
  }

  return userPermission && hasAccountTrust && !currentDomainIsNotPermitted;
});
export const getIsSchedulingAlreadyPublished = createSelector([getShouldPublishImmediately, getHardSavedContentModelIsPublishedNotScheduled, getHasPagesSchedulingUpdateAccess], (publishImmediately, hardSavedContentModelIsPublishedNotScheduled, hasPagesSchedulingUpdateAccess) => !publishImmediately && hardSavedContentModelIsPublishedNotScheduled && !hasPagesSchedulingUpdateAccess);
export const getPublishValidationErrors = createSelector([getPublishDate, getShouldPublishImmediately, getIsSchedulingAlreadyPublished, getHasAccountTrustScope, getAccountVerificationStatus, getIsUngatedForPatagonia], (publishDate, publishImmediately, isSchedulingAlreadyPublished, hasAccountTrust, accountVerificationStatus, isUngatedForPatagonia) => {
  const validationErrors = {};

  if (!publishImmediately && !publishDate) {
    validationErrors.publishDate = {
      pane: 'publishing',
      type: 'required',
      messageKey: 'publish.scheduleRequiredDateError'
    };
  }

  if (isSchedulingAlreadyPublished && !isUngatedForPatagonia) {
    validationErrors.isSchedulingAlreadyPublished = {
      pane: 'publishing',
      type: 'isSchedulingAlreadyPublished',
      messageKey: 'publish.cannotSchedulePublishedPage',
      target: 'publishDate'
    };
  }

  if (!hasAccountTrust) {
    validationErrors.hasLowAccountTrust = {
      pane: 'publishing',
      type: 'accountVerificationSuspended',
      messageKey: `accountVerification.${accountVerificationStatus}.actionTooltip`
    };
  }

  return validationErrors;
});
export const getIsPublishEnabled = createSelector([getIsPublishEnabledBase, getHasPermissionsToPublish, getIsAllowedToPublishWithHubdb, getHasExceededMlangSitePagesLimit], (isPublishEnabledBase, hasPermissionsToPublish, isAllowedToPublishWithHubdb, hasExceededMlangSitePagesLimit) => {
  return Boolean(isPublishEnabledBase && hasPermissionsToPublish && isAllowedToPublishWithHubdb && !hasExceededMlangSitePagesLimit);
});
export const getIsUnpublishEnabled = createSelector([getIsPublished, getHasPermissionsToPublish, getIsBlogListingPage], (isPublished, hasPermissionsToPublish, isBlogListingPage) => isPublished && hasPermissionsToPublish && !isBlogListingPage);
export const getPublishButtonAction = createSelector([getHasPermissionsToPublish, getPublishType], (hasPermissionsToPublish, publishType) => {
  if (hasPermissionsToPublish) {
    return publishType;
  }

  return 'notAuthorized';
});
export const getPatagoniaPublishButtonAction = createSelector([getHasPermissionsToPublish, getPatagoniaPublishType], (hasPermissionsToPublish, patagoniaPublishType) => {
  if (!patagoniaPublishType) return null;

  if (hasPermissionsToPublish) {
    return patagoniaPublishType;
  }

  return 'notAuthorized';
});
export const getPublishButtonTextKey = createSelector([getPublishButtonAction, getHasStagingDomain, getHasPermissionsToPublish, getPatagoniaPublishButtonAction], (publishButtonAction, hasStagingDomain, hasPermissionsToPublish, patagoniaPublishButtonAction) => {
  const buttonAction = patagoniaPublishButtonAction || publishButtonAction;

  if (hasStagingDomain && hasPermissionsToPublish) {
    return `header.publishButton.staging.${buttonAction}.label`;
  }

  return `header.publishButton.${buttonAction}.label`;
}); // Since it is for PQL, ignores permissions so it just shows the publish action name

export const getLockedPublishButtonTextKey = createSelector([getHasStagingDomain, getPatagoniaPublishType, getPublishType], (hasStagingDomain, patagoniaPublishType, legacyPublishType) => {
  const publishType = patagoniaPublishType || legacyPublishType;

  if (hasStagingDomain) {
    return `header.publishButton.staging.${publishType}.label`;
  }

  return `header.publishButton.${publishType}.label`;
});
export const getPublishOptionsModalButtonTextKey = createSelector([getPublishButtonAction, getPublishStateKey, getShouldPublishImmediately], (publishButtonAction, publishStateKey, shouldPublishImmediately) => {
  if (shouldPublishImmediately) {
    return `header.publishButton.modal.button.${publishStateKey}.${publishButtonAction}`;
  }

  return `header.publishButton.modal.button.${publishStateKey}.schedule`;
});
export const getPublishOptionsModalHeadingTextKey = createSelector([getPublishButtonAction, getPublishStateKey, getShouldPublishImmediately], (publishButtonAction, publishStateKey, shouldPublishImmediately) => {
  if (shouldPublishImmediately) {
    return `publishOptionsModal.heading.${publishStateKey}.${publishButtonAction}`;
  }

  return `publishOptionsModal.heading.${publishStateKey}.schedule`;
});
export const getCannotPublishOnActiveDomainReason = createSelector([getHasPermissionsToPublish, getActiveDomain, getIsSitePage, getHasLandingPagesPublishScope, getHasSitePagesPublishScope, getHasAccountTrustScope, getCurrentDomainIsNotPermitted, getDomainExclusionReason], (hasPermissionsToPublish, activeDomain, isSitePage, hasLandingPagesPublishScope, hasSitePagesPublishScope, hasAccountTrust, domainIsNotPermitted, domainExclusionReason) => {
  if (hasPermissionsToPublish || !isSitePage && !hasAccountTrust) {
    return '';
  }

  const contentTypeStr = isSitePage ? 'SitePage' : 'LandingPage';
  const userHasPublishAccess = isSitePage ? hasSitePagesPublishScope : hasLandingPagesPublishScope;
  return getCannotPublishOnDomainReason({
    domain: activeDomain,
    domainIsNotPermitted,
    domainExclusionReason,
    contentTypeStr,
    userHasPublishAccess
  });
});
export const getCannotPublishReasonShouldAllowSwitchDomain = createSelector([getCannotPublishOnActiveDomainReason, getDomainExclusionReasonRequest, getCurrentDomainIsNotPermitted], (cannotPublishOnDomainReason, domainExclusionReasonRequest, currentDomainIsNotPermitted) => !currentDomainIsNotPermitted || requestSucceeded(domainExclusionReasonRequest) && (!cannotPublishOnDomainReason || cannotPublishOnDomainReason !== cannotPublishOnDomainReasons.USER_HAS_NO_PUBLISH_ACCESS && cannotPublishOnDomainReason !== cannotPublishOnDomainReasons.NO_DOMAIN_PERMISSION));
export const getIsSchedulingUpdate = createSelector([getShouldPublishImmediately, getHardSavedContentModelIsPublishedNotScheduled, getHasPagesSchedulingUpdateAccess], (publishImmediately, hardSavedContentModelIsPublishedNotScheduled, hasPagesSchedulingUpdateAccess) => hasPagesSchedulingUpdateAccess && !publishImmediately && hardSavedContentModelIsPublishedNotScheduled);