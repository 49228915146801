import { combineReducers } from 'redux';
import { createGenericRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
import * as ActionTypes from 'ContentData/actions/ActionTypes';
import SurveyTypes from 'ContentUtils/constants/SurveyTypes';
import * as RequestStatus from 'ContentUtils/constants/RequestStatus';
const {
  SURVEYS_FETCH_SURVEY,
  SURVEYS_FETCH,
  SURVEYS_CLEAR_CACHE,
  SURVEYS_SAVE_SURVEY_CACHE,
  SURVEYS_UPDATE_SURVEY_STATE
} = ActionTypes;
const INITIAL_STATE = {
  [SurveyTypes.CES]: {}
};

function surveys(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SURVEYS_UPDATE_SURVEY_STATE:
    case SURVEYS_FETCH_SURVEY:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [response.surveyType]: Object.assign({}, state[response.surveyType], {
            [response.id]: response
          })
        });
      }

    case SURVEYS_SAVE_SURVEY_CACHE:
      {
        const {
          survey
        } = action;
        if (!survey) return state;
        return Object.assign({}, state, {
          [survey.surveyType]: Object.assign({}, state[survey.surveyType], {
            [survey.id]: survey
          })
        });
      }

    default:
      return state;
  }
}

function surveysCache(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SURVEYS_FETCH:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return response.objects.reduce((hash, survey) => {
          hash[survey.surveyType] = Object.assign({
            [survey.id]: survey
          }, hash[survey.surveyType]);
          return hash;
        }, Object.assign({}, state));
      }

    case SURVEYS_UPDATE_SURVEY_STATE:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [response.surveyType]: Object.assign({}, state[response.surveyType], {
            [response.id]: response
          })
        });
      }

    case SURVEYS_CLEAR_CACHE:
      {
        if (Object.keys(state).length) return INITIAL_STATE;
        return state;
      }

    default:
      return state;
  }
}

function surveysCacheRequestStatus(state = RequestStatus.UNINITIALIZED, action) {
  switch (action.type) {
    case SURVEYS_FETCH:
      if (action.response) return RequestStatus.SUCCEEDED;
      if (action.error) return RequestStatus.FAILED;
      return RequestStatus.PENDING;

    default:
      return state;
  }
}

export default combineReducers({
  surveys,
  requestStatus: createGenericRequestStatusReducer(SURVEYS_FETCH_SURVEY),
  surveysCache,
  surveysCacheRequestStatus
});