import { isEmpty } from 'underscore';
import { createGenericOptionsSelector, tryParseNumber } from 'ContentData/helpers/reduxHelpers';
import { DISPLAY_COLUMN_EXCLUSIONS } from 'ContentUtils/constants/HubDbColumnTypes';
import { getRowDisplayFormat } from 'ContentUtils/helpers/HubDbHelpers';
import { ColumnTypes } from 'ContentUtils/constants/HubDbColumnTypes';
// Helpers
export const getColumnsByName = table => {
  const builtInColumns = table.useForPages ? {
    hs_name: {
      id: null,
      type: ColumnTypes.TEXT
    },
    hs_path: {
      id: null,
      type: ColumnTypes.URL
    }
  } : {};
  return table.columns.reduce((acc, col) => {
    const {
      id,
      name,
      type
    } = col;

    if (!DISPLAY_COLUMN_EXCLUSIONS[type]) {
      acc[name] = {
        id,
        type
      };
    }

    return acc;
  }, builtInColumns);
};

const getRowDisplayTextArray = (row, columnsByName, displayColumns, displayFormat) => {
  if (!(row && row.values)) return '';
  let hasNoContent = true;
  const columnDelimeter = /(%\d+)/;
  const numberOfDisplayColumns = displayColumns && displayColumns.length;
  const optionArray = getRowDisplayFormat(displayFormat, numberOfDisplayColumns).split(columnDelimeter);
  const columns = numberOfDisplayColumns ? displayColumns : [Object.keys(columnsByName)[0]];
  const optionLabelArray = optionArray.reduce((labelDataArray, frag) => {
    let hubDbContent = frag ? {
      text: frag
    } : null;

    if (columnDelimeter.test(frag)) {
      const columnNameIndex = parseInt(frag.substring(1), 10);
      const column = columnsByName[columns[columnNameIndex]];
      let content;

      if (column) {
        if (column.id) {
          content = row.values[column.id];
        } else if (column.type === ColumnTypes.URL) {
          content = row.path;
        } else {
          content = row.name;
        }
      }

      hubDbContent = content !== undefined // content can be the number 0
      ? {
        columnType: column.type,
        text: content
      } : null;
      if (hubDbContent !== null) hasNoContent = false;
    }

    if (hubDbContent !== null) labelDataArray.push(hubDbContent);
    return labelDataArray;
  }, []);
  return hasNoContent ? [] : optionLabelArray;
};

const disabledPredicate = table => table && table.publishedAt === 0;

const getTableDisplayText = table => table && (table.label || table.name) || undefined; // Selectors


export const getHubDbTables = state => state.resources.hubdb.tables.byId;
export const getHubDbTable = (state, props) => isNaN(props.id) ? state.resources.hubdb.tables.byName[props.id] : state.resources.hubdb.tables.byId[props.id];
export const getHubDbTableCache = (state, props) => state.resources.hubdb.tablesCache[props.id];

const getHubDbTablesCache = state => state.resources.hubdb.tablesCache;

export const getHubDbTableFetchStatus = (state, props) => state.resources.hubdb.tableRequestStatus[props.id];
export const getIsUnpublished = (state, props) => disabledPredicate(getHubDbTable(state, props));
export const getIsDeleted = (state, props) => {
  const hubDbTable = getHubDbTable(state, props);
  return hubDbTable && hubDbTable.deleted;
};

const getHubDbRow = (state, props) => {
  const savedRowsTable = state.resources.hubdb.rows[props.tableId];
  if (!isEmpty(savedRowsTable)) return savedRowsTable[props.rowId];
  return {};
};

export const getHubDbTableOptions = createGenericOptionsSelector(getHubDbTablesCache, {
  disabledPredicate,
  getDisplayText: getTableDisplayText
});
export const getDefaultHubDbTableOptions = createGenericOptionsSelector(getHubDbTables, {
  disabledPredicate,
  getDisplayText: getTableDisplayText
});
export const getDisplayDataFromProps = (state, props) => {
  return {
    displayColumns: props.displayColumns,
    displayFormat: props.displayFormat
  };
};
export const getHubDbRows = (state, props) => {
  return state.resources.hubdb.rows[props.id] || {};
};

const getHubDbRowsCache = (state, props) => {
  return state.resources.hubdb.rowsCache[props.id] || {};
};

export const getHubDbCacheRequestStatus = state => state.resources.hubdb.hubDbCacheRequestStatus;
export const getCachedHubDbRow = (state, props) => {
  const cachedRowsTable = state.resources.hubdb.rowsCache[props.tableId];
  if (!isEmpty(cachedRowsTable)) return cachedRowsTable[props.rowId];
  return {};
};
export const getHubDbRowFetchStatus = (state, props) => state.resources.hubdb.rowRequestStatus[props.id];
export const getHubDbRowOptions = (state, props) => {
  const hubDbTable = getHubDbTable(state, props);
  const hubDbRowsCache = getHubDbRowsCache(state, props);
  const displayData = getDisplayDataFromProps(state, props);
  const columnsByName = hubDbTable ? getColumnsByName(hubDbTable) : {};
  const {
    displayColumns,
    displayFormat
  } = displayData;
  return Object.keys(hubDbRowsCache).map(rowId => {
    const hubDbRow = hubDbRowsCache[rowId];
    return {
      value: tryParseNumber(rowId),
      textArray: getRowDisplayTextArray(hubDbRow, columnsByName, displayColumns, displayFormat)
    };
  });
};
export const getDefaultHubDbRowOptions = (state, props) => {
  const hubDbTable = getHubDbTable(state, props);
  const hubDbRows = getHubDbRows(state, props);
  const displayData = getDisplayDataFromProps(state, props);
  const columnsByName = hubDbTable ? getColumnsByName(hubDbTable) : {};
  const {
    displayColumns,
    displayFormat
  } = displayData;
  return Object.keys(hubDbRows).map(rowId => {
    const hubDbRow = hubDbRows[rowId];
    return {
      value: tryParseNumber(rowId),
      textArray: getRowDisplayTextArray(hubDbRow, columnsByName, displayColumns, displayFormat)
    };
  });
};
export const getHubDbRowLabel = (state, props) => {
  const hubDbTable = getHubDbTable(state, props);
  const columnsByName = hubDbTable ? getColumnsByName(hubDbTable) : {};
  const hubDbRowProps = Object.assign({
    tableId: hubDbTable && hubDbTable.id
  }, props);
  const hubDbRow = getHubDbRow(state, hubDbRowProps) || {};
  const labelArray = getRowDisplayTextArray(hubDbRow, columnsByName, props.displayColumns, props.displayFormat);
  return labelArray && labelArray.length ? labelArray : hubDbRow.id && hubDbRow.id.toString();
};