import { getLatestArtifact } from 'redux-request-state/selectors/artifactSelectors';
import * as requestKeys from 'ContentEditorUI/utils/requestStatesKeys';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
export const buildRequestStateSelector = requestKey => basicSelector(state => getLatestArtifact(state, {
  requestKey
}));
export const getContentSaveRequest = buildRequestStateSelector(requestKeys.CONTENT_SAVE_REQUEST);
export const getContentPublishRequest = buildRequestStateSelector(requestKeys.CONTENT_PUBLISH_REQUEST);
export const getContentSchemaRequest = buildRequestStateSelector(requestKeys.FETCH_CONTENT_SCHEMA_REQUEST);
export const getModuleSchemasRequest = buildRequestStateSelector(requestKeys.FETCH_MODULE_SCHEMAS_REQUEST);
export const getTemplateSchemaRequest = buildRequestStateSelector(requestKeys.FETCH_TEMPLATE_SCHEMA_REQUEST);
export const getSupportedLanguagesRequest = buildRequestStateSelector(requestKeys.FETCH_SUPPORTED_LANGUAGES_REQUEST);
export const getLayoutRequest = buildRequestStateSelector(requestKeys.FETCH_LAYOUT_DATA_REQUEST);
export const getTemplateInfoRequest = buildRequestStateSelector(requestKeys.FETCH_TEMPLATE_INFO_REQUEST);
export const getRevisionsRequest = buildRequestStateSelector(requestKeys.FETCH_REVISIONS_REQUEST);
export const getScheduledVersionRequest = buildRequestStateSelector(requestKeys.FETCH_SCHEDULED_VERSION_REQUEST);
export const getThemeRequest = buildRequestStateSelector(requestKeys.FETCH_THEME_REQUEST);
export const getEditableSlugRequest = buildRequestStateSelector(requestKeys.FETCH_EDITABLE_SLUG_INFO_REQUEST);
export const getPortalScopesRequest = buildRequestStateSelector(requestKeys.FETCH_PORTAL_SCOPES_REQUEST);
export const getMultiDomainPublishingScopeChangeRequest = buildRequestStateSelector(requestKeys.FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_REQUEST);
export const getHardSavedContentModelRequest = buildRequestStateSelector(requestKeys.FETCH_HARD_SAVED_CONTENT_MODEL_REQUEST);
export const getUsersRequest = buildRequestStateSelector(requestKeys.FETCH_USERS_REQUEST);
export const getInitialUserAttributesRequest = buildRequestStateSelector(requestKeys.FETCH_INITIAL_USER_ATTRIBUTES_REQUEST);
export const getModuleErrorsRequest = buildRequestStateSelector(requestKeys.FETCH_MODULE_ERRORS_REQUEST);
export const getCustomDomainsPurgeDateRequest = buildRequestStateSelector(requestKeys.FETCH_CUSTOM_DOMAINS_PURGE_REQUEST);
export const getSitePagesPasswordProtectionPurgeDateRequest = buildRequestStateSelector(requestKeys.FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST);
export const getLandingPagesPasswordProtectionPurgeDateRequest = buildRequestStateSelector(requestKeys.FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST);
export const getAudienceAccessPurgeDateRequest = buildRequestStateSelector(requestKeys.FETCH_AUDIENCE_ACCESS_PURGE_DATE_REQUEST);
export const getDynamicPageAccessPurgeDateRequest = buildRequestStateSelector(requestKeys.FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_REQUEST);
export const getMarketerSectionsRequest = buildRequestStateSelector(requestKeys.FETCH_MARKETER_SECTIONS_REQUEST);
export const getDomainExclusionReasonRequest = buildRequestStateSelector(requestKeys.FETCH_DOMAIN_EXCLUSION_REASON);
export const getFetchGeneratedHublRequest = buildRequestStateSelector(requestKeys.FETCH_GENERATED_LAYOUT_HUBL_REQUEST);
export const getFetchCustomSectionsRequest = buildRequestStateSelector(requestKeys.FETCH_CUSTOM_SECTIONS_REQUEST);
export const getFetchCmsSettingsRequest = buildRequestStateSelector(requestKeys.FETCH_CMS_SETTINGS_REQUEST);