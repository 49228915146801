'use es6';

import events from 'ui-addon-opt/events.yaml';
import { OPT_CHOICE_HUB_OPT_IN, OPT_CHOICE_HUB_OPT_OUT, OPT_CHOICE_USER_OPT_IN, OPT_CHOICE_USER_OPT_OUT } from 'ui-addon-opt/state/OptState';
const OPTIN_INTERACTION = 'optin-interaction';
const OPTIN_VIEW = 'optin-view';
const LEARN_MORE_CLICK = 'learnMoreClick';
const FEEDBACK_PROVIDED = 'feedbackProvided';
const OPTED_IN = 'opted in';
const OPTED_OUT = 'opted out';
const FEEDBACK_CLICKED = 'clicked to submit feedback';
const ARTICLE_CLICKED = 'clicked to view article';
const ACTIONS = {
  [FEEDBACK_PROVIDED]: FEEDBACK_CLICKED,
  [LEARN_MORE_CLICK]: ARTICLE_CLICKED,
  [OPT_CHOICE_HUB_OPT_IN]: OPTED_IN,
  [OPT_CHOICE_HUB_OPT_OUT]: OPTED_OUT,
  [OPT_CHOICE_USER_OPT_IN]: OPTED_IN,
  [OPT_CHOICE_USER_OPT_OUT]: OPTED_OUT
};
export const emptyTracker = {
  clone: () => emptyTracker,
  track: () => {}
};
export const cloneTracker = tracker => ({
  standard: tracker.clone({
    events
  }),
  immediate: tracker.clone({
    events,
    isBeforeUnload: true
  })
});
export const trackOptView = (usageTracker, name) => {
  usageTracker.standard.track(OPTIN_VIEW, {
    name
  });
};
export const trackOptAction = (usageTracker, name, choice) => {
  usageTracker.immediate.track(OPTIN_INTERACTION, {
    name,
    action: ACTIONS[choice]
  });
};
export const trackLearnMoreClick = (usageTracker, name) => {
  usageTracker.standard.track(OPTIN_INTERACTION, {
    name,
    action: ACTIONS[LEARN_MORE_CLICK]
  });
};
export const trackFeedbackSurveyClick = (usageTracker, name) => {
  usageTracker.standard.track(OPTIN_INTERACTION, {
    name,
    action: ACTIONS[FEEDBACK_PROVIDED]
  });
};