import { iterateFrom, collectAllDescendentRowsAndCells } from './helpers';
import { CELL_TYPE } from '../LayoutDataTree/constants';
import Cell from './Cell';
import Row from './Row';
// immutable swap of value in array
export const mapAndSwapValue = (array, oldValue, newValue) => {
  return array.map(value => value === oldValue ? newValue : value);
};
export const getCellOrRow = (tree, cellOrRowName) => {
  if (tree.hasCell(cellOrRowName)) {
    return tree.findCell(cellOrRowName);
  } else {
    return tree.findRow(cellOrRowName);
  }
};
export const getChildrenNames = cellOrRow => {
  if (cellOrRow instanceof Cell) {
    return cellOrRow.getRowNames();
  } else {
    return cellOrRow.getColumnNames();
  }
};
export const getChildren = cellOrRow => {
  if (cellOrRow instanceof Cell) {
    return cellOrRow.getRows();
  } else {
    return cellOrRow.getColumns();
  }
};
export const getNumberChildren = cellOrRow => {
  if (cellOrRow instanceof Cell) {
    return cellOrRow.getNumberRows();
  } else {
    return cellOrRow.getNumberColumns();
  }
};
export const assertIsValidCell = cell => {
  const cellErrors = cell._treeRef.isInvalidCell(cell);

  if (cellErrors) {
    throw new Error(`Invalid cell: ${cellErrors.join(', ')}`);
  }
};
export const assertIsValidRow = row => {
  const rowErrors = row._treeRef.isInvalidRow(row);

  if (rowErrors) {
    throw new Error(`Invalid row: ${rowErrors.join(', ')}`);
  }
};
export const remapNodeToTree = (nodeInEarlierTreeVersion, newTree) => {
  return nodeInEarlierTreeVersion.isCell() ? newTree.findCell(nodeInEarlierTreeVersion.getName()) : newTree.findRow(nodeInEarlierTreeVersion.getName());
};
export const remapNodesToTree = (nodesInEarlierTreeVersion, newTree) => {
  return nodesInEarlierTreeVersion.map(node => {
    return remapNodeToTree(node, newTree);
  });
};

const maybeUpdateTreeRefForCellOrRow = (cellOrRow, treeRef) => {
  if (cellOrRow._treeRef !== treeRef) {
    return cellOrRow.cloneAndChange({
      treeRef
    });
  }

  return cellOrRow;
};

export const cloneAndModifyState = (treeRef, {
  modifiedColumns,
  modifiedRows,
  deletedColumns,
  deletedRows,
  nameCtr,
  validate = true
}) => {
  const stateClone = treeRef.stateSnapshot();
  const isTemporaryMutableTree = treeRef._isTemporaryMutableTree === true;
  const setOfDeletedColumnNames = new Set();
  const setOfDeletedRowNames = new Set();

  if (nameCtr != null) {
    stateClone.nameCtr = nameCtr;
  }

  if (modifiedColumns || deletedColumns) {
    if (modifiedColumns) {
      for (let col of modifiedColumns) {
        if (isTemporaryMutableTree) {
          col = maybeUpdateTreeRefForCellOrRow(col, treeRef);
        }

        stateClone.columnsByName[col.getName()] = col;
      }
    }

    if (deletedColumns) {
      for (const col of deletedColumns) {
        setOfDeletedColumnNames.add(col.getName());
        delete stateClone.columnsByName[col.getName()];
      }
    }
  }

  if (modifiedRows || deletedRows) {
    if (modifiedRows) {
      for (let row of modifiedRows) {
        if (isTemporaryMutableTree) {
          row = maybeUpdateTreeRefForCellOrRow(row, treeRef);
        }

        stateClone.rowsByName[row.getName()] = row;
      }
    }

    if (deletedRows) {
      for (const row of deletedRows) {
        setOfDeletedRowNames.add(row.getName());
        delete stateClone.rowsByName[row.getName()];
      }
    }
  } // Trying out an optimization where you can explicitly make a temporary mutable tree that does _not_
  // have to do a clone/validation on every little operation


  if (isTemporaryMutableTree) {
    treeRef._state = stateClone;
    return treeRef;
  }

  const clonedTree = treeRef.clone(stateClone); // Validate here, where we know exactly what has changed as compared to the previous version of the tree

  if (validate) {
    if (modifiedColumns && clonedTree.isInvalidCell) {
      for (const modifiedCell of modifiedColumns) {
        if (!setOfDeletedColumnNames.has(modifiedCell.getName())) {
          const remapedCell = remapNodeToTree(modifiedCell, clonedTree);

          if (remapedCell instanceof Cell) {
            assertIsValidCell(remapedCell);
          }
        }
      }
    }

    if (modifiedRows && clonedTree.isInvalidRow) {
      for (const modifiedRow of modifiedRows) {
        if (!setOfDeletedRowNames.has(modifiedRow.getName())) {
          const remapedRow = remapNodeToTree(modifiedRow, clonedTree);

          if (remapedRow instanceof Row) {
            assertIsValidRow(remapedRow);
          }
        }
      }
    }
  }

  return clonedTree;
};
export const concatenateName = (prefix, nameCtr) => {
  return `${prefix}-name-${nameCtr}`;
};
export const isGeneratedName = nameStr => {
  return /^\w+-name-\d+/.test(nameStr);
};
export const extractIntFromGeneratedName = nameStr => {
  return parseInt(nameStr.split('-')[2], 10);
};
export const newBlankSlate = (treeRef, {
  rootName
}) => {
  rootName = rootName || concatenateName('root', 1);
  return {
    columnsByName: {
      [rootName]: treeRef.createNewCell({
        name: rootName
      })
    },
    rowsByName: {},
    rootCellName: rootName,
    nameCtr: 2
  };
};

const cloneStaticSectionHelper = ({
  tree,
  node,
  newName,
  parentName
}) => {
  const clonedNode = node.cloneAndChange({
    name: newName,
    parentCellName: parentName
  });
  return {
    clonedNode,
    tree
  };
};

const cloneRowHelper = ({
  tree,
  node,
  newName,
  parentName,
  mapOfOldNameToClonedName
}) => {
  const newColumnNames = node.getColumns().map(oldNode => {
    const {
      newName: newColumnName,
      tree: newTree
    } = tree._nextCellName({
      newCellValue: oldNode.getValue()
    });

    mapOfOldNameToClonedName[oldNode.getName()] = newColumnName;
    tree = newTree;
    return newColumnName;
  });
  const clonedNode = node.cloneAndChange({
    name: newName,
    parentCellName: parentName,
    columnNames: newColumnNames
  });
  return {
    clonedNode,
    tree
  };
};

const cloneColumnHelper = ({
  tree,
  node,
  newName,
  parentName,
  mapOfOldNameToClonedName,
  stripSmartContentData
}) => {
  const newRowNames = node.getRowNames().map(oldName => {
    const {
      newName: newRowName,
      tree: newTree
    } = tree._nextRowName();

    mapOfOldNameToClonedName[oldName] = newRowName;
    tree = newTree;
    return newRowName;
  });
  const clonedNode = node.createFullClone({
    name: newName,
    parentRowName: parentName,
    rowNames: newRowNames,
    value: Object.assign({}, node.getValue(), {
      id: newName
    })
  }, {
    stripSmartContentData
  });
  return {
    clonedNode,
    tree
  };
}; // Helper that clones a node and all of its children, including iterating over all nested columns/rows
// and the nodeToClone to create new ID for each of them.


export const cloneSubtree = (tree, nodeToClone, stripSmartContentData) => {
  const modifiedRows = [];
  const modifiedColumns = [];
  const mapOfOldNameToClonedName = {};
  const cloneIdResult = nodeToClone.isRow() ? tree._nextRowName() : tree._nextCellName({
    newCellValue: nodeToClone.getValue()
  });
  tree = cloneIdResult.tree;
  mapOfOldNameToClonedName[nodeToClone.getName()] = cloneIdResult.newName;
  const helperFunc = nodeToClone.isRow() ? cloneRowHelper : cloneColumnHelper;
  const subtreeRootCloneResult = helperFunc({
    tree,
    node: nodeToClone,
    newName: cloneIdResult.newName,
    parentName: nodeToClone.getParentName(),
    mapOfOldNameToClonedName,
    stripSmartContentData
  });
  tree = subtreeRootCloneResult.tree;
  const clonedNode = subtreeRootCloneResult.clonedNode; // Static sections do not need to be iterated over -
  // the static section modules will never change position, or names - just their values/params
  // This is making the assumption that StaticSections are top level!
  // If that functionality changes - will need to update this code.

  if (clonedNode.isStaticSection()) {
    const staticSectionResult = cloneStaticSectionHelper({
      tree,
      node: clonedNode,
      newName: mapOfOldNameToClonedName[clonedNode.getName()],
      parentName: mapOfOldNameToClonedName[clonedNode.getParentName()]
    });
    tree = staticSectionResult.tree;
    modifiedRows.push(staticSectionResult.clonedNode);
  } else {
    iterateFrom(nodeToClone, ({
      cell,
      row
    }) => {
      if (cell) {
        const columnResult = cloneColumnHelper({
          tree,
          node: cell,
          newName: mapOfOldNameToClonedName[cell.getName()],
          parentName: mapOfOldNameToClonedName[cell.getParentName()],
          mapOfOldNameToClonedName,
          stripSmartContentData
        });
        tree = columnResult.tree;
        modifiedColumns.push(columnResult.clonedNode);
      } else if (row) {
        const rowResult = cloneRowHelper({
          tree,
          node: row,
          newName: mapOfOldNameToClonedName[row.getName()],
          parentName: mapOfOldNameToClonedName[row.getParentName()],
          mapOfOldNameToClonedName
        });
        tree = rowResult.tree;
        modifiedRows.push(rowResult.clonedNode);
      }
    }, {
      includeStart: false
    });
  } // Flip the mapOfOldNameToClonedName map so it is easier to consume


  const mapOfClonedToOldNodeName = {};
  Object.keys(mapOfOldNameToClonedName).forEach(oldName => {
    const newName = mapOfOldNameToClonedName[oldName];
    mapOfClonedToOldNodeName[newName] = oldName;
  });
  return {
    tree,
    clonedNode,
    modifiedColumns,
    modifiedRows,
    mapOfClonedToOldNodeName
  };
};
export const pathToFirstAncestorWithSiblingsToDelete = (cellOrRow, {
  pathToAncestor = [],
  ancestorToNotAutoDelete
} = {}) => {
  const parentCellOrRow = cellOrRow.getParent();
  const siblingNames = parentCellOrRow instanceof Row ? parentCellOrRow.getColumnNames() : parentCellOrRow.getRowNames();
  pathToAncestor.push(cellOrRow.getName());

  if (!parentCellOrRow.isRoot() && parentCellOrRow.getName() !== ancestorToNotAutoDelete && siblingNames.length === 1 && siblingNames[0] === cellOrRow.getName() && parentCellOrRow.shouldAutoDeleteWhenEmpty()) {
    return pathToFirstAncestorWithSiblingsToDelete(parentCellOrRow, {
      pathToAncestor,
      ancestorToNotAutoDelete
    });
  }

  return {
    ancestor: cellOrRow,
    pathToAncestor
  };
};

const rowWithoutColumn = (row, cellNameToRemove) => {
  return row.cloneAndChange({
    columnNames: row.getColumnNames().filter(c => c !== cellNameToRemove)
  });
};

const cellWithoutRow = (cell, rowNameToRemove) => {
  return cell.cloneAndChange({
    rowNames: cell.getRowNames().filter(r => r !== rowNameToRemove)
  });
};

export const removeCellOrRow = (treeRef, cellOrRowToDelete, {
  automaticallyDeleteEmptyParents,
  automaticallyDeleteDescendents = true,
  ancestorToNotAutoDelete,
  preventEmptiedRows
} = {}) => {
  const originalCellOrRowToDelete = cellOrRowToDelete;
  let deletedRows = [];
  let deletedColumns = [];
  let modifiedRows;
  let modifiedColumns; // Delete the specified row/column and all of its descendents if requested

  if (automaticallyDeleteDescendents === true) {
    ({
      descendentRows: deletedRows,
      descendentColumns: deletedColumns
    } = collectAllDescendentRowsAndCells(cellOrRowToDelete));
  } // If the only "child" row/cell of the parent is the row/cell being deleted, then automatically
  // delete up to the last ancescestors that has more than one "child" (except the root)


  if (automaticallyDeleteEmptyParents) {
    const {
      ancestor,
      pathToAncestor
    } = pathToFirstAncestorWithSiblingsToDelete(cellOrRowToDelete, {
      ancestorToNotAutoDelete
    });
    cellOrRowToDelete = ancestor; // The first element of the array is always the ancestor, take that off (since cellOrRowToDelete
    // will add it to the deleted arrays later)

    pathToAncestor.pop(); // And reverse the array so we go "top-down" instead of "bottom-up" since other code depends on
    // the deleted arrays being ordered

    pathToAncestor.reverse(); // If we actually needed to traverse up past any empty ancestors, make sure to delete those
    // even when `automaticallyDeleteDescendents` is false

    if (pathToAncestor.length > 0) {
      for (const rowOrCellName of pathToAncestor) {
        const rowOrCell = getCellOrRow(treeRef, rowOrCellName);

        if (rowOrCell.isRow()) {
          deletedRows.push(rowOrCell);
        } else {
          deletedColumns.push(rowOrCell);
        }
      }
    }
  } // Clean up invalid trees that have duplicate nodes attached to different parents by deleting
  // _all_ of the references of the original node to delete


  if (originalCellOrRowToDelete.isCell()) {
    const allParentsThatHaveOriginalNodeToDelete = treeRef.allRows({
      includeStart: true
    }).filter(row => row.getColumnNames().includes(originalCellOrRowToDelete.getName()));
    modifiedRows = allParentsThatHaveOriginalNodeToDelete.map(row => rowWithoutColumn(row, originalCellOrRowToDelete.getName()));
  } else if (originalCellOrRowToDelete.isRow()) {
    const allParentsThatHaveOriginalNodeToDelete = treeRef.allCells({
      includeStart: true
    }).filter(cell => cell.getRowNames().includes(originalCellOrRowToDelete.getName()));
    modifiedColumns = allParentsThatHaveOriginalNodeToDelete.map(cell => cellWithoutRow(cell, originalCellOrRowToDelete.getName()));
  } // Then actually do the deletion of the thing we're supposed to to delete (which may be some
  // ancestor of several empty nodes)


  if (cellOrRowToDelete instanceof Cell) {
    deletedColumns.unshift(cellOrRowToDelete);

    if (cellOrRowToDelete !== originalCellOrRowToDelete) {
      modifiedRows = modifiedRows || [];
      modifiedRows.push(rowWithoutColumn(cellOrRowToDelete.getParent(), cellOrRowToDelete.getName()));
    }
  } else if (cellOrRowToDelete instanceof Row) {
    deletedRows.unshift(cellOrRowToDelete);

    if (cellOrRowToDelete !== originalCellOrRowToDelete) {
      modifiedColumns = modifiedColumns || [];
      modifiedColumns.push(cellWithoutRow(cellOrRowToDelete.getParent(), cellOrRowToDelete.getName()));
    }
  }

  let newTree = cloneAndModifyState(treeRef, {
    deletedRows,
    deletedColumns,
    modifiedRows,
    modifiedColumns
  }); // If we're left with an emptied row, make sure we insert an empty cell so things
  // can be moved into it in the future.

  if (preventEmptiedRows && cellOrRowToDelete.isCell()) {
    const parent = newTree.findRow(cellOrRowToDelete.getParentName());

    if (parent.getNumberColumns() === 0) {
      newTree = newTree.appendColumn(parent.getName(), {
        newCellValue: {
          type: CELL_TYPE
        }
      }).tree;
    }
  }

  return {
    tree: newTree,
    deletedRows,
    deletedColumns
  };
};
export const validateBeforeAndAfterTargetParams = (funcName, {
  beforeCellName,
  afterCellName,
  beforeRowName,
  afterRowName,
  insideCellName,
  insideRowName
}) => {
  const targetCellName = beforeCellName || afterCellName || insideCellName;
  const targetRowName = beforeRowName || afterRowName || insideRowName;

  if (beforeCellName && afterCellName || beforeCellName && insideCellName || afterCellName && insideCellName) {
    throw new Error(`You can only pass one of beforeCellName, afterCellName, or insideCellName to ${funcName}`);
  }

  if (beforeRowName && afterRowName || beforeRowName && insideRowName || afterRowName && insideRowName) {
    throw new Error(`You can only pass one of beforeRowName, afterRowName, or insideRowName to ${funcName}`);
  }

  if (targetCellName && targetRowName) {
    throw new Error(`You cannot pass beforeCellName/afterCellName/insideCellName and beforeRowName/afterRowName/insideRowName to ${funcName}`);
  } else if (!targetCellName && !targetRowName) {
    throw new Error(`You need to pass beforeCellName, afterCellName, insideCellName, beforeRowName, afterRowName, or insideRowName to ${funcName}`);
  }
}; // Helper to figure out if any of a row/column's children will be removed when a specific row/cell is
// deleted, _even_ accounting for auto-deletions that happen when a cell/row is the only child of its parent.

export const getChildrenMinusThoseToDelete = (tree, {
  deletedName,
  targetName
}) => {
  if (!targetName) {
    return [];
  }

  const targetedCellOrRow = getCellOrRow(tree, targetName);
  const targetParentCellOrRow = targetedCellOrRow.getParent();
  let cellsAndRowNamesToDelete = [];

  if (deletedName) {
    const existingCellOrRow = getCellOrRow(tree, deletedName);
    const {
      pathToAncestor
    } = pathToFirstAncestorWithSiblingsToDelete(existingCellOrRow);
    cellsAndRowNamesToDelete = pathToAncestor;
  } // Use the row's children filtered to exclude any cells/rows that will be deleted


  return getChildrenNames(targetParentCellOrRow).filter(colName => !cellsAndRowNamesToDelete.includes(colName));
}; // Assert that:
//   - All "pointers" in the tree reference actual cells/rows
//   - There are no "dangling" rows/cells
//   - All internal names match actual node names
//   - All of the children of a node actually have that node as its parent name

export const assertInternalStateIntegrity = tree => {
  // Note, we are intentionally only using the subset of Set functionality that is supported in IE 11
  const pointerNameSet = new Set();
  const allCellsInState = Object.keys(tree._state.columnsByName).map(colName => [colName, tree._state.columnsByName[colName]]);
  const allRowsInState = Object.keys(tree._state.rowsByName).map(rowName => [rowName, tree._state.rowsByName[rowName]]);
  const allNodeTuplesInState = allRowsInState.concat(allCellsInState);

  for (const [internalName, node] of allNodeTuplesInState) {
    const childNames = node.isRow() ? node.getColumnNames() : node.getRowNames(); // Ensure the names in the internal state map matches the actual name stored internally on the node

    if (internalName !== node.getName()) {
      throw new Error(`Tree's internal state name for ${internalName} mismatches the nodes actual name ${node.getName()}`);
    }

    for (const childName of childNames) {
      const actualChild = tree._state.columnsByName[childName] || tree._state.rowsByName[childName]; // Ensure all of the child name "pointers" point to actual nodes in the tree

      if (!actualChild) {
        throw new Error(`Tree has pointer to ${childName}, but that node doesn't actually exist in the tree (from parent ${node.getName()})`);
      } // Ensure that the name contained inside the child matches the child name pointer


      if (actualChild.getName() !== childName) {
        throw new Error(`Parent (${internalName}) has child pointer to ${childName}, but that child has name = ${actualChild.getName()}`);
      } // Ensure that the child's parent matches the node where the child pointer was located


      if (actualChild.getParentName() !== internalName) {
        throw new Error(`Parent (${internalName}) has child pointer to ${childName}, but that child has parentName = ${actualChild.getParentName()}`);
      } // Collect all the pointer names and ensure we don't point to the same node from more than one place


      if (pointerNameSet.has(childName)) {
        throw new Error(`Tree has more than one internal reference to ${childName}`);
      }

      pointerNameSet.add(childName);
    }
  } // Ensure all of the (non root) nodes in the tree have some pointer to them


  for (const [internalName] of allNodeTuplesInState) {
    if (!pointerNameSet.has(internalName) && tree._state.rootCellName !== internalName) {
      throw new Error(`Tree has node in state (${internalName}), but there are no child pointers to that node`);
    }
  }
};