import { useCallback, useMemo } from 'react';
import fuzzy from 'fuzzy';

const getOrderedCategorizedSuggestions = (filteredSuggestions, isFuzzyFilterResult) => {
  const categorizedSuggestions = filteredSuggestions.reduce((newCategorizedSuggestions, filteredSuggestion) => {
    // If the suggestions are fuzzy filtered, extract the original first so
    // that we're working with a SpotlightSuggestion
    const suggestion = isFuzzyFilterResult ? filteredSuggestion.original : filteredSuggestion; // Categorize the suggestions

    if (!newCategorizedSuggestions[suggestion.category]) {
      newCategorizedSuggestions[suggestion.category] = [];
    }

    newCategorizedSuggestions[suggestion.category].push(suggestion);
    return newCategorizedSuggestions;
  }, {});
  return {
    categorizedSuggestions,
    // Flatten categorizedSuggestions to get the list order of the suggestions
    orderedSuggestions: Object.values(categorizedSuggestions).flat()
  };
};

const fuzzyOptions = {
  extract: option => {
    const {
      fuzzySearchHint,
      label
    } = option;
    let searchableString = label;

    if (fuzzySearchHint) {
      searchableString = `${fuzzySearchHint} ${searchableString}`;
    }

    return searchableString;
  }
};
export const useCategorizedSuggestions = (suggestions, inputValue, maxSuggestions) => {
  const filterSuggestions = useCallback((suggestionsToFilter, filter) => {
    const results = fuzzy.filter(filter, suggestionsToFilter, fuzzyOptions); // Only slice if results exceed the max to avoid an unnecessary array copy

    return results.length > maxSuggestions ? results.slice(0, maxSuggestions) : results;
  }, [maxSuggestions]); // Filter, sort, and categorize suggestions

  return useMemo(() => {
    let filteredSuggestions = [];

    if (inputValue) {
      // Filter and sort suggestions
      filteredSuggestions = filterSuggestions(suggestions, inputValue);
    } else {
      // Filter out suggestions that should be hidden until search
      filteredSuggestions = suggestions.filter(suggestion => !suggestion.hiddenUntilSearch);
    }

    const {
      categorizedSuggestions,
      orderedSuggestions
    } = getOrderedCategorizedSuggestions(filteredSuggestions, !!inputValue);
    return {
      categorizedSuggestions,
      orderedSuggestions,
      total: orderedSuggestions.length
    };
  }, [inputValue, suggestions, filterSuggestions]);
};