export const AiFlags = {
  SLASH_COMMAND: 'SLASH_COMMAND',
  HIGHLIGHT_COMMAND: 'HIGHLIGHT_COMMAND',
  FEATURED_IMAGE: 'FEATURED_IMAGE',
  IMAGE: 'IMAGE',
  SUBJECT_LINE: 'SUBJECT_LINE',
  PREVIEW_TEXT: 'PREVIEW_TEXT',
  TITLE: 'TITLE',
  META_DESCRIPTION: 'META_DESCRIPTION',

  /**
   * Marketing email uses this to represent
   * whether the slash and/or highlight
   * commands were used.
   */
  MAIN_CONTENT: 'MAIN_CONTENT'
};