'use es6';

import { createSelector } from 'reselect';
import { SIDEBAR_PANELS } from 'ContentEditorUI/lib/sidebar/constants';
import { getHasContainers, getHasAnyLayoutSections } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getIsReadOnlyMode, getIframeReady, getAddModuleSidebarLoaded } from 'ContentEditorUI/redux/selectors/appStatusSelectors';
import { isApplyChangesModalOpen } from 'ContentEditorUI/redux/selectors/modalSelectors';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { getPatagoniaAddSidebarSubCategories } from 'ContentEditorUI/redux/selectors/sidebarCategorySelectors';
import { PATAGONIA_ADD_PANEL_CATEGORIES, PATAGONIA_ADD_PANEL_SUB_CATEGORIES } from 'ContentEditorUI/constants/Sidebar';
import { shouldShowSchema } from 'ContentEditorUI/lib/moduleSearchHelpers';
import { getCategoryToModulesMap } from 'ContentEditorUI/js/redux/selectors/categorizedModuleSchemaSelectors';
import { getDeveloperSections, getMarketerSections } from 'ContentEditorUI/redux/selectors/templateInfoSelectors';
import { getIsUngatedForPatagonia } from 'ContentEditorUI/redux/selectors/authSelectors';
import { LAYOUT_COLUMNS, LAYOUTS_KEY_TO_LABEL_MAP, translatedLabelKey } from 'ContentEditorUI/containers/sections/utils';
import { Map as ImmutableMap } from 'immutable';
import { shouldShowSection } from 'ContentEditorUI/utils/sidebarUtil';
export const getSidebarState = basicSelector(state => state.sidebarState);
export const getSidebarSearchQuery = createSelector([getSidebarState], sidebarState => sidebarState.get('searchQuery'));
export const getActiveSidebarPanel = createSelector([getSidebarState, getIsReadOnlyMode], (sidebarState, isReadOnlyMode) => {
  const currentActivePanel = sidebarState.get('activePanel');
  let activePanel = sidebarState ? currentActivePanel : '';

  if (isReadOnlyMode && activePanel === SIDEBAR_PANELS.addWidget) {
    activePanel = SIDEBAR_PANELS.widgetList;
  }

  return activePanel;
});
export const getSidebarPanels = createSelector([getHasContainers, getHasAnyLayoutSections, getIsReadOnlyMode], (hasContainers, hasAnyLayoutSections, isReadOnlyMode) => {
  const panels = [SIDEBAR_PANELS.preview, SIDEBAR_PANELS.optimizer, SIDEBAR_PANELS.widgetList, SIDEBAR_PANELS.edit];

  if (!isReadOnlyMode && (hasContainers || hasAnyLayoutSections)) {
    panels.push(SIDEBAR_PANELS.addWidget);
  }

  return panels;
}); // NOTE These are editSidebar selectors. It's a different piece of redux state,
// but we're keping them in the same selector file because we should eventually
// consolidate them.

const getEditSidebar = basicSelector(state => state.editSidebar);
export const getEditSidebarActiveTab = createSelector([getEditSidebar], editSidebar => editSidebar.get('activeTab'));
export const getIsBaymaxSidebarCollapsed = createSelector([getSidebarState], sidebarState => sidebarState.get('sidebarCollapsed'));
export const getIsPatagoniaSidebarCollapsed = createSelector([getSidebarState], sidebarState => sidebarState.get('patagoniaSidebarCollapsed'));
export const getIsSidebarExpanded = createSelector([getActiveSidebarPanel, getIsBaymaxSidebarCollapsed], (activeSidebarPanel, baymaxSidebarCollapsed) => activeSidebarPanel !== '' && !baymaxSidebarCollapsed);
export const getIsBaymaxSidebarHidden = createSelector([getSidebarState], sidebarState => sidebarState.get('sidebarHidden'));
export const getIsSidebarCollapseAnimationFinished = createSelector([getSidebarState], sidebarState => sidebarState.get('sidebarCollapseAnimationFinished'));
export const getIsPatagoniaSidebarCollapseAnimationFinished = createSelector([getSidebarState], sidebarState => sidebarState.get('patagoniaSidebarCollapseAnimationFinished'));
export const getSidebarIframeIsVisible = createSelector([getIsBaymaxSidebarHidden, getIsBaymaxSidebarCollapsed, getIsSidebarCollapseAnimationFinished, getActiveSidebarPanel], (sidebarHidden, sidebarCollapsed, collapseAnimationFinished, activePanel) => !sidebarHidden && (!sidebarCollapsed || sidebarCollapsed && !collapseAnimationFinished) && activePanel === SIDEBAR_PANELS.addWidget);
export const getPatagoniaSidebarIframeVisible = createSelector([getIsBaymaxSidebarHidden, getIsPatagoniaSidebarCollapsed, getIsPatagoniaSidebarCollapseAnimationFinished, getActiveSidebarPanel], (sidebarHidden, sidebarCollapsed, collapseAnimationFinished, activePanel) => !sidebarHidden && (!sidebarCollapsed && collapseAnimationFinished || sidebarCollapsed && !collapseAnimationFinished) && activePanel === SIDEBAR_PANELS.addWidget);
export const getSidebarIframeNeedsLoadingIndicator = createSelector([getIframeReady, getAddModuleSidebarLoaded, getActiveSidebarPanel], (iframeReady, addModuleSidebarLoaded, activePanel) => {
  return (!iframeReady || !addModuleSidebarLoaded) && activePanel === SIDEBAR_PANELS.addWidget;
});
export const getModuleEditSidebarNeedsLoadingIndicator = createSelector([getIframeReady, getActiveSidebarPanel, getIsReadOnlyMode], (iframeReady, activePanel, isReadOnlyMode) => {
  return !iframeReady && !isReadOnlyMode && activePanel === SIDEBAR_PANELS.edit;
});
export const getScrollToModule = createSelector([getSidebarState], sidebarState => sidebarState.get('scrollToModule'));
export const getApplyChangesButtonOpen = createSelector([getEditSidebar, isApplyChangesModalOpen], (editSidebar, applyChangesModalOpen) => editSidebar.get('applyChangesButtonOpen') && !applyChangesModalOpen);
export const getPreviousSidebarPanel = createSelector([getSidebarState], sidebarState => {
  return sidebarState.get('previousPanel');
});
export const getSelectedField = createSelector([getEditSidebar], sidebarState => sidebarState.get('selectedField'));
export const getSidebarCategory = createSelector([getSidebarState], sidebarState => sidebarState.get('category'));
export const getSidebarSubCategory = createSelector([getSidebarState], sidebarState => sidebarState.get('subCategory')); // generally will be be the first item, but can be overridden
// E.g. Don't show an empty section when a useful section exists

export const getDefaultSubCategoryMap = createSelector([getSidebarState, getPatagoniaAddSidebarSubCategories, getSidebarCategory], (sidebarState, subCategories, sidebarCategory) => {
  if (sidebarCategory === PATAGONIA_ADD_PANEL_CATEGORIES.MODULES) {
    return ImmutableMap({
      MODULES: subCategories[sidebarCategory][0],
      SECTIONS: 'SAVED',
      LAYOUTS: 'NONE'
    });
  }

  return sidebarState.get('defaultSelectedSubCategoryMap');
});
export const getSidebarSearchResultsMap = createSelector([getSidebarSearchQuery, getCategoryToModulesMap, getDeveloperSections, getMarketerSections, getIsUngatedForPatagonia], (searchQuery, categoryToModulesMap, developerSections, marketerSections, isUngatedForPatagonia) => {
  const filteredResultsMap = {
    MODULES: {},
    SECTIONS: {
      SAVED: {},
      THEME: {}
    },
    LAYOUTS: {}
  };

  if (!isUngatedForPatagonia) {
    return filteredResultsMap;
  } //this selector is for universal search that is part of module categories work which searches
  //through all categories instead of within a category/sub category and maps the results and count out in one place
  //filter modules


  const modulesCollection = categoryToModulesMap['ALL'] || [];
  const filteredModulesCollection = {
    theme: {
      modules: [],
      count: 0
    },
    nonTheme: {
      modules: [],
      count: 0
    }
  };
  let filteredModulesCount = modulesCollection.length || 0;

  if (searchQuery) {
    modulesCollection.forEach(w => {
      if (shouldShowSchema(w, searchQuery.toLowerCase())) {
        if (w.isThemeAsset) {
          filteredModulesCollection.theme.modules.push(w);
        } else {
          filteredModulesCollection.nonTheme.modules.push(w);
        }
      }
    });
    const filteredThemeCount = filteredModulesCollection.theme.modules.length || 0;
    filteredModulesCollection.theme.count = filteredThemeCount;
    const filteredNonThemeCount = filteredModulesCollection.nonTheme.modules.length || 0;
    filteredModulesCollection.nonTheme.count = filteredNonThemeCount;
    filteredModulesCount = filteredThemeCount + filteredNonThemeCount;
  }

  filteredResultsMap[PATAGONIA_ADD_PANEL_CATEGORIES.MODULES] = {
    results: filteredModulesCollection,
    count: filteredModulesCount
  };

  if (filteredModulesCount) {
    filteredResultsMap.firstCategoryWithResult = PATAGONIA_ADD_PANEL_CATEGORIES.MODULES;
  } //filter sections


  const filteredDeveloperSections = searchQuery ? developerSections.filter(section => {
    return shouldShowSection(searchQuery, section);
  }) : developerSections;
  filteredResultsMap[PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS][PATAGONIA_ADD_PANEL_SUB_CATEGORIES.SECTIONS.THEME].results = filteredDeveloperSections;
  const filteredMarketerSections = searchQuery ? marketerSections.filter(section => {
    return shouldShowSection(searchQuery, section);
  }) : marketerSections;
  filteredResultsMap[PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS][PATAGONIA_ADD_PANEL_SUB_CATEGORIES.SECTIONS.SAVED].results = filteredMarketerSections;
  filteredResultsMap[PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS].count = filteredDeveloperSections.length + filteredMarketerSections.length || 0;

  if (!filteredResultsMap.firstCategoryWithResult && (filteredDeveloperSections.length || filteredMarketerSections.length)) {
    filteredResultsMap.firstCategoryWithResult = PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS;
  } //filter layouts


  const filteredLayouts = searchQuery ? Object.keys(LAYOUT_COLUMNS).filter(key => translatedLabelKey(key).includes(searchQuery.toUpperCase()) || LAYOUTS_KEY_TO_LABEL_MAP[key].includes(searchQuery)).reduce((cur, key) => {
    return Object.assign(cur, {
      [key]: LAYOUT_COLUMNS[key]
    });
  }, {}) : LAYOUT_COLUMNS;
  filteredResultsMap[PATAGONIA_ADD_PANEL_CATEGORIES.LAYOUTS].results = filteredLayouts;
  filteredResultsMap[PATAGONIA_ADD_PANEL_CATEGORIES.LAYOUTS].count = Object.keys(filteredLayouts).length || 0;

  if (!filteredResultsMap.firstCategoryWithResult && Object.keys(filteredLayouts).length) {
    filteredResultsMap.firstCategoryWithResult = PATAGONIA_ADD_PANEL_CATEGORIES.LAYOUTS;
  }

  return filteredResultsMap;
});