import { GROUP, FONT } from 'ContentUtils/constants/CustomWidgetFieldTypes';
import { FONT_SETS } from 'ContentUtils/constants/Fonts';
import { getIn } from '../utils/object';
export const getGoogleFontsMap = (fields, settingsValues, fontsMap = {}, parentFieldKey = []) => {
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    const fieldKey = parentFieldKey.concat(field.name);

    if (field.type === GROUP) {
      fontsMap = getGoogleFontsMap(field.children, settingsValues, fontsMap, fieldKey);
      continue;
    }

    if (field.type !== FONT) {
      continue;
    }

    const fieldValue = getIn(settingsValues, fieldKey) || field.default || {};
    const {
      font,
      font_set: fontSet,
      variant
    } = fieldValue;

    if (!(font && fontSet === FONT_SETS.GOOGLE)) {
      continue;
    }

    const fontVariants = fontsMap[font] ? fontsMap[font].split(',') : [];

    if (!fontVariants.includes(variant)) {
      fontsMap[font] = fontVariants.concat(variant).sort().join(',');
    }
  }

  return fontsMap;
};