'use es6';

import { FETCH_CONTENT_SCHEMA_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
const CONTENT_SCHEMA_INTERFACE = {
  builtin_mapping: {},
  content_group: {},
  preview_domain: null,
  template_type: null,
  content: {
    attachedStylesheets: [],
    category: null,
    categoryId: null,
    domain: null,
    id: null,
    label: null,
    layoutSections: {},
    name: null,
    portalId: null,
    previewKey: null,
    subcategory: null,
    templatePath: null,
    widgetContainers: {},
    widgets: {}
  },
  schema: {
    layout_sections: {},
    layout_section_widgets: {},
    widgets: {},
    widget_containers: {},
    global_group_info_by_path: {},
    global_partial_info_by_path: {}
  },
  context: {
    active_domain: {}
  }
};

const validateContentSchemas = (baseSchema, schema, path = []) => {
  Object.keys(baseSchema).forEach(key => {
    if (!schema.hasOwnProperty(key)) {
      const missingKey = path.concat(key).join('.');
      throw new Error(`Content schema missing required property: ${missingKey}`);
    }

    const baseSchemaValue = baseSchema[key];

    if (baseSchemaValue && typeof baseSchemaValue === 'object') {
      validateContentSchemas(baseSchemaValue, schema[key], path.concat(key));
    }
  });
};

export default function contentSchemaValidationMiddleware() {
  return () => next => action => {
    const result = next(action);

    if (action.type === FETCH_CONTENT_SCHEMA_SUCCEEDED) {
      validateContentSchemas(CONTENT_SCHEMA_INTERFACE, action.response);
    }

    return result;
  };
}