/**
 * Util for ensuring a number is >= 0.
 * Returns the input value if it's a positive number, otherwise returns `0`.
 */
const zeroBound = num => Math.max(0, num);

export const makeRectCoords = (top, right, bottom, left) => ({
  top,
  right,
  bottom,
  left
});
/** Creates a Rect */

export const makeRect = (top, right, bottom, left) => ({
  width: right - left,
  height: bottom - top,
  top,
  right,
  bottom,
  left
});
export const makeRectFromCoords = ({
  top,
  right,
  bottom,
  left
}) => makeRect(top, right, bottom, left);
/**
 * Returns a copy of the given `rect` moved `x` px to the right and `y` px down
 */

export const translateRect = (rect, x, y) => makeRect(rect.top + y, rect.right + x, rect.bottom + y, rect.left + x);
/** Returns a copy of the given Rect */

export const copyRect = rect => makeRect(rect.top, rect.right, rect.bottom, rect.left);
/**
 * Returns a Rect that indicates how much overlap there is with the constraint on each side
 */

export const getCollisionRect = (rect, constraintRect) => makeRect(zeroBound(constraintRect.top - rect.top), zeroBound(rect.right - constraintRect.right), zeroBound(rect.bottom - constraintRect.bottom), zeroBound(constraintRect.left - rect.left));
/** Subtracts the given `[top, right, bottom, left]` values from a rect, shrinking it. */

export const clipRectByValues = (rect, top, right, bottom, left) => makeRect(rect.top + top, rect.right - right, rect.bottom - bottom, rect.left + left);