import { REHYDRATE_MODULE_SCHEMAS_SUCCEEDED, FETCH_MODULE_SCHEMAS_SUCCEEDED, FETCH_CONTENT_SCHEMA_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import { combineModuleSchemas, rehydrateModuleSchemasWithTranslations } from 'ContentEditorUI/data/ModuleTransforms';
export const moduleSchemasInitialState = {
  all: {},
  allAddable: {},
  builtinMapping: {},
  builtInModules: {},
  categoryName: null,
  customModules: {},
  globalModules: {},
  globalGroupInfo: {},
  globalPartialInfo: {}
};

const moduleSchemaReducer = (state = moduleSchemasInitialState, data = {
  type: '',
  response: {}
}) => {
  const {
    type,
    response
  } = data;

  switch (type) {
    case REHYDRATE_MODULE_SCHEMAS_SUCCEEDED:
      return rehydrateModuleSchemasWithTranslations(response, state);

    case FETCH_MODULE_SCHEMAS_SUCCEEDED:
      return combineModuleSchemas(Object.assign({
        existingAllModulesMap: state.all,
        existingAllAddableMap: state.allAddable,
        existingBuiltInModulesMap: state.builtInModules,
        existingGlobalModulesMap: state.globalModules,
        existingCustomModulesMap: state.customModules
      }, response), state);

    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const contentSchemaResponse = response;
        const {
          used_module_schemas: usedModuleSchemas
        } = contentSchemaResponse;

        if (usedModuleSchemas && Object.keys(usedModuleSchemas).length) {
          let categoryName = state.categoryName;

          if (!categoryName) {
            categoryName = contentSchemaResponse.categoryName;
            state = Object.assign({}, state, {
              categoryName
            });
          }

          state = combineModuleSchemas({
            categoryName,
            usedModuleSchemas,
            existingAllModulesMap: state.all,
            existingAllAddableMap: state.allAddable,
            existingBuiltInModulesMap: state.builtInModules,
            existingGlobalModulesMap: state.globalModules,
            existingCustomModulesMap: state.customModules,
            canAddToAddableMap: false
          }, state);
        }

        return Object.assign({}, state, {
          builtinMapping: contentSchemaResponse.builtin_mapping,
          globalGroupInfo: contentSchemaResponse.schema.global_group_info_by_path,
          globalPartialInfo: contentSchemaResponse.schema.global_partial_info_by_path
        });
      }

    default:
      return state;
  }
};

export default moduleSchemaReducer;