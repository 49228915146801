'use es6'; // Copied from https://github.com/pofigizm/redux-dynamic-middlewares (v2.0.0, MIT)

import { compose } from 'redux';

const createDynamicMiddlewares = () => {
  let allDynamicMiddlewares = [];
  let allApplyedDynamicMiddlewares = [];
  let store;

  const enhancer = _store => {
    store = _store;
    return next => action => {
      return compose(...allApplyedDynamicMiddlewares)(next)(action);
    };
  };

  const addMiddleware = (...middlewares) => {
    allApplyedDynamicMiddlewares.push(...middlewares.map(middleware => middleware(store)));
    allDynamicMiddlewares.push(...middlewares);
  };

  const removeMiddleware = middleware => {
    const index = allDynamicMiddlewares.findIndex(d => d === middleware);

    if (index === -1) {
      console.error('Middleware does not exist!', middleware);
      return;
    }

    allDynamicMiddlewares = allDynamicMiddlewares.filter((_, mdwIndex) => mdwIndex !== index);
    allApplyedDynamicMiddlewares = allApplyedDynamicMiddlewares.filter((_, mdwIndex) => mdwIndex !== index);
  };

  const resetMiddlewares = () => {
    allApplyedDynamicMiddlewares = [];
    allDynamicMiddlewares = [];
  };

  return {
    enhancer,
    addMiddleware,
    removeMiddleware,
    resetMiddlewares
  };
};

const dynamicMiddlewaresInstance = createDynamicMiddlewares(); // START HUBSPOT CUSTOMIZATIONS
// Chaning all exports to be named to make asset bender happy
// export default dynamicMiddlewaresInstance.enhancer;
//
// export const {
//   addMiddleware,
//   removeMiddleware,
//   resetMiddlewares,
// } = dynamicMiddlewaresInstance;
//
// export { createDynamicMiddlewares };

export const dynamicMiddlewares = dynamicMiddlewaresInstance.enhancer;
export const addMiddleware = dynamicMiddlewaresInstance.addMiddleware;
export const removeMiddleware = dynamicMiddlewaresInstance.removeMiddleware;
export const resetMiddlewares = dynamicMiddlewaresInstance.resetMiddlewares; // END HUBSPOT CUSTOMIZATIONS