'use es6';

import { FEATURES } from 'collaboration-sidebar';
import { getSaveData, getCanSave } from 'page-editor-ui/redux/selectors/saveSelectors';
import { getHasPermissionsToLoadEditor } from 'page-editor-ui/redux/selectors/authSelectors';
import { autoSaveDebounced, autoSave, save } from 'page-editor-ui/redux/actions/pageSaveActions';
import SidebarContainer from './containers/SidebarContainer';
import HeaderContainer from './containers/HeaderContainer';
import TabBarContainer from './containers/TabBarContainer';
import ExtraApplicationContainerComponents from './components/ExtraApplicationContainerComponents';
import RootTreeWrapper from './components/RootTreeWrapper';
import rhumbConfig from 'page-editor-ui/rhumb-config.yaml';
import lang from 'i2l?query=sporks!page-editor-ui/lang/en.lyaml';
import routes from './Routes';
import rootReducer from 'page-editor-ui/redux/reducers'; // At some point, pull this out into its own file in page-editor-ui so we can use the StandardRichText

import { asyncComponentsOverrides } from './components/AsyncComponents';
import { getAsyncComponents } from 'ContentEditorUI/components/AsyncComponents';
import { PAGES_API_BASE_URL } from 'page-editor-ui/Constants';
import universalAppReadyMiddleware from 'ContentEditorUI/redux/middleware/universalAppReadyMiddleware';
import { combinedInitialRequestThunk, contentAssistantThunk } from './redux/actions/requestThunks';
import { mapStateToTinyMCEConfig } from './mapStateToTinyMCEConfig';
import { mapStateToCollaborationSidebarConfig } from './mapStateToCollaborationSidebarConfig';
import { getStandardFetchCssRequestOptions } from 'ContentEditorUI/lib/scalableEditor/commonFeatureConfigs';
import setupDevHelpers from './devHelpers';
export const editorConfig = {
  // Passed to the renderer to locate where the request is coming from
  appKey: 'page',
  features: {
    cssAssets: {
      getFetchCssRequestOptions: getStandardFetchCssRequestOptions
    },
    collaborationSidebar: {
      appName: 'page-editor',
      enabledFeatures: [FEATURES.COMMENTS, FEATURES.TASKS, FEATURES.CALENDAR, FEATURES.CAMPAIGNS],
      mapStateToCollaborationSidebarConfig
    },
    templateTypes: true,
    shouldUseNopeZonesForAllModules: false,
    shouldFocusSingleStaticModule: false,
    hideLockedEditFormFields: true,
    checkUserAttributes: true,
    breakpoints: true,
    undoRedo: true,
    sections: true,
    smartContent: true,
    aiGeneratedContent: true,
    inlineStyles: true
  },
  // Selectors required for the core app to work
  redux: {
    reducers: {
      rootReducer
    },
    selectors: {
      getSaveData,
      getCanSave,
      getHasPermissionsToLoadEditor
    },
    actions: {
      autoSave,
      autoSaveDebounced,
      save
    },
    checkAppReadyMiddleware: universalAppReadyMiddleware(['FETCH_CONTENT_SCHEMA_SUCCEEDED', 'FETCH_MODULE_SCHEMAS_SUCCEEDED', 'GET_CONTENT_ASSISTANT_SETTINGS'])
  },
  components: {
    asyncComponents: getAsyncComponents(asyncComponentsOverrides),
    HeaderExtension: HeaderContainer,
    TabBarExtension: TabBarContainer,
    SidebarExtension: SidebarContainer,
    RootTreeWrapper,
    ExtraApplicationContainerComponents
  },
  routes,
  infra: {
    rootUrl: '/pages/{portalId}/editor',
    rhumbConfig,
    lang
  },
  api: {
    API_BASE_URL: PAGES_API_BASE_URL,
    // Commenting this out for posterity. Using an empty array ends up fetching a bunch of user attributes
    // we don't need
    // userAttributesToFetch: ALL_USER_ATTRIBUTE_KEYS_IN_DATABASE_AND_SUPERSTORE,
    initialRequests: [combinedInitialRequestThunk, contentAssistantThunk]
  },
  mapStateToTinyMCEConfig,
  setupDevHelpers
};