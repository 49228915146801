'use es6';

import http from 'hub-http/clients/apiClient';
const CONTACTS_PATH = 'contacts/v1';

function fetchLists(search) {
  const query = {
    count: 50,
    direction: 'ASC',
    offset: 0,
    q: search,
    sortBy: 'LIST_NAME'
  };
  return http.get(`${CONTACTS_PATH}/segment-ui/all`, {
    query
  });
}

function fetchSpecificLists(listIds) {
  return http.post(`${CONTACTS_PATH}/lists/batch`, {
    data: listIds
  });
}

function findContact(search) {
  const query = {
    count: 100,
    offset: 0,
    q: search,
    property: ['email', 'firstname', 'lastname']
  };
  return http.get(`${CONTACTS_PATH}/search/query`, {
    query
  });
}

export default {
  fetchLists,
  fetchSpecificLists,
  findContact
};