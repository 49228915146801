import { FETCH_THINGS_PEOPLE_COVER_SUCCEEDED } from '../actionTypes';
export const thingsPeopleCoverReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_THINGS_PEOPLE_COVER_SUCCEEDED:
      {
        return action.payload;
      }

    default:
      return state;
  }
};
export const selectThingsPeopleCover = state => state.thingsPeopleCover;