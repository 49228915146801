import { useContext } from 'react';
import CollaborationSidebarPropsContext from '../contexts/CollaborationSidebarPropsContext';
export default function useCollaborationSidebarProps() {
  const props = useContext(CollaborationSidebarPropsContext);

  if (!props) {
    throw new Error('CollaborationSidebarPropsContext not provided');
  }

  return props;
}