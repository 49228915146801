export const getRowDisplayFormat = (displayFormat, numberOfDisplayColumns) => {
  if (displayFormat) return displayFormat;
  let defaultDisplayFormat = '%0';

  if (numberOfDisplayColumns && numberOfDisplayColumns > 0) {
    for (let i = 1; i <= numberOfDisplayColumns - 1; i++) {
      defaultDisplayFormat = `${defaultDisplayFormat} %${i}`;
    }
  }

  return defaultDisplayFormat;
};