'use es6';

import { buildDndAreaCellSelector, buildDndAreaUniqueRowSelector } from '../../dom/dndAreaDomSelectors';
import { VERTICAL_ALIGNMENTS } from 'ContentUtils/constants/Alignments'; //TODO branden this was taken from ContentEditorUI
// https://git.hubteam.com/HubSpot/ContentEditorUI/blob/master/ContentEditorUI/static/js/lib/deviceConstants.js#L3

const TABLET_PORTRAIT_WIDTH = 768;
export const calculateVerticalAlignmentClasses = (treeNode, styleValue) => {
  const styleValueToJustifyValue = {
    [VERTICAL_ALIGNMENTS.TOP]: 'flex-start',
    [VERTICAL_ALIGNMENTS.MIDDLE]: 'center',
    [VERTICAL_ALIGNMENTS.BOTTOM]: 'flex-end'
  };
  const justifyContentValue = styleValueToJustifyValue[styleValue];

  if (!justifyContentValue) {
    console.warn(`"${styleValue}" not a valid vertical alignment value. Valid values are ${Object.keys(styleValueToJustifyValue).join(', ')}`);
    return [];
  }

  const parentRendererRowName = treeNode.getParent().getRendererCompatibleRowName();
  const result = [{
    nodeName: treeNode.getName(),
    domNodeSelector: buildDndAreaCellSelector(treeNode.getName()),
    className: `${treeNode.getName()}-vertical-alignment`,
    cssSelector: `.${treeNode.getName()}-vertical-alignment`,
    mediaQuery: `@media(min-width: ${TABLET_PORTRAIT_WIDTH}px)`,
    cssProperties: {
      display: 'flex !important',
      'flex-direction': 'column !important',
      'justify-content': `${justifyContentValue} !important` // Not needed on the FE, but needed in the renderer to support IE 10
      //
      // display: -ms-flexbox !important;
      // -ms-flex-direction: column !important;
      // -ms-flex-pack: {start|center|end} !important;

    }
  }, {
    nodeName: treeNode.getParentName(),
    domNodeSelector: buildDndAreaUniqueRowSelector(treeNode.getParent().getParent().getName(), treeNode.getParent().getName()),
    className: `${parentRendererRowName}-vertical-alignment`,
    cssSelector: `.${parentRendererRowName}-vertical-alignment > .row-fluid`,
    mediaQuery: `@media(min-width: ${TABLET_PORTRAIT_WIDTH}px)`,
    cssProperties: {
      display: 'flex !important',
      'flex-direction': 'row' // Not needed on the FE, but needed in the renderer to support IE 10
      //
      // display: -ms-flexbox !important;
      // -ms-flex-direction: row;

    }
  }];
  result.push({
    nodeName: treeNode.getName(),
    domNodeSelector: buildDndAreaCellSelector(treeNode.getName()),
    className: `${treeNode.getName()}-vertical-alignment`,
    // Since veritical alignment assume there is a wrapper column (and not just a module)
    // we can assume the direct child element is a DIV (I think?)
    cssSelector: `.${treeNode.getName()}-vertical-alignment > div`,
    mediaQuery: `@media(min-width: ${TABLET_PORTRAIT_WIDTH}px)`,
    cssProperties: {
      // Fix IE (https://git.hubteam.com/HubSpot/ContentEditorUI/issues/7534)
      'flex-shrink': '0 !important'
    }
  });
  return result;
};
export const calculateExistingVerticalAlignmentForRow = row => {
  const childColumns = row.getColumns();
  const columnAlignmentCounts = {};
  const NONE_KEY = 'NONE';
  columnAlignmentCounts[NONE_KEY] = 0;
  Object.keys(VERTICAL_ALIGNMENTS).forEach(key => {
    columnAlignmentCounts[key] = 0;
  });
  childColumns.forEach(column => {
    const styles = column.getLayoutStyleData();

    if (!styles || !styles.verticalAlignment) {
      columnAlignmentCounts[NONE_KEY] += 1;
      return;
    }

    columnAlignmentCounts[styles.verticalAlignment] += 1;
  });
  let mostCommonAlignment = VERTICAL_ALIGNMENTS.TOP;
  Object.keys(columnAlignmentCounts).forEach(alignmentKey => {
    if (columnAlignmentCounts[alignmentKey] > columnAlignmentCounts[mostCommonAlignment]) {
      mostCommonAlignment = alignmentKey;
    }
  });

  if (mostCommonAlignment === NONE_KEY) {
    return null;
  }

  return mostCommonAlignment;
};