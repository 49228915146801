'use es6';

import { FETCH_CONTENT_SCHEMA_SUCCEEDED, FETCH_TEMPLATE_INFO_SUCCESS, FETCH_TEMPLATE_PREVIEW_URL_SUCCESS, FETCH_ADDABLE_SECTIONS_SUCCESS, DELETE_SECTION_SUCCESS, FETCH_MARKETER_SECTIONS_SUCCESS, SAVE_SECTION_SUCCESS, CUSTOM_LAYOUT_SECTION_ADDED, SET_ADD_SECTION_MISSING_MODULE_ERRORS, CLEAR_ADD_SECTION_MISSING_MODULE_ERRORS } from 'ContentEditorUI/redux/actions/actionTypes';
import Immutable from 'immutable';
import UsageTracker from 'ContentEditorUI/utils/UsageTracker';

const templateReducer = (state = Immutable.Map({
  templateType: null,
  customSections: null,
  marketerSectionsCount: -1,
  marketerSectionsLimit: -1,
  previousInsertedSectionTree: null
}), {
  type,
  payload,
  response
}) => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        return state.merge({
          templateType: response.template_type
        });
      }

    case FETCH_TEMPLATE_INFO_SUCCESS:
      return state.merge(Object.assign({}, payload));

    case FETCH_TEMPLATE_PREVIEW_URL_SUCCESS:
      if (state.get('userMeta')) {
        return state.setIn(['userMeta', 'thumbnailPath'], payload.webUrl);
      }

      return state.set('userMeta', Immutable.fromJS({
        thumbnailPath: payload.webUrl
      }));

    case FETCH_ADDABLE_SECTIONS_SUCCESS:
      {
        state = state.merge({
          marketerSectionsCount: payload.marketerSectionsCount,
          marketerSectionsLimit: payload.marketerSectionsLimit
        });
        return state.set('customSections', payload.allSections);
      }

    case DELETE_SECTION_SUCCESS:
      {
        const sectionToDelete = payload;
        const newSectionList = state.get('customSections').filter(section => section.id !== sectionToDelete);
        const currentMarketerSectionsCount = state.get('marketerSectionsCount');
        state = state.set('customSections', newSectionList);
        return state.set('marketerSectionsCount', currentMarketerSectionsCount - 1);
      }

    case FETCH_MARKETER_SECTIONS_SUCCESS:
      return state.merge({
        marketerSectionsCount: payload.total,
        marketerSectionsLimit: payload.themeLimit
      });

    case SAVE_SECTION_SUCCESS:
      {
        const currentMarketerSectionsCount = state.get('marketerSectionsCount');
        const marketerSectionsLimit = state.get('marketerSectionsLimit');

        if (currentMarketerSectionsCount + 1 === marketerSectionsLimit) {
          UsageTracker.trackEditorInteraction({
            action: 'Sections action',
            event: 'hit-section-limit'
          });
        }

        const newSectionList = [payload, ...state.get('customSections')];
        state = state.set('customSections', newSectionList);
        return state.set('marketerSectionsCount', currentMarketerSectionsCount + 1);
      }

    case CUSTOM_LAYOUT_SECTION_ADDED:
      {
        return state.set('previousInsertedSectionTree', null);
      }

    case SET_ADD_SECTION_MISSING_MODULE_ERRORS:
      {
        const previousInsertedSectionTree = payload.previousTree;
        return state.set('previousInsertedSectionTree', previousInsertedSectionTree);
      }

    case CLEAR_ADD_SECTION_MISSING_MODULE_ERRORS:
      {
        return state.set('previousInsertedSectionTree', null);
      }

    default:
      return state;
  }
};

export default templateReducer;