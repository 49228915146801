/* hs-eslint ignored failing-rules */

/* eslint-disable promise/catch-or-return */
'use es6';

import { FETCH_PAGE_LIMIT_FAILED, FETCH_PAGE_LIMIT_SUCCEEDED, FETCH_PAGE_LIMIT_STARTED, FETCH_MLANG_SITE_PAGES_LIMIT_STARTED, FETCH_MLANG_SITE_PAGES_LIMIT_FAILED, FETCH_MLANG_SITE_PAGES_LIMIT_SUCCEEDED } from '../actions/actionTypes';
import { fetchPageLimitForContentType, fetchMlangSitePagesLimit as fetchMlangSitePagesLimitApi } from '../../api/PageLimitApi';
import { getIsLandingPage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors'; // REQUEST FAILURES ---------------------->

const fetchPageLimitFailed = error => {
  return {
    type: FETCH_PAGE_LIMIT_FAILED,
    payload: error
  };
};

const fetchMlangSitePagesLimitFailed = error => {
  return {
    type: FETCH_MLANG_SITE_PAGES_LIMIT_FAILED,
    payload: error
  };
}; // REQUEST SUCCESSES --------------------->


const fetchPageLimitSucceeded = limit => {
  return {
    type: FETCH_PAGE_LIMIT_SUCCEEDED,
    payload: limit
  };
};

const fetchMlangSitePagesLimitSucceeded = limit => {
  return {
    type: FETCH_MLANG_SITE_PAGES_LIMIT_SUCCEEDED,
    payload: limit
  };
}; // REQUESTS ------------------------------>


export const fetchPageLimit = () => (dispatch, getState) => {
  const isLandingPage = getIsLandingPage(getState());
  const contentType = isLandingPage ? 'landing-pages' : 'site-pages';
  dispatch({
    type: FETCH_PAGE_LIMIT_STARTED
  });

  const success = resp => {
    if (resp) {
      dispatch(fetchPageLimitSucceeded(resp));
    } else {
      dispatch(fetchPageLimitFailed(resp));
    }
  };

  const error = resp => dispatch(fetchPageLimitFailed(resp));

  fetchPageLimitForContentType(contentType).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchMlangSitePagesLimit = contentId => dispatch => {
  dispatch({
    type: FETCH_MLANG_SITE_PAGES_LIMIT_STARTED
  });

  const success = resp => {
    if (resp) {
      dispatch(fetchMlangSitePagesLimitSucceeded(resp));
    } else {
      dispatch(fetchMlangSitePagesLimitFailed(resp));
    }
  };

  const error = resp => dispatch(fetchMlangSitePagesLimitFailed(resp));

  fetchMlangSitePagesLimitApi(contentId).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};