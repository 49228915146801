import { combineReducers } from 'redux';
import formatName from 'I18n/utils/formatName';
import * as RequestStatus from 'ContentUtils/constants/RequestStatus';
import { USERS_FETCH_USERS, USERS_FETCH_USERS_FOR_TEAMS, USERS_UPDATE_USERS_FOR_TEAMS } from 'ContentData/actions/ActionTypes';

const users = (state = {}, action) => {
  switch (action.type) {
    case USERS_FETCH_USERS:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return response.reduce((hash, user) => {
          hash[user.email] = Object.assign({}, user);
          return hash;
        }, Object.assign({}, state));
      }

    default:
      return state;
  }
};

const userFetchStatus = (state = RequestStatus.UNINITIALIZED, action) => {
  switch (action.type) {
    case USERS_FETCH_USERS:
      {
        const {
          response,
          error
        } = action;
        if (response) return RequestStatus.SUCCEEDED;
        if (error) return RequestStatus.FAILED;
        return RequestStatus.PENDING;
      }

    default:
      return state;
  }
};

function formatUserFullName(user) {
  const {
    email,
    firstName,
    lastName
  } = user;
  return formatName({
    firstName,
    lastName
  }) || email;
}

const usersForTeams = (state = {
  data: []
}, action) => {
  switch (action.type) {
    case USERS_FETCH_USERS_FOR_TEAMS:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          data: response.map(user => Object.assign({}, user, {
            fullName: formatUserFullName(user)
          }))
        });
      }

    case USERS_UPDATE_USERS_FOR_TEAMS:
      {
        const {
          data
        } = action;
        if (!data) return state;
        return Object.assign({}, state, {
          data
        });
      }

    default:
      return state;
  }
};

const usersForTeamsFetchStatus = (state = RequestStatus.UNINITIALIZED, action) => {
  switch (action.type) {
    case USERS_FETCH_USERS_FOR_TEAMS:
      {
        const {
          response,
          error
        } = action;
        if (response) return RequestStatus.SUCCEEDED;
        if (error) return RequestStatus.FAILED;
        return RequestStatus.PENDING;
      }

    default:
      return state;
  }
};

export default combineReducers({
  users,
  userFetchStatus,
  usersForTeams,
  usersForTeamsFetchStatus
});