'use es6';

import { OPTIN_VIEW_NEW, OPTIN_VIEW_CURRENT } from 'ui-addon-opt/constants/UIOptConstants';
import { ACTION_CHOICE_RESET, ACTION_STATE_UPDATED, ACTION_HUB_OPT_IN, ACTION_HUB_OPT_OUT, ACTION_INIT_OPT, ACTION_USER_OPT_IN, ACTION_USER_OPT_OUT } from './OptActions';
import { OPT_CHOICE_HUB_OPT_IN, OPT_CHOICE_HUB_OPT_OUT, OPT_CHOICE_USER_OPT_IN, OPT_CHOICE_USER_OPT_OUT, isOptedIn, isKnownState } from './OptState';
import { isSuperAdmin } from './UserState';

const getTargetOptinView = (state, previousOptinView) => {
  if (isOptedIn(state)) {
    return OPTIN_VIEW_NEW;
  }

  if (isKnownState(state)) {
    return OPTIN_VIEW_CURRENT;
  }

  return previousOptinView || OPTIN_VIEW_CURRENT;
};

const initFromSettings = settings => ({
  choice: null,
  optinView: getTargetOptinView(settings.availability.state),
  isSuperAdmin: isSuperAdmin(settings.user),
  state: null
});

export const createInitialOptState = () => ({
  choice: null,
  optinView: null,
  isSuperAdmin: false,
  state: null
});
export const optStateReducer = (state, action) => {
  switch (action.type) {
    case ACTION_INIT_OPT:
      return Object.assign({}, state, {}, initFromSettings(action.settings));

    case ACTION_USER_OPT_IN:
      return Object.assign({}, state, {
        choice: OPT_CHOICE_USER_OPT_IN
      });

    case ACTION_USER_OPT_OUT:
      return Object.assign({}, state, {
        choice: OPT_CHOICE_USER_OPT_OUT
      });

    case ACTION_HUB_OPT_IN:
      return Object.assign({}, state, {
        choice: OPT_CHOICE_HUB_OPT_IN
      });

    case ACTION_HUB_OPT_OUT:
      return Object.assign({}, state, {
        choice: OPT_CHOICE_HUB_OPT_OUT
      });

    case ACTION_STATE_UPDATED:
      return Object.assign({}, state, {
        choice: null,
        optinView: getTargetOptinView(state.choice, state.optinView),
        state: state.choice
      });

    case ACTION_CHOICE_RESET:
      return Object.assign({}, state, {
        choice: null
      });

    default:
      throw new Error(`Invalid opt action: ${action.type}`);
  }
};