import { createSelector } from 'reselect';
import { INIT_TOPICS_GROUP_STORE } from '../actionTypes';
const initialState = {
  id: null
};
export const contentReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_TOPICS_GROUP_STORE:
      return Object.assign({}, state, {
        id: action.payload.contentId
      });

    default:
      return state;
  }
};

const selectContentState = state => state.content;

export const selectContentId = createSelector(selectContentState, content => content.id);