import { CompletionStatuses } from '../../../../../constants';
import { GathererKeys } from '../../../../../gatherers/allGatherers';
const MIN_SUBTOPIC_PHRASES_IN_BODY = 2;
export const increaseClusterPhraseInBodyCountAudit = (artifacts, subtopics) => {
  const DOMBody = artifacts[GathererKeys.DOM_BODY];
  const postBody = artifacts[GathererKeys.POST_BODY]; // postBody would only exist if it's a BLOG_POST, for all other content types use the DOMBody

  const body = postBody || DOMBody;
  const subtopicPhrases = subtopics.map(subtopic => subtopic.phrase);

  if (subtopicPhrases.length === 0) {
    return {
      status: CompletionStatuses.NOT_APPLICABLE
    };
  }

  if (!body) {
    return {
      status: CompletionStatuses.INCOMPLETE,
      subtopicPhrases
    };
  }

  const phrasesFoundInBody = subtopicPhrases.filter(phrase => body.includes(phrase));

  if (phrasesFoundInBody.length >= MIN_SUBTOPIC_PHRASES_IN_BODY || phrasesFoundInBody.length === subtopicPhrases.length) {
    return {
      status: CompletionStatuses.COMPLETE
    };
  }

  return {
    status: CompletionStatuses.INCOMPLETE,
    subtopicPhrases
  };
};