export const DrawerTypes = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  DOCUMENT: 'DOCUMENT',
  FILE: 'FILE',
  AUDIO: 'AUDIO'
};
export const PickerTypes = {
  PANEL: 'PANEL',
  SIDE_MODAL: 'SIDE_MODAL'
};
export const PickerPanels = {
  BROWSE: 'BROWSE',
  SEARCH: 'SEARCH',
  STOCK_IMAGES: 'STOCK_IMAGES',
  FOLDER: 'FOLDER',
  FROM_URL: 'FROM_URL',
  FILE_DETAIL: 'FILE_DETAIL',
  BULK_IMPORT_FROM_URL: 'BULK_IMPORT_FROM_URL',
  IMAGE_GENERATION: 'IMAGE_GENERATION'
};
export const IframeComponentTypes = {
  FILE_PICKER: 'FILE_PICKER',
  IMAGE_EDITOR: 'IMAGE_EDITOR'
};
export const FileTypes = {
  IMG: 'IMG',
  MOVIE: 'MOVIE',
  DOCUMENT: 'DOCUMENT',
  AUDIO: 'AUDIO',
  OTHER: 'OTHER'
};
export const FMMessageType = 'FILE_MANAGER';
export const MessageActions = {
  IFRAME_READY: 'IFRAME_READY',
  OPEN_PICKER: 'OPEN_PICKER',
  OPEN_IMAGE_EDITOR: 'OPEN_IMAGE_EDITOR',
  OPEN_PICKER_COMPLETE: 'OPEN_PICKER_COMPLETE',
  CLOSE_PICKER: 'CLOSE_PICKER',
  ENTER_FULLSCREEN: 'ENTER_FULLSCREEN',
  EXIT_FULLSCREEN: 'EXIT_FULLSCREEN',
  UPLOAD_STARTED: 'UPLOAD_STARTED',
  UPLOAD_COMPLETE: 'UPLOAD_COMPLETE',
  CLOSE_PANEL: 'CLOSE_PANEL',
  SELECT_FILE: 'SELECT_FILE'
};
export const FileExtensionFilterTypes = {
  SUPPORTED: 'SUPPORTED',
  UNSUPPORTED: 'UNSUPPORTED',
  NONE: 'NONE'
};
export const PANEL_WIDTH = 590;
export const PANEL_WIDTH_EXPANDED = PANEL_WIDTH;
export const ThumbnailSizes = {
  ICON: 'icon',
  THUMB: 'thumb',
  PREVIEW: 'preview',
  MEDIUM: 'medium'
};