'use es6';

import { MessageTypes } from '../Constants';

const serializeError = errorMessage => {
  if (errorMessage instanceof Error) {
    const {
      message
    } = errorMessage;
    return {
      message
    };
  }

  return errorMessage;
};

export const serialize = (message, type) => {
  switch (type) {
    case MessageTypes.JSON:
      return JSON.stringify(message);

    case MessageTypes.ERROR:
      return serializeError(message);

    case MessageTypes.RAW:
    case MessageTypes.API_REQUEST:
    case MessageTypes.API_RESPONSE:
    default:
      return message;
  }
};
export const parse = (message, type) => {
  switch (type) {
    case MessageTypes.JSON:
      return JSON.parse(message);

    case MessageTypes.ERROR:
    case MessageTypes.RAW:
    case MessageTypes.API_REQUEST:
    case MessageTypes.API_RESPONSE:
    default:
      return message;
  }
};