import { createSelector } from 'reselect';
import Categories from 'ContentUtils/constants/Categories'; // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Cont... Remove this comment to see the full error message

import { getSubcategory } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers'; // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Cont... Remove this comment to see the full error message

import { getContentSchema } from 'ContentEditorUI/redux/selectors/contentSchemaSelectors';
export const getContentReadOnlyData = basicSelector(state => state.contentReadOnlyData);
export const getCategoryId = createSelector([getContentReadOnlyData], contentData => contentData.get('categoryId'));
export const getIsBlogPost = createSelector([getCategoryId], categoryId => categoryId === Categories.blog_post.id);
export const getIsEmail = createSelector([getCategoryId], categoryId => categoryId === Categories.email.id);
export const getIsSms = createSelector([getCategoryId], categoryId => categoryId === Categories.sms.id);
export const getIsPage = createSelector([getCategoryId], categoryId => categoryId === Categories.landing_page.id || categoryId === Categories.blog_listing_page.id);
export const getIsInstanceLayoutPage = createSelector([getContentSchema], ({
  content
}) => content.isInstanceLayoutPage);
export const getIsBlogListingPage = createSelector([getCategoryId], categoryId => categoryId === Categories.blog_listing_page.id);
export const getIsSitePage = createSelector([getSubcategory], subcategory => subcategory === 'site_page');
export const getIsLandingPage = createSelector([getIsPage, getIsSitePage, getIsBlogListingPage], (isPage, isSitePage, isBlogListingPage) => isPage && !isSitePage && !isBlogListingPage);
export const getIsLandingOrSitePage = createSelector([getIsPage, getIsSitePage, getIsBlogListingPage], (isPage, isSitePage) => isPage || isSitePage);
export const getIsPageOrBlog = createSelector([getIsPage, getIsBlogPost], (isPage, isBlogPost) => isPage || isBlogPost);
export const getContentTypeId = createSelector([getCategoryId, getIsSitePage], (categoryId, isSitePage) => {
  if (isSitePage) {
    return Categories.site_page.id;
  }

  return categoryId;
}); // This doesn't seem necessary unless we update the Categories file in
// ContentUILib with all new consumers of scalable editors

export const getFullCategory = createSelector([getCategoryId], categoryId => Categories[categoryId]);
export const getFullCategoryName = createSelector([getFullCategory], fullCategory => fullCategory.name);
export const getUpdated = createSelector([getContentReadOnlyData], contentData => contentData.get('updated'));
export const getUpdatedById = createSelector([getContentReadOnlyData], contentData => contentData.get('updatedById'));
export const getTranslatedContent = createSelector([getContentReadOnlyData], contentData => contentData.get('translatedContent'));
export const getTranslatedContentValuesArray = createSelector(getTranslatedContent, translatedContent => translatedContent.valueSeq().toJS());
export const getAbsoluteUrl = createSelector([getContentReadOnlyData], contentData => contentData.get('absoluteUrl'));
export const getSecurityState = createSelector([getContentReadOnlyData], contentData => contentData.get('securityState'));
export const getContentHasAB = createSelector([getContentReadOnlyData], contentData => contentData.get('ab'));
export const getContentHasMAB = createSelector([getContentReadOnlyData], contentData => contentData.get('mab'));
export const getIsContentFlaggedOrViolated = createSelector([getSecurityState], securityState => securityState && ['FLAGGED_FOR_REVIEW', 'SECURITY_VIOLATION'].includes(securityState));
export const getIsContentSafe = createSelector([getIsContentFlaggedOrViolated], isFlaggedOrViolated => !isFlaggedOrViolated);
export const getContentGroupId = createSelector([getContentReadOnlyData], contentData => contentData.get('contentGroupId'));
export const getContentSchemaSchemaForInpage = createSelector([getContentSchema, getAbsoluteUrl, getFullCategory], (contentSchema, absoluteUrl, category) => {
  const schema = {
    absoluteUrl,
    portalId: window.hubspot.portal.id,
    category: {
      field_name: category.field_name
    },
    context: {
      active_domain: {
        domain: contentSchema.context.active_domain.domain,
        isSslOnly: contentSchema.context.active_domain.isSslOnly
      }
    },
    module_missing_errors: contentSchema.schema.module_missing_errors
  };
  return schema;
});
export const getCrmObjectId = createSelector([getContentReadOnlyData], contentData => contentData.get('crmObjectId'));