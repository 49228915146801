const NAMESPACE = 'editor-platform';

let _usageTracker;

export const getUsageTracker = usageTracker => {
  if (_usageTracker) {
    return _usageTracker;
  }

  _usageTracker = usageTracker.clone({
    properties: {
      namespace: NAMESPACE,
      app: NAMESPACE
    }
  });
  return _usageTracker;
};