import { combineReducers } from 'redux';
import * as RequestStatus from 'ContentUtils/constants/RequestStatus';
import { TEAMS_FETCH_TEAMS, TEAMS_UPDATE_TEAMS_DATA } from 'ContentData/actions/ActionTypes';

const teams = (state = {
  data: []
}, action) => {
  switch (action.type) {
    case TEAMS_FETCH_TEAMS:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          data: response
        });
      }

    case TEAMS_UPDATE_TEAMS_DATA:
      {
        const {
          data
        } = action;
        if (!data) return state;
        return Object.assign({}, state, {
          data
        });
      }

    default:
      return state;
  }
};

const teamsRequestStatus = (state = RequestStatus.UNINITIALIZED, action) => {
  switch (action.type) {
    case TEAMS_FETCH_TEAMS:
      {
        const {
          response,
          error
        } = action;
        if (response) return RequestStatus.SUCCEEDED;
        if (error) return RequestStatus.FAILED;
        return RequestStatus.PENDING;
      }

    default:
      return state;
  }
};

export default combineReducers({
  teams,
  requestStatus: teamsRequestStatus
});