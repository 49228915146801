'use es6';

import { FETCH_CONTENT_SCHEMA_SUCCEEDED, FETCH_HARD_SAVED_CONTENT_MODEL_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import Immutable from 'immutable'; // Stores the hard-saved content model for the cases where we need that
// rather than the buffer (eg. differentiating scheduled vs published pages)

const hardSavedContentModelReducer = (state = Immutable.Map(), {
  type,
  payload,
  response
}) => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const hardContentInfo = response.context.hard_content_info;
        return state.merge({
          publishDate: hardContentInfo.publish_date,
          state: hardContentInfo.state,
          publishImmediately: hardContentInfo.publish_immediately,
          scheduledUpdateDate: response.content.scheduledUpdateDate
        });
      }

    case FETCH_HARD_SAVED_CONTENT_MODEL_SUCCEEDED:
      {
        return state.merge(payload);
      }

    default:
      return state;
  }
};

export default hardSavedContentModelReducer;