'use es6';

const STRING_COLLATOR = window.Intl && typeof window.Intl === 'object' && new Intl.Collator();
export default ((option1, option2) => {
  const option1Text = option1.text;
  const option2Text = option2.text;
  let comparisonResult = 0;

  if (STRING_COLLATOR) {
    comparisonResult = STRING_COLLATOR.compare(option1Text, option2Text);
  } else if (option1Text > option2Text) {
    comparisonResult = 1;
  } else if (option2Text > option1Text) {
    comparisonResult = -1;
  }

  return comparisonResult;
});