'use es6';

import { createSelector } from 'reselect';
import { getIsBlogListingPage, getIsBlogPost, getIsEmail, getIsSms, getIsPage, getIsSitePage, getCategoryId } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getCategoryName } from 'ContentEditorUI/lib/categories/utils';
import { getActiveStagingDomain } from 'ContentEditorUI/redux/selectors/portalDomainsSelectors';
const ContentTypeSlugs = {
  STAGING: 'staging',
  BLOG_POST: 'blog',
  SITE_PAGE: 'sitePage',
  LANDING_PAGE: 'landingPage',
  EMAIL: 'email',
  SMS: 'sms',
  BLOG_LISTING_PAGE: 'blogListingPage'
};
export const getContentTypeSlug = createSelector([getActiveStagingDomain, getIsBlogPost, getIsPage, getIsSitePage, getIsEmail, getIsSms, getIsBlogListingPage], (isStagedPage, isBlogPost, isPage, isSitePage, isEmail, isSms, isBlogListingPage) => {
  if (isStagedPage) {
    return ContentTypeSlugs.STAGING;
  } else if (isBlogPost) {
    return ContentTypeSlugs.BLOG_POST;
  } else if (isSitePage) {
    return ContentTypeSlugs.SITE_PAGE;
  } else if (isBlogListingPage) {
    return ContentTypeSlugs.BLOG_LISTING_PAGE;
  } else if (isPage) {
    return ContentTypeSlugs.LANDING_PAGE;
  } else if (isEmail) {
    return ContentTypeSlugs.EMAIL;
  } else if (isSms) {
    return ContentTypeSlugs.SMS;
  }

  return '';
});
export const getContentTypeName = createSelector([getCategoryId, getIsSitePage], getCategoryName);