import { Map as ImmutableMap } from 'immutable';
import { FETCH_CONTENT_SCHEMA_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes'; // This reducer is for the few values pulled off of the content part of the
// server response that don't fit into a specific category themselves, and
// aren't being saved so don't belong on the contentModel.
//

const contentReadOnlyDataReducer = (state = ImmutableMap(), {
  type,
  response
}) => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      return state.merge({
        // Defaults to the "unmapped" category ID if a category ID does not exist
        categoryId: response.content.categoryId || 0,
        updated: response.content.updated,
        updatedById: response.content.updatedById,
        translatedContent: response.content.translatedContent,
        absoluteUrl: response.content.absoluteUrl,
        securityState: response.content.securityState,
        contentGroupId: response.content.contentGroupId,
        ab: response.content.ab,
        mab: response.content.mab,
        crmObjectId: response.content.crmObjectId
      });

    default:
      return state;
  }
};

export default contentReadOnlyDataReducer;