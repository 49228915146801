import Categories from 'ContentUtils/constants/Categories';
import ContentRoutes from 'ContentUtils/Routes';
import { getContentTypeId, getShareablePreviewURLForContent, getSocialComposerOptions } from './contentTableUtils';
import redirectToNewUrl from './redirectToNewUrl';
export const getContentPrimaryActions = ({
  hasSocialAccess,
  content,
  handleOpenModal
}) => {
  const primaryActions = {};
  const {
    id: contentId,
    categoryId,
    subcategory
  } = content;

  primaryActions.EDIT = (_, {
    metaKey,
    ctrlKey
  }) => {
    const url = ContentRoutes.edit(contentId, categoryId);

    if (metaKey || ctrlKey) {
      window.open(url);
    } else {
      redirectToNewUrl(url);
    }
  };

  primaryActions.VIEW = () => {
    const url = getShareablePreviewURLForContent(content);
    window.open(url);
  };

  primaryActions.DETAILS = () => {
    const detailUrl = ContentRoutes.details(contentId, categoryId, subcategory, {
      referrer: window.location.href
    });
    redirectToNewUrl(detailUrl);
  };

  primaryActions.CLONE = () => {
    handleOpenModal('CLONE', content);
  };

  primaryActions.DELETE = () => {
    handleOpenModal('DELETE', content);
  };

  if (hasSocialAccess) {
    primaryActions.SHARE = () => {
      const socialComposerOptions = getSocialComposerOptions(content);
      handleOpenModal('SHARE', content, socialComposerOptions);
    };
  }

  return primaryActions;
};
export const getDisabledActions = ({
  content,
  currentlyOpenContentId,
  getUsageLimitInfoForContentTypeId
}) => {
  const disabledActions = {};
  const contentTypeId = getContentTypeId(content);
  const {
    cannotCreateMoreContent,
    disabledTooltipSuffix
  } = getUsageLimitInfoForContentTypeId(contentTypeId);

  if (currentlyOpenContentId === content.id) {
    disabledActions.EDIT = {
      disabled: true,
      disabledTooltipTitle: 'contentManagementPanel.table.disabledActions.EDIT.alreadyEditingContent',
      disabledTooltipOptions: {
        contentName: content.name
      }
    };
    disabledActions.DELETE = {
      disabled: true,
      disabledTooltipTitle: 'contentManagementPanel.table.disabledActions.DELETE.alreadyEditingContent',
      disabledTooltipOptions: {
        contentName: content.name
      }
    };
  }

  if (content.translatedContent && Object.keys(content.translatedContent).length > 0 && !content.translatedFromId) {
    disabledActions.DELETE = {
      disabled: true,
      disabledTooltipTitle: 'contentManagementPanel.table.disabledActions.DELETE.cannotDeletePrimary',
      disabledTooltipOptions: {}
    };
  }

  if (content.contentTypeCategoryId === Categories.blog_listing_page.id) {
    disabledActions.CLONE = {
      disabled: true,
      disabledTooltipTitle: 'contentManagementPanel.table.disabledActions.CLONE.blogListingPage',
      disabledTooltipOptions: {}
    };
  }

  if (cannotCreateMoreContent) {
    disabledActions.CLONE = {
      disabled: true,
      disabledTooltipTitle: `contentManagementPanel.table.disabledActions.CLONE.${disabledTooltipSuffix}`,
      disabledTooltipOptions: {}
    };
  }

  return disabledActions;
};