import { CMS_HUB_PROFESSIONAL, MARKETING_HUB_PROFESSIONAL, OPERATIONS_HUB_PROFESSIONAL, SALES_HUB_PROFESSIONAL, SERVICE_HUB_PROFESSIONAL } from 'self-service-api/constants/ApiNames';
export const ProApiNames = [MARKETING_HUB_PROFESSIONAL, CMS_HUB_PROFESSIONAL, SALES_HUB_PROFESSIONAL, SERVICE_HUB_PROFESSIONAL, OPERATIONS_HUB_PROFESSIONAL];
export const MarketingHubProDefaultSurveyResponses = {
  social: false,
  seo: false,
  workflows: false,
  campaigns: false,
  customReportsBuilder: false
};
export const SalesHubProDefaultSurveyResponses = {
  forecasts: false,
  products: false,
  quotes: false,
  workflows: false,
  sequences: false,
  salesAnalytics: false
};
export const ServiceHubProDefaultSurveyResponses = {
  sla: false,
  customerPortal: false,
  knowledgeBase: false,
  serviceEssentials: false,
  workflows: false
};
export const CMSHubProDefaultSurveyResponses = {
  sitePages: false,
  cmsEssentials: false,
  seo: false,
  smartContent: false,
  hubDb: false
};
export const OperationsHubProDefaultSurveyResponses = {
  triggerWorkflows: false,
  automateFormatting: false,
  dataQualityCommandCenter: false,
  webhooks: false,
  customCodedWorkflowActions: false
};
export const upgradeProductSurveyResponseMap = {
  [MARKETING_HUB_PROFESSIONAL]: MarketingHubProDefaultSurveyResponses,
  [SALES_HUB_PROFESSIONAL]: SalesHubProDefaultSurveyResponses,
  [SERVICE_HUB_PROFESSIONAL]: ServiceHubProDefaultSurveyResponses,
  [CMS_HUB_PROFESSIONAL]: CMSHubProDefaultSurveyResponses,
  [OPERATIONS_HUB_PROFESSIONAL]: OperationsHubProDefaultSurveyResponses
};
export const isProApiName = apiName => {
  if (typeof apiName === 'number') {
    return false;
  }

  return ProApiNames.includes(apiName);
};