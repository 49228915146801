import { Component } from 'react';
import { captureException } from '../../utils/sentryUtils';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      errorInfo,
      error
    });
    captureException(new Error('Product Approvals error'), {
      level: 'error',
      extra: {
        error
      }
    });
  }

  render() {
    if (this.state.errorInfo) {
      return null;
    }

    return this.props.children;
  }

}

export default ErrorBoundary;