const NAMESPACE = 'content-assistance-lib';
let _usageTracker = null;
export const getUsageTracker = (usageTracker, usageDescription) => {
  if (_usageTracker) {
    return _usageTracker;
  }

  _usageTracker = usageTracker.clone({
    properties: {
      namespace: NAMESPACE,
      usageDescription
    },
    preserveTrackerProperties: false
  });
  return _usageTracker;
};
export const getCurrentPrompt = (commandId, parameters) => {
  const currentPrompt = `commandId: ${commandId} - parameters: ${JSON.stringify(parameters)}`;
  return currentPrompt;
};