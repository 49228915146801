'use es6';

import * as FieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
import { makeErrorThrowingAsyncComponent } from 'ContentEditorUI/components/shared/makeAsyncUtils';
const AsyncRichTextField = makeErrorThrowingAsyncComponent(() => {
  return Promise.all([import(
  /* webpackChunkName: 'RichTextField' */
  'ContentEditorUI/components/sidebar/edit/LazyStandardRichTextField'), importDlb('codemirror-dlb/bundle.js')]).then(([mod]) => mod.default()).catch(() => {
    throw new Error('Error loading CodeMirror or dependent module');
  });
});
const AsyncFormField = makeErrorThrowingAsyncComponent(() => import(
/* webpackPrefetch: true, webpackChunkName: 'FormField' */
'ContentEditorUI/components/sidebar/edit/FormEditorContainer'));
const AsyncSimpleMenuField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'SimpleMenuField', webpackPrefetch: true */
'ContentEditorUI/components/sidebar/edit/PopOutSimpleMenuEditor')); // Standard mofo fields

const AsyncBooleanField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'BooleanField' */
'ContentComponents/cmv2/fields/Boolean'));
const AsyncChoiceField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'ChoiceField' */
'ContentComponents/cmv2/fields/Choice'));
const AsyncColorPickerField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'ColorPickerField' */
'ContentComponents/cmv2/fields/ColorPicker'));
const AsyncCTAField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'CtaField' */
'ContentComponents/cmv2/fields/Cta'));
const AsyncDateTimeField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'DateTimeField' */
'ContentComponents/cmv2/fields/DateTime'));
const AsyncEmailField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'EmailField' */
'ContentComponents/cmv2/fields/Email'));
const AsyncFileField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'FileField' */
'ContentComponents/cmv2/fields/File'));
const AsyncHubDbField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'HubDb' */
'ContentComponents/cmv2/fields/HubDb'));
const AsyncImageField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'ImageField', webpackPrefetch: true */
'ContentComponents/cmv2/fields/Image'));
const AsyncNumberField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'NumberField' */
'ContentComponents/cmv2/fields/Number'));
const AsyncTextField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'TextField', webpackPrefetch: true */
'ContentComponents/cmv2/fields/Text'));
const AsyncLogoField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'LogoField', webpackPrefetch: true */
'ContentComponents/cmv2/fields/Logo'));
const AsyncIconPickerField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'IconPickerField' */
'ContentComponents/cmv2/fields/IconPicker'));
const AsyncSalesforceCampaignField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'SalesforceCampaign, webpackPrefetch: true' */
'ContentComponents/cmv2/fields/SalesforceCampaign'));
const AsyncFollowupEmailField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'FollowupEmail' */
'ContentComponents/cmv2/fields/FollowupEmail'));
const AsyncBlogField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'BlogField' */
'ContentComponents/cmv2/fields/Blog'));
const AsyncPageField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'PageSelectField' */
'ContentComponents/cmv2/fields/PageSelect'));
const AsyncFontPickerField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'FontPickerField' */
'ContentComponents/cmv2/fields/FontPicker'));
const AsyncMenuSelect = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'MenuSelectField', webpackPrefetch: true */
'ContentComponents/cmv2/fields/MenuSelect'));
const AsyncTagSelect = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'TagSelectField' */
'ContentComponents/cmv2/fields/TagSelect'));
const AsyncWorkflowSelect = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'WorkflowSelectField' */
'ContentComponents/cmv2/fields/WorkflowSelect'));
const AsyncFieldGroup = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'FieldGroupField' */
'ContentComponents/cmv2/fields/FieldGroup'));
const AsyncMeetingSelect = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'MeetingSelectField' */
'ContentComponents/cmv2/fields/MeetingSelect'));
const AsyncUrl = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'UrlField', webpackPrefetch: true */
'ContentComponents/cmv2/fields/Url'));
const AsyncLink = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'LinkField', webpackPrefetch: true */
'ContentComponents/cmv2/fields/Link'));
const AsyncLocation = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'LocationField', webpackPrefetch: true */
'ContentComponents/cmv2/fields/Location'));
const AsyncVideo = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'VideoField' */
'ContentComponents/cmv2/fields/Video'));
const AsyncSurvey = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'SurveyField' */
'ContentComponents/cmv2/fields/Survey'));
const AsyncCrmObject = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'CrmObjectField' */
'ContentComponents/cmv2/fields/CrmObject'));
const AsyncCrmObjectProperty = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'CrmObjectPropertyField' */
'ContentComponents/cmv2/fields/CrmObjectProperty'));
const AsyncEmbed = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'EmbedField' */
'ContentComponents/cmv2/fields/Embed'));
const AsyncBackgroundImage = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'BackgroundImageField' */
'ContentComponents/cmv2/fields/BackgroundImage'));
const AsyncSpacing = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'SpacingField' */
'ContentComponents/cmv2/fields/Spacing'));
const AsyncGradient = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'GradientField' */
'ContentComponents/cmv2/fields/Gradient'));
const AsyncBorder = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'BorderField' */
'ContentComponents/cmv2/fields/Border'));
const AsyncHubDbRowField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'HubDbRow' */
'ContentComponents/cmv2/fields/HubDbRow'));
const AsyncAlignmentField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'AlignmentField' */
'ContentComponents/cmv2/fields/Alignment'));
const AsyncTextAlignmentField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'TextAlignmentField' */
'ContentComponents/cmv2/fields/TextAlignment'));
const AsyncPaymentField = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'PaymentField' */
'ContentComponents/cmv2/fields/Payment'));
const AsyncAudio = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'AudioField' */
'ContentComponents/cmv2/fields/Audio'));
const AsyncProduct = makeErrorThrowingAsyncComponent(() => import(
/* webpackChunkName: 'ProductField' */
'ContentComponents/cmv2/fields/Product'));
export default {
  [FieldTypes.IMAGE]: AsyncImageField,
  [FieldTypes.FILE]: AsyncFileField,
  [FieldTypes.HUBDB_TABLE]: AsyncHubDbField,
  [FieldTypes.TEXT]: AsyncTextField,
  [FieldTypes.RICH_TEXT]: AsyncRichTextField,
  [FieldTypes.CHOICE]: AsyncChoiceField,
  [FieldTypes.NUMBER]: AsyncNumberField,
  [FieldTypes.BOOLEAN]: AsyncBooleanField,
  [FieldTypes.COLOR]: AsyncColorPickerField,
  [FieldTypes.CTA]: AsyncCTAField,
  [FieldTypes.PAGE]: AsyncPageField,
  [FieldTypes.FORM]: AsyncFormField,
  [FieldTypes.WORKFLOW]: AsyncWorkflowSelect,
  [FieldTypes.FOLLOW_UP_EMAIL]: AsyncFollowupEmailField,
  [FieldTypes.BLOG]: AsyncBlogField,
  [FieldTypes.MENU]: AsyncMenuSelect,
  [FieldTypes.TAG]: AsyncTagSelect,
  [FieldTypes.MEETING]: AsyncMeetingSelect,
  [FieldTypes.DATE]: AsyncDateTimeField,
  [FieldTypes.DATE_TIME]: AsyncDateTimeField,
  [FieldTypes.EMAIL]: AsyncEmailField,
  [FieldTypes.SIMPLE_MENU]: AsyncSimpleMenuField,
  [FieldTypes.LOGO]: AsyncLogoField,
  [FieldTypes.ICON]: AsyncIconPickerField,
  [FieldTypes.SFDC_CAMPAIGN]: AsyncSalesforceCampaignField,
  [FieldTypes.FONT]: AsyncFontPickerField,
  [FieldTypes.GROUP]: AsyncFieldGroup,
  [FieldTypes.URL]: AsyncUrl,
  [FieldTypes.LINK]: AsyncLink,
  [FieldTypes.LOCATION]: AsyncLocation,
  [FieldTypes.VIDEO]: AsyncVideo,
  [FieldTypes.SURVEY]: AsyncSurvey,
  [FieldTypes.CRM_OBJECT]: AsyncCrmObject,
  [FieldTypes.EMBED]: AsyncEmbed,
  [FieldTypes.BACKGROUND_IMAGE]: AsyncBackgroundImage,
  [FieldTypes.SPACING]: AsyncSpacing,
  [FieldTypes.GRADIENT]: AsyncGradient,
  [FieldTypes.BORDER]: AsyncBorder,
  [FieldTypes.HUBDB_ROW]: AsyncHubDbRowField,
  [FieldTypes.ALIGNMENT]: AsyncAlignmentField,
  [FieldTypes.TEXT_ALIGNMENT]: AsyncTextAlignmentField,
  [FieldTypes.PAYMENT]: AsyncPaymentField,
  [FieldTypes.CRM_OBJECT_PROPERTY]: AsyncCrmObjectProperty,
  [FieldTypes.AUDIO]: AsyncAudio,
  [FieldTypes.PRODUCT]: AsyncProduct
};