import { UE_APPROVAL_CONCLUDED_ID } from '../constants/unifiedEventTypeIds';
export const checkUnifiedEventsPropertiesUtils = event => {
  const requiredPropertiesForAll = ['hs_steps_approvers_owners_ids_list', 'hs_approval_type', 'hs_approvable_object_id'];
  const missingProperties = requiredPropertiesForAll.filter(property => {
    if (property in event.properties) {
      return false;
    }

    return property;
  });

  if (event.eventTypeId.innerId !== UE_APPROVAL_CONCLUDED_ID && !('hs_submitted_by_user_id' in event.properties)) {
    missingProperties.push('hs_submitted_by_user_id');
  }

  if (missingProperties.length > 0) {
    throw Error(`Unified events missing properties: ${missingProperties}`);
  }

  return null;
};