import Raven from 'raven-js';
import { useCallback, useEffect, useState } from 'react';
import { submitInteraction } from 'self-service-api/api/submitInteraction';
import { LoadingModal } from 'ui-addon-upgrades/_core/common/components/LoadingModal';
import { UpgradeMessageEventKeys } from 'ui-addon-upgrades/_core/common/constants/upgradeMessageEventKeys';
import { getCanViewNewSeats } from '../_core/common/eligibility/getCanViewNewSeats';
import { track } from '../_core/common/eventTracking/tracker';
import { redirectUserToPPP, shouldRedirectToPersonalizedPP } from '../_core/utils/personalizedPricingPageRedirect';
import { useModalFrame } from '../useModalFrame';
import useErrorAlert from './useErrorAlert';
import { UpgradePointStatus, getFrameSrc, getOwnedProductsPromise, getUpgradeDataTrackingProperties, getUpgradePointPromise, updateViewedUpgradePointsCache, warnArchivedUpgradePointUse } from './utils';
/**
 * ## Upgrade Management System
 *
 * `useUpgradeModal` takes your upgrade key as an argument and returns a callback
 * to open the upgrade modal. Upgrade keys can be created and managed in the
 * [Upgrade Management System](<https://tools.hubteamqa.com/ums>) (`go/ums`).
 *
 *
 * _Note that if you are using `useUpgradeModal` from inside of an iframe,
 * you will need to call `useUpgradeEventListener` in the top-most window
 * of your application. This will allow your upgrade modal to open in the
 * top window instead of opening inside of the iframe._
 *
 * @example
 * ```tsx
 * const YourComponent = () => {
 *  const { openUpgradeModal } = useUpgradeModal('your-upgrade-key');
 *
 *  return <UIButton onClick={openUpgradeModal} />;
 * }
 * ```
 */

export function useUpgradeModal(upgradeKey, isFeatureLocked = true) {
  const frameSrc = getFrameSrc(upgradeKey);
  const upgradePointPromise = getUpgradePointPromise({
    upgradeKey,
    delayCall: true
  });
  const ownedProductsPromise = getOwnedProductsPromise({
    delayCall: true
  });
  const {
    setLoadFrame
  } = useModalFrame({
    id: 'ui-addon-upgrade-modal-frame',
    src: frameSrc,
    renderLoadingModal: LoadingModal
  });
  const {
    setShowErrorAlert
  } = useErrorAlert();
  const [hasError, setHasError] = useState(false);
  const [canViewNewSeats, setCanViewNewSeats] = useState(false);
  const [shouldRedirectToPPP, setShouldRedirectToPPP] = useState(); // Fetch upgrade config and track view

  useEffect(() => {
    if (isFeatureLocked && upgradeKey) {
      Promise.all([getCanViewNewSeats(), upgradePointPromise, ownedProductsPromise]).then(([newSeatsResponse, upgradePoint, ownedProducts]) => {
        if (!upgradePoint) return;
        setCanViewNewSeats(newSeatsResponse);
        setShouldRedirectToPPP(shouldRedirectToPersonalizedPP(upgradePoint.apiNames[0], ownedProducts));
        updateViewedUpgradePointsCache(upgradeKey, () => {
          track('interaction', Object.assign({
            action: 'viewed upgrade point'
          }, getUpgradeDataTrackingProperties(upgradePoint, true, true, newSeatsResponse)));

          if (upgradePoint.status === UpgradePointStatus.ARCHIVED) {
            warnArchivedUpgradePointUse(upgradePoint.upgradeKey);
          }
        });
      }).catch(err => {
        console.error(err);
        setHasError(true);
      });
    }
  }, [isFeatureLocked, upgradeKey, upgradePointPromise, ownedProductsPromise]); // Track click and set state

  const openUpgradeModal = useCallback(() => {
    if (!isFeatureLocked) {
      return;
    }

    if (hasError) {
      setShowErrorAlert(true);
      return;
    }

    if (shouldRedirectToPPP) {
      redirectUserToPPP(upgradeKey);
    } else {
      // In iframes, opening the modal has to be
      // handled by the top window, which must be
      // listening using `useUpgradeEventListener`
      const isInIframe = window.self !== window.top;

      if (isInIframe) {
        window.top.postMessage({
          modalKey: upgradeKey,
          event: UpgradeMessageEventKeys.OPEN_MODAL
        }, '*');
        return;
      }

      setLoadFrame(true);
    }

    Promise.all([getCanViewNewSeats(), upgradePointPromise]).then(([newSeatsResponse, upgradePoint]) => {
      if (!upgradePoint) return;
      setCanViewNewSeats(newSeatsResponse);
      track('interaction', Object.assign({
        action: 'clicked upgrade point'
      }, getUpgradeDataTrackingProperties(upgradePoint, true, true, canViewNewSeats)));
      submitInteraction(upgradeKey).catch(error => Raven.captureException(new Error('Failed to submit interaction with upgrade modal'), {
        extra: {
          error
        }
      }));
    }).catch(() => {
      setLoadFrame(false);
      setShowErrorAlert(true);
    });
  }, [setLoadFrame, isFeatureLocked, upgradeKey, upgradePointPromise, hasError, setShowErrorAlert, canViewNewSeats, shouldRedirectToPPP]);
  return {
    openUpgradeModal
  };
}
export function useUpgradeModalInIframe(upgradeKey, isFeatureLocked = true) {
  // Post message to useUpgradeEventListener
  useEffect(() => {
    window.top.postMessage({
      modalKey: upgradeKey,
      event: UpgradeMessageEventKeys.FETCH_UPGRADE_POINT,
      isFeatureLocked
    }, '*');
  }, [upgradeKey, isFeatureLocked]); // Handle opening the modal

  const openUpgradeModal = useCallback(() => {
    if (!isFeatureLocked) {
      return;
    }

    window.top.postMessage({
      modalKey: upgradeKey,
      event: UpgradeMessageEventKeys.OPEN_MODAL
    }, '*');
  }, [isFeatureLocked, upgradeKey]); // return the callback for use in app

  return {
    openUpgradeModal
  };
}