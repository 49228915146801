'use es6';

import { FETCH_USERS_SUCCEEDED, FETCH_USERS_FAILED } from 'ContentEditorUI/redux/actions/actionTypes';

const userReducer = (state = [], {
  type,
  payload
}) => {
  switch (type) {
    case FETCH_USERS_SUCCEEDED:
      return payload;

    case FETCH_USERS_FAILED:
      console.warn(`Error fetching users ${payload}`);
    // eslint-disable-next-line no-fallthrough

    default:
      return state;
  }
};

export default userReducer;