import { useContext } from 'react';
import SuspensionBannersContext from '../contexts/suspensionBannersContext';
import useBanner from '../hooks/useBanner';

const useSuspensionBanners = scope => {
  const {
    statusFor,
    bannerHiddenByUserFor
  } = useContext(SuspensionBannersContext);
  const {
    showBanner,
    scopeDisabled
  } = useBanner(bannerHiddenByUserFor(scope), statusFor(scope));
  return {
    bannerVisible: showBanner,
    scopeDisabled
  };
};

export default useSuspensionBanners;