import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// @ts-expect-error Not migrated yet
import { setScrollTo } from 'ContentEditorUI/redux/actions/baseSettingsActions'; // @ts-expect-error Not migrated yet

import { openModal } from 'ContentEditorUI/redux/actions/appActions';

const useSetSettingsModalOpenWithNavItem = modalType => {
  const dispatch = useDispatch();
  const setSettingsModalOpenWithNavItem = useCallback(navItem => {
    dispatch(openModal(modalType));
    dispatch(setScrollTo(navItem));
  }, [dispatch, modalType]);
  return setSettingsModalOpenWithNavItem;
};

export default useSetSettingsModalOpenWithNavItem;