import IframeStylesManager from './IframeStylesManager';
import { PANEL_ANIMATION_TYPES } from 'editor-components/EPConstants';
const TOOLBAR_WIDTH = 52;
const TOOLBAR_WIDTH_WITH_PREVIEW_IFRAME_WRAPPER = 55;

function shouldAnimatePreview(animationType) {
  switch (animationType) {
    case PANEL_ANIMATION_TYPES.PUSHY_TO_PUSHY:
    case PANEL_ANIMATION_TYPES.PUSHY_TO_FLOATY:
    case PANEL_ANIMATION_TYPES.FLOATY_TO_PUSHY:
    case PANEL_ANIMATION_TYPES.PUSHY_TO_NONE:
    case PANEL_ANIMATION_TYPES.NONE_TO_PUSHY:
      return true;

    default:
      return false;
  }
}

class PreviewIframeStylesManager extends IframeStylesManager {
  constructor(...args) {
    super(...args);
    this._toggleAnimationInProgress = false;
    this._hasPreviewIframeWrapper = false;
  }

  requestIframeStyleChangesFromProxy(styleObjects) {
    if (this.getIsAnimationInProgress()) {
      return;
    }

    if (styleObjects) {
      window.requestAnimationFrame(() => {
        styleObjects.forEach(styleObject => {
          const {
            styleKey,
            styleValue
          } = styleObject;

          if (!this.iframeWrapperElement) {
            return;
          }

          this.iframeWrapperElement.style.setProperty(styleKey, styleValue);
        });
      });
    }
  }

  onToggleAnimationStart() {
    this._toggleAnimationInProgress = true;
  }

  onToggleAnimationEnd() {
    this._toggleAnimationInProgress = false;
  }

  getIsAnimationInProgress() {
    return this._toggleAnimationInProgress;
  }

  setHasPreviewIframeWrapper(value) {
    return this._hasPreviewIframeWrapper = value;
  }

  getHasPreviewIframeWrapper() {
    return this._hasPreviewIframeWrapper;
  }

  onPreviewToggleAnimationFrame(newLeftPosition, animationType) {
    if (this.iframeWrapperElement === null) {
      return;
    }

    if (shouldAnimatePreview(animationType)) {
      if (animationType === PANEL_ANIMATION_TYPES.PUSHY_TO_FLOATY) {
        this.iframeWrapperElement.style.left = `${TOOLBAR_WIDTH}px`;
      } else {
        const finalToolbarWidth = this.getHasPreviewIframeWrapper() ? TOOLBAR_WIDTH_WITH_PREVIEW_IFRAME_WRAPPER : TOOLBAR_WIDTH;
        this.iframeWrapperElement.style.left = `${finalToolbarWidth + newLeftPosition}px`;
      }
    }
  }

}

const PreviewIframeStylesManagerInstance = new PreviewIframeStylesManager();
export default PreviewIframeStylesManagerInstance;