import { combineReducers } from 'redux';
import { createGenericRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
import { MODULE_FETCH_GRAPHQL_VARIABLES } from 'ContentData/actions/ActionTypes';

const dataQueryVariables = (state = {}, action) => {
  switch (action.type) {
    case MODULE_FETCH_GRAPHQL_VARIABLES:
      {
        const {
          response,
          options
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [options]: Object.values(response).reduce((acc, dataQueryVar) => {
            return Object.assign({}, acc, {
              [dataQueryVar.variableName]: dataQueryVar
            });
          }, {})
        });
      }

    default:
      return state;
  }
};

export default combineReducers({
  dataQueryVariables,
  requestStatus: createGenericRequestStatusReducer(MODULE_FETCH_GRAPHQL_VARIABLES)
});