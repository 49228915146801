import { FETCH_CONTENT_SCHEMA_SUCCEEDED, FORCE_SHOW_END_TEST_MODAL } from 'ContentEditorUI/redux/actions/actionTypes';
import produce from 'immer';

const abTestReducer = (state = {}, action) => produce(state, draft => {
  const {
    type,
    response,
    value
  } = action;

  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        draft.abTestId = response.content.abTestId;
        draft.abStatus = response.content.abStatus;
        draft.abVariation = response.content.abVariation;
        return Object.assign(draft, response.abtest);
      }

    case FORCE_SHOW_END_TEST_MODAL:
      {
        draft.forceEndAb = value;
        return draft;
      }

    default:
      return draft;
  }
});

export default abTestReducer;