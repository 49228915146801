'use es6';

import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
const MAX_COLOR_PICKER_FAVORITES_COUNT = 6;
export const getSiteSettings = basicSelector(state => state.siteSettings);

const createSettingsSelector = property => createSelector(getSiteSettings, settings => settings ? settings.get(property) : null);

export const getSiteSettingsBodyOverrideByDomain = createSettingsSelector('bodyOverrideByDomain');
export const getSiteSettingColorFavorites = createSelector([getSiteSettings], settings => {
  const favorites = [];

  for (let i = 1; i <= MAX_COLOR_PICKER_FAVORITES_COUNT; i++) {
    const color = settings.get(`colorPickerFavorite${i}`);

    if (color) {
      favorites.push(color);
    }
  }

  return favorites;
});
export const getMergeTagDefaults = createSettingsSelector('mergeTagDefaults');