import { isErr, isOk, parseJsonResult, unwrapErrOrElseThrow, unwrapOrElseThrow } from './Result';
import Raven from 'raven';
import { Metrics } from './Metrics';
import quickFetch from 'quick-fetch';
import http from 'hub-http/clients/apiClient';
export const ErrorTypes = {
  FAILED_VALIDATION_CONTAINS_JSON: 'FAILED_VALIDATION_CONTAINS_JSON',
  FAILED_VALIDATION_INVALID_TYPE: 'FAILED_VALIDATION_INVALID_TYPE',
  FAILED_VALIDATION_MAX_LENGTH: 'FAILED_VALIDATION_MAX_LENGTH',
  FAILED_VALIDATION_NO_SCHEMA_FOR_PREFERENCE: 'FAILED_VALIDATION_NO_SCHEMA_FOR_PREFERENCE',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR'
};
export class PreferencesClient {
  constructor(caller, fetchEndpoint, writeEndpoint, deleteEndpoint) {
    this.httpClient = http;
    this.caller = caller;
    this.fetchEndpoint = fetchEndpoint;
    this.writeEndpoint = writeEndpoint;
    this.deleteEndpoint = deleteEndpoint;
  }

  waitForEarlyRequestOrSendNewBatchFetch(names, earlyRequestKey) {
    const earlyRequest = earlyRequestKey ? quickFetch.getRequestStateByName(earlyRequestKey) : null;

    const sendNewBatchFetch = () => this.httpClient.post(this.fetchEndpoint, {
      data: {
        names
      },
      timeout: 5000,
      query: {
        caller: this.caller
      }
    });

    if (earlyRequestKey && earlyRequest) {
      return new Promise((resolve, reject) => {
        earlyRequest.whenFinished(result => {
          resolve(result);
          quickFetch.removeEarlyRequest(earlyRequestKey);
        });
        earlyRequest.onError((xhr, errorMsg) => {
          // retry the request
          // eslint-disable-next-line promise/catch-or-return
          sendNewBatchFetch().then(resolve, reject);
          const error = new Error(errorMsg);
          Metrics.counter('fetch-preference-early-request-failed').increment();
          Raven.captureException(error);
          console.error(error);
          quickFetch.removeEarlyRequest(earlyRequestKey);
        });
      });
    } else {
      return sendNewBatchFetch();
    }
  }

  fetch(preferenceName, defaultValue, earlyRequestKey) {
    if (defaultValue == null) {
      throw new Error('defaultValue cannot be null or undefined');
    }

    return this.waitForEarlyRequestOrSendNewBatchFetch([preferenceName], earlyRequestKey).then(json => {
      const result = parseJsonResult(json);

      if (isErr(result)) {
        return defaultValue;
      }

      const {
        results
      } = unwrapOrElseThrow(result);

      if (Object.prototype.hasOwnProperty.call(results, preferenceName)) {
        return results[preferenceName];
      }

      return defaultValue;
    }).catch(error => {
      Metrics.counter('fetch-preference-threw-error').increment();
      Raven.captureException(error);
      console.error(error);
      return defaultValue;
    });
  }

  batchFetch(defaults, earlyRequestKey) {
    for (const [key, value] of Object.entries(defaults)) {
      if (value == null) {
        throw new Error(`default value for preference "${key}" cannot be null or undefined`);
      }
    }

    return this.waitForEarlyRequestOrSendNewBatchFetch(Object.keys(defaults), earlyRequestKey).then(json => {
      const result = parseJsonResult(json);

      if (isErr(result)) {
        return defaults;
      }

      const {
        results
      } = unwrapOrElseThrow(result);
      return Object.assign({}, defaults, {}, results);
    }).catch(error => {
      Metrics.counter('fetch-preferences-batch-threw-error').increment();
      Raven.captureException(error);
      console.error(error);
      return defaults;
    });
  }

  write(preferenceName, value) {
    return this.httpClient.post(this.writeEndpoint, {
      data: {
        name: preferenceName,
        value
      },
      query: {
        caller: this.caller
      }
    }).then(json => {
      const result = parseJsonResult(json);

      if (isOk(result)) {
        return value;
      }

      const errorFromServer = unwrapErrOrElseThrow(result);
      throw new Error(errorFromServer.message ? `${errorFromServer.type} - ${errorFromServer.message}` : errorFromServer.type);
    }).catch(error => {
      Metrics.counter('write-preference-threw-error').increment();
      Raven.captureException(error);
      console.error(error);
      throw error;
    });
  }

  del(preferenceName) {
    return this.httpClient.post(this.deleteEndpoint, {
      data: {
        name: preferenceName
      },
      query: {
        caller: this.caller
      }
    }).then(json => {
      const result = parseJsonResult(json);

      if (isOk(result)) {
        const deletedPreference = unwrapOrElseThrow(result);

        if (deletedPreference === null) {
          return null;
        }

        return deletedPreference.value;
      }

      const errorFromServer = unwrapErrOrElseThrow(result);
      throw new Error(errorFromServer.message ? `${errorFromServer.type} - ${errorFromServer.message}` : errorFromServer.type);
    }).catch(error => {
      Metrics.counter('delete-preference-threw-error').increment();
      Raven.captureException(error);
      console.error(error);
      throw error;
    });
  }

  withHttpClient(client) {
    this.httpClient = client;
    return this;
  }

}