// ⚠️ Hello HubSpotters, Please don't import from this module. ☠️
let uniqueIdCounter = 0;
/** @returns {string} a unique id string with the given prefix */

export const uniqueId = prefix => `${prefix == null ? '' : prefix}${++uniqueIdCounter}`;
/** @returns {boolean} whether the given value is the constant NaN */

export const isNaN = value => typeof value === 'number' && value !== +value;

/** @returns {*} the first element of an Array or NodeList */
export const first = list => list == null || list.length === 0 ? undefined : list[0];
/** @returns {*} the last element of an Array or NodeList */

export const last = list => list == null || list.length === 0 ? undefined : list[list.length - 1];