import { useContext, useMemo } from 'react';
import { XrayPanelContext } from './XrayPanelContext';
import events from 'xray-recommendation-lib/events.yaml';
export const emptyTracker = {
  clone: () => emptyTracker,
  track: () => {}
};
export const useClonedTracker = tracker => {
  return useMemo(() => {
    if (!tracker) {
      return emptyTracker;
    }

    return tracker.clone({
      events
    });
  }, [tracker]);
};
export const useTracker = () => {
  const {
    tracker
  } = useContext(XrayPanelContext);
  return tracker || emptyTracker;
};