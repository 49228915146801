'use es6';

import { createStack } from 'hub-http';
import * as core from 'hub-http/middlewares/core';
import * as hubapi from 'hub-http/middlewares/hubapi';
import * as debug from 'hub-http/middlewares/debug';
import * as externalAuth from 'hub-http/middlewares/externalAuth';
import promiseClient from 'hub-http/adapters/promiseClient'; // Customized retry logic based on https://git.hubteam.com/HubSpot/hub-http/blob/5a541693c657a5b1bc862aed1f39f09e942ebaef/static/js/middlewares/hubapi.js#L119
// Will retry GETs and PUTS/POSTS/DELETEs, but only on 5xx errors (not network timeouts or 4xx errors)

const customRetryOnError = core.retry(response => response.status >= 500, {
  maxRetries: 1
});
export default promiseClient(createStack(core.services, hubapi.defaults, debug.allowTimeoutOverride, core.jsonBody, core.httpsOnly, hubapi.hubapi, externalAuth.cookieAuthentication, core.withQuery, debug.rewriteUrl, hubapi.timeoutInQuery, core.reportOptionsError, hubapi.logoutOnUnauthorized, customRetryOnError, core.validateStatus, core.jsonResponse));