import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
const getTheme = basicSelector(state => state.theme);
export const getHasTheme = createSelector([getTheme], theme => theme ? theme.hasTheme : false);
export const getThemeObject = createSelector([getTheme], theme => theme ? theme.theme : null);
export const getThemeSettings = createSelector([getTheme], theme => theme ? theme.themeSettings : null);
export const getHasThemeSettings = createSelector([getTheme, getHasTheme], (theme, hasTheme) => hasTheme && !!theme && theme.hasThemeSettings);
export const getOverriddenThemeSettings = createSelector([getTheme], theme => theme ? theme.overriddenThemeSettings : null);
export const getThemePath = createSelector([getThemeObject], theme => theme ? theme.path : null);
export const getParentThemePath = createSelector([getThemeObject], theme => theme ? theme.parentThemePath : null);
export const getThemeFormSpec = createSelector([getThemeObject], theme => {
  if (!theme) {
    return {
      fields: [],
      messages: {}
    };
  }

  return {
    fields: theme.fields || [],
    messages: theme.messages || {}
  };
});
export const getThemeName = createSelector([getThemeObject], theme => theme ? theme.name : null);
export const getThemeGlobalSettings = createSelector([getThemeSettings], themeSettings => {
  if (!themeSettings) {
    return {};
  }

  return themeSettings.values || {};
});
export const getThemesCustomFontsList = createSelector([getTheme], theme => theme ? theme.customFonts : []);
export const selectThemeVariantName = createSelector([getThemeSettings], themeSettings => themeSettings ? themeSettings.variantName : null);