// Importing here and re-exporting helps us get around the spying-default-exports-in-Jasmine problem
import reactUtilsDebounce from 'react-utils/debounce'; // Functions below are from underscore
// https://github.com/jashkenas/underscore/blob/master/underscore.js

export const shuffle = inputArray => {
  const outputArray = [...inputArray];

  for (let i = outputArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = outputArray[i];
    outputArray[i] = outputArray[j];
    outputArray[j] = temp;
  }

  return outputArray;
};

const objectFilter = (object, include, ...filters) => {
  if (object == null) return object;
  const filtersAsArray = filters.flat();
  return Object.keys(object).reduce((prev, key) => {
    const passesFilter = include ? Object.assign({}, prev, {
      [key]: object[key]
    }) : prev;
    const failsFilter = include ? prev : Object.assign({}, prev, {
      [key]: object[key]
    });
    return filtersAsArray.includes(key) ? passesFilter : failsFilter;
  }, {});
};

export const omit = (object, ...filters) => {
  return objectFilter(object, false, ...filters);
};
export const pick = (object, ...filters) => {
  return objectFilter(object, true, ...filters);
};
export const last = list => {
  return list == null || list.length === 0 ? undefined : list[list.length - 1];
};
export const once = func => {
  let ran;
  let result;
  return (...args) => {
    if (ran) {
      return result;
    }

    ran = true;
    result = func.apply(this, args);
    return result;
  };
};
export const isUndefined = value => {
  return typeof value === 'undefined';
};
export const memoize = (func, hasher = args => JSON.stringify(args)) => {
  const memoized = function memoized(...args) {
    const cache = memoized.cache;
    const address = `${hasher.call(this, ...args)}`; // eslint-disable-next-line no-prototype-builtins

    if (!cache.hasOwnProperty(address)) cache[address] = func.apply(this, args);
    return cache[address];
  };

  memoized.cache = {};
  return memoized;
};
export const debounce = reactUtilsDebounce;
export const getParameter = (params, key) => params[key];