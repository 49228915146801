import http from 'hub-http/clients/apiClient';
import I18n from 'I18n'; // https://tools.hubteam.com/api-catalog/services/ContentCategoriesService/v1/spec/internal#

const BASE_URL = 'content/categories/v1/categories'; // taken from https://git.hubteam.com/HubSpot/ContentCategories/blob/master/ContentCategoriesCore/src/main/java/com/hubspot/content/categories/core/AssetType.java

const MODULE = 'MODULE';
export const fetchCategoryTypes = () => {
  return http.get(`${BASE_URL}/meta`, {
    query: {
      // @ts-expect-error def exists, not sure why its not defined on I18n
      lang: I18n.lang
    }
  });
};
export const fetchCategorizedModules = () => {
  return http.get(`${BASE_URL}/${MODULE}`);
};