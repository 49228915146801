'use es6';

import { FETCH_CONTENT_SCHEMA_SUCCEEDED, FETCH_DOMAIN_EXCLUSION_REASON_SUCCESS, UPDATE_CONTENT_MODEL_DOMAIN } from 'ContentEditorUI/redux/actions/actionTypes';
import Immutable from 'immutable';

const portalDomainsReducer = (state = Immutable.fromJS({
  domains: [],
  domainExlusionReason: null
}), {
  type,
  payload,
  response
}) => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const domains = response.domains;
        return domains ? state.set('domains', Immutable.fromJS(domains)) : state;
      }

    case FETCH_DOMAIN_EXCLUSION_REASON_SUCCESS:
      {
        return state.set('domainExclusionReason', payload);
      }

    case UPDATE_CONTENT_MODEL_DOMAIN:
      {
        return state.set('domainExclusionReason', null);
      }

    default:
      return state;
  }
};

export default portalDomainsReducer;