import { useMemo } from 'react';
import { useApprovalContext } from './useApprovalContext';

const useApproversInfo = () => {
  const approval = useApprovalContext();
  const approverIds = useMemo(() => {
    if (!approval.data) {
      return [];
    }

    return approval.data.approvalSteps.map(step => step.approver.ownerId);
  }, [approval.data]);
  const approvedApproverIds = useMemo(() => {
    if (!approval.data) {
      return [];
    }

    return approval.data.approvalSteps.filter(step => step.approvalStepStatus === 'APPROVED').map(step => step.approver.ownerId);
  }, [approval.data]);
  return {
    approverIds,
    approvedApproverIds
  };
};

export default useApproversInfo;