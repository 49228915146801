/* hs-eslint ignored failing-rules */

/* eslint-disable react-hooks/exhaustive-deps */
'use es6';

import { useEffect } from 'react'; // A simple custom hook to execute a function once (essentially the same as componentDidMount)

const useMount = effect => {
  useEffect(effect, []);
};

export default useMount;