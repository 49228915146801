import { useCallback } from 'react';
import { getUsageTracker } from '../utils/usageTracker';
const USAGE_EVENT_KEY = 'contentGenerationModalInteraction';
export const useUsageTracker = ({
  usageTracker,
  usageDescription,
  eventKey,
  trackInteraction
}) => {
  const trackUsage = useCallback((action, extraProps = {}) => {
    if (usageTracker) {
      const _usageTracker = getUsageTracker(usageTracker, usageDescription);

      const properties = Object.assign({
        action
      }, extraProps);

      _usageTracker.track(eventKey || USAGE_EVENT_KEY, properties);
    } else if (trackInteraction) {
      trackInteraction(action, Object.assign({
        usageDescription
      }, extraProps), eventKey);
    }
  }, [eventKey, trackInteraction, usageDescription, usageTracker]);
  return trackUsage;
};