import { useMemo } from 'react';
import { COMMENT_FILTER_VALUES } from '../../../constants/commentFilterConstants';

const useIsCommentIconVisibleFromFilter = ({
  isCommenting,
  commentsFilter,
  moduleWithReply
}) => {
  return useMemo(() => {
    // Display the commenting bubble when the user is adding a new comment
    if (isCommenting) {
      return true;
    }

    if (!moduleWithReply) {
      return false;
    }

    switch (commentsFilter) {
      case COMMENT_FILTER_VALUES.resolved:
        return !!moduleWithReply.resolved;

      case COMMENT_FILTER_VALUES.all:
        return true;

      default:
        return !moduleWithReply.resolved;
    }
  }, [isCommenting, commentsFilter, moduleWithReply]);
};

export default useIsCommentIconVisibleFromFilter;