import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';

/**
 * GET blogs/v3/tags/{id}
 * @param {number} id Tag id
 * @param [httpClient] httpClient - optional custom http client
 * @returns {Promise} fetch tag by id
 */
export function fetchTag(id, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`blogs/v3/tags/${id}`);
}
/**
 * GET blogs/v3/tags
 * @param {String} slug
 * @param [httpClient] httpClient - optional custom http client
 * @returns {Promise} fetch tag by slug
 */

export function fetchTagBySlug(slug, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('blogs/v3/tags', {
    query: {
      slug__eq: slug
    }
  });
}
/**
 * GET blogs/v3/tags
 * @param {String} name
 * @param [httpClient] httpClient - optional custom http client
 * @returns {Promise} fetch tag by name
 */

export function fetchTagByName(name, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('blogs/v3/tags', {
    query: {
      name__eq: name
    }
  });
}
/**
 * GET blogs/v3/tags/search
 * @returns {Promise} fetch all tags (paginated)
 */

export function fetchTags(query, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('blogs/v3/tags/search', {
    query
  });
}