'use es6';

import shallowEqual from 'react-utils/shallowEqual';
import { isValidRGBA, isValidGradientDirection } from 'ContentUtils/helpers/ColorsHelpers';
import { getTreeNodeDomProperties } from '../helpers';
export const GRADIENT_DIRECTIONS = {
  TO_BOTTOM: 'toBottom',
  TO_RIGHT: 'toRight',
  TO_BOTTOM_LEFT: 'toBottomLeft',
  TO_BOTTOM_RIGHT: 'toBottomRight'
};
export const GRADIENT_OBJECTS = {
  [GRADIENT_DIRECTIONS.TO_BOTTOM]: {
    verticalSide: 'BOTTOM'
  },
  [GRADIENT_DIRECTIONS.TO_RIGHT]: {
    horizontalSide: 'RIGHT'
  },
  [GRADIENT_DIRECTIONS.TO_BOTTOM_LEFT]: {
    verticalSide: 'BOTTOM',
    horizontalSide: 'LEFT'
  },
  [GRADIENT_DIRECTIONS.TO_BOTTOM_RIGHT]: {
    verticalSide: 'BOTTOM',
    horizontalSide: 'RIGHT'
  }
};
export const getGradientDirectionTypeFromObject = gradientObject => {
  let matchingType;
  Object.keys(GRADIENT_OBJECTS).forEach(type => {
    if (shallowEqual(GRADIENT_OBJECTS[type], gradientObject)) {
      matchingType = type;
      return;
    }
  });
  return matchingType;
};

const isValidStyleValue = styleValue => {
  if (!styleValue || !styleValue.colors) {
    console.warn('Background gradient must have an array of "colors"');
    return false;
  }

  if (!styleValue.sideOrCorner || !isValidGradientDirection(styleValue.sideOrCorner)) {
    console.warn('Not a valid linear gradient direction');
    return false;
  }

  if (styleValue.colors.length !== 2) {
    console.warn('Linear gradients only support two color stops');
    return false;
  }

  for (const color of styleValue.colors) {
    if (!color.color) {
      console.warn('Color property expected on color stop object');
      return false;
    }

    if (!isValidRGBA(color.color)) {
      console.warn(`Color stop not a valid RGBA value: ${JSON.stringify(color.color)}`);
      return false;
    }
  }

  return true;
};

export const generateBackgroundGradientCss = styleValue => {
  if (!isValidStyleValue(styleValue)) {
    return null;
  } // TODO: In the future we may support different types of gradients, when that happens we should split the linear gradient stuff into it's own method


  let direction = 'to';

  if (styleValue.sideOrCorner.verticalSide) {
    direction = `${direction} ${styleValue.sideOrCorner.verticalSide}`;
  }

  if (styleValue.sideOrCorner.horizontalSide) {
    direction = `${direction} ${styleValue.sideOrCorner.horizontalSide}`;
  }

  const colorStopsCss = styleValue.colors.map(stop => `rgba(${stop.color.r}, ${stop.color.g}, ${stop.color.b}, ${stop.color.a})`);
  const cssProperties = {
    backgroundImage: `linear-gradient(${direction}, ${colorStopsCss.join(',')})`
  };
  return cssProperties;
};
export const calculateBackgroundGradientClasses = (treeNode, styleValue) => {
  const backgroundGradientCss = generateBackgroundGradientCss(styleValue);
  if (!backgroundGradientCss) return [];
  const {
    backgroundImage
  } = backgroundGradientCss;
  const {
    domNodeSelector,
    rendererName
  } = getTreeNodeDomProperties(treeNode);
  const cssProperties = {
    'background-image': `${backgroundImage} !important`
  };
  return [{
    nodeName: treeNode.getName(),
    domNodeSelector,
    className: `${rendererName}-background-gradient`,
    cssSelector: `.${rendererName}-background-gradient`,
    cssProperties
  }];
};