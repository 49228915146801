'use es6';

import { FETCH_PORTAL_SCOPES_SUCCESS, FETCH_PORTAL_INFO_SUCCESS, FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_SUCCESS, FETCH_CUSTOM_DOMAINS_PURGE_SUCCESS, FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_SUCCESS, FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_SUCCESS, FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_SUCCESS, FETCH_AUDIENCE_ACCESS_PURGE_DATE_SUCCESS } from 'ContentEditorUI/redux/actions/actionTypes';
import { Map as ImmutableMap, List } from 'immutable';
const initialState = ImmutableMap({
  portalInfo: ImmutableMap(),
  scopes: List(),
  multiDomainPublishingScopeChangeInfo: ImmutableMap(),
  customDomainsPurgeDateForFreeLP: null,
  dynamicPageAccessPurgeDate: null,
  sitePagesPasswordProtectionPurgeDate: null
});

const portalReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case FETCH_PORTAL_SCOPES_SUCCESS:
      return state.set('scopes', List(payload.scopes));

    case FETCH_PORTAL_INFO_SUCCESS:
      return state.set('portalInfo', ImmutableMap(payload));

    case FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_SUCCESS:
      return state.set('multiDomainPublishingScopeChangeInfo', ImmutableMap(payload));

    case FETCH_CUSTOM_DOMAINS_PURGE_SUCCESS:
      return state.set('customDomainsPurgeDateForFreeLP', payload.date);

    case FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_SUCCESS:
      return state.set('dynamicPageAccessPurgeDate', payload.date);

    case FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_SUCCESS:
      return state.set('sitePagesPasswordProtectionPurgeDate', payload.date);

    case FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_SUCCESS:
      return state.set('landingPagesPasswordProtectionPurgeDate', payload.date);

    case FETCH_AUDIENCE_ACCESS_PURGE_DATE_SUCCESS:
      return state.set('audienceAccessPurgeDate', payload.date);

    default:
      return state;
  }
};

export default portalReducer;