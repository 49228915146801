class WidgetLogger {
  static logIfWidgetNotFound(widgetElements, widget) {
    if (!widgetElements.length && !widget.export_to_template_context && widget.name !== 'preview_text' && !WidgetLogger.widgetsAlreadyLogged.includes(widget)) {
      WidgetLogger.widgetsAlreadyLogged.push(widget);
      console.warn('IPEUI: Widget not found in DOM', widget);
    }
  }

}

WidgetLogger.widgetsAlreadyLogged = [];

const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n); // Count widgets contained in an element, by descendant data attributes and .hs_cos_wrapper classes.
//
// The renderer will mark elements which are the direct parent of unwrapped widgets with
// data-hs-contains-widget=true - contains >= 1 unwrapped widgets, and
// data-hs-contains-widget-myrichtextmodule1=1 - contains 1 widget with name myrichtextmodule1


const countWidgets = nodeList => {
  const findWidgets = (selector, shouldAddBack) => {
    const tmp = [...nodeList[0].querySelectorAll(selector)];

    if (shouldAddBack) {
      tmp.push(...nodeList);
    }

    return tmp;
  };

  const widgets = findWidgets('[data-hs-contains-widget]', nodeList[0].hasAttribute('data-hs-contains-widget'));
  let childWidgetsByMarker = 0;

  for (let i = 0; i < widgets.length; i++) {
    const {
      dataset
    } = widgets[i];
    childWidgetsByMarker += Object.keys(dataset).filter(k => {
      return k.indexOf('hsContainsWidget') > -1 && isNumeric(dataset[k]);
    }).reduce((memo, k) => {
      return memo + Number(dataset[k]);
    }, 0);
  }

  const childWidgetsByWrapperClass = findWidgets('.hs_cos_wrapper', nodeList[0].className.includes('hs_cos_wrapper'));
  return childWidgetsByMarker + childWidgetsByWrapperClass.length;
};

const getWidgetNodeList = ({
  name,
  type,
  key,
  id
}) => {
  const isGlobalWidget = type === 'global_widget';
  const isCanSpamOrWebVersionLink = type === 'email_can_spam' || type === 'email_view_as_web_page';
  let widgets;

  if (isGlobalWidget) {
    // Flex column/inpage
    widgets = document.querySelectorAll(`#hs_cos_wrapper_widget_${key || id}`);
  } else if (isCanSpamOrWebVersionLink) {
    widgets = document.querySelectorAll(`[data-widget-type='${type}']`);
  }

  if (widgets && widgets.length) {
    return widgets;
  }

  return document.querySelectorAll(`#hs_cos_wrapper_${name}`);
};

export function getWidgetElement(widget) {
  let widgetElements = [];
  let wrapperType = 'none';
  let parentSelector = '';

  if (widget.name) {
    widgetElements = getWidgetNodeList(widget);

    if (widgetElements.length) {
      wrapperType = 'wrapper';
    } else if (widget.name !== 'preview_text') {
      parentSelector = `body [data-hs-contains-widget-${widget.name}]`;
      widgetElements = document.querySelectorAll(parentSelector);

      if (widgetElements.length) {
        wrapperType = countWidgets(widgetElements) > 1 ? 'sharedParent' : 'parent';
      }
    }

    WidgetLogger.logIfWidgetNotFound(widgetElements, widget);
  }

  if (wrapperType === 'sharedParent') {
    widgetElements = [];
  }

  return {
    widgetElements,
    wrapperType,
    parentSelector
  };
}