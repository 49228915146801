import { combineReducers } from 'redux';
import { createGenericRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
import { HUBDB_CLEAR_CACHE, HUBDB_FETCH_ROW, HUBDB_FETCH_ROWS, HUBDB_FETCH_TABLE, HUBDB_FETCH_TABLE_BY_NAME, HUBDB_FETCH_TABLES, HUBDB_SAVE_ROW_CACHE, HUBDB_SAVE_TABLE_CACHE, HUBDB_SEARCH_ROWS } from 'ContentData/actions/ActionTypes';
import * as RequestStatus from 'ContentUtils/constants/RequestStatus';

const rows = (state = {}, action) => {
  switch (action.type) {
    case HUBDB_FETCH_ROW:
      {
        const {
          response,
          options
        } = action;
        const {
          tableId,
          rowId
        } = options || {};
        if (!response) return state;
        return Object.assign({}, state, {
          [tableId]: Object.assign({}, state[tableId], {
            [rowId]: response
          })
        });
      }

    case HUBDB_SAVE_ROW_CACHE:
      {
        const {
          row,
          tableId
        } = action;
        if (!row) return state;
        return Object.assign({}, state, {
          [tableId]: Object.assign({}, state[tableId], {
            [row.id]: row
          })
        });
      }

    default:
      return state;
  }
};

const rowsCache = (state = {}, action) => {
  switch (action.type) {
    case HUBDB_FETCH_ROWS:
    case HUBDB_SEARCH_ROWS:
      {
        const {
          response,
          options
        } = action;
        const tableId = options && options.tableId;
        if (!(response && response.objects && tableId)) return state;
        const rowsHash = response.objects.reduce((hash, row) => {
          hash[row.id] = row;
          return hash;
        }, Object.assign({}, state[tableId]));
        return Object.assign({}, state, {
          [tableId]: rowsHash
        });
      }

    case HUBDB_CLEAR_CACHE:
      {
        if (Object.keys(state).length) return {};
        return state;
      }

    default:
      return state;
  }
};

const tables = (state = {
  byId: {},
  byName: {}
}, action) => {
  switch (action.type) {
    case HUBDB_FETCH_TABLE:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          byId: Object.assign({}, state.byId, {
            [response.id]: response
          }),
          byName: Object.assign({}, state.byName, {
            [response.name]: response
          })
        });
      }

    case HUBDB_FETCH_TABLE_BY_NAME:
      {
        const {
          response
        } = action;
        if (!(response && response.objects)) return state;
        const table = response.objects[0];
        return Object.assign({}, state, {
          byName: Object.assign({}, state.byName, {
            [table.name]: table
          }),
          byId: Object.assign({}, state.byId, {
            [table.id]: table
          })
        });
      }

    case HUBDB_SAVE_TABLE_CACHE:
      {
        const {
          table
        } = action;
        if (!table) return state;
        return Object.assign({}, state, {
          byId: Object.assign({}, state.byId, {
            [table.id]: table
          }),
          byName: Object.assign({}, state.byName, {
            [table.name]: table
          })
        });
      }

    default:
      return state;
  }
};

const tablesCache = (state = {}, action) => {
  switch (action.type) {
    case HUBDB_FETCH_TABLES:
      {
        const {
          response
        } = action;
        if (!(response && response.objects)) return state;
        return response.objects.reduce((hash, table) => {
          hash[table.id] = table;
          return hash;
        }, Object.assign({}, state));
      }

    case HUBDB_CLEAR_CACHE:
      {
        if (Object.keys(state).length) return {};
        return state;
      }

    default:
      return state;
  }
};

function hubDbCacheRequestStatus(state = RequestStatus.UNINITIALIZED, action) {
  switch (action.type) {
    case HUBDB_FETCH_ROWS:
    case HUBDB_FETCH_TABLES:
      if (action.response) return RequestStatus.SUCCEEDED;
      if (action.error) return RequestStatus.FAILED;
      return RequestStatus.PENDING;

    default:
      return state;
  }
}

export default combineReducers({
  hubDbCacheRequestStatus,
  rows,
  rowsCache,
  tables,
  tablesCache,
  tableRequestStatus: createGenericRequestStatusReducer(HUBDB_FETCH_TABLE),
  rowRequestStatus: createGenericRequestStatusReducer(HUBDB_FETCH_ROW)
});