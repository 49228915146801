'use es6';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getActiveBreakpointName, getActiveBreakpoint, getPreviewFrameMinWidth, getIsInDefaultBreakpointMode } from 'ContentEditorUI/redux/selectors/styleBreakpointsSelectors';

const mapStateToProps = state => {
  return {
    activeBreakpointName: getActiveBreakpointName(state),
    activeBreakpoint: getActiveBreakpoint(state),
    isInDefaultBreakpointMode: getIsInDefaultBreakpointMode(state),
    previewFrameMinWidth: getPreviewFrameMinWidth(state)
  };
};

class SkeletonPreviewIframeStyler extends Component {
  componentDidMount() {
    this.setPreviewFrameWidth();
  }

  componentDidUpdate(prevProps) {
    const {
      activeBreakpointName
    } = this.props;
    const breakpointChanged = prevProps.activeBreakpointName !== activeBreakpointName;

    if (breakpointChanged) {
      this.setPreviewFrameWidth();
    }
  }

  setPreviewFrameWidth() {
    const {
      activeBreakpoint,
      isInDefaultBreakpointMode,
      previewFrameMinWidth
    } = this.props;
    const iframeElement = this.getIframeElement();

    if (isInDefaultBreakpointMode) {
      iframeElement.classList.add('default-preview');
      iframeElement.classList.remove('breakpoint-preview');
      iframeElement.style.width = '';
    } else if (!isInDefaultBreakpointMode) {
      iframeElement.style.width = `${activeBreakpoint.previewWidth.value}${activeBreakpoint.previewWidth.units}`;
      iframeElement.classList.add('breakpoint-preview');
      iframeElement.classList.remove('default-preview');
    }

    iframeElement.style.minWidth = previewFrameMinWidth;
  }

  getIframeElement() {
    const {
      iframeWrapperElement
    } = this.props;

    if (!this.iframeElement) {
      this.iframeElement = iframeWrapperElement.querySelector('iframe');
    }

    return this.iframeElement;
  }

  render() {
    return null;
  }

}

SkeletonPreviewIframeStyler.propTypes = {
  activeBreakpoint: PropTypes.object.isRequired,
  activeBreakpointName: PropTypes.string.isRequired,
  isInDefaultBreakpointMode: PropTypes.bool.isRequired,
  previewFrameMinWidth: PropTypes.string.isRequired,
  iframeWrapperElement: PropTypes.instanceOf(Element).isRequired
};
export default connect(mapStateToProps)(SkeletonPreviewIframeStyler);