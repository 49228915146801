'use es6';

import { IFRAME_HOST } from 'ui-addon-iframeable/messaging/IFrameControlMessage';
import { getLocalSetting } from './LocalEnv';
const MSG_SENT = 'SENT';
const MSG_RECEIVED = 'RECEIVED';
const IFRAME_EMBED = 'EMBED';

const debugEnabled = name => !!getLocalSetting(name, 'debug');

const toMessage = (name, message) => `[iframeable ${name}] ${message}`;

const logMessage = (name, source, direction, message) => {
  if (debugEnabled(name)) {
    console.log(toMessage(name, `${source} ${direction}`), message);
  }
};

const logError = (name, source, error, ...args) => {
  if (debugEnabled(name)) {
    console.error(toMessage(name, source), error, ...args);
  }
};

export const logHostError = (name, error, ...args) => logError(name, IFRAME_HOST, error, ...args);
export const logEmbedError = (name, error, ...args) => logError(name, IFRAME_EMBED, error, ...args);
export const logHostMessageSent = (name, message) => logMessage(name, IFRAME_HOST, MSG_SENT, message);
export const logHostMessageReceived = (name, message) => logMessage(name, IFRAME_HOST, MSG_RECEIVED, message);
export const logEmbedMessageSent = (name, message) => logMessage(name, IFRAME_EMBED, MSG_SENT, message);
export const logEmbedMessageReceived = (name, message) => logMessage(name, IFRAME_EMBED, MSG_RECEIVED, message);