export const FORM_OBJECT_TYPE_ID = '0-28';
export const PROPERTIES = {
  FORM_ID: 'hs_form_id',
  FORM_TYPE: 'hs_form_type',
  NAME: 'hs_name',
  STATUS: 'hs_status',
  BUSINESS_UNIT_IDS: 'hs_all_assigned_business_unit_ids',
  LAST_MODIFIED_DATE: 'hs_lastmodifieddate',
  CREATED_AT: 'hs_created_at',
  UPDATED_AT: 'hs_updated_at',
  // Rollup properties
  VIEWS: 'hs_page_views',
  SUBMISSION_RATE: 'hs_form_submissions_per_page_view',
  SUBMISSIONS: 'hs_form_submissions',
  SPAM_SUBMISSIONS: 'hs_spam_submissions',
  FOLDER_ID: 'hs_folder_id',
  DELETABLE: 'hs_deletable',
  CLONEABLE: 'hs_cloneable',
  // Empty properties
  CREATED_BY_USER_ID: 'hs_created_by_user_id',
  UPDATED_BY_USER_ID: 'hs_updated_by_user_id',
  HUBSPOT_OWNER_ASSIGNEDDATE: 'hubspot_owner_assigneddate',
  HUBSPOT_OWNER_ID: 'hubspot_owner_id',
  HUBSPOT_TEAM_ID: 'hubspot_team_id',
  HUBSPOT_ALL_TEAM_IDS: 'hs_all_team_ids',
  // Meta properties
  EMBED_VERSION: 'hs_embed_version'
};
export const EMBED_VERSIONS = {
  V3: 'v3',
  V4: 'v4'
};