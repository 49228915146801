export const PLACEMENTS_HORIZ = ['left', 'right'];
export const PLACEMENTS_VERT = ['top', 'bottom'];
export const PLACEMENTS_SIDES = [...PLACEMENTS_HORIZ, ...PLACEMENTS_VERT];
export const PLACEMENT_EDGES = [...PLACEMENTS_SIDES, 'center', 'middle'];
export const PLACEMENTS = [...PLACEMENTS_SIDES, 'left top', 'left bottom', 'right top', 'right bottom', 'top left', 'top right', 'bottom left', 'bottom right'];
export const OPPOSITE_DIRECTIONS = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left',
  middle: 'middle',
  center: 'center'
};
/**
 * The min distance from the edge of the popover to its arrow, ensuring that it doesn't overlap with
 * the popover's rounded corners.
 */

export const ARROW_INSET_LOWER_BOUND = 8;