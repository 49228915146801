export const BOOTSTRAP_2_NUM_COLUMNS = 12;
export const REQUIRED_PROPERTIES_ON_LEAF_MODULE = ['type', 'label', 'id', 'params', 'width' // to be serialized to w
];
export const REQUIRED_PARAM_PROPERTIES_ON_LEAF_MODULE = [// 'overrideable'  Not actually always present???
];
export const CELL_TYPE = 'cell';
export const CUSTOM_WRAPPING_HTML_PARAM_KEY = 'wrapping_html';
export const CELL_NAME_PREFIX = 'cell_';
export const MODULE_NAME_PREFIX = 'module_';
export const ROW_NAME_PREFIX = 'row_';
export const ROOT_NAME_PREFIX = 'root_';