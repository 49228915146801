/* hs-eslint ignored failing-rules */

/* eslint-disable @typescript-eslint/no-floating-promises */
import { REMOVE_ATTACHED_CONTENT_STARTED, REMOVE_ATTACHED_CONTENT_SUCCEEDED, REMOVE_ATTACHED_CONTENT_FAILED, CREATE_ATTACHED_CONTENT_STARTED, CREATE_ATTACHED_CONTENT_SUCCEEDED, CREATE_ATTACHED_CONTENT_FAILED } from '../actionTypes';
import { fetchAttachedContentWithContext } from '../attachedContentWithContext/thunks';
import { deleteAttachedContent, postAttachedContent } from './client';
export const createAttachedContent = (contentId, clusterId, topicId) => dispatch => {
  dispatch({
    type: CREATE_ATTACHED_CONTENT_STARTED
  });
  return postAttachedContent(contentId, clusterId, topicId).then(response => {
    dispatch({
      type: CREATE_ATTACHED_CONTENT_SUCCEEDED,
      payload: response
    });
    dispatch(fetchAttachedContentWithContext({
      contentId
    }));
  }).catch(error => {
    dispatch({
      type: CREATE_ATTACHED_CONTENT_FAILED,
      payload: {
        status: error.status
      }
    });
  });
};
export const removeAttachedContent = ({
  attachedContentId
}) => dispatch => {
  dispatch({
    type: REMOVE_ATTACHED_CONTENT_STARTED
  });
  return deleteAttachedContent({
    attachedContentId
  }).then(response => {
    dispatch({
      type: REMOVE_ATTACHED_CONTENT_SUCCEEDED,
      payload: response
    });
  }).catch(error => {
    dispatch({
      type: REMOVE_ATTACHED_CONTENT_FAILED,
      payload: {
        status: error.status
      }
    });
  });
};