import { FETCH_PAGE_LIMIT_SUCCEEDED, FETCH_MLANG_SITE_PAGES_LIMIT_SUCCEEDED // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '../a... Remove this comment to see the full error message
} from '../actions/actionTypes';

const pageLimitReducer = (state = null, {
  payload,
  type
}) => {
  switch (type) {
    case FETCH_PAGE_LIMIT_SUCCEEDED:
      {
        if (payload && payload.pageLimit) {
          const existingState = state || {};
          return Object.assign({}, existingState, {
            pageLimit: payload.pageLimit,
            quantityUsed: payload.quantityUsed,
            hasExceededContentTypeLimit: payload.quantityUsed > payload.pageLimit
          });
        }

        return state;
      }

    case FETCH_MLANG_SITE_PAGES_LIMIT_SUCCEEDED:
      {
        if (payload && payload.limit) {
          const existingState = state || {};
          return Object.assign({}, existingState, {
            mlangSitePagesLimit: payload.limit,
            mlangSitePagesQuantityUsed: payload.quantityUsed
          });
        }

        return state;
      }

    default:
      return state;
  }
};

export default pageLimitReducer;