import { useReducer, useCallback } from 'react';
import { MSG_TYPE_NOTIFICATION_SETTINGS_FAILURE, MSG_TYPE_NOTIFICATION_SETTINGS_LOADING, MSG_TYPE_NOTIFICATION_SETTINGS_SUCCESS, MSG_TYPE_OPEN_NOTIFICATION_SETTINGS_MODAL, MSG_TYPE_SAVE_NOTIFICATION_SETTINGS } from 'collaboration-sidebar/constants/IFrameMessageTypes';
import { GLOBAL_NOTIFICATION_EMAIL_SETTING } from '../../constants/notificationSettings';
const HANDLE_CLOSE = 'HANDLE_CLOSE';
const HANDLE_CONFIRM = 'HANDLE_CONFIRM';

function reducer(state, action) {
  switch (action.type) {
    case MSG_TYPE_OPEN_NOTIFICATION_SETTINGS_MODAL:
      return Object.assign({}, state, {
        show: true,
        userSetting: action.userSetting,
        globalSettings: action.globalSettings
      });

    case MSG_TYPE_NOTIFICATION_SETTINGS_FAILURE:
      return Object.assign({}, state, {
        failure: true,
        loading: false
      });

    case MSG_TYPE_NOTIFICATION_SETTINGS_LOADING:
      return Object.assign({}, state, {
        failure: false,
        loading: true
      });

    case MSG_TYPE_NOTIFICATION_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        failure: false,
        loading: false
      });

    case HANDLE_CONFIRM:
      return Object.assign({}, state, {
        show: false
      });

    case HANDLE_CLOSE:
      return Object.assign({}, state, {
        show: false
      });

    default:
      return state;
  }
}

export default function useNotificationSettingsModal({
  sendMessageRef,
  isSendMessageReady
}) {
  const [{
    show,
    failure,
    loading,
    userSetting,
    globalSettings
  }, dispatch] = useReducer(reducer, {
    show: false,
    failure: false,
    loading: false
  });
  const handleMessage = useCallback(({
    payload
  }) => dispatch(payload), []);
  const onClose = useCallback(() => dispatch({
    type: HANDLE_CLOSE
  }), []);
  const onSave = useCallback(updatedSetting => {
    if (isSendMessageReady && sendMessageRef.current && updatedSetting !== userSetting) {
      sendMessageRef.current(MSG_TYPE_SAVE_NOTIFICATION_SETTINGS, {
        userSetting: updatedSetting
      });
    }

    dispatch({
      type: HANDLE_CONFIRM
    });
  }, [isSendMessageReady, sendMessageRef, userSetting]);
  const newCommentsEnabled = globalSettings ? globalSettings.newComments.enabledChannels.includes(GLOBAL_NOTIFICATION_EMAIL_SETTING) : false;
  const atMentionsEnabled = globalSettings ? globalSettings.atMentions.enabledChannels.includes(GLOBAL_NOTIFICATION_EMAIL_SETTING) : false;
  return {
    handleMessage,
    onClose,
    onSave,
    show,
    loading,
    failure,
    userSetting,
    newCommentsEnabled,
    atMentionsEnabled
  };
}