'use es6';

import getCountryCodeFromLocale from './getCountryCodeFromLocale';
import * as TelephoneData from 'I18n/constants/TelephoneData';
const INVALID_OPTION = {
  dialCode: '',
  priority: 10001,
  iso2: null
};
export default (inputNumber => {
  const allCountries = TelephoneData.allCountries;
  const allCountryCodes = TelephoneData.allCountryCodes;
  const iso2Lookup = TelephoneData.iso2Lookup;
  let bestGuess;
  const secondBestGuess = allCountries[iso2Lookup[getCountryCodeFromLocale()]] || allCountries[iso2Lookup.us];
  const inputNumberForCountries = inputNumber.substr(0, 4);

  if (inputNumber.trim() !== '') {
    let selectedCountry = INVALID_OPTION;
    allCountries.every(country => {
      // no country should have a dial code of more than 4 digits
      if (selectedCountry.dialCode.length === 4) {
        return false;
      } // if the country dialCode exists with area code (4 digits)


      if (allCountryCodes[inputNumberForCountries] && allCountryCodes[inputNumberForCountries][0] === country.iso2) {
        selectedCountry = country;
        return true; // if the selected country dialCode is there with the area code
      } else if (allCountryCodes[inputNumberForCountries] && allCountryCodes[inputNumberForCountries][0] === selectedCountry.iso2) {
        return true;
      } // else check if the input number closely matches this country


      if (inputNumber.indexOf(country.dialCode) === 0) {
        if (country.dialCode.length > selectedCountry.dialCode.length || country.dialCode.length === selectedCountry.dialCode.length && country.priority < selectedCountry.priority) {
          selectedCountry = country;
          return true;
        }
      }

      return true;
    });
    bestGuess = selectedCountry;
  }

  if (!bestGuess || !bestGuess.iso2) {
    return secondBestGuess;
  }

  return bestGuess;
});