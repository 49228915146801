export let AccountVerificationProgressStatus;

(function (AccountVerificationProgressStatus) {
  AccountVerificationProgressStatus["NEEDS_ACCOUNT_VERIFICATION_APPEAL"] = "NEEDS_ACCOUNT_VERIFICATION_APPEAL";
  AccountVerificationProgressStatus["PENDING_ACCOUNT_VERIFICATION"] = "PENDING_ACCOUNT_VERIFICATION";
  AccountVerificationProgressStatus["REJECTED_ACCOUNT_VERIFICATION"] = "REJECTED_ACCOUNT_VERIFICATION";
  AccountVerificationProgressStatus["ACCEPTED_ACCOUNT_VERIFICATION"] = "ACCEPTED_ACCOUNT_VERIFICATION";
  AccountVerificationProgressStatus["LOST_ACCESS_CANNOT_APPEAL"] = "LOST_ACCESS_CANNOT_APPEAL";
  AccountVerificationProgressStatus["NOT_APPLICABLE"] = "NOT_APPLICABLE";
})(AccountVerificationProgressStatus || (AccountVerificationProgressStatus = {}));

export let AccountVerificationState;

(function (AccountVerificationState) {
  AccountVerificationState["UNINITIATED"] = "UNINITIATED";
  AccountVerificationState["PENDING"] = "PENDING";
  AccountVerificationState["PENDING_AUTO_APPROVAL"] = "PENDING_AUTO_APPROVAL";
  AccountVerificationState["ACCEPTED"] = "ACCEPTED";
  AccountVerificationState["ACCEPTED_WITHOUT_EMAIL"] = "ACCEPTED_WITHOUT_EMAIL";
  AccountVerificationState["AUTO_ACCEPTED"] = "AUTO_ACCEPTED";
  AccountVerificationState["REJECTED"] = "REJECTED";
  AccountVerificationState["PENDING_AUTO_DENIAL"] = "PENDING_AUTO_DENIAL";
  AccountVerificationState["AUTO_REJECTED"] = "AUTO_REJECTED";
})(AccountVerificationState || (AccountVerificationState = {}));