'use es6';

import { createSelector } from 'reselect';
import { getIsSitePage, getIsBlogListingPage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { createHasScopeSelector, getHasBlogListingPagesWriteScope } from 'ContentEditorUI/redux/selectors/authSelectors';
import { getHasAudienceAccessScope, getHasHidePrimaryCss } from 'page-editor-ui/redux/selectors/authSelectors';
import { getPagePrimaryDomain } from 'ContentEditorUI/redux/selectors/portalDomainsSelectors';
import { getIsSaveInProgress, getIsSavePermanentlyDisabled } from 'ContentEditorUI/redux/selectors/saveDataSelectors';
import { getContentModel } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getContentModelPassword, getContentModelPublicAccessRules, getContentModelPublicAccessRulesEnabled } from 'page-editor-ui/redux/selectors/pageContentModelSelectors';
import { getLayoutSectionsAsJson } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getModuleSaveData } from 'ContentEditorUI/redux/selectors/moduleSaveSelectors';
import { getThemePageOverrides } from 'ContentEditorUI/redux/selectors/themeSelectorsAdvanced';
import { isTemporarySlug } from 'ContentEditorUI/utils/contentModelURLUtils';
import { basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';
import { getIsReadOnlyMode } from 'ContentEditorUI/redux/selectors/appStatusSelectors';
import { getIsDynamicPageSourceValuePortalSpecificCrmObject } from './pageSettingsSelectors';
import { formatCrmObjectDynamicPageDataSourceIdForSave } from '../../utils/dynamicPagesUtils';
export const getHasPermissionsToSaveLandingPages = createHasScopeSelector('landingpages-write');
export const getHasPermissionsToSaveSitePages = createHasScopeSelector('pages-write');
export const getHasPermissionsToSave = createSelector([getIsSitePage, getHasPermissionsToSaveLandingPages, getHasPermissionsToSaveSitePages, getHasBlogListingPagesWriteScope, getIsBlogListingPage], (isSitePage, hasPermissionsToSaveLandingPages, hasPermissionsToSaveSitePages, hasBlogListingPagesWriteScope, isBlogListingPage) => {
  if (isSitePage) {
    return hasPermissionsToSaveSitePages;
  } else if (isBlogListingPage) {
    return hasBlogListingPagesWriteScope;
  }

  return hasPermissionsToSaveLandingPages;
});
export const getIsSavingEnabled = createSelector([getHasPermissionsToSave, getIsSaveInProgress, getIsSavePermanentlyDisabled], (hasPermissionsToSave, isSaveInProgress, isSavePermanentlyDisabled) => hasPermissionsToSave && !isSaveInProgress && !isSavePermanentlyDisabled);
const getAttachedStyleSheets = createSelector([getContentModel], contentModel => contentModel.get('pageStylesheets'));
const getAttachedStyleSheetsAsJSObject = createSelector([getAttachedStyleSheets], attachedStylesheets => attachedStylesheets.toJS()); // Fields to subscribe to backbone's change listener

export const getModelDataForBackbone = createSelector([getContentModel, getContentModelPassword, getLayoutSectionsAsJson, getHasAudienceAccessScope, getContentModelPublicAccessRules, getContentModelPublicAccessRulesEnabled, getHasHidePrimaryCss, getThemePageOverrides, getIsDynamicPageSourceValuePortalSpecificCrmObject, getAttachedStyleSheetsAsJSObject], (contentModel, contentModelPassword, layoutSectionsAsJson, hasAudienceAccessWrite, contentModelPublicAccessRules, contentModelPublicAccessRulesEnabled, hasHidePrimaryCss, themePageOverrides, isDynamicPageSourceValuePortalSpecificCrmObject, attachedStylesheetsAsJSObject) => {
  const result = {
    attached_stylesheets: attachedStylesheetsAsJSObject,
    campaign: contentModel.get('campaign'),
    campaign_name: contentModel.get('campaignName'),
    campaign_utm: contentModel.get('campaignUtm'),
    css: contentModel.get('css') ? contentModel.get('css').toJS() : {},
    css_text: contentModel.get('cssText'),
    domain: contentModel.get('domain'),
    dynamic_page_hub_db_table_id: contentModel.get('dynamicPageHubDbTableId'),
    dynamic_page_data_source_id: formatCrmObjectDynamicPageDataSourceIdForSave(contentModel.get('dynamicPageDataSourceId'), isDynamicPageSourceValuePortalSpecificCrmObject),
    dynamic_page_data_source_type: contentModel.get('dynamicPageDataSourceType'),
    property_for_dynamic_page_slug: contentModel.get('propertyForDynamicPageSlug'),
    property_for_dynamic_page_title: contentModel.get('propertyForDynamicPageTitle'),
    property_for_dynamic_page_meta_description: contentModel.get('propertyForDynamicPageMetaDescription'),
    property_for_dynamic_page_canonical_url: contentModel.get('propertyForDynamicPageCanonicalUrl'),
    property_for_dynamic_page_featured_image: contentModel.get('propertyForDynamicPageFeaturedImage'),
    enable_domain_stylesheets: contentModel.get('enableDomainStylesheets'),
    enable_layout_stylesheets: contentModel.get('enableLayoutStylesheets'),
    featured_image: contentModel.get('featuredImage'),
    featured_image_alt_text: contentModel.get('featuredImageAltText'),
    footer_html: contentModel.get('footerHtml'),
    head_html: contentModel.get('headHtml'),
    html_title: contentModel.get('htmlTitle') || null,
    include_default_custom_css: contentModel.get('includeDefaultCustomCss'),
    language: contentModel.get('language'),
    layout_sections: layoutSectionsAsJson,
    link_rel_canonical_url: contentModel.get('linkRelCanonicalUrl'),
    meta_description: contentModel.get('metaDescription') || null,
    name: contentModel.get('name'),
    page_expiry_date: contentModel.get('pageExpiryDate'),
    page_expiry_enabled: contentModel.get('pageExpiryEnabled'),
    page_expiry_redirect_id: contentModel.get('pageExpiryRedirectId'),
    page_expiry_redirect_url: contentModel.get('pageExpiryRedirectUrl'),
    publish_date: contentModel.get('publishDate'),
    publish_immediately: contentModel.get('publishImmediately'),
    slug: contentModel.get('slug') || '/',
    template_path: contentModel.get('templatePath'),
    theme_settings_values: themePageOverrides,
    translated_from_id: contentModel.get('translatedFromId'),
    use_featured_image: contentModel.get('useFeaturedImage')
  };

  if (contentModel.get('requirePassword') && !hasAudienceAccessWrite) {
    result.password = contentModelPassword || null;
  } else {
    result.password = null;
  }

  if (hasAudienceAccessWrite) {
    result.public_access_rules = contentModelPublicAccessRules.toJS();
    result.public_access_rules_enabled = contentModelPublicAccessRulesEnabled;
    result.password = contentModelPassword;
  }

  if (hasHidePrimaryCss && result.include_default_custom_css) {
    result.include_default_custom_css = false;
  }

  return result;
}); // Save data minus all widget data

const _getModelSaveData = createSelector([getModelDataForBackbone, getPagePrimaryDomain], (modelData, primaryDomain) => {
  if (modelData.domain === primaryDomain) {
    modelData.domain = '';
  }

  return modelData;
});

export const getModelSaveData = basicSelectorWithStats((state, opts = {}) => {
  const modelSaveData = _getModelSaveData(state);

  const {
    slug
  } = modelSaveData;

  if (opts.isSaveBeforePublish && isTemporarySlug(slug)) {
    modelSaveData.slug = slug.substring(0, slug.indexOf('-temporary-slug-'));
  }

  return modelSaveData;
}); // All save data

export const getSaveData = createSelector([getModelSaveData, getModuleSaveData], (modelSaveData, moduleSaveData) => Object.assign({}, modelSaveData, {}, moduleSaveData));
export const getCanSave = createSelector([getHasPermissionsToSave, getIsSavePermanentlyDisabled, getIsReadOnlyMode], (hasPermissionsToSave, isSavePermanentlyDisabled, isReadOnly) => hasPermissionsToSave && !isSavePermanentlyDisabled && !isReadOnly);