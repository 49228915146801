import { useCallback, useState } from 'react';
import { fetchCommentsCached, updateCommentsCache } from '../api/Commenting';
export default function useFetchComments() {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const fetchComments = useCallback(({
    objectType,
    objectId,
    refetch
  }) => {
    if (!objectId) {
      return;
    }

    setLoading(true);
    fetchCommentsCached({
      objectType,
      objectId,
      refetch
    }).then(({
      results
    }) => {
      // MSW will return undefined in some of our client apps
      // until we mock the client apps response we fallback to an empty array.
      // This won't impact the apps behaviour because the real API will always return
      // an array.
      setComments(results || []);
    }).catch(e => {
      setError(e);
    }).finally(() => {
      setLoading(false);
    });
  }, []);
  const updateCache = useCallback(({
    objectType,
    objectId,
    updatedComments
  }) => {
    updateCommentsCache({
      objectType,
      objectId,
      comments: updatedComments
    });
    setComments(updatedComments);
  }, []);
  return {
    fetchComments,
    comments,
    error,
    loading,
    updateCache
  };
}