import { getAbsoluteRect, padRect } from '../utils/elementUtils';
export class PulserSpotlight {
  constructor(target, pulserPathOptions) {
    this.target = target;
    this.targetRect = getAbsoluteRect(target);
    this.pulserPathOptions = pulserPathOptions;
  }

  equalsRect(rect) {
    return this.targetRect.left === rect.left && this.targetRect.right === rect.right && this.targetRect.top === rect.top && this.targetRect.bottom === rect.bottom;
  }

  equals(spotlight) {
    return !!spotlight && this.equalsRect(spotlight.targetRect);
  }

  isAnimated() {
    const {
      distance
    } = this.pulserPathOptions;
    return distance !== undefined && distance !== 0;
  }
  /**
   * Generate SVG path for pulser background overlay and highlighted boarder
   *
   * For pulser background overlay:
   *   Two rectangle will be drawn, the background overlay will be the difference set of A to B:
   *      A. Rectangle with full screen size
   *      B. Rectangle covered highlighted area
   *
   * For highlighted boarder:
   *   A rectangle with solid line will be drawn around highlighted area
   *
   * M,V,H are all SVG path commands:
   * See {@link https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d#path_commands}
   */


  createRectPath(rect) {
    const {
      radius = 0
    } = this.pulserPathOptions;
    const {
      left,
      right,
      top,
      bottom
    } = rect;
    return [// Draw a rectangle around the target
    `M${left + radius},${top}a${radius},${radius},0,0,0-${radius},${radius}`, `V${bottom - radius}a${radius},${radius},0,0,0,${radius},${radius}`, `H${right - radius}a${radius},${radius},0,0,0,${radius}-${radius}`, `V${top + radius}a${radius},${radius},0,0,0-${radius}-${radius}Z`].join('');
  }

  generatePulserPath() {
    const {
      distance,
      padding
    } = this.pulserPathOptions;
    const pulserPath = {
      start: this.createRectPath(padRect(this.targetRect, padding))
    };

    if (this.isAnimated()) {
      const endPath = padRect(this.targetRect, padding + distance);
      pulserPath.end = this.createRectPath(endPath);
    }

    this.pulserPath = pulserPath;
    return pulserPath;
  }

}