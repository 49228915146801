import { useEffect, useRef } from 'react';
const channelSubscriptions = {};
let channelCount = 0;
export const makePubSubChannel = () => channelCount++;
/**
 * Returns the same channel on every call.
 * @return {number}
 */

export const usePubSubChannel = () => {
  const channelRef = useRef();

  if (channelRef.current == null) {
    channelRef.current = makePubSubChannel();
  }

  return channelRef.current;
};
/**
 * @param {number} channel
 * @return {Function} A function that publishes a message to all subscribers on the given channel
 */

export const usePublish = channel => {
  return message => {
    const subscriptions = channelSubscriptions[channel];

    if (subscriptions) {
      subscriptions.forEach(listener => {
        listener(message);
      });
    }
  };
};
/**
 * @param {number} channel
 * @param {Function} listener A function that's called with messages published on the given channel
 */

export const useSubscribe = (channel, listener) => {
  if (!channelSubscriptions[channel]) {
    channelSubscriptions[channel] = new Set();
  }

  channelSubscriptions[channel].add(listener); // Unsubscribe on unmount, or if the same component passes in different params

  useEffect(() => {
    return () => {
      channelSubscriptions[channel].delete(listener);
    };
  }, [channel, listener]);
};