export const isObject = val => {
  return Object.prototype.toString.call(val) === '[object Object]';
};
export const getIn = (obj, keyPath) => {
  if (!isObject(obj)) {
    throw new Error(`Expected object. Received '${typeof obj}': ${obj}`);
  }

  if (!Array.isArray(keyPath)) {
    throw new Error(`Expected keyPath to be an array. Received '${typeof keyPath}': ${keyPath}`);
  }

  let val = obj;

  for (let i = 0; i < keyPath.length; i++) {
    if (!isObject(val)) {
      return undefined;
    }

    val = val[keyPath[i]];

    if (val === undefined) {
      return undefined;
    }
  }

  return val;
};