'use es6';

import hubspot from 'hubspot';
import { getContainers, getModulesInContainerHelper, getModulesInContainers } from 'ContentEditorUI/redux/selectors/moduleSelectors';
export const formatContainersFromStoreForSave = () => {
  const modulesInContainers = getModulesInContainers(hubspot.ContentEditorUI.store.getState());
  const currentContainers = getContainers(hubspot.ContentEditorUI.store.getState());
  const containers = []; // Transform state into the required schema
  // [{id: containerId, widgets: [id_0, id_1...]} ...]

  currentContainers.forEach(container => {
    const id = container.getIn(['id']);
    const modulesInContainer = getModulesInContainerHelper(modulesInContainers, id).sortBy(module => module.getIn(['order']));
    containers.push({
      id,
      widgets: modulesInContainer.keySeq().toJS()
    });
  });
  return containers;
};
/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking

if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}