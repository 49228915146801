'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import * as moduleRenderClient from 'ContentUtils/api/moduleRenderClient';
import { constructSearchStringFromUrlParams } from '../utils/routeHelper';

const getDataForRender = data => {
  if (data.selectedSmartRuleId) {
    const smartBody = data.smart_objects.find(r => r.criterion_id === data.selectedSmartRuleId);

    if (smartBody) {
      data.body = Object.assign({
        module_id: data.module_id
      }, smartBody.body);
    }
  } else if (data.module_id && !data.body.module_id) {
    data.body.module_id = data.module_id;
  }

  return data;
};

function render(data) {
  return moduleRenderClient.render(getDataForRender(data.data));
}

function renderWithContext(data) {
  const _data$data = data.data,
        {
    widgetParams
  } = _data$data,
        restOfData = _objectWithoutPropertiesLoose(_data$data, ["widgetParams"]);

  const body = widgetParams.body ? getDataForRender(widgetParams) : widgetParams;

  if (body.id === 'hs_email_body' && body.type === 'rich_text') {
    body.type = 'email_body';
  }

  return moduleRenderClient.renderWithContext(Object.assign({
    widgetParams: body
  }, restOfData), data.getParams && constructSearchStringFromUrlParams(data.getParams));
}

export default {
  render,
  renderWithContext,
  getDataForRender
};