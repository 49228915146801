'use es6';

import { makeAsyncComponent, makeAsyncComponentWithOverlay } from 'ContentEditorUI/components/shared/makeAsyncUtils';
import AsyncMofoFields from 'page-editor-ui/components/AsyncMofoFields';
export const AsyncOnboardingTour = makeAsyncComponent(() => import(
/* webpackChunkName: 'OnboardingTour' */
'onboarding-tours/components/OnboardingTour'));
export const AsyncCodeMirrorEditorField = makeAsyncComponent(() => {
  return Promise.all([import(
  /* webpackChunkName: 'CodeMirrorEditorField' */
  'page-editor-ui/components/LazyCodeMirrorEditorField'), importDlb('codemirror-dlb/bundle.js')]).then(([mod]) => mod.default()).catch(() => {
    throw new Error('Error loading CodeMirror or dependent module');
  });
});
const AsyncHtmlEditorModalContainer = makeAsyncComponentWithOverlay(() => {
  return Promise.all([import(
  /* webpackChunkName: 'HtmlEditorModalContainer' */
  'page-editor-ui/components/LazyHtmlEditorModalContainer'), importDlb('codemirror-dlb/bundle.js')]).then(([mod]) => mod.default());
});
const AsyncPagesPreviewModalContainer = makeAsyncComponent(() => import(
/* webpackChunkName: 'PagesPreviewModalContainer' */
'page-editor-ui/containers/PagesPreviewModalContainer'));
const AsyncPageAdvancedSettingsContainer = makeAsyncComponent(() => import(
/* webpackChunkName: 'PageAdvancedSettingsContainer' */
'page-editor-ui/containers/PageAdvancedSettingsContainer'));
const AsyncPageSettingsForMABContainer = makeAsyncComponent(() => import(
/* webpackChunkName: 'PageSettingsForMABContainer' */
'page-editor-ui/containers/PageSettingsForMABContainer'));
const AsyncPagePublishConfirmModal = makeAsyncComponent(() => import(
/* webpackChunkName: 'PagePublishConfirmModal' */
'page-editor-ui/components/PagePublishConfirmModalComponent'));
const AsyncPagePublishingOptionsContainer = makeAsyncComponent(() => import(
/* webpackChunkName: 'PagePublishingOptionsContainer' */
'page-editor-ui/containers/publishing/PagePublishingOptionsContainer'));
const AsyncPageSettingsContainer = makeAsyncComponent(() => import(
/* webpackChunkName: 'PageSettingsContainer' */
'page-editor-ui/containers/PageSettingsContainer'));
const AsyncPageScopesHaveChangedModal = makeAsyncComponent(() => import(
/* webpackChunkName: 'PageScopesHaveChangedModal' */
'page-editor-ui/components/PageScopesHaveChangedModal'));
const AsyncPartnerMatchingModalContainer = makeAsyncComponent(() => import(
/* webpackChunkName: 'PartnerMatchingModalContainer' */
'page-editor-ui/containers/MatchingModalContainer'));
const AsyncPageExpiryFieldContainer = makeAsyncComponent(() => import(
/* webpackChunkName: 'PageExpiryFieldContainer' */
'page-editor-ui/containers/PageExpiryFieldContainer'));
const AsyncCampaignFieldContainer = makeAsyncComponent(() => import(
/* webpackChunkName: 'CampaignFieldContainer' */
'ContentEditorUI/containers/settings/CampaignFieldContainer'));
const AsyncCollaborationSidebarContainer = makeAsyncComponent(() => import(
/* webpackChunkName: 'collaboration-sidebar-container' */
'ContentEditorUI/containers/CollaborationSidebarContainer'));
const AsyncContentGenerationModalContainer = makeAsyncComponentWithOverlay(() => {
  return import(
  /* webpackChunkName: 'ContentGenerationModalContainer' */
  'tinymce-plugins/components/ContentGenerationModalContainer');
});
const AsyncPatagoniaRunATestContainer = makeAsyncComponent(() => import(
/* webpackChunkName: 'PatagoniaRunATestContainer' */
'page-editor-ui/components/PatagoniaMABExperimentContainer'));
const AsyncPatagoniaSettingsModalContainer = makeAsyncComponent(() => import(
/* webpackChunkName: 'PatagoniaPageSettingsModalContainer' */
'page-editor-ui/containers/PatagoniaPageSettingsModalContainer'));
const AsyncPatagoniaOnboardingTourModalContainer = makeAsyncComponent(() => import(
/* webpackChunkName: 'PatagoniaPageSettingsModalContainer' */
'page-editor-ui/containers/PatagoniaOnboardingTourModalContainer'));
const AsyncPagePublishOptionsModal = makeAsyncComponent(() => import(
/* webpackChunkName: 'PagePublishOptionsModal' */
'page-editor-ui/components/PagePublishOptionsModal'));
const AsyncPatagoniaPagePublishConfirmModal = makeAsyncComponent(() => import(
/* webpackChunkName: 'PatagoniaPagePublishConfirmModal' */
'page-editor-ui/components/PatagoniaPagePublishConfirmModal'));
const AsyncPatagoniaPagePublishingOptionsModal = makeAsyncComponent(() => import(
/* webpackChunkName: 'PatagoniaPagePublishingOptionsModal' */
'page-editor-ui/components/PatagoniaPagePublishingOptionsModal'));
export const asyncComponentsOverrides = {
  extraComponents: {
    PagesPreviewModalContainer: AsyncPagesPreviewModalContainer,
    CampaignFieldContainer: AsyncCampaignFieldContainer,
    CodeMirrorEditorField: AsyncCodeMirrorEditorField,
    CollaborationSidebarContainer: AsyncCollaborationSidebarContainer,
    PageSettingsForMABContainer: AsyncPageSettingsForMABContainer,
    PagePublishConfirmModal: AsyncPagePublishConfirmModal,
    PatagoniaPagePublishConfirmModal: AsyncPatagoniaPagePublishConfirmModal,
    PatagoniaPagePublishingOptionsModal: AsyncPatagoniaPagePublishingOptionsModal,
    PageAdvancedSettingsContainer: AsyncPageAdvancedSettingsContainer,
    PagePublishingOptionsContainer: AsyncPagePublishingOptionsContainer,
    PageSettingsContainer: AsyncPageSettingsContainer,
    PageScopesHaveChangedModal: AsyncPageScopesHaveChangedModal,
    PartnerMatchingModalContainer: AsyncPartnerMatchingModalContainer,
    PageExpiryFieldContainer: AsyncPageExpiryFieldContainer,
    OnboardingTour: AsyncOnboardingTour,
    PatagoniaSettingsModalContainer: AsyncPatagoniaSettingsModalContainer,
    PatagoniaOnboardingTourModalContainer: AsyncPatagoniaOnboardingTourModalContainer,
    PatagoniaRunATestContainer: AsyncPatagoniaRunATestContainer,
    PagePublishOptionsModal: AsyncPagePublishOptionsModal
  },
  extraMofoFields: Object.assign({}, AsyncMofoFields),
  extraTinyMceModals: {
    HtmlEditorModalContainer: AsyncHtmlEditorModalContainer,
    ContentGenerationModalContainer: AsyncContentGenerationModalContainer
  }
};