/* hs-eslint ignored failing-rules */

/* eslint-disable no-prototype-builtins */
'use es6';

import shallowEqual from './shallowEqual';
import Immutable, { fromJS } from 'immutable';
import { pick } from 'underscore';
const defaultConfig = {};

const getValue = (key, state, {
  config = defaultConfig,
  parentConfig,
  prevState
} = {}) => {
  const {
    immutable,
    allowlist,
    exportBeforeMessageSent,
    nestedConfig
  } = config[key];
  const parentConfigIsImmutable = parentConfig && parentConfig.immutable;
  let stateForKey;
  let prevStateForKey;

  if (state) {
    stateForKey = parentConfigIsImmutable ? state.get(key) : state[key];
  }

  if (prevState) {
    prevStateForKey = parentConfigIsImmutable ? prevState.get(key) : prevState[key];
  }

  let value = stateForKey;

  if (nestedConfig && value) {
    for (const nestedSlice in nestedConfig) {
      if (nestedConfig.hasOwnProperty(nestedSlice)) {
        const nestedValue = getValue(nestedSlice, value, {
          config: nestedConfig,
          parentConfig: config[key],
          prevState
        });

        if (immutable) {
          value = value.set(nestedSlice, nestedValue);
        } else {
          value = Object.assign({}, value, {
            [nestedSlice]: nestedValue
          });
        }
      }
    }
  }

  if (exportBeforeMessageSent) {
    value = exportBeforeMessageSent(value, prevStateForKey);
  }

  value = value && immutable ? value.toJS() : value;

  if (allowlist) {
    value = pick(value, allowlist);
  }

  return value;
};
/**
 * Extract current iframe state from the app state based on the config
 *
 * @param {Object} state - current redux state for app
 * @return {Object} state to be sent to the iframe app
 */


export const getIframeAppState = (state, config = defaultConfig) => {
  const inpageState = {};

  for (const key in config) {
    if (config.hasOwnProperty(key)) {
      inpageState[key] = getValue(key, state, {
        config
      });
    }
  }

  return inpageState;
};
/**
 * Calculate a diff of changes in the inpage app state. Intended to be used in
 * redux middleware to calculate what updates need to be synced to the inpage
 * app.
 *
 * @param {Object} prevState - redux state for the app
 * @param {Object} nextState - next redux state for the app
 * @return {Object|null} diff containing key => value changes to inpage state
 */

export const calculateDiff = (prevState, nextState, {
  config = defaultConfig,
  parentConfig
} = {}) => {
  if (prevState === nextState) {
    return null;
  }

  const parentConfigIsImmutable = parentConfig && parentConfig.immutable;
  const diff = {};

  for (const key in config) {
    if (config.hasOwnProperty(key)) {
      const {
        customCompareFunction,
        nestedConfig
      } = config[key];
      const compareFunc = customCompareFunction ? customCompareFunction.bind(config[key]) : shallowEqual;
      let prevStateForKey;

      if (prevState) {
        prevStateForKey = parentConfigIsImmutable ? prevState.get(key) : prevState[key];
      }

      let nextStateForKey;

      if (nextState) {
        nextStateForKey = parentConfigIsImmutable ? nextState.get(key) : nextState[key];
      }

      if (nestedConfig) {
        const nestedDiff = calculateDiff(prevStateForKey, nextStateForKey, {
          config: nestedConfig,
          parentConfig: config[key]
        });

        if (nestedDiff) {
          diff[key] = nestedDiff;
        }
      } else if (!compareFunc(prevStateForKey, nextStateForKey)) {
        diff[key] = getValue(key, nextState, {
          config,
          parentConfig,
          prevState
        });
      }
    }
  }

  if (Object.keys(diff).length === 0) {
    return null;
  }

  return diff;
};

const setOnJsObject = (obj, key, value) => Object.assign({}, obj, {
  [key]: value
});

const setOnImmutableMap = (obj, key, value) => obj.set(key, value);
/**
 * Apply diff of inpage state changes to the inpage state
 *
 * @param {Object} diff - object containing updated key => value changes
 * @param {Object} state - current inpage redux state
 * @return {Object} updated inpage redux state
 */


export const applyDiff = (diff, state, {
  config = defaultConfig,
  parentConfig
} = {}) => {
  let modifiedState = state;

  if (!modifiedState) {
    modifiedState = parentConfig && parentConfig.immutable ? new Immutable.Map() : {};
  }

  const setOnState = parentConfig && parentConfig.immutable ? setOnImmutableMap : setOnJsObject;

  for (const key in diff) {
    if (diff.hasOwnProperty(key)) {
      // Need to allow missing configs here so export functions can pass through extra data that isn't
      // in the inpage sync config
      const {
        immutable,
        parseAfterMessageReceived,
        nestedConfig
      } = config[key] || {};
      const stateForKey = parentConfig && parentConfig.immutable ? modifiedState.get(key) : modifiedState[key];
      let diffValue = diff[key];

      if (nestedConfig) {
        const origDiffValue = diff[key];
        diffValue = applyDiff(origDiffValue, stateForKey, {
          config: nestedConfig,
          parentConfig: config[key]
        });
      } else if (parseAfterMessageReceived) {
        diffValue = parseAfterMessageReceived(diffValue, stateForKey);
      } else {
        diffValue = immutable ? fromJS(diffValue) : diffValue;
      }

      modifiedState = setOnState(modifiedState, key, diffValue);
    }
  }

  return modifiedState;
};