import produce from 'immer';
import { GET_CONTENT_ASSISTANT_SETTINGS } from 'ContentEditorUI/redux/actions/actionTypes';
const initialState = {
  contentAssistanceEnabled: false,
  blogBrandVoiceEnabled: false
};
export const contentAssistantReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case GET_CONTENT_ASSISTANT_SETTINGS:
      draft.contentAssistanceEnabled = action.payload.contentAssistanceEnabled;
      draft.blogBrandVoiceEnabled = action.payload.blogBrandVoiceEnabled;
      return draft;

    default:
      return draft;
  }
});
export default contentAssistantReducer;