'use es6';

import { fromJS } from 'immutable';
import Superstore, { IndexedDB } from 'superstore';
import { USER_SETTINGS_STORE_NAMESPACE, PER_CONTENT_SETTINGS_STORE_NAMESPACE, PER_PORTAL_SETTINGS_STORE_NAMESPACE } from './userAndLocalCacheSettings/constants';
const storeInstancesByNamespace = {};
const ALLOWED_NAMESPACES = [USER_SETTINGS_STORE_NAMESPACE, PER_CONTENT_SETTINGS_STORE_NAMESPACE, PER_PORTAL_SETTINGS_STORE_NAMESPACE];

const matchesAllowedNamespacePrefixes = namespace => ALLOWED_NAMESPACES.includes(namespace);

const getSuperstoreInstance = ({
  namespace,
  partition,
  global
}) => {
  if (!matchesAllowedNamespacePrefixes(namespace)) {
    throw new Error(`Cannot get store instance for namespace "${namespace}", it is not in ALLOWED_NAMESPACES`);
  } else if (storeInstancesByNamespace.hasOwnProperty(namespace)) {
    return storeInstancesByNamespace[namespace];
  }

  storeInstancesByNamespace[namespace] = new Superstore({
    namespace,
    backend: IndexedDB,
    partition,
    global
  }).open();
  return storeInstancesByNamespace[namespace];
};

export const getUserSuperstoreInstance = () => getSuperstoreInstance({
  namespace: USER_SETTINGS_STORE_NAMESPACE,
  // User settings need to to be global to disable default portal-based partitioning
  global: true
});
export const getPerPortalSuperstoreInstance = () => {
  return getSuperstoreInstance({
    namespace: PER_PORTAL_SETTINGS_STORE_NAMESPACE,
    global: false // No need to pass partition, by default superstore partitions by portal ID

  });
};
export const getPerContentSuperstoreInstance = contentId => {
  if (contentId == null) {
    throw new Error('No contentId set in getPerContentSuperstoreInstance');
  }

  return getSuperstoreInstance({
    namespace: PER_CONTENT_SETTINGS_STORE_NAMESPACE,
    global: false,
    partition: () => contentId
  });
}; // Retrieves all requested keys from a store, returning a promise that resolves to an immutable Map
// Note, deeply makes all nested arrays and objects Immutable

export const getAllValuesFromSuperstoreAsMap = (store, keys) => {
  const getPromises = keys.map(key => store.get(key));
  return Promise.all(getPromises).then(values => {
    return fromJS(keys.reduce((obj, key, i) => {
      obj[key] = values[i];
      return obj;
    }, {}));
  });
};