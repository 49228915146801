'use es6';

import LayoutDataCell from 'layout-data-lib/LayoutDataTree/LayoutDataCell';

const hasAnyLayoutStyleDataExcludingVerticalAlignment = cellOrRow => {
  const layoutStyleData = cellOrRow.getLayoutStyleData();
  return layoutStyleData && Object.keys(layoutStyleData).filter(key => key !== 'verticalAlignment').length > 0;
}; // Small editor customization tweaks to the tree lib Cell class


export default class CustomCellTweakingAutoDeleteLogicPerTestFlag extends LayoutDataCell {
  shouldAutoDeleteWhenEmpty() {
    // Ignoring vertical alignment styles when auto-deleting empty ancestors
    return !this.hasAnyCustomCssParams() && !hasAnyLayoutStyleDataExcludingVerticalAlignment(this);
  }

}