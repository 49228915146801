'use es6';

import { buildDndAreaCellSelector } from '../../dom/dndAreaDomSelectors';
import { NO_ALIGNMENT, VERTICAL_ALIGNMENTS, HORIZONTAL_ALIGNMENTS } from 'ContentUtils/constants/Alignments';
const INVALID_ALIGNMENT_VALUE_CODE = 'INVALID_ALIGNMENT_VALUE_CODE';
const JUSTIFY_ITEMS_STYLE_BY_VERTICAL_ALIGNMENT = {
  [VERTICAL_ALIGNMENTS.TOP]: 'flex-start',
  [VERTICAL_ALIGNMENTS.MIDDLE]: 'center',
  [VERTICAL_ALIGNMENTS.BOTTOM]: 'flex-end'
};
const ALIGN_ITEMS_STYLE_BY_HORIZONTAL_ALIGNMENT = {
  [HORIZONTAL_ALIGNMENTS.LEFT]: 'flex-start',
  [HORIZONTAL_ALIGNMENTS.CENTER]: 'center',
  [HORIZONTAL_ALIGNMENTS.RIGHT]: 'flex-end'
};

const cssPropertiesForValue = alignmentValue => {
  if (alignmentValue === NO_ALIGNMENT) {
    return {};
  }

  const tokens = alignmentValue.split('_');
  const alignItemsValue = ALIGN_ITEMS_STYLE_BY_HORIZONTAL_ALIGNMENT[tokens[1]];
  const justifyContentValue = JUSTIFY_ITEMS_STYLE_BY_VERTICAL_ALIGNMENT[tokens[0]];

  if (tokens.length !== 2 || !alignItemsValue || !justifyContentValue) {
    return INVALID_ALIGNMENT_VALUE_CODE;
  }

  return {
    display: 'flex !important',
    'flex-direction': 'column !important',
    'justify-content': `${justifyContentValue}`,
    'align-items': `${alignItemsValue} !important` // Not needed on the FE, but needed in the renderer to support IE 10
    //
    // display: -ms-flexbox !important;
    // -ms-flex-direction: column !important;
    // -ms-flex-pack: {start|center|end}
    // -ms-flex-align: {start|center|end} !important;

  };
};

export const calculateFlexboxPositioningClasses = (treeNode, styleValue) => {
  const cssProperties = cssPropertiesForValue(styleValue);

  if (cssProperties === INVALID_ALIGNMENT_VALUE_CODE) {
    console.warn(`Invalid flexboxPositioning layout style value: ${styleValue}`);
    return [];
  }

  const result = [{
    nodeName: treeNode.getName(),
    domNodeSelector: buildDndAreaCellSelector(treeNode.getName()),
    className: `${treeNode.getName()}-flexbox-positioning`,
    cssSelector: `.${treeNode.getName()}-flexbox-positioning`,
    cssProperties
  }];
  result.push({
    nodeName: treeNode.getName(),
    domNodeSelector: buildDndAreaCellSelector(treeNode.getName()),
    className: `${treeNode.getName()}-flexbox-positioning`,
    // Since "flexbox positioning" is only used for a subset of modules (see
    // MODULES_TYPES_WITH_FLEXBOX_HORIZONTAL_STYLES_ENABLED: cta, image, logo, button), we can
    // always assume there is a `div.hs_cos_wrapper` element that is a child of `.dnd-module`
    cssSelector: `.${treeNode.getName()}-flexbox-positioning > div`,
    cssProperties: {
      // Fix IE (https://git.hubteam.com/HubSpot/ContentEditorUI/issues/7534)
      'flex-shrink': '0 !important',
      'max-width': '100%'
    }
  });
  return result;
};