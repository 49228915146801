import { isObject } from 'ContentComponents/cmv2/utils';
import * as FieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
export const InternalFields = {
  hs_name: 'hs_name',
  hs_path: 'hs_path',
  hs_child_table_id: 'hs_child_table_id'
};
export function getPublishedTablesSortedByPublishedTime(tables) {
  return tables.filter(table => isObject(table) && table.publishedAt > 0).sort(table => table.publishedAt);
}
export function getTablesUsedForPages(tables) {
  return tables.filter(table => table.useForPages);
}
export function mapModuleValueToHubdbValue(value, fieldType, field) {
  switch (fieldType) {
    case FieldTypes.URL:
      {
        return value.href;
      }

    case FieldTypes.VIDEO:
      {
        return value.player_id;
      }

    case FieldTypes.IMAGE:
      {
        return {
          url: value.src,
          height: value.height,
          width: value.width,
          type: 'image'
        };
      }

    case FieldTypes.BOOLEAN:
      {
        return value ? 1 : 0;
      }

    case FieldTypes.HUBDB_ROW:
      {
        return value.toJS();
      }

    case FieldTypes.LOCATION:
      {
        return Object.assign({}, value, {
          type: FieldTypes.LOCATION
        });
      }

    case FieldTypes.CHOICE:
      {
        if (field.multiple) {
          return value.map((option_id, order) => {
            const selectedChoice = field.choices && field.choices.find(choice => choice[0] === option_id.toString());
            return {
              id: option_id,
              order,
              name: selectedChoice && selectedChoice[1],
              type: 'option'
            };
          });
        } else {
          const selectedChoice = field.choices && field.choices.find(choice => choice[0] === value.toString());
          return {
            id: value,
            name: selectedChoice && selectedChoice[1],
            type: 'option'
          };
        }
      }

    default:
      return value;
  }
}
export function getPropertyFromInternalField(fieldId) {
  if (fieldId === InternalFields.hs_child_table_id) return 'childTableId';
  if (fieldId === InternalFields.hs_name) return 'name';
  return 'path';
}