import { combineReducers } from 'redux';
import * as ActionTypes from 'ContentData/actions/ActionTypes';
import { createGenericRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
const {
  THEME_SETTINGS_FETCH
} = ActionTypes;

const themeSettings = (state = {}, action) => {
  switch (action.type) {
    case THEME_SETTINGS_FETCH:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {}, response);
      }

    default:
      return state;
  }
};

export default combineReducers({
  themeSettings,
  themeSettingRequestStatus: createGenericRequestStatusReducer(THEME_SETTINGS_FETCH)
});