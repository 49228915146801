'use es6';

import enviro from 'enviro';
import { getPaneNameFromPath } from 'ContentEditorUI/utils/helpers';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { determineHubletSubdomain } from 'ContentEditorUI/utils/routeHelper';
import { parseQueryString } from 'ContentEditorUI/utils/routeHelper';
import getHistory from 'ContentEditorUI/redux/getHistory';
import EditorConfigSingleton from '../../EditorConfigSingleton';
export const getHost = basicSelector(() => `https://app${determineHubletSubdomain()}.hubspot${enviro.isQa() ? 'qa' : ''}.com`);
export const getRoutingLocation = () => getHistory() ? getHistory().location : null;

const createRoutingSelector = field => {
  return basicSelector(() => {
    const routingLocation = getRoutingLocation();

    if (!routingLocation) {
      return '';
    }

    return routingLocation[field];
  });
};

export const getRoutingPathname = createRoutingSelector('pathname');
export const getRoutingQueryParams = basicSelector(() => {
  const routingLocation = getRoutingLocation();

  if (!routingLocation) {
    return {};
  }

  const queryString = routingLocation.search;
  return parseQueryString(queryString);
});
export const getRoutingHash = createRoutingSelector('hash');
export const getRoutingKey = createRoutingSelector('key');
export const getRoutingCurrentPage = basicSelector(() => {
  const routingPathName = getRoutingPathname();
  return getPaneNameFromPath(routingPathName);
}); // TODO branden eventually replace getRoutingCurrentPage?

export const getCurrentTabId = basicSelector(() => {
  const routingPathName = getRoutingPathname();
  return getPaneNameFromPath(routingPathName);
});
export const getRoutingRelativePath = basicSelector(() => {
  const pathname = getRoutingPathname();

  if (pathname.split('/').length > 3 || EditorConfigSingleton.getIsOnScalableEditor() && pathname.split('/').length > 2) {
    return pathname.substring(0, pathname.lastIndexOf('/') + 1);
  }

  return pathname.endsWith('/') ? pathname : `${pathname}/`;
});
export const getRoutingLocationFromRedux = basicSelector(state => state.__location);
export const getIsEmbeddedEmailEditorRoute = basicSelector(() => {
  const pathname = getRoutingPathname();
  return pathname.includes('edit-csp');
});