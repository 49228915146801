'use es6';

import { FETCH_REVISIONS_SUCCEEDED, FETCH_REVISIONS_FAILED, FETCH_SCHEDULED_VERSION_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import { fromJS } from 'immutable';

const revisionsReducer = (state = fromJS({
  scheduledRevision: null,
  objects: [],
  total: 0,
  offset: 0,
  limit: 0,
  error: null
}), {
  type,
  response,
  error
}) => {
  switch (type) {
    case FETCH_REVISIONS_SUCCEEDED:
      {
        if (response.offset === 0) {
          return state.merge(response);
        }

        const newObjects = state.get('objects').concat(fromJS(response.objects));
        return state.set('objects', newObjects).set('offset', response.offset).set('error', null);
      }

    case FETCH_SCHEDULED_VERSION_SUCCEEDED:
      {
        const scheduledRevision = response.objects[0];

        if (scheduledRevision) {
          return state.set('scheduledRevision', {
            id: scheduledRevision.bufferId,
            object: Object.assign({}, scheduledRevision.object),
            updatedAt: scheduledRevision.object.updated,
            isScheduledUpdate: true
          });
        }

        return state;
      }

    case FETCH_REVISIONS_FAILED:
      return state.set('error', error);

    default:
      return state;
  }
};

export default revisionsReducer;