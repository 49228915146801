/* hs-eslint ignored failing-rules */

/* eslint-disable promise/catch-or-return */
'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import I18n from 'I18n';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import { getPublishType, getIsPublishingNotUpdating, getIsSchedulingNotUpdating, getIsAfterScheduledPublishDate } from 'ContentEditorUI/redux/selectors/publishSelectors';
import { getOriginalPublishDate, getPublishDate } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import PublishApi from 'ContentEditorUI/api/PublishApi';
import { getCategoryId, getIsLandingOrSitePage, getIsBlogPost } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getIsAdvancedPageTemplateOrHasAdvancedFeaturesAccess } from 'ContentEditorUI/redux/selectors/templateInfoSelectors';
import UsageTracker from 'ContentEditorUI/utils/UsageTracker';
import { getHasAnyLayoutSections } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { logSentryWithRecentReduxHistory } from 'ContentEditorUI/redux/actions/errorActions';
import { updateOptimisticLockingForRequest, showLoading, hideLoading, togglePublishOptionsModalOpen, requestedContentPublish, contentPublishSucceeded, contentPublishFailed } from 'ContentEditorUI/redux/actions/appActions';
import getUrlParameters from 'ContentEditorUI/utils/getUrlParameters';
import ContentRoutes from 'ContentUtils/Routes';
import { getActiveStagingDomain, getPageSecondaryToDomain, getIsPageOnPagebuilderDomain } from 'ContentEditorUI/redux/selectors/portalDomainsSelectors';
import { getIsMABExperiment } from 'ContentEditorUI/redux/selectors/mabExperimentSelectors';
import { getSubcategory } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { showErrorWithHTML } from 'ContentEditorUI/utils/errorUtils';
import { getIsAbTest, getABVariantUrl } from 'ContentEditorUI/redux/selectors/abTestSelectors';
import { togglePublishModalOpen } from 'ContentEditorUI/redux/actions/appActions';
import { getActiveDomainIsConnected, getActiveDomainIsInternal } from 'ContentEditorUI/redux/selectors/portalDomainsSelectors';
import { getBaseApiUrl } from 'ContentEditorUI/redux/selectors/extraInitialStateSelectors';
import { CONTENT_SAVE_REQUEST_FAILED, UPDATE_PUBLISH_DATE, UPDATE_PUBLISH_IMMEDIATELY } from 'ContentEditorUI/redux/actions/actionTypes';
import { getBaseContentUrl } from 'ContentEditorUI/api/ContentApi';
import { getAdditionalTranslationOptionsForSaveError, getErrorTitle, getErrorMessage } from 'ContentEditorUI/utils/errorUtils';
import { getHasAccessToUrlRedirects } from 'ContentEditorUI/redux/selectors/authSelectors';
import { getIsFormValid } from '../selectors/formStatusSelectors';
import { getIsPublishOptionsModalOpen } from '../selectors/modalSelectors';
import { stringify } from 'hub-http/helpers/params';
import { EditorMetricsTracker } from 'ContentEditorUI/utils/metricsTracker';
const IGNORE_ERROR_CODE = [401, 403];
export const updatePublishDate = date => {
  return {
    type: UPDATE_PUBLISH_DATE,
    payload: date
  };
};
export const updatePublishImmediately = publishImmediately => (dispatch, getState) => {
  const state = getState();
  let publishDate = getPublishDate(state);
  const originalPublishDate = getOriginalPublishDate(state);

  if (publishImmediately) {
    /**
     * When publishing immediately, content's publishDate
     * should either reflect current time or the already
     * set publishDate from when the content was published first.
     */
    if (!publishDate) {
      publishDate = I18n.moment.portalTz().valueOf(); // for new content `publishDate` is `0` so set current time as the `publishDate`
    } else if (publishDate !== originalPublishDate) {
      /**
       * for updating old published content
       * `publishDate` should remain the same
       * unless customer manually changes
       * the publish date.
       *
       * JIRA for posterity: https://issues.hubspotcentral.com/browse/CG-28120
       */
      publishDate = originalPublishDate;
    }
  }

  if (!publishImmediately && getIsAfterScheduledPublishDate(state)) {
    publishDate = I18n.moment.portalTz().add(1, 'days').valueOf();
  }

  const payload = {
    publishImmediately,
    publishDate
  };
  dispatch({
    type: UPDATE_PUBLISH_IMMEDIATELY,
    payload
  });
};

const trackPublish = trackingData => {
  const {
    contentId,
    isDndPage,
    usageTrackingProperties = {},
    isConnectedAndCustomDomain
  } = trackingData,
        rest = _objectWithoutPropertiesLoose(trackingData, ["contentId", "isDndPage", "usageTrackingProperties", "isConnectedAndCustomDomain"]);

  const properties = Object.assign({}, usageTrackingProperties, {
    what_value: contentId,
    isDragAndDropPage: isDndPage
  }, rest);

  if (isConnectedAndCustomDomain) {
    UsageTracker.trackAsBeacon('publishOnConnectedCustomDomainActivation', properties);
    UsageTracker.trackAsBeacon('publishOnConnectedCustomDomainUsage', properties);
  }

  if (usageTrackingProperties.isSchedulingUpdate) {
    UsageTracker.trackAsBeacon('scheduleUpdateActivation', properties);
    UsageTracker.trackAsBeacon('scheduleUpdateUsage', properties);
  }

  UsageTracker.trackAsBeacon('publishActivation', properties);
  UsageTracker.trackAsBeacon('publishUsage', properties);
  return Promise.resolve();
};

export const redirectAfterPublishDefault = ({
  contentId,
  categoryId,
  state,
  withParams = {}
}) => {
  const urlParams = getUrlParameters();
  let url;
  const mab = getIsMABExperiment(state);
  const subcategory = getSubcategory(state);
  const isPageOrPost = getIsLandingOrSitePage(state) || getIsBlogPost(state);
  const isPublishing = getIsPublishingNotUpdating(state);
  const isScheduling = getIsSchedulingNotUpdating(state);
  const isWorkflowsRedirect = urlParams.redirectName === 'Workflows';
  const isHubSpotUrl = /^http(s)?:\/\/[a-z0-9-]+.hubspot(qa)?.com\//i.test(urlParams.redirectUrl);
  const shouldAllowRedirect = isWorkflowsRedirect && isHubSpotUrl; // If a customer is editing an "advanced" template page _OR_ they have the advanced features
  // scope (from enterprise/pro account or a trial), then we are considering them "Not a starter customer-ish"

  const notStarterCustomerish = getIsAdvancedPageTemplateOrHasAdvancedFeaturesAccess(state);
  const isPageOnPagebuilderDomain = getIsPageOnPagebuilderDomain(state);

  if (urlParams.redirectUrl) {
    if (shouldAllowRedirect) {
      url = urlParams.redirectUrl;
    } else if (window.newrelic) {
      window.newrelic.addPageAction('recordRedirectUrlOnPublishAction', {
        redirectUrl: urlParams.redirectUrl,
        urlParams
      });
    }
  } else if (getActiveStagingDomain(state)) {
    const domain = getPageSecondaryToDomain(state);
    url = ContentRoutes.stagingBeta(domain ? domain.get('id') : null, '/review');
  } else {
    // Default to going to the content-detail-ui performance route, but change the route for MAB
    // experiments and page & blog posts that were just published or scheduled (but not updated)
    //
    // Note also _not_ sending LP starter customers to the published/scheduled routes
    // so that they _do not_ see the regular post-publish survey in content-detail-ui (e.g. keep
    // LP starter feedback separate from the other CSAT data we're collecting from all regular customers)
    let detailsTab = 'performance';

    if (mab) {
      detailsTab = 'testResults';
    } else if (isPageOrPost && isPublishing && notStarterCustomerish) {
      detailsTab = 'published';
    } else if (isPageOrPost && isScheduling && notStarterCustomerish) {
      detailsTab = 'scheduled';
    } // Send the justPublished=true query param in the case that the user has
    // just published on a pagebuilder domain. This triggers the post-publish
    // custom domain modal to open on the content details page, given that
    // they are ungated for the modal and have not seen the modal before
    // or haven't seen it recently.


    const detailsPageQueryParams = isPageOnPagebuilderDomain && !isScheduling ? {
      justPublished: true
    } : null;
    url = window.location.origin + ContentRoutes.details(contentId, categoryId, subcategory, detailsPageQueryParams, {
      tab: detailsTab
    });
  }

  const maybeWithParamsString = stringify(withParams);
  const hasQuery = url.indexOf('?') !== -1;
  url = maybeWithParamsString ? `${url}${hasQuery ? '&' : '?'}${maybeWithParamsString}` : url;
  setTimeout(() => window.location = url, 1);
};

const showPublishErrors = (response, categoryId, state) => {
  const subcategory = getSubcategory(state);
  const additionalTranslationOptions = getAdditionalTranslationOptionsForSaveError(response, categoryId, subcategory);
  const isAbTest = getIsAbTest(state);
  const expireAndUnpublishErrors = ['EXPIRY_BEFORE_PUBLISH_DATE', 'EXPIRY_DATE_NOT_IN_FUTURE', 'EXPIRY_REDIRECT_CONFLICT', 'EXPIRY_REDIRECT_NOT_FOUND'];

  if (isAbTest && response.errorType === 'VALIDATION_FAILED') {
    // TODO clean up this temporary code
    response.errorType = 'AB_VALIDATION_FAILED';
    const bUrl = getABVariantUrl(state);
    response.errorTokens = {
      url: `${bUrl}/review`
    };
    showErrorWithHTML({
      response: {
        responseJSON: response
      },
      additionalTranslationOptions
    });
  } else if (response.errorType === 'REDIRECT_EXISTS' && additionalTranslationOptions.translatedContentType === 'email') {
    const hasAccessToUrlRedirects = getHasAccessToUrlRedirects(state);
    showErrorWithHTML({
      action: hasAccessToUrlRedirects ? 'email' : 'emailNoAccessToUrl',
      response: {
        responseJSON: response
      },
      additionalTranslationOptions
    });
  } else if (response.errorType === 'EMAIL_PUBLISHING_DISABLED') {
    showErrorWithHTML({
      action: 'publishingDisabled',
      response: {
        responseJSON: response
      },
      additionalTranslationOptions
    });
  } else if (expireAndUnpublishErrors.includes(response.errorType)) {
    const data = {
      action: 'saveFailed',
      response: {
        responseJSON: response
      },
      additionalTranslationOptions
    };
    FloatingAlertStore.addAlert({
      titleText: getErrorTitle(Object.assign({
        prefixKey: 'app.errors.unpublish'
      }, data)),
      message: getErrorMessage(Object.assign({
        prefixKey: 'app.errors.unpublish'
      }, data)),
      type: 'danger'
    });
  } else {
    showErrorWithHTML({
      action: 'saveFailed',
      response: {
        responseJSON: response
      },
      additionalTranslationOptions
    });
  }
};

export const makeStructuredContentPublishAction = ({
  actionToBeDispatchedAfterPublishing,
  publishRequestAction,
  // action required for making request to publish/unpublish structured content
  unpublish,
  // boolean value to determine if this is an unpublish or publish action
  saveAction // save action passed along from consumer

}) => ({
  redirectAfterPublish = redirectAfterPublishDefault,
  onPublishError,
  onPublishSuccess,
  skipPublishRedirect = false
}) => (dispatch, getState) => {
  const state = getState();
  const contentId = state.contentModel.get('id');
  const categoryId = getCategoryId(state);
  const isPublishOptionsModalOpen = getIsPublishOptionsModalOpen(state);

  if (!unpublish && !getIsFormValid(state)) {
    return;
  }

  dispatch(requestedContentPublish());
  dispatch(saveAction({
    isSaveBeforePublish: true
  })).then(({
    type
  } = {}) => {
    // If the save request fails, then we don't want to try a publish,
    // due to popping two errors (that will be the same due to a publish requiring the save)
    if (type === CONTENT_SAVE_REQUEST_FAILED) {
      dispatch(contentPublishFailed());
      return;
    }

    dispatch(showLoading());
    dispatch(publishRequestAction()).then(({
      data,
      xhr
    }) => {
      if (isPublishOptionsModalOpen) {
        dispatch(togglePublishOptionsModalOpen(false));
      }

      dispatch(updateOptimisticLockingForRequest({
        hasSaved: true,
        // Note, in Chrome I'm now seeing `Refused to get unsafe header "x-last-modified-timestamp"`,
        // even though `Access-Control-Expose-Headers:x-last-modified-timestamp` is set. I think this
        // is a browser bug though (https://stackoverflow.com/questions/5822985/cross-domain-resource-sharing-get-refused-to-get-unsafe-header-etag-from-re#comment6753514_5837798
        // and https://github.com/MiniProfiler/rack-mini-profiler/issues/95)
        xLastModifiedTimestampHeader: xhr.getResponseHeader('x-last-modified-timestamp')
      }));

      if (typeof onPublishSuccess === 'function') {
        onPublishSuccess();
      }

      dispatch(contentPublishSucceeded());

      if (actionToBeDispatchedAfterPublishing && skipPublishRedirect) {
        dispatch(actionToBeDispatchedAfterPublishing(data));
      }

      if (!skipPublishRedirect) {
        redirectAfterPublish({
          contentId,
          categoryId,
          state,
          withParams: {}
        });
      }
    }).catch((errorResponse = {}) => {
      if (!IGNORE_ERROR_CODE.includes(errorResponse.status)) {
        EditorMetricsTracker.counter('publish-errors').increment();
        dispatch(logSentryWithRecentReduxHistory('Publish errors', {
          errorResponse: errorResponse.responseJSON,
          errorStatus: errorResponse.status
        }, 'publishError'));
      }

      dispatch(updateOptimisticLockingForRequest({
        hasSaved: false,
        xLastModifiedTimestampHeader: errorResponse.xhr.getResponseHeader('x-last-modified-timestamp')
      }));
      dispatch(contentPublishFailed());

      if (typeof onPublishError === 'function') {
        onPublishError(errorResponse);
      }

      return showPublishErrors(errorResponse.responseJSON || {}, categoryId, state);
    }).finally(() => {
      dispatch(hideLoading());
      dispatch(togglePublishModalOpen(false));
    });
  }).catch(() => {
    showPublishErrors({}, categoryId, state);
  }).finally(() => {
    dispatch(hideLoading());
    dispatch(togglePublishModalOpen(false));
  });
};
export const makePublishAction = ({
  unpublish,
  saveAction,
  useReduxForValidation = false
}) => ({
  redirectAfterPublish = redirectAfterPublishDefault,
  onPublishError,
  onPublishSuccess,
  shouldTrackPublish = true,
  trackPublishEvent = trackPublish,
  skipPublishRedirect = false,
  usageTrackingProperties,
  timeout = 15000,
  actionToBeDispatchedAfterPublishing
}) => (dispatch, getState) => {
  const state = getState();
  const contentId = state.contentModel.get('id');
  const categoryId = getCategoryId(state);
  const baseContentUrl = getBaseContentUrl({
    baseUrl: getBaseApiUrl(state),
    categoryId,
    subcategory: getSubcategory(state),
    contentId
  });
  const domainIsConnected = getActiveDomainIsConnected(state);
  const domainIsInternal = getActiveDomainIsInternal(state);
  const isDndPage = getHasAnyLayoutSections(state);
  const publishType = getPublishType(state);
  const isPublishOptionsModalOpen = getIsPublishOptionsModalOpen(state);

  if (useReduxForValidation && !unpublish && !getIsFormValid(state)) {
    return;
  }

  dispatch(requestedContentPublish());
  dispatch(saveAction({
    isSaveBeforePublish: true
  })).then(({
    type
  } = {}) => {
    // If the save request fails, then we don't want to try a publish,
    // due to popping two errors (that will be the same due to a publish requiring the save)
    if (type === CONTENT_SAVE_REQUEST_FAILED) {
      dispatch(contentPublishFailed());
      return;
    }

    const _trackPublishEvent = isSuccessful => {
      if (shouldTrackPublish) {
        // Track publish event may require api calls, so return a promise
        return trackPublishEvent({
          contentId,
          isConnectedAndCustomDomain: domainIsConnected && !domainIsInternal,
          isDndPage,
          publishType,
          skipPublishRedirect,
          usageTrackingProperties,
          isSuccessful
        }) || Promise.resolve();
      }

      return Promise.resolve();
    };

    dispatch(showLoading());
    PublishApi.publish({
      baseContentUrl,
      timeout,
      unpublish
    }).then(({
      xhr
    }) => {
      if (isPublishOptionsModalOpen) {
        dispatch(togglePublishOptionsModalOpen(false));
      }

      dispatch(updateOptimisticLockingForRequest({
        hasSaved: true,
        // Note, in Chrome I'm now seeing `Refused to get unsafe header "x-last-modified-timestamp"`,
        // even though `Access-Control-Expose-Headers:x-last-modified-timestamp` is set. I think this
        // is a browser bug though (https://stackoverflow.com/questions/5822985/cross-domain-resource-sharing-get-refused-to-get-unsafe-header-etag-from-re#comment6753514_5837798
        // and https://github.com/MiniProfiler/rack-mini-profiler/issues/95)
        xLastModifiedTimestampHeader: xhr.getResponseHeader('x-last-modified-timestamp')
      }));

      if (typeof onPublishSuccess === 'function') {
        onPublishSuccess();
      }

      _trackPublishEvent(true).then(() => {
        dispatch(contentPublishSucceeded());

        if (actionToBeDispatchedAfterPublishing) {
          dispatch(actionToBeDispatchedAfterPublishing());
        }

        if (!skipPublishRedirect) {
          redirectAfterPublish({
            contentId,
            categoryId,
            state,
            withParams: {}
          });
        }
      });
    }).catch((errorResponse = {}) => {
      if (!IGNORE_ERROR_CODE.includes(errorResponse.status)) {
        EditorMetricsTracker.counter('publish-errors').increment();
        dispatch(logSentryWithRecentReduxHistory('Publish errors', {
          errorResponse: errorResponse.responseJSON,
          errorStatus: errorResponse.status
        }, 'publishError'));
      }

      dispatch(updateOptimisticLockingForRequest({
        hasSaved: false,
        xLastModifiedTimestampHeader: errorResponse.xhr.getResponseHeader('x-last-modified-timestamp')
      }));
      dispatch(contentPublishFailed());
      UsageTracker.trackEditorInteraction({
        action: 'Publishing Action',
        event: 'publish error'
      });

      if (typeof onPublishError === 'function') {
        onPublishError(errorResponse);
      }

      showPublishErrors(errorResponse.responseJSON || {}, categoryId, state);
      return _trackPublishEvent(false);
    }).finally(() => {
      dispatch(hideLoading());
      dispatch(togglePublishModalOpen(false));
    });
  }).catch(() => {
    showPublishErrors({}, categoryId, state);
  }).finally(() => {
    dispatch(hideLoading());
    dispatch(togglePublishModalOpen(false));
  });
};
export const publish = makePublishAction(false);
export const unpublish = makePublishAction(true);