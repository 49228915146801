import { useCallback, useState } from 'react';
import PortalIdParser from 'PortalIdParser';
import http from 'hub-http/clients/apiClient';
import { DEFAULT_CONTENT_USAGE_LIMITS } from 'ContentEditorUI/context/ContentUsageLimitsContext';

const fetchPagesUsage = isLandingPage => {
  const uri = isLandingPage ? 'cospages/v2/landing-pages' : 'cospages/v2/site-pages';
  return http.get(`${uri}/${PortalIdParser.get()}/usage`).then(({
    quantityUsed,
    pageLimit
  }) => ({
    quantityUsed,
    limit: pageLimit
  }));
};

const getUsageDataIfPromiseFulfilled = response => {
  return response.status === 'fulfilled' ? response.value : {
    quantityUsed: 0,
    limit: null
  };
};

const fetchContentUsageLimits = () => {
  return Promise.allSettled([http.get(`blogs/v3/blog-posts/${PortalIdParser.get()}/usage`), http.get(`blog-settings/v1/blogs/${PortalIdParser.get()}/usage`), fetchPagesUsage(false), fetchPagesUsage(true)]).then(([blogPostsUsageResponse, blogUsageResponse, sitePagesUsageResponse, landingPagesUsageResponse]) => ({
    blogPosts: getUsageDataIfPromiseFulfilled(blogPostsUsageResponse),
    blog: getUsageDataIfPromiseFulfilled(blogUsageResponse),
    sitePages: getUsageDataIfPromiseFulfilled(sitePagesUsageResponse),
    landingPages: getUsageDataIfPromiseFulfilled(landingPagesUsageResponse)
  }));
};

const useFetchContentUsageLimits = () => {
  const [contentUsageLimits, setContentUsageLimits] = useState(DEFAULT_CONTENT_USAGE_LIMITS);
  const [requestStatus, setRequestStatus] = useState('UNINITIALIZED');
  const fetchContentUsage = useCallback(() => {
    setRequestStatus('PENDING');
    return fetchContentUsageLimits().then(response => {
      setContentUsageLimits(response);
      setRequestStatus('SUCCEEDED');
    }, () => setRequestStatus('FAILED'));
  }, []);
  return {
    requestStatus,
    fetchContentUsage,
    contentUsageLimits
  };
};

export default useFetchContentUsageLimits;