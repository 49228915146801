'use es6'; // This API is meant for internal use only between `UIIFrame` and `UIEmbeddedComponent`

import { createBroadcastMessage, createBroadcastReplyMessage, createDirectedMessage, createMessage, createReplyMessage, isOfType, TO_ALL, validFormat } from './IFrameMessage';
export const IFRAME_HOST = 'HOST';
export const MSG_TYPE_SYNC = 'SYNC';
export const MSG_TYPE_SYNC_ACK = 'SYNC_ACK';
export const MSG_TYPE_READY = 'READY';
export const MSG_TYPE_EMBEDDED_PROPS_CHANGED = 'EMBEDDED_PROPS_CHANGED';

const createHostSendMessageContext = (embedId, sendMessageFn) => ({
  sendMessage: (type, data = {}, callback) => {
    const message = createMessage(type, data, {
      to: embedId,
      from: IFRAME_HOST
    });
    sendMessageFn(message, callback);
  },
  sendReplyMessage: (replyToMessage, type, data = {}, callback) => {
    const message = createReplyMessage(replyToMessage, type, data);
    sendMessageFn(message, callback);
  }
});

const createEmbeddedSendMessageContext = (embedId, sendMessageFn, defaultGroup) => ({
  sendMessage: (type, data = {}, callback) => {
    const message = createMessage(type, data, {
      to: IFRAME_HOST,
      from: embedId
    });
    sendMessageFn(message, callback);
  },
  sendReplyMessage: (replyToMessage, type, data = {}, callback) => {
    const message = createReplyMessage(replyToMessage, type, data);
    sendMessageFn(message, callback);
  },
  sendDirectedMessage: (type, data, to, callback) => {
    const message = createDirectedMessage(type, data, {
      to,
      from: embedId
    });
    sendMessageFn(message, callback);
  },
  sendBroadcastMessage: (type, data, group = defaultGroup) => {
    const message = createBroadcastMessage(type, data, {
      from: embedId,
      group
    });
    sendMessageFn(message);
  },
  sendBroadcastReplyMessage: (replyToMessage, type, data) => {
    const message = createBroadcastReplyMessage(replyToMessage, type, data, {
      from: embedId
    });
    sendMessageFn(message);
  }
});

export const isSyncMessage = message => isOfType(message, MSG_TYPE_SYNC);
export const isReadyMessage = message => isOfType(message, MSG_TYPE_READY);
export const isEmbeddedPropsChangedMessage = message => isOfType(message, MSG_TYPE_EMBEDDED_PROPS_CHANGED);
export const isToIFrameHost = message => message.to === IFRAME_HOST || message.to === TO_ALL;
export const createAppData = (info, name) => ({
  info,
  name,
  url: window.location.href
});
export const createHostContext = (embedId, sendMessageFn) => Object.assign({
  id: embedId
}, createHostSendMessageContext(embedId, sendMessageFn));
export const createEmbeddedContext = ({
  embedId,
  group,
  name,
  app,
  sendMessageFn
}) => Object.assign({
  id: embedId,
  group,
  name,
  app
}, createEmbeddedSendMessageContext(embedId, sendMessageFn, group));
export const createSyncMessage = ({
  embedId,
  group,
  name,
  appData,
  embeddedProps
}) => createMessage(MSG_TYPE_SYNC, {
  embedId,
  group,
  name,
  appData,
  embeddedProps
}, {
  to: embedId,
  from: IFRAME_HOST
});
export const createSyncAckMessage = syncMessage => createReplyMessage(syncMessage, MSG_TYPE_SYNC_ACK, {});
export const createReadyMessage = (from, data = {}) => createMessage(MSG_TYPE_READY, data, {
  to: IFRAME_HOST,
  from
});
export const createEmbeddedPropsChangeMessage = (embedId, embeddedProps) => createMessage(MSG_TYPE_EMBEDDED_PROPS_CHANGED, embeddedProps, {
  to: embedId,
  from: IFRAME_HOST
});
export const validMessage = ({
  origin,
  data
}, expectedOrigin) => origin === expectedOrigin && validFormat(data);