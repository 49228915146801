import { CompletionStatuses } from '../../../constants';
import { getMetaDescriptionFromArtifacts } from '../../utils/metaTags';
const MAX_META_DESCRIPTION_LENGTH = 155;
export const shortenMetaDescriptionAudit = artifacts => {
  const metaDescription = getMetaDescriptionFromArtifacts(artifacts);
  let status;
  let length;

  if (!metaDescription) {
    status = CompletionStatuses.BLOCKED;
    length = 0;
  } else if (metaDescription.length > MAX_META_DESCRIPTION_LENGTH) {
    status = CompletionStatuses.INCOMPLETE;
    length = metaDescription.length;
  } else {
    status = CompletionStatuses.COMPLETE;
    length = metaDescription.length;
  }

  return {
    status,
    maxLength: MAX_META_DESCRIPTION_LENGTH,
    length
  };
};