import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';
import { HubUserAttributesClient } from 'frontend-preferences-client';

/**
 * GET blog-settings/v1/blogs/{id}
 */
export function fetchBlog(id, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`blog-settings/v1/blogs/${id}`);
}
/**
 * GET blog-settings/v1/blogs
 */

export function fetchBlogs(query, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('blog-settings/v1/blogs', {
    query
  });
}
/**
 * POST blog-settings/v1/blogs
 * @param {object} data { name, domain, slug, language }
 * @return {Promise} create blog
 */

export function createBlog(data, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.post(`blog-settings/v1/blogs`, {
    data
  });
}
/**
 * POST blogs/v3/blog-posts
 * @param {object} data { name, language }
 * @return {Promise}
 */

export function createBlogPost(id, data, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.post(`blogs/v3/blog-posts`, {
    data: Object.assign({
      contentGroupId: id,
      publishDate: new Date().getTime()
    }, data)
  });
}
const hubUserAttributesClient = HubUserAttributesClient.forCaller('cms-module-form');
const DEFAULT_BLOG_USER_ATTRIBUTE_KEY = ':cms-control-center-favorite-blog';
export function fetchDefaultBlog() {
  return hubUserAttributesClient.fetch(DEFAULT_BLOG_USER_ATTRIBUTE_KEY, '');
}