'use es6';

import http from 'hub-http/clients/apiClient';
import { FETCH_EDITABLE_SLUG_INFO_FAILED, FETCH_EDITABLE_SLUG_INFO_SUCCESS, FETCH_EDITABLE_SLUG_INFO_REQUEST } from 'ContentEditorUI/redux/actions/actionTypes';

const fetchEditableSlugInfoFailure = error => {
  return {
    type: FETCH_EDITABLE_SLUG_INFO_FAILED,
    payload: error
  };
};

const fetchEditableSlugInfoSuccess = slugInfo => {
  return {
    type: FETCH_EDITABLE_SLUG_INFO_SUCCESS,
    payload: slugInfo
  };
};

export const fetchEditableSlugInfo = contentId => dispatch => {
  dispatch({
    type: FETCH_EDITABLE_SLUG_INFO_REQUEST
  });

  const success = slugInfo => dispatch(fetchEditableSlugInfoSuccess(slugInfo));

  const error = err => dispatch(fetchEditableSlugInfoFailure(err));

  http.get(`content-editor/v1/slug-info/${contentId}`).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};