'use es6';

import { createSelector } from 'reselect';
import { Map as ImmutableMap } from 'immutable';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
export const getPortalState = basicSelector(state => state.portal);
export const getPortalScopes = createSelector([getPortalState], portalState => portalState.get('scopes'));
export const getPortalInfo = createSelector([getPortalState], portalState => portalState.get('portalInfo'));
export const getPortalCreatedDate = createSelector([getPortalInfo], portalInfo => portalInfo.get('createdAt'));
export const getIsSandboxPortal = createSelector([getPortalInfo], portalInfo => portalInfo.get('hubType') === 11);

const createHasPortalScopeSelector = scope => createSelector([getPortalScopes], portalScopes => portalScopes.contains(scope));

export const getPortalHasABTesting = createHasPortalScopeSelector('content-ab-testing');
export const getPortalHasDesignManager = createHasPortalScopeSelector('design-manager-access');
export const getPortalHasMeetingsEmbed = createHasPortalScopeSelector('sales-meetings-access');
export const getPortalHasSitePagesCustomDomainAccess = createHasPortalScopeSelector('sitepages-custom-domain-access');
export const getPortalHasBlogCustomDomainAccess = createHasPortalScopeSelector('blog-custom-domain-access');
export const getPortalHasSitePagesHsSitesDomainAccess = createHasPortalScopeSelector('sitepages-hs-sites-domain-write');
export const getPortalHasBlogHsSitesDomainAccess = createHasPortalScopeSelector('blog-hs-sites-domain-write');
const getMultiDomainPublishingScopeChangeInfo = createSelector([getPortalState], portalState => portalState && portalState.get('multiDomainPublishingScopeChangeInfo') || ImmutableMap());
const getMultiDomainPublishingScopeStatus = createSelector([getMultiDomainPublishingScopeChangeInfo], scopeChangeInfo => scopeChangeInfo.get('status'));
export const getMultiDomainPublishingScopePurgeTime = createSelector([getMultiDomainPublishingScopeChangeInfo], scopeChangeInfo => scopeChangeInfo.get('purgedTime'));
export const getAllowDomainChangeForNinetyDaysAfterDowngrade = createSelector([getMultiDomainPublishingScopeStatus], scopeStatus => scopeStatus === 'TO_BE_PURGED');
export const getCustomDomainsPurgeDate = createSelector([getPortalState], portalState => portalState.get('customDomainsPurgeDateForFreeLP'));
export const getSitePagesPasswordProtectionPurgeDate = createSelector([getPortalState], portalState => portalState.get('sitePagesPasswordProtectionPurgeDate'));
export const getLandingPagesPasswordProtectionPurgeDate = createSelector([getPortalState], portalState => portalState.get('landingPagesPasswordProtectionPurgeDate'));
export const getAudienceAccessPurgeDate = createSelector([getPortalState], portalState => portalState.get('audienceAccessPurgeDate'));
export const getDynamicPageAccessPurgeDate = createSelector([getPortalState], portalState => portalState.get('dynamicPageAccessPurgeDate'));