export let sourceKeys;

(function (sourceKeys) {
  sourceKeys["GENERAL"] = "GENERAL";
  sourceKeys["UPGRADE_BUTTON"] = "UPGRADE_BUTTON";
  sourceKeys["USAGE_LIMIT"] = "USAGE_LIMIT";
  sourceKeys["LIMIT_PACK"] = "LIMIT_PACK";
  sourceKeys["FEATURE_LIMIT"] = "FEATURE_LIMIT";
  sourceKeys["CHECKOUT"] = "CHECKOUT";
  sourceKeys["CONTACT_SALES"] = "CONTACT_SALES";
  sourceKeys["CONTACT_SALES_BUTTON"] = "CONTACT_SALES_BUTTON";
  sourceKeys["TRIAL"] = "TRIAL";
  sourceKeys["TRIAL_BUTTON"] = "TRIAL_BUTTON";
  sourceKeys["EXPIRED_TRIAL_MODAL"] = "EXPIRED_TRIAL_MODAL";
  sourceKeys["TRIAL_MODAL"] = "TRIAL_MODAL";
  sourceKeys["LOCKED_FEATURE"] = "LOCKED_FEATURE";
  sourceKeys["LOCKED_APP"] = "LOCKED_APP";
  sourceKeys["UPGRADE_BANNER"] = "UPGRADE_BANNER";
  sourceKeys["PRODUCT_ACTIVATION"] = "PRODUCT_ACTIVATION";
  sourceKeys["STANDALONE"] = "STANDALONE";
})(sourceKeys || (sourceKeys = {}));

export const sources = {
  [sourceKeys.GENERAL]: 'general',
  [sourceKeys.UPGRADE_BUTTON]: 'upgrade-button',
  // Will be deprecated
  [sourceKeys.USAGE_LIMIT]: 'usage-limit',
  [sourceKeys.LIMIT_PACK]: 'limit-pack',
  [sourceKeys.FEATURE_LIMIT]: 'feature-limit',
  // Will be deprecated
  [sourceKeys.CHECKOUT]: 'checkout',
  [sourceKeys.CONTACT_SALES]: 'contact-sales',
  [sourceKeys.CONTACT_SALES_BUTTON]: 'contact-sales-button',

  /** @deprecated */
  [sourceKeys.TRIAL]: 'trial',
  [sourceKeys.TRIAL_BUTTON]: 'trial-button',
  [sourceKeys.EXPIRED_TRIAL_MODAL]: 'expired-trial-modal',
  [sourceKeys.TRIAL_MODAL]: 'trial-modal',
  [sourceKeys.LOCKED_FEATURE]: 'locked-feature',
  [sourceKeys.LOCKED_APP]: 'locked-app',
  [sourceKeys.UPGRADE_BANNER]: 'upgrade-banner',
  [sourceKeys.PRODUCT_ACTIVATION]: 'product-activation',
  [sourceKeys.STANDALONE]: 'standalone'
};