import { EditorMetricsTracker } from './metricsTracker'; // After the app's HTML finishes (performance.timing.responseEnd - performance.timing.navigationStart)

export const PARENT_DOCUMENT_RESPONSE_FINISHED = 'PARENT_DOCUMENT_RESPONSE_FINISHED'; // When main app JS starts/finishes executing (after network download and VM parse time)

export const PARENT_APP_JS_INJECTED = 'PARENT_APP_JS_INJECTED';
export const PARENT_APP_JS_STARTED_EXECUTING = 'PARENT_APP_JS_STARTED_EXECUTING';
export const PARENT_APP_JS_FINISHED_EXECUTING = 'PARENT_APP_JS_FINISHED_EXECUTING'; // When inpage JS starts/finishes executing (after network download and VM parse time)

export const INPAGE_JS_STARTED_EXECUTING = 'INPAGE_JS_STARTED_EXECUTING';
export const INPAGE_JS_FINISHED_EXECUTING = 'INPAGE_JS_FINISHED_EXECUTING'; // Note there is some subtlety with the "finished" lifecycles above. They don't mean that every line
// in the JS bundle has been run, rather that the VM has taken its first execution pass through the
// whole file and has started moving to the next script/blocking browser operation.
//
// Essentially, these start/finish data points are trying to capture how long our large JS bundle
// "blocks" the UI, because nothing else can happen (and the browser will seem frozen) until the script
// has been run through
// Various critical APIs

export const COMBINED_EDIT_REQUEST_STARTED = 'COMBINED_EDIT_REQUEST_STARTED';
export const COMBINED_EDIT_REQUEST_FINISHED = 'COMBINED_EDIT_REQUEST_FINISHED';
export const PREVIEW_KEY_REQUEST_STARTED = 'PREVIEW_KEY_REQUEST_STARTED';
export const PREVIEW_KEY_REQUEST_FINISHED = 'PREVIEW_KEY_REQUEST_FINISHED';
export const PREVIEW_INFO_REQUEST_STARTED = 'PREVIEW_INFO_REQUEST_STARTED';
export const PREVIEW_INFO_REQUEST_FINISHED = 'PREVIEW_INFO_REQUEST_FINISHED';
export const CUSTOM_MODULE_SCHEMA_REQUEST_STARTED = 'CUSTOM_MODULE_SCHEMA_REQUEST_STARTED';
export const CUSTOM_MODULE_SCHEMA_REQUEST_FINISHED = 'CUSTOM_MODULE_SCHEMA_REQUEST_FINISHED';
export const GLOBAL_MODULE_SCHEMA_REQUEST_STARTED = 'GLOBAL_MODULE_SCHEMA_REQUEST_STARTED';
export const GLOBAL_MODULE_SCHEMA_REQUEST_FINISHED = 'GLOBAL_MODULE_SCHEMA_REQUEST_FINISHED'; // Preview frame lifecycle

export const PREVIEW_IFRAME_STARTED = 'PREVIEW_IFRAME_STARTED';
export const PREVIEW_IFRAME_WAITING_ON_PARENT_FRAME = 'PREVIEW_IFRAME_WAITING_ON_PARENT_FRAME';
export const PARENT_SENDING_PREVIEW_FRAME_INITIAL_PAYLOAD = 'PARENT_SENDING_PREVIEW_FRAME_INITIAL_PAYLOAD';
export const PREVIEW_IFRAME_DOMREADY = 'PREVIEW_IFRAME_DOMREADY';
export const PREVIEW_IFRAME_UX_READY = 'PREVIEW_IFRAME_UX_READY'; // Sidebar frame lifecycle

export const SIDEBAR_IFRAME_STARTED = 'SIDEBAR_IFRAME_STARTED';
export const SIDEBAR_IFRAME_UX_READY = 'SIDEBAR_IFRAME_UX_READY'; // When the whole parent app (but not necessarily the iframes) are ready

export const PARENT_APP_UX_READY = 'PARENT_APP_UX_READY'; // When the whole app and iframes are ready to go

export const WHOLE_APP_UX_READY = 'WHOLE_APP_UX_READY';
const LIFECYCLES_ALREADY_TRACKED = {};
const lifecycleQueue = window.hsQueuedLifecycleTimestamps || {};
export const trackAppLifecycleTimestamp = (lifecycleName, {
  timestamp,
  shouldCreatePerformanceMark = true
} = {}) => {
  // Ensure we only track a lifecycle timestamp once
  if (LIFECYCLES_ALREADY_TRACKED.hasOwnProperty(lifecycleName)) {
    return;
  }

  timestamp = timestamp || window.performance.now(); // If early request logic or something else pushed a lifecycle onto the queue but _after_
  // trackQueuedLifecycleTimestamps was run, then double check if the queued timestamp is less
  // then the passed/now timestamp (and use it instead)

  if (lifecycleQueue[lifecycleName] && lifecycleQueue[lifecycleName] < timestamp) {
    timestamp = lifecycleQueue[lifecycleName]; // Assume that queued up marks from quick fetch and others handled their own marks

    shouldCreatePerformanceMark = false;
  }

  LIFECYCLES_ALREADY_TRACKED[lifecycleName] = timestamp;

  if (shouldCreatePerformanceMark && window.performance.mark) {
    window.performance.mark(lifecycleName);
  }

  EditorMetricsTracker.timer(`EP_LIFECYCLE_${lifecycleName}`).update(timestamp);
}; // Make available on the window so that non transpiled code can use this function
// (for better precision tracking JS execution time)

window.hsTrackAppLifecycleTimestamp = trackAppLifecycleTimestamp;
export const trackQueuedLifecycleTimestamps = () => {
  if (lifecycleQueue && Object.keys(lifecycleQueue).length > 0) {
    Object.keys(lifecycleQueue).forEach(lifecycle => {
      const timestamp = lifecycleQueue[lifecycle];
      trackAppLifecycleTimestamp(lifecycle, {
        timestamp,
        shouldCreatePerformanceMark: false
      });
    });
  }
};
export const getAppLifecycleTimestamps = () => {
  return LIFECYCLES_ALREADY_TRACKED;
};