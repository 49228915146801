import { combineReducers } from 'redux';
import { PENDING, SUCCEEDED, FAILED, NOT_FOUND } from 'ContentUtils/constants/RequestStatus';
import { NOT_FOUND as CODE_NOT_FOUND } from 'ContentUtils/enums/HttpStatusCodes';
import { EMBED_FETCH_ASSET, EMBED_FETCH_MEDIA_BRIDGE_ASSET, EMBED_FETCH_MEDIA_BRIDGE_PROVIDERS, EMBED_UPDATE_ASSET } from 'ContentData/actions/ActionTypes';

const embeds = (state = {}, action) => {
  switch (action.type) {
    case EMBED_FETCH_ASSET:
      {
        const {
          options: {
            oEmbedUrl
          },
          response
        } = action;
        if (!(response && response.type)) return state;
        return Object.assign({}, state, {
          [oEmbedUrl]: response
        });
      }

    case EMBED_UPDATE_ASSET:
      {
        const {
          oEmbedAsset,
          oEmbedUrl
        } = action;
        if (!oEmbedAsset) return state;
        return Object.assign({}, state, {
          [oEmbedUrl]: oEmbedAsset
        });
      }

    default:
      return state;
  }
};

const mediaBridgeProviders = (state = {}, action) => {
  switch (action.type) {
    case EMBED_FETCH_MEDIA_BRIDGE_PROVIDERS:
      {
        const {
          response
        } = action;
        if (!(response && response.length > 0)) return state;
        const HSVIDEO_APPID = 1204600;
        const newState = response.reduce((acc, provider) => {
          if (provider.appId !== HSVIDEO_APPID) {
            acc[provider.appId] = provider;
          }

          return acc;
        }, {});
        return Object.assign({}, state, {}, newState);
      }

    default:
      return state;
  }
};

const mediaBridgeProvidersRequestStatus = (state = {
  byProviderId: {},
  installedProviders: null
}, action) => {
  switch (action.type) {
    case EMBED_FETCH_MEDIA_BRIDGE_PROVIDERS:
      {
        const {
          options: providerIds,
          response,
          error
        } = action;

        if (!providerIds || !providerIds.length) {
          let status = PENDING;
          if (response) status = SUCCEEDED;
          if (error) status = FAILED;
          return Object.assign({}, state, {
            installedProviders: status
          });
        } else {
          let responsesByProviderId = {};

          if (response) {
            responsesByProviderId = response.reduce((acc, provider) => {
              acc[provider.appId] = provider;
              return acc;
            }, {});
          }

          const newProvidersState = providerIds.reduce((acc, providerId) => {
            let status = PENDING;

            if (response) {
              status = responsesByProviderId[providerId] ? SUCCEEDED : FAILED;
            }

            if (error) status = FAILED;
            acc[providerId] = status;
            return acc;
          }, {});
          return Object.assign({}, state, {
            byProviderId: Object.assign({}, state.byProviderId, {}, newProvidersState)
          });
        }
      }

    default:
      return state;
  }
};

const mediaBridgeAssetRequestStatus = (state = {}, action) => {
  switch (action.type) {
    case EMBED_FETCH_MEDIA_BRIDGE_ASSET:
      {
        const {
          response,
          error,
          options: {
            mediaId
          }
        } = action;
        let requestStatus = PENDING;
        if (response) requestStatus = SUCCEEDED;

        if (error) {
          requestStatus = error.status === CODE_NOT_FOUND ? NOT_FOUND : FAILED;
        }

        return Object.assign({}, state, {
          [mediaId]: requestStatus
        });
      }

    default:
      return state;
  }
};

const embedRequestStatus = (state = {}, action) => {
  switch (action.type) {
    case EMBED_FETCH_ASSET:
      {
        const {
          response,
          error,
          options: {
            oEmbedUrl
          }
        } = action;
        let requestStatus = PENDING;
        if (response) requestStatus = SUCCEEDED;
        if (error) requestStatus = FAILED;
        return Object.assign({}, state, {
          [oEmbedUrl]: requestStatus
        });
      }

    default:
      return state;
  }
};

export default combineReducers({
  embeds,
  mediaBridgeProviders,
  requestStatus: embedRequestStatus,
  mediaBridgeAssetRequestStatus,
  mediaBridgeProvidersRequestStatus
});