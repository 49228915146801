import { getIsFreePortalAndOwnedProducts } from '../_core/common/eligibility/getIsFreePortal';
import { getUpgradePointPromise, validateBannerUpgradeConfig } from './utils';

const getCorrectedUpgradePoint = upgradePoint => {
  upgradePoint.upgradeProduct = upgradePoint.upgradeProducts[0];
  upgradePoint.apiName = upgradePoint.apiNames[0];
  return upgradePoint;
};

export const fetchValidatedBannerDetails = upgradeKey => {
  return Promise.all([getIsFreePortalAndOwnedProducts(), getUpgradePointPromise({
    upgradeKey,
    delayCall: false
  })]).then(([isFreePortalAndOwnedProductsResponse, upgradePointResponse]) => {
    if (!upgradePointResponse) return {};
    const upgradePoint = getCorrectedUpgradePoint(upgradePointResponse);

    try {
      validateBannerUpgradeConfig(upgradePoint);
    } catch (validationError) {
      console.error(validationError);
      throw validationError;
    }

    return {
      upgradeConfig: upgradePoint,
      isFreePortal: isFreePortalAndOwnedProductsResponse.isFreePortal,
      ownedProducts: isFreePortalAndOwnedProductsResponse.ownedProducts
    };
  });
};