import PortalIdParser from 'PortalIdParser';
import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';
/**
 * GET sfdc/v1/campaigns/{portalId}/{id}
 */

export const fetchCampaign = (id, ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`sfdc/v1/campaigns/${PortalIdParser.get({
    preserveGlobalId: true
  })}/${id}`);
};
/**
 * GET sfdc/v1/campaigns/{portalId}
 */

export const fetchCampaigns = (...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`sfdc/v1/campaigns/${PortalIdParser.get({
    preserveGlobalId: true
  })}`);
};
/**
 * GET sfdc/v1/connection-status
 */

export const fetchIntegrationStatus = (...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('sfdc/v1/connection-status');
};