export const isTemporarySlug = slug => {
  return slug && (slug.indexOf('-temporary-slug-') > -1 || slug.indexOf('-temporary-url-') > -1);
};
export const getURLWithoutProtocol = url => url.substring(url.indexOf('://') + 3, url.length);
export const buildLanguageString = (bodyOverrideByDomain, language = '', domain) => {
  const bodyContentLanguage = bodyOverrideByDomain.getIn([domain, 'contentLanguage']) || bodyOverrideByDomain.getIn([domain, 'content_language']);
  const addLangToSlug = bodyOverrideByDomain.getIn([domain, 'addDomainLangToSlug']) || bodyOverrideByDomain.getIn([domain, 'add_domain_lang_to_slug']);

  if (language && (language !== bodyContentLanguage || addLangToSlug)) {
    return language;
  }

  return '';
};
export const buildFullUrl = (bodyOverrideByDomain, activeDomain, slugRaw = '', language) => {
  if (!activeDomain) {
    return '';
  }

  const domain = activeDomain.get('domain', '');
  const languageString = buildLanguageString(bodyOverrideByDomain, language, domain);
  const languagePath = languageString ? `${languageString}/` : '';
  const domainWithLanguage = `${domain}/${languagePath}`;
  const protocol = activeDomain && (activeDomain.get('is_ssl_only', false) || activeDomain.get('isSslOnly', false)) ? 'https://' : 'http://';
  return `${protocol}${domainWithLanguage}${slugRaw}`;
};
export const buildSlugWithLang = (slug, language) => {
  if (language) {
    return `${language}/${slug}`;
  }

  return slug;
};