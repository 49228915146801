import { isMS } from './BrowserTest';
export const overflowsX = (innerEl, outerEl = innerEl) => {
  if (innerEl === null || outerEl === null) {
    return false;
  }

  const tolerance = isMS() ? 1 : 0; // IE/Edge rounding workaround; see HubSpot/UIComponents#1711

  const contentWidth = innerEl.scrollWidth;
  const containerWidth = outerEl.clientWidth;
  return contentWidth > containerWidth + tolerance;
};
export const overflowsY = (innerEl, outerEl = innerEl) => {
  if (innerEl === null || outerEl === null) {
    return false;
  }

  const tolerance = isMS() ? 1 : 0; // IE/Edge rounding workaround; see HubSpot/UIComponents#1711

  const contentHeight = innerEl.scrollHeight;
  const containerHeight = outerEl.clientHeight;
  return contentHeight > containerHeight + tolerance;
};