import { createSelector } from 'reselect';
export const loadingReducer = (state = {}, action) => {
  const matches = /(.*)_(STARTED|SUCCEEDED|FAILED)/.exec(action.type);

  if (!matches) {
    return state;
  }

  const [, requestName, requestState] = matches;
  return Object.assign({}, state, {
    [requestName]: requestState === 'STARTED'
  });
};

const isWaitingForQueryResponse = loading => {
  const isFetchNotStarted = loading === undefined;
  return isFetchNotStarted || loading;
};

const selectLoadingState = state => state.loading;

export const selectIsFetchingAttachedCluster = createSelector(selectLoadingState, loading => isWaitingForQueryResponse(loading['FETCH_ATTACHED_CLUSTER']));
export const selectIsFetchingClusters = createSelector(selectLoadingState, loading => isWaitingForQueryResponse(loading['FETCH_CLUSTERS']));
export const selectIsFetchingTopics = createSelector(selectLoadingState, loading => isWaitingForQueryResponse(loading['FETCH_TOPICS']));
export const selectIsFetchingThingsPeopleCover = createSelector(selectLoadingState, loading => isWaitingForQueryResponse(loading['FETCH_THINGS_PEOPLE_COVER']));