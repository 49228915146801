'use es6';

export default function memoize(func, hasher = args => JSON.stringify(args)) {
  const memoized = function memoized(...args) {
    const breakMemoization = !!args.length && !!args[0] && args.find(arg => arg.shouldMemoize && arg.shouldMemoize === false);

    if (breakMemoization) {
      return func.apply(this, args);
    }

    const cache = memoized.cache;
    const address = `${hasher.apply(this, args)}`;
    if (!cache[address]) cache[address] = func.apply(this, args);
    return cache[address];
  };

  memoized.cache = {};
  return memoized;
}