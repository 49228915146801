'use es6';

import Immutable from 'immutable';
import { START_ONBOARDING_TOUR_IN_PARENT, LAYOUT_SECTION_INSERTED_IN_EMPTY_COLUMN, LAYOUT_SECTION_INSERTED_IN_EMPTY_ROW, LAYOUT_SECTION_COLUMN_INSERTED_AFTER, LAYOUT_SECTION_COLUMN_INSERTED_BEFORE, LAYOUT_SECTION_ROW_INSERTED_AFTER, LAYOUT_SECTION_ROW_INSERTED_BEFORE, SHOW_ONBOARDING_TOUR_QUIT_MODAL, HIDE_ONBOARDING_TOUR_QUIT_MODAL, FINISH_TOUR_INPAGE, SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP, GO_TO_STYLE_SECTION, TRACK_LP_ONBOARDING_TOUR_PROGRESSION, CLEAR_PROGRESSION_TO_TRACK } from 'ContentEditorUI/redux/actions/actionTypes';
const initialState = Immutable.Map({
  currentTourIframe: 'inpage',
  hasDraggedModuleIn: false,
  showOnboardingTourQuitModal: false,
  finishTourInPage: false,
  goToStyleSection: false,
  progressionToTrack: Immutable.Map({})
});

const onboardingTourStateReducer = (state = initialState, {
  type,
  progressionToTrack
}) => {
  switch (type) {
    case START_ONBOARDING_TOUR_IN_PARENT:
      {
        return state.merge({
          currentTourIframe: 'parent'
        });
      }

    case LAYOUT_SECTION_INSERTED_IN_EMPTY_COLUMN:
    case LAYOUT_SECTION_INSERTED_IN_EMPTY_ROW:
    case LAYOUT_SECTION_COLUMN_INSERTED_AFTER:
    case LAYOUT_SECTION_COLUMN_INSERTED_BEFORE:
    case LAYOUT_SECTION_ROW_INSERTED_AFTER:
    case LAYOUT_SECTION_ROW_INSERTED_BEFORE:
      {
        if (!state.getIn(['onboardingTourState', 'hasDraggedModuleIn'])) {
          return state.merge({
            hasDraggedModuleIn: true
          });
        }

        return state;
      }

    case SHOW_ONBOARDING_TOUR_QUIT_MODAL:
      return state.merge({
        showOnboardingTourQuitModal: true
      });

    case HIDE_ONBOARDING_TOUR_QUIT_MODAL:
      return state.merge({
        showOnboardingTourQuitModal: false
      });

    case FINISH_TOUR_INPAGE:
      return state.merge({
        finishTourInPage: true
      });

    case SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP:
      return state.merge({
        currentTourIframe: 'parent',
        finishTourInPage: true
      });

    case GO_TO_STYLE_SECTION:
      return state.merge({
        goToStyleSection: true
      });

    case TRACK_LP_ONBOARDING_TOUR_PROGRESSION:
      return state.merge({
        progressionToTrack
      });

    case CLEAR_PROGRESSION_TO_TRACK:
      return state.merge({
        progressionToTrack: {}
      });

    default:
      return state;
  }
};

export default onboardingTourStateReducer;