import { createSelector } from 'reselect';
import { createGenericOptionsSelector } from 'ContentData/helpers/reduxHelpers';
export const getPages = state => state.resources.pages.pages;
export const getPage = (state, props) => state.resources.pages.pages[props.id];

const getPagesCacheOrder = state => state.resources.pages.pagesCacheOrder;

export const getPageCache = (state, props) => state.resources.pages.pagesCache[props.id];
export const getPagesCache = state => state.resources.pages.pagesCache;
export const getPageFetchStatus = (state, props) => state.resources.pages.requestStatus[props.id];
export const getPagesFetchStatus = state => state.resources.pages.pagesRequestStatus;

const disabledPredicate = page => page && page.isPublished;

const getHelpText = page => page && page.absoluteUrl;

export const getPageOptions = createSelector([getPagesCache, getPagesCacheOrder], (pagesCache, order) => order.reduce((hash, id) => {
  const page = pagesCache[id];
  hash.push({
    value: id,
    text: page.name,
    help: page.absoluteUrl
  });
  return hash;
}, []));
export const getDefaultPageOptions = createGenericOptionsSelector(getPages, {
  disabledPredicate,
  getHelpText
});