import { PAGE_SETTINGS_TAB_FIELD, POST_SETTINGS_TAB_FIELD // @ts-expect-error TS not migrated
} from 'ContentEditorUI/lib/xray/constants';
export const getGathererKeys = config => {
  const gathererSet = new Set();

  if (config) {
    const {
      recommendations
    } = config;

    if (recommendations) {
      recommendations.forEach(rec => {
        const {
          gathererKeys
        } = rec;

        if (gathererKeys) {
          gathererKeys.forEach(gatherer => {
            gathererSet.add(gatherer);
          });
        }
      });
    }
  }

  return Array.from(gathererSet);
};
export const getSettingsFieldForContentType = (field, isBlogPost) => {
  return isBlogPost ? POST_SETTINGS_TAB_FIELD[field] : PAGE_SETTINGS_TAB_FIELD[field];
};