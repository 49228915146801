'use es6';

import { connect } from 'react-redux';
import { Component } from 'react';
import { getIsI18nReady } from 'ContentEditorUI/redux/selectors/appStatusSelectors';

const mapStateToProps = state => {
  return {
    isReady: getIsI18nReady(state)
  };
};

class I18nReadyContainer extends Component {
  render() {
    if (!this.props.isReady) {
      return null;
    }

    return this.props.children;
  }

}
/* eslint-enable react/prop-types */


export default connect(mapStateToProps)(I18nReadyContainer);