import { AccountVerificationProgressStatus } from '../enums';
import mapAccountVerificationProgressStatusToUIAlertType from '../util/mapAccountVerificationProgressStatusToUIType';
import mapScopeStatusToAccountVerificationProgressStatus from '../util/mapScopeStatusToAccountVerificationProgressStatus';
import toSafeEpoch from '../util/toSafeEpoch';
const LANG_BASE_PATH = 'ui-suspension-banners-lib.accountSuspension';
const {
  ACCEPTED_ACCOUNT_VERIFICATION,
  NEEDS_ACCOUNT_VERIFICATION_APPEAL,
  NOT_APPLICABLE
} = AccountVerificationProgressStatus;

const useBanner = (userHidBanner, status) => {
  const progressStatus = mapScopeStatusToAccountVerificationProgressStatus(status);
  const safeDecisionDate = status ? toSafeEpoch(status.decidedAt) : new Date().getTime();
  const today = new Date();
  const decidedAt = new Date(safeDecisionDate);
  const daySinceDecision = (today.getTime() - decidedAt.getTime()) / (1000 * 60 * 60 * 24);
  const verificationAccepted = progressStatus === ACCEPTED_ACCOUNT_VERIFICATION;
  const olderAcceptedVerification = verificationAccepted && daySinceDecision >= 60;
  const userClosedClosableBanner = verificationAccepted && userHidBanner;
  const hideBanner = progressStatus === undefined || progressStatus === NOT_APPLICABLE || olderAcceptedVerification || userClosedClosableBanner;
  const scopeEnabled = progressStatus === NOT_APPLICABLE || progressStatus === ACCEPTED_ACCOUNT_VERIFICATION;
  const showCta = progressStatus === NEEDS_ACCOUNT_VERIFICATION_APPEAL;
  const alertType = mapAccountVerificationProgressStatusToUIAlertType(progressStatus);
  const baseLangPath = `${LANG_BASE_PATH}.${progressStatus}`;
  const langPath = {
    title: `${baseLangPath}.title`,
    body: `${baseLangPath}.body_jsx`,
    cta: `${baseLangPath}.cta`
  };
  return {
    showBanner: !hideBanner,
    scopeDisabled: !scopeEnabled,
    showCta,
    alertType,
    langPath,
    closable: verificationAccepted
  };
};

export default useBanner;