import { createSelector } from 'reselect';
import { ID, NAME, SLUG, ALL } from 'ContentUtils/enums/TagDefaultValueTypes';
export const getTags = state => state.resources.tags.tags;

const getValue = (state, props) => props.value;

const getTagValue = (state, props) => props.tagValue || SLUG;

export const getTag = createSelector([getValue, getTagValue, getTags], (key, tagValue, tags) => {
  if (tagValue === ID) {
    return tags.byId[key];
  }

  if (tagValue === NAME || tagValue === ALL) {
    return tags.byName[key];
  }

  return tags.bySlug[key];
});
export const getTagsCache = state => {
  return state.resources.tags.tagsCache;
};
export const getTagsCacheRequestStatus = state => state.resources.tags.tagsCacheRequestStatus;
export const getTagCache = createSelector([getValue, getTagValue, getTagsCache], (key, tagValue, tagsCache) => {
  if (tagValue === ID) {
    return tagsCache.byId[key];
  }

  if (tagValue === NAME || tagValue === ALL) {
    return tagsCache.byName[key];
  }

  return tagsCache.bySlug[key];
});
export const getTagFetchStatus = (state, props) => state.resources.tags.requestStatus[props.key];
export const getTagOptions = createSelector([getTagValue, getTagsCache], (tagValue, tagsCache) => {
  let cache = tagsCache.bySlug;
  if (tagValue === ID) cache = tagsCache.byId;
  if (tagValue === NAME || tagValue === ALL) cache = tagsCache.byName;
  return Object.keys(cache).map(key => {
    const tag = cache[key];
    const value = tagValue === ID ? Number(key) || key : key;
    return {
      value,
      text: tag.name
    };
  });
});
export const makeGetDefaultTagOptions = () => {
  return createSelector([getTagValue, getTags], (tagValue, allTags) => {
    let tags = allTags.bySlug;
    if (tagValue === ID) tags = allTags.byId;
    if (tagValue === NAME || tagValue === ALL) tags = allTags.byName;
    return Object.keys(tags).map(key => {
      const tag = tags[key];
      const value = tagValue === ID ? Number(key) || key : key;
      return {
        value,
        text: tag.name
      };
    });
  });
};