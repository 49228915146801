//@ts-expect-error range util is js
import range from '../utils/underscore/range';
export const MAX_OFFSET = 12;
export const MAX_SIZE = 12;
export const MIN_OFFSET = 0;
export const MIN_SIZE = 1;
export const OFFSET_RANGE = range(MIN_OFFSET, MAX_OFFSET + 1);
export const SIZE_RANGE = range(MIN_SIZE, MAX_SIZE + 1);
export const SCREEN_SIZES = ['xs', 'sm', 'md', 'lg', 'xl'];

function createSizeClasses(screenSize) {
  const classes = [];

  for (let i = MIN_OFFSET; i <= MAX_SIZE; i++) {
    if (i === 0) {
      classes[i] = '';
    } else {
      classes[i] = `col-${screenSize}-${i}`;
    }
  }

  return classes;
}

function createAllSizeClasses(append = '') {
  const holdScreenClasses = {};
  SCREEN_SIZES.forEach(screenSize => {
    holdScreenClasses[screenSize] = createSizeClasses(`${screenSize}${append}`);
  });
  return holdScreenClasses;
}

export const SIZE_CLASSES = createAllSizeClasses();
export const OFFSET_CLASSES = createAllSizeClasses('-offset');