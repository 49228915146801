'use es6';

import { FETCH_CONTENT_SCHEMA_SUCCEEDED, UPDATE_SITE_SETTINGS, UPDATE_SITE_SETTINGS_DOMAIN_LANGUAGE } from 'ContentEditorUI/redux/actions/actionTypes';
import { Map as ImmutableMap, fromJS } from 'immutable';

const siteSettingsReducer = (state = ImmutableMap(), {
  type,
  payload,
  response
}) => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      return fromJS(response.settings);

    case UPDATE_SITE_SETTINGS:
      return fromJS(payload);

    case UPDATE_SITE_SETTINGS_DOMAIN_LANGUAGE:
      return state.setIn(['bodyOverrideByDomain', payload.domain], fromJS({
        contentLanguage: payload.language
      }));

    default:
      return state;
  }
};

export default siteSettingsReducer;