'use es6';

import PortalIdParser from 'PortalIdParser';
import http from 'hub-http/clients/apiClient';
import getEarlyRequesterResultPromise from 'ContentEditorUI/earlyRequester/getEarlyRequesterResultPromise';
import enviro from 'enviro';
const MULTI_DOMAIN_PUBLISHING_SCOPE_ID = 97;
const LANDINGPAGES_DOMAINS_CHANGE = enviro.isQa() ? 677 : 559;
const SITEPAGES_PASSWORD_PROTECTION_CHANGE = enviro.isQa() ? 3280 : 1867;
const LANDINGPAGES_PASSWORD_PROTECTION_CHANGE = enviro.isQa() ? 3279 : 1866;
const AUDIENCE_ACCESS_CHANGE = enviro.isQa() ? 1309 : 1059;
const DYNAMIC_PAGE_ACCESS_CHANGE = enviro.isQa() ? 3381 : 1960;

const getPortalInfoURI = portalId => `hubs2/v1/info/hub/${portalId}`;

const getScopesURI = portalId => `hubs2/v1/scopes/hub/${portalId}/filter`;

const getScopeChangesURI = (portalId, scopeId) => `hubs/v2/scope-changes/hub/${portalId}/scope/${scopeId}/status`;

export const fetchPortalInfo = () => http.get(getPortalInfoURI(PortalIdParser.get()));
export const fetchPortalScopes = () => {
  return getEarlyRequesterResultPromise('initial-portal-scopes-fetch', false) || http.get(getScopesURI(PortalIdParser.get()), {
    query: {
      scopes: ['landingpages-access', 'sitepages-access', 'blog-access', 'landingpages-schedule', 'pages-schedule', 'blog-schedule', 'content-ab-testing', 'content-mab-testing', 'design-manager-access', 'email-access', 'sales-meetings-access', 'sitepages-custom-domain-access', 'blog-custom-domain-access', 'sitepages-hs-sites-domain-write', 'blog-hs-sites-domain-write']
    }
  });
};
export const fetchMultiDomainScopeChange = () => http.get(getScopeChangesURI(PortalIdParser.get(), MULTI_DOMAIN_PUBLISHING_SCOPE_ID));
export const fetchCustomDomainsPurgeDate = () => {
  return http.get(getScopeChangesURI(PortalIdParser.get(), LANDINGPAGES_DOMAINS_CHANGE)).then(({
    purgedTime
  }) => purgedTime);
};
export const fetchSitePagesPasswordProtectionPurgeDate = () => {
  return http.get(getScopeChangesURI(PortalIdParser.get(), SITEPAGES_PASSWORD_PROTECTION_CHANGE)).then(({
    purgedTime
  }) => purgedTime);
};
export const fetchLandingPagesPasswordProtectionPurgeDate = () => {
  return http.get(getScopeChangesURI(PortalIdParser.get(), LANDINGPAGES_PASSWORD_PROTECTION_CHANGE)).then(({
    purgedTime
  }) => purgedTime);
};
export const fetchAudienceAccessPurgeDate = () => {
  return http.get(getScopeChangesURI(PortalIdParser.get(), AUDIENCE_ACCESS_CHANGE)).then(({
    purgedTime
  }) => purgedTime);
};
export const fetchDynamicPageAccessPurgeDate = () => {
  return http.get(getScopeChangesURI(PortalIdParser.get(), DYNAMIC_PAGE_ACCESS_CHANGE)).then(({
    purgedTime
  }) => purgedTime);
};