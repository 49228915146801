import { combineReducers } from 'redux';
import { createGenericRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
import { OPTIN_FETCH, OPTIN_UPDATE } from 'ContentData/actions/ActionTypes';

function optins(state = {}, action = {}) {
  switch (action.type) {
    case OPTIN_FETCH:
    case OPTIN_UPDATE:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [response.optinName]: response
        });
      }

    default:
      return state;
  }
}

export default combineReducers({
  optins,
  requestStatus: createGenericRequestStatusReducer(OPTIN_FETCH)
});