'use es6';

import { getTreeNodeDomProperties } from '../helpers';
const allowedDirections = ['top', 'bottom'];
const allowedUnits = ['topUnits', 'bottomUnits'];
/*
 * Supports two formats, the "old" one looks like
 * { "top": 50, "bottom": 50, topUnits: "px" ...etc }
 *
 * The "new" format is nested and looks like
 * {
 *  "top": { value: 50, units: "px" }
 *  "bottom": { value: 50, units: "px" }
 * }
 *
 * We need to support both until the new margin structure normalization done on the backend
 * is ungated to all. Afterwhich we can drop support for the old structure.
 */

const isValidMarginObject = marginObject => {
  const marginObjectKeys = Object.keys(marginObject); // Only allowedDirections and allowedUnits are valid top level keys

  const hasValidKeys = marginObjectKeys.every(keyEntry => allowedDirections.includes(keyEntry) || allowedUnits.includes(keyEntry));
  const isNestedmarginFormat = marginObjectKeys.some(keyEntry => typeof marginObject[keyEntry] === 'object');
  let hasValidValues;
  let hasValidUnits;

  if (isNestedmarginFormat) {
    hasValidValues = true;
    hasValidUnits = true;
    marginObjectKeys.forEach(keyEntry => {
      if (typeof marginObject[keyEntry].value !== 'number') {
        hasValidUnits = false;
        return;
      }

      if (marginObject[keyEntry].units && typeof marginObject[keyEntry].units !== 'string') {
        hasValidUnits = false;
        return;
      }
    });
  } else {
    // Supporting the "old" format should be temporary until data normalization is ungated to all on the backend
    hasValidValues = allowedDirections.every(keyEntry => marginObject[keyEntry] === undefined || typeof marginObject[keyEntry] === 'number' || marginObject[keyEntry] === 'number');
    hasValidUnits = allowedUnits.every(keyEntry => marginObject[keyEntry] === undefined || typeof marginObject[keyEntry] === 'string');
  }

  if (!hasValidKeys || !hasValidValues || !hasValidUnits) {
    console.warn('Margin value must only include either top or bottom keys, all of which should be numbers or objects');
    return false;
  }

  return true;
};

const calculateCssProperties = styleValue => {
  const cssProperties = {};
  allowedDirections.forEach((key, index) => {
    if (styleValue[key] !== undefined) {
      const valueToSet = styleValue[key].value !== undefined // because 0 is falsey
      ? styleValue[key].value : styleValue[key];
      let unit = styleValue[key].value !== undefined ? styleValue[key].units : styleValue[allowedUnits[index]];

      if (unit === undefined) {
        unit = 'px';
      }

      cssProperties[`margin-${key}`] = `${valueToSet}${unit} !important`;
    }
  });
  return cssProperties;
};

const calculateCommonMarginDataForTreeNode = (treeNode, styleValue) => {
  if (!isValidMarginObject(styleValue)) {
    return false;
  }

  const {
    domNodeSelector,
    rendererName
  } = getTreeNodeDomProperties(treeNode);
  return {
    nodeName: treeNode.getName(),
    domNodeSelector,
    className: `${rendererName}-margin`,
    cssSelector: `.${rendererName}-margin`,
    cssProperties: calculateCssProperties(styleValue)
  };
};

const calculateMarginDataForModule = (module, styleValue) => {
  if (!isValidMarginObject(styleValue)) {
    return false;
  }

  return {
    nodeName: module.get('name'),
    domNodeSelector: `#hs_cos_wrapper_${module.get('id')}`,
    className: '',
    cssSelector: `#hs_cos_wrapper_${module.get('id')}`,
    cssProperties: calculateCssProperties(styleValue)
  };
};

export const calculateMarginClasses = (treeNode, styleValue) => {
  const marginObject = calculateCommonMarginDataForTreeNode(treeNode, styleValue);

  if (marginObject) {
    return [marginObject];
  } else {
    return [];
  }
};
export const calculateBreakpointMarginClassesForModule = (module, styleValue, breakpoint) => {
  const marginObject = styleValue === null ? null : calculateMarginDataForModule(module, styleValue);

  if (marginObject) {
    marginObject.mediaQuery = breakpoint.mediaQuery;
    return [marginObject];
  } else {
    return [];
  }
};
export const calculateBreakpointMarginClassesForTreeNode = (node, styleValue, breakpoint) => {
  const marginObject = styleValue === null ? null : calculateCommonMarginDataForTreeNode(node, styleValue);

  if (marginObject) {
    marginObject.mediaQuery = breakpoint.mediaQuery;

    if (node.isStaticSectionModule()) {
      // dnd modules create an extra div wrapper with the #hs_cos_wrapper_{id} selector
      // But Static Section Modules do not - as they come back as a single chunk
      // When the renderDndAreas.js#calculateBreakpointPaddingClassesForTreeNode is applied, for static section modules
      // there is no extra div wrapper, so we need to adjust the selector accordingly
      const newSelector = `${marginObject.cssSelector}#hs_cos_wrapper_${marginObject.nodeName}`;
      marginObject.cssSelector = newSelector;
    } else if (node.isModule()) {
      const newSelector = `${marginObject.cssSelector}>#hs_cos_wrapper_${marginObject.nodeName}`;
      marginObject.cssSelector = newSelector;
    }

    return [marginObject];
  } else {
    return [];
  }
};