'use es6';

import http from 'hub-http/clients/apiClient';
import httpWithNonIdempotentRetries from 'ContentEditorUI/legacyHoldingPattern/apiClientWithNonIdempotentRetries';
import { getCategory } from 'ContentComponents/utils/contentModelUtils';
import I18n from 'I18n';
const SAVE_TIMEOUT = 15000;
let editorUpdateId = 0;
let editorSessionId; // Defer to refering to moment until later (because it isn't available yet to use)

const getEditorSessionId = () => {
  if (editorSessionId == null) {
    editorSessionId = I18n.moment.utc().valueOf();
  }

  return editorSessionId;
};

export const getBaseContentUrl = ({
  baseUrl,
  categoryId,
  subcategory,
  contentId
}) => {
  const fullCategory = getCategory(categoryId, subcategory);
  const postfix = contentId ? `/${contentId}` : '';
  return `${baseUrl}/${fullCategory.api_slug}${postfix}`;
};
export const fetch = baseContentUrl => {
  return http.get(`${baseContentUrl}/buffer`);
};
export const deleteBuffer = baseContentUrl => {
  return http.post(`${baseContentUrl}/discard-buffer`);
};
export const fetchHardSavedContent = ({
  baseContentUrl,
  query = {}
}) => {
  return http.get(`${baseContentUrl}`, {
    query
  });
};
export const getSaveQuery = (editingInfo, shouldAvoidSnakeCasing) => {
  const defaultQuery = {
    if_unmodified_since_ticks: editingInfo.last_edit_ticks,
    editor_session_id: getEditorSessionId(),
    editor_update_id: editorUpdateId++
  };
  return shouldAvoidSnakeCasing ? defaultQuery : Object.assign({}, defaultQuery, {
    casing: 'snake'
  });
};
export const save = ({
  baseContentUrl,
  buffer,
  data,
  query
}) => {
  if (buffer) {
    baseContentUrl = `${baseContentUrl}/buffer`;
  }

  return httpWithNonIdempotentRetries.putWithResponse(baseContentUrl, {
    data,
    query,
    timeout: SAVE_TIMEOUT
  });
}; // TODO make a more flexible approach for structured content save methods
// as not every consumer should be required to make a patch for updates

export const structuredContentSaveRequest = ({
  baseContentUrl,
  data,
  query
}) => {
  if (!baseContentUrl) {
    throw new Error('baseContentUrl is missing. Make sure makeSaveActions has the apiUrl property.');
  }

  return httpWithNonIdempotentRetries.patchWithResponse(baseContentUrl, {
    data,
    query,
    timeout: SAVE_TIMEOUT
  });
};