import { useState, useCallback, useMemo } from 'react';
import productApprovalsApi from '../data/productApprovalsApi';
import { reportRequestException } from '../utils/sentryUtils';

const useApprovalConfig = (approvalType, approvalAction) => {
  const [isLoading, setIsLoading] = useState(false);
  const [config, setConfig] = useState({
    approvalAction,
    approvalType,
    enabled: null,
    portalId: null
  });
  const fetchConfig = useCallback(callback => {
    setIsLoading(true);
    productApprovalsApi.fetchProductApprovalsConfig({
      approvalType,
      approvalAction
    }).then(response => {
      setConfig(prev => Object.assign({}, prev, {}, response));

      if (callback && typeof callback === 'function') {
        callback(response);
      }
    }).catch(errorResponse => {
      // If we cannot get a response from config endpoint, we will set approvals to enabled:false
      setConfig(prev => Object.assign({}, prev, {
        enabled: false
      }));
      reportRequestException('fetchConfig', errorResponse);
    }).finally(() => {
      setIsLoading(false);
    }).catch(() => {
      console.error('Error fetching approvals');
    });
  }, [approvalAction, approvalType]);
  return useMemo(() => ({
    config,
    fetchConfig,
    isLoading
  }), [config, fetchConfig, isLoading]);
};

export default useApprovalConfig;