'use es6';

import { createSelector } from 'reselect';
import ContentRoutes from 'ContentUtils/Routes';
import StateStatusGroups from 'ContentUtils/constants/StateStatusGroups';
import { getHasStagingDomain } from 'ContentEditorUI/redux/selectors/contentSchemaSelectors';
import { getContentState, getSubcategory, getContentModelId, getPreviewKey } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getCategoryId, getContentGroupId, getIsBlogListingPage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getScopes } from 'ContentEditorUI/redux/selectors/authSelectors';
import { getPageSettingsFullUrl } from 'page-editor-ui/redux/selectors/pageSettingsSelectors';
import { basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';
const getReturnToDashboardUrl = createSelector([getCategoryId, getSubcategory, getContentGroupId, getIsBlogListingPage, getScopes], (categoryId, subcategory, contentGroupId, isBlogListingPage, scopes) => ContentRoutes.dashboard(categoryId, subcategory, isBlogListingPage ? contentGroupId : null, scopes)); // Note, email's getIsDraft selector is a little different (it excludes `ERROR`)

const getIsDraft = createSelector([getContentState], state => StateStatusGroups.DRAFT.includes(state));

const getStagingDashboardUrl = state => {
  const route = getIsDraft(state) ? '/manage/stagedDraft' : '/review';
  const domainId = state.contentSchema.context.secondary_to_domain && state.contentSchema.context.secondary_to_domain.id; // Staging beta is gone, no need to check its gate anymore (I believe?)

  return ContentRoutes.staging(domainId, route);
};

export const getDashboardUrl = basicSelectorWithStats(state => {
  if (getHasStagingDomain(state)) {
    return getStagingDashboardUrl(state);
  }

  return getReturnToDashboardUrl(state);
});
export const getShareablePreviewUrl = createSelector([getContentModelId, getPreviewKey, getPageSettingsFullUrl], (id, previewKey, publishedURL) => {
  return `${publishedURL}?hs_preview=${previewKey}-${id}`;
});