'use es6';

import { Map as ImmutableMap, Set as ImmutableSet, fromJS } from 'immutable';
import { quickTreeExport, quickTreeImport } from 'layout-data-lib/LayoutDataTree/serialize';
export const JS_OBJECT = Object.freeze({
  immutable: false
});
export const PRIMITIVE_JS_VALUE = Object.freeze({});
export const IMMUTABLE_MAP = Object.freeze({
  immutable: true
});
export const IMMUTABLE_LIST = Object.freeze({
  immutable: true
});

const coerceToImmutableSet = valueToImport => {
  return new ImmutableSet(fromJS(valueToImport));
};

export const IMMUTABLE_SET = Object.freeze({
  immutable: true,
  parseAfterMessageReceived: coerceToImmutableSet
});
export const NestedImmutableMap = nestedConfig => ({
  immutable: true,
  nestedConfig
});
export const NestedJsObject = nestedConfig => ({
  nestedConfig
}); // Type to represent the limited subset of the userInfo auth object to pass to the iframe
// (for security concerns we want to pass down as little as possible since the iframe can run arbitrary JS)

export const ALLOWLISTED_AUTH_OBJECT = Object.freeze({
  immutable: false,
  exportBeforeMessageSent: valueToExport => {
    return {
      gates: valueToExport.gates,
      user: {
        scopes: valueToExport.user.scopes,
        user_id: valueToExport.user.user_id
      },
      portal: {
        portal_id: valueToExport.portal.portal_id
      }
    };
  }
});
export const ALLOWLISTED_SCHEMA_OBJECT = Object.freeze({
  immutable: false,
  exportBeforeMessageSent: valueToExport => {
    return {
      context: valueToExport.context,
      settings: valueToExport.settings,
      schema: {
        template_errors: valueToExport.schema.template_errors
      }
    };
  }
});
export const ALLOWLISTED_SITE_SETTINGS_MAP = Object.freeze({
  immutable: true,
  exportBeforeMessageSent: valueToExport => {
    return ImmutableMap({
      colorPickerFavorite1: valueToExport.get('colorPickerFavorite1'),
      colorPickerFavorite2: valueToExport.get('colorPickerFavorite2'),
      colorPickerFavorite3: valueToExport.get('colorPickerFavorite3'),
      colorPickerFavorite4: valueToExport.get('colorPickerFavorite4'),
      colorPickerFavorite5: valueToExport.get('colorPickerFavorite5'),
      colorPickerFavorite6: valueToExport.get('colorPickerFavorite6')
    });
  }
}); // D&D area specific types (trees and such)

const UNCHANGED_TREE = 'UNCHANGED_TREE';

const exportObjectOfTrees = (valueToExport, prevValue) => {
  return valueToExport.map(tree => {
    const prevTree = prevValue && prevValue.get(tree.getRootName());

    if (tree === prevTree) {
      return UNCHANGED_TREE;
    }

    return quickTreeExport(tree, {
      previousTreeToDiffValues: prevTree
    });
  });
};

const importObjectToImmutableMapOfTrees = (valueToImport, prevValue) => {
  const result = {};

  for (const treeName in valueToImport) {
    const tree = valueToImport[treeName];
    const prevTree = prevValue && prevValue.get(treeName);

    if (tree === UNCHANGED_TREE) {
      // Carry over unchanged trees
      result[treeName] = prevTree;
    } else {
      result[treeName] = quickTreeImport(tree, {
        previousTreeToDiffValues: prevTree
      });
    }
  }

  return new ImmutableMap(result);
};

export const IMMUTABLE_MAP_OF_LAYOUT_TREES = Object.freeze({
  immutable: true,
  exportBeforeMessageSent: exportObjectOfTrees,
  parseAfterMessageReceived: importObjectToImmutableMapOfTrees
});
export const ALLOWLISTED_EXTRA_INITIAL_STATE_OBJECT = Object.freeze({
  immutable: false,
  exportBeforeMessageSent: valueToExport => {
    return {
      tempCanUseQuickSpot: valueToExport && valueToExport.tempCanUseQuickSpot
    };
  }
});