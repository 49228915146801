import { combineReducers } from 'redux';
import { createGenericRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
import * as ActionTypes from 'ContentData/actions/ActionTypes';
import * as RequestStatus from 'ContentUtils/constants/RequestStatus';
const {
  MEETINGS_CLEAR_MEETINGS,
  MEETINGS_FETCH_MEETING,
  MEETINGS_FETCH_MEETINGS,
  MEETINGS_SAVE_MEETING_CACHE
} = ActionTypes;

const meetings = (state = {}, action) => {
  switch (action.type) {
    case MEETINGS_FETCH_MEETING:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [response.link]: response
        });
      }

    case MEETINGS_SAVE_MEETING_CACHE:
      {
        const {
          meeting
        } = action;
        if (!meeting) return state;
        return Object.assign({}, state, {
          [meeting.link]: meeting
        });
      }

    default:
      return state;
  }
};

const meetingsCache = (state = {}, action) => {
  switch (action.type) {
    case MEETINGS_FETCH_MEETINGS:
      {
        const {
          response
        } = action;
        if (!(response && response.results && response.results.length)) return state;
        return response.results.reduce((hash, meeting) => {
          hash[meeting.link] = meeting;
          return hash;
        }, Object.assign({}, state));
      }

    case MEETINGS_CLEAR_MEETINGS:
      {
        if (Object.keys(state).length) return {};
        return state;
      }

    default:
      return state;
  }
};

function meetingsCacheRequestStatus(state = RequestStatus.UNINITIALIZED, action) {
  switch (action.type) {
    case MEETINGS_FETCH_MEETINGS:
      if (action.response) return RequestStatus.SUCCEEDED;
      if (action.error) return RequestStatus.FAILED;
      return RequestStatus.PENDING;

    default:
      return state;
  }
}

export default combineReducers({
  meetings,
  meetingsCache,
  meetingsCacheRequestStatus,
  requestStatus: createGenericRequestStatusReducer(MEETINGS_FETCH_MEETING)
});