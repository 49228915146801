function escapeMarkup(result) {
  const tempDiv = document.createElement('div');
  tempDiv.innerText = result;
  return tempDiv.innerHTML;
}

function trimNewLines(result) {
  return result.replace(/^\s+|\s+$/g, '');
}

function brToNewLine(result) {
  return result.replace(/<br\/?>/g, '\n');
}

function sanitizeString(result) {
  const trimmedResult = trimNewLines(result);
  const escapedResult = escapeMarkup(trimmedResult); // returning innerHtml has the effect of turning newlines into brs we only support plain text results at the moment

  const plainTextResult = brToNewLine(escapedResult);
  return plainTextResult;
}

function sanitizeArray(result) {
  return result.map(sanitize);
}

function sanitizeObject(result) {
  return Object.fromEntries(Object.entries(result).map(([key, value]) => [key, sanitize(value)]));
}

export function sanitize(result) {
  if (typeof result === 'string') {
    return sanitizeString(result);
  }

  if (Array.isArray(result)) {
    return sanitizeArray(result);
  }

  if (typeof result === 'object') {
    return sanitizeObject(result);
  }

  return result;
}