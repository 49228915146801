'use es6';

import { Map as ImmutableMap } from 'immutable';
import { FETCH_LAYOUT_DATA_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';

const layoutDataReducer = (state = ImmutableMap(), {
  type,
  payload
}) => {
  switch (type) {
    case FETCH_LAYOUT_DATA_SUCCEEDED:
      return state.merge(payload);

    default:
      {
        return state;
      }
  }
};

export default layoutDataReducer;