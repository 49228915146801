export function isNullOrUndefined(value) {
  return value === null || value === undefined;
}
export const isEmptyObject = obj => {
  return obj != null && Object.keys(obj).length === 0 && obj.constructor === Object;
};
export const getDeepClone = (object, onlyTruthy = false) => {
  if (isNullOrUndefined(object) || typeof object !== 'object') {
    return object;
  }

  let result;

  if (Array.isArray(object)) {
    result = object.map(item => {
      return getDeepClone(item, onlyTruthy);
    });
  } else {
    result = Object.assign({}, object);
    Object.keys(result).forEach(key => {
      const value = getDeepClone(result[key], onlyTruthy);

      if (onlyTruthy && isNullOrUndefined(value)) {
        delete result[key];
      } else {
        result[key] = value;
      }
    });
  }

  return result;
};
export function setDeepValue(originalObj, valueKey, value) {
  const key = valueKey[0];
  const obj = originalObj ? JSON.parse(JSON.stringify(originalObj)) : {};

  if (!Array.isArray(valueKey) || !valueKey || !valueKey.length) {
    return obj;
  }

  if (valueKey.length === 1) {
    obj[key] = value;
  } else {
    obj[key] = setDeepValue(obj[key], valueKey.slice(1), value);
  }

  return obj;
}
export function getDeepValue(searchObject, valueKey) {
  if (!Array.isArray(valueKey)) {
    return undefined;
  }

  let i = 0;
  let current = JSON.parse(JSON.stringify(searchObject));

  while (i < valueKey.length) {
    if (isNullOrUndefined(current) || typeof current !== 'object') {
      return undefined;
    }

    current = current[valueKey[i]];
    i = i + 1;
  }

  return current;
}
export function removeDeepValue(searchObject, valueKey) {
  if (!Array.isArray(valueKey)) return undefined;
  const obj = JSON.parse(JSON.stringify(searchObject));
  let currentObj = obj;
  valueKey.forEach((key, index) => {
    if (index === valueKey.length - 1) {
      delete currentObj[key];
      return;
    } else if (typeof currentObj[key] === 'object') {
      currentObj = currentObj[key];
    } else {
      return;
    }
  });
  return obj;
}