import I18n from 'I18n';
import getLang from 'I18n/utils/getLang';
import { getFullUrl } from 'hubspot-url-utils';
import { sendEventsViaFetch } from 'usage-tracker-core/client/fetchClient';
import { createClient } from 'usage-tracker-core';
import { once } from 'usage-tracker-core/common/helpers';
import { genericClient } from 'usage-tracker-core/client';
const origin = getFullUrl('app-api');
const path = '/usage-logging/v1/log/hublytics-multi/no-auth';

const reportNetworkError = err => {
  return genericClient.reportError(err, {
    fingerprint: ['usage-tracker-js', 'network']
  });
}; // This prevents the Hub User Info from being requested multiple times
// instead it is requested only once, which should reduce unnecessary calls


const onceHubUserInfo = once(() => // We only import `userInfo` whenever we need it, to remove the dependency on `hub-http`
// during bundle time, as it's not always necessary (only in the case we need userInfo)
import('hub-http/userInfo').then(({
  default: userInfo
}) => userInfo({
  ignoreRedirect: true
})));
const trackingClient = createClient({
  clientName: 'app',
  getDebug: genericClient.getDebug,
  getHstc: genericClient.getHstc,
  getTempStorage: genericClient.getTempStorage,
  setTempStorage: genericClient.setTempStorage,
  logWarning: genericClient.logWarning,
  logError: genericClient.logError,
  reportWarning: genericClient.reportWarning,
  reportError: genericClient.reportError,
  getEmail: () => onceHubUserInfo().then(({
    user
  }) => user.email),
  getHubId: () => onceHubUserInfo().then(({
    portal
  }) => portal.portal_id),
  getLang: () => Promise.resolve(I18n.Info).then(getLang).catch(genericClient.getLang),
  send: ({
    events,
    query
  }) => {
    const endpoint = `${origin}${path}?${query}`;

    const sendXhr = () => sendEventsViaFetch(endpoint, {
      data: events
    }).catch(reportNetworkError);

    genericClient.sendBeacon(endpoint, events, sendXhr);
  }
});
export default trackingClient;