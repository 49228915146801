'use es6';

import http from 'hub-http/clients/apiClient';
import getLang from 'I18n/utils/getLang';
import getEarlyRequesterResultPromise from '../earlyRequester/getEarlyRequesterResultPromise';
import { trackAppLifecycleTimestamp, COMBINED_EDIT_REQUEST_STARTED, COMBINED_EDIT_REQUEST_FINISHED } from 'ContentEditorUI/utils/lifecycleData';

function getUrl(id, includeUsedModuleSchemas) {
  const language = getLang();
  return includeUsedModuleSchemas ? `content-editor/v1/combined-edit-data/${id}?includeUsedModuleSchemas=true&userLanguage=${language}` : `content-editor/v1/combined-edit-data/${id}?userLanguage=${language}`;
}

function trackLifecycleAndDeletePostBodyWidget(res) {
  trackAppLifecycleTimestamp(COMBINED_EDIT_REQUEST_FINISHED); // HACK The post_body is a fake widget and should never be saved.
  // Deleting at the highest level possible to avoid inconsistencies

  if (res && res.content && res.content.widgets && res.content.widgets.post_body) {
    delete res.content.widgets.post_body;
  }

  return res;
}

function httpFetch(id, includeUsedModuleSchemas) {
  return http.get(getUrl(id, includeUsedModuleSchemas), {
    timeout: 30000
  });
}

export function fetch(id, includeUsedModuleSchemas = false) {
  trackAppLifecycleTimestamp(COMBINED_EDIT_REQUEST_STARTED);
  const earlyRequest = getEarlyRequesterResultPromise(`content-${id}`);
  const earlyRequestWithRetry = earlyRequest ? earlyRequest.catch(() => httpFetch(id, includeUsedModuleSchemas)) : null;
  const contentPromise = earlyRequestWithRetry || httpFetch(id, includeUsedModuleSchemas);
  return contentPromise.then(trackLifecycleAndDeletePostBodyWidget);
}
export function fetchPageCss(id) {
  return http.get(`cos-rendering/v1/internal/render/${id}/edit/css-assets`);
}
/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking

if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}